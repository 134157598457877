import React, { useEffect, useState } from 'react';

import classes from './styles.module.scss';

export const About = ({ career, educations, description, __typename }) => {
  const isIndividual = __typename === 'IndividualProfile'

  const getYear = (date) => {
    const d = new Date(date)
    return d.getFullYear()
  }

  return (
    <div className={classes.root}>
     <div className={classes.main}>
       <div className={classes.about}>
         <div className={classes.title}>{isIndividual ? 'О себе' : 'О компании'}</div>
         {description ? description : 'Нет данных'}
       </div>

       {/*<div className={classes.about}>*/}
       {/*  <div className={classes.title}>Деятельность</div>*/}
       {/*  Ясность нашей позиции очевидна: дальнейшее развитие различных форм деятельности выявляет срочную потребность приоритизации разума над эмоциями! Банальные, но неопровержимые выводы, а также акционеры крупнейших компаний лишь добавляют фракционных разногласий.*/}
       {/*</div>*/}
     </div>

      <aside>
        <div className={classes.about}>
          <div className={classes.title}>Образование</div>
          {Array.isArray(educations) && educations.length > 0 ?
            educations.map((el) => (
              <div className={classes.educate} key={el.institutionName}>
                {el.institutionName}
                <span>{getYear(el.completeDate)}</span>
                <span>{el.specialty}</span>
              </div>
            ))
            : 'Нет данных'
          }
        </div>

        <div className={classes.about}>
          <div className={classes.title}>Карьера</div>
          {Array.isArray(career) && career.length > 0 ?
            career.map((el) => (
              <div className={classes.educate} key={el.jobName}>
                {el.jobName}
                <span>{getYear(el.beginDate)}-{getYear(el.endDate)}</span>
                <span>{el.position}</span>
              </div>
            ))
            : 'Нет данных'
          }
        </div>
      </aside>
    </div>
  )
}
