import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.svg';


const Logo = props => {
    const { auth } = props;
    return (
        <div className="logo">
            <Link to={`${auth ? '/profile/me' : '/'}`}>
                <img src={logo} width="180px" alt="website logo"/>
            </Link>
        </div>
    )
};


export default Logo;
