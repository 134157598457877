import Language from "language/index";

export const userTypesClassName = type => {
    switch (type) {
        case 'Investor':
            return 'investor';
        case 'CoInvestor':
            return 'coinvestor';
        case 'Innovator':
            return 'innovator';
        case 'BusinessWoman':
            return 'business_lady';
        case 'Expert':
            return 'expert';
        case 'Employer':
            return 'employer';
        case 'Unemployed':
            return 'unemployed';
        case 'ProjectManager':
            return 'proektant';
        case 'Provider':
            return 'provider';
        case 'BusinessProduct':
            return 'seller_business_products';
        case 'hrManager':
            return 'hr_manager';
        default:
            return '';
    }
};

export const userTypeDesc = type => {
    switch (type) {
        case 'Investor':
            return Language.investor;
        case 'Innovator':
            return Language.novator;
        case 'BusinessWoman':
            return Language.businessWoman;
        case 'Unemployed':
            return Language.unemployed;
        case 'Provider':
            return Language.supplier;
        case 'BusinessProduct':
            return Language.sellerBusiness;
        default:
            return '';
    }
};


export const reccomendType = type => {

    return ["BusinessProduct", "Innovator", "Investor", "Provider", "BusinessWoman", "Unemployed"];

    switch (type) {
        case 'Investor':
            return ["BusinessProduct", "Innovator", "Investor", "Provider", "BusinessWoman", "Unemployed"];
        case 'Innovator':
            return ["Investor", "BusinessWoman"];
        case 'BusinessProduct':
            return ["Investor","BusinessProduct", "Unemployed",  "BusinessWoman"];
        case 'Provider':
            return ["Investor", "Provider", "BusinessWoman", "BusinessProduct"];
        case 'BusinessWoman':
            return ["BusinessWoman", "BusinessProduct", "Investor"];
        case 'Unemployed':
            return ["Investor", "BusinessProduct", "Provider", "BusinessWoman"];
        case 'Viewer':
            return [];
        default:
            return [];
    }
};

export const typeRequestTitle = type => {
  switch (type) {
    case 'investor':
      return Language.ApplicationInvestment;
    case 'novator':
      return Language.ApplicationInnovation;
    case 'businessproduct':
      return Language.ApplicationBusinessProduct;
    case 'provider':
      return Language.supplierApplication;
    case 'businesswoman':
      return Language.BusinesswomanApplication;
    case 'unemployed':
      return Language.ApplicationUnemployed;
    default:
      return [];
  }
};
