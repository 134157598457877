import PhoneVerificationForm from 'components/UI/Forms/PhoneVerificationForm';
import RestorePassword from "components/UI/Forms/RestorePassword/index";
import SetPasswordForm from 'components/UI/Forms/SetPasswordForm';
import { ActionNotification } from "components/UI/Notifications/action";
import { required } from "libs/fieldValidation";
import { getItem } from "libs/localStorage";
import { showError } from "libs/showFormsError";
import { passwordConfirm } from "libs/validationsForms";
import { SEND_CODE } from "mutation/index";
import { RESTORE_PASSWORD } from "mutation/profile";
import { CHECK_CODE, SET_NEW_PASSWORD } from "query/index";
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { client } from "../../../apollo";
import { removeItem, setItem } from "../../../libs/localStorage";


class RecoveryPassword extends Component {

    interval = null;

    state = {
        step: 1,
        seconds: 120
    };

    startTimeCode = () => {
        this.setState({ seconds: 120 });
        this.interval = setInterval(() => {
            if (this.state.seconds === 0) clearInterval(this.interval);
            else this.setState({ seconds: this.state.seconds - 1 });
        }, 1000)
    };

    startTwoStep = () => {
        this.setState({ step: 2 });
        this.startTimeCode();
    };

    startStepThree = () => {
        this.setState({ step: 3 });
    };

    checkCode = value => {
        const { code } = value;

        showError({
            code: [required(code)],
        });

        let phone = getItem('phone', phone);
        phone = phone.replace(/\D+/g, "");

        client.query({ query: CHECK_CODE, variables: { phone, code } })
            .then(res => {
                if (res.data.testCode) {
                    setItem('code', code);
                    this.startStepThree();
                } else {
                    ActionNotification('1', `Неверный код`);
                }
            })
    };


    setNewPasword = (values, mutate) => {
        let { password, repeatPassword } = values;

        let validateObj = {
            password: [required(password)],
            repeatPassword: [required(repeatPassword)]
        };

        if (password && repeatPassword) {
            validateObj['repeatPassword'] = [passwordConfirm(password, repeatPassword)]
        }

        showError(validateObj);

        let phone = getItem('phone', phone);
        phone = phone.replace(/\D+/g, "");
        let code = getItem('code');

        mutate({ variables: { phone, code, password, repeatPassword } })
            .then(res => {
                let { token } = res.data.setNewPassword;
                setItem('token', token);
                removeItem('code');
                removeItem('phone');

                this.props.history.go('/')
            })
    };


    repeatCode = () => {
        this.startTimeCode();
        client.mutate({ mutation: RESTORE_PASSWORD, variables: { phone: getItem('phone').replace(/\D+/g, "") } })
    };


    render() {
        const { step } = this.state;
        const initialValues = { phone: getItem('phone', '') };
        return (
            <div className="recovery-windows flex">
                {step === 1 && <RestorePassword nextStep={this.startTwoStep}/>}
                {step === 2 && <PhoneVerificationForm
                    mutate={SEND_CODE}
                    initialValues={initialValues}
                    submit={this.checkCode}
                    counter={this.state.seconds}
                    resendCode={this.repeatCode}
                />}
                {step === 3 && <SetPasswordForm
                    mutate={SET_NEW_PASSWORD}
                    submit={this.setNewPasword}/>
                }
            </div>
        )
    }
}


export default withRouter(RecoveryPassword);