import React, { Component, useEffect, useRef } from 'react';
import Input from "components/UI/Fields/Input";
import { Field, reduxForm, stopSubmit, change } from "redux-form";
import Language from "language/index";
import { Mutation } from "react-apollo";
import { RESTORE_PASSWORD } from "mutation/profile";
import { submitRestorePasswordForm } from "components/UI/Forms/RestorePassword/submit";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-google";
import { getItem } from "libs/localStorage";
import classes from 'pages/Registration/components/RegistrationForms/styles.module.scss';
import { Button } from 'components/v1/Button';


const RestorePassword = ({ handleSubmit, dispatch }) => {
    const captcha = useRef();

    const onLoadRecaptcha = () => {
        if (captcha.current) {
            captcha.current.reset();
        }
    };

    useEffect(() => {
        loadReCaptcha();

        if (captcha.current) {
            captcha.current.reset();
        }
    }, [])


    const handleCaptcha = value => {
        dispatch(change('restorePassword', 'captcha', value))
    };

    return (
      <div className={classes.block}>
          <div className={classes.title}>Восстановление пароля</div>
            <form onSubmit={handleSubmit}>
                <Field
                    name="phone"
                    component={Input}
                    label={Language.yourPhone}
                    mask="+7 (999) 999-99-99"
                    placeholder={Language.phone}
                    className={classes.field}
                />

                <div className={classes.recaptcha}>
                    <ReCaptcha
                        ref={captcha}
                        hl={getItem('language', 'ru')}
                        size="normal"
                        render="explicit"
                        sitekey="6LdcFbkUAAAAAIwbZGXl_UQwVDWmuxNjjIcY5Bb8"
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={handleCaptcha}
                    />
                </div>
                <Button className={classes.button} type="submit">Продолжить</Button>
            </form>
        </div>
    );
}


const RestorePasswordRedux =  reduxForm({
    form: 'restorePassword',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('restorePassword'));
        }
    }
})(RestorePassword);


export default props => (
    <Mutation
        mutation={RESTORE_PASSWORD}
        onCompleted={() => props.nextStep()}
    >
        {mutate => (
            <RestorePasswordRedux
                {...props}
                onSubmit={values => submitRestorePasswordForm(values, mutate)}
            />
        )}
    </Mutation>
);
