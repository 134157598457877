import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import React, { PureComponent } from 'react';
import { Field } from "redux-form";
import PropTypes from 'prop-types';
import { years } from "libs/optionsSelect";
import Language from "language/index";


class Career extends PureComponent {

    static propTypes = {
        index: PropTypes.number
    };

    static defaultProps = {
        index: 0
    };

    render() {
        const { index } = this.props;
        return (
            <div className="elements-group elements-group-fix">
                <div className="form fullWidth">
                    <div className="element selectReq">
                        <div className="row clearfix">
                            <Field
                                name={`career[${index}].jobName`}
                                type="text"
                                component={Input}
                                label={`${Language.placeWork} *`}
                                placeholder="Введите место работы"
                                className="col-3"
                            />
                            <Field
                                name={`career[${index}].beginDate`}
                                type="text"
                                component={Select}
                                label={`${Language.yearStart} *`}
                                placeholder="Выберите год"
                                className="col-3"
                                options={years}
                            />
                            <Field
                                name={`career[${index}].endDate`}
                                type="text"
                                component={Select}
                                label={`${Language.yearGraduation}`}
                                placeholder="Выберите год"
                                className="col-3"
                                options={years}
                            />

                        </div>
                    </div>
                    <Field
                        name={`career[${index}].position`}
                        type="text"
                        component={Input}
                        label={`${Language.Position} *`}
                        placeholder="Введите должность"
                    />

                </div>
            </div>
        );
    }
}


export default Career;