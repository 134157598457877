import AppContainer from 'components/UI/Requests/AppContainer';
import { User } from 'components/v1/User';
import { Button } from 'components/v1/Button';
import { MessageIcon } from 'components/v1/Icons/MessageIcon';
import { typeRequestTitle } from 'libs/uaerTypes';
import { getIndustryName } from 'components/v1/Request/utils';

import classes from './styles.module.scss';
import { RequestControl } from './RequestControl';
import { dialogueChecking } from 'libs/dialogueChecking';
import { setItem } from 'libs/localStorage';
import forwardTo from 'libs/forwardTo';
import { dateToString } from 'libs/convertDate';

export const Request = props => {
  const { user, typeOffer, title, industry, isMe, createDate } = props;
  console.log(props);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <User {...user} size="small" />
        <div className={classes.actions}>
          {!isMe && (
            <Button
              variant="outlined"
              size="small"
              icon={<MessageIcon className={classes.icon}/>}
              onClick={() => dialogueChecking(user._id)}
            />
          )}
          <RequestControl {...props} edit={() => {
            setItem('editrequest', JSON.stringify(props))
            forwardTo('/edit');
          }}/>
        </div>
      </div>
      <div className={classes.body}>
        {Boolean(Object.keys(industry).length) && (
          <div className={classes.industry}>
            <span>{getIndustryName(industry)}</span>
          </div>
        )}
        <div className={classes.title}>
          {title || typeRequestTitle(typeOffer)}
        </div>
        <AppContainer data={props} layout="table-layout"/>
      </div>
      <div className={classes.footer}>
        {dateToString(new Date(createDate), 'stringFullDate')}
      </div>
    </div>
  )
}
