import React from 'react';
import cn from 'classnames';

import classes from './styles.module.scss';

export const AsideTab  = ({ label, value, count, activeTab, onChangeTab }) => {
  const handleClickTab = () => onChangeTab(value);

  return (
    <div
      className={cn(classes.item, { [classes.active]: value === activeTab })}
      onClick={handleClickTab}
    >
      {label}
      {Boolean(count) && <span>{count}</span>}
    </div>
  );
};
