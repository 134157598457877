import { ActionNotification } from "../../../Notifications/action";
import showError from "libs/showFormErrors";
import { required } from "libs/fieldValidation";
import Language from "language/index";

export const submitFreelsncerForm = (values, mutate, cb) => {

  const { additionallyInform, paymentType, position, industry, address, salary } = values;
  showError({
    'additionallyInform': [required(additionallyInform)],
    'paymentType': [required(paymentType)],
    'position': [required(position)],
    'industry.groupName': [required(industry)],
    'address.countryId': [required(address)],
    'salary.amount': [required(salary)]
  });

  const fields = ['_id', 'additionallyInform', 'paymentType', 'address', 'position', 'industry', 'salary'];

  const value = { ...values };

  for (let key in value) {
    if (value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
    if (!fields.some(el => el === key)) {
      delete value[key];
    } else if (value[key].hasOwnProperty('amount')) {
      value[key].amount = parseInt(value[key].amount);
    }
  }

  mutate({ variables: { offer: { ...value } } })
      .then(res => {
          if (cb) {
              cb();
              ActionNotification('2', Language.applicationChanged);
          } else {
              ActionNotification('2', Language.applicationSuccessfullySent);
          }
      })
      .catch(err => {
          if (err.hasOwnProperty('graphQLErrors') && err.graphQLErrors[0].hasOwnProperty('code') && err.graphQLErrors[0].hasOwnProperty('description')) {
              ActionNotification('1', err.graphQLErrors[0].description);
          } else {
              ActionNotification('1', "Произошла ошибка");
          }
      })
};