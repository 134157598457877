export const SettingsRequesIcon = props => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="3.80078" x2="18" y2="3.80078" stroke="#FF7400" strokeWidth="2" strokeLinecap="round"/>
      <circle cx="5.5" cy="3.5" r="2.5" fill="white" stroke="#FF7400" strokeWidth="2"/>
      <line x1="1" y1="12.3496" x2="18" y2="12.3496" stroke="#FF7400" strokeWidth="2" strokeLinecap="round"/>
      <circle cx="13.1006" cy="12.0508" r="2.5" fill="white" stroke="#FF7400" strokeWidth="2"/>
    </svg>

  )
}
