import React from 'react';
import { Field } from "redux-form";
import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import PropTypes from 'prop-types';
import { years } from "libs/optionsSelect";
import Language from "language/index";


class Education extends React.Component {

    static propTypes = {
        index: PropTypes.number
    };

    static defaultProps = {
        index: 0
    };

    render() {
        const { index } = this.props;
        return (
            <div className="elements-group elements-group-fix">
                <div className="form fullWidth">
                    <div className="element selectReq">
                        <div className="row clearfix">
                            <Field
                                name={`educations[${index}].institutionName`}
                                type="text"
                                component={Input}
                                label={`${Language.educationalInstitution} *`}
                                placeholder="Введите учебное заведение"
                                className="col-9"
                            />
                            <Field
                                name={`educations[${index}].completeDate`}
                                type="text"
                                component={Select}
                                label={`${Language.yearGraduation} *`}
                                placeholder="Выберите год"
                                className="col-3"
                                options={years}
                            />
                        </div>
                    </div>
                    <Field
                        name={`educations[${index}].specialty`}
                        type="text"
                        component={Input}
                        label={`${Language.academicSpeciality} *`}
                        placeholder="Введите специальность"
                    />
                </div>
            </div>
        );
    }
}

export default Education;