import ModalWindow from "components/UI/ModalWindow/index";
import PreloaderCircle from "components/UI/Prealoders/PreloaderCircle";
import { GET_PROFILE_USER } from "query/profile";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import ProfileIndividualForm from "components/UI/Forms/ProfileIndividualForm/index";
import ProfileLegalEntityForm from "components/UI/Forms/ProfileLegalEntityForm/index";
import { SET_ENTITY_PROFILE_ADMIN, SET_INDIVIDUAL_PROFILE_ADMIN } from "mutation/profile";

class EditProfile extends Component {

    state = {
        isOpen: false,
        loading: false,
    };


    render() {
        console.log(this.props.getUserById);
        let initialValues = { ...this.props.getUserById };
        for (let key in initialValues) {
            if (initialValues[key] === null) delete initialValues[key]
        }
        return (
            <div>
                <ModalWindow className="modal block" title="Редактирование профиля" handleClose={this.props.close}>
                    {
                        this.props.getUserById.__typename === 'IndividualProfile' &&
                        <ProfileIndividualForm cb={this.props.close} mutation={SET_INDIVIDUAL_PROFILE_ADMIN}  initialValues={initialValues}/>
                    }

                    {
                        this.props.getUserById.__typename === 'EntityProfile' &&
                        <ProfileLegalEntityForm cb={this.props.close} mutation={SET_ENTITY_PROFILE_ADMIN} initialValues={initialValues}/>
                    }
                </ModalWindow>
            </div>
        )
    }
}


export default props => {
    return (
        <Query query={GET_PROFILE_USER} variables={{ userId: props.userId }}>
            {({ data, loading, error }) => {
                if (error) return 'Error...';
                if (loading && !data.UserGetter) return <PreloaderCircle/>;
                return <EditProfile {...props} {...data.UserGetter}/>
            }}
        </Query>
    )
};