import React, { Component } from 'react';
import Language from "language/index";

class RightSide extends Component {
  render() {
    return (
      <div className="rightside">
        <div className="fixed-help-block">
          <div className="side-block ">

            <div className="sb-title">
              <div className="element clearfix">
                <em>{Language.userDocuments}</em>
              </div>
            </div>

            <ul className="blue-links">
              <li><a target="_blank" href='/cookie'> {Language.TermsUseCookies} </a></li>
              <li><a target="_blank" href='/privacy'> {Language.UsersPersonalPrivacy} </a></li>
              <li><a target="_blank" href='/terms'> {Language.TermsOfUser} </a></li>

            </ul>

          </div>
        </div>
      </div>
    );
  }
}

export default RightSide;