import Language from "language/index";
import React, { Component } from 'react';

export default class NotificationWarnings extends Component {

    render() {
        const { text, close } = this.props;
        return (
            <div className="notification warnings" onClick={close}>
                <div className="container">
                    <i className="panton b">i</i>
                    <strong>{Language.Warning}</strong>
                    <p>{text}</p>
                </div>
            </div>
        )
    }
}
