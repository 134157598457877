import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

const Language = {
	dateTime: 'ru'
};

export const PayloadFilter = (items, sort = false) => {
	let payload = [];
	items.forEach((el, i) => payload[i] = { value: el.id, label: el.title });

	if (sort)
		return payload.sort((prev, next) => {
			if (prev.value < next.value) return -1;
			if (prev.value > next.value) return 1;
			return 0;
		});
	return payload;
};

export const vkAPI = (url, count = 1000) => {
	return axios({
		url: `https://api.vk.com/method/database.${url}&lang=${Language.dateTime}&count=${count}&v=5.92&access_token=a866b793a866b793a866b793c8a80473bdaa866a866b793f297308c31df4d5b2ba35960`,
		method: 'GET',
		adapter: jsonpAdapter
	})
};


export const getCountry = (country, regionSuccess, citiesSuccess) => {
	vkAPI(`getRegions?country_id=${country.value}`).then(res => {
		// если найдены регионы этой страны то вернуть их, иначе сразу показать города
		if (res.data.response.count > 0) {
			let returnResponse = PayloadFilter(res.data.response.items, true); // начало костыля для присоединения крыма
			if (country.value === 1) returnResponse.push({ value: 1500001, label: "Крым" }); // конец костыля для присоединения крыма
			if (country.value === 2)
				returnResponse = returnResponse.filter(el => { if (el.value !== 1500001) return el; });
			regionSuccess(returnResponse);
		} else {
			vkAPI(`getCities?country_id=${country.value}&need_all=1`).then(res => {
				if (res.data.response.count > 0)
					citiesSuccess(PayloadFilter(res.data.response.items, true));
			});
		}
	});
};

export const getRegion = (countryID, region, Cities) => {
	// присоединяем крым
	let countrySearch = countryID;
	if (countrySearch === 1 && region.value === 1500001) countrySearch = 2;

	vkAPI(`getCities?country_id=${countrySearch}&region_id=${region.value}`).then(res => {
		// если при выборе региона нет городов, пробуем искать только по стране, иначе возвращаем города
		if (res.data.response !== undefined) { // временный фикс
			if (res.data.response.count === 0) {
				vkAPI(`getCities?country_id=${countrySearch}`).then(res => {
					Cities(PayloadFilter(res.data.response.items, true));
				});
			} else {
				Cities(PayloadFilter(res.data.response.items, true));
			}
		}
	})
};

export const getCity = (countryID, regionID, city, Success) => {
	// присоединяем крым
	let countrySearch = countryID;
	if (countrySearch === 1 && regionID === 1500001 && city === '') countrySearch = 2;

	vkAPI(`getCities?country_id=${countrySearch}&region_id=${regionID}&q=${city}`).then(res => {
		Success(PayloadFilter(res.data.response.items));
	});
};

export const getCities = (cityID, Success) => {
	vkAPI(`getCitiesById?city_ids=${cityID}`).then(res => {
		Success(PayloadFilter(res.data.response));
	});
};

export const getCountries = Success => {
	vkAPI(`getCountries?need_all=1`).then(res => {
		Success(PayloadFilter(res.data.response.items, true));
	});
};