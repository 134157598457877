import SideBlock from "components/UI/SideBlock/index";
import React, { Component } from 'react';
import Language from "language/index";


class AboutProfile extends Component {
    render() {
        const { description } = this.props;
        if (description === null) return null
        return (
            <SideBlock
                className="other--styles pb90"
                title={Language.aboutMe}
            >
                <p> {description === null ? '...' : description} </p>

                {/*<div className="reg-soc-btns small">*/}
                {/*<p>Ищите меня</p>*/}
                {/*<a href="/" className="vk">ВК</a>*/}
                {/*<a href="/" className="fb">Facebook</a>*/}
                {/*</div>*/}
            </SideBlock>
        )
    }
}


export default AboutProfile;