import gql from 'graphql-tag';

export const CREATE_DIALOG = gql`
    mutation($dialog: DialogInput) {
        DialogSetter {
            createDialog(dialog: $dialog) {
                _id
            }
        }
    }
`;

export const SEND_MESSAGE = gql`
    mutation($msg: MsgInput) {
        DialogSetter {
            sendMsg(msg: $msg) {
                _id
                dialogId
                senderId
                text
                isRead
                links
                createDate
            }
        }
    }
`;

export const TOGGLE_FAVORITE_DIALOG = gql`
    mutation ($dialogId: String!, $favorite: Boolean){
        DialogSetter {
            toggleFavoriteDialog(dialogId: $dialogId, favorite: $favorite) {
                _id
                favorite
            }
        }
    }
`;

export const TOGGLE_FAVORITE_MSG = gql`
    mutation ($dialogId: String!, $msgId: String!, $favorite: Boolean){
        DialogSetter {
            toggleFavoriteMsg(dialogId: $dialogId, msgId: $msgId, favorite: $favorite) {
                _id
                favorite
            }
        }
    }
`;

export const READ_MSG = gql`
    mutation ($dialogId: String!, $msgId: String!){
        DialogSetter {
            readMsg(dialogId: $dialogId, msgId: $msgId) {
                _id
                isRead
            }
        }
    }
`;

export const HIDE_DIALOG_BY_ID = gql`
    mutation ($dialogId: String!){
        DialogSetter {
            hideDialogById(dialogId: $dialogId) {
                _id
            }
        }
    }
`;

export const SHOW_DIALOG_BY_ID = gql`
    mutation ($dialogId: String!){
        DialogSetter {
            showDialogById(dialogId: $dialogId) {
                _id
            }
        }
    }
`;

