import React from 'react'
import Language from 'language';

import { rules } from 'libs/rules'
import { InputController } from 'components/v1/HookFields/Input';

import styles from './styles.module.scss'

export const ContactForm = ({ control }) => {

  // const { control } = useForm()

  return (
    <>
      <div className={styles.section}>
        <div>Сайт</div>
        <InputController
          control={control}
          name="site"
        />
      </div>
      <div className={styles.section}>
        <div>E-mail</div>
        <InputController
          control={control}
          name="email"
          rules={rules.email}
        />
      </div>
      {/*<div className={styles.section}>*/}
      {/*  <div>{Language.phone}</div>*/}
      {/*  <div style={{ maxWidth: 289, width: '100%' }}>*/}
      {/*    <InputController*/}
      {/*      control={control}*/}
      {/*      name="phone"*/}
      {/*      mask="+7 (999) 999-99-99"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="separator" />*/}
      {/*<Button type="submit">*/}
      {/*  Сохранить*/}
      {/*</Button>*/}
    </>
  )
}
