import { Avatar } from "components/UI/Avatar/index";
import { dateToString } from "libs/convertDate";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compareTime } from "libs/getMessageTime";
import { Link } from "react-router-dom";


export default class MessagesItem extends Component {

    static propTypes = {
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        readMessage: PropTypes.func
    };

    static defaultProps = {
        readMessage: () => {return false}
    };

    componentDidMount() {
        this.props.readMessage();
    }


    render() {

        const { createDate, senderId, userFrom, userTo, prevMess, links} = this.props;
        const senderMsg = userFrom._id === senderId ? userFrom : userTo;
        let author = true;
        if (prevMess) {
            if (prevMess.senderId === senderId && compareTime(createDate, prevMess.createDate)) {
                author = false;
            }
        }



        return (
            <li>

                {
                    author &&
                    <Avatar avatar={senderMsg.avatar}/>
                }

                <div className={`s-contact-info ${!author ? 'not_height' : ''}`}>
                    {
                        author &&
                        <Link className="contact-name" to={`/profile/${senderMsg._id}`}>{senderMsg.lastName} {senderMsg.firstName}</Link>

                    }
                    <div className="contact-message">{
                        this.props.text.split(' ').map(el => {
                            if (!!el.match(/http:\/\/[\w\.\-]+\b/ig) || !!el.match(/https:\/\/[\w\.\-]+\b/ig)) {
                                return (
                                    <a key={el} className="color_link" href={el} target="_blank">{el}</a>
                                )
                            } else return  el + ' '
                        })
                    }</div>

                    <div className="contact-files">
                        {links.map((url, index) => {
                            let path = url.split('.'),
                                ext = path[path.length - 1];
                            switch (ext) {
                                default:
                                    return (
                                        <a key={index} href={url} target="_blank" class="link">
                                            { url.split('/')[url.split('/').length - 1] }
                                        </a>
                                    );
                                case 'jpg':
                                case 'jpeg':
                                case 'png':
                                case 'gif':
                                    return <a key={index} href={url} target="_blank"><img src={url} alt={url}/></a>;
                            }
                        })}
                    </div>

                    <div className="right-mes-desc">
                        {/*TODO: добавить на бекэнде избранные диалоги*/}
                        {/*<div className="tar mb10">*/}
                            {/*<button type="button" className="tooltip-featured featured-btn tooltipstered"/>*/}
                        {/*</div>*/}
                        {
                            author &&
                            <span className="time"> {dateToString(new Date(createDate), 'time')} </span>
                        }
                    </div>
                </div>
            </li>
        );
    }
}




