import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Query } from 'react-apollo'

import Loading from 'components/UI/Prealoders/Loading'
import SideBlock from 'components/UI/SideBlock/index'
import SbContactsList from 'components/UI/SbContacts/SbContactsList'

import { GET_CONTACTS } from 'query/contacts'
import Language from 'language/index'

import { client } from '../../../apollo'


class MyContacts extends Component {

  static propTypes = {
    ContactGetter: PropTypes.object,
    loading: PropTypes.bool,

  };

  static defaultProps = {
    ContactGetter: {
      getContactList: {
        contacts: [],
        total: 0
      }
    },
    loading: false
  };

  render () {

    const { total, contacts } = this.props.ContactGetter.getContactList
    const { loading } = this.props;

    return (
      <SideBlock
        count={total}
        title={Language.MyContacts}
      >
        {
          loading ?
            <Loading/> :
            <SbContactsList
              isStatus
              users={contacts.slice(0, 12)}
            />
        }
      </SideBlock>
    )
  }
}


export default props => {
  return (
    <Query
      client={client}
      query={GET_CONTACTS}
      variables={{ status: 'is_added', count: 50 }}
    >
      {({ loading, error, data: { ContactGetter } }) => {
        if (error) return 'Error...';
        return (
          <MyContacts
            loading={loading}
            ContactGetter={ContactGetter}
            {...props}
          />
        )
      }}
    </Query>
  )
};
