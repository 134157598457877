import AppContainer from "components/UI/Requests/AppContainer";
import RequestControl from "components/UI/Requests/RequestControl";
import RequestHeaderItem from "components/UI/Requests/RequestHeaderItem";
import SideBlock from "components/UI/SideBlock/index";
import { typeRequestTitle } from "libs/uaerTypes";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Language from "language/index";
import ProfileTypeForm from "components/Pages/Requests/ProfileTypeForm";


const AuthorNotice = ({ author, id }) => {
  return (
    <tr className="author-notice">
      <td><span>{Language.author}:</span></td>
      <td><Link target="_blank" to={`profile/${id}`}>{author}</Link></td>
    </tr>
  )
};


class Application extends Component {

  state = {
    isEdit: false
  };

  toggleEdit = () => this.setState({ isEdit: !this.state.isEdit });

  render() {
    const { _id, createDate, typeOffer, isNetwork, user, status, __typename } = this.props;
    return (
      <SideBlock>
        <div className="_visible">
          <RequestHeaderItem title={typeRequestTitle(typeOffer)} id={_id} date={createDate}/>
          {
            !this.state.isEdit &&
            <div className="posr">
              <RequestControl
                _id={_id}
                user={user}
                status={status}
                isNetwork={isNetwork}
                edit={this.toggleEdit}
                admin={this.props.admin}
              />
              <table className="sb-table flex-order-for-notice">
                <tbody>
                {
                  (isNetwork || this.props.admin) &&
                  <AuthorNotice
                      isAdmin={this.props.admin}
                    author={`${user.lastName} ${user.firstName}`}
                    id={user._id}
                  />
                }
                <AppContainer data={this.props} layout="table-layout"/>
                </tbody>
              </table>
            </div>
          }

          {
            this.state.isEdit &&
            <ProfileTypeForm type={__typename} initialValues={this.props} cb={this.toggleEdit} />
          }
        </div>
      </SideBlock>
    );
  }
}


export default Application;
