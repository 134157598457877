import React from 'react';
import { Query } from 'react-apollo';
import { Request } from 'components/v1/Request';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import { GET_OFFERS_BU_USER_ID, GET_OFFERS_LIST } from 'query/offers';

import classes from './styles.module.scss';
import { EmptyContent } from 'components/v1/EmptyContent';

// const { offers, total } = this.props.OfferGetter.getOfferList,

export const MyRequestComponent = ({ OfferGetter }) => {
  return (
    <div className={classes.root}>
      {OfferGetter?.getOfferList?.offers.map((request) => (
        <Request key={request._id} isMe {...request} />
      ))}
    </div>
  )
}

export const MyRequest = props => {
  return (
    <Query query={GET_OFFERS_LIST} variables={{ isMy: true, ...props.variables, count: 6 }}>
      {({ loading, error, data, refetch }) => {
        if (error) return 'Loading...';
        if (data?.OfferGetter?.getOfferList?.total === 0) return <EmptyContent text=""/>;
        return (
          <MyRequestComponent
            loading={loading}
            refetch={refetch}
            {...data}
            {...props}
          />
        )
      }}
    </Query>
  )
};


