import React from 'react';

const File = (url, removeFile) => {
  let path = url.split('.'),
      ext = path[path.length-1];
  switch (ext) {
    default:
      return (
        <div key={Math.random().toString(36).substring(7)} style={{
            margin: '15px 5px 0'
        }}>
          <a href={url}>
              <div class="file-no-name"></div>
          </a>
           <button onClick={removeFile}>x</button>
        </div>
      );
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return (
        <button key={Math.random().toString(36).substring(7)} className="button-load-image" onClick={removeFile}>
          <img src={url} alt={url} />
          <span className="button-load-image-close"><i className="panton c">I</i></span>
        </button>
      );
    case 'loading':
      return (
        <button key={Math.random().toString(36).substring(7)} className="button-load-preview">
          <span className="button-load-image-close" />
          <span className="circle-preloader" />
        </button>
      );
  }
};

export default File;
