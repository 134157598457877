export const ErrorHandler = (code, description) => {
    switch (code) {
        //чёрный лист
        case 'USER_ADD_SELF_TO_BLACKLIST':
            return 'Вы не можете добавить сами себя в черный список';
        case 'USER_ADD_NULL_TO_BLACKLIST':
            return 'Пользователя добавляемого в чёрный список не существует';
        case 'USER_RM_SELF_TO_BLACKLIST':
            return 'Вы не можете удалить сами себя из черного списка';
        case 'USER_RM_NULL_TO_BLACKLIST':
            return 'Пользователя удалего из чёрного списка не существует';

        //dialog
        case 'ADD_NULL_TO_DIALOG':
            return 'Пользователя для создания диалога не существует';
        case 'REPEAT_DIALOG':
            return 'Диалог уже существует';
        case 'DIALOG_NOT_FOUND':
            return 'Диалог не найден';
        case 'USER_BANNED_MSG':
            return 'Нельзя отправлять сообщения забаненому пользователю';
        case 'YOU_BANNED_MSG':
            return 'Вы не можете отправлять сообщения этому пользователю';
        case 'MSG_NOT_FOUND':
            return 'Сообщения не найдено';
        case 'EMPTY_MSG':
            return 'Пустое сообщение не может быть отправлено';
        case 'READ_SELF_MSG':
            return 'Вы не можете прочесть свое сообщение';
        case 'READ_READING_MSG':
            return 'Вы не можете прочесть прочитанное сообщение';
        case 'ERROR_READ_MSG':
            return 'Ошибка чтения сообщения';

        // контакты
        case 'USER_ADD_SELF_TO_CONTACTS':
            return 'Вы не можете добавить себя в контакты';
        case 'USER_ADD_NULL_TO_CONTACTS':
            return 'Добавляемый пользователь не найден';
        case 'USER_RM_SELF_TO_CONTACTS':
            return 'Вы не можете удалить себя из контактов';
        case 'USER_RM_NULL_TO_CONTACTS':
            return 'Удаляемый пользователь не найден';
        case 'USER_BANNED':
            return 'Нельзя добавить заблокированного пользователя';
        case 'YOU_BANNED':
            return 'Вы не можете добавить пользователя в контакты тк он вас забанил';
        case 'EMPTY_CONTACTS':
            return 'Контакт не найден'; // (при добавление тегов контакту)

        //offer
        case 'OFFER_USER_INVALID':
            return 'Пользователь не может создать заявку такого типа';
        case 'OFFER_NOT_FOUND':
            return 'Заявка не найдена';
        case 'OFFER_TYPE_INVALID':
            return 'Тип заявки не поддерживаеться системой';
        case 'OFFER_SAVE_ERROR':
            return 'Ошибка сохранения заявки, или данные не менялись, или невалидные';

        //registration
        case 'INVALID_PHONE':
            return 'Некорректный телефон';
        case 'EMPTY_TYPE_KEYS':
            return 'Не выбран тип пользователя';
        case 'INVALID_TYPE_KEY':
            return 'Выбран не верный тип пользователя';
        case 'OLD_SMS_CODE':
            return 'Прошло менее 2 минут с отправки прошлого кода';
        case 'INVALID_SMS_CODE':
            return 'Неверный код из смс';
        case 'NOT_REPEAT_PASS':
            return 'Пароли не совпадают ';
        case 'REPEAT_USER_PHONE':
            return 'Пользователь с таким телефоном уже существует ';

        //profile
        case 'INVALID_STATUS':
            return 'Пользователю запрещено это действие';  // { status: user || admin }
        case 'INVALID_ID':
            return 'Неверный ID пользователя';
        case 'USER_NOT_FOUND':
            return 'Пользователь не найден';
        case 'RECOMMENDED_NOT_FOUND':
            return 'Рекомендованых пользователей не найдено';
        case 'INVALID_PASSWORD':
            return 'Неверный пароль';
        case 'INVALID_ACCESS':
            return 'Ошибка доступа';
        case 'INVALID_ACCOUNT_TYPE':
            return 'Ошибка типа аккаунта';
        case 'USE_REPEAT_PARAMS':
            return 'Email/URL profile заняты';
        case 'USER_SAVE_ERROR':
            return 'Ошибка сохранения пользователя, или данные не менялись, или невалидные';
        case 'INVALID_TOKEN':
            return 'Невалидный токен';
        case 'INVALID_PERSONAL_LINK':
            return 'Некоректный URL адрес профиля';
        case 'INVALID_EMAIL':
            return 'Некоректный Email';


        //Files
        case 'FILE_NOT_FOUND':
            return 'Файл не найден';

        //NEWS
        case 'NEWS_NOT_FOUND':
            return 'Новость не найдена';

        default:
            return description
    }
}