import React from 'react';
import Industry from "components/UI/Forms/GroupFields/Industry";


class IndustryDropDown extends React.Component {
    render() {
        const {initialValues} = this.props;
        return (
            <div className="elements-group elements-group-fix">
                <div className="form fullWidth">
                    <Industry {...initialValues}/>
                </div>
            </div>
        );
    }
}

export default IndustryDropDown;