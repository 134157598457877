import gql from 'graphql-tag';

export const AUTH = gql`
    mutation auth($phone: String!, $password: String!) {
        auth (phone: $phone, password: $password) {
            token
        }
    }
`;

export const SET_AUTH_FLAG = gql`
    mutation isAuth($isAuth: Boolean!) {
        isAuth (isAuth: $isAuth)
    }
`;

export const REGISTRATION = gql`
	mutation registration($client: ClientInput! $captcha: String!) {
		registration (client: $client  captcha: $captcha) {
				token
		}
	}
`;

export const SET_PASSWORD = gql`
    mutation setPassword($password: String! $repeatPassword: String!) {
        setPassword (password: $password repeatPassword: $repeatPassword) {
            firstName
						lastName
						phone
						typeKeys
        }
    }
`;

export const SEND_CODE = gql`
    mutation sendCode($code: CodeInput!) {
        sendCode (code: $code) {
            firstName
            lastName
            phone
            typeKeys
        }
    }
`;


export const SEARCH_USER_BY_FULLNAME = gql`
    query($name: String, $count: Int = 6) {
        SearchGetter {
            searchByFullName(name: $name, count: $count) {
                users {
                    _id
                    firstName
                    lastName
                    avatar
                    typeKeys
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                }
                total
            }
        }
    }
`;


export const SEND_MESSAGE_TECH_SUPPORT = gql`
    mutation($theme: String!, $text: String!) {
        SupportSetter {
            HelpMe(theme: $theme, text: $text)
        }
    }
`;


export const SEND_HAVE_IDEA = gql`
    mutation($name: String!, $phone: String!, $email: String!, $text: String!) {
        SupportSetter {
            iHaveIdea(name: $name, phone: $phone, email: $email, text: $text)
        }
    }
`;

