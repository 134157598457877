import SearchProfileUser from "components/Pages/Admin/SearchProfileUser";
import ProfileUsersListSocial from "./SearchProfileUserSocial";
import SideBlock from "components/UI/SideBlock/index";
import React, { Component } from 'react';
import StatisticUsers from "components/UI/Admin/StatisticUsers";
import SearchStatisticUsers from "components/UI/Admin/SearchStatisticUsers";


class ProfileUsersSocial extends Component {

	state = {
		activeTabIndex: 0,
		search: {},
		searchStatistic: {}
	};


	changeTab = activeTabIndex => this.setState({ activeTabIndex });

	searchFilterUsers = values => {
		this.setState({ search: { ...values } });
	};

	resetSearch = () => {
		this.setState({ search: {} })
	};

	searchFilterUsersStatistic = values => {
		this.setState({ searchStatistic: { ...values } });
	};

	resetSearchStatistic = () => {
		this.setState({ searchStatistic: {} })
	};


	render() {

		const isActiveUser = this.state.activeTabIndex === 0;
		let tabs = [
			{ title: 'Список пользователей', type: 'user_list' }
		];

		return (
			<React.Fragment>
				<div className="centerside">

					<SideBlock tabs={tabs} changeTab={this.changeTab} activeTabIndex={this.state.activeTabIndex}/>
					{
						isActiveUser &&
						<ProfileUsersListSocial variables={this.state.search}/>
					}

					{
						!isActiveUser &&
						<StatisticUsers variables={this.state.searchStatistic}/>
					}

				</div>
				<div className="rightside"/>
			</React.Fragment>
		)
	}
}


export default ProfileUsersSocial;
