import { Avatar } from "components/UI/Avatar/index";
import ContactStatus from "components/UI/ContactStatus/index";
import { dateToString } from "libs/convertDate";
import { formatIdOffer } from "libs/formatNumbers";
import { HIDE_DIALOG_BY_ID } from "mutation/dialog";
import PropTypes from 'prop-types';
import { GET_DIALOGS } from "query/dialogs";
import React, { PureComponent } from 'react';
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";


class DialogItem extends PureComponent {

    static propTypes = {
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatar: PropTypes.string,
        _id: PropTypes.string,
        personalLink: PropTypes.string,
        isContacts: PropTypes.bool,
        onlineStatus: PropTypes.object,
        typeKeys: PropTypes.array,
        offer: PropTypes.object,
        countNewMsg: PropTypes.number
    };

    static defaultProps = {
        input: {},
        meta: {},
        onlineStatus: {},
        offer: {},
        typeKeys: [],
        countNewMsg: 0
    };


    render() {
        const { firstName, lastName, avatar, _id, typeKeys, onlineStatus, lastMsg, offer, countNewMsg, user } = this.props;
        return (
            <li>
                <div className="hover"/>
                <Link to={`/messages/${_id}`}/>
                <Avatar
                    size={70}
                    avatar={avatar}
                />
                <div className="s-contact-info">
                    <div className="contact-name">
                        <i className={`${onlineStatus.isOnline ? 'online' : 'offline'}`}/>
                        <Link className="link-btn" to={`/profile/${user._id}`}>
                            {`${firstName}  ${lastName}`}
                        </Link>
                        <RemoveDialog action={this.props.mutate}/>
                    </div>

                    <ContactStatus className="contact-status" type={typeKeys[0]}/>
                    {lastMsg && <LastMsg msg={lastMsg.text}/>}
                    <div className="right-mes-desc">
                        {offer && <em>Диалог по заявке №{formatIdOffer(offer._id)}, </em>}
                        {lastMsg && <LastMsgTime time={lastMsg.createDate}/>}
                    </div>
                    {countNewMsg > 0 && <Counter count={countNewMsg}/>}
                </div>
            </li>
        );
    }
}


export default props => {
    return (
        <Mutation
            mutation={HIDE_DIALOG_BY_ID}
            refetchQueries={[{ query: GET_DIALOGS }]}
            onCompleted={data => {  }}
        >
            {mutate => (
                <DialogItem
                    {...props}
                    mutate={() => mutate({ variables: { dialogId: props._id } })}
                />
            )}
        </Mutation>
    )
};

const LastMsgTime = ({ time }) => {
    return (
        <span className="time">
            {dateToString(new Date(time), 'time')}
        </span>
    )
};

const LastMsg = ({ msg }) => {
    return (
        <div className="contact-message">
            {msg}
        </div>
    )
}

const Counter = ({ count }) => {
    return (
        <span className="counter">
            <a href="#">+{count}</a>
        </span>
    )
};

const RemoveDialog = ({ action }) => {
    return (
        <span title="Удалить диалог" className="remove_dialog" onClick={action}>
            <img src="/templates/img/close.svg" alt="remove"/>
        </span>
    )
}

