import forwardTo from "libs/forwardTo";
import { CREATE_DIALOG } from "mutation/dialog";
import { client } from '../../../apollo';

export const dialogueChecking = (userIdTo, offerId) => {
  client.mutate({
    variables: { dialog: { userIdTo, offerId } },
    mutation: CREATE_DIALOG
  })
    .then(res => {
      forwardTo(`/messages/${res.data.DialogSetter.createDialog._id}`);
    })
    .catch(error => {
      if (error.graphQLErrors[0].code === "REPEAT_DIALOG") {
        forwardTo(`/messages/${error.graphQLErrors[0].description}`);
      }
    })
};
