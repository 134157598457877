import { gql } from "apollo-boost";

export const ADD_BLACK_LIST = gql`
    mutation ($userId: String!){
        BlackListSetter {
            addToBlackList(userId: $userId) {
                _id
                firstName
                lastName
            }
        }
    }
`;

export const REMOVE_FROM_BLACK_LIST = gql`
    mutation ($userId: String!){
        BlackListSetter {
            removeFromBlackList(userId: $userId) {
                _id
                firstName
                lastName
            }
        }
    }
`;
