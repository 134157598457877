import React, { Component } from 'react';
import Language from "../../../../language/index";

export const StatusRequest = ({ status }) => {
  return (
    <React.Fragment>
      {
        status === 'MODERATION' &&
        <div className="status moderation"><i className="panton c">H</i> {Language.inModeration}</div>
      }
      {
        status === 'ACCEPTED' &&
        <div className="status published"><i className="panton d">s</i> {Language.Published}</div>
      }
      {
        status === 'DECLINED' &&
        <div className="status denied"><i className="panton c">I</i> {Language.Rejected}</div>
      }
    </React.Fragment>
  )
};