import React from 'react';
import { Query } from 'react-apollo';
import { client } from 'apollo';
import { GET_CONTACTS } from 'query/contacts';

import { MyActions, User } from 'components/v1/User';
import { RecommendContacts } from 'components/v1/RecommendContacts';
import { EmptyContent } from 'components/v1/EmptyContent';

import classes from './styles.module.scss';

export const MyContactsComponent = ({ users, typeUser }) => {
  const hasData = users?.getContactList?.total > 0

  return (
    <div className={classes.root}>
      {!hasData && (
        <EmptyContent text='Добавьте людей в список контактов' />
      )}
      {hasData && (
        <div className={classes.users}>
          {users?.getContactList?.contacts.map((contact) => (
            <User
              key={contact.user._id}
              {...contact.user}
              actions={(
                <div className={classes.buttons}>
                  <MyActions {...contact.user} />
                </div>
              )}
            />
          ))}
        </div>
      )}
      <aside>
        <RecommendContacts typeUser={typeUser} />
      </aside>
    </div>
  )
}

export const MyContacts = props => {
  return (
    <Query
      client={client}
      query={GET_CONTACTS}
      variables={{ status: 'is_added', count: 50 }}
    >
      {({ loading, error, data: { ContactGetter } }) => {
        if (error) return 'Error...';
        return (
          <MyContactsComponent
            loading={loading}
            users={ContactGetter}
            {...props}
          />
        )
      }}
    </Query>
  )
};
