import React, { Component } from 'react';

class PreloaderCircle extends Component {
    render() {
        return(
            <div className={this.props.hasOwnProperty('bg') ? 'circle-preloader-bg ' + this.props.bg : 'circle-preloader-bg'}>
                <div className="circle-preloader"> </div>
            </div>
        )
    }
}

export default PreloaderCircle;