import DialogList from "components/UI/Dialog/DialogList";
import FavoritesDialogs from "components/UI/FavoritesDialogs/index";
import PreloaderCircle from "components/UI/Prealoders/PreloaderCircle";
import SideBlock from "components/UI/SideBlock/index";
import Language from "language/index";
import { getItem } from "libs/localStorage";
import { GET_DIALOGS } from "query/dialogs";
import React, { Component, Fragment } from 'react';
import { Query } from "react-apollo";
import { UPDATE_DIALOGS } from "subscription/index";


const RightBlock = () => (
    <div className="_right">
        <div className="checkbox">
            <label>
                <input type="checkbox"/><i/>
                <span>Показать только заявки</span>
            </label>
        </div>
        <button type="button" className="gray-btn s-btn cogs">
            <i className="panton c">i</i>
        </button>
        &nbsp;
        <button type="button" className="gray-btn s-btn">
            <i className="panton d">G</i>
            Сообщение
        </button>
    </div>
);


class Dialogs extends Component {

    componentDidMount() {
        this.props.subscribeToNewComments();
    }

    render() {
        const { DialogGetter, fetchMore } = this.props;
        return (
            <Fragment>
                <div className="centerside">
                    <SideBlock
                        titlePage={Language.myDialogues}
                        // rightBlock={<RightBlock/>}
                    />

                    <DialogList {...DialogGetter} fetchMore={fetchMore}/>


                </div>
                <div className="rightside">
                    <FavoritesDialogs/>
                </div>
            </Fragment>
        )
    }
}


export default props => {
    return (
        <Query query={GET_DIALOGS} fetchPolicy={'cache-and-network'}>
            {({ loading, data, client, error, subscribeToMore, fetchMore }) => {
                if (error) return <p> Error.... </p>;
                if (!data.DialogGetter && loading) return <PreloaderCircle/>;
                return <Dialogs
                    {...props}
                    {...data}
                    fetchMore={fetchMore}
                    subscribeToNewComments={() => subscribe(subscribeToMore)}
                />
            }}
        </Query>
    )
};


const subscribe = (subscribeToMore) => {
    const token = getItem('token', null);
    subscribeToMore({
        document: UPDATE_DIALOGS,
        variables: { token }
    })
};