import React from 'react'
import { Button } from 'components/v1/Button';

import { TextareaController } from 'components/v1/HookFields/Textarea';

import styles from './styles.module.scss'

export const AboutForm = ({ control }) => {

  return (
    <>
      <div className={styles.section}>
        <div>О себе</div>
        <TextareaController
          control={control}
          name="description"
        />
      </div>
      {/*<div className={styles.section}>*/}
      {/*  <div>Деятельность</div>*/}
      {/*  <TextareaController*/}
      {/*    control={control}*/}
      {/*    name="industryDetail"*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  )
}
