import Input from 'components/UI/Fields/Input';
import { submitSetPasswordForm } from "components/UI/Forms/SetPasswordForm/submit";
import { getItem } from "libs/localStorage";
import { SET_PASSWORD } from "mutation/index";
import React, { Component } from 'react';
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from 'redux-form';
import Language from "language/index";
import { ActionNotification } from "components/UI/Notifications/action";


class SetPasswordForm extends Component {

    state = { className: 'r-window' };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ className: 'r-window active' })
        }, 10)
    }

    render() {
        const { handleSubmit } = this.props;
        const userLogin = getItem('phone', null);
        return (
            <div className={this.state.className}>
                <div className="flex">
                    <div>
                        <article>{Language.createPasswordLong}</article>
                        <p>{Language.securePassword}</p>
                    </div>
                    <div>
                        <div className="recovery-title">{Language.createPassword}</div>
                        <div className="form-pass-recovery">
                            <form onSubmit={handleSubmit}>
                                <div className="element">
                                    <strong> {Language.yourLogin}:
                                        <span className="user-login"> {userLogin} </span>
                                    </strong>
                                </div>
                                <Field
                                    name="password"
                                    type="password"
                                    component={Input}
                                    strong={Language.Password}
                                />
                                <Field
                                    name="repeatPassword"
                                    type="password"
                                    component={Input}
                                    strong={Language.confirmPassword}
                                />
                                <button type="submit" className="orange-btn">{Language.save}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const SetPasswordFormRedux = reduxForm({
    form: 'setPassword',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('setPassword'));
        }
    }
})(SetPasswordForm);

export default props => (
    <Mutation
        mutation={props.mutate}
        onCompleted={() => props.nextStep ? props.nextStep() : {}}
    >
        {mutate => (
            <SetPasswordFormRedux
                {...props}
                onSubmit={values => props.submit ? props.submit(values, mutate) : submitSetPasswordForm(values, mutate)}
            />
        )}
    </Mutation>
)