import React from 'react';
import { Query } from 'react-apollo';

import { GET_RECCOMEND } from 'query';
import { User } from 'components/v1/User';
import Loading from 'components/UI/Prealoders/Loading';

import classes from './styles.module.scss';
import { AddUserIcon } from 'components/v1/Icons/AddUserIcon';

export const RecommendContactsComponent = ({ users }) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AddUserIcon />
        Вас могут заинтересовать
      </div>
      <div className={classes.users}>
        {users.map((user, i) => (
          <User
            size="small"
            key={user._id + i}
            {...user}
          />
        ))}
      </div>
    </div>
  )
}

export const RecommendContacts = props => {
  return (
    <Query
      query={GET_RECCOMEND}
      variables={{ userType: props.typeUser }}
    >
      {({ loading, error, data }) => {
        if (error) return 'Error...';
        if (loading) return <Loading/>;

        let result = (data.UserGetter.getRecommendedOffers || [])
          .map((el) => ({ ...el.userId, name: el.name || el.title })) || []
        result = result.length > 0 ? result.slice(0, 6) : result

        return (
          <RecommendContactsComponent
            refetchQuery={{
              query: GET_RECCOMEND,
              variables: { userType: props.typeUser }
            }}
            isNew
            users={result}
          />
        )
      }}
    </Query>
  )
};
