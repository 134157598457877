import React from 'react'
import cn from 'classnames';

import { NewsIcon } from 'components/v1/Icons/NewsIcon'

import styles from './styles.module.scss'

export const EmptyContent = ({ text = 'Опубликуйте новость или событие', className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <NewsIcon width={42} height={42} />
      <h3>Здесь сейчас пусто</h3>
      <p>{text}</p>
    </div>
  )
}
