import gql from 'graphql-tag';

export const ADD_CONTACT = gql`
    mutation addContact($contact: ContactInput) {
        ContactSetter {
            addContact (contact: $contact) {
               user {
                   _id
                   firstName
                   lastName
                   avatar
                   typeKeys
                  
                   isContacts
                   isLockedUser
                   isLockedYou
                   address {
                       countryId
                       stateId
                       cityId
                   }
                   onlineStatus {
                       isOnline
                       lastTime
                   }
                   __typename
               }
                status
                tags
            }
        }
    }
`;

export const DELETE_CONTACTS = gql`
    mutation removeContact($userId: String!) {
        ContactSetter {
            removeContact(userId: $userId) {
                user {
                    _id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const SET_CONTACT_TAG = gql`
    mutation ($userId: String!, $tags: [String]){
        ContactSetter {
            setContactTags(userId: $userId, tags: $tags) {
               user {
                   _id
                  
               }
                tags
            }
        }
    }
`;
