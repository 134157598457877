import React, { Component } from 'react';
import SearchBlock from "./parts/SearchBlock";
import HelpBlock from "./parts/HelpBlock";

class CenterSide extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return(
      <div className="centerside">
        <SearchBlock/>

        {
          this.props.questions.map(item => {
            return(
              <React.Fragment key={item.title}>
                <HelpBlock block={item}/>
              </React.Fragment>
            );
          })
        }

      </div>
    );
  }
}

export default CenterSide;