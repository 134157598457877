import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { GET_AUTH } from 'query';

import Logo from 'components/UI/Header/Logo'
import { Button } from 'components/v1/Button';
import { Badge } from 'components/v1/Badge';
import { NotificationIcon } from 'components/v1/Icons/NotificationIcon';
import { ProfileMenu } from 'components/v1/Header/ProfileMenu';
import Notifications from 'components/UI/Notifications';

import forwardTo, { replaceTo } from 'libs/forwardTo';
import { getItem } from 'libs/localStorage';
import { Search } from './Search';

import styles from './styles.module.scss'
import { useSizeXS } from 'libs/useMediaQuery';

const nav = [
  { href: '/about', label: 'О проекте' },
  { href: '/terms', label: 'Правила и документы' },
  { href: '/help', label: 'Помощь' },
]

const Header = ({ isAuth, location }) => {

  const isMobile = useSizeXS()

  const lang = getItem('language', 'ru')

  const goToHome = () => {
    forwardTo('/auth')
  }

  useEffect(() => {
    if (isAuth && (location.pathname === '/auth' || location.pathname === '/' || location.pathname === '' || location.pathname === '/recovery' )) {
      replaceTo(isMobile ? '/menu' : '/profile/me')
    }
  }, [isAuth, location, isMobile])

  return (
    <>
      <Notifications/>
      <div className={styles.header}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo auth={isAuth} />
          </div>

          {isAuth ? (
            <>
              {/*<div className={styles.searchBox}>*/}
                <Search />
              {/*</div>*/}
              <div className={styles.rightPart}>
                {/*<div className={styles.notifications}>*/}
                {/*  <Badge count={4}>*/}
                {/*    <NotificationIcon />*/}
                {/*  </Badge>*/}
                {/*</div>*/}
                <ProfileMenu />
              </div>
            </>
          ) : (
            <>
              <nav className={styles.nav}>
                <ul>
                  {nav.map((el) => (
                    <li key={el.href}>
                      <Link to={el.href}>{el.label}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className={styles.authBox}>
                <Button onClick={goToHome}>Войти</Button>
                {/*<button className={styles.lang}>{lang}</button>*/}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default withRouter(props => {
  return (
    <Query query={GET_AUTH} fetchPolicy="cache-only">
      {({ data }) => {
        return (
          <Header {...data} {...props}/>
        )
      }}
    </Query>
  )
});
