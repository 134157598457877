import { client } from '../apollo';
import { GET_CONTACTS } from "query/contacts";

export const addContactWithContactList = contact => {
  const queryContacts = { query: GET_CONTACTS, variables: { status: 'is_added', count: 50 } };
  let data = client.readQuery(queryContacts);
  data.ContactGetter.getContactList.contacts.push(contact);
  data.ContactGetter.getContactList.total = data.ContactGetter.getContactList.total + 1;
  client.writeQuery({
    ...queryContacts,
    data
  });
};

export const removeContact = (cache, { data: { ContactSetter } }, type) => {
  // let { ContactGetter } = client.readQuery({ query: GET_CONTACTS, variables: { status: type, count: 50 } });
  // const { _id } = ContactSetter.removeContact.user;
  //
  // client.writeQuery({
  //   query: GET_CONTACTS,
  //   variables: { status: type, count: 50 },
  //   data: {
  //     ContactGetter: {
  //       getContactList: {
  //         contacts: [
  //           ...ContactGetter.getContactList.contacts.filter(el => el.user._id !== _id)
  //         ],
  //         total: parseInt(ContactGetter.getContactList.total) - 1,
  //         __typename: "MyContacts"
  //       },
  //       __typename: "ContactGetter"
  //     }
  //   },
  // });


};
