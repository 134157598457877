import React from 'react';
import Language from "language/index";


const RightControlBlock = ({ actionCreate, actionSearch }) => {
    return (
        <div className="_right" style={{ paddingBottom: '13px' }}>
            <button
                onClick={actionCreate}
                type="button"
                className="gray-btn s-btn new-request-js active">
                {Language.createNewRequest}
            </button>
            {/*<button type="button" onClick={actionSearch} className="search-js"/>*/}
        </div>
    )
};

export default RightControlBlock;