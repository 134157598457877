import React, { Component } from 'react';
import Language  from "language/index";

class RightSide extends Component {

  render() {
    return (
      <div className="rightside">
        <div className="fixed-help-block">
          <div className="side-block ">
            <div className="sb-title">
              <div className="element clearfix">
                <em>{Language.Chapters}</em>
              </div>
            </div>
            <ul className="blue-links">
              <li><a href='#GENERALTERMS'> {Language.GENERALTERMS} </a> </li>
              <li><a href='#DEFINITIONOFTERMS'> {Language.DEFINITIONOFTERMS} </a> </li>
              <li><a href='#INFORMATIONABOUTUSERS'> {Language.INFORMATIONABOUTUSERS} </a> </li>
              <li><a href='#PURPOSEPROCESSINGPERSONAL'> {Language.PURPOSEPROCESSINGPERSONAL} </a> </li>
              <li><a href='#PROCESSINGUSERPERSONAL'> {Language.PROCESSINGUSERPERSONAL} </a> </li>
              <li><a href='#RIGHTSOBLIGATIONSUSERS'> {Language.RIGHTSOBLIGATIONSUSERS} </a> </li>
              <li><a href='#OBLIGATIONSSITEADMINISTRATION'> {Language.OBLIGATIONSSITEADMINISTRATION} </a> </li>
              <li><a href='#MEASURESPROTECTUSER'> {Language.MEASURESPROTECTUSER} </a> </li>
              <li><a href='#RESPONSIBILITYPARTIES'> {Language.RESPONSIBILITYPARTIES} </a> </li>
              <li><a href='#ADDITIONALCONDITIONS'> {Language.ADDITIONALCONDITIONS} </a> </li>
            </ul>
          </div>

          <div className="side-block ">
            <ul className="blue-links">
              <li><a target="_blank" href='/cookie'> {Language.TermsUseCookies} </a> </li>
            </ul>
          </div>

          <div className="side-block ">
            <ul className="blue-links">
              <li><a target="_blank" href='/terms'> {Language.TermsOfUser} </a> </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default RightSide;