// import DataFormat from "../../parts/date-format";
import {
    GetCurrencyValue,
    GetDeliveryValue,
    GetExperienceValue,
    GetImplementedValue,
    GetIndustry,
    GetPeriodValue,
    GetSalaryValue
} from "libs/getApplicationFields";
import { GetLabel } from "libs/getLabel";
import MappingLocation from "libs/MappingLocation";
import React from 'react';


class AppContainer extends React.Component {

    getLabel = (key, type) => {
        return GetLabel[type][key] ? `${GetLabel[type][key]}: ` : null;
    };

    getValue = value => {
        if (typeof(value) === 'object') {
            return this.getField(value, 'child');
        }
        return value;
    };

    getField = (obj, rang, type = null) => {
        let fields = [];
        let value = '';
        for (let key in obj) {
            let disableKey =
                key !== 'typeOffer' &&
                key !== '__typename' &&
                key !== 'user' &&
                key !== '_id' &&
                key !== 'type' &&
                key !== 'created' &&
                key !== 'status' &&
                key !== 'paymentType' &&
                key !== 'image' &&
                key !== 'variableUnit' &&
                key !== 'isNetwork' &&
                key !== 'numberOffer' &&
                key !== 'createDate';

            if (obj[key] && disableKey) {
                switch (key) {
                    case 'industry':
                        if (obj[key].groupName !== null) {
                            value = GetIndustry(obj[key]);
                        } else {
                            value = null;
                        }
                        break;
                    case 'expectedReturn':
                    case 'cost':
                    case 'profitability':
                    case 'investmentVolume':
                        value = GetCurrencyValue(obj[key]);
                        break;
                    case 'paybackPeriod':
                        value = <GetPeriodValue periodObj={obj[key]}/>;
                        break;
                    case 'workExperience':
                        if (typeof(obj[key]) === 'object') {
                            value = <GetPeriodValue periodObj={obj[key]}/>;
                        } else {
                            value = obj[key];
                        }
                        break;
                    case 'sharedParticipation':
                        value = `${obj[key]} %`;
                        break;
                        // case 'startTime':
                        //     value = <DataFormat dateTime={obj[key]} format={1}/>;
                        //     break;
                        // case 'endTime':
                        //     value = <DataFormat dateTime={obj[key]} format={1}/>;
                        break;
                    case 'investmentTerm':
                        value = <GetPeriodValue periodObj={obj[key]}/>;
                        break;
                    case 'deliveryTime':
                        value = <GetPeriodValue periodObj={obj[key]}/>;
                        break;
                    case 'maximumDeliveryVolume':
                        value = GetDeliveryValue({
                            maximumDeliveryVolume: obj[key].count,
                            variableUnit: obj[key].typeVolume
                        });
                        break;
                    case 'address':
                        value = <MappingLocation location={obj[key]}/>;
                        break;
                    case 'investmentExperience':
                        value = GetExperienceValue(obj[key]);
                        break;
                    case 'salary':
                        value = GetSalaryValue({
                            salary: obj[key],
                            paymentType: obj.paymentType,
                        });
                        break;
                    case 'softCap':
                        value = `${obj.softCap.amount} ${obj.softCap.currency}`;
                        break;
                    case 'hardCap':
                        value = `${obj.hardCap.amount} ${obj.hardCap.currency}`;
                        break;
                    case 'isImplemented':
                    case 'isBusinessOwner':
                        value = GetImplementedValue(obj[key]);
                        break;
                    case 'additionallyInform':
                        value = `${obj[key]}`;
                        break;
                    default:
                        value = this.getValue(obj[key], key);
                        break;
                }

                if (value !== null) {
                    if (this.props.layout === 'table-layout') {
                        fields.push(
                            <tr className={`type-${obj.type} ${key}`} key={key}>
                                <td>
                                    <span>
                                        {
                                            rang === 'parent'
                                            && this.getLabel(key, type)
                                        }
                                    </span>
                                </td>
                                {
                                    typeof(value) === 'string' &&
                                    <td dangerouslySetInnerHTML={{ __html: value }}/>
                                }
                                {
                                    typeof(value) !== 'string' &&
                                    <td>
                                        {value}
                                    </td>
                                }

                            </tr>
                        );
                    } else {
                        fields.push(
                            <div className={`row type-${obj.type} ${key}`} key={key}>
                                <div className="colLeft">
                                    <b className="fontOpenSans">
                                        {
                                            rang === 'parent'
                                            && this.getLabel(key, type)
                                        }
                                    </b>
                                </div>
                                <div className="colRight">
                                    {value}
                                </div>
                            </div>
                        );
                    }
                }
            }
        }
        return fields;
    };

    render() {
        let obj = this.props.data,
            fields = this.getField(obj, 'parent', obj.typeOffer);

        return (
            <React.Fragment>
                {fields}
            </React.Fragment>
        );
    }

}


export default AppContainer;
