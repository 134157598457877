import { required } from "libs/fieldValidation";
import showError from "libs/showFormErrors";
import { ActionNotification } from "../../../Notifications/action";
import Language from "language/index";

export const submitBussinesManForm = (values, mutate, cb) => {

    const { description, title, industry, address, profitability } = values;

    showError({
        'description': [required(description)],
        'title': [required(title)],
        'industry.groupName': [required(industry)],
        'address.countryId': [required(address)],
        'profitability.amount': [required(profitability)]
    });


    const fields = ['_id', 'additionallyInform', 'description', 'address', 'title', 'industry', 'profitability'];
    const value = { ...values };

    for (let key in value) {
        if (value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
        if (!fields.some(el => el === key)) {
            delete value[key];
        } else if (value[key].hasOwnProperty('amount')) {
            value[key].amount = parseInt(value[key].amount);
        }
    }

    mutate({ variables: { offer: { ...value } } })
        .then(res => {
            if (cb) {
                cb();
                ActionNotification('2', Language.applicationChanged);
            } else {
                ActionNotification('2', Language.applicationSuccessfullySent);
            }
        })
        .catch(err => {
            if (err.hasOwnProperty('graphQLErrors') && err.graphQLErrors[0].hasOwnProperty('code') && err.graphQLErrors[0].hasOwnProperty('description')) {
                ActionNotification('1', err.graphQLErrors[0].description);
            } else {
                ActionNotification('1', "Произошла ошибка");
            }
        })
};