import gql from 'graphql-tag';

export const WATCH_NEWS = gql`
    mutation ($newsId: String){
        NewsSetter {
            watchNews(newsId: $newsId) {
                ... on NewsUser {
                    _id
                }

                ...on NewsContact {
                    _id
                }

                ... on NewsOffer {
                    _id
                }

                ... on NewsSystem {
                    _id
                }
            }
        }
    }
`;

export const ADD_NEWS = gql`
    mutation ($news: UserNewsInput) {
        UserNewsSetter {
            addNews(news: $news) {
                _id
                __typename
                createDate
            }
        }
    }
`

export const ADD_COMMENT = gql
    `mutation ($newsId: String!, $content: String!) {
        UserNewsSetter {
            addNewsComment(newsId: $newsId, content: $content) {
                _id
                message
            }
        }
    }`

export const DELETE_NEWS = gql
    `mutation ($newsId: String!) {
        UserNewsSetter {
            deleteNews(newsId: $newsId)
        }
    }`

export const RESTORE_NEWS = gql
        `mutation ($newsId: String!) {
        UserNewsSetter {
            restoreNews(newsId: $newsId)
        }
    }`

export const ADD_LIKE = gql
        `mutation ($newsId: String!) {
        NewsSetter {
            likeNews(newsId: $newsId) {
                ... on NewsUser {
                    _id
                }

                ...on NewsContact {
                    _id
                }

                ... on NewsOffer {
                    _id
                }

                ... on NewsSystem {
                    _id
                }
            }
        }
    }`
