import React from 'react'
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { client } from 'apollo';
import Language from 'language';

import { UserTypeBadge } from 'components/v1/UserTypeBadge';
import { Avatar } from 'components/v1/Avatar';
import { SettingsIcon } from 'components/v1/Icons/SettingsIcon';
import { HelpIcon } from 'components/v1/Icons/HelpIcon';
import { LogoutIcon } from 'components/v1/Icons/LogoutIcon';

import { GET_MY_PROFILE } from 'query';

import { userTypesClassName } from 'libs/uaerTypes';
import { removeItem } from 'libs/localStorage';
import forwardTo from 'libs/forwardTo';

import styles from './styles.module.scss'
import { EditIcon } from 'components/v1/Icons/EditIcon';
import { logout } from 'libs/logout';

const Menu = ({ getMyProfile }) => {

  const nav = [
    { href: '/profile/edit', label: 'Редактировать профиль', icon: <EditIcon /> },
    { href: '/profile/settings', label: 'Настройки', icon: <SettingsIcon /> },
    { href: '/help', label: 'Помощь', icon: <HelpIcon /> },
    { onClick: logout, label: Language.exit, icon: <LogoutIcon /> },
  ]

  const { firstName, lastName, avatar, phone } = getMyProfile

  return (
    <div className={styles.profile}>
      <div className={styles.containerAvatar}>
        <Avatar size={'circle'} source={avatar} className={styles.avatar} />
      </div>
      <div className={styles.dropdown}>
        <Link to={'/profile/me'}>
          <div className={styles.preview}>
            <div>
              <Avatar source={avatar} size={'circle'} className={styles.avatar} />
            </div>
            <div className={styles.data}>
              <div className={styles.username}>{firstName} {lastName}</div>
              <div className={styles.phone}>{phone}</div>
            </div>
          </div>
          {Array.isArray(getMyProfile.typeKeys) && getMyProfile.typeKeys.length > 0 && (
            <UserTypeBadge streched name={Language[userTypesClassName(getMyProfile.typeKeys[0])]} />
          )}
          <div className="separator separator-mini" />
        </Link>
        <ul>
          {nav.map((el) => {
            if (el.hasOwnProperty('onClick')) {
              return (
                <li key={el.label} onClick={el.onClick} className={styles.item}>
                  {el.icon}
                  {el.label}
                </li>
              )}

            return (
              <li key={el.label} className={styles.item}>
                <Link to={el.href}>
                  {el.icon}
                  {el.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export const ProfileMenu = (props) => {
  return (
    <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
      {({ data, loading, error }) => {
        if (error || loading) return 'Loading...';
        if (data.ProfileGetter) {
          return <Menu {...data.ProfileGetter} />
        }
      }}
    </Query>
  )
}
