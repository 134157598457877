import Guests from "components/Pages/Profile/Guests";
import MyContacts from "components/Pages/Profile/MyContacts";
import ReccommendUserByUserTypes from "components/Pages/Profile/ReccommendUserByUserTypes";
import ReccommendUsers from "components/Pages/Profile/ReccommendUsers";
import SearchUser from "components/Pages/Profile/SearchUser";
import SearchUserList from 'components/Pages/Profile/SearchUserList';
import News from "components/UI/News/index";
import AboutProfile from "components/UI/Profile/AboutProfile";
import UserDescription from "components/UI/Profile/UserDescription";
import SideBlock from "components/UI/SideBlock/index";
import { required } from "libs/fieldValidation";
import { showError } from "libs/showFormsError";
import { reccomendType, userTypeDesc } from "libs/uaerTypes";
import { GET_MY_PROFILE } from "query/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import SbContactsList from 'components/UI/SbContacts/SbContactsList';

const ProfileImage = props => {
  return (
    <div className="img">
      <img
        src={props.avatar || '/templates/img/uploader_default.jpg'}
        alt="..."
      />
    </div>
  )
};


class Profile extends Component {

  state = {
    tabs: [],
    activeTab: 0,
    variables: {},
  };

  changeTab = index => {
    this.setState({ activeTab: index });
  };

  search = values => {
    const { typeKeys } = values;

    // showError({
    //   'typeKeys': [required(typeKeys)],
    // });

    if (values.yearBegin) values.yearBegin = parseInt(values.yearBegin);
    if (values.yearEnd) values.yearEnd = parseInt(values.yearEnd);
    console.log(values);
    const variables = values;
    variables.count = 30;
    this.setState({ variables })
  };

  componentWillMount() {
    const myType = this.props.getMyProfile.typeKeys[0];
    let tabs = reccomendType(myType).map(el => ({ title: userTypeDesc(el), type: el }));
    this.setState({ tabs });
  }

  resetSearch = () => {
    this.setState({ variables: {} })
  }

  render() {
    const { getMyProfile } = this.props;
    return (
      <React.Fragment>
        <div className="centerside">
          <SideBlock>
            <div className="user-main-info clearfix">
              <ProfileImage avatar={getMyProfile.avatar}/>
              <UserDescription {...getMyProfile}/>
            </div>
          </SideBlock>
          {Object.keys(this.state.variables).length !== 0 && (
            <SearchUserList
              variables={this.state.variables}
            />
          )}
          <ReccommendUserByUserTypes
            typeUser={getMyProfile.typeKeys[0]}
            tabs={this.state.tabs}
            changeTab={this.changeTab}
            activeTab={this.state.activeTab}
          />
          {/*<ReccommendUsers*/}
          {/*  userType={getMyProfile.typeKeys[0]}*/}
          {/*/>*/}
          <MyContacts />


        </div>
        <div className="rightside">
          <AboutProfile
            description={getMyProfile.description}
          />
          <SearchUser
            onSubmit={this.search}
            resetSearch={this.resetSearch}
          />
          <News/>
          <Guests/>
        </div>
      </React.Fragment>
    )
  }
}


export default props => {
  return (
    <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
      {({ data, loading, error }) => {
        if (error || loading) return 'Loading...';
        if (data.ProfileGetter) {
          return <Profile {...props} {...data.ProfileGetter}/>
        } else return 'Loading...'
      }}
    </Query>
  );
}
