import React, { Component } from 'react';
import RightSide from "./right";
import CenterSide from "./center";
import styles from 'pages/News/styles.module.scss';


class PrivacyPolicy extends Component {

  render() {
    return (
      <div className={styles.container}>
        <div className="content">
          <CenterSide/>
        </div>
        <aside>
          <div className="content">
            <RightSide />
          </div>
        </aside>
      </div>
    );
  }
}

export default PrivacyPolicy;
