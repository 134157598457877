import React, { Component } from 'react';
import Language  from "language/index";
import Scrollchor from 'react-scrollchor';

class RightSide extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="rightside">
        <div className="fixed-help-block">
          <div className="side-block">
            <ul className="blue-links">
              <li><Scrollchor to='#StatusOfTheTerms'> {Language.StatusOfTermsUse} </Scrollchor> </li>
              <li><Scrollchor to='#NewnextSiteStatus'> {Language.NewnextSiteStatus} </Scrollchor> </li>
              <li><Scrollchor to='#NewnextSiteAdministration'> {Language.NewnextSiteAdministration} </Scrollchor> </li>
              <li><Scrollchor to='#RegistrationNewnext'> {Language.RegistrationOnTheNewnextUser} </Scrollchor> </li>
              <li><Scrollchor to='#ObligationsUserWebsite'> {Language.ObligationsTheUser} </Scrollchor> </li>
              <li><Scrollchor to='#IntellectualProperty'> {Language.IntellectualProperty} </Scrollchor> </li>
              <li><Scrollchor to='#OpertaionNewnextSite'> {Language.OpertaionNewnextSite} </Scrollchor> </li>
              <li><Scrollchor to='#FinalProvision'> {Language.FinalProvision} </Scrollchor> </li>
            </ul>
          </div>

          <div className="side-block ">
            <ul className="blue-links">
              <li><a target="_blank" href='/cookie'> {Language.TermsUseCookies} </a> </li>
            </ul>
          </div>

          <div className="side-block ">
            <ul className="blue-links">

              <li><a target="_blank" href='/privacy'> {Language.UsersPersonalPrivacy} </a> </li>
            </ul>
          </div>
        </div>





      </div>
    );
  }
}

export default RightSide;
