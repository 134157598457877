import DialogItem from "components/UI/Dialog/DialogItem";
import NotFound from "components/UI/NotFound/index";
import SideBlock from "components/UI/SideBlock/index";
import { compareDate, getMessageDateString } from "libs/getMessageTime";
import PropTypes from 'prop-types';
import { GET_MY_ID } from "query/profile";
import React, { Component, Fragment } from 'react';
import { Query } from "react-apollo";

const Day = ({ day }) => {
    return (
        <div className="day">
            <span>{day}</span>
        </div>
    )
};


class DialogList extends Component {

    static propTypes = {
        getMyDialogs: PropTypes.object,
        getMyProfile: PropTypes.object,
    };
    static defaultProps = {
        getMyDialogs: { dialogs: [] },
        getMyProfile: {}
    };

    state = {
        page: 1,
        loading: false
    };



    render() {
        const { getMyProfile, getMyDialogs } = this.props,
            isDialogs = getMyDialogs.dialogs.length > 0;

        return (
            <Fragment>
                {
                    isDialogs &&

                    <SideBlock>
                            <div className="s-messages">
                                <ul className="mes-list">

                                    {
                                        getMyDialogs.dialogs.map((el, index) => {
                                            const { userFrom, userTo } = el;
                                            let user = userFrom._id !== getMyProfile._id ? userFrom : userTo;
                                            let prevDialog = index > 0 ? getMyDialogs.dialogs[index - 1] : false;
                                            let day = (index === 0 && el.lastMsg) || (prevDialog.lastMsg && el.lastMsg && !compareDate(el.lastMsg.createDate, prevDialog.lastMsg.createDate));
                                            return (
                                                <Fragment key={index}>
                                                    {day && <Day day={getMessageDateString(el.lastMsg.createDate)}/>}
                                                    <DialogItem {...user} user={user} _id={el._id} offer={el.offer}
                                                                countNewMsg={el.countNewMsg}
                                                                lastMsg={el.lastMsg}/>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                    </SideBlock>

                }
                {
                    !isDialogs &&
                    <NotFound title="У вас нет активных диалогов" img="/templates/img/chat-11.svg">
                        Написать сообщение пользователю бизнес-сети newnext можно, перейдя на страницу пользователя и
                        нажав клавишу «Написать сообщение», расположенную под аватаром.
                        <br/><br/>
                        Обмен сообщениями в newnext позволяет вести личную переписку с другими участниками бизнес-сети,
                        а также находить новые возможности для решения ваших бизнес задач.
                    </NotFound>
                }


            </Fragment>
        );
    }
}


export default props => (
    <React.Fragment>
        <Query query={GET_MY_ID} fetchPolicy="cache-only">
            {({ data: { ProfileGetter }, error }) => {
                if (error) return <p> Error.... </p>;
                return <DialogList {...ProfileGetter} {...props}/>
            }}
        </Query>
    </React.Fragment>
);