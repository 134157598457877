import { isPerfections, required } from "libs/fieldValidation";
import showError, { showErrorReg } from "libs/showFormErrors";
import { emails, lastnames, name } from "libs/validationsForms";
import ym from "react-yandex-metrika";

export const submitProfileIndividualForm = (values, mutate, cb) => {

    const { firstName, lastName, birthday, sex, email, address, educations, career } = values;

    let validateObj = {
        'firstName': [required(firstName)],
        'lastName': [required(lastName)],
        'birthday': [required(birthday)],
        'sex': [required(sex)],
        'address.countryId': [required(address)],
        'email': [required(email)],
    };

    if (email)  validateObj['email'] = [emails(email)];

    if (address) {
        validateObj['address.countryId'] = [required(address.countryId)];
        validateObj['address.stateId'] = [required(address.stateId)];
        validateObj['address.cityId'] = [required(address.cityId)];
    }

    if (educations && educations[0]) {
        validateObj['educations.0.institutionName'] = [required(educations[0].institutionName)];
        validateObj['educations.0.completeDate'] = [required(educations[0].completeDate)];
        validateObj['educations.0.specialty'] = [required(educations[0].specialty)];
    }

    if (career && career[0]) {
        validateObj['career.0.jobName'] = [required(career[0].jobName)];
        validateObj['career.0.beginDate'] = [required(career[0].beginDate)];
        validateObj['career.0.position'] = [required(career[0].position)];
    }

    if (firstName) validateObj['firstName'] = [name(firstName)];
    if (lastName) validateObj['lastName'] = [lastnames(lastName)];

    if (birthday) {
        validateObj['birthday'] = [isPerfections(birthday)];
    }

    if (showErrorReg(validateObj) && values.__typename === 'Client') {
        ym('reachGoal', 'regerror')
    }

    showError(validateObj);

    const fields = [
        'firstName', 'lastName', 'midName',
        'birthday', 'email', 'sex', 'address',
        'description', 'personalLink', '_id', 'educations',
        'career', 'industry', 'maritalStatus', 'hobby'
    ];

    const value = { ...values };

    for (let key in value) {
        if (Array.isArray(value[key]) && value[key][0] instanceof Object && value[key][0].hasOwnProperty('__typename')) {
            delete value[key][0].__typename;
        }

        if ((key === 'educations' || key === 'career') && !value[key][0]) {
            delete value[key]
        }

        if (!!value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;

        if (!fields.some(el => el === key)) {
            delete value[key];
        } else if (!!value[key] && value[key].hasOwnProperty('count')) {
            value[key].count = parseInt(value[key].count);
        } else if (key === 'industry' && value[key].groupName === null) {
            delete value[key]
        }
    }

    mutate({ variables: { profile: { ...value } } })
};