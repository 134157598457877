import { required } from "libs/fieldValidation";
import { showError } from "libs/showFormsError";

export const submitPhoneVerificationForm = (values, mutate) => {
    let { code } = values;

    showError({
        code: [required(code)],
    });

    mutate({ variables: { code: { code } } })
};