import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export const UserTypeBadge = ({ name, streched }) => {

  return (
    <div className={cn(styles.container, { [styles.streched]: streched })}>
      {name}
    </div>
  )
}
