
import React, { Fragment, useEffect } from 'react';
import { Mutation } from "react-apollo";
import { READ_MSG } from "mutation/dialog";
import { GET_MESSAGES } from "query/messages";
import { compareDate } from "libs/getMessageTime";
import { MessagesItem } from './MessagesItem';
import { Day } from './Day';

export const MessagesListComponent = props => {
  const { messages, userFrom, userTo, offer, dialogId } = props;
  const newArrayMessages = [...messages].reverse();


  const scrollToBottom = () => {
    let el = document.querySelector('#scrollable_messages');
    if (document.querySelector('#scroll_block_messages')) {
      let height = document.querySelector('#scroll_block_messages').offsetHeight;
      el.scrollTo(0, height + 200);
    }
  };

  useEffect(() => {
    props.subscribeToNewComments();
    setTimeout(() => scrollToBottom(), 80)
  }, [])

  useEffect(() => {
    setTimeout(() => scrollToBottom(), 80)
  }, [props.messages?.length])

  return (
    <div>
      {newArrayMessages.map((message, index) => {
        let isNeedRead = false;
        if (message.senderId !== props._id && !message.isRead) { isNeedRead = true; }
        const prevMess = index !== 0 ? newArrayMessages[index - 1] : false;
        const isDay = !prevMess || (prevMess && !compareDate(message.createDate, prevMess.createDate));
        return (
          <Fragment key={index}>
            {isDay && <Day createDate={message.createDate} />}
            <MessagesItem
              {...message}
              prevMess={prevMess}
              userFrom={userFrom}
              userTo={userTo}
              offer={offer}
              _id={props._id}
              isRead={message.isRead}
              readMessage={
                isNeedRead ?
                  () => props.mutate({ variables: { dialogId, msgId: message._id } }) :
                  () => {}
              }
            />
            <br/><br/>
          </Fragment>
        )
      })}
    </div>
  )
}


export const MessagesList = props => {
  return (
    <Mutation
      mutation={READ_MSG}
      update={(cache, { data: { DialogSetter } }) => updateReadMsg(cache, { data: { DialogSetter } }, props.dialogId)}>
      {mutate => {
        return (
          <MessagesListComponent mutate={mutate} {...props}/>
        )
      }}
    </Mutation>

  )
}


const updateReadMsg = (cache, { data: { DialogSetter } }, dialogId) => {
  let { DialogGetter } = cache.readQuery({ query: GET_MESSAGES, variables: { "dialogId": dialogId, count: 50 } });

  DialogGetter.getMessagesByDialogId.messages.map(el => {
    if (DialogSetter.readMsg._id === el._id) {
      el.isRead = true
    }
  });


  cache.writeQuery({
    query: GET_MESSAGES,
    variables: { "dialogId": dialogId, count: 50 },
    data: { DialogGetter },
  });
};
