import { history } from "components/../App";


export default function forwardTo(location,params) {
	history.push(location, params);
}

export function replaceTo(location, params) {
	history.replace(location, params);
}

export function goBack() {
	history.goBack();
}

export function clearHistory() {
// 	history[0] = history[history.length - 1]
// 	history.length = 1
}

export const setQSHistory = (location, params = [], hash = false) => {
	if (Object.keys(params).length > 0) {
		let qs = location + '?';
		for (let obj in params) {
			qs += obj + '=' + params[obj] + '&';
		}
		qs = qs.slice(0, qs.length - 1);
		if (hash !== false) history.push(qs + '#' + hash);
		else history.push(qs);
	}
	else history.push(location, params);
};
