import { SubmissionError } from 'redux-form';

const showError = (errors) => {
    for (const err in errors) {
        errors[err] = errors[err].filter((it) => it !== undefined);
        if (errors[err].length === 0) {
            delete errors[err];
        } else {
            errors[err] = errors[err].join('; ');
        }
    }

    if (Object.keys(errors).length > 0) {
        throw new SubmissionError(errors);
    }
};

const showErrorAsync = (errors) => {
    for (const err in errors) {
        errors[err] = errors[err].filter((it) => it !== undefined);
        if (errors[err].length === 0) {
            delete errors[err];
        } else {
            errors[err] = errors[err].join('; ');
        }
    }
    if (Object.keys(errors).length > 0) {
        throw errors;
    }
};

export { showError, showErrorAsync };