import classes from './MessagesList.module.scss';
import { Avatar } from 'components/v1/Avatar';
import { BackButton } from 'components/v1/BackButton';

export const UserInfo = props => {
  const { avatar, firstName, lastName, isMobile, closeChat } = props;


  return (
    <div className={classes.userInfo}>
      {isMobile && (
        <BackButton onClick={closeChat}/>
      )}
      <Avatar type="circle" source={avatar} className={classes.avatar} />
      <div className={classes.name}>
        {firstName} {lastName}
      </div>
    </div>
  )
}
