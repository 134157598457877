import ProfileTypeForm from "components/Pages/Requests/ProfileTypeForm";
import { SlideToggleAnimation } from "components/UI/Accordion/SlideToggleAnimation";
import Archive from "components/UI/Archive/index";
import ICOForm from "components/UI/Forms/Requests/ICO/index";
import SearchRequest from "components/UI/Forms/SearchRequest/SearchRequest";
import SideBlockTemporary from "components/UI/Requests/SideBlockTemporary";
import SideBlock from "components/UI/SideBlock/index";
import Language from "language/index";
import { GET_MY_PROFILE } from "query/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import ApplicationList from "../../UI/Requests/RequestApplicationList";


class Requests extends Component {

    state = {
        tabs: [{ title: Language.myApplication, type: 'my' }, { title: Language.networkRequests, type: 'network' }],
        tabsRequests: [{ title: Language.createNewRequest, type: 'new_req' }, { title: 'ICO', type: 'ico' }],
        activeTab: 0,
        activeTabIndexReq: 0,
        isOpened: false,
        variables: {}
    };

    changeTab = (val, name) => this.setState({ [name]: val });

    toggleAddedCreate = () => {
        this.setState({ isOpened: !this.state.isOpened })
    };

    search = values => {
        let value = { typeOffer: values.typeOffer };

        if (values.hasOwnProperty('industry')) {
            if (values.industry.groupName) value.industryGroupName = values.industry.groupName;
            if (values.industry.name) value.industryName = values.industry.name;
        }

        if (values.hasOwnProperty('address')) {
            if (values.address.countryId) value.countryId = values.address.countryId;
            if (values.address.stateId) value.stateId = values.address.stateId;
            if (values.address.cityId) value.cityId = values.address.cityId;
        }

        this.setState({ variables: value })
    };

    render() {

        const { activeTab, tabs, tabsRequests, activeTabIndexReq } = this.state,
            {typeKeys, status} = this.props.getMyProfile,
            isMy = this.state.activeTab === 0;

        return (
            <React.Fragment>
                <div className="centerside">
                    <SideBlockTemporary
                        tabs={tabs}
                        changeTab={val => this.changeTab(val, 'activeTab')}
                        activeTab={activeTab}
                        action={{ actionCreate: this.toggleAddedCreate, actionSearch: this.search }}
                    />
                    <SlideToggleAnimation open={this.state.isOpened}>
                        <SideBlock
                            title={<b>{Language.newApplication}</b>}
                            // tabs={tabsRequests}
                            // activeTabIndex={activeTabIndexReq}
                            // changeTab={val => this.changeTab(val, 'activeTabIndexReq')}
                        >
                            {
                                activeTabIndexReq === 0 ?
                                    <ProfileTypeForm type={typeKeys[0]}
                                                     cb={this.toggleAddedCreate}/> :
                                    <ICOForm cb={this.toggleAddedCreate}/>
                            }
                        </SideBlock>
                    </SlideToggleAnimation>
                    <ApplicationList toggleAddedCreate={this.toggleAddedCreate} admin={status === 'admin'} isMy={isMy} variables={this.state.variables}/>
                </div>
                <div className="rightside">
                    {
                        isMy ? <Archive/> :
                            <SideBlock>
                                <div className="pickup">
                                    <div className="sb-title">
                                        <span>{Language.applicationSearch}</span>
                                    </div>
                                    <SearchRequest
                                        onSubmit={this.search}
                                        resetSearch={() => this.setState({ variables: {} })}
                                    />
                                </div>
                            </SideBlock>
                    }
                </div>
            </React.Fragment>
        )
    }
}


export default props => {
    return (
        <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
            {({ data, loading, error, client }) => {
                if (error || loading) return 'Loading...';
                console.log(data);
                if (data.ProfileGetter) {
                    return <Requests client={client} {...props} {...data.ProfileGetter}/>
                } else return 'Loading...'
            }}
        </Query>
    )
};
