import React from 'react';
import PropTypes from 'prop-types';
import { getItem } from "libs/localStorage";

class Video extends React.Component {

	static propTypes = {
		show: PropTypes.bool,
		close: PropTypes.func,
	};

	static defaultProps = {
		show: false,
		close: () => {},
	};


	render () {
		const { show, close } = this.props;
		const src = getItem('Language') === 'en' ? 'https://www.youtube.com/embed/E2AXhf2jomo' : 'https://www.youtube.com/embed/NZQXyg-JR94';

		return (
			<div className={`videoModalContainer ${show ? 'open' : ''}`} onClick={close}>
				<div className="containerForVideo">
					<button onClick={close} type="button" className="closeButton" />
				{
					show && <iframe src={src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="video" />
				}
				</div>
			</div>
		)
	}
}

export default Video;