import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';


export class Avatar extends PureComponent {

    static propTypes = {
        avatar: PropTypes.string,
        size: PropTypes.number
    };

    static defaultProps = {
        avatar: '',
        size: 70
    };


    render() {
        const { size, avatar } = this.props;
        let newAvatar = null;
        if (avatar) {
            newAvatar = avatar.split('/');
            newAvatar[6] = newAvatar[5];
            newAvatar[5] = size;
            newAvatar = newAvatar.join('/');
        }

        return (
            <Fragment>
                {
                    avatar ?
                        <img
                            src={newAvatar}
                            alt="..." /> :
                        <img src="/templates/img/user_default.jpg" alt="" />
                }
            </Fragment>
        )
    }
}




