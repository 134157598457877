import ProfileIndividualForm from "components/UI/Forms/ProfileIndividualForm/index";
import ProfileLegalEntityForm from "components/UI/Forms/ProfileLegalEntityForm/index";
import SideBlock from "components/UI/SideBlock/index";
import { GET_MY_PROFILE } from "query/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import CustomSelect from "../../UI/Fields/Select";
import LoaderAvatar from "../../UI/Fields/LoaderAvatar";
import Language from "language/index";
import DescriptionType from "components/UI/DescriptionTypes/index";




class ChangeProfile extends Component {

  state = {
    typeProfile: this.props.getMyProfile.__typename,
    options: [
      {value: 'EntityProfile', label: 'Юр лицо'},
      {value: 'IndividualProfile', label: 'Физ лицо'}
    ]
  };

  render() {
    let initialValue = { ...this.props.getMyProfile };
    for (let key in initialValue) {
      if (initialValue[key] === null) delete initialValue[key]
    }
    const { typeProfile } = this.state;  //['EntityProfile', 'IndividualProfile', 'Client'];
    const title = (<h1 className="sm">
      Анкета для {`${typeProfile === 'EntityProfile' ? 'юридического' : 'физического'}`} лица
    </h1>);

    if (this.props.getMyProfile.__typename === 'Client') {
      initialValue = {
        ...initialValue,
        firstName: initialValue.firstNames,
        lastName: initialValue.lastNames
      }
    }

    console.log(this.props);

    return (

        <div className="columns clearfix">
          <div className="leftcolumn">
            <div className="uploader">
              <LoaderAvatar input={{value: initialValue.avatar}}/>
            </div>
          </div>
          <div className="centercolumn fixPaddingUserSettings">
            <SideBlock title={title}>

                {
                    initialValue.typeKeys && initialValue.typeKeys[0] &&
                    <div className="descriptionType">
                      <DescriptionType userType={initialValue.typeKeys[0]} />
                    </div>
                }


              {
                typeProfile === 'Client' &&
                <div className=" form fullWidth">
                  <CustomSelect
                    className="element"
                    label={Language.ChooseUserCategory}
                    options={this.state.options}
                    placeholder={Language.LegalEntityIndividual}
                    input={{onChange: value => this.setState({typeProfile: value})}}
                  />
                </div>
              }

              {
                typeProfile === 'IndividualProfile' &&
                <ProfileIndividualForm initialValues={initialValue}/>
              }

              {
                typeProfile === 'EntityProfile' &&
                <ProfileLegalEntityForm initialValues={initialValue}/>
              }

            </SideBlock>
          </div>
        </div>
    )
  }
}


export default props => {
  return (
    <Query query={GET_MY_PROFILE}>
      {({ data: { ProfileGetter }, loading, error }) => {
        console.log(ProfileGetter);
        if (error || loading) return 'Loading...';
        return (
          <ChangeProfile {...ProfileGetter} {...props}/>
        )
      }}
    </Query>
  )
};
