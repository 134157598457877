import React, { Component } from 'react';
import Language  from "language/index";
import Scrollchor from 'react-scrollchor';

class RightSide extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return(
      <div className="rightside">
        <div className="side-block fixed-help-block">
          <div className="sb-title">
            <div className="element clearfix">
              <em>{Language.Contents}</em>
            </div>
          </div>
          <ul className="blue-links">
            {
              this.props.questions.map((item, i) => {
                return(
                  <li key={i}><Scrollchor to={'#' + item.id}>{item.title}</Scrollchor><span className="help-counter">{item.list.length}</span></li>
                );
              })
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default RightSide;