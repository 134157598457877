
import Language from 'language';

import styles from 'pages/News/styles.module.scss';


export const Help = props => {

  const tabs = [
    { value: 0, label: 'Общие вопросы' },
    { value: 1, label: 'Описание newnext' },
    { value: 2, label: 'Страница пользователя' },
    { value: 3, label: 'Контакты' },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className="content">
          <h1>{Language.help}</h1>
          <p style={{ marginTop: 20 }}>Страница в разработке</p>
        </div>
      </div>
      <aside>

      </aside>
    </div>
  )
}
