import Loading from "components/UI/Prealoders/Loading";
import SbContactsList from "components/UI/SbContacts/SbContactsList";
import SideBlock from "components/UI/SideBlock/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import { GET_RECCOMEND } from "../../../query";


class ReccommendUserByUserTypes extends Component {

  render() {
    const { tabs, changeTab, activeTab, typeUser } = this.props;
    if (tabs.length === 0) return null;
    return (
      <SideBlock
        changeTab={changeTab}
        activeTabIndex={activeTab}
        className="recommended"
      >
        <Query
          query={GET_RECCOMEND}
          variables={{ userType: typeUser }}
        >
          {({ loading, error, data }) => {
            if (error) return 'Error...';
            if (loading) return <Loading/>;

            let result = (data.UserGetter.getRecommendedOffers || [])
              .map((el) => ({ ...el.userId, name: el.name || el.title })) || []
            result = result.length > 0 ? result.slice(0, 12) : result

            return (
              <SbContactsList
                refetchQuery={{
                  query: GET_RECCOMEND,
                  variables: { userType: typeUser }
                }}
                isNew
                users={result}
              />
            )
          }}
        </Query>
      </SideBlock>
    )
  }
}


export default ReccommendUserByUserTypes;
