import React from 'react'
import Language from 'language';

import { InputController } from 'components/v1/HookFields/Input';
import { SelectController } from 'components/v1/HookFields/Select';
import BirthGroup from 'components/v1/HookFields/Group/Birth';
import RegionGroup from 'components/v1/HookFields/Group/Region';
import IndustryGroup from 'components/v1/HookFields/Group/Industry';
import TypeProfileSelect from 'components/UI/Forms/RegForm/TypeProfileSelect';

import { PROFILE_TYPES_OPTIONS, SEX_OPTIONS } from 'pages/Profile/EditForms/utils';
import { required, rules } from 'libs/rules';

import styles from './styles.module.scss'


export const MainForm = ({ control, setValue, watch, errors, disabledTypeKeys }) => {

  return (
    <>
      <div className={styles.section}>
        <div>{Language.firstName}</div>
        <InputController
          control={control}
          name="firstName"
          rules={rules.firstName}
          errors={errors.firstName}
        />
      </div>
      <div className={styles.section}>
        <div>{Language.lastName}</div>
        <InputController
          control={control}
          name="lastName"
          rules={rules.lastName}
          errors={errors.lastName}
        />
      </div>
      <BirthGroup control={control} errors={errors} />
      <div className={styles.section}>
        <div>{Language.sex}</div>
        <div style={{ maxWidth: 186, width: '100%' }}>
          <SelectController
            control={control}
            placeholder={Language.ChooseYourGenders}
            name="sex"
            label={''}
            options={SEX_OPTIONS}
            rules={{required}}
            errors={errors.sex}
          />
        </div>
      </div>
      <RegionGroup control={control} setValue={setValue} watch={watch} errors={errors} />
      <IndustryGroup control={control} setValue={setValue} watch={watch} errors={errors} />
      <div className={styles.section}>
        <div>Тип участника</div>
        <SelectController
          control={control}
          name="typeKeys"
          label={''}
          placeholder={Language.userCategoryss}
          options={TypeProfileSelect}
          rules={{required}}
          errors={errors.typeKeys}
          disabled={disabledTypeKeys}
        />
      </div>
    </>
  )
}
