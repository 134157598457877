import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { DotsIcon } from 'components/v1/Icons/DotsIcon'

import styles from './styles.module.scss'

export const Dropdown = ({ items }) => {

  const [opened, setOpened] = useState(false)

  const ref = useRef(null)

  const handleClickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      setOpened(false)
    }
  }

  useEffect(() => {
    if (ref) {
      document.addEventListener('click', handleClickOutside, false);
    }

    return () => {
      if (ref) {
        document.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [ref])

  const onToggle = () => {
    setOpened(opened => !opened)
  }

  return (
    <div
      className={cn(styles.container, { [styles.opened]: opened })}
      ref={ref}
    >
      <div role="button" className={styles.button} onClick={onToggle}>
        <DotsIcon/>
      </div>
      <div className={styles.dropdown}>
        <ul>
          {items.map((el) => (
            <li key={el.label} onClick={() => {
              el.onClick();
              setOpened(false)
            }}>{el.icon}{el.label}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
