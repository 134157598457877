import React from 'react'
import cn from 'classnames'
import { Query } from 'react-apollo';
import { GET_AUTH } from 'query'

import Header from 'components/v1/Header/Header'
import { LeftMenu } from 'components/v1/LeftMenu'
import { BottomNavBar } from 'components/v1/BottomNavBar'

import styles from './styles.module.scss'

export const Layout = ({ children, withLeftMenu, isNotContainer, location }) => {

  return (
    <Query query={GET_AUTH} fetchPolicy="cache-only">
      {({ data }) => {
        return (
          <div id="main-layout">
            <Header location={location} />
            {withLeftMenu && data.isAuth && (
              <LeftMenu />
            )}
            <div className={cn(styles.content, { [styles.withLeftMenu]: withLeftMenu && data.isAuth, [styles.isNotContainer]: isNotContainer })}>
              <div>
                {children}
              </div>
            </div>
            {data.isAuth && (
              <BottomNavBar location={location} />
            )}
          </div>
        )
      }}
    </Query>
  )
}
