import Language from 'language';
import { name, lastnames, emails } from 'libs/validationsForms';
import { passwords } from 'libs/fieldValidation';

export const required = {
  value: true,
  message: Language.fillField,
};

export const rules = {
  firstName: {
    required,
    validate: {
      name
    }
  },
  lastName: {
    required,
    validate: {
      lastnames
    }
  },
  email: {
    validate: {
      emails
    }
  },
  passwords: {
    required,
    validate: {
      passwords
    }
  },
  confirmPassword: {

  }
}
