import Select from 'components/UI/Fields/Select';
import Industry from "components/UI/Forms/GroupFields/Industry";
import Region from "components/UI/Forms/GroupFields/Region";
import Language from "language/index";
import React, { Component } from 'react';
import 'react-input-range/lib/css/index.css';
import { change, Field, formValueSelector, reduxForm, stopSubmit } from 'redux-form';
import { reset } from 'redux-form';
import compose from "redux/src/compose";
import { connect } from "react-redux";
import { Button } from 'components/v1/Button';

class SearchRequest extends Component {


    changeValue = values => {
        this.setState({ params: { ...this.state.params, value: { ...values } } });
        this.setValue(values.min, 'yearBegin');
        this.setValue(values.max, 'yearEnd');
    };

    setValue = (values, nameField) => {
        this.props.dispatch(change('searchUserForm', nameField, values));
    };


    resetForm = () => {
        this.props.dispatch(reset('searchRequest'));
        this.props.resetSearch();
    };


    render() {
        const { handleSubmit, isAdditionalSearch } = this.props;
        return (
            <div className="form">
                <form onSubmit={handleSubmit}>
                    <Field
                        name="typeOffer"
                        component={Select}
                        label={`${Language.TypeApplication} *`}
                        placeholder={Language.ChangeTypeApplication}
                        className={`element selectReq`}
                        options={[
                            { value: 'investor', label: Language.investor },
                            { value: 'novator', label: Language.novator },
                            { value: 'businessproduct', label: Language.sellerBusiness },
                            { value: 'provider', label: Language.supplier },
                            { value: 'businesswoman', label: Language.businessWoman },
                            { value: 'unemployed', label: Language.unemployed }
                        ]}
                    />
                    <Industry
                        label={Language.SectorAffiliation}
                    />
                    <Region dispatch={this.props.dispatch}/>

                    <div className="flex">
                      <Button type="submit">{Language.find}</Button>
                        {/*<button type="submit" className="orange-btn search_users">{Language.find}</button>*/}
                        {
                            Boolean(Object.keys(this.props.values).length) &&
                            <Button variant="outlined" type="button" onClick={this.resetForm} className="reset" style={{ display: 'block' }}>{Language.Reset}</Button>
                        }
                    </div>
                </form>
            </div>
        );
    }
}

const selector = formValueSelector('searchRequest');

export default compose(
    reduxForm({
        form: 'searchRequest',
        onChange: (values, dispatch, props) => {
            if (props.submitFailed) {
                dispatch(stopSubmit('searchRequest'));
            }
        }
    }),
    connect(
        state => ({
            values: selector(state, 'typeOffer', 'industry', 'address')
        })
    ),
)(SearchRequest);

