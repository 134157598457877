import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'

import { GET_MY_PROFILE } from 'query'
import { Avatar } from 'components/v1/Avatar'
import { ArrowRightFlatIcon } from 'components/v1/Icons/ArrowRightFlatIcon'

import styles from './styles.module.scss'

const ProfilePreview = ({ getMyProfile }) => {

  const { firstName, lastName, avatar } = getMyProfile

  return (
    <Link to={'/profile/me'} className={styles.container}>
      <Avatar size={'circle'} source={avatar} className={styles.avatar} />
      <div className={styles.info}>
        <div className={styles.username}>{firstName} {lastName}</div>
        <span className={styles.goto}>Перейти в профиль</span>
      </div>
      <ArrowRightFlatIcon/>
    </Link>
  )
}

export default (props) => {
  return (
    <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
      {({ data, loading, error }) => {
        if (error || loading) return 'Loading...';
        if (data.ProfileGetter) {
          return <ProfilePreview {...data.ProfileGetter} {...props} />
        }
      }}
    </Query>
  )
}
