import classes from './MessagesList.module.scss';
import { getMessageDateString } from 'libs/getMessageTime';

export const Day = props => {
  const { createDate } = props;
  return (
    <div className={classes.day}>
      <div className={classes.line} />
      <div className={classes.date}>{getMessageDateString(createDate)}</div>
    </div>
  )
}
