import React, { Fragment, useMemo, useState } from 'react'
import { Mutation, Query } from 'react-apollo';
import { useForm } from 'react-hook-form';
import ym from 'react-yandex-metrika';

import { Tabs } from 'components/v1/Tabs';
import { Page } from 'components/v1/Page';

import { GET_MY_PROFILE } from 'query';
import { SET_ENTITY_PROFILE, SET_INDIVIDUAL_PROFILE } from 'mutation/profile';

import { MainForm } from 'pages/Profile/EditForms/MainForm';
import { ContactForm } from 'pages/Profile/EditForms/ContactForm';
import { AboutForm } from 'pages/Profile/EditForms/AboutForm';
import { EducationForm, educationValues } from 'pages/Profile/EditForms/EducationForm';
import { CareerForm, careerValues } from 'pages/Profile/EditForms/CareerForm';
import { MainCompanyForm } from 'pages/Profile/EditForms/MainCompanyForm';
import { AboutCompanyForm } from 'pages/Profile/EditForms/AboutCompanyForm';
import { ChangeAvatar } from 'components/v1/Avatar';
import { Button } from 'components/v1/Button';
import { ActionNotification } from 'components/UI/Notifications/action';

import Language from 'language';
import CustomSelect from 'components/UI/Fields/Select';
import { ACCOUNT_TYPE_OPTIONS } from 'pages/Profile/EditForms/utils';

import styles from './styles.module.scss'
import { HeaderMobile } from 'components/v1/HeaderMobile';

const hiddenStyle = { opacity: 0, position: 'absolute', pointerEvents: 'none' }

const EntityProfile = ({ initialValues, mutate }) => {
  const [currentTab, setCurrentTab] = useState(0)

  const tabs = useMemo(() => ([
    { label: 'Основное', value: 0 },
    { label: 'Контакты', value: 1 },
    { label: 'О компании', value: 2 },
  ]), [])

  const isNext = initialValues.__typename === 'Client' && currentTab < tabs.length - 1

  const { control, watch, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      address: {
        countryId: '',
      },
      ...initialValues,
    }
  })

  const onSubmit = (data) => {
    const values = {
      ...data,
    }

    const fields = [
      'firstName', 'lastName', 'companyName',
      'industry', 'email', 'termOfWork', 'address',
      'site', 'description', 'personalLink', '_id', 'typeKeys'
    ];

    // typeKeys: ['Investor', 'BusinessProduct', 'Innovator', 'BusinessWoman', 'Unemployed', 'Provider']

    const value = { ...values };

    for (let key in value) {
      if (!!value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
      if (!fields.some(el => el === key)) {
        delete value[key];
      } else if (!!value[key] && value[key].hasOwnProperty('count')) {
        value[key].count = parseInt(value[key].count);
      }
    }

    mutate({ variables: { profile: { ...value } } })
  }

  return (
    <Fragment>
      <Tabs
        items={tabs}
        activeTab={currentTab}
        onChangeTab={setCurrentTab}
        className={styles.tab}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div style={currentTab === 0 ? {} : hiddenStyle}>
            <MainCompanyForm control={control} setValue={setValue} watch={watch} errors={errors}/>
          </div>
          <div style={currentTab === 1 ? {} : hiddenStyle}>
            <ContactForm control={control} errors={errors} />
          </div>
          <div style={currentTab === 2 ? {} : hiddenStyle}>
            <AboutCompanyForm control={control} errors={errors} />
          </div>
          <div className="separator" />
          {isNext ? (
            <Button type="button" onClick={() => setCurrentTab(currentTab => ++currentTab)}>
              Далее
            </Button>
          ) : (
            <Button type="submit">
              Сохранить
            </Button>
          )}
        </div>
      </form>
    </Fragment>
  )
}

const IndividualProfile = ({ initialValues, mutate }) => {

  const [currentTab, setCurrentTab] = useState(0)

  const tabs = useMemo(() => ([
    { label: 'Основное', value: 0 },
    { label: 'Контакты', value: 1 },
    { label: 'О себе', value: 2 },
    { label: 'Образование', value: 3 },
    { label: 'Карьера', value: 4 }
  ]), [])

  const isNext = initialValues.__typename === 'Client' && currentTab < tabs.length - 1

  const getBirthday = () => {
    if (initialValues.birthday) {
      let d = new Date(initialValues.birthday.slice(0,4), initialValues.birthday.slice(5,7), parseInt(initialValues.birthday.slice(8,10)) + 1);
      const dayOfBirth = `0${d.getDate()}`.slice(-2)
      const monthOfBirth = `0${d.getMonth()}`.slice(-2)
      const yearOfBirth = `${d.getFullYear()}`
      // const dayOfBirth = initialValues.birthday.slice(8,10)
      // const monthOfBirth = initialValues.birthday.slice(5,7)
      // const yearOfBirth = initialValues.birthday.slice(0,4)

      return {
        dayOfBirth,
        monthOfBirth,
        yearOfBirth,
      }
    }

    return {
      dayOfBirth: '',
      monthOfBirth: '',
      yearOfBirth: '',
    }
  }

  const { control, watch, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      address: {
        countryId: '',
      },
      ...initialValues,
      ...getBirthday(),
      educations: Array.isArray(initialValues.educations) && initialValues.educations.length > 0 ? initialValues.educations : [educationValues],
      career: Array.isArray(initialValues.career) && initialValues.career.length > 0 ? initialValues.career : [careerValues],
      typeKeys: Array.isArray(initialValues.typeKeys) && initialValues.typeKeys.length > 0 ? initialValues.typeKeys[0] : initialValues.typeKeys,
    }
  })

  const onSubmit = (data) => {
    const values = {
      ...data,
      // phone: data.phone.replace(/\D+/g, ""),
      typeKeys: [data.typeKeys],
    }

    if (values.dayOfBirth && values.monthOfBirth && values.yearOfBirth) {
      values.birthday = new Date(values.yearOfBirth, parseInt(values.monthOfBirth) - 1, values.dayOfBirth)
      delete values.dayOfBirth
      delete values.monthOfBirth
      delete values.yearOfBirth
    }

    const fields = [
      'firstName', 'lastName', 'midName',
      'birthday', 'email', 'sex', 'address',
      'description', 'personalLink', '_id', 'educations',
      'career', 'industry', 'maritalStatus', 'hobby', 'site', 'typeKeys'
    ];

    const value = { ...values };

    for (let key in value) {
      if (Array.isArray(value[key]) && value[key][0] instanceof Object && value[key][0].hasOwnProperty('__typename')) {
        delete value[key][0].__typename;
      }

      if ((key === 'educations' || key === 'career') && !value[key][0]) {
        delete value[key]
      }

      if (!!value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;

      if (!fields.some(el => el === key)) {
        delete value[key];
      } else if (!!value[key] && value[key].hasOwnProperty('count')) {
        value[key].count = parseInt(value[key].count);
      } else if (key === 'industry' && value[key].groupName === null) {
        delete value[key]
      }
    }

    delete value.address.regionName
    delete value.onlineStatus
    delete value.status
    delete value.__typename

    mutate({ variables: { profile: { ...value } } })
  }

  return (
    <Fragment>
      <HeaderMobile goBack title="Редактирование" />
      <Tabs
        items={tabs}
        activeTab={currentTab}
        onChangeTab={setCurrentTab}
        className={styles.tab}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div style={currentTab === 0 ? {} : hiddenStyle}>
            <MainForm
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
              disabledTypeKeys={Array.isArray(initialValues.typeKeys) && initialValues.typeKeys.length > 0}
            />
          </div>
          <div style={currentTab === 1 ? {} : hiddenStyle}>
            <ContactForm control={control} errors={errors}/>
          </div>
          <div style={currentTab === 2 ? {} : hiddenStyle}>
            <AboutForm control={control} errors={errors}/>
          </div>
          <div style={currentTab === 3 ? {} : hiddenStyle}>
            <EducationForm control={control} errors={errors}/>
          </div>
          <div style={currentTab === 4 ? {} : hiddenStyle}>
            <CareerForm control={control} errors={errors}/>
          </div>
          <div className="separator" />
          {isNext ? (
            <Button type="button" onClick={() => setCurrentTab(currentTab => ++currentTab)}>
              Далее
            </Button>
          ) : (
            <Button type="submit">
              Сохранить
            </Button>
          )}
        </div>
      </form>
    </Fragment>
  )
}

const ChangeProfile = ({ data, ...props }) => {

  const [typeProfile, setTypeProfile] = useState(data.ProfileGetter.getMyProfile.__typename)
  let initialValues = data.ProfileGetter.getMyProfile

  if (data.ProfileGetter.getMyProfile.__typename === 'Client') {
    initialValues = {
      ...initialValues,
      firstName: initialValues.firstNames,
      lastName: initialValues.lastNames
    }

    delete initialValues.firstNames
    delete initialValues.lastNames
  }

  return (
    <Page className={styles.container}>
      <div className={styles.aside}>
        <ChangeAvatar
          value={initialValues.avatar}
          firstName={initialValues.firstName}
          lastName={initialValues.lastName}
          companyName={''}
        />
      </div>
      <Page.Body>
        <div className="content">
          <h1 className={styles.h1}>Редактирование профиля</h1>
          {typeProfile === 'Client' && (
            <CustomSelect
              options={ACCOUNT_TYPE_OPTIONS}
              placeholder={Language.LegalEntityIndividual}
              input={{
                onChange: value => {
                  setTypeProfile(value)
                }
              }}
            />
          )}
          {typeProfile === 'EntityProfile' && (
            <Mutation
              onCompleted={() => {
                ActionNotification('2', Language.profileUpdated);
                if (initialValues.__typename === 'Client') {ym('reachGoal', 'regsuccess')}
                if (props.cb) props.cb();
              }}
              mutation={props.mutation ? props.mutation : SET_ENTITY_PROFILE}
              refetchQueries={[{ query: GET_MY_PROFILE }]}
            >
              {mutate => (
                <EntityProfile initialValues={initialValues} mutate={mutate} />
              )}
            </Mutation>
          )}
          {typeProfile === 'IndividualProfile' && (
            <Mutation
              mutation={props.mutation ? props.mutation : SET_INDIVIDUAL_PROFILE}
              onCompleted={() => {
                ActionNotification('2', Language.profileUpdated);
                if (initialValues.__typename === 'Client') {ym('reachGoal', 'regsuccess')}
                if (props.cb) props.cb();
              }}
              refetchQueries={[{ query: GET_MY_PROFILE }]}
            >
              {mutate => (
                <IndividualProfile initialValues={initialValues} mutate={mutate} />
              )}
            </Mutation>
          )}
        </div>
      </Page.Body>
    </Page>
  )
}

const ChangeProfilePage = (props) => {
  return (
    <Query
      query={GET_MY_PROFILE}
      fetchPolicy={'cache-only'}
    >
      {({ ...rest }) => {
        if (rest.loading) {
          return 'loading...'
        }
        if (!rest.error && !rest.loading && rest.data && Object.keys(rest.data).length > 0) {
          return <ChangeProfile {...rest} />
        }
        return null
      }}
    </Query>
  )
}

export default ChangeProfilePage
