export const NewsIcon = ({ className, width = 19, height = 19 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2863 1.51676H4.37921C2.8343 1.51676 1.5819 2.70552 1.5819 4.17194V14.1288C1.5819 15.5953 2.8343 16.784 4.37921 16.784H14.2863C15.8313 16.784 17.0837 15.5953 17.0837 14.1288V4.17194C17.0837 2.70552 15.8313 1.51676 14.2863 1.51676ZM4.37921 0.299805C2.12622 0.299805 0.299805 2.03342 0.299805 4.17194V14.1288C0.299805 16.2674 2.12622 18.001 4.37921 18.001H14.2863C16.5393 18.001 18.3658 16.2674 18.3658 14.1288V4.17194C18.3658 2.03342 16.5393 0.299805 14.2863 0.299805H4.37921Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0837 6.38458H1.5819V5.16763H17.0837V6.38458Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.5819 5.16763H17.0837V4.17194C17.0837 2.70552 15.8313 1.51676 14.2863 1.51676H4.37921C2.8343 1.51676 1.5819 2.70552 1.5819 4.17194V5.16763ZM16.7838 4.86782V4.17213C16.7838 2.88595 15.6807 1.81696 14.2865 1.81696H4.37941C2.98525 1.81696 1.8821 2.88595 1.8821 4.17213V4.86782H16.7838ZM17.0837 6.38458H1.5819V14.1288C1.5819 15.5953 2.8343 16.784 4.37921 16.784H14.2863C15.8313 16.784 17.0837 15.5953 17.0837 14.1288V6.38458ZM1.8821 6.68478V14.129C1.8821 15.4152 2.98525 16.4842 4.37941 16.4842H14.2865C15.6807 16.4842 16.7838 15.4152 16.7838 14.129V6.68478H1.8821ZM0 4.17213C0 1.85338 1.97566 0 4.37941 0H14.2865C16.6903 0 18.6659 1.85338 18.6659 4.17213V14.129C18.6659 16.4478 16.6903 18.3012 14.2865 18.3012H4.37941C1.97566 18.3012 0 16.4478 0 14.129V4.17213ZM0.299805 4.17194C0.299805 2.03342 2.12622 0.299805 4.37921 0.299805H14.2863C16.5393 0.299805 18.3658 2.03342 18.3658 4.17194V14.1288C18.3658 16.2674 16.5393 18.001 14.2863 18.001H4.37921C2.12622 18.001 0.299805 16.2674 0.299805 14.1288V4.17194Z" fill="currentColor"/>
    </svg>
  )
};



