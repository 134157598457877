import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import Industry from "components/UI/Forms/GroupFields/Industry";
import Money from "components/UI/Forms/GroupFields/Money";
import Region from "components/UI/Forms/GroupFields/Region";
import TimePeriod from "components/UI/Forms/GroupFields/TimePeriod";
import { SET_INVESTOR } from "mutation/offer";
import React from 'react';
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from "redux-form";
import { submitInvestForm } from "./submit";
import { GET_OFFERS_LIST } from "query/offers";
import Language from "language/index";
import { Button } from 'components/v1/Button';



class InvestorForm extends React.Component {


    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return (
            <div className="form fullWidth">
                <form className="new_application" id="new_request_investor" onSubmit={handleSubmit}>
                    <Money
                        nameField="investmentVolume"
                        label={`${Language.VolumeInvestments} *`}
                    />
                    <Industry
                        isReq
                        label={`${Language.InvestmentIndustry} *`}
                        {...initialValues}
                    />
                    <Region
                        countryLabel={`${Language.InvestmentRegion} *`}
                        regionLabel={`${Language.regionResidence} *`}
                        {...initialValues}
                    />
                    <TimePeriod
                        label={`${Language.InvestmentTerm} *`}
                        name="investmentTerm"
                        {...initialValues}
                    />
                    <TimePeriod
                        label={`${Language.PaybackTime} *`}
                        name="paybackPeriod"
                        {...initialValues}
                    />
                    <Money
                        label={`${Language.VolumeInvestments} *`}
                        nameField="expectedReturn"
                        {...initialValues}
                    />
                    <Field
                        name="investmentExperience"
                        component={Select}
                        label={`${Language.ExperienceInvestment} `}
                        options={[
                            { value: 'false', label: 'Впервые' },
                            { value: 'true', label: 'Есть участие в проектах' },
                        ]}
                    />
                    <Field
                        name="proportion"
                        type="number"
                        maxLength="3"
                        component={Input}
                        label={Language.sharedInPraticipation}
                        // placeholder="Введите дополнительную информацию"
                    />
                    <Field
                        name="additionallyInform"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.additionalInformationBlock} *`}
                        placeholder="Введите дополнительную информацию"
                    />
                    <Button type="submit" disabled={loading} className="orange-btn"> {Language.save} </Button>
                </form>
            </div>
        );
    }
}


InvestorForm = reduxForm({
    form: 'investorForm',
    enableReinitialize: true,
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('investorForm'));
        }
    }
})(InvestorForm);


export default props => {
    const initialValues = Boolean(Object.keys(props.initialValues).length) ? props.initialValues : {
        investmentVolume: {
            currency: 'rub'
        },
        investmentTerm: {
            typeDate: 'month'
        },
        paybackPeriod: {
            typeDate: 'month'
        },
        expectedReturn: {
            currency: 'rub'
        }
    };

    return (
        <Mutation mutation={SET_INVESTOR} refetchQueries={[{query: GET_OFFERS_LIST, variables: { isMy: true}}]}>
            {(mutate,{ loading }) => (
                <InvestorForm  {...props} initialValues={initialValues} loading={loading}
                              onSubmit={values => submitInvestForm(values, mutate, props.cb)}/>
            )}
        </Mutation>

    )
};
