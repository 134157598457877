import Language from "language/index";
import React, { Component } from 'react';

export default class NotificationInfo extends Component {

  render() {
    const { text, close } = this.props;
    return (
      <div className="notification info" onClick={close}>
        <div className="container">
          <i className="panton d">B</i>
          <strong>{Language.Notice}</strong>
          <p>{ text }</p>
          {/*<a href="#" className="close-notify"><img src="templates/img/w-arrow.png" alt=""/></a>*/}
        </div>
      </div>
    )
  }
}
