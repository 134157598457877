import React from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

export function SlideToggleAnimation(props) {
  return (
    <SlideDown>
      {props.open ? props.children : null}
    </SlideDown>
  )
}