import React, { Component } from 'react';
import RightSide from "./right";
import CenterSide from "./center";
import Language from "language/index";

import styles from 'pages/News/styles.module.scss'

class TermsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      generalText:
        {
          title: Language.TermsOfUser,
          text: [
            Language.WelcomeToNewnext,
            Language.NewNextWebsiteServes,
            Language.TheSubjectOfTheseTerms,
            Language.ShouldCarefullyRead
          ]
        },
      terms: [
        {
          id: 'StatusOfTermsUse',
          title: Language.StatusOfTermsUse,
          text: [
            Language.TheseTermsUseNewnextSite
          ]
        },
        {
          id: 'NewnextSiteStatus',
          title: Language.NewnextSiteStatus,
          text: [
            Language.NewnextWebsiteInternational
          ]
        }
      ]
    };
  }

  render() {
    return (
      <div className={styles.container}>
        <div className="content">
          <CenterSide generalText={this.state.generalText} terms={this.state.terms}/>
        </div>
        <aside className="sticky">
          <div className="content">
            <RightSide terms={this.state.terms}/>
          </div>
        </aside>
      </div>
    );
  }
}

export default TermsPage;
