import NotFound from "components/UI/NotFound/index";
import Loading from "components/UI/Prealoders/Loading";
import PreloaderCircle from "components/UI/Prealoders/PreloaderCircle";
import Application from "components/UI/Requests/index";
import PropTypes from 'prop-types';
import { GET_OFFERS_LIST } from "query/offers";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import InfiniteScroll from 'react-infinite-scroller';


class ApplicationList extends Component {

    static propTypes = {
        OfferGetter: PropTypes.object
    };
    static defaultProps = {
        OfferGetter: { getOfferList: { offers: [], total: 0 } }
    };

    state = {
        page: 1
    };

    loadMore = page => {
        this.props.refetch({ isMy: this.props.isMy, page: 0, count: page * 10 });
        this.setState({ page })
    };

    render() {
        const { offers, total } = this.props.OfferGetter.getOfferList,
            { isNetwork } = this.props,
            isOffers = offers.length === 0;
        const { loading } = this.props;

        return (
            <React.Fragment>
                {loading && isOffers && <Loading/>}

                {
                    this.props.admin &&
                    <div className="side-block">
                        <div className="sb-title"><span>Заявки для администрирования:</span><i>{total}</i></div>
                    </div>
                }
                {
                    !loading && isOffers &&
                    <NotFound title="У вас нет созданых предложений">
                        Чтобы начать работу на сайте newnext, вам необходимо в данном разделе разместить свое
                        предложение, заполнив все указанные поля.
                        <br/><br/>
                        Ваше предложение будет доступно всем зарегистрированным пользователям.
                        В случае их заинтересованности вы будете получать отклики на ваше предложение на странице
                        «Сообщения», сможете установить необходимые бизнес-контакты и развивать ваш бизнес или проект.
                        <button
                            onClick={this.props.toggleAddedCreate}
                            type="button"
                            style={{ marginTop: '35px', display: 'block' }}
                            className="gray-btn s-btn new-request-js active">
                            Создать предложение
                        </button>
                    </NotFound>
                }

                {
                    !isOffers &&
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadMore}
                        hasMore={!loading && this.state.page * 10 < total}
                        loader={<PreloaderCircle key={2351}/>}
                    >
                        {offers.map((el, index) => {
                            if (el.status === 'ARCHIVED' || el.status === 'REMOVED') return null;
                            return (
                                <Application admin={this.props.admin} isNetwork={isNetwork} {...el} key={index}/>
                            )
                        })}

                        {loading && <PreloaderCircle key={322323}/>}
                    </InfiniteScroll>
                }
            </React.Fragment>
        );
    }
}


export default props => {
    return (
        <Query query={GET_OFFERS_LIST} variables={{ isMy: props.isMy, ...props.variables }}>
            {({ loading, error, data, refetch }) => {
                if (error) return 'Loading...';
                return (
                    <ApplicationList
                        loading={loading}
                        refetch={refetch}
                        isNetwork={!props.isMy}
                        {...data}
                        {...props}
                    />
                )
            }}
        </Query>
    )
};
