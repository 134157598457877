import EditProfile from 'components/UI/Admin/EditProfile';
import Language from 'language/index';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import InfiniteScroll from 'react-infinite-scroller';
import { GET_PROFILE_FOR_ADMIN } from '../../../query/index';
import PreloaderCircle from '../../UI/Prealoders/PreloaderCircle';
import SContactsItem from '../../UI/SContacts/SContactsItem';
import { DELETE_USER } from '../../../mutation/admin';
import { ActionNotification } from '../../UI/Notifications/action';
import { dateToString } from 'libs/convertDate';
import ModalWindow from 'components/UI/ModalWindow';
import { CHECK_USER } from 'mutation/admin';


const ConfirmDeleteUser = props => {
	return (
		<ModalWindow handleClose={props.close} title={Language.confirmAction}>
			<p>
				Вы действительно хотите удалить пользователя?
			</p>
			<div className="modal__body__btn">
				<button
					onClick={props.confirm}
					type="button"
					className="orange-btn btn_color_green"> Удалить
				</button>
				<button
					onClick={props.close}
					type="button"
					className="orange-btn btn_color_red"> Отмена
				</button>
			</div>
		</ModalWindow>
	)
};


class ProfileUsersList extends Component {

	state = {
		page: 1,
		loading: false,
		userId: false
	};

	pagination = page => {
		this.setState({ loading: true });
		this.props.fetchMore({
			variables: {
				page
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev;
				this.setState({ page, loading: false });
				prev.ProfileGetter.getProfiles.profiles = [...prev.ProfileGetter.getProfiles.profiles, ...fetchMoreResult.ProfileGetter.getProfiles.profiles];
				return prev;
			}
		})
	};

	toggleUserId = userId => {
		this.setState({ userId })
	};

	setUserDelete = userIdDelete => {

		this.setState({ userIdDelete })
	};

	deleteUser = () => {

		this.props.client.mutate({
			mutation: DELETE_USER,
			variables: { userId: this.state.userIdDelete },
			refetchQueries: [{ query: GET_PROFILE_FOR_ADMIN, variables: { page: 0, ...this.props.variables } }]
		})
			.then(res => {
				ActionNotification('2', 'Пользователь успешно удален');
			})
			.catch(err => {
				ActionNotification('1', 'Произошла ошибка при удалении пользователя');

			});

		this.handleCloseModalConfirm();
	};

	setAdsStatus = (UserId) => {

		this.props.client.mutate({
			mutation: CHECK_USER,
			variables: { UserId, check: true },
			refetchQueries: [{ query: GET_PROFILE_FOR_ADMIN, variables: { page: 0, ...this.props.variables } }]
		})
			.then(res => {
				ActionNotification('2', 'Статус успешно изменен');
			})
			.catch(err => {
				ActionNotification('1', 'Произошла ошибка при изменение статуса');

			});

	};

	handleCloseModalConfirm = () => {
		this.setState({ userIdDelete: null })
	};

	render() {
		const { total, profiles } = this.props.getProfiles;
		console.log(this.state.userIdDelete, 'this.state.userIdDelete');
		return (
			<React.Fragment>
				<div className="search-form-dialogs form">
					<form action="" target="post_form">
						<div className="element">
							<div style={{ display: 'inline', position: 'relative' }} className="add-clear-span">
								<input
									type="text"
									name="search"
									placeholder={Language.searchFullName}
									style={{ paddingRight: '20px' }}
									onChange={e => {
										if (e.target.value && e.target.value.length > 2) {
											this.props.refetch({ name: e.target.value })
										} else if (e.target.value.length === 0) {
											this.props.refetch({ name: '' })
										}
									}}
								/>
							</div>
							{/*<button type="submit">{Language.find}</button>*/}
						</div>
					</form>
				</div>
				<div className="side-block">
					<div className="sb-title">
						<span>Найдено пользователей:</span>
						<i>{total}</i>
					</div>
					<InfiniteScroll
						pageStart={0}
						loadMore={this.pagination}
						hasMore={!this.state.loading && this.state.page * 10 < total}
						loader={<PreloaderCircle key={2351}/>}
					>
						<div className="s-messages">
							<ul className="mes-list">
								{
									profiles.map((el, index) => {
										if (el._id) {
											return <SContactsItem
												actionEdit={this.toggleUserId}
												actionDelete={this.setUserDelete}
												actionStatus={this.setAdsStatus}
												isAdmin
												link={`/profile/${el._id}`}
												offer={false}
												key={index}
												{...el}
											/>
										}
										return null
									})
								}
							</ul>
							{
								this.state.loading &&
								<PreloaderCircle/>
							}
						</div>
					</InfiniteScroll>
				</div>

				{
					this.state.userId &&
					<EditProfile userId={this.state.userId} close={() => this.toggleUserId(false)}/>
				}

				{
					this.state.userIdDelete &&
					<ConfirmDeleteUser close={this.handleCloseModalConfirm} confirm={this.deleteUser}/>
				}


			</React.Fragment>
		)
	}
}


export default props => {
	return (
		<Query query={GET_PROFILE_FOR_ADMIN} variables={{ page: 0, ...props.variables }}>
			{({ client, data, loading, error, fetchMore, refetch }) => {
				if (error) return 'Error...';
				if (loading && !data.ProfileGetter) return <PreloaderCircle/>;
				return <ProfileUsersList refetch={refetch} fetchMore={fetchMore}
										 client={client} {...props} {...data.ProfileGetter}/>
			}}
		</Query>
	)
};
