import React, { useState } from 'react'
import { Query } from 'react-apollo';

import { EmptyContent } from 'components/v1/EmptyContent'

import { GET_NEWS } from 'query/news';

import styles from '../News/styles.module.scss'
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import InfiniteScroll from 'react-infinite-scroller';
import { GET_MY_ID } from 'query/profile';
import { NotificationItem } from 'pages/Notifications/components/NotificationItem';
import { HeaderMobile } from 'components/v1/HeaderMobile';

const NotificationsPage = ({ isProfilePage, isMyPage }) => {

  const [currentTab, setCurrentTab] = useState('latest')
  const [page, setPage] = useState(1)

  const tabs = [
    { value: 'latest', label: 'Последние новости' },
    { value: 'contacts', label: 'Новости контактов' },
  ]

  const loadMore = page => {
    setPage(page)
  };

  const changeTab = (val) => {
    if (val !== currentTab) {
      setPage(1)
      setCurrentTab(val)
    }
  }

  return (
    <Query query={GET_MY_ID}>
      {({ data, loading }) => {
        if (data.ProfileGetter) {
          const { getMyProfile } = data.ProfileGetter
          return (
            <div className={styles.notificationContainer}>
              <HeaderMobile goBack title="Уведомления" />
              <div className={styles.notifications}>
                <Query
                  query={GET_NEWS}
                  variables={{ page: 0, count: page * 20 }}
                  fetchPolicy={'network-only'}
                >
                  {({ data, loading, error }) => {
                    if (data?.NewsGetter) {
                      return (
                        <>
                          {data.NewsGetter.getNews.total === 0 && (
                            <EmptyContent />
                          )}
                          {data.NewsGetter.getNews.total > 0 && (
                            <div className="content">
                              <InfiniteScroll
                                pageStart={1}
                                loadMore={loadMore}
                                hasMore={!loading && page * 10 < data.NewsGetter.getNews.total}
                                loader={<PreloaderCircle key={2351}/>}
                              >
                                {
                                  data.NewsGetter.getNews.news.map((el) => {
                                    return (
                                      <NotificationItem
                                        key={el._id}
                                        isMe={getMyProfile._id === el?.userFrom?._id}
                                        {...el}
                                      />
                                    )
                                  })
                                }
                              </InfiniteScroll>
                            </div>
                          )}
                        </>
                      )}
                  }}
                </Query>
              </div>
              <aside>
                {/*<AsideTabs items={tabs} activeTab={currentTab} onChangeTab={changeTab}/>*/}
              </aside>
            </div>
          )
        }
      }}
    </Query>
  )
}

export default NotificationsPage
