import React from 'react';

import { Button } from 'components/v1/Button';
import { MessageIcon } from 'components/v1/Icons/MessageIcon';
import { DeleteUserIcon } from 'components/v1/Icons/DeleteUserIcon';

import { dialogueChecking } from 'components/UI/Contacts/dialogueChecking';
import { ADD_CONTACT, DELETE_CONTACTS } from 'mutation/Contacts';
import { ActionNotification } from 'components/UI/Notifications/action';
import Language from 'language';
import { GET_PROFILE_USER } from 'query/profile';
import { Mutation } from 'react-apollo';

import classes from './styles.module.scss';

export const Actions = ({ id, isContacts }) => {
  console.log(isContacts);
  return (
    <div className={classes.actions}>
      <Mutation
        mutation={ADD_CONTACT}
        variables={{ contact: { userId: id } }}
        onCompleted={data => {
          const { lastName, firstName } = data.ContactSetter.addContact.user;
          ActionNotification('2', `${firstName} ${lastName} ${Language.addedContacts}`);
        }}
        refetchQueries={[{
          query: GET_PROFILE_USER,
          variables: { 'userId': id }
        }]}>
        {mutate => {
          let actionContactAdd = mutate;
          return (
            <Mutation
              mutation={DELETE_CONTACTS}
              variables={{ userId: id }}
              onCompleted={data => {
                const { lastName, firstName } = data.ContactSetter.removeContact.user;
                ActionNotification('2', `${firstName} ${lastName} ${Language.removedContacts}`);
              }}
              refetchQueries={[{
                query: GET_PROFILE_USER,
                variables: { 'userId': id }
              }]}
            >
              {mutate => (
                <>
                  {isContacts && (
                    <>
                      <Button onClick={() => dialogueChecking(id)}> Написать </Button>
                      <Button onClick={mutate} variant="outlined" icon={<DeleteUserIcon />}/>
                    </>
                  )}
                  {!isContacts && (
                    <>
                      <Button onClick={actionContactAdd}> Добавить </Button>
                      <Button variant="outlined" onClick={() => dialogueChecking(id)} icon={<MessageIcon />}/>
                    </>
                  )}
                </>
              )}
            </Mutation>
          )
        }}
      </Mutation>
    </div>
  )
}
