import SearchUsersAdmin from "components/UI/Forms/SearchUsersAdmin/index";
import React, { Component } from 'react';
import Language from "language/index";


class SearchProfileUser extends Component {
    render() {
        return (
            <div className="side-block other--styles">
                <div className="pickup">
                    <div className="sb-title">
                        <span className="pointer">{Language.search}</span>
                    </div>
                    <SearchUsersAdmin { ...this.props }/>
                </div>
            </div>
        )
    }
}


export default SearchProfileUser;