import Language from "language/index";

export const years = [{ "value": "2019-01-01T20:00:00.000Z", "label": "2019" }, {
  "value": "2018-01-01T20:00:00.000Z",
  "label": "2018"
}, { "value": "2017-01-01T20:00:00.000Z", "label": "2017" }, {
  "value": "2016-01-01T20:00:00.000Z",
  "label": "2016"
}, { "value": "2015-01-01T20:00:00.000Z", "label": "2015" }, {
  "value": "2014-01-01T20:00:00.000Z",
  "label": "2014"
}, { "value": "2013-01-01T20:00:00.000Z", "label": "2013" }, {
  "value": "2012-01-01T20:00:00.000Z",
  "label": "2012"
}, { "value": "2011-01-01T20:00:00.000Z", "label": "2011" }, {
  "value": "2010-01-01T20:00:00.000Z",
  "label": "2010"
}, { "value": "2009-01-01T20:00:00.000Z", "label": "2009" }, {
  "value": "2008-01-01T20:00:00.000Z",
  "label": "2008"
}, { "value": "2007-01-01T20:00:00.000Z", "label": "2007" }, {
  "value": "2006-01-01T20:00:00.000Z",
  "label": "2006"
}, { "value": "2005-01-01T20:00:00.000Z", "label": "2005" }, {
  "value": "2004-01-01T20:00:00.000Z",
  "label": "2004"
}, { "value": "2003-01-01T20:00:00.000Z", "label": "2003" }, {
  "value": "2002-01-01T20:00:00.000Z",
  "label": "2002"
}, { "value": "2001-01-01T20:00:00.000Z", "label": "2001" }, {
  "value": "2000-01-01T20:00:00.000Z",
  "label": "2000"
}, { "value": "1999-01-01T20:00:00.000Z", "label": "1999" }, {
  "value": "1998-01-01T20:00:00.000Z",
  "label": "1998"
}, { "value": "1997-01-01T20:00:00.000Z", "label": "1997" }, {
  "value": "1996-01-01T20:00:00.000Z",
  "label": "1996"
}, { "value": "1995-01-01T20:00:00.000Z", "label": "1995" }, {
  "value": "1994-01-01T20:00:00.000Z",
  "label": "1994"
}, { "value": "1993-01-01T20:00:00.000Z", "label": "1993" }, {
  "value": "1992-01-01T20:00:00.000Z",
  "label": "1992"
}, { "value": "1991-01-01T20:00:00.000Z", "label": "1991" }, {
  "value": "1990-01-01T20:00:00.000Z",
  "label": "1990"
}, { "value": "1989-01-01T20:00:00.000Z", "label": "1989" }, {
  "value": "1988-01-01T20:00:00.000Z",
  "label": "1988"
}, { "value": "1987-01-01T20:00:00.000Z", "label": "1987" }, {
  "value": "1986-01-01T20:00:00.000Z",
  "label": "1986"
}, { "value": "1985-01-01T20:00:00.000Z", "label": "1985" }, {
  "value": "1984-01-01T20:00:00.000Z",
  "label": "1984"
}, { "value": "1983-01-01T20:00:00.000Z", "label": "1983" }, {
  "value": "1982-01-01T20:00:00.000Z",
  "label": "1982"
}, { "value": "1981-01-01T20:00:00.000Z", "label": "1981" }, {
  "value": "1980-01-01T20:00:00.000Z",
  "label": "1980"
}, { "value": "1979-01-01T20:00:00.000Z", "label": "1979" }, {
  "value": "1978-01-01T20:00:00.000Z",
  "label": "1978"
}, { "value": "1977-01-01T20:00:00.000Z", "label": "1977" }, {
  "value": "1976-01-01T20:00:00.000Z",
  "label": "1976"
}, { "value": "1975-01-01T20:00:00.000Z", "label": "1975" }, {
  "value": "1974-01-01T20:00:00.000Z",
  "label": "1974"
}, { "value": "1973-01-01T20:00:00.000Z", "label": "1973" }, {
  "value": "1972-01-01T20:00:00.000Z",
  "label": "1972"
}, { "value": "1971-01-01T20:00:00.000Z", "label": "1971" }, {
  "value": "1970-01-01T20:00:00.000Z",
  "label": "1970"
}, { "value": "1969-01-01T20:00:00.000Z", "label": "1969" }, {
  "value": "1968-01-01T20:00:00.000Z",
  "label": "1968"
}, { "value": "1967-01-01T20:00:00.000Z", "label": "1967" }, {
  "value": "1966-01-01T20:00:00.000Z",
  "label": "1966"
}, { "value": "1965-01-01T20:00:00.000Z", "label": "1965" }, {
  "value": "1964-01-01T20:00:00.000Z",
  "label": "1964"
}, { "value": "1963-01-01T20:00:00.000Z", "label": "1963" }, {
  "value": "1962-01-01T20:00:00.000Z",
  "label": "1962"
}, { "value": "1961-01-01T20:00:00.000Z", "label": "1961" }, {
  "value": "1960-01-01T20:00:00.000Z",
  "label": "1960"
}, { "value": "1959-01-01T20:00:00.000Z", "label": "1959" }, {
  "value": "1958-01-01T20:00:00.000Z",
  "label": "1958"
}, { "value": "1957-01-01T20:00:00.000Z", "label": "1957" }, {
  "value": "1956-01-01T20:00:00.000Z",
  "label": "1956"
}, { "value": "1955-01-01T20:00:00.000Z", "label": "1955" }, {
  "value": "1954-01-01T20:00:00.000Z",
  "label": "1954"
}, { "value": "1953-01-01T20:00:00.000Z", "label": "1953" }, {
  "value": "1952-01-01T20:00:00.000Z",
  "label": "1952"
}, { "value": "1951-01-01T20:00:00.000Z", "label": "1951" }, {
  "value": "1950-01-01T20:00:00.000Z",
  "label": "1950"
}, { "value": "1949-01-01T20:00:00.000Z", "label": "1949" }, {
  "value": "1948-01-01T20:00:00.000Z",
  "label": "1948"
}, { "value": "1947-01-01T20:00:00.000Z", "label": "1947" }, {
  "value": "1946-01-01T20:00:00.000Z",
  "label": "1946"
}, { "value": "1945-01-01T20:00:00.000Z", "label": "1945" }, {
  "value": "1944-01-01T20:00:00.000Z",
  "label": "1944"
}, { "value": "1943-01-01T20:00:00.000Z", "label": "1943" }, {
  "value": "1942-01-01T20:00:00.000Z",
  "label": "1942"
}, { "value": "1941-01-01T20:00:00.000Z", "label": "1941" }, {
  "value": "1940-01-01T20:00:00.000Z",
  "label": "1940"
}, { "value": "1939-01-01T20:00:00.000Z", "label": "1939" }, {
  "value": "1938-01-01T20:00:00.000Z",
  "label": "1938"
}, { "value": "1937-01-01T20:00:00.000Z", "label": "1937" }, {
  "value": "1936-01-01T20:00:00.000Z",
  "label": "1936"
}, { "value": "1935-01-01T20:00:00.000Z", "label": "1935" }, {
  "value": "1934-01-01T20:00:00.000Z",
  "label": "1934"
}, { "value": "1933-01-01T20:00:00.000Z", "label": "1933" }, {
  "value": "1932-01-01T20:00:00.000Z",
  "label": "1932"
}, { "value": "1931-01-01T20:00:00.000Z", "label": "1931" }, {
  "value": "1930-01-01T20:00:00.000Z",
  "label": "1930"
}, { "value": "1929-01-01T20:00:00.000Z", "label": "1929" }, {
  "value": "1928-01-01T20:00:00.000Z",
  "label": "1928"
}, { "value": "1927-01-01T20:00:00.000Z", "label": "1927" }, {
  "value": "1926-01-01T20:00:00.000Z",
  "label": "1926"
}, { "value": "1925-01-01T20:00:00.000Z", "label": "1925" }, {
  "value": "1924-01-01T20:00:00.000Z",
  "label": "1924"
}, { "value": "1923-01-01T20:00:00.000Z", "label": "1923" }, {
  "value": "1922-01-01T20:00:00.000Z",
  "label": "1922"
}, { "value": "1921-01-01T20:00:00.000Z", "label": "1921" }, { "value": "1920-01-01T20:00:00.000Z", "label": "1920" }];



export const hobbies = [
  { value: 'Альпинизм', label: Language.hobbies._1 },
  { value: 'Археология', label: Language.hobbies._2 },
  { value: 'Аэробика', label: Language.hobbies._3 },
  { value: 'Аэрография', label: Language.hobbies._4 },
  { value: 'Бег', label: Language.hobbies._5 },
  { value: 'Бильярд', label: Language.hobbies._6 },
  { value: 'Боулинг', label: Language.hobbies._7 },
  { value: 'Велопоездки', label: Language.hobbies._8 },
  { value: 'Гимнастика', label: Language.hobbies._9 },
  { value: 'Горные лыжи', label: Language.hobbies._10 },
  { value: 'Граффити', label: Language.hobbies._11 },
  { value: 'Дайвинг', label: Language.hobbies._12 },
  { value: 'Дегустация блюд', label: Language.hobbies._13 },
  { value: 'Дегустация вин', label: Language.hobbies._14 },
  { value: 'Диггерство ', label: Language.hobbies._15 },
  { value: 'Дизайн помещений', label: Language.hobbies._16 },
  { value: 'Дрессировка животных', label: Language.hobbies._17 },
  { value: 'Живопись, рисование', label: Language.hobbies._18 },
  { value: 'Игрушки', label: Language.hobbies._19 },
  { value: 'Изучение иностранных языков', label: Language.hobbies._20 },
  { value: 'Интернет', label: Language.hobbies._21 },
  { value: 'Йога', label: Language.hobbies._22 },
  { value: 'Исполнение музыки', label: Language.hobbies._23 },
  { value: 'Историческое фехтование', label: Language.hobbies._24 },
  { value: 'Катание на коньках', label: Language.hobbies._25 },
  { value: 'Коллекционирование ', label: Language.hobbies._26 },
  { value: 'Конный спорт', label: Language.hobbies._27 },
  { value: 'Кроссворды', label: Language.hobbies._28 },
  { value: 'Кулинария', label: Language.hobbies._29 },
  { value: 'Ландшафтный дизайн', label: Language.hobbies._30 },
  { value: 'Лыжи', label: Language.hobbies._31 },
  { value: 'Массаж', label: Language.hobbies._32 },
  { value: 'Медитации', label: Language.hobbies._33 },
  { value: 'Настрольные игры', label: Language.hobbies._34 },
  { value: 'Общение', label: Language.hobbies._35 },
  { value: 'Парашютный спорт', label: Language.hobbies._36 },
  { value: 'Пейнтбол', label: Language.hobbies._37 },
  { value: 'Пение', label: Language.hobbies._38 },
  { value: 'Плавание', label: Language.hobbies._39 },
  { value: 'Пляжный отдых', label: Language.hobbies._40 },
  { value: 'Посещение музеев и выставок', label: Language.hobbies._41 },
  { value: 'Походы', label: Language.hobbies._42 },
  { value: 'Прогулки ', label: Language.hobbies._43 },
  { value: 'Прослушивание лекций', label: Language.hobbies._44 },
  { value: 'Просмотр театральных постановок', label: Language.hobbies._45 },
  { value: 'Просмотр телепередач', label: Language.hobbies._46 },
  { value: 'Просмотр фильмов', label: Language.hobbies._47 },
  { value: 'Растения', label: Language.hobbies._48 },
  { value: 'Ролевые игры', label: Language.hobbies._49 },
  { value: 'Ролики', label: Language.hobbies._50 },
  { value: 'Рукоделие', label: Language.hobbies._51 },
  { value: 'Рыбалка', label: Language.hobbies._52 },
  { value: 'Силовые тренировки', label: Language.hobbies._53 },
  { value: 'Создание мультфильмов', label: Language.hobbies._54 },
  { value: 'Сочинение музыки', label: Language.hobbies._55 },
  { value: 'Спелеология ', label: Language.hobbies._56 },
  { value: 'Спорт', label: Language.hobbies._57 },
  { value: 'Танцы', label: Language.hobbies._58 },
  { value: 'Театр', label: Language.hobbies._59 },
  { value: 'Тренинги', label: Language.hobbies._60 },
  { value: 'Туризм', label: Language.hobbies._61 },
  { value: 'Фотоохота', label: Language.hobbies._62 },
  { value: 'Чайная церемония', label: Language.hobbies._63 },
  { value: 'Чтение книг', label: Language.hobbies._64 },
  { value: 'Яхтенный спорт', label: Language.hobbies._65 }
]