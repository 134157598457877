import Select from "components/UI/Fields/Select";
import { getCities, getCity, getCountries, getCountry, getRegion } from "libs/getLocation";
import React from 'react';
import { change, Field } from "redux-form";
import PropTypes from 'prop-types';
import Language from "language/index";
import { connect } from 'react-redux';

class Region extends React.PureComponent {

    static propTypes = {
        countryLabel: PropTypes.string,
        regionLabel: PropTypes.string,
        cityLabel: PropTypes.string
    };

    static defaultProps = {
        countryLabel: Language.Country,
        regionLabel: Language.regionResidence,
        cityLabel: Language.cityResidence
    };


    state = {
        countryId: null,
        stateId: null,
        cityId: null,
        countryName: '',
        stateName: '',
        cityName: '',
        optionsCountry: [],
        optionsRegion: [],
        optionsCity: []
    };

    setValue = (field, value) => this.props.dispatch(change(this.props.form, field, value));

    changeRegionRequest = () => {
        const cbCountry = optionsRegion => this.setState({ optionsRegion });
        const cbCity = optionsCity => this.setState({ optionsCity });

        getCountry({value: this.state.countryId}, cbCountry);
        getCities(this.state.cityId, cbCity);
    };

    handleChangeCountry = value => {
        if (value) {
            this.setState({ countryId: value.value, countryName: value.label });
            this.setValue('address', { countryId: value.value });
            getCountry(value, optionsRegion => {
                this.setState({
                    optionsRegion,
                    stateId: null,
                    cityId: null
                });
            }, optionsCity => this.setState({ optionsCity, countryId: null }));
        } else return false;
    };

    handleChangeRegion = value => {
        if (value) {
            this.setState({ stateId: value.value, stateName: value.label });
            getRegion(this.state.countryId, value, optionsCity => {
                this.setState({ optionsCity, cityId: null });
            });
        } else return false;
    };

    handleSelectChangeCity = value => {
        if (value) {
            this.setState({ cityId: value.value, cityName: value.label });
        } else return false;
    };

    componentDidMount() {
        const { address } = this.props;
        if (address) this.setState({ ...address }, this.changeRegionRequest);
        getCountries(optionsCountry => this.setState({ optionsCountry }));
    }


    handleSearchCity = inputValue => {
        if (inputValue.length > 0) {
            getCity(this.state.countryId, this.state.stateId, inputValue, optionsCity => this.setState({ optionsCity }));
        }
    };

    render() {
        const { countryLabel, regionLabel, cityLabel } = this.props,
            { optionsCountry, optionsRegion, optionsCity, countryId, stateId } = this.state;

        return (
            <React.Fragment>
                <Field
                    name="address[countryId]"
                    component={Select}
                    label={countryLabel}
                    filterOptions
                    searchable
                    placeholder={Language.ChooseCountry}
                    options={optionsCountry}
                    change={this.handleChangeCountry}
                />

                {
                    !!countryId &&
                    <Field
                        name="address[stateId]"
                        component={Select}
                        label={regionLabel}
                        filterOptions
                        searchable
                        placeholder={Language.ChooseARegion}
                        options={optionsRegion}
                        change={this.handleChangeRegion}
                    />
                }

                {
                    !!stateId &&
                    <Field
                        name="address[cityId]"
                        component={Select}
                        label={cityLabel}
                        searchable
                        placeholder={Language.ChooseACity}
                        options={optionsCity}
                        change={this.handleSelectChangeCity}
                        onChangeSelect={this.handleSearchCity}
                    />
                }
            </React.Fragment>
        )
    }
}


export default connect()(Region);
