import { dateToString, stringToDate } from 'libs/convertDate';

export const required = val => {
    const err = 'Необходимо заполнить это поле';
    if (val instanceof Array)
        return val.length === 0 ? err : undefined;
    if (val instanceof Object && Object.keys(val).length > 0) {
        for (let key in val) {
            if (val[key]) {
                return undefined;
            }
        }
    }
    return val ? undefined : err;
};


export const moneyValidate = val => val instanceof Object && val.amount && val.currency ? undefined : 'Error';

export const volumeValidate = val => val instanceof Object && val.amount && val.unit ? undefined : 'Error';

export const timePeriodValidate = val => val instanceof Object && val.time && val.period ? undefined : 'Error';

export const regionValidate = val => val instanceof Object && val.countryId ? undefined : 'Error';

export const industryValidate = val => val instanceof Object && val.groupName ? undefined : 'Error';

export const datePickerValue = val => {
    if (val && typeof(val.getMonth) !== 'function') {
        try {
            let d = stringToDate(val);
            return (d instanceof Date && !isNaN(d)) === false ? 'Неверный формат даты' : undefined;
        } catch (e) {
            return 'Неверный формат даты';
        }
    }
};

export const phones = val => {
    return val && val.replace(/\D+/g, "").length !== 11 ?
        'Некорректный телефон' : undefined;
};

export const password = val => val && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(val) ?
    'Некорректный пароль' : undefined;

export const passwordConfirm = (pass1, pass2) => {
    if (pass2 && pass2.length > 0)
        return pass1 === pass2 ? undefined : 'Пароли не совпадают';
    else return undefined;
};

export const code = val => val && (!/[0-9]{4,}$/.test(val) || val.length !== 4) ?
    'Некорректный код' : undefined;

export const name = val => val && (val.replace( /\s/g, '').length < 3 || !/[a-zA-Zа-яА-Я]{3,}$/.test(val)) ?
    'Некорректное имя' : undefined;

export const lastnames = val => val && (val.replace( /\s/g, '').length < 3 || !/[a-zA-Zа-яА-Я]{3,}$/.test(val)) ?
    'Некорректная фамилия' : undefined;


export const clientPassport = val => {
    if (val && val.length === 11) {
        let path = val.split(' ');
        return path[0].match(/^\d{4}$/) && path[1].match(/^\d{6}$/) ? undefined : 'Неверный формат паспорта'
    }
};

/**
 * Проверка даты на текущий день
 * или проверка двух дат
 * nextDate(дата выгрузки или точка маршрута)
 * должна быть позже или равна начальной дате
 */
export const loadDate = (date, nextDate) => {
    if (date) {

        let dateNow = dateToString(new Date(Date.now())).split('.'),
            currentDate = dateToString(new Date(date)).split('.');

        if (nextDate) {
            dateNow = dateToString(new Date(date)).split('.');
            currentDate = dateToString(new Date(nextDate)).split('.');
        }

        if (dateNow[2] < currentDate[2]) return undefined;
        else if (dateNow[2] === currentDate[2] && dateNow[1] < currentDate[1]) return undefined;
        else if (dateNow[2] === currentDate[2] && dateNow[1] === currentDate[1] && dateNow[0] <= currentDate[0]) return undefined;
        else return 'Error date';
        // TODO: Написать более подходящий текст ошибки или передавать текст отдельно под ситуцию
    }
};

export const maxLength = (max) => (value) =>
    value && value.length > max ? `Max ${max} chars` : undefined;

export const minLength = (min) => (value) =>
    value && value.length < min ? `Min ${min} chars` : undefined;

export const emails = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Email неправильного формата' : undefined;

export const login = (value) => value && (!/[a-zA-Z0-9]{3,}$/.test(value) || value.length > 10) ?
    'Неправильный логин, используйте только англ. буквы или цифры. Колличество символов от трёз до десяти' : undefined;


export const confirmPassword = (password, passwordAgain) => passwordAgain === password ?
    undefined : 'Пароли не совпадают';

export const defaultTime = (value) => {
    if (value && value.length === 5) {
        let path = value.split(':');
        return parseInt(path[0]) > 24 || parseInt(path[1]) > 59 || (parseInt(path[0]) === 24 && parseInt(path[1]) > 0) ?
            'Неверный формат времени' : undefined;
    }
};
