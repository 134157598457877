import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import classes from './styles.module.scss';

export const Button = (props) => {
  const { children, size = 'large', variant = 'contained', icon, color, className, ...rest } = props;
  const isIcon = Boolean(icon) && !Boolean(children);

  return (
    <button
      className={cn(classes.button, classes[size], classes[variant], classes[color], className, {
        [classes.icon]: isIcon,
      })}
      {...rest}
    >
      {isIcon ? icon : children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  variant: PropTypes.oneOf(['outlined', 'contained', "normal"]),
  icon: PropTypes.node,
  className: PropTypes.string,
};
