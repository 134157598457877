import React, { Component } from 'react';
import MappingDataProfile from "../../../fragment/index";


class UserDescAdditionalData extends Component {

  state = {
    items: []
  };


  componentDidMount() {
    let items = [];
    for (let key in this.props) {
      items = [...items, MappingDataProfile(key, this.props[key], this.props)]
    }
    this.setState({ items })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      let items = [];
      for (let key in nextProps) {
        items = [...items, MappingDataProfile(key, nextProps[key], nextProps)]
      }
      this.setState({ items })
    }
  }

  render() {
    return (
      <table className="sb-table">
        <tbody>
        {
          this.state.items.map((el, index) => {
            if (el === undefined) return null;
            return (
              <tr key={index}>
                <td><span>{el.title}</span></td>
                {
                  typeof(el.data) === 'string' ?
                    <td dangerouslySetInnerHTML={{ __html: el.data }}/> :
                    <td>{el.data}</td>
                }
              </tr>
            )
          })
        }
        </tbody>
      </table>
    )
  }
}


export default UserDescAdditionalData;