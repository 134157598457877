import React, { Component } from 'react';
import Language  from "language/index";

class CenterSide extends Component {
  render() {
    return (
      <div className="centerside">
        <div className="side-block">
          <div className="title-block">
            <div className="flex jcsb align">

              <h1> {Language.UsersPersonalPrivacy} </h1>

            </div>
          </div>
        </div>

        <div className="side-block mt15 terms-content">
          <div className="element clearfix">

            <div className="test" id="GENERALTERMS" />
            <div >
              <h3> {Language.GENERALTERMS}  </h3>
              <p>
                {Language.GENERALTERMS_1_1}
              </p>
              <p>
                {Language.GENERALTERMS_1_2}
              </p>
              <p>
                {Language.GENERALTERMS_1_3}
              </p>
              <p>
                {Language.GENERALTERMS_1_4.one}
                <a href={Language.GENERALTERMS_1_4.link}>{Language.GENERALTERMS_1_4.link}</a>
                {Language.GENERALTERMS_1_4.two}
              </p>
              <p>
                {Language.GENERALTERMS_1_5}
              </p>
              <p>
                {Language.GENERALTERMS_1_6}
              </p>
              <p>
                {Language.GENERALTERMS_1_7}
              </p>
              <p>
                {Language.GENERALTERMS_1_8}
              </p>
              <p>
                {Language.GENERALTERMS_1_9}
              </p>
              <p>
                {Language.GENERALTERMS_1_10}
              </p>
              <p>
                {Language.GENERALTERMS_1_11}
              </p>

            </div>
            <div className="test" id="DEFINITIONOFTERMS" />
            <div>
              <h3> {Language.DEFINITIONOFTERMS} </h3>

              <p>
                {Language.DEFINITIONOFTERMS_1}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_2.one}
                <a href={Language.DEFINITIONOFTERMS_2.link}>{Language.DEFINITIONOFTERMS_2.link}</a>
                {Language.DEFINITIONOFTERMS_2.two}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_3}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_4}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_5}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_6}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_7}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_8}
              </p>

              <p>
                {Language.DEFINITIONOFTERMS_9}
              </p>
            </div>
            <div className="test" id="INFORMATIONABOUTUSERS" />
            <div>
              <h3> {Language.INFORMATIONABOUTUSERS} </h3>
              <p>
                {Language.INFORMATIONABOUTUSERS_1}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_2}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_3}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_4}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_5}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_6}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_7}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_8}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_9}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_10}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_11}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_12}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_13}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_14}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_15}
              </p>
              <p>
                {Language.INFORMATIONABOUTUSERS_16}
              </p>
            </div>
            <div className="test" id="PURPOSEPROCESSINGPERSONAL" />
            <div>
              <h3> {Language.PURPOSEPROCESSINGPERSONAL} </h3>
              <p>
                {Language.PURPOSEPROCESSINGPERSONAL_1}
              </p>
              <p>
                {Language.PURPOSEPROCESSINGPERSONAL_2}
              </p>
            </div>
            <div className="test" id="PROCESSINGUSERPERSONAL" />
            <div>
              <h3> { Language.PROCESSINGUSERPERSONAL } </h3>
              <p>
                {Language.PROCESSINGUSERPERSONAL_1}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_2}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_3}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_4}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_5}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_6}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_7}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_8}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_9}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_10}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_11}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_12}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_13}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_14}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_15}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_16}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_17}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_18}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_19}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_20}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_21}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_22}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_23}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_24}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_25}
              </p>
              <p>
                {Language.PROCESSINGUSERPERSONAL_26}
              </p>
            </div>
            <div className="test" id="RIGHTSOBLIGATIONSUSERS" />
            <div>
              <h3> {Language.RIGHTSOBLIGATIONSUSERS} </h3>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_1 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_2 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_3 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_4 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_5 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_6 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_7 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_8 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_9 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_10 }
              </p>
              <p>
                { Language.RIGHTSOBLIGATIONSUSERS_11 }
              </p>
              <p>
                <ul>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._1 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._2 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._3 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._4 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._5 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._6 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._7 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._8 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._9 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._10 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._11 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._12 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._13 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._14 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._15 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._16 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._17 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._18 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._19 }</li>
                  <li>{ Language.RIGHTSOBLIGATIONSUSERS_12._20 }</li>
                </ul>

              </p>

            </div>
            <div className="test" id="OBLIGATIONSSITEADMINISTRATION" />
            <div>
              <h3> {Language.OBLIGATIONSSITEADMINISTRATION} </h3>
              <p>
                { Language.OBLIGATIONSSITEADMINISTRATION_1 }
              </p>
              <p>
                { Language.OBLIGATIONSSITEADMINISTRATION_2 }
              </p>
              <p>
                { Language.OBLIGATIONSSITEADMINISTRATION_3 }
              </p>
              <p>
                { Language.OBLIGATIONSSITEADMINISTRATION_4 }
              </p>
            </div>
            <div className="test" id="MEASURESPROTECTUSER" />
            <div>
              <h3> {Language.MEASURESPROTECTUSER} </h3>
              <p>
                { Language.MEASURESPROTECTUSER_1 }
              </p>
              <p>
                { Language.MEASURESPROTECTUSER_2 }
              </p>
              <p>
                { Language.MEASURESPROTECTUSER_3 }
              </p>
              <p>
                { Language.MEASURESPROTECTUSER_4 }
              </p>
              <p>
                { Language.MEASURESPROTECTUSER_5 }
              </p>
            </div>

            <div className="test" id="RESPONSIBILITYPARTIES" />
            <div>
              <h3> {Language.RESPONSIBILITYPARTIES} </h3>
              <p>
                { Language.RESPONSIBILITYPARTIES_1 }
              </p>
              <p>
                { Language.RESPONSIBILITYPARTIES_2 }
              </p>
              <p>
                { Language.RESPONSIBILITYPARTIES_3 }
              </p>
              <p>
                { Language.RESPONSIBILITYPARTIES_4 }
              </p>
              <p>
                { Language.RESPONSIBILITYPARTIES_5 }
              </p>
            </div>

            <div className="test" id="ADDITIONALCONDITIONS" />
            <div>
              <h3> {Language.ADDITIONALCONDITIONS} </h3>
              <p>
                { Language.ADDITIONALCONDITIONS_1.text }
                <a href={ Language.ADDITIONALCONDITIONS_1.link }>{ Language.ADDITIONALCONDITIONS_1.link }</a>
              </p>
              <p>
                { Language.ADDITIONALCONDITIONS_2 }
              </p>
              <p>
                { Language.ADDITIONALCONDITIONS_3 }
              </p>
              <p>
                { Language.ADDITIONALCONDITIONS_4 }
              </p>
              <p>
                { Language.ADDITIONALCONDITIONS_5 }
              </p>
              <p>
                { Language.ADDITIONALCONDITIONS_6 }
              </p>
              <p>
                { Language.ADDITIONALCONDITIONS_7 }
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CenterSide;