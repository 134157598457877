import React, { Component } from 'react';

export default class NotificationSuccess extends Component {

    render() {
        const { text, close } = this.props;
        return (
            <div className="notification success" onClick={close}>
                <i className="panton c"> G </i>
                <strong> Успешно </strong>
                <div className="container">
                    <p>{text}</p>
                    {/*<a href="#" className="close-notify"><img src="templates/img/w-arrow.png" alt=""/></a>*/}
                </div>
            </div>
        )
    }
}
