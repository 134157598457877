import React, { Component } from 'react';


class NotFound extends Component {

    render() {
        const { title, img } = this.props;
        return (
            <div className="side-block">
                <div className="sb-title">
                    <em> {title} </em>
                </div>
                <div className="flex not_found_request" style={{ paddingTop: '70px', paddingBottom: '80px' }}>
                    <aside className="aside__not_found_request">
                        <img src={`${img ? img : '/templates/img/file-3.svg'}`} alt="..." style={{ maxHeight: '78px' }}/>
                    </aside>
                    <div className="w75">
                        <p style={{ color: '#636f80' }}>
                            {this.props.children}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}


export default NotFound;