import React, { Component } from 'react';
import RightSide from "./right";
import CenterSide from "./center";

class TechnicalSupport extends Component {

  render() {
    return (
      <React.Fragment>
        <CenterSide/>
        <RightSide/>
      </React.Fragment>
    );
  }
}

export default TechnicalSupport;