import React from 'react';
import cn from 'classnames';
import { Avatar } from 'components/v1/Avatar';
import { userTypeDesc } from 'libs/uaerTypes';

import classes from './styles.module.scss';
import { Link } from 'react-router-dom';


export const User = ({ avatar: source, firstName, lastName, typeKeys, onlineStatus, size, actions, _id }) => {
  return (
    <div className={cn(classes.user, classes[size])}>
      <div className={classes.main}>
        <Link to={`/profile/${_id}`} className={classes.link} />
          <Avatar source={source} size={size} type="circle" />
          <div className={classes.detail}>
            <div className={classes.name}>
              {firstName} {lastName}
              {onlineStatus?.isOnline && <div className={classes.online} />}
            </div>
            <span>{typeKeys && userTypeDesc(typeKeys[0])}</span>
          </div>
      </div>
      <div className={classes.actions}>
        {actions}
      </div>
    </div>
  );
};
