import FavoritesDialogs from "components/UI/FavoritesDialogs/index";
// import SendMessage from "components/UI/Forms/SendMessage/index";
import MessagesListOld from "components/UI/Messages/MessagesListOld";
import UserInfo from "components/UI/Messages/UserInfo";
import Loading from "components/UI/Prealoders/Loading";
import PreloaderCircle from "components/UI/Prealoders/PreloaderCircle";
import SideBlock from "components/UI/SideBlock/index";
import { getItem } from "libs/localStorage";
import { GET_DIALOG_BY_ID } from "query/dialogs";
import { GET_MESSAGES } from "query/messages";
import { GET_MY_ID } from "query/profile";
import React, { Component, Fragment } from 'react';
import { Query } from "react-apollo";
import { UPDATE_MESSAGES } from "subscription/index";
import SendMessage from "../../UI/Messages/message-list/parts/send-message";

const Search = () => {
    return (
        <div className="search-form-dialogs form">
            <form action="" target="post_form">
                <div className="element">
                    <div className="add-clear-span">
                        <input type="text" name="search" placeholder="Поиск сообщения"/>
                        {/*<a href="#clear" style="display: none; color: rgb(204, 204, 204); text-decoration: none; overflow: hidden; position: absolute; right: 4px; top: 1px; line-height: 1;">✖</a>*/}
                    </div>
                    <button type="submit">Найти</button>
                </div>
            </form>
        </div>
    )
};


class Messages extends Component {


    render() {
        const { userFrom, userTo, offer, favorite } = this.props.getDialogById,
            { id } = this.props.match.params,
            { _id } = this.props.getMyProfile,
            user = userFrom._id === _id ? userTo : userFrom;


        return (
            <Fragment>
                <div className="centerside">
                    <UserInfo user={user} favorite={favorite} _id={id} dialogId={id}/>
                    <SideBlock className="messages_list">
                        <Query query={GET_MESSAGES} variables={{ "dialogId": id, count: 50 }}
                               fetchPolicy='cache-and-network'>
                            {({ loading, data, error, subscribeToMore, fetchMore }) => {
                                if (error) return <p> Error.... </p>;
                                if (loading) return <Loading/>;
                                return (
                                    <div className="side-block" id="scrollable_messages">
                                        <div className="s-messages" id="scroll_block_messages">
                                            <MessagesListOld
                                                subscribeToNewComments={() => subscribe(subscribeToMore, this.props.match.params.id, _id)}
                                                {...data.DialogGetter.getMessagesByDialogId}
                                                userFrom={userFrom}
                                                userTo={userTo}
                                                offer={offer}
                                                _id={_id}
                                                dialogId={id}
                                            />
                                        </div>
                                    </div>

                                )
                            }}
                        </Query>
                    </SideBlock>
                    <SendMessage dialogId={id} client={this.props.client}/>
                </div>
                <div className="rightside">
                    <FavoritesDialogs _id={_id}/>
                </div>
            </Fragment>
        )
    }
}


export default props => (
    <Query query={GET_DIALOG_BY_ID} variables={{ "dialogId": props.match.params.id }} fetchPolicy='cache-and-network'>
        {({ loading, data, client, error }) => {
            if (error) return <p> Произошла ошибка получения диалога </p>;
            if (!data.getDialogById && loading) return <PreloaderCircle/>;
            let { ProfileGetter } = client.cache.readQuery({ query: GET_MY_ID });
            return <Messages
                client={client}
                {...data.DialogGetter}
                {...ProfileGetter}
                {...props}
            />
        }}
    </Query>
);

const subscribe = (subscribeToMore, dialogId) => {
    const token = getItem('token', null);
    subscribeToMore({
        document: UPDATE_MESSAGES,
        variables: { dialogId, token },
        updateQuery: (prev, { subscriptionData }) => {
            console.log(prev);
            console.log(subscriptionData);
            if (!subscriptionData.data) return prev;
            const newFeedItem = subscriptionData.data.updateMessages;
            let { messages } = prev.DialogGetter.getMessagesByDialogId;
            if (messages.some(el => el._id === newFeedItem._id)) {
                messages.map(el => {
                    if (el._id === newFeedItem._id) {
                        el.isRead = true;
                    }
                    return el
                });

                return {
                    ...prev,
                    DialogGetter: {
                        getMessagesByDialogId: {
                            messages: [...messages],
                            total: prev.DialogGetter.getMessagesByDialogId.total,
                            __typename: "Messages"
                        },
                        __typename: "getDialogs"
                    }
                }

            }
            return {
                ...prev,
                DialogGetter: {
                    getMessagesByDialogId: {
                        messages: [newFeedItem, ...prev.DialogGetter.getMessagesByDialogId.messages],
                        total: prev.DialogGetter.getMessagesByDialogId.total + 1,
                        __typename: "Messages"
                    },
                    __typename: "getDialogs"
                }
            }
        }
    })
};
