import { required } from "libs/fieldValidation";
import showError, { showErrorReg } from "libs/showFormErrors";
import { emails, lastnames, name } from "libs/validationsForms";
import ym from "react-yandex-metrika";

export const submitLegalEntityFormForm = (values, mutate, cb) => {

    const { firstName, lastName, companyName, industry, email, address } = values;

    let validateObj = {
        'firstName': [required(firstName)],
        'lastName': [required(lastName)],
        'companyName': [required(companyName)],
        'industry.groupName': [required(industry)],
        'email': [required(email)],
        'address.countryId': [required(address)]
    };

    if (email) validateObj['email'] = [emails(email)];

    if (firstName) validateObj['firstName'] = [name(firstName)];
    if (lastName) validateObj['lastName'] = [lastnames(lastName)];

    if (address) {
        validateObj['address.countryId'] = [required(address.countryId)];
        validateObj['address.stateId'] = [required(address.stateId)];
        validateObj['address.cityId'] = [required(address.cityId)];
    }

    if (industry) {
        validateObj['industry.groupName'] = [required(industry.groupName)];
        validateObj['industry.name'] = [required(industry.name)];
    }


    if (showErrorReg(validateObj) && values.__typename === 'Client') {
        ym('reachGoal', 'regerror')
    }

    showError(validateObj);

    const fields = [
        'firstName', 'lastName', 'companyName',
        'industry', 'email', 'termOfWork', 'address',
        'site', 'description', 'personalLink', '_id', 'typeKeys'
    ];

    // typeKeys: ['Investor', 'BusinessProduct', 'Innovator', 'BusinessWoman', 'Unemployed', 'Provider']

    const value = { ...values };

    for (let key in value) {
        if (!!value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
        if (!fields.some(el => el === key)) {
            delete value[key];
        } else if (!!value[key] && value[key].hasOwnProperty('count')) {
            value[key].count = parseInt(value[key].count);
        }
    }

    mutate({ variables: { profile: { ...value } } })
};