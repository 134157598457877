import React, { useState } from 'react'
import { Query } from 'react-apollo';

import { Post, PostForm } from 'components/v1/Post'
import { EmptyContent } from 'components/v1/EmptyContent'
import { RecommendContacts } from 'components/v1/RecommendContacts';
import { AsideTabs } from 'components/v1/AsideTabs';

import { GET_NEWS, GET_USER_NEWS } from 'query/news';

import styles from './styles.module.scss'
import classes from 'pages/Profile/components/MyContacts/styles.module.scss'
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import InfiniteScroll from 'react-infinite-scroller';
import { GET_MY_ID } from 'query/profile';
import { HeaderMobile } from 'components/v1/HeaderMobile';

const NewsPage = ({ isProfilePage, isMyPage, userId }) => {

  const [currentTab, setCurrentTab] = useState(Boolean(isProfilePage) ? 'user' : 'latest')
  const [page, setPage] = useState(1)

  const tabs = [
    { value: 'latest', label: 'Последние новости' },
    { value: 'contacts', label: 'Новости контактов' },
  ]


  const loadMore = page => {
    setPage(page)
  };

  const changeTab = (val) => {
    if (val !== currentTab) {
      setPage(1)
      setCurrentTab(val)
    }
  }

  return (
    <Query query={GET_MY_ID}>
      {({ data, loading }) => {
        if (data.ProfileGetter) {
          const { getMyProfile } = data.ProfileGetter
          return (
            <div className={isProfilePage ? classes.root : styles.container}>
              {!isProfilePage && (
                <HeaderMobile goBack title="Новости" />
              )}
              <div className={styles.main}>
                <Query
                  query={GET_USER_NEWS}
                  variables={{ page: 0, count: page * 15, feedType: currentTab, ...(userId ? { userId } : {}) }}
                  fetchPolicy={'network-only'}
                >
                  {({ data, loading, error }) => {
                    if (data?.UserNewsGetter) {
                      return (
                        <>
                          {isMyPage && (
                            <PostForm variables={{ page: 0, count: page * 15, feedType: currentTab }} />
                          )}
                          {!isProfilePage && !isMyPage && (
                            <PostForm variables={{ page: 0, count: page * 15, feedType: currentTab }} />
                          )}
                          {data.UserNewsGetter.getNews.total === 0 && (
                            <EmptyContent className={classes.empty}/>
                          )}
                          {data.UserNewsGetter.getNews.total > 0 && (
                            <InfiniteScroll
                              pageStart={1}
                              loadMore={loadMore}
                              hasMore={!loading && page * 10 < data.UserNewsGetter.getNews.total}
                              loader={<PreloaderCircle key={2351}/>}
                            >
                              {
                                data.UserNewsGetter.getNews.news.map((el) => {
                                  return (
                                    <Post
                                      key={el._id}
                                      title={el.title}
                                      content={el.content}
                                      isMe={getMyProfile._id === el?.userFrom?._id}
                                      {...el}
                                    />
                                  )
                                })
                              }
                            </InfiniteScroll>
                          )}
                        </>
                      )}
                  }}
                </Query>
              </div>
              <aside>
                {!isProfilePage && (
                  <AsideTabs items={tabs} activeTab={currentTab} onChangeTab={changeTab}/>
                )}
                <RecommendContacts typeUser={getMyProfile.typeKeys[0]} />
              </aside>
            </div>
          )
        }
      }}
    </Query>
  )
}

export default NewsPage
