import React, { PureComponent } from 'react';

export default class Tabs extends PureComponent {

    render() {
        const { item, changeTab, activeTabIndex, isSpan, count } = this.props;
        return (
            <ul className="tabs">
                {
                    item.map((el, index) => {
                        return (
                            <li
                                key={index}
                                className={`${index === activeTabIndex ? 'active' : ''}`}
                                onClick={() => changeTab(index)}
                            >
                                {
                                    isSpan ?
                                        <span>{el.title}</span> :
                                        el.title
                                }

                                {
                                    el.count !== undefined &&
                                    <i>{el.count}</i>
                                }
                            </li>
                        )
                    })
                }
            </ul>
        )
    }
}