import PreloaderCircle from "components/UI/Prealoders/PreloaderCircle";
import { dateToString } from "libs/convertDate";
import { STATISTIC_REGISTRATION_USERS } from "query/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";


class StatisticUsers extends Component {

    state = {
        page: 1,
        loading: false,
    };


    render() {
        const { rows } = this.props.getReportRegistrationUsersByDate;

        let rowss = [...rows];
        return (
            <React.Fragment>
                <div className="side-block">
                    <div className="s-messages">
                        <ul className="mes-list">
                            {
                                rowss.reverse().map((el, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="day">
                                                {dateToString(new Date(el.date), 'stringDate')}
                                            </div>
                                            <div className="amount" style={{ paddingTop: '10px' }}>
                                                Зарегистрировалось: {el.count}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default props => {
    return (
        <Query query={STATISTIC_REGISTRATION_USERS} variables={{ ...props.variables }}>
            {({ data, loading, error, fetchMore }) => {
                if (error) return 'Error...';
                if (loading) return <PreloaderCircle/>;
                return <StatisticUsers fetchMore={fetchMore} {...props} {...data.ReportGetter}/>
            }}
        </Query>
    )
};