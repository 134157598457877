import SearchFormRedux from "components/UI/Forms/SearchUser/index";
import React, { Component } from 'react';
import Language from "language/index";


class SearchUser extends Component {

  state = {
    isAdditionalSearch: false
  };

  toggleAdditionalSearch = () => {
    this.setState({ isAdditionalSearch: !this.state.isAdditionalSearch })
  };

  render() {
    const { isAdditionalSearch } = this.state;
    let title = isAdditionalSearch ? Language.scrollUp : Language.extended;
    return (
      <div className="side-block other--styles">
        <div className="pickup">
          <div className="sb-title">
            <span className="pointer">{Language.search}</span>
            <span
              className="extend"
              onClick={this.toggleAdditionalSearch}>
                            {title}
                        </span>
          </div>
          <SearchFormRedux {...this.props} isAdditionalSearch={isAdditionalSearch}/>
        </div>
      </div>
    )
  }
}


export default SearchUser;
