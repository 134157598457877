import { ApolloClient, ApolloLink, HttpLink } from 'apollo-boost';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { Observable, split } from "apollo-link";
import { onError } from "apollo-link-error";

import { withClientState } from 'apollo-link-state';

import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from "apollo-utilities";

import defaults from 'apollo/defaults';
import resolvers from 'apollo/resolvers';
import typeDefs from 'apollo/typeDefs';
import { getItem } from "libs/localStorage";

import introspectionQueryResultData from './fragmentTypes.json';
import { ActionNotification } from "components/UI/Notifications/action";
import { ErrorHandler } from "libs/errorHandler";

import fetch from 'isomorphic-unfetch';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const stateLink = withClientState({
    resolvers,
    defaults,
    cache,
    typeDefs,
});

const request = async (operation) => {
    const token = getItem('token', false);
    operation.setContext(({ headers }) => {
        return {
            headers: {
                ...headers,
                credentials: 'include',
                token: token ? token : null
            }
        };
    });
};

const requestLink = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
        let handle;
        Promise.resolve(operation)
            .then(oper => request(oper))
            .then(() => {
                handle = forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            })
            .catch(observer.error.bind(observer));
        return () => {
            if (handle) handle.unsubscribe();
        };
    })
});

// const wsLink = new WebSocketLink({
//     uri: `ws://188.120.245.51:3000/graphql`,
//     options: {
//         reconnect: true
//     }
// });
//
// export const client = new ApolloClient({
//     link: ApolloLink.from([
//         requestLink,
//         stateLink,
//         wsLink,
//         new HttpLink({
//             uri: 'https://cors-anywhere.herokuapp.com/http://188.120.245.51:88',
//         })
//     ]),
//     cache,
//     connectToDevTools: true
// });


const httpLink = new HttpLink({ uri: `/graphql`, fetchOptions: { fetch }  });

const token = getItem('token', null);

const wsLink = new WebSocketLink({
    uri: 'wss://newnext.ru/graphql',
    options: {
        reconnect: true,
        connectionParams: { token }
    },
});

const middlewareLink = new ApolloLink((operation, forward) => {
    let token = getItem('token', null);
    if (window.location.pathname === '/' && getItem('regToken', false)) token = getItem('regToken', false);

    operation.setContext({
        headers: {
            token: token,
        },
    });
    return forward(operation);
});

const middlewareLinkWs = new ApolloLink((operation, forward) => {
    const token = getItem('token', null);
    operation.setContext({
        options: {
            reconnect: true,
            connectionParams: { token }
        },
    });

    operation.token = token;
    return (forward)(operation);
});

const authorizedLink = middlewareLinkWs.concat(wsLink);

const setLink = middlewareLink.concat(httpLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ code, description }) => {
        if (code !== 'REPEAT_DIALOG') {
            ActionNotification('1', `${ErrorHandler(code, description ? description : 'Произошла ошибка')}`);
        }});
    if (networkError) ActionNotification('1', `${networkError}`);
});

const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    authorizedLink,
    setLink,
);

export const client = new ApolloClient({
    link: errorLink.concat(link),
    cache,
    connectToDevTools: true
});
