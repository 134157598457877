import React, { useEffect, useState } from 'react'

import { SelectController } from 'components/v1/HookFields/Select';

import styles from 'pages/Profile/EditForms/styles.module.scss';
import { getCities, getCity, getCountries, getCountry, getRegion } from 'libs/getLocation';
import Language from 'language';
import { InputController } from 'components/v1/HookFields/Input';

const defaultState = { value: null, label: '' }

const RegionGroup = ({
  control,
  setValue,
  watch,
}) => {
  const country = watch('address[countryId]')
  const region = watch('address[stateId]')
  const city = watch('address[cityId]')

  const [selectedCountry, setSelectedCountry] = useState(defaultState)
  const [selectedRegion, setSelectedRegion] = useState(defaultState)
  const [selectedCity, setSelectedCity] = useState(defaultState)

  const [optionsCountry, setOptionsCountry] = useState([])
  const [optionsRegion, setOptionsRegion] = useState([])
  const [optionsCity, setOptionsCity] = useState([])

  const changeRegionRequest = (address) => {
    const cbCountry = optionsRegion => setOptionsRegion(optionsRegion);
    const cbCity = optionsCity => setOptionsCity(optionsCity);

    getCountry({ value: address['countryId'] }, cbCountry);
    getCities(address['cityId'], cbCity);
  }

  useEffect(() => {
    const address = watch('address')

    if (address) {
      changeRegionRequest(address)
    }

    getCountries(optionsCountry => setOptionsCountry(optionsCountry))
  }, [])


  const onHandleChangeCountry = (value) => {
    if (value) {
      setSelectedCountry(value)
      setValue('address[countryName]', value.label)
      getCountry(value, optionsRegion => {
        setOptionsRegion(optionsRegion)
        setSelectedRegion(defaultState)
        setSelectedCity(defaultState)
        setValue('address[stateId]', defaultState.value)
        setValue('address[cityId]', defaultState.value)
      }, optionsCity => {
        setOptionsCity(optionsCity)
      })
    }
  }

  const onHandleChangeRegion = (value) => {
    if (value) {
      setSelectedRegion(value)
      setValue('address[stateName]', value.label)
      getRegion(country || selectedCountry.value, value, optionsCity => {
        setOptionsCity(optionsCity)
        setSelectedCity(defaultState)
        setValue('address[cityId]', defaultState.value)
      })
    }
  }

  const onHandleChangeCity = (value) => {
    if (value) {
      setSelectedCity(value)
      setValue('address[cityName]', value.label)
    }
  }

  const onHandleSearchCity = (inputValue) => {
    if (inputValue.length > 0) {
      getCity(country || selectedCountry.value, region || selectedRegion.value, inputValue, optionsCity => setOptionsCity(optionsCity));
    }
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <InputController
          control={control}
          type="hidden"
          name="address[countryName]"
        />
        <InputController
          control={control}
          type="hidden"
          name="address[stateName]"
        />
        <InputController
          control={control}
          type="hidden"
          name="address[cityName]"
        />
      </div>
      <div className={styles.section}>
        <div>Страна проживания</div>
        <SelectController
          control={control}
          name="address[countryId]"
          filterOptions
          searchable
          placeholder={Language.ChooseCountry}
          options={optionsCountry}
          label={''}
          change={onHandleChangeCountry}
        />
      </div>
      {(!!selectedCountry.value || country) && (
        <div className={styles.section}>
          <div>Регион</div>
          <SelectController
            control={control}
            name="address[stateId]"
            filterOptions
            searchable
            placeholder={Language.ChooseARegion}
            options={optionsRegion}
            label={''}
            change={onHandleChangeRegion}
          />

        </div>
      )}
      {(!!selectedRegion.value || region) && (
        <div className={styles.section}>
          <div>Город</div>
          <SelectController
            control={control}
            name="address[cityId]"
            searchable
            placeholder={Language.ChooseACity}
            options={optionsCity}
            label={''}
            change={onHandleChangeCity}
            onChangeSelect={onHandleSearchCity}
          />
        </div>
      )}
    </>
  )
}


export default RegionGroup
