import React, { Component } from 'react';
import DescBlock from "./DescBlock";
import Language  from "language/index";

class HelpBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slice: false,
      textBtn: Language.showMore,
    };
    this.showAll = this.showAll.bind(this);
  }

  showAll(e) {
    e.preventDefault();
    this.setState({
      slice: !this.state.slice,
      textBtn: this.state.slice ? Language.showMore : Language.Hide
    });
  }

  render() {
    const ShowBtn = this.props.block.list.length > 7;
    const left = this.props.block.list.length > 7 ? this.props.block.list.length - 7 : '';
    return (
      <div className="side-block mt15">
        <div id={this.props.block.id} className="test"> </div>
        <div className="sb-title">
          <div className="element clearfix">
            <em className="help-title-block-flex">

              <span className="help-icon">
                <img src={this.props.block.imgSrc} alt=""/>
              </span>

              {this.props.block.title}

            </em>
          </div>
        </div>
        <ul className="blue-links help-list">
          {
            this.props.block.list.slice(0, this.state.slice ? this.props.block.list.length : 7).map((item, i) => {
              return (
                  <DescBlock info={item} key={i}/>
              );
            })
          }
        </ul>
        {
          ShowBtn &&
          <div className="help-more">
            <a href="#" onClick={this.showAll}>
              {this.state.textBtn}
              {
                !this.state.slice &&
                left
              }
            </a>
          </div>
        }
      </div>
    );
  }
}

export default HelpBlock;