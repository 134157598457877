import { dateToString } from "libs/convertDate";
import React from 'react';
import { declOfNum, GetIndustry } from "libs/getApplicationFields";
import Language from "language/index";

const MappingDataProfile = (field, fieldData, allData) => {
    if (fieldData) {
        switch (field) {
            case 'career':
                if (fieldData[0]) {
                    const { jobName, beginDate, endDate, position } = fieldData[0];
                    return {
                        title: `${Language.expWork}:`,
                        data: `${jobName} - ${position} - ${dateToString(new Date(beginDate), 'getYear')} - ${dateToString(new Date(endDate), 'getYear')}`
                    };
                } else return undefined;
            case 'educations':
                if (fieldData[0] && fieldData[0].institutionName) {
                    const { institutionName, specialty, completeDate } = fieldData[0];
                    return {
                        title: `${Language.education}:`,
                        data: `${institutionName} - ${specialty} - ${dateToString(new Date(completeDate), 'getYear')}`
                    };
                }
                else return undefined;
            case 'industry':
                const { groupName, name } = fieldData;
                if (groupName === null) return undefined;
                return {
                    title: `${Language.industry}:`,
                    data: GetIndustry(fieldData)
                };
            case 'termOfWork':
                return {
                    title: `${Language.periodWork}:`,
                    data: `${fieldData.count} ${fieldData.typeDate === 'month' ? declOfNum(fieldData.count, ['месяц','месяца', 'месяцев']) :
                        declOfNum(fieldData.count, ['год','года', 'лет'])}`
                };
            case 'maritalStatus':

                if (allData.sex) {
                    let current = parseInt(allData.sex) ===  1 ? 'one' : 'two';
                    return {
                        title: `${Language.maritalStatus}:`,
                        data: `${fieldData === 'married' ? Language.MarriedSex[current] : Language.SingleUnmarriedSex[current]}`
                    };
                }

                return {
                    title: `${Language.maritalStatus}:`,
                    data: `${fieldData === 'married' ? Language.Married : Language.SingleUnmarried}`
                };
            case 'hobby':
                if (fieldData[0])
                    return {
                        title: `${Language.hobby}:`,
                        data: `${fieldData[0]}`
                    };
                else return undefined;
            case 'email':
                return {
                    title: 'Email:',
                    data: <a href={`mailto:${fieldData}`}>{fieldData}</a>
                };
            case 'site':
                return {
                    title: `${Language.website}:`,
                    data: <a target="_blank" href={fieldData}>{fieldData}</a>
                };
        }
    } else {
        return undefined
    }

};

export default MappingDataProfile;