import React, { Component } from 'react';
import Language  from "language/index";

import styles from 'pages/News/styles.module.scss'

class CenterSide extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="centerside">
        <div className="side-block">
          <div className="title-block">
            <div className="flex jcsb align">

              <h1> {Language.TermsOfUser} </h1>

            </div>
          </div>
        </div>

        <div className="side-block mt15 terms-content">
          <div className="element clearfix">
            <p>
              {Language.WelcomeToNewnext}
            </p>
            <p>
              {Language.NewNextWebsiteServes}
            </p>
            <p>
              {Language.TheSubjectOfTheseTerms}
            </p>
            <div className="test" id="StatusOfTheTerms" />
            <div>
              <h3> {Language.StatusOfTermsUse}  </h3>
              <p>
                {Language.TheseTermsUseNewnextSite}
              </p>
              <p>
                {Language.RulesDetermineTermsConditions}
              </p>
              <p>
                {Language.IfResultTheUseSite}
              </p>
              <p>
                {Language.TheseRulesAreLegally}
              </p>
              <p>
                {Language.TheSubjectOfAgreement}
              </p>
              <p>
                {Language.AllSpecialDocuments}
              </p>
              <p>
                {Language.RulesAreOpenAndPublic}
                <a href={Language.RulesAreOpenAndPubliclink.url}>{Language.RulesAreOpenAndPubliclink.text}</a>
              </p>
              <p>
                {Language.PriorToRegistration}
              </p>
              <p>
                {Language.RulesCanBeChangedSupplemented}
              </p>
            </div>
            <div className="test" id="NewnextSiteStatus" />
            <div>
              <h3> {Language.NewnextSiteStatus} </h3>

              <p>
                {Language.NewnextWebsiteInternational}
              </p>

              <p>
                {Language.SiteAdministrationProvidesAccess}
              </p>

              <p>
                {Language.RightsSiteAsWhole}
              </p>

              <p>
                {Language.TheConditionsAreSet}
              </p>
            </div>
            <div className="test" id="NewnextSiteAdministration" />
            <div>
              <h3> {Language.NewnextSiteAdministration} </h3>
              <p>
                {Language.SiteAdministrationTheseRules}
              </p>
              <p>
                {Language.AppealsProposalsAndClaims}
              </p>
              <p>
                {Language.WithRegardToTheOperation}
              </p>
              <p>
                {Language.TheRightUseBrandName}
              </p>
            </div>
            <div className="test" id="RegistrationNewnext" />
            <div>
              <h3> {Language.RegistrationOnTheNewnextUser} </h3>
              <p>
                {Language.UserRegistrationSiteIfFree}

                <a href={Language.UserRegistrationSiteIfFreelink.url}>{Language.UserRegistrationSiteIfFreelink.text}</a>
              </p>
              <p>
                {Language.UserOfSiteisANatural}
              </p>
              <p>
                {Language.IfTheUserofTheSiteLegal}
              </p>
              <p>
                {Language.WhenRegisteringonTheSite}
              </p>
              <p>
                {Language.TheUserIsResponsible}
              </p>
              <p>
                {Language.AfterProvidingInformation}
              </p>
              <p>
                {Language.ConfirmRegistrationAutomated}
              </p>
              <p>
                {Language.ConfirmTheRegistration}
              </p>
              <p>
                {Language.InTheCaseCorrect}
              </p>
              <p>
                {Language.RegistrationTheUserOnTheSite}
              </p>
              <p>
                {Language.AfterSuccessfulReg}
              </p>
              <p>
                {Language.TheProcessingUsers}
              </p>
              <p>
                {Language.SiteAdminTakesAll}
              </p>
              <p>
                {Language.SiteAdminHasRightUse}
              </p>
              <p>
                {Language.LoginAndPassword}
              </p>
              <p>
                {Language.AnyActionsPerformed}
              </p>
              <p>
                {Language.TheUserHasRightCreate}
              </p>
              <p>
                {Language.TheUserAsTheOwner}
              </p>
              <p>
                {Language.WithRegardToPersonal}
              </p>
              <p>
                {Language.IndependentlyDeleteInfo}
              </p>
              <p>
                {Language.IndependentlyDeletePersonal}
              </p>
            </div>
            <div className="test" id="ObligationsUserWebsite" />
            <div>
              <h3> {Language.ObligationsTheUser} </h3>
              <p>
                {Language.WhenUsingTheSite}
              </p>
              <p>
                {Language.ToComplyProvisions}
              </p>
              <p>
                {Language.ToEnterReliable}
              </p>
              <p>
                {Language.ToInformImmediately}
              </p>
              <p>
                {Language.ToWithholdAccess}
              </p>
              <p>
                {Language.NotToPost}
              </p>
              <p>
                {Language.ToBeResponsibleLegality}
              </p>
              <p>
                {Language.ToBeResponsibleSecurity}
              </p>
              <p>
                {Language.NotToProvide}
              </p>
              <p>
                {Language.SiteAdminRecommends}
              </p>
              <p>
                {Language.TheUserOfTheSiteProhibited}
              </p>
              <p>
                {Language.FromRegistering}
              </p>
              <p>
                {Language.FromMisleading}
              </p>
              <p>
                {Language.FromUploadingStore}
              </p>
              <p>
                {Language.ViolatesPrivacy}
              </p>
              <p>
                {Language.ViolatesMinors}
              </p>
              <p>
                {Language.VulgarOrObscene}
              </p>
              <p>
                {Language.ContainsScenesAnimalAbuse}
              </p>
              <p>
                {Language.IncitementToCommitSuicide}
              </p>
              <p>
                {Language.PromotesRacial}
              </p>
              <p>
                {Language.ExtremistMaterials}
              </p>
              <p>
                {Language.CriminalActivityAdvice}
              </p>
              <p>
                {Language.RestrictedInfo}
              </p>
              <p>
                {Language.AdvertisesOrPromo}
              </p>
              <p>
                {Language.isFraudulent}
              </p>
              <p>
                {Language.ViolatesOtherRights}
              </p>
              <p>
                {Language.ToUploadStore}
              </p>
              <p>
                {Language.WithoutConsentOther}
              </p>
              <p>
                {Language.ToCarryOutActions}
              </p>
              <p>
                {Language.ToUseViruses}
              </p>
              <p>
                {Language.ToUseAutomatedScripts}
              </p>
              <p>
                {Language.InAnyWayTry}
              </p>
              <p>
                {Language.UseSiteWayOtherThan}
              </p>
              <p>
                {Language.ToCopyAndTransfer}
              </p>
              <p>
                {Language.ToPostCommercial}
              </p>
              <p>
                {Language.ToPostAnyOtherInfo}
              </p>
              <p>
                {Language.UserPersonally}
              </p>
              <p>
                {Language.UserObliged}
              </p>
            </div>
            <div className="test" id="IntellectualProperty" />
            <div>
              <h3> {Language.IntellectualProperty} </h3>
              <p>
                {Language.ExclusiveRights}
              </p>
              <p>
                {Language.ObjectsExclusive}
              </p>
              <p>
                {Language.NoContentMayBeCopied}
              </p>
              <p>
                {Language.UserGrantsOtherUsers}
              </p>
              <p>
                {Language.UsersUseContentAccess}
              </p>
              <p>
                {Language.UserGrantsSiteAdmin}
              </p>
              <p>
                {Language.SiteAdminGranted}
              </p>
              <p>
                {Language.NonExclusiveRight}
              </p>
              <p>
                {Language.ExpirationTheTerm}
              </p>
              <p>
                {Language.SiteAdminHasRightTransfer}
              </p>
              <p>
                {Language.AdminReservesRight}
              </p>
              <p>
                {Language.UserDoesNotHaveRightTo}
              </p>
              <p>
                {Language.AnyUserSite}
              </p>
              <p>
                {Language.NothingTheseTerms}
              </p>
              <p>
                {Language.Liability}
              </p>
              <p>
                {Language.UserPersonallyResponsible}
              </p>
              <p>
                {Language.TheUserDoesNotRelevant}
              </p>
              <p>
                {Language.AdminNotObliged}
              </p>
              <p>
                {Language.SiteAdminMay}
              </p>
              <p>
                {Language.WebsitedAndContent}
              </p>
              <p>
                {Language.SiteMayContain}
              </p>
              <p>
                {Language.AdminNotResponsible}
              </p>
              <p>
                {Language.LinksOrManuls}
              </p>
              <p>
                {Language.LinkToAnySiteProduct}
              </p>
              <p>
                {Language.IfUserGoesThirdParty}
              </p>
            </div>
            <div className="test" id="OpertaionNewnextSite" />
            <div>
              <h3> {Language.OpertaionNewnextSite} </h3>
              <p>
                {Language.UsersResponsible}
              </p>
              <p>
                {Language.ForTheirOwnActions}
              </p>
              <p>
                {Language.ForActionsRelatedPlacement}
              </p>
              <p>
                {Language.AdminDoesNotParticipate}
              </p>
              <p>
                {Language.SiteAdminCanImplement}
              </p>
              <p>
                {Language.AdminReservesRightToChange}
              </p>
              <p>
                {Language.SiteAdminNotEngaged}
              </p>
              <p>
                {Language.SiteaDminHasRightChange}
              </p>
              <p>
                {Language.SiteAdminDelete}
              </p>
              <p>
                {Language.SiteAdminBlock}
              </p>
              <p>
                {Language.SiteAdminImplementsMeasures}
              </p>
              <p>
                {Language.RemovalPersonalPage}
              </p>
              <p>
                {Language.SiteAdminEnsures}
              </p>
              <p>
                {Language.SiteAdminNotResponsible}
              </p>
              <p>
                {Language.AdminNotResponsibleAnyDamage}
              </p>
              <p>
                {Language.SiteADminDispose}
              </p>
              <p>
                {Language.SiteAdminHasSend}
              </p>
              <p>
                {Language.LimitationLiability}
              </p>
              <p>
                {Language.WEBSITEFUNCTIONALITY}
              </p>
              <p>
                {Language.USERSHOULDTAKE}
              </p>
              <p>
                {Language.BYUSINGWEBSITE}
              </p>
              <p>
                {Language.UNDERNOCIRCUMSTANCES}
              </p>
            </div>
            <div className="test" id="FinalProvision" />
            <div>
              <h3> {Language.FinalProvision} </h3>
              <p>
                {Language.TheseTermsAreAgreement}
              </p>
              <p>
                {Language.RulesShallBeGoverned}
              </p>
              <p>
                {Language.InCaseAnyDisputed}
              </p>
              <p>
                {Language.RulesShallEnterInto}
              </p>
              <p>
                {Language.RulesAreWrittenRussian}
              </p>
              <p>
                {Language.IfForAnyReason}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CenterSide;
