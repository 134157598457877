import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import Industry from "components/UI/Forms/GroupFields/Industry";
import Money from "components/UI/Forms/GroupFields/Money";
import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from "redux-form";
import { Mutation } from "react-apollo";
import { submitNovatorForm } from "./submit";
import { SET_NOVATOR } from "mutation/offer";
import { GET_OFFERS_LIST } from "query/offers";
import Language from "language/index";
import { Button } from 'components/v1/Button';



class NovatorForm extends Component {

    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return (
            <div className="form fullWidth">
                <form className="new_application" id="new_request_novator" onSubmit={handleSubmit}>
                    <Field
                        name="name"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.naming} *`}
                    />
                    <Industry
                        label={`${Language.IndustryIntroduction} *`}
                        {...initialValues}
                    />
                    <Field
                        name="patentNumber"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.patent}`}
                    />
                    <Money
                        nameField="cost"
                        label={`${Language.Cost} *`}
                    />
                    <Field
                        name="isImplemented"
                        component={Select}
                        label={`${Language.Introduced} *`}
                        options={[
                            { value: true, label: 'Да' },
                            { value: false, label: 'Нет' },
                        ]}
                    />
                    <Field
                        name="additionallyInform"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.additionalInformationBlock}`}
                    />

                    <Button type="submit" disabled={loading} className="orange-btn"> {Language.save} </Button>

                </form>
            </div>
        );
    }
}


NovatorForm = reduxForm({
    form: 'novatorForm',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('investorForm'));
        }
    }
})(NovatorForm);


export default props => {
  const initialValues = Boolean(Object.keys(props.initialValues).length) ? props.initialValues : {
    cost: {
      currency: 'rub'
    }
  };
  return (
    <Mutation mutation={SET_NOVATOR} refetchQueries={[{query: GET_OFFERS_LIST, variables: { isMy: true}}]}>
      {(mutate,{ loading }) => (
        <NovatorForm   {...props} initialValues={initialValues} loading={loading}
                         onSubmit={values => submitNovatorForm(values, mutate, props.cb)}/>
      )}
    </Mutation>
  )
};
