import React from 'react';
import { Page } from 'components/v1/Page';

import ProfileTypeForm from 'components/Pages/Requests/ProfileTypeForm';
import { Query } from 'react-apollo';
import { GET_MY_PROFILE } from 'query';

import classes from './styles.module.scss';
import forwardTo from 'libs/forwardTo';
import { getItem, removeItem } from 'libs/localStorage';
import { HeaderMobile } from 'components/v1/HeaderMobile';

export const CreateRequestComponent = ({ getMyProfile }) => {

  const initialValues = JSON.parse(getItem('editrequest', '{}'));
  const isEdit = Boolean(Object.keys(initialValues).length)

  return (
    <Page>
      <HeaderMobile goBack title="Ваше предложение" />
      <Page.Body>
        <div className={classes.create}>
          <div className={classes.title}>
            {isEdit ? 'Редактировать предложение' : 'Создать предложение'}
          </div>
          <ProfileTypeForm initialValues={initialValues} type={getMyProfile.typeKeys[0]} cb={() => {
            removeItem('editrequest');
            forwardTo('/nnnotice-my');
          }}/>
        </div>
      </Page.Body>
    </Page>
  );
};

export const CreateRequest = props => {
  return (
    <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
      {({ data, loading, error, client }) => {
        if (error || loading) return 'Loading...';
        if (data.ProfileGetter) {
          return <CreateRequestComponent client={client} {...props} {...data.ProfileGetter}/>
        } else return 'Loading...'
      }}
    </Query>
  )
};
