import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import Select from 'react-select';
import cn from 'classnames';

import classes from './styles.module.scss';


class CustomSelect extends Component {

	static propTypes = {
		input: PropTypes.object,
		meta: PropTypes.object,
		type: PropTypes.string,
		label: PropTypes.string,
		strong: PropTypes.string,
		mask: PropTypes.string,
		placeholder: PropTypes.string,
		className: PropTypes.string,
		options: PropTypes.array,
		disabled: PropTypes.bool,
		searchable: PropTypes.bool,
		filterOptions: PropTypes.bool,
		onChangeSelect: PropTypes.func,
		change: PropTypes.func,
	};
	static defaultProps = {
		input: {},
		meta: {},
		type: 'select',
		label: ' ',
		strong: '',
		mask: '',
		placeholder: '',
		className: 'element',
		options: [],
		disabled: false,
		searchable: false,
		filterOptions: false,
		onChangeSelect: () => {
		},
		change: () => {
		}
	};

	select = null;

	render() {

		const { label, strong, name, meta, placeholder, className, disabled, change, filterOptions, searchable, errors, onChangeSelect, options, input, defaultValue } = this.props;
		const error = (meta.error || errors) && 'error';

		return (
			<div className={cn(classes.select, className, { [classes.error]: meta.error || errors})}>
				{label !== '' && <p className={classes.label}>{label}</p>}
				{strong !== '' && <strong>{strong}</strong>}
				<Select
					styles={{
						container: (provided, state) => ({
							...provided,
							border: '1px solid #D5D5D5!important',
							borderRadius: '8px',
							height: '51px',
						}),
						option: (provided, state) => ({
							...provided,
							fontSize: '14px',
							// height: '30px',
							borderBottom: 'none',
							color: '#000',
							background: state.isSelected ? '#F8F8F8' : '',
							'&:hover': {
								cursor: 'pointer',
								background: '#F8F8F8'
							}
						}),
						valueContainer: (provided) => ({
							...provided,
							marginTop: '-3px'
						}),
						control: (provided) => ({
							...provided,
							border: 'none!important',
							borderRadius: '8px',
							height: '51px',
							background: 'none!important',
							boxShadow: 'none!important'
						}),
						indicatorSeparator: (provided) => ({
							display: 'none'
						}),
					}}
					ref={select => this.select = select}
					name={name}
					id={name}
					clearable={this.props.clearable}
					searchable={searchable}
					filterOptions={filterOptions}
					onChange={(option) => {
						if (option) {
							input.onChange(option.value);
							change(option);
						}
					}}
					defaultValue={defaultValue}
					value={options.find(elm => elm.value === input.value) || null}
					// selected={options.find(elm => elm.value === input.value)}
					placeholder={placeholder}
					options={options}
					className={error}
					onInputChange={onChangeSelect}
					disabled={disabled}
					isDisabled={disabled}
					autocomplete="off"
					noResultsText="Список пуст"
				/>
				{errors && (
					<span className="error">{errors.message}</span>
				)}
				{
					meta.error &&
					<span className="error">{meta.error}</span>
				}
			</div>
		);
	}
}


export default CustomSelect;
