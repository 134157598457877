import React, { Component } from 'react';
import Language from 'language/index';
import { Query } from 'react-apollo';
import Profile from 'components/UI/ReccomendUsers/index';
import { GET_PROFILE_USER } from 'query/profile';
import { GET_MY_PROFILE } from 'query/index';

class DataFormat extends React.Component {
  render() {
    const { dateTime, format } = this.props;

    let options;
    let date = new Date(dateTime);

    let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    if (isSafari && typeof (dateTime.substring) === 'function') {
      date = new Date(dateTime.substring(0, dateTime.length - 5));
    }

    switch (parseInt(format, 10)) {
      case 1:
        options = { day: 'numeric', month: 'long', year: 'numeric' };
        break;
      case 2:
        options = { day: 'numeric', month: 'long' };
        break;
      case 3:
        options = { hour: 'numeric', minute: 'numeric' };
        break;
      case 4:
        options = { month: 'long', day: 'numeric', timezone: 'UTC', hour: 'numeric', minute: 'numeric' };
        break;
      default:
        options = { day: 'numeric', month: 'long' };
        break;
    }
    return date.toLocaleString(Language.dateTime, options)
  }
};


class HeaderPanel extends Component {


  render() {
    let userClassName, userTypeName;

    switch (this.props.typeKeys[0]) {
      case 'Investor':
        userClassName = 'investor';
        userTypeName = Language.investor;
        break;
      case 2:
        userClassName = 'coinvestor';
        userTypeName = Language.coinvestor;
        break;
      case 'Innovator':
        userClassName = 'innovator';
        userTypeName = Language.novator;
        break;
      case 'BusinessProduct':
        userClassName = 'seller_business_products';
        userTypeName = Language.sellerBusiness;
        break;
      case 'Provider':
        userClassName = 'provider';
        userTypeName = Language.supplier;
        break;
      case 'BusinessWoman':
        userClassName = 'business_lady';
        userTypeName = Language.businessWoman;
        break;
      case 7:
        userClassName = 'proektant';
        userTypeName = Language.designer;
        break;
      case 'Unemployed':
        userClassName = 'unemployed';
        userTypeName = Language.unemployed;
        break;
      case 9:
        userClassName = 'employer';
        userTypeName = Language.employer;
        break;
      case 10:
        userClassName = 'hr_manager';
        userTypeName = Language.hrManager;
        break;
      case 11:
        userClassName = 'expert';
        userTypeName = Language.expert;
        break;
      case 12:
        userClassName = 'Viewer';
        userTypeName = 'Viewer';
        break;
      case 13:
        userClassName = 'admin';
        userTypeName = Language.admin;
        break;
    }

    let classNameOnline = 'success-user';
    let onlineStatus = true;
    let lastSign = '';

    let show = true;

    let today, lastSignIn, compareYear, compareMonth, compareDate, compareYesterday, compareToday;

    if (this.props.hasOwnProperty('onlineStatus') && typeof (this.props.onlineStatus) === 'object') {
      classNameOnline = this.props.onlineStatus.isOnline ? 'success-user' : 'clock-user';
      onlineStatus = this.props.onlineStatus.isOnline;
      lastSign = this.props.onlineStatus.lastTime;

      today = new Date();
      lastSignIn = new Date(lastSign);

      if (lastSign) {
        let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
        if (isSafari && typeof (lastSign.substring) === 'function') {
          lastSignIn = new Date(lastSign.substring(0, lastSign.length - 5));
        }

        compareYear = lastSignIn.getYear() === today.getYear();
        compareMonth = lastSignIn.getMonth() === today.getMonth();
        compareDate = lastSignIn.getDate() === today.getDate();
        compareYesterday = lastSignIn.getDate() === today.getDate() - 1;
        compareToday = compareYear && compareMonth && compareDate;
      }

    }

    if (this.props.hasOwnProperty('onlineStatus') && typeof (this.props.onlineStatus) === 'boolean') {
      show = false;
    }

    return (
      <div className={`after-header-panel ${userClassName}`}>
        <div className="container">
          <div className="user-category">
            {/*<i className="fa fa-check-circle fa-2x" aria-hidden="true" />*/}
            <div className={`icon-user-check ${classNameOnline}`}/>
            <div className="flex align">
              <div>
                <span>{userTypeName}</span>
                {
                  show &&
                  <React.Fragment>
                    {
                      onlineStatus &&
                      <span className="last-sign">
                                                {Language.onlineStatus}
                                            </span>
                    }
                    {
                      !onlineStatus &&
                      <span className="last-sign">
                                                {
                                                  compareToday &&
                                                  Language.Today
                                                }
                        {
                          compareYear && compareMonth && compareYesterday &&
                          Language.Yesterday
                        }
                        {
                          compareYear && compareMonth && !compareYesterday && !compareToday &&
                          <DataFormat dateTime={lastSignIn} format={0}/>
                        }
                        {
                          compareYear && !compareMonth &&
                          <DataFormat dateTime={lastSignIn} format={0}/>
                        }
                        {
                          !compareYear &&
                          <DataFormat dateTime={lastSignIn} format={1}/>
                        }
                        , в <DataFormat dateTime={lastSignIn} format={3}/>
									                              </span>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
          <ul className="dop-user-info">
            {/*{*/}
            {/*this.props.counterRequest !== false &&*/}
            {/*<React.Fragment>*/}
            {/*<li>*/}
            {/*<span>{Language.recommend}</span> &nbsp;*/}
            {/*<i className="count">0</i>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*<span>{Language.request}</span> &nbsp;*/}
            {/*<i className="count">{this.props.counterRequest}</i>*/}
            {/*</li>*/}
            {/*</React.Fragment>*/}
            {/*}*/}
          </ul>
        </div>
      </div>
    );
  }
}


export default props => {

  let query = props.uid ? GET_PROFILE_USER : GET_MY_PROFILE;
  let variables = props.uid ? { 'userId': props.uid } : null;

  return (
    <Query query={query} variables={variables} fetchPolicy="cache-only">
      {({ data, loading, error }) => {
        if (error || loading) return null;
        console.log(data);
        if (data.ProfileGetter || data.UserGetter) {
          let profile = props.uid ? data.UserGetter.getUserById : data.ProfileGetter.getMyProfile;
          return <HeaderPanel {...props} {...profile} />
        } else return null
      }}
    </Query>
  )
};
