import classes from './styles.module.scss';
import { EmptyContent } from 'components/v1/EmptyContent';

export const AboutCompany = ({ description }) => {
  if (description === null || !Boolean(description.length)) {
    return <EmptyContent text="" />
  }

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.about}>
          <div className={classes.title}>О компании</div>
          {description}
        </div>
      </div>
    </div>
  )
}
