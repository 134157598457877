import React from 'react'
import cn from 'classnames'
import { Controller } from 'react-hook-form';

import { CheckIcon } from 'components/v1/Icons/CheckIcon';

import styles from './checkbox.module.scss'

export const CheckboxController = ({
  control,
  name,
  label,
  rules,
  ...rest
}) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <label
          className={cn(styles.checkbox, { [styles.disabled]: rest.disabled })}
        >
          <input
            className={styles.defaultInput}
            type={"checkbox"}
            onChange={() => {
              onChange(!value);
              rest.onChange && rest.onChange(!value);
            }}
            checked={value}
            value={value}
            name={name}
            disabled={rest.disabled}
          />
          <div className={styles.box}>{value && <CheckIcon />}</div>
          <div className={styles.label}>{label}</div>
        </label>
      )}
    />
  )
}
