import { ActionNotification } from "components/UI/Notifications/action";
import { CHANGE_STATUS_OFFER } from "mutation/offer";
import { GET_OFFERS_LIST } from "query/offers";
import { client } from "../../../apollo";

export const changeStatusRequests = (status, offerId, refetchQueries, update) => {

    let obj = {
        mutation: CHANGE_STATUS_OFFER,
        variables: { status, offerId },
        refetchQueries: [{ query: GET_OFFERS_LIST, variables: { isMy: true } }]
    };



    if (refetchQueries) {
        obj.refetchQueries = [...obj.refetchQueries, refetchQueries]
    }

    if (update) {
        obj.update = [update]
    }

    client.mutate(obj)
        .then(res => {
            if (status === 'MODERATION') {
                ActionNotification('2', 'Ваша заявка успешно отправлена на модерацию');
            } else if (status === 'CREATED') {
                ActionNotification('2', 'Ваша заявка успешно восстановлена');
            } else if (status === 'ARCHIVED') {
                ActionNotification('2', 'Ваша заявка успешно добавлена в архив');
            } else if (status === 'REMOVED') {
                ActionNotification('2', 'Ваша заявка успешно удалена');
            }
        })
        .catch(e => {
            ActionNotification('1', 'Произошла ошибка ');
        })
};