import React, { Component } from 'react';
import Industry from "components/UI/Forms/GroupFields/Industry";
import Region from "components/UI/Forms/GroupFields/Region";
import Money from "components/UI/Forms/GroupFields/Money";
import Select from "components/UI/Fields/Select";
import { Field, reduxForm, stopSubmit } from "redux-form";
import Input from "components/UI/Fields/Input";
import { Mutation } from "react-apollo";
import { submitFreelsncerForm } from "./submit";
import { SET_FREELANCER } from "mutation/offer";
import { GET_OFFERS_LIST } from "query/offers";
import Language from "language/index";
import { Button } from 'components/v1/Button';

class FreelancerForm extends Component {

    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return(
            <div className="form fullWidth">
                <form className="new_application" id="new_request_businesswoman" onSubmit={handleSubmit}>
                    <Industry
                        label={`${Language.industry} *`}
                        {...initialValues}
                    />
                    <Region
                      {...initialValues}
                    />
                    <Money
                        nameField="salary"
                        label={`${Language.Income} *`}
                    />
                    <Field
                        name="paymentType"
                        component={Select}
                        label={`${Language.PaymentMethod} *`}
                        options={[
                            { value: 'час', label: 'час' },
                            { value: 'день', label: 'день' },
                            { value: 'неделя', label: 'неделя' },
                            { value: 'месяц', label: 'месяц' },
                            { value: 'год', label: 'год' },
                        ]}
                    />
                    <Field
                        name="position"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.Position} *`}
                    />
                    <Field
                        name="additionallyInform"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.additionalInformationBlock} *`}
                    />
                    <Button type="submit" disabled={loading} className="orange-btn"> {Language.save} </Button>

                </form>
            </div>
        );
    }
}


FreelancerForm = reduxForm({
    form: 'freelancerForm',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('freelancerForm'));
        }
    }
})(FreelancerForm);


export default props => {
  const initialValues = Boolean(Object.keys(props.initialValues).length) ? props.initialValues : {
    profitability: {
      salary: 'rub'
    }
  };
  return (
    <Mutation mutation={SET_FREELANCER} refetchQueries={[{query: GET_OFFERS_LIST, variables: { isMy: true }}]}>
      {(mutate,{ loading }) => (
        <FreelancerForm  {...props} initialValues={initialValues}  loading={loading}
                         onSubmit={values => submitFreelsncerForm(values, mutate, props.cb)}/>
      )}
    </Mutation>
  )
};;
