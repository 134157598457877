import { getItem } from "../libs/localStorage";

let Language = {
    ru: {
        mm: 'мм',
        cm: 'см',
        km: 'км',
        inch: 'дюйм',
        foot: 'фут',
        yard: 'ярд',
        mile: 'миля',
        mm2: 'мм2',
        cm2: 'см2',
        m2: 'м2',
        ha: 'га',
        km2: 'км2',
        inch2: 'дюйм2',
        foot2: 'фут2',
        yard2: 'ярд2',
        a: 'а',
        mm3: 'мм3',
        cm3: 'см3',
        ml: 'мл',
        l: 'л',
        dm3: 'дм3',
        m3: 'м3',
        inch3: 'дюйм3',
        foot3: 'фут3',
        yard3: 'ярд3',
        mg: 'мг',
        k: 'кар',
        g: 'г',
        kg: 'кг',
        t: 'т',
        kt: 'кт',
        Status: 'Статус',
        selectStatus: 'Выберите статус',
        robotRecaptcha: 'Подтвердите что вы не робот',
        techSupport: {one: 'Спасибо!', two: 'Ваше сообщение успешно отправлено, мы сделаем все возможное, чтобы дать Вам ответ как можно скорее.', three: 'Пожалуйста, не дублируйте Ваш запрос, наши специалисты обязательно с Вами свяжутся.'},

        recaptcha: 'ru',
        AuthorizationRegistration: 'Авторизация/Регистрация',
        VerifyYour: 'Подтверждение',
        Phone: 'телефона',

        LogRegisterSupport: 'Авторизируйтесь или зарегистрируйтесь для отправки формы в техподдержку',
        userDocuments: 'Документы пользователя',
        techSupportFormText: 'Если у вас возникли вопросы или проблемы при использовании бизнес сети newnext, обратитесь в Службу поддержки. Для этого заполните форму:',

        ABOUTTHEPROJECTFIGURES: 'О ПРОЕКТЕ В ЦИФРАХ',
        YourBlacklistEmpty: 'Нет заблокированных контактов',
        beforeTheEndOf: 'До окончания',
        delete: 'Удалить',
        UsersPersonalPrivacy: 'Политика конфиденциальности персональных данных пользователей Сайта newnext',
        GENERALTERMS: '1. ОБЩИЕ ПОЛОЖЕНИЯ',
        GENERALTERMS_1_1: '1.1. Предметом настоящей Политики конфиденциальности персональных данных пользователей Сайта newnext (далее – Политика) являются условия,   которые Администрация Сайта выполняет в отношении персональных данных, которые Администрация Сайта  может получить о Пользователе во время иcпользования  им Cайта newnext и его функционала.',
        GENERALTERMS_1_2: '1.2. Целью настоящей Политики является обеспечение Администрацией Сайта надлежащей защиты персональных данных Пользователей Сайта от несанкционированного доступа и разглашения.',
        GENERALTERMS_1_3: '1.3. Пользователю необходимо внимательно ознакомиться с условиями настоящей Политики, которую Администрация Сайта определяет как публичную оферту в соответствии со ст. 437 Гражданского кодекса Российской Федерации.',
        GENERALTERMS_1_3_1: 'Отношения, возникающие в связи со сбором, обработкой и хранением информации о Пользователях Сайта регулируются настоящей Политикой, иными официальными документами Администрации Сайта и действующим законодательством Российской Федерации.',
        GENERALTERMS_1_4: {one: '1.4. Политика является публичным документом, размещенным  в свободном доступе по адресу: ', link: 'http://newnextglobal.com/privacy', two: ', и доступна любому Пользователю.'},
        GENERALTERMS_1_5: '1.5. Настоящая Политика является официальным документом Администрации Сайта.',
        GENERALTERMS_1_6: '1.6. Настоящая Политика разработана в полном соответствии с Правилами пользования Сайтом. В случае обнаружения противоречий между настоящей Политикой и иными официальными документами Администрации  применению подлежит настоящая Политика.',
        GENERALTERMS_1_7: '1.7. Использование Пользователем Сайта и его функционала означает согласие с настоящей Политикой  и условиями обработки персональных данных Пользователя.',
        GENERALTERMS_1_8: '1.8. В случае несогласия с условиями Политики Пользователь должен прекратить использование Сайта  и его функционала.',
        GENERALTERMS_1_9: '1.9. Настоящая Политика  применяется только к Сайту. Администрация Сайта не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.',
        GENERALTERMS_1_10: '1.10. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем Сайта.',
        GENERALTERMS_1_11: '1.11. Администрация Сайта оставляет за собой право вносить изменения в настоящую Политику. После внесения изменений Администрация размещает новую редакцию Политики на Сайте не позднее 7 (Семи) дней со дня вступления в действие Политики в новой редакции.',
        DEFINITIONOFTERMS: '2. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ',
        DEFINITIONOFTERMS_1: 'В настоящей Политике используются следующие термины:',
        DEFINITIONOFTERMS_2:  {one: 'Сайт newnext (далее - Сайт) – международная глобальная бизнес-сеть newnext,  является интернет-ресурсом и представляет собой совокупность информации и программ для ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет по  адресу: ', link: 'http://newnextglobal.com', two: ' (включая все уровни указанного домена, как функционирующие на дату принятия Пользователем настоящей Политики, так и запускаемые и вводимые в эксплуатацию в течение всего срока его действия). '},
        DEFINITIONOFTERMS_3: 'Администрация Сайта newnext (ранее и далее – Администрация сайта или Администрация) – Общество с ограниченной ответственностью «Альтернативные Современные Технологии», юридическое лицо, созданное в соответствии с  законодательством Российской Федерации и зарегистрированное по адресу: 445012, Самарская область, г.Тольятти, ул.Коммунистическая, д.8, уполномоченные сотрудники которого имеют полномочия на управления сайтом, организацию и осуществление обработки персональных данных, а также определение цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.',
        DEFINITIONOFTERMS_4: 'Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных).',
        DEFINITIONOFTERMS_5: 'Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.',
        DEFINITIONOFTERMS_6: 'Конфиденциальность персональных данных — обязательное для соблюдения Администрацией  или иным уполномоченным лицом, получившим доступ к персональным данным, требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.',
        DEFINITIONOFTERMS_7: 'Пользователь Сайта newnext (далее - Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и пользующееся Сайтом и его функционалом.',
        DEFINITIONOFTERMS_8: 'Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере Пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу Сайта.',
        DEFINITIONOFTERMS_9: 'IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.',
        INFORMATIONABOUTUSERS: '3. ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЯХ САЙТА',
        INFORMATIONABOUTUSERS_1: '3.1. Настоящая Политика  устанавливает обязательства Администрации Сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации Сайта при регистрации на Сайте   или при использовании функционала Сайта.',
        INFORMATIONABOUTUSERS_2: '3.2. Администрация Сайта защищает персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, которые предоставляются Пользователем путём заполнения регистрационной формы на Сайте и при использовании Пользователем функционала Сайта.',
        INFORMATIONABOUTUSERS_3: '3.2.1. Персональные данные Пользователей',
        INFORMATIONABOUTUSERS_4: 'Персональные данные Пользователей включают в себя:',
        INFORMATIONABOUTUSERS_5: '1)  предоставляемые Пользователями и минимально необходимые для регистрации на Сайте: тип Пользователя, имя, фамилия, номер мобильного телефона;',
        INFORMATIONABOUTUSERS_6: '2) предоставляемые Пользователями с использованием раздела редактирования своих страниц на Сайте: пол, дата рождения, адрес проживания, адрес электронной почты, образование, отрасль, карьера, семейное положение, хобби, дополнительная информация, предоставленная Пользователем при редактировании профиля;',
        INFORMATIONABOUTUSERS_7: '3) предоставляемые Пользователями по запросу Администрации Сайта в случаях, когда для исполнения обязательств Администрации Сайта перед Пользователями, Администрация Сайта вправе, в частности, запросить у Пользователя копию документа, удостоверяющего личность, либо иного документа, содержащего имя, фамилию, фотографию Пользователя, а также иную дополнительную информацию, которая, по усмотрению Администрации Сайта, будет являться необходимой и достаточной для идентификации такого Пользователя и позволит исключить злоупотребления и нарушения прав третьих лиц.',
        INFORMATIONABOUTUSERS_8: '3.2.2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта',
        INFORMATIONABOUTUSERS_9: 'Иная информация о Пользователях  включает в себя: IP адрес;',
        INFORMATIONABOUTUSERS_10: '1)  данные, получаемые при доступе к Сайту, включающие в себя данные о технических средствах (устройствах), технологическом взаимодействии с Сайтом (в т.ч. IP-адрес хоста, вид операционной системы пользователя, тип браузера, географическое положение, поставщик услуг Интернета, данные из адресной книги, данные, полученные в результате доступа к камере, микрофону и т.п. устройств), и последующих действиях Пользователя на Сайте, а также информация, содержащая историю посещения Сайта пользователем;',
        INFORMATIONABOUTUSERS_11: '2) информация, автоматически получаемая при доступе к Сайту с использованием файлов cookies;',
        INFORMATIONABOUTUSERS_12: '3) информация, создаваемая пользователями на Сайте вне раздела редактирования страниц (в том числе заявки, сообщения,  комментарии, фотографии);',
        INFORMATIONABOUTUSERS_13: '4) информация, полученная в результате действий Пользователя на Сайте (в частности, информация о добавлении других Пользователей в список контактов, размещении фотографий, информация о переданных и полученных сообщениях). Данная информация о Пользователе может быть доступна для контактов Пользователя в соответствии с настройками Пользователя;',
        INFORMATIONABOUTUSERS_14: '5) информация, полученная в результате действий других пользователей на Сайте, связанная с использованием функционала Сайта.',
        INFORMATIONABOUTUSERS_15: '3.4. Администрация Сайта осуществляет сбор статистики об IP-адресах своих Пользователей. Данная информация используется с целью выявления и решения потенциальных технических проблем.',
        INFORMATIONABOUTUSERS_16: '3.5. Любая иная персональная информация неоговоренная выше (история посещений Сайта, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п. 5.4 (пп. 2,3)  настоящей Политики конфиденциальности.',
        PURPOSEPROCESSINGPERSONAL: '4. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ',
        PURPOSEPROCESSINGPERSONAL_1: '4.1. Сбор персональных данных Пользователя Администрацией Сайта осуществляется в целях исполнения условий Правил пользования Сайтом,  на которых Администрация Сайта предоставляет Пользователю доступ к использованию Сайта  и его функционала.',
        PURPOSEPROCESSINGPERSONAL_2: 'В силу п.п. 2 п. 2 статьи 22 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» Администрация Сайта вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по защите прав субъектов персональных данных.',
        PROCESSINGUSERPERSONAL: '5. ОБРАБОТКА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ',
        PROCESSINGUSERPERSONAL_1: '5.1. Принципы обработки информации:',
        PROCESSINGUSERPERSONAL_2: '1) Обработка персональных данных осуществляется на законной и справедливой основе.',
        PROCESSINGUSERPERSONAL_3: '2) Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей, установленных Администрацией Сайта.',
        PROCESSINGUSERPERSONAL_4: '3) Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.',
        PROCESSINGUSERPERSONAL_5: '4) Обработке подлежат только персональные данные, которые отвечают целям их обработки.',
        PROCESSINGUSERPERSONAL_6: '5) Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не являются избыточными по отношению к заявленным целям их обработки.',
        PROCESSINGUSERPERSONAL_7: '6) При обработке персональных данных  обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Администрация Сайта  принимает необходимые меры по удалению или уточнению неполных или неточных данных.',
        PROCESSINGUSERPERSONAL_8: '7) Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.',
        PROCESSINGUSERPERSONAL_9: '8) Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей.',
        PROCESSINGUSERPERSONAL_10: '5.2. Сбор персональных данных',
        PROCESSINGUSERPERSONAL_11: 'Сбор персональных данных Пользователя осуществляется на Сайте при регистрации,  а также  при внесении Пользователем по своей инициативе дополнительных сведений о себе при редактировании профиля.',
        PROCESSINGUSERPERSONAL_12: '5.3. Хранение и использование персональных данных',
        PROCESSINGUSERPERSONAL_13: 'Персональные данные пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.',
        PROCESSINGUSERPERSONAL_14: '5.3. Передача персональных данных',
        PROCESSINGUSERPERSONAL_15: '1) Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими Правилами.',
        PROCESSINGUSERPERSONAL_16: '2) При указании Пользователя или при наличии согласия Пользователя возможна передача персональных данных Пользователя третьим лицам-контрагентам Администрации Сайта с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации.',
        PROCESSINGUSERPERSONAL_17: '3) Персональные данные Пользователей предоставляются по запросу государственных органов (органов местного самоуправления) в порядке, предусмотренном законодательством РФ.',
        PROCESSINGUSERPERSONAL_18: '4) В целях исполнения Правил пользования Сайтом и предоставления Пользователю доступа к использованию функционала Сайта, Администрация Сайта развивает предоставляемые сервисы и продукты, разрабатывает и внедряет новые сервисы и продукты, оптимизирует качество сервисов и продуктов, совершенствует доступный функционал Сайта и сервисов. Для обеспечения реализации указанных целей Пользователь соглашается на осуществление Администрацией Сайта с соблюдением применимого законодательства сбора, хранения, накопления, систематизации, извлечения, сопоставления, использования, наполнения (уточнения) их данных, а также на получение и передачу аффилированным лицам и партнерам результатов автоматизированной обработки таких данных с применением различных моделей оценки информации, в виде целочисленных и/или текстовых значений и идентификаторов, соответствующих заданным в запросах оценочным критериям для обработки данных Администрацией Сайта и/или лицами, указанными в настоящем пункте.',
        PROCESSINGUSERPERSONAL_19: '5.5. Уничтожение персональных данных',
        PROCESSINGUSERPERSONAL_20: 'Персональные данные пользователя уничтожаются при:',
        PROCESSINGUSERPERSONAL_21: '1) самостоятельном удалении Пользователем данных со своей персональной страницы;',
        PROCESSINGUSERPERSONAL_22: '2) удалении Администрацией Сайта информации, размещаемой Пользователем, а также персональной страницы Пользователя в случаях, установленных Правилами пользования сайтом.',
        PROCESSINGUSERPERSONAL_23: 'В случае удаления персональной страницы Администрация Сайта хранит на своих электронных носителях персональные и иные необходимые данные Пользователя в течение необходимого и установленного действующим законодательством Российской Федерации срока. ',
        PROCESSINGUSERPERSONAL_24: '5.6. Размещая информацию на персональной странице, в том числе свои персональные данные, Пользователь осознает и соглашается с тем, что указанная информация может быть доступна другим пользователям сети Интернет с учетом особенностей архитектуры и функционала Сайта. Пользователь самостоятельно определяет режим конфиденциальности и условия доступа к информации посредством соответствующих настроек. Администрация Сайта принимает технические и организационные меры по обеспечению функционирования соответствующего инструментария Сайта.',
        PROCESSINGUSERPERSONAL_25: '5.7. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.',
        PROCESSINGUSERPERSONAL_26: '5.8. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.',
        RIGHTSOBLIGATIONSUSERS: '6. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ',
        RIGHTSOBLIGATIONSUSERS_1: '6.1. Пользователь обязан:',
        RIGHTSOBLIGATIONSUSERS_2: '6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом и его функционалом.',
        RIGHTSOBLIGATIONSUSERS_3: '6.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.',
        RIGHTSOBLIGATIONSUSERS_4: '6.2. Пользователь  вправе:',
        RIGHTSOBLIGATIONSUSERS_5: '6.2.1. Осуществлять свободный бесплатный доступ к информации о себе посредством загрузки своих персональных страниц на Сайте с использованием логина и пароля.',
        RIGHTSOBLIGATIONSUSERS_6: '6.2.2. С помощью инструментария Сайта установить в отношении информации о себе желаемый уровень конфиденциальности для отдельных пользователей Сайта, помещая их в «черный список».',
        RIGHTSOBLIGATIONSUSERS_7: '6.2.3. Самостоятельно вносить изменения и исправления в информацию о себе на персональной странице Пользователя на Сайте, при условии, что такие изменения и исправления содержат актуальную и достоверную информацию;',
        RIGHTSOBLIGATIONSUSERS_8: '6.2.4. Удалять информацию о себе со своей персональной страницы на Сайте;',
        RIGHTSOBLIGATIONSUSERS_9: '6.2.5. Требовать от Администрации Сайта уточнения своих персональных данных, их блокирования или уничтожения в случае, если такие данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки.',
        RIGHTSOBLIGATIONSUSERS_10: '6.2.6. Hа основании запроса получать от Администрации Сайта информацию, касающуюся обработки его персональных данных.',
        RIGHTSOBLIGATIONSUSERS_11: '6.3. Поскольку Сайт является универсальным средством коммуникации и поиска бизнес-контактов и главной функцией Сайта является установление и поддержание необходимых бизнес-связей, любому зарегистрированному Пользователю Сайта доступна следующая информация:',
        RIGHTSOBLIGATIONSUSERS_12: {_1: 'тип Пользователя,', _2: 'имя,', _3: 'фамилия,', _4: 'Отчество,', _5: 'номер мобильного телефона,', _6: 'пол,', _7: 'дата рождения,', _8: 'профильная фотография,', _9: 'адрес проживания,', _10: 'адрес электронной почты,', _11: 'образование, ', _12: 'отрасль,', _13: 'карьера, ', _14: 'семейное положение, ', _15: 'хобби,', _16: 'дополнительная информация, предоставленная Пользователем,', _17: 'контакты,', _18: 'заявки,', _19: 'сообщения,', _20: 'комментарии.', },
        OBLIGATIONSSITEADMINISTRATION: '7. ОБЯЗАННОСТИ АДМИНИСТРАЦИИ САЙТА',
        OBLIGATIONSSITEADMINISTRATION_1: '7.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.',
        OBLIGATIONSSITEADMINISTRATION_2: '7.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя. ',
        OBLIGATIONSSITEADMINISTRATION_3: '7.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.',
        OBLIGATIONSSITEADMINISTRATION_4: '7.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.',
        MEASURESPROTECTUSER: '8. МЕРЫ ПО ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ',
        MEASURESPROTECTUSER_1: '8.1. Администрация Сайта принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий.',
        MEASURESPROTECTUSER_2: '8.2. Для авторизации доступа к Сайту используется логин (номер мобильного телефона) и пароль Пользователя. Ответственность за сохранность данной информации несет Пользователь. Пользователь не вправе передавать собственный логин и пароль третьим лицам, а также обязан предпринимать меры по обеспечению их конфиденциальности.',
        MEASURESPROTECTUSER_3: '8.3. В целях обеспечения более надежной защиты информации о Пользователях Администрация Сайта использует систему привязки страницы к мобильному телефону. Для осуществления данной системы Пользователь должен предоставить Администрации Сайта номер своего мобильного телефона.',
        MEASURESPROTECTUSER_4: 'В рамках системы привязки страницы к мобильному телефону Пользователь в случае утраты логина или пароля может восстановить доступ к странице с помощью кода восстановления, содержащегося в SMS-сообщении, которое Пользователь получает на свой мобильный телефон.',
        MEASURESPROTECTUSER_5: '8.4. Пользователь вправе получать информацию о том, в какое время и с каких устройств производилась авторизация на его персональную страницу.',
        RESPONSIBILITYPARTIES: '9. ОТВЕТСТВЕННОСТЬ СТОРОН',
        RESPONSIBILITYPARTIES_1: '9.1. Администрация Сайта, не исполнившая свои обязательства по защите персональных данных Пользователя, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п. 5.4 (пп.2,3)  настоящей Политики Конфиденциальности.',
        RESPONSIBILITYPARTIES_2: '9.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:',
        RESPONSIBILITYPARTIES_3: '9.2.1. Стала публичным достоянием до её утраты или разглашения.',
        RESPONSIBILITYPARTIES_4: '9.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.',
        RESPONSIBILITYPARTIES_5: '9.2.3. Была разглашена с согласия Пользователя.',
        ADDITIONALCONDITIONS: '10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ',
        ADDITIONALCONDITIONS_1: {text:'10.1. Все предложения или запросы, касающиеся  настоящей Политики конфиденциальности, следует сообщать на  Сайт по адресу:', link: 'newnextglobal.com'},
        ADDITIONALCONDITIONS_2: '10.2. Запрос, направляемый Пользователем, должен содержать следующую информацию:',
        ADDITIONALCONDITIONS_3: '1) скан-копию номер основного документа, удостоверяющего личность Пользователя или его представителя;',
        ADDITIONALCONDITIONS_4: '2) сведения, подтверждающие участие Пользователя в отношениях с Администратором Сайта (в частности, порядковый номер id пользователя или короткое (поддоменное) имя, заменяющее порядковый номер id);',
        ADDITIONALCONDITIONS_5: '3) фотографию Пользователя вместе с фотографией на паспорте, по которой можно идентифицировать принадлежность паспорта или иного документа, удостоверяющего личность.',
        ADDITIONALCONDITIONS_6: '10.3. Администрация Сайта рассматривает и направляет ответ Пользователю на поступивший запрос  в течение 30 (Тридцати) дней со дня получения Администрацией Сайта обращения Пользователя.',
        ADDITIONALCONDITIONS_7: '10.4. Вся корреспонденция, полученная Администрацией Сайта от пользователей, относится к информации ограниченного доступа и не разглашается без письменного согласия Пользователя. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.',


        TermsUseCookies: 'Правила использования файлов cookie и похожих технологий',
        StatusOfTheTerms: '1. Статус Правил использования файлов Cookies и похожих технологий (далее по тексту – Правила)',
        StatusOfTheTerms_1: '1.1. Настоящие Правила определяют порядок использования файлов Cookie и похожих технологий при пользовании Сайтом  и его функционалом.',
        StatusOfTheTerms_2: '1.2. Условиями настоящих Правил Администрация Сайта определяет их как публичную оферту в соответствии со ст. 437 Гражданского кодекса Российской Федерации. ',
        StatusOfTheTerms_3: '1.3. До момента регистрации на Сайте  Пользователь обязан полностью ознакомиться с настоящими Правилами. Регистрация Пользователя на Сайте означает полное и безоговорочное принятие Пользователем настоящих Правил в соответствии со ст. 438 Гражданского кодекса Российской Федерации.',
        StatusOfTheTerms_4: '1.4. Термины и определения, используемые в настоящих Правилах, определены в Правилах пользования Сайтом.',
        StatusOfTheTerms_5: '1.5. Используя Сайт, Пользователь подтверждает свое согласие на использование файлов cookie в соответствии с условиями, описанными ниже.',
        StatusOfTheTerms_6: '1.6. Если Пользователь Сайта не согласен с тем, чтобы  использовался данный тип файлов и похожие технологии,  он должны соответствующим образом установить настройки своего браузера или не пользоваться функционалом Сайта.',
        UseOfCookies: '2. Использование файлов cookies',
        UseOfCookies_1: '2.1. Cookies – это небольшие файлы, состоящие из букв и цифр, которые сохраняются  браузером Пользователя Сайта на устройстве Пользователя Сайта (компьютер, телефон, планшет и т.п.), если Пользователь Сайта  соглашается на их использование.',
        UseOfCookies_2: '2.2. Файлы cookies и похожие технологии на Сайте newnext необходимы для того, чтобы отличить Пользователя Сайта от других пользователей. Использование файлов cookies позволяет Администрации Сайта владельцу определить особенности пользования  Сайтом для дальнейшего совершенствования его функциональности,  повышения привлекательность, улучшения содержания.',
        UseOfCookies_3: '2.3. Виды файлов cookie , используемых на Сайте:',
        UseOfCookies_4: '• Крайне необходимые файлы cookies. Это файлы, необходимые для корректной работы  Сайта.',
        UseOfCookies_5: '• Аналитические/технические файлы cookies . Они позволяют оценить и подсчитать число посетителей Сайта, а также понять, как они перемещаются по Сайту при работе с ним. Это помогает вносить улучшения в работу сайта, , делая его более и эффективным.',
        UseOfCookies_6: '• Функциональные файлы. Данные файлы cookies  нужны для того, чтобы распознать Пользователя, когда он снова заходит на Сайт.',
        UseOfCookies_7: '• Целевые файлы cookies. Эти файлы регистрируют посещения Сайта Пользователем, запоминают страницы, которые посетил Пользователь и ссылки, по которым он проходил.',
        UseOfCookies_8: {attentional: 'ВАЖНО!', text: ' Обращаем внимание на то, что третьи лица (например, рекламные сети или провайдеры внешних услуг, таких как анализ веб-трафика) также могут использовать файлы cookies . Этот процесс не контролируется Администрацией. Эти файлы cookies  скорее всего являются аналитическими/техническими cookie-файлами или целевыми cookie-файлами.'},
        UseOfCookies_9: 'Пользователь можете заблокировать файлы cookies, активировав определённую настройку в браузере, что позволит Пользователю отказаться от установки всех или части файлов cookies. Однако в случае блокировки файлов cookies в интернет браузере (включая крайне необходимые файлы cookies), Пользователь может потерять доступ ко всем или нескольким разделам Сайта.',
        TheShelfLifeCookies: '3. Срок хранения файлов cookies',
        TheShelfLifeCookies_1: '3.1. .«Сеансовые». Это файлы cookies, действующие с момента  входа Пользователя на Сайт до конца данной конкретной сессии работы в браузере. При закрытии браузера эти файлы становятся ненужными и автоматически удаляются.',
        TheShelfLifeCookies_2: '3.2. .«Постоянные». Эти файлы cookie сохраняются на устройстве и в промежутке между сессиями работы в браузере — они не удаляются после закрытия браузера. Такие файлы Срок хранения постоянных файлов cookie на устройстве различается для разных файлов cookie.',
        CookieManagement: '4. Управление файлами cookie',
        CookieManagement_1: '4.1. Большинство интернет-браузеров изначально настроены автоматически принимать cookie. Пользователь можете изменить настройки таким образом, чтобы блокировать cookie или предупреждать, когда файлы данного типа будут отправлены на устройство.',
        CookieManagement_2: '4.2. Для управления файлами cookie необходимо  обратиться к инструкции браузера и узнать больше о том, как скорректировать или изменить настройки браузера. Если отключить файлы cookie, которые использует Сайт, это может повлиять на работу с Сайтом.',
        CookieManagement_3: '4.3. При  использовании Пользователем различных устройства для доступа к Сайту (например, компьютер, смартфон, планшет и т.д.), необходимо убедиться, что каждый браузер на каждом устройстве настроен в соответствии с  точкой зрения Пользователя  на работу с файлами cookie.',
        OtherSimilarTechnologies: '5. Другие подобные технологии',
        OtherSimilarTechnologies_1: '5.1. Администрацией Сайта так же используются технологии, схожие с cookies.',
        OtherSimilarTechnologies_2: '5.2. Подобные технологии дают  информацию о том, как Сайт и его содержание используются Пользователями и позволяет установить, посещался ли  Сайт с  компьютера или девайса Пользователя.',
        OtherSimilarTechnologies_3: '5.3. Серверы автоматически считывают IP-адрес Пользователя, когда он посещает Сайт, и  связывают эту информацию с доменным именем или с именем  интернет-провайдера Пользователя.',
        OtherSimilarTechnologies_4: '5.4. Администрация Сайта также может собирать «сведения о посещениях», относящиеся к использованию Сайта Пользователем. Сведения о посещениях включает в себя, например, информацию о компьютере или устройстве Пользователя, веб-браузере или операционной системе и их установках, странице, которая привела на  Сайт, страницы и содержание, которые видит и на которые кликает Пользователь во время визита, а также когда и на протяжении какого времени Пользователь это делает, что загружает, какие сайты посещает Пользователь после того, как покидает Сайт, а также ключевые слова, которые вводил Пользователь на Сайте или сайтах, с которых пришел на Сайт.',
        ChangesTermsCookies: '6. Изменения в Правилах использования файлов cookies',
        ChangesTermsCookies_1: '6.1. Администрация оставляет за собой право в любое время вносить изменения в Правила и актуализировать их в соответствии с обновляющимся законодательством и регуляциями. При пользовании Сайтом Пользователь может знакомиться с обновлениями и изменениями Правил, развитием методов сбора данных онлайн и использование этих данных Сайтом.',
        ChangesTermsCookies_2: '6.2. По вопросам, возникающим при исполнении данных Правил, Пользователь может обращаться в техническую поддержку Сайта.',

        WatchVideoProject: {one: 'Посмотрите видео о проекте', two: '(В разработке)'},
        AboutTheProject: 'О проекте',
        NewnextWorldFirst: 'newnext – международная бизнес-сеть, пользователи которой предлагают свои проекты, а так же устанавливают нужные им связи и контакты. newnext позволяет пользователям расширять свои возможности для реализации бизнес-идей, находить партнеров и единомышленников.',
        FoundedIn: {one: 'год создания', two: '2018'},
        MoreDevelopers: {one:'>20', two: 'участников'},
        MoreDesigners: {one:'4', two: 'предложений сети'},
        MoreAnalysts: {one:'6', two: 'типов пользователей'},
        WhatCanYouNewnext: 'Что можно делать в newnext?',
        PotentialInvestorss: 'Потенциальным инвесторам находить проекты для финансовых вложений',
        PlaceProjectsInvestment: 'Размещать проекты на инвестиционном рынке с целью привлечения инвестиций',
        FindBestDealsBusiness: 'Находить лучшие предложения бизнес-продуктов',
        FindGoodsProducts: 'Находить товары и продукцию',
        FindInterestedUsersImplementation: 'Находить заинтересованных пользователей для внедрения новаторских проектов и изобретений',
        ReceiveGualifiedSupport: 'Получать квалифицированную поддержку экспертов и профессионалов разных отраслей экономики и бизнеса',
        TheServicesExisting: 'Лучшие сервисы существующих социальных сетей объединяются в новой глобальной бизнес-сети newnext',
        TheFunctionalCapabilities: 'Функциональные возможности newnext',
        EstablishBusinessRelationships: 'Устанавливать деловые связи, удовлетворяющие интересам каждого типа пользователя',
        ReceiveConvenientTargeted: 'Получать удобный таргетированный поиск контактов в соответствии с целями каждого типа участника newnext',
        CreatePersonalContactList: 'Создавать персональный список контактов',
        ViewYourContactsFeeds: 'Просматривать ленты новостей своих контактов',
        MaintainPersonalCorrespondenceBusinessNetwork: 'Вести личную переписку с другими участниками бизнес-сети',
        MakeWithAbilityAmong: 'Размещать заявки с функцией рассылки целевым группам пользователей',
        ANYIDEAS: 'ЕСТЬ ИДЕЯ?',
        AreYouUserNetworkWould: 'У Вас есть интересная идея для бизнеса и Вы хотите найти финансирование для ее реализации? Предложите ее инвесторам!',
        OfferIdea: 'Предложить идею',



        RestrictedAccess: 'Ограничен доступ к странице',
        canNotSend: 'Вы не можете отправить сообщение этому пользователю, поскольку он ограничил доступ к своей странице',
        toAddBlackList: {one:'Вы хотите добавить', two: 'в черный список?'},
        addToBlackList: 'Добавить в черный список',
        removeFromBlackList: 'Удалить из черного списка',
        fromTheBlacklist: 'из черного списка?',
        blackList: 'Черный список',
        Contents: 'Содержание',
        Chapters: 'Главы',
        DidNotHelp: 'Не помогло, создать обращение в техподдержку',
        showMore: 'Показать еще ',
        help: 'Помощь',
        TermsOfUser: `Правила пользования Сайтом newnext`,
        WelcomeToNewnext: `Приветствуем Вас на  Сайте newnext, интернет-ресурсе, который помогает Вам в разумные сроки с минимумом каких-либо затрат решать поставленные бизнес-задачи и реализовывать бизнес-идеи путем поиска и создания  необходимых связей и контактов.`,
        NewNextWebsiteServes: `Сайт newnext выполняет функции площадки для бизнеса, объединяя пользователей в профессиональные бизнес-сообщества.`,
        TheSubjectOfTheseTerms: `Предметом настоящих Правил пользования Сайтом newnext (далее по тексту – Правила)  являются условия,  на которых Администрация Сайта newnext предоставляет Вам  доступ к использованию Сайта newnext  и его функционала.`,
        ShouldCarefullyRead: `Вам необходимо внимательно ознакомиться с условиями настоящих Правил, которые Администрация Сайта определяет как публичную оферту в соответствии со ст. 437 Гражданского кодекса Российской Федерации.`,
        StatusOfTermsUse: `1. Статус Правил пользования Сайтом newnext`,
        TheseTermsUseNewnextSite: `1.1. Настоящие Правила пользования Сайтом newnext (ранее и далее – Правила) разработаны Администрацией Сайта.`,
        RulesDetermineTermsConditions: `Правила определяют условия использования Сайта и его функционала, права и обязанности его Пользователей и Администрации.`,
        IfResultTheUseSite: `Если в результате использования Сайта и его функционала Пользователем затронуты права и интересы третьих лиц, не являющихся Пользователями Сайта, Правила распространяются также на отношения, связанные с правами и интересами этих третьих лиц.`,
        TheseRulesAreLegally: `1.2. Настоящие Правила являются юридически обязательным соглашением между Пользователем и Администрацией Сайта.`,
        TheSubjectOfAgreement: `Предметом соглашения между Пользователем и Администрацией Сайта  является предоставление Администрацией Сайта Пользователю доступа к использованию Сайта и его функционала.`,
        AllSpecialDocuments: `Все специальные документы, регулирующие предоставление доступа к использованию отдельного функционала Сайта, не включенные в Правила, также относятся к соглашению между Пользователем и Администрацией Сайта и размещены в соответствующих разделах Сайта в сети Интернет.`,
        RulesAreOpenAndPublic: '1.3. Настоящие Правила являются открытым и общедоступным документом. Действующая редакция Правил располагается в сети Интернет по адресу:',
        RulesAreOpenAndPubliclink: {text: ' http://newnextglobal.com/terms', url: '/terms'},
        PriorToRegistration: `1.4. До момента регистрации на Сайте  Пользователь обязан полностью ознакомиться с настоящими Правилами. Регистрация Пользователя на Сайте означает полное и безоговорочное принятие Пользователем настоящих Правил в соответствии со ст. 438 Гражданского кодекса Российской Федерации.`,
        RulesCanBeChangedSupplemented: `1.5. Настоящие Правила могут быть изменены и/или дополнены по инициативе Администрацией Сайта в одностороннем порядке без  специального уведомления. Администрация Сайта рекомендует Пользователям регулярно проверять условия настоящих Правил на предмет их изменения и/или дополнения, так как продолжение использования Сайта Пользователем после внесения изменений и/или дополнений в настоящие Правила однозначно означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.`,
        NewnextSiteStatus: `2. Статус Сайта newnext`,
        NewnextWebsiteInternational: `2.1. Сайт newnext (или Сайт) – международная глобальная бизнес-сеть newnext,  является интернет-ресурсом и представляет собой совокупность информации и программ для ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет по  адресу: newnextglobal.com  (включая все уровни указанного домена, как функционирующие на дату принятия Пользователем настоящих Правил, так и запускаемые и вводимые в эксплуатацию в течение всего срока его действия).`,
        SiteAdministrationProvidesAccess: `2.2. Администрация Сайта предоставляет доступ к Сайту всем заинтересованным лицам в соответствии с настоящими Правилами и действующим законодательством Российской Федерации.`,
        RightsSiteAsWhole: `Права на Сайт в целом и на использование сетевого адреса (доменного имени) http://newnextglobal.com принадлежат Администрации Сайта.`,
        TheConditionsAreSet: `2.3. Настоящими Правилами установлены условия, в соответствии с которыми права на использование информации и результатов интеллектуальной деятельности (включая, но не ограничиваясь литературными, музыкальными, аудиовизуальными произведениями и фонограммами, произведениями графики и дизайна, фотографическими произведениями, программами для ЭВМ) в составе отдельных разделов Сайта, могут принадлежать Пользователям Сайта и иным лицам, самостоятельно создавшим и/или разместившим указанные объекты на Сайте без непосредственного участия Администрации Сайта.`,
        NewnextSiteAdministration: `3. Администрация Сайта newnext`,
        SiteAdministrationTheseRules: `3.1. Под Администрацией Сайта newnext (ранее и далее – Администрация Сайта или Администрация) в настоящих Правилах и иных специальных документах, размещенных на Сайте, понимается Общество с ограниченной ответственностью «Альтернативные Современные Технологии», юридическое лицо (ИНН 6324004013, ОГРН 1096324003698), созданное в соответствии с  законодательством Российской Федерации и зарегистрированное по адресу: 445012, Самарская область, г.Тольятти, ул.Коммунистическая, д.8.`,
        AppealsProposalsAndClaims: `3.2. Обращения, предложения и претензии физических и юридических лиц к Администрации Сайта в связи с настоящими Правилами и всеми вопросами по функционированию Сайта, нарушениями прав и интересов третьих лиц при его использовании, а также для запросов уполномоченных законодательством Российской Федерации лиц могут быть направлены на почтовый адрес ООО «Альтернативные Современные Технологии», указанный в п. 4.1. настоящих Правил.`,
        WithRegardToTheOperation: `3.3. В отношении функционирования и развития Сайта Администрация руководствуется законодательством Российской Федерации, настоящими Правилами и иными специальными документами, которые разработаны или могут быть разработаны и приняты Администрацией Сайта в целях регламентации предоставления Пользователям доступа к отдельному функционалу Сайта.`,
        TheRightUseBrandName: `3.4. Право на использование фирменного наименования, товарных знаков, доменных имен и иных отличительных знаков Администрации Сайта может быть предоставлено исключительно по письменному соглашению с Администрацией Сайта. Никакие положения настоящих Правил не предоставляют Пользователю право на использование фирменного наименования, товарных знаков, доменных имен и иных отличительных знаков Администрации Сайта.`,
        RegistrationOnTheNewnextUser: `4. Регистрация на Сайте newnext и статус Пользователя`,
        UserRegistrationSiteIfFree: '4.1. Регистрация Пользователя на Сайте является бесплатной, добровольной и производится по адресу в сети Интернет:',
        UserRegistrationSiteIfFreelink: {text: ' newnextglobal.com', url: '/'},
        UserOfSiteisANatural: `4.2. Пользователем Сайта является физическое или юридическое лицо, зарегистрированное на Сайте в соответствии с установленным настоящими Правилами порядком, достигшее возраста, допустимого в соответствии с законодательством Российской Федерации для акцепта настоящих Правил (для физических лиц),  (ранее и далее – Пользователь).`,
        IfTheUserofTheSiteLegal: `Если Пользователем Сайта является юридическое лицо, представитель(ли)  Пользователя, осуществляющий регистрацию Пользователя, имеющий в последствии доступ к странице Пользователя определяются Пользователем самостоятельно и не регулируются настоящими Правилами.`,
        WhenRegisteringonTheSite: `4.3. При регистрации на Сайте Пользователь обязан предоставить Администрации Сайта необходимую достоверную и актуальную информацию для формирования персональной страницы Пользователя, включая уникальные для каждого Пользователя логин и пароль доступа к Сайту, а также все данные, отмеченные в регистрационной форме  как обязательные. Регистрационная форма Сайта содержит дополнительную информацию, заполнение которой позволит Пользователю более полно использовать возможности и функционал Сайта.`,
        TheUserIsResponsible: `4.4. Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству Российской Федерации информации, предоставленной при регистрации информации,  и ее чистоту от претензий третьих лиц.`,
        AfterProvidingInformation: `4.5. После предоставления информации, указанной в п. 4.3. настоящих Правил, Пользователю  предлагается:`,
        ConfirmRegistrationAutomated: `(а) подтвердить регистрацию путем распознания автоматизированного теста, предназначенного для различия компьютеров и людей («капчи»);`,
        ConfirmTheRegistration: `(б) подтвердить регистрацию путем введения в соответствующую форму на Сайте кода, полученного Пользователем в виде sms-сообщения от Администрации Сайта на мобильный телефон Пользователя, номер которого предоставляется самим Пользователем.`,
        InTheCaseCorrect: `В случае верного последовательного выполнения всех регистрационных действий на Сайте создается персональная страница Пользователя, имеющая сетевой адрес вида http://newnextglobal.com/id[номер].`,
        RegistrationTheUserOnTheSite: `4.6. Регистрация Пользователя на Сайте означает, что Пользователь соглашается с настоящими Правилами и принимает на себя указанные в них права и обязанности, связанные с использованием Сайта и его функционала. Пользователь соглашается на получение посредством сервисов Администрации Сайта и/или сервисов третьих лиц электронных сообщений, sms и иных видов рассылок информационного, в т.ч. рекламно-информационного, содержания, в том числе от партнеров Администрации Сайта, которым Пользователь выразил согласие на отправку ему сообщений. Пользователь приобретает полный доступ к использованию функционала Сайта (его данных и команд).`,
        AfterSuccessfulReg: `4.7. После успешной регистрации Пользователя на Сайте у Администрации возникают  права и обязанности перед Пользователем, указанные в настоящих Правилах.`,
        TheProcessingUsers: `4.8. Обработка персональных данных Пользователя осуществляется в соответствии с законодательством Российской Федерации. Администрация Сайта обрабатывает персональные данные Пользователя в целях предоставления Пользователю доступа к использованию функционала Сайта, в том числе в целях получения Пользователем персонализированной (таргетированной) рекламы; проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать функционал и разделы Сайта, а также разрабатывать новый функционал и разделы Сайта.`,
        SiteAdminTakesAll: `Администрация Сайта принимает все разумные меры для защиты персональных данных Пользователя от неправомерного доступа, изменения, раскрытия или уничтожения. Администрация предоставляет доступ к персональным данным Пользователя только тем работникам, подрядчикам и агентам Администрации, которым эта информация необходима для обеспечения функционирования Сайта и предоставления Пользователю доступа к его использованию.`,
        SiteAdminHasRightUse: `Администрация Сайта вправе использовать предоставленную Пользователем информацию, в том числе персональные данные, а также передавать ее третьим лицам, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации, защиты прав и интересов Пользователей, Администрации Сайта, третьих лиц (в том числе в целях выявления, проверки/расследования и/или пресечения противоправных действий). Раскрытие предоставленной Пользователем информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях. Поскольку Администрация Сайта осуществляет обработку персональных данных Пользователя в целях исполнения настоящих Правил, в силу положений законодательства о персональных данных, отдельного согласия Пользователя на обработку его персональных данных не требуется.`,
        LoginAndPassword: `4.9. Логин и пароль, зарегистрированные  Пользователем, являются необходимой и достаточной информацией для доступа Пользователя на Сайт. Пользователь не имеет права передавать свои логин и пароль третьим лицам, несет полную ответственность за их сохранность, самостоятельно выбирая способ их хранения. Пользователь может разрешить хранение логина и пароля (с использованием файлов cookies) для последующей автоматической авторизации на Сайте на используемом им аппаратно-программном обеспечении.`,
        AnyActionsPerformed: `4.10.  Любые действия, совершенные с использованием  логина и пароля Пользователя , считаются совершенными соответствующим Пользователем, если Пользователем не доказано обратное. Пользователь обязан незамедлительно сообщать Администрации Сайта  о случаях несанкционированного доступа к логину и паролю, персональной странице Пользователя,  распространения логина и пароля.`,
        TheUserHasRightCreate: `4.11. Пользователь имеет право самостоятельно в личных целях создавать, использовать и определять содержание собственной персональной страницы и условия доступа других Пользователей к ее содержанию, а также получает возможности доступа и размещения информации на персональных страницах других Пользователей (при условии получения соответствующих прав доступа от их обладателей), использования виджетов newnext на сторонних сайтах без дополнительной авторизации. При использовании  виджетов Пользователем сторонним сайтам не предоставляются данные о логине и пароле Пользователя, а также его данные, защищенные настройками приватности.`,
        TheUserAsTheOwner: `4.12. Пользователь, как обладатель информации, размещенной на собственной персональной странице, осознает, что за исключением случаев, установленных настоящими Правилами и действующим законодательством Российской Федерации, Администрация Сайта не принимает участие в формировании и использовании содержания и контроле доступа других пользователей к персональной странице Пользователя. Размещая информацию на персональной странице, в том числе свои персональные данные, Пользователь осознает и соглашается с тем, что указанная информация может быть доступна другим пользователям сети Интернет с учетом особенностей архитектуры и функционала Сайта.`,
        WithRegardToPersonal: `4.13. В отношении информации о себе Пользователь имеет право:`,
        IndependentlyDeleteInfo: `(а) самостоятельно удалять ранее размещенную Пользователем информацию на Сайте;`,
        IndependentlyDeletePersonal: `(б) самостоятельно удалять персональную страницу Пользователя, направив запрос в Поддержку Сайта.`,
        ObligationsTheUser: `5. Обязанности Пользователя Сайта`,
        WhenUsingTheSite: `5.1. При использовании Сайта и его функционала Пользователь обязан:`,
        ToComplyProvisions: `(а) соблюдать положения действующего законодательства Российской Федерации, настоящих Правил и иных документов Администрации, связанных с функционированием Сайта;`,
        ToEnterReliable: `(б) вводить при заполнении регистрационной формы на Сайте для регистрации достоверные данные, следить за их актуализацией;`,
        ToInformImmediately: `(в) информировать незамедлительно Администрацию Сайта о несанкционированном доступе к персональной странице и/или о несанкционированном доступе и/или использовании пароля и логина Пользователя;`,
        ToWithholdAccess: `(г) не предоставлять доступ другим Пользователям к собственной персональной странице или к отдельной содержащейся на ней информации в случае, если это может привести к нарушению законодательства Российской Федерации и/или настоящих Правил, специальных документов Администрации Сайта;`,
        NotToPost: `(д) не размещать на персональной странице информацию и объекты (включая ссылки на них), которые могут нарушать права и интересы других лиц;`,
        ToBeResponsibleLegality: `(е) нести ответственность за законность размещения информации и объектов (включая, но не ограничиваясь, изображениями других лиц, чужими текстами различного содержания, аудиозаписями и видеофильмами);`,
        ToBeResponsibleSecurity: `(ж) нести ответственность за сохранность в тайне информации, содержащей персональные данные, сведения о деловой активности  и частной жизни других Пользователей и третьих лиц;`,
        NotToProvide: `(з) не предоставлять, без получения соответствующего предварительного разрешения, другим Пользователям и третьим лицам информацию, содержащую персональные данные, сведения о деловой активности  и частной жизни других Пользователей и третьих лиц.`,
        SiteAdminRecommends: `5.2. Администрация Сайта рекомендует воздержаться от осуществления тех или иных действий, в том числе по размещению информации или предоставлению доступа, в случае наличия сомнений в отношении законности.`,
        TheUserOfTheSiteProhibited: `5.3. Пользователю Сайта запрещается:`,
        FromRegistering: `5.3.1. регистрироваться от имени или вместо другого лица, создав «фальшивый аккаунт». Допускается регистрация от имени и поручению другого физического лица или юридического лица при обязательном наличии  полномочий в порядке и форме, предусмотренных законодательством Российской Федерации;`,
        FromMisleading: `5.3.2. вводить в заблуждение Пользователей относительно своей личности, используя логин и пароль другого  Пользователя, искажать сведения о себе, своих отношениях с третьими лицами или организациями;`,
        FromUploadingStore: `5.3.3. загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать любую информацию, которая:`,
        ViolatesPrivacy: `(а) нарушает неприкосновенность частной жизни других Пользователей или третьих лиц;`,
        ViolatesMinors: `(б) нарушает права несовершеннолетних лиц;`,
        VulgarOrObscene: `(в) является вульгарной или непристойной, содержит порнографические изображения и тексты или сцены сексуального характера с участием несовершеннолетних, содержит угрозы, дискредитирует, оскорбляет, порочит честь и достоинство или деловую репутацию других Пользователей или третьих лиц,`,
        ContainsScenesAnimalAbuse: `(г) содержит сцены жестокого обращения с животными;`,
        IncitementToCommitSuicide: `(д) содержит любое подстрекательство к совершению суицида;`,
        PromotesRacial: `(е) пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды, пропагандирует фашизм или идеологию расового превосходства;`,
        ExtremistMaterials: `(ж) содержит экстремистские материалы;`,
        CriminalActivityAdvice: `(з) пропагандирует преступную деятельность, советы, инструкции или руководства по совершению преступных действий;`,
        RestrictedInfo: `(и) содержит информацию ограниченного доступа, включая, но не ограничиваясь, государственной и коммерческой тайной, информацией о частной жизни третьих лиц;`,
        AdvertisesOrPromo: `(к) содержит рекламу или пропагандирует привлекательность употребления наркотических веществ, в том числе «цифровых наркотиков» (звуковых файлов, оказывающих воздействие на мозг человека за счет бинауральных ритмов), информацию о распространении наркотиков, рецепты их изготовления и советы по употреблению;`,
        isFraudulent: `(л) носит мошеннический характер;`,
        ViolatesOtherRights: `(м) нарушает иные права и интересы граждан и юридических лиц, а так же требования законодательства Российской Федерации.`,
        ToUploadStore: `5.3.4. загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать интеллектуальную собственность Пользователей и третьих лиц, получив доступ к ним незаконным путем.`,
        WithoutConsentOther: `5.3.5. без согласия других Пользователей Сайта осуществлять массовые рассылки сообщений в их адрес.`,
        ToCarryOutActions: `5.3.6. осуществлять действия, в том числе используя программные средства,  направленные возникновение сбоев в работе Сайта.`,
        ToUseViruses: `5.3.7. использовать вирусы, трояны и другие вредоносные программы.`,
        ToUseAutomatedScripts: `5.3.8. использовать автоматизированные скрипты (программы, боты, краулеры) для сбора информации на Сайте и/или взаимодействия с Сайтом и его функционалом.`,
        InAnyWayTry: `5.3.9. любым способом пытаться получить доступ к персональным данным, логину и паролю другого Пользователя, осуществлять незаконные как сбор, так и обработку персональных данных других лиц;`,
        UseSiteWayOtherThan: `5.3.10. без предварительного соглашения с Администрацией осуществлять использование Сайта иным способом, кроме как через интерфейс, предоставленный Администрацией Сайта.`,
        ToCopyAndTransfer: `5.3.11. копировать и передавать (продавать) доступ к использованию Сайта;`,
        ToPostCommercial: `5.3.12. размещать коммерческую и политическую рекламу вне специальных разделов Сайта, установленных Администрацией Сайта;`,
        ToPostAnyOtherInfo: `5.3.13. размещать любую другую информацию, которая не соответствует целям создания Сайта, ущемляет интересы Пользователей или по другим причинам является нежелательной для размещения на Сайте.`,
        UserPersonally: `5.4. Пользователь несет личную ответственность за любую информацию, которую размещает на Сайте, сообщает другим Пользователям, а также за любые взаимодействия с другими Пользователями, осуществляемые на свой риск.`,
        UserObliged: `5.5. Пользователь обязан отказаться от использования Сайта в случае несогласия Пользователя с настоящими Правилами или в будущем их обновлениями, проинформировав об этом Администрацию Сайта.`,
        IntellectualProperty: `6. Условия об интеллектуальных правах`,
        ExclusiveRights: `6.1. Исключительные права на Контент, размещенный на Сайте.`,
        ObjectsExclusive: `6.1.1. Объектами исключительных прав Администрации, Пользователей Сайта и других правообладателей являются все объекты, размещенные на Сайте, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, скрипты  и другие объекты и их подборки (далее — Контент). Все права на эти объекты защищены.`,
        NoContentMayBeCopied: `6.1.2. Никакой Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен во фрейме, опубликован, скачан, передан, продан или иным способом использован целиком или по частям без предварительного разрешения правообладателя,  кроме случаев, когда правообладатель явным образом выразил свое согласие на свободное использование Контента любым лицом, а так же кроме случаев, установленных настоящими Правилами и действующим законодательством Российской Федерации.`,
        UserGrantsOtherUsers: `6.1.3. Пользователь предоставляет другим пользователям неисключительное право на использование размещаемого  на Сайте принадлежащего ему на законных основаниях Контента в рамках функционала, предоставляемого Сайтом,  путем просмотра или  воспроизведения (в том числе копирования) с целью личного некоммерческого использования, за исключением  случаев, когда такое использование причиняет или может причинить вред охраняемым законом интересам правообладателя.`,
        UsersUseContentAccess: `6.1.4. Использование Пользователем Контента, доступ к которому получен исключительно для личного некоммерческого использования, допускается при условии сохранения всех знаков авторства (копирайтов) или других уведомлений об авторстве, сохранения имени автора в неизменном виде, сохранении произведения в неизменном виде.`,
        UserGrantsSiteAdmin: `6.1.5. Пользователь предоставляет  Администрации Сайта неисключительное право использовать на безвозмездной основе размещенный на Сайте и принадлежащий ему на законных основаниях Контент в целях обеспечения Администрацией Сайта функционирования Сайта в объеме, определяемом функционалом и архитектурой Сайта.`,
        SiteAdminGranted: `Администрации  Сайта предоставляется неисключительное право использования  и отображения Контента в промоматериалах Администрации Сайта, в том числе в рамках изображений интерфейса Сайта, включая использование с целью  доведения таких промоматериалов до всеобщего сведения.`,
        NonExclusiveRight: `Указанное неисключительное право распространяет свое действие на территории стран всего мира и предоставляется на срок размещения Контента на Сайте.`,
        ExpirationTheTerm: `Окончание срока размещения Контента на Сайте и/или срока действия неисключительного права не влечет за собой необходимость изъятия из оборота промоматериалов Администрации Сайта с отображением Контента (в том числе их удаление из сети Интернет). Пользователь соглашается с тем, что Администрация вправе использовать функциональные и технические возможности Сайта, обеспечивающие отображение размещаемого Пользователем Контента, включая проигрыватель (плеер), предназначенные для целей отображения Контента, по своему усмотрению, в том числе для целей показа рекламной информации.`,
        SiteAdminHasRightTransfer: `Администрация Сайта вправе передавать права, указанные в настоящем пункте третьим лицам.`,
        AdminReservesRight: `6.1.6. Администрация оставляет за собой право в случае необходимости, обусловленной техническими особенностями работы Сайта, сохранять архивные копии пользовательского Контента в течение необходимого срока  даже если Пользователь удаляет свой Контент с Сайта,  и это повлекло за собой автоматический отзыв неисключительного права, упомянутого в п. 6.1.5. настоящих Правил.`,
        UserDoesNotHaveRightTo: `6.1.7. Пользователь не вправе загружать или иным образом доводить до всеобщего сведения (публиковать на Сайте) Контент других сайтов, баз данных и прочие результаты интеллектуальной деятельности, кроме своего собственного Контента,   при отсутствии явным образом выраженного согласия правообладателя на такие действия.`,
        AnyUserSite: `6.1.8. Категорически запрещено любое использование Сайта или Контента без предварительного письменного разрешения правообладателя, кроме разрешенного в настоящих Правилах или в случае явно выраженного согласия правообладателя на такое использование.`,
        NothingTheseTerms: `6.1.9. Ничто в настоящих Правилах не может быть рассмотрено как передача исключительных прав на Контент, если иное явным образом не установлено в настоящих Правилах,.`,
        Liability: `6.2. Ответственность за нарушение исключительных прав.`,
        UserPersonallyResponsible: `6.2.1. Пользователь несет личную ответственность за любой Контент или иную информацию, которые он загружает или иным образом доводит до всеобщего сведения (публикует) на Сайте или с его помощью.`,
        TheUserDoesNotRelevant: `Если Пользователь не обладает соответствующими правами, приобретенными или переданными ему в соответствии с законодательством Российской Федерации, он не имеет права загружать, передавать или публиковать Контент на Сайте. Для рассмотрения жалоб, касающихся нарушения прав, Пользователь должен направить обращение в Поддержку Сайта.`,
        AdminNotObliged: `6.2.2. Администрация Сайта  не обязана, просматривать Сайт на наличие запрещенного Контента, но может  по своему усмотрению удалять или перемещать (без предупреждения) любой Контент или пользователей.`,
        SiteAdminMay: `Администрация Сайта может по  своему личному усмотрению, по любой причине или без причины, без всяких ограничений перемещение или удаление Контента, который, по личному мнению Администрации, нарушает настоящие Правила, законодательство Российской Федерации и/или может нарушать права, причинить вред или угрожать безопасности других Пользователей или третьих лиц.`,
        WebsitedAndContent: `6.3. Сайты и Контент третьих лиц.`,
        SiteMayContain: `6.3.1. Сайт может содержать  ссылки на другие сайты в сети Интернет (сайты третьих лиц), включая статьи, фотографии, иллюстрации, графические изображения, музыку, звуки, видео, информацию, приложения, программы и другой Контент, принадлежащий третьим лицам (Контент третьих лиц), которые являются продуктами  интеллектуальной деятельности и охраняются в соответствии с законодательством Российской Федерации.`,
        AdminNotResponsible: `6.3.2. Администрация не несет ответственность за любую информацию, размещенную на сайтах третьих лиц, к которым Пользователь получает доступ через Сайт или через Контент третьих лиц, и не проверяет на соответствие тем или иным требованиям.`,
        LinksOrManuls: `6.3.3. Размещенные на Сайте ссылки или руководства по скачиванию файлов и/или установке программ третьих лиц не означают поддержки или одобрения этих действий со стороны Администрации.`,
        LinkToAnySiteProduct: `6.3.4. Ссылка на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на Сайте, не означает одобрения или рекомендаций данных продуктов (услуг) со стороны Администрации.`,
        IfUserGoesThirdParty: `6.3.5. Если Пользователь переходит к сайтам третьих лиц или использует или устанавливает программы третьих лиц, покинув  Сайт,  Сайт не несет за дальнейшие действия Пользователя никакой ответственности, и с этого момента настоящие Правила более не распространяются на Пользователя.`,
        OpertaionNewnextSite: `7. Функционирование Сайта newnext и ответственность при его использовании`,
        UsersResponsible: `7.1. Пользователи несут ответственность:`,
        ForTheirOwnActions: `- за собственные действия в связи с созданием и размещением информации на собственной персональной странице на Сайте,`,
        ForActionsRelatedPlacement: `- за действия, связанные с размещением информации на персональных страницах других Пользователей и в иных разделах Сайта в соответствии с действующим законодательством Российской Федерации. Нарушение настоящих Правил и действующего законодательства Российской Федерации влечет за собой гражданско-правовую, административную и уголовную ответственность.`,
        AdminDoesNotParticipate: `7.2. Администрация не участвует в формировании содержания персональных страниц Пользователей и не контролирует и не несет ответственности за действия или бездействие любых лиц в отношении использования Сайта или формирования и использования содержания персональных страниц Пользователей на Сайте, а лишь предоставляет техническую возможность использования Сайта.`,
        SiteAdminCanImplement: `7.3. Администрация Сайта может реализовывать технические решения, осуществляющие автоматические цензуру и контроль действий и информационных отношений Пользователей по использованию Сайта, нарушений прав третьих лиц на результаты интеллектуальной деятельности.`,
        AdminReservesRightToChange: `7.4. Администрация сохраняет за собой право изменять оформление Сайта, его содержание, функционал, изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся на Сайте, любые серверные приложения в любое время с предварительным уведомлением или без такового.`,
        SiteAdminNotEngaged: `7.5. Администрация Сайта не занимается предварительной модерацией или цензурой информации, содержащейся на персональных страницах  Пользователей, и предпринимает действия по защите прав и интересов лиц и обеспечению соблюдения требований законодательства Российской Федерации только после обращения в установленном порядке заинтересованного лица к Администрации Сайта.`,
        SiteaDminHasRightChange: `7.6. Администрация Сайта имеет право по собственному усмотрению, а также при получении информации от других пользователей либо третьих лиц о нарушении Пользователем настоящих Правил, изменять (модерировать), блокировать или удалять любую публикуемую Пользователем информацию, нарушающую запреты, установленные настоящими Правилами. Администрация Сайта оставляет за собой право приостанавливать, ограничивать или прекращать доступ Пользователя  к любому из разделов или функционалу Сайта в любое время по любой причине или без объяснения причин, с предварительным уведомлением или без такового, а так же Сайта в целом.`,
        SiteAdminDelete: `Администрация Сайта имеет право удалить персональную страницу Пользователя и/или приостановить, ограничить или прекратить доступ Пользователя к любой из функциональных возможностей Сайта, если Администрация обнаружит, что по ее мнению, Пользователь представляет угрозу для Сайта и/или его Пользователей.`,
        SiteAdminBlock: `Администрация Сайта вправе блокировать и/или удалять Приложения API, ограничивать доступ Пользователей к Приложения API, сайтам, приложениям третьих лиц, иным сторонним ресурсам, удалять ссылки на них, в случае если у Администрации Сайта появятся основания полагать, что они представляют или могут представлять угрозу для нормальной работы Сайта и его Пользователей.`,
        SiteAdminImplementsMeasures: `Администрация Сайта реализует описанные выше меры в соответствии с применимым законодательством и не несет ответственности за возможные негативные последствия таких мер для Пользователя или третьих лиц.`,
        RemovalPersonalPage: `7.7. Удаление персональной страницы Пользователя означает автоматическое удаление всей информации, размещенной на ней, а также всей информации Пользователя, введенной при регистрации на Сайте. После удаления персональной страницы Пользователь теряет доступ к использованию Сайта.`,
        SiteAdminEnsures: `7.8. Администрация Сайта обеспечивает функционирование и работоспособность Сайта и обязуется оперативно восстанавливать его работоспособность в случае технических сбоев и перерывов.`,
        SiteAdminNotResponsible: `Администрация Сайта не несет ответственности  за временные сбои и перерывы в работе Сайта и вызванные ими последствия потери информации.`,
        AdminNotResponsibleAnyDamage: `Администрация не несет ответственности за любой ущерб компьютеру Пользователя или иного лица, мобильным устройствам, любому другому оборудованию или программному обеспечению, вызванный или связанный со скачиванием материалов с Сайта или по ссылкам, размещенным на Сайте.`,
        SiteADminDispose: `7.9. Администрация Сайта имеет право распоряжаться статистической информацией, связанной с функционированием Сайта, а также информацией Пользователей для обеспечения адресного показа рекламной информации различным аудиториям Пользователей Сайта.`,
        SiteAdminHasSend: `7.10. Администрация Сайта имеет право направлять Пользователю информацию о развитии Сайта и его функционала, а также рекламировать собственную деятельность и услуги.`,
        LimitationLiability: `7.11. Ограничение ответственности Администрации Сайта:`,
        WEBSITEFUNCTIONALITY: `7.11.1. САЙТ И ЕГО ФУНКЦИОНАЛ, ВКЛЮЧАЯ ВСЕ СКРИПТЫ, ПРИЛОЖЕНИЯ, КОНТЕНТ И ОФОРМЛЕНИЕ САЙТА ПОСТАВЛЯЮТСЯ «КАК ЕСТЬ». АДМИНИСТРАЦИЯ ОТКАЗЫВАЕТСЯ ОТ ВСЯКИХ ГАРАНТИЙ ТОГО, ЧТО САЙТ ИЛИ ЕГО ФУНКЦИОНАЛ МОГУТ ПОДХОДИТЬ ИЛИ НЕ ПОДХОДИТЬ ДЛЯ КОНКРЕТНЫХ ЦЕЛЕЙ ИСПОЛЬЗОВАНИЯ. АДМИНИСТРАЦИЯ НЕ МОЖЕТ ГАРАНТИРОВАТЬ И НЕ ОБЕЩАЕТ НИКАКИХ СПЕЦИФИЧЕСКИХ РЕЗУЛЬТАТОВ ОТ ИСПОЛЬЗОВАНИЯ САЙТА И/ИЛИ ЕГО ФУНКЦИОНАЛА;`,
        USERSHOULDTAKE: `7.11.2. ПОЛЬЗОВАТЕЛЮ СЛЕДУЕТ СОБЛЮДАТЬ МЕРЫ ПРЕДОСТОРОЖНОСТИ В СКАЧИВАНИИ С САЙТА ИЛИ ПО РАЗМЕЩЕННЫМ НА НЕМ ССЫЛКАМ, И ИСПОЛЬЗОВАНИИ ЛЮБЫХ ФАЙЛОВ, В ТОМ ЧИСЛЕ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ. АДМИНИСТРАЦИЯ САЙТА НАСТОЯТЕЛЬНО РЕКОМЕНДУЕТ ИСПОЛЬЗОВАТЬ ТОЛЬКО ЛИЦЕНЗИОННОЕ, В ТОМ ЧИСЛЕ АНТИВИРУСНОЕ, ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ;`,
        BYUSINGWEBSITE: `7.11.3. ИСПОЛЬЗУЯ САЙТ, ПОЛЬЗОВАТЕЛЬ СОГЛАШАЕТСЯ С ТЕМ, ЧТО СКАЧИВАЕТ С САЙТА ИЛИ С ЕГО ПОМОЩЬЮ ЛЮБЫЕ МАТЕРИАЛЫ НА СВОЙ СОБСТВЕННЫЙ РИСК И НЕСЕТ ЛИЧНУЮ ОТВЕТСТВЕННОСТЬ ЗА ВОЗМОЖНЫЕ ПОСЛЕДСТВИЯ ИСПОЛЬЗОВАНИЯ УКАЗАННЫХ МАТЕРИАЛОВ, В ТОМ ЧИСЛЕ ЗА УЩЕРБ, КОТОРЫЙ ЭТО МОЖЕТ ПРИЧИНИТЬ КОМПЬЮТЕРУ ПОЛЬЗОВАТЕЛЯ ИЛИ ТРЕТЬИМ ЛИЦАМ, ЗА ПОТЕРЮ ДАННЫХ ИЛИ ЛЮБОЙ ДРУГОЙ ВРЕД;`,
        UNDERNOCIRCUMSTANCES: `7.11.4. НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ АДМИНИСТРАЦИЯ САЙТА ИЛИ ЕЕ ПРЕДСТАВИТЕЛИ НЕ НЕСУТ ОТВЕТСТВЕННОСТЬ ПЕРЕД ПОЛЬЗОВАТЕЛЕМ ИЛИ ПЕРЕД ЛЮБЫМИ ТРЕТЬИМИ ЛИЦАМИ ЗА ЛЮБОЙ КОСВЕННЫЙ, СЛУЧАЙНЫЙ, НЕУМЫШЛЕННЫЙ УЩЕРБ, ВКЛЮЧАЯ УПУЩЕННУЮ ВЫГОДУ ИЛИ ПОТЕРЯННЫЕ ДАННЫЕ, ВРЕД ЧЕСТИ, ДОСТОИНСТВУ ИЛИ ДЕЛОВОЙ РЕПУТАЦИИ, ВЫЗВАННЫЙ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ САЙТА, СОДЕРЖИМОГО САЙТА ИЛИ ИНЫХ МАТЕРИАЛОВ, К КОТОРЫМ ВЫ ИЛИ ИНЫЕ ЛИЦА ПОЛУЧИЛИ ДОСТУП С ПОМОЩЬЮ САЙТА, ДАЖЕ ЕСЛИ АДМИНИСТРАЦИЯ САЙТА ПРЕДУПРЕЖДАЛА ИЛИ УКАЗЫВАЛА НА ВОЗМОЖНОСТЬ ТАКОГО ВРЕДА.`,
        FinalProvision: `8. Заключительные положения`,
        TheseTermsAreAgreement: `8.1. Настоящие Правила являются  соглашением между Пользователем и Администрацией Сайта относительно порядка использования Сайта и его функционала.`,
        RulesShallBeGoverned: `8.2. Настоящие Правила регулируются и толкуются в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Правилами, подлежат разрешению в соответствии с законодательством Российской Федерации.`,
        InCaseAnyDisputed: `8.3. В случае возникновения любых споров или разногласий, связанных с исполнением настоящих Правил, Пользователь и Администрация Сайта приложат все усилия для их разрешения путем проведения переговоров между ними. В случае, если споры не будут разрешены путем переговоров, споры подлежат разрешению в порядке, установленном действующим законодательством Российской Федерации.`,
        RulesShallEnterInto: `8.4. Настоящие Правила вступают в силу для Пользователя с момента его присоединения к ним и действуют в течение неопределенного срока.`,
        RulesAreWrittenRussian: `8.5. Настоящие Правила составлены на русском языке и могут быть предоставлены Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Правил и версии Правил на ином языке, применяются положения русскоязычной версии настоящих Правил.`,
        IfForAnyReason: `8.6. Если по тем или иным причинам одно или несколько положений настоящих Правил будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений.`,



        GlobalBusiness: `Гид глобальной бизнес-сети newnext (newnext-гид)`,
        NewnextGuide: `Newnext-гид – проводник, с помощью которого Пользователи глобальной бизнес-сети newnext получают ответы на часто задаваемые вопросы по использованию функционала newnext.`,
        GENERALQUESTIONS: `ОБЩИЕ ВОПРОСЫ`,
        WhoToContact: `Куда обратиться, если Вы направили запрос в службу  Поддержки, но не получили ответа?`,
        WeWillDefinitelyAnswer: `Мы обязательно ответим на все вопросы, заданные Вами,  в порядке очереди. Ответ придет на Вашу электронную почту в течение 7 дней.`,
        IfYouHaveNotReceived: `Если в течение 7 дней Вы не получили ответ на запрос, пожалуйста проверьте указанный Вами при регистрации адрес электронной почты, перейдя в раздел «Редактирование профиля».`,
        DontAskUsTheSame: `Не задавайте нам один и тот же вопрос несколько раз. Вопросы, заданные  службе  Поддержки, не теряются.`,
        WhatIfYourDataIs: `Что делать, если Ваши данные используются на чужой странице?`,
        IfAnyUserUsesYour: `Если на  странице какого-то Пользователя используются Ваше имя, фамилия, фотография или другие данные, напишите нам в  службу Поддержки.`,
        InYourRequestPlease: `В своем обращении укажите следующие данные:`,
        FirstALinkToThe: `1. Ссылку на страницу «самозванца».`,
        SecondADescriptionOfWhat: `2. Описание, что именно принадлежит Вам (главная фотография, информация, имя и т.п.).`,
        ThirdAPhotoOfYour: `3. Фотографию Вашего документа, удостоверяющего Вашу личность.`,
        FourthAPhotoThatShowsYour: `4. Фотографию, на которой видно Ваше лицо на фоне Вашего обращения (рядом с монитором так, чтобы на нём было видно Ваше обращение).`,
        YouMustUnderstandThatAll: `Вы должны понимать, что все запрашиваемые для обращения данные необходимы нам, чтобы быть уверенными, что к нам обращается истинный владелец этих фотографий.`,
        HowToChangeTheSize: `Как изменить размеры страницы или шрифта?`,
        PleasePressCTRL0: `Пожалуйста, нажмите сочетание клавиш Ctrl+0 (зажать клавишу Ctrl и нажать ноль) — страница вернётся к стандартному размеру. Зажав Ctrl и покрутив колесо мыши, Вы можете всегда выбрать удобный масштаб.`,
        HowToTakeAScreen: `Как сделать скриншот?`,
        ForWindows: `Для Windows:`,
        PressThePrtScrKeyIn: `Нажмите клавишу Prt Scr в правом верхнем углу клавиатуры. Затем откройте Пуск → Все программы → Стандартные → Paint, выберите «Вставить». Сохраните файл в формате PNG или JPG.`,
        ForOSX: `Для OS X:`,
        CommandShiftThree: `Command + Shift + 3: весь экран в файл на рабочем столе.`,
        CommandShiftFour: `Command + Shift + 4: выделенная область в файл.`,
        CommandShiftFourSpace: `Command + Shift + 4 + пробел: окно программы в файл.`,
        ToSaveToTheClipboard: `Для сохранения в буфер обмена нужно дополнительно удерживать Ctrl.`,
        WhoToContactIfYouAre: `Куда обращаться, если Вам угрожают жалобами или блокировкой?`,
        YouNeedToContactTheSupport: `Вам нужно обратиться в службу  Поддержки и описать сложившуюся ситуацию.`,
        PleaseNoteThatOnlyTheSite: `Учтите, что блокировать Пользователя может только Администрация Сайта.`,
        IncommingComplaintsAre: `Поступающие жалобы проверяются  модераторами сайта вручную. Безосновательные жалобы отклоняются.`,
        HowToRefreshThePage: `Как обновить страницу?`,
        PressTheF5: `Нажмите на кнопку F5 в верхнем ряду клавиатуры – страница обновится.`,
        WhereCanIReadTheTerms: `Где прочитать Правила пользования Сайтом newnext?`,
        YouNeedToLinkTo: {text: `Вам нужно перейти по ссылке:  `, link: {text: 'newnextglobal.com/terms.', url: '/terms'}},
        WhereCanIReport: `Куда сообщить об ошибке в работе Сайта?`,
        IfYouFindAnError: `Если Вы  обнаружили ошибку в работе Сайта и хотите рассказать о ней, пожалуйста, сообщите об этом в службу Поддержки.`,
        InOrderTo: `Для полноты понимания обнаруженной Вами ошибки, пожалуйста, укажите последовательные шаги воспроизведения проблемы и прикрепите к обращению скриншоты.`,
        HowCanIChangeTheTime: `Как изменить настройки времени на Сайте?`,
        OurWebsiteUses: `Наш Сайт использует настройки времени Вашего устройства, поэтому Вам необходимо самостоятельно их проверить и установить.`,
        WeRecommendThat: `Рекомендуем выставить автоматическое определение часовых поясов.`,
        WhereToGoIfYou: `Куда обратиться, если Вас обманули, или как бороться с мошенниками?`,
        NewNextIsAPlatformThat: `Newnext  — площадка, включающая в себя  в том числе Пользователей, предоставляющих продукты и услуги. Мы предоставляем право пользоваться сервисами  нашего Сайта, но не проверяем добросовестность всех Пользователей. У нас нет инструментов, позволяющих подтвердить или опровергнуть информацию о предоставляемых Пользователями продуктах и услугах.`,
        IfYouAreAVictim: `Если Вы стали жертвой мошенников, настоятельно советуем обратиться в правоохранительные органы.`,
        DESCRIPTIONOFNEWNEXT: `ОПИСАНИЕ NEWNEXT`,
        WhatIsTheInternational: `Что такое международная глобальная бизнес-сеть newnext?`,
        NewnextIsTheWorlds: `Newnext - первая в мире международная глобальная бизнес-сеть, выполняющая функции площадки для бизнеса, объединив пользователей в профессиональное бизнес-сообщество заинтересованных людей.`,
        NewnextAllowsYouToSolve: ` Newnext позволяет в разумные сроки с минимумом ресурсных затрат решать поставленные бизнес-задачи и реализовывать бизнес-идеи путем приобретения и установки необходимых бизнес-связей и контактов.`,
        WhatServiceDoesNewnext: `Какой сервис предоставляет  Пользователям newnext?`,
        NewnextUsersAreProvided: `Пользователям newnext предоставляется удобный сервис, позволяющий:`,
        PotentialInvestors: `• потенциальным инвесторам находить проекты для финансовых вложений;`,
        UsersToPlaceProjects: `• размещать проекты на инвестиционном рынке с целью привлечения инвестиций;`,
        UsersToFindTheBest: `• находить лучшие предложения бизнес-продуктов;`,
        UsersToFindGoods: `• находить  товары и  продукцию;`,
        UsersToLearnAbout: `• узнавать о последних новинках из мира инноваций и высоких технологий;`,
        UsersToFindInterested: `• находить заинтересованных пользователей для внедрения новаторских проектов и изобретений;`,
        UsersToReceive: `• получать квалифицированную поддержку экспертов и профессионалов разных отраслей экономики и бизнеса;`,
        UsersToFindCustomers: `• находить клиентов и бизнес-партнеров;`,
        UserToFindQualified: `• находить квалифицированные кадры;`,
        UsersToFindJobs: `• находить работу, соответствующую квалификации и материальным потребностям;`,
        UsersToFormATeam: `• формировать команду для решения проектных задач;`,
        UsersToImplement: `• реализовывать краудфандинговые проекты,в том числе посредством привлечения инвестиций через ICO.`,
        NewnextHelpsUsersToDevelop: `Newnext помогает Пользователям развивать собственный  бизнес, зарабатывать реальные деньги, привлекать реальные инвестиции, устанавливать реальные бизнес-контакты.`,
        NewnextFunctionalCapabilities: `Функциональные возможности newnext`,
        NewnextUsersHave: `Пользователи newnext имеют возможности:`,
        EstablishBusinnes: `• Устанавливать деловые связи, удовлетворяющие интересам каждого типа пользователя.`,
        AttractInvestments: `• Привлекать инвестиции.`,
        FindProjectsForInvestment: `• Находить проекты для инвестиций.`,
        CreateStoreAndModify: `• Создавать, хранить и модифицировать персональную информацию.`,
        ReceiveAListOfNewnext: `• Получать список участников сети newnext (потенциальных контактов) в автоматическом режиме в соответствии с интересами каждого типа участника сети.`,
        ToObtainEasyTargeted: `• Получать удобный таргетированный поиск контактов в соответствии с бизнес-целями каждого типа участника сети.`,
        VisitAndViewPagesOf: `• Посещать и просматривать страницы других участников проекта.`,
        CreateAPersonalContact: `• Создавать персональный список контактов.`,
        MaintainPersonalCorrespondence: `• Вести личную переписку с другими участниками системы.`,
        CommunicateWithOtherParticipants: `• Общаться с другими участниками на удобном языке (используя автоматический перевод сообщений)`,
        PlaceApllicationsWith: `• Размещать заявки с функцией рассылки целевым группам участников.`,
        USERPAGE: `СТРАНИЦА ПОЛЬЗОВАТЕЛЯ`,
        HowToCreateAPage: `Как создать страницу?`,
        InOrderToCreateAPage: `Для того, чтобы создать страницу в newnext, необходимо выполнить следующий набор действий:`,
        FirstFollowTheLink: {text: `1. Перейти по ссылке `, link: {text: 'newnextglobal.com.', url: '/'}},
        SecondFillInReg: `2. На главной странице – «Регистрация» заполнить: тип участника, имя, фамилию, номер телефона, нажать клавишу «Зарегистрироваться».`,
        ThirdConfirmThePhone: `3. Подтвердить номер телефона кодом из SMS, пришедшего на указанный Вами телефон.`,
        ThePhoneNumberIsYour: `! Номер телефона – Ваш логин для входа в newnext.`,
        FourthEnterAndConfirm: `4. В следующем окне ввести и подтвердить пароль.`,
        AfterPerformingThe: `После выполнения вышеуказанных действий Вам предлагается выбрать категорию Пользователя newnext – физическое или юридическое лицо, заполнить поля анкеты, соответствующей выбранной категории Пользователя, и нажать клавишу «Отправить».`,
        CanIDeleteMyPage: `Можно ли удалить свою страницу?`,
        TheUserPageCanBe: `Страница Пользователя может быть удалена только Администрацией Сайта.`,
        YouCanSubmitA: `Вы можете направить запрос на удаление страницы в службу Поддержки.`,
        ATTENTIONItIsPossible: { attention: 'Важно!', text: 'Удаление страницы, к которой нет доступа, возможно, если на странице указаны настоящие имя и фамилия, а так же на аватаре присутствует Ваша фотография.'},
        InYourRequest: `В обращении в службу Поддержки необходимо приложить ссылку на страницу и два изображения: `,
        APhotoOfYourDocument: `- Фотографию Вашего документа (паспорта, водительских прав, студенческого билета свидетельства о рождении, и т.п.).`,
        APhotoThatShows: `- Фотографию, на которой Ваше лицо находится на фоне Вашего обращения (т. е. рядом с монитором, на котором виден Ваш вопрос).`,
        OnlyInThisCaseWe: `Только в этом случае мы можем убедиться, что запрос в Поддержку направлен именно Вами.`,
        HowToRecoverA: `Как восстановить забытый пароль?`,
        IfYourPageIsLinkedToTheCurrent: `Если Ваша страница привязана к актуальному номеру телефона, пароль можно изменить, нажав клавишу «Забыли пароль» / «Восстановить пароль» на главной странице Сайта.`,
        IfYourPageIsLinkedToAPhone: `Если Ваша страница привязана к номеру телефона, который в данный момент недоступен, необходимо обратиться в службу Поддержки.`,
        WhatDoesTypeOfMember: `Что означает  «тип участника»?`,
        TypeOfMemberInNewnext: `«Тип участника» в newnext является основным определяющим понятием.`,
        DueToTheFactThatTheMain: `В связи с тем, что основная задача newnext – установление бизнес связей между пользователями, развитие собственного бизнеса пользователей, осуществление бизнес-проектов, идей и т.п., «тип участника» позволяет в автоматическом режиме предоставлять информацию для конкретного Пользователя по интересующим его направлениям бизнеса.`,
        ItIsThereforeRecommended: `Именно поэтому рекомендуем очень внимательно отнестись к выбору «типа участника» при регистрации в newnext.`,
        WhyIsItNecessary: `Зачем выбирать категория Пользователя – юридическое или физическое лицо?`,
        YouCanRegisterInNewnext: `Вы можете зарегистрироваться в newnext как физическое лицо(1) или как юридическое лицо(2).`,
        InThefirstCaseAll: `В первом случае все Ваши действия будут вестись от собственного имени.`,
        InTheSecondCaseYou: `Во втором случае Вы регистрируетесь от имени юридического лица, как его представитель, и Ваши действия ведутся  в рамках деятельности юридического лица.`,
        WeWouldLikeToPoint: `Отметим, что в зависимости от выбранной Вами категории Вам предоставляются к заполнению поля анкеты либо юридического, либо физического лица при заполнении профиля участника.`,
        WhatDoesTheIndustryField: `Что означает поле "Отрасль" в анкете Пользователя?`,
        TheFieldIndustryInTheApllication: `Поле «Отрасль» в анкете отражает отраслевую  принадлежность основного вида бизнеса Пользователя newnext.`,
        CanIChangeThePhone: `Можно ли поменять телефон, привязанный к моей странице?`,
        YouCannotChangeThePhone: `Вы не можете самостоятельно поменять телефон, привязанный к странице, так как телефон – Ваш логин для входа в newnext.`,
        IfThePhoneNumberProvided: `Если номер телефона, указанный при регистрации, утратил актуальность, Вам следует обратиться в службу Поддержки.`,
        HowToSetTheMainPhoto: `Как установить главную фотографию (аватар)?`,
        OnTheLeftSideOf: `В левой части страницы Пользователя нажмите кнопку «Загрузить фотографию» (не более 1 МБ).`,
        DoYouWantToGetALink: `Вы хотите получить ссылку на свою страницу?`,
        ToGetALinkToYour: `Для получения ссылки на свою страницу Вам необходимо скопировать адрес профиля из навигационной строки браузера, которая находится вверху окна.`,
        InThisCaseTheCharacter: `При этом  набор символов после  «…/profile/» в адресной строке отражают номер Вашего ID.`,
        WhatDoesVerified: `Что означает «подтвержденная» Страница?`,
        VerifiedPageMeansThat: `«Подтвержденная» страница означает, что страница прошла верификацию —  проверку на подлинность и соответствие персоне или организации.`,
        ThePresenceOfACheck: `Наличие галочки, расположенной справа от имени Пользователя, является подтверждением, что пред Вами не клон.`,
        WhatNewsWillISee: `Какие новости я буду видеть на своей странице?`,
        OnTheUserPageThe: `На Странице пользователя в разделе Новости отображается информация:`,
        WhetherYouHaveBeen: `- добавление Вас в контакты Пользователями newnext.`,
        PlacingOrdersByUsers: `- размещение заявок Пользователями из Вашего списка контактов.`,
        CanIViewTheNewsOn: `Можно ли посмотреть новости на чужой странице?`,
        NoTheNewsOnSomeoneElses: `Нет. Новости на чужой странице доступны только владельцу этой страницы.`,
        HowToChangePersonal: `Как изменить личные данные  в анкете?`,
        YouCanChangeTheData: `Изменить данные в анкете можно,  перейдя в раздел «Редактирование профиля».`,
        ItIsImportantToRemember: `При этом важно помнить, что нельзя изменить телефон (логин), категорию участника и тип участника.`,
        CONTACTS: `КОНТАКТЫ`,
        HowToAddAUser: `Как добавить Пользователя в свой список контактов?`,
        AfterYouHaveVisitedThe: `После того, как Вы зашли на страницу интересующего Вас Пользователя, нажмите клавишу «+ Контакт» под аватаром, после чего появится комментарий «Пользователь успешно добавлен в контакт».`,
        YouCanAlsoAddAUser: `Вы так же можете добавить Пользователя в контакты на  своей странице,  нажав клавишу «+ Контакт» под именем Пользователя в разделе «Рекомендуем».`,
        IfTheUserInTurn: `В случае если Пользователь, в свою очередь, также добавил Вас в контакты, Вы получить об этом уведомление в разделе «Новости контактов».`,
        CanIFindAUserByPhone: `Можно ли найти Пользователя по номеру телефона?`,
        ItIsImpossibleToFind: `В newnext найти Пользователя по номеру телефона невозможно.`,
        HowToFindAUserById: `Как найти Пользователя по его id?`,
        PageIDIsTheNumberThat: `ID страницы — это номер, который получает Пользователь при регистрации в newnext.`,
        TheUserPageCanBeFound: `Страницу Пользователя можно найти, если Вам известен ID. Для этого  введите в строку браузера newnextglobal.com/profile/idХХХ, где XXX — нужный ID номер.`,
        WhyDoINeedToAdd: `Зачем добавлять Пользователя в свой список контактов?`,
        NewnextIsAnInternational: `Newnext – международная глобальная бизнес-сеть, которая поможет Вам установить необходимые бизнес-контакты и связи.`,
        EachUserOfNewnext: `Каждому Пользователю newnext соответствует свой «тип участника», выбранный им при регистрации. В соответствии со своим типом участника, Пользователи формируют список заявок на продвижение своих бизнес-интересов.`,
        InformationAbout: `Информация обо всех заявках, сформированных Пользователями из Вашего  списка контактов,  будет приходить на Вашу ленту новостей.`,
        HowWillTheUserKnow: `Как Пользователь узнает, что я добавил его в свой список контактов?`,
        IfYouHaveAddedTheUser: `Если Вы добавили Пользователя в список контактов, на его странице в верхней части над разделом «Контакты» загорится  уведомление, что  есть Пользователь, добавивший его  в контакты.`,
        ByClickingTheContacts: `Нажав ссылку  «Контакты» в верхней части страницы, Пользователь открывает одноименный раздел, в котором, перейдя в подраздел «Запросы», получает список Пользователей, добавивших его в контакты.`,
        InAccordanceWithTheList: `В соответствии со списком,  Пользователь может, нажатием клавиш «Принять», добавить Вас в контакты, о чем Вам будет отправлено уведомление в разделе «Новости».`,
        HowCanIRemoveAUser: `Как удалить Пользователя из контактов?`,
        AfterYouHavevisitedThePageOf: `После того, как Вы зашли на страницу интересующего Вас Пользователя, нажмите клавишу «х Удалить из контактов» под аватаром, после чего появится запрос на подтверждение действия на удаление. Нажатием клавиши «Да», Пользователь будет удален из Вашего списка контактов, и Вы получите комментарий «Пользователь успешно удален из контактов».`,
        YouCanAlsoRemoveAUser: `Вы так же можете удалить Пользователя из контактов на своей странице, перейдя по ссылке «Контакты» и  нажав в открывшемся списке контактов клавишу «х Удалить» напротив Пользователя, которого собираетесь удалить из контактов.`,
        HowWillTheUserKnowThatIDeleted: `Как Пользователь узнает, что я удалил его из своего списка контактов?`,
        ThereWillNotBeAny: `Никак оповещений о том, что Пользователь удален из Вашего списка контактов не формируется.`,
        HowManyUsersCanIHave: `Сколько у меня может быть Пользователей в списке контактов?`,
        TheNumberOfUsersInThe: `Число Пользователей в списке контактов не ограничено.`,
        WhyDoesEachUser: `Почему каждому Пользователю в списке контактов соответствует «тип Пользователя»?`,
        NewnextWillHelp: `Newnext – международная глобальная бизнес-сеть, которая поможет Вам установить необходимые бизнес-контакты и связи.`,
        EachNewnextUserCorresponds: `Каждому Пользователю newnext соответствует свой «тип участника», выбранный им при регистрации.`,
        AccordingToTheirMemberType: `В соответствии со своим типом участника, Пользователи формируют список заявок на продвижение своих бизнес-интересов.`,
        WhenYouOpenAContact: `Когда Вы открываете список контактов, информация о «типе Пользователя» дает Вам базовую  бизнес-ориентированную информацию о Пользователе.`,
        MESSAGES: `СООБЩЕНИЯ`,
        HowToWrite: `Как написать и отправить  cообщение?`,
        YouCanSend: `Написать  сообщение Пользователю newnext можно, перейдя на страницу Пользователя и нажав клавишу «Написать сообщение», расположенную под аватаром.`,
        AfterYouFinished: `После завершения набора текста сообщения нажатием клавиши «Отправить», сообщение будет отправлено Пользователю.`,
        YouCanSendAMessage: `Написать сообщение контакту из Вашего списка Вы можете, нажав клавишу «Сообщение» в соответствующей строке списка контактов.`,
        AreThereAnyLimits: `Есть ли лимиты на отправку cообщений?`,
        ThereAreNoLimits: `Никакие лимиты на размер и количество сообщений в newnext не установлены.`,
        HowToDeleteAMessage: `Как удалить cообщение?`,
        YouCanDeleteAMes: `Сообщение можно удалить нажатием клавиши «х», расположенной напротив интересующего Вас сообщения.`,
        HowCanIDeleteEntireDialog: `Как удалить диалог целиком?`,
        ItIsPossibleToRemove: `Диалог целиком можно удалить нажатием клавиши «х», расположенной напротив интересующего Вас диалога.`,
        HowCanIRecover: `Как восстановить удаленное cообщение или удаленный диалог?`,
        YouCannotRecover: `Удалённые Вами сообщения или диалог восстановить нельзя.`,
        PleaseNoteThatDeleting: `Обращаем Ваше внимание на то, что удаление сообщения -   одностороннее действие, и у  адресата сообщения остаются, даже если Вы удалили их на своей странице.`,
        IfYouNeedInformation: `Если Вам необходима информация из удаленных сообщений, обратитесь к адресату, и если  у него сообщения не удалены, он пришлет  Вам нужную информацию из сообщений или перешлет переписку целиком.`,
        HowToInsertEmoticons: `Как вставить смайлы в cообщение?`,
        YouCanUseEmoticons: `Вы можете использовать смайлики в личной переписке. В углу поля для ввода текста отображается иконка, при наведении на неё Вы увидите список всех доступных смайликов.`,
        AlsoYouCanEnterEmoticons: `Кроме того, Вы можете вводить  смайлики с клавиатуры: многие текстовые смайлики (например, «:-)» или «<3» ), автоматически преобразуются в графические.`,
        PleaseBeCarefulAndDoNotInstall: `Убедительно просим Вас быть осторожными и не устанавливать программы, которые обещают добавить к нашему набору новые дополнительные смайлики (мемы, троллфейсы и так далее). Они созданы не нами, часто крадут пароли и могут рассылать спам от Вашего имени.`,
        IfThereIsNoNecessary: `Нет нужного смайла.`,
        WeUserEmoticons: `Мы используем смайлы стандарта Emoji.`,
        HowCanICancel: `Как отменить  отправку cообщения?`,
        YouCannotCancelASent: `Отправленное сообщение отменить нельзя.`,
        WhyIsThereNo: `Почему на странице Пользователя нет кнопки «Написать сообщение»?`,
        IfTheUsersPageDoesNot: `Если на странице Пользователя нет кнопки «Написать сообщение», это означает, что Пользователь ограничил круг своего общения и, по-видимому, поместил Вас в «черный список».`,
        HowCanIPrevent: `Как запретить Пользователю отправлять мне cообщения?`,
        YouCanLimitTheCircle: `Вы можете ограничить круг общения с Пользователем, поместив его в «черный список».`,
        HowCanIKnowThatTheRecipient: `Как узнать о  том, что адресат  прочитал отправленное мною cообщение?`,
        AllUnreadMessagesAre: `Все непрочитанные сообщения подсвечиваются тонированным фоном.  Если сообщение отображается на белом фоне, значит оно прочитано адресатом.`,
        // Notice: `ЗАЯВКИ`,
        WhatDoesNnnotice: `Что такое заявка?`,
        NewnextIsAnInternational2: `Newnext – международная глобальная бизнес-сеть, которая поможет Вам установить необходимые бизнес-контакты и связи.`,
        EachNewnextUserCorresponds2: `Каждому Пользователю newnext соответствует свой «тип участника», выбранный им при регистрации. В соответствии со своим типом участника, Пользователи формируют список заявок на продвижение своих бизнес-интересов.`,
        DependingOnYourUserType: `В зависимости от Вашего «типа Пользователя» Вы формируете заявку, в которой Вы размещаете детальную информацию, отражающую  предлагаемое Вами базнес-направление.`,
        HowToMakeNNnotice: `Как создать заявку?`,
        InOrderToMakeNNnotice: `Для того, чтобы создать заявку Вам необходимо перейти в раздел «Заявки» и нажать клавишу «Создать новую заявку».`,
        YouWillBeAsked: `Вам будет предложено заполнить форму заявки, соответствующую Вашему «типу Пользователя».`,
        WhatAreNetwortNnnotice: `Что такое «Заявки сети»?`,
        ThisIsAListOf: `Это список заявок всех Пользователей newnext.`,
        HowCanIAddAnImage: `Как добавить изображение к заявке?`,
        InOrderToAddAn: `Для того, чтобы добавить изображение к заявке загрузите нужное изображение на файлообменник (например dropmefiles.com), и сформируйте ссылку на него.`,
        AddTheGenerated: `Сформированную ссылку  добавьте в поле  «Дополнительная информация».`,
        WhatIfIWantToAdd: `Что делать, если я хочу внести в заявку дополнительную информацию, но для этого нет специального поля?`,
        IfThereIsNoSpecial: `Если для размещения какой-то Вашей информации в заявке нет специального поля,  используйте поле «Дополнительная информация».`,
        WhatDoesTheIndustryFieldNnotice: `Что означает  поле "Отрасль" в заявке?`,
        TheFieldIndustry: `Поле «Отрасль» в заявке отражает смысловую отраслевую принадлежность заявки Пользователя.`,
        WhatDoesNNnoticeHasNotBeen: `Что означает «Заявка не опубликована»?`,
        AfterYouHaveSavedNNnotice: `После того, как Вы сохранили созданную Вами заявку, в поле статуса заявки отразится статус «Не опубликовано». Это означает, что Ваша заявка недоступна другим Пользователям newnext. `,
        IfYouWantToMake: `Если Вы хотите сделать заявку доступной для Пользователей newnext, выберите команду «Отправить на модерацию».`,
        WhatDoesTheNNnotice: `Что означает статус заявки?`,
        Unpublished: `«Не опубликовано»`,
        NNnoticeForSomeReason: `Заявка по каким-то причинам не отправляется Пользователем на модерацию, а сохраняется только на его странице.`,
        BeingModerated: `«На модерации»`,
        NNnoticeHasBeenSent: `Заявка отправлена на проверку Администрации newnext, перед тем как сделать ее доступной Пользователям newnext.`,
        Refused: `«Отклонено»`,
        NNnoticeDidNotPassModeration: `Заявка не прошла модерацию и осталась доступной только создавшему ее Пользователю.`,
        Published: `«Опубликовано»`,
        NNnoticeIsAvailable: `Заявка доступна  всем Пользователям newnext.`,
        HowToSendNNnotice: `Как отправить заявку на проверку(модерацию)?`,
        InTheListOfNNnotice: `В списке заявок напротив интересующей Вас заявки нажмите крайнюю левую кнопку и во всплывающем окне выберите «Отправить на модерацию».`,
        WhyIsNNnotice: `Для чего нужен архив заявок?`,
        TheNNnoticeArchive: `В архиве заявок хранятся заявки, которые Пользователь убрал из списка заявок, но не удалил их, сохранив возможность восстановления информации.`,
        HowToPutNNnotice: `Как переместить заявку в архив и как вернуть заявку из архива?`,
        IfYouNeedToPut: `Если Вам нужно переместить заявку в архив, в списке заявок напротив интересующей Вас заявки нажмите крайнюю левую кнопку и во всплывающем окне выберите «Архивировать» и подтвердите действие нажатием клавиши «Да».`,
        IfYouWantToReturn: `Если Вы хотите вернуть заявку из архива, в разделе архив наведением курсора выберите интересующую заявку, во всплывающем окне выберите «Восстановить» и подтвердите действие нажатием клавиши «Да».`,
        HowToFindNNnotice: `Как найти заявку по заданным мною критериям поиска?`,
        InTheSection: `В разделе «Заявки сети» в правой части расположена экранная форма «Поиски Заявки». Заполнив интересующие Вас параметры, Вы получите соответствующий выбранным Вами параметрам  список заявок.`,
        WhatIsICO: `Что такое ICO?`,
        ICOIsAForm: `ICO – это форма привлечения инвестиций в виде продажи инвесторам фиксированного количества новых единиц криптовалют (токенов).`,
        ICOIsACryptocurrency: `ICO - это криптовалютный краудандинг, поиск денег под идею или готовый проект, только в отличие от обычных денег здесь используется криптовалюта. Как правило, речь идет о новых сервисах и технологиях, находящихся в финальной стадии создания или об идеях с прототипом (прообразом конечной идеи). Взамен потраченным деньгам инвестор получает некоторые количество токенов, которые, в случае успеха предприятия, будут расти в цене.`,
        HowToCreateYourICO: `Как создать свое ICO в newnext?`,
        InOrderToCreateYourICO: `Для того, чтобы создать свое ICO, в разделе «Заявки» выберите «Создать новую заявку», затем «ICO».`,
        AfterFillingInTheProposed: `Заполнив предложенную форму, нажмите клавишу «Отправить».`,
        HowToContact: `Как связаться с Пользователем - автором заявки?`,
        TheUserAuthor: `В списке заявок newnext отражается автор заявки – Пользователь, разместивший заявку в newnext. Наведя курсор на автора заявки и нажав кнопку мыши,  Вы попадаете на страницу Пользователя(автора), где можете написать ему сообщение или добавить в контакты.`,
        ACCESSTOTHEPAGE: `ДОСТУП К СТРАНИЦЕ`,
        RestoreAccessPage: `Как восстановить доступ к странице?`,
        FirstIfYouNeedRestore: `1. Если Вам необходимо восстановить доступ к своей странице в случае утери пароля:`,
        IfYourPageCurrentPhone: `Если Ваша страница привязана к актуальному номеру телефона, пароль можно изменить, нажав клавишу «Забыли пароль» / «Восстановить пароль» на главной странице Сайта.`,
        IfYourPagePhoneNumberThat: `Если Ваша страница привязана к номеру телефона, который в данный момент недоступен, необходимо обратиться в службу Поддержки.`,
        SecondAccessToThePage: `2. Доступ к странице может быть заблокирован Администрацией Сайта за нарушение Правил пользования Сайтом.`,
        InThisCaseYouNeed: `В этом случае Вам необходимо обратиться в службу Поддержки, приложив к обращению следующие документы:`,
        ALinkToBlocked: `- ссылку на заблокированную страницу,`,
        APhotoOfYourIdentity: `- фотографию документа, удостоверяющего Вашу личность,`,
        APhotoThatShowsYourFace: ` - фотографию, на которой видно Ваше лицо на фоне Вашего обращения (рядом с монитором так, чтобы на нём было видно Ваше обращение).`,
        YouMustUnderstandThatAll2: `Вы должны понимать, что все запрашиваемые для обращения данные необходимы нам, чтобы быть уверенными, что к нам обращается истинный владелец этих фотографий.`,
        YouForgotMyUsername: `Вы забыли и логин и пароль. Что делать?`,
        InNewnextTheUsername: `В newnext в качестве логина выступает номер привязанного телефона (в международном формате).`,
        IfYourPageIsLinkedToTheCurrentPhone: `Если Ваша страница привязана к актуальному номеру телефона, пароль можно изменить, нажав клавишу «Забыли пароль» / «Восстановить пароль» на главной странице Сайта.`,
        IfYourPageIsLinkedToAPhoneNumber: `Если Ваша страница привязана к номеру телефона, который в данный момент недоступен, необходимо обратиться в службу Поддержки.`,
        WhatToDoIf: `Вашу страницу взломали?`,
        IfYourPageHasBeen: `Если Вашу страницу взломали, необходимо:`,
        FirstTryToRestoreAccess: {text: '1. Попытайтесь восстановить доступ к странице, перейдя по ссылке: ', link: {text: ' https://newnextglobal.com/recovery/.', url: '/recovery'}},
        SecondIfYouHaveProblems: `2. Если  возникают  трудности с восстановлением, напишите в службу Поддержки, обязательно указав ссылку на страницу.`,
        ATTENTIONToAvoid:  { attention: 'Важно!', text: 'Чтобы избежать взлома в будущем, необходимо привязать страницу к актуальному номеру мобильного телефона, а также регулярно проверять компьютер антивирусом.'},
        WhatToDoIfPageBlocked: `Вашу страницу заблокировали?`,
        TheNewnextUserPage: `Страница Пользователя newnext может быть заблокирована только Администрацией Сайта за нарушение Правил пользования Сайтом.`,
        ATTENTIONRestoring: { attention: 'Важно!', text: `Восстановление доступа к заблокированной странице бесплатное, поэтому если Вы столкнулись с тем, что Вам предлагают заплатить, сообщив данные платежной карты,  или отправить куда-то SMS — ни в коем случае не совершайте этих действий, так как наверняка  Вы имеете дело мошенниками.`},
        HowToDeletePage: `Как удалить страницу?`,
        TheUserPageCan: `Страница Пользователя может быть удалена только Администрацией Сайта.`,
        YouCanSubmitARequest: `Вы можете направить запрос на удаление страницы в службу Поддержки.`,
        ATTENTIONDeletingPage: { attention: 'Важно!', text: `Удаление страницы, к которой нет доступа, возможно, если на странице указаны настоящие имя и фамилия, а так же на аватаре присутствует Ваша фотография.`},
        InYourRequestToTheSupport: `В обращении в службу Поддержки необходимо приложить ссылку на страницу и два изображения:`,
        APhotoOfYourDocument2: `- Фотографию Вашего документа (паспорта, водительских прав, студенческого билета свидетельства о рождении, и т.п.).`,
        APhotoThatShowsYourFace2: `- Фотографию, на которой Ваше лицо находится на фоне Вашего обращения (т. е. рядом с монитором, на котором виден Ваш вопрос).`,
        OnlyInThisCaseWe2: `Только в этом случае мы можем убедиться, что запрос в Поддержку направлен именно Вами.`,
        WhatToDoCloseThePage: `Вы хотите закрыть страницу Пользователя, которого нет в живых?`,
        UnfortunatelyWeCannot: `К сожалению, мы не можем предоставить доступ к странице Пользователя, которого нет в живых.`,
        WeCanEither: `Мы можем либо удалить его страницу, либо заблокировать.`,
        YouNeedToContact: ` Вам необходимо обратиться в службу Поддержки с запросом, приложив скан или чёткую фотография свидетельства о его смерти, документа, подтверждающего родство или иного права распоряжаться страницей.`,
        YouCannotGoToTheSite: `Вы не можете зайти на сайт — требуется валидация страницы!`,
        IfYouCannot: `Если Вы не можете зайти в newnext, а Вам предлагается отправить SMS или перевести деньги на какой-то счёт — не делайте этого ни при каких обстоятельствах.`,
        NewnextDoesNot: `Newnext не требует никаких оплат и/или отправки сообщений с Вашего телефона для входа в newnext.`,
        IfThePageDoes: `Если страница  не загружается, попытайтесь это сделать с другого компьютера.`,
        IfAfterThatTheProblem: `Если после этого проблема останется, пожалуйста, напишите нам в службу Поддержки.`,
        WhatToDoTeRemoveThe: `Вы хотите убрать автосохранение пароля в браузере?`,
        YouNeedGoToSettings: `Вам понадобятся «Настройки» браузера, которым Вы пользуетесь.`,
        ForGoogleChrome: `Для Google Chrome:`,
        FirstInTheTopRight: `1. В правом верхнем углу нажмите на значок «три полоски».`,
        SecondSelectSettings: `2. Выберите «Настройки».`,
        ThirdClickShow: `3. Нажмите «Показать дополнительные настройки» в самом низу страницы и найдите раздел «Пароли и формы».`,
        FourthUncheckThe: `4. Уберите флажок с «Включить автозаполнение для внесения данных в веб-формы одним кликом».`,
        ForOpera: {text: 'Для Opera: ', link: {text: 'http://help.opera.com/opera/Windows/1284/ru/controlPa..', url: '/recovery'}},
        ForExplorer: {text: 'Для Internet Explorer: ', link: {text: 'http://windows.microsoft.com/ru-ru/internet-explorer/..', url: 'https://support.microsoft.com/ru-ru/help/17499/windows-internet-explorer-11-remember-passwords-fill-out-web-forms#ie=ie-11'}},
        ForMozilla: {text: `Для Mozilla: `, link: {text: `https://support.mozilla.org/ru/kb/upravlenie-avtomati..`, url: `https://support.mozilla.org/ru/kb/upravlenie-avtomaticheskim-zapolneniem-form-vashej`}},
        ChangeThePhone: `Вы хотите изменить номер телефона, привязанный к Вашей странице?`,
        YouCannotChangeThePhone2: `Вы не можете самостоятельно поменять телефон, привязанный к странице, так как телефон – Ваш логин для входа в newnext.`,
        IfThePhoneNumberProvided2: `Если номер телефона, указанный при регистрации, утратил актуальность, Вам следует обратиться в службу Поддержки.`,
        WhatToDoIfYouWant: `Вы хотите привязать номер телефона к своей странице, но он занят другим Пользователем?`,
        InThisCaseYouNeedToContact: `В этом случае Вам необходимо обратиться в Поддержку, указав свой номер телефона.`,
        InYourRequestToTheSupportService: `В обращении в службу Поддержки, пожалуйста, пришлите ссылку на профиль, к которому привязан Ваш номер телефона,   и изложите Вашу версию происшедшего, что ускорит решение вопроса.`,
        PRIVACYSETTINGS: `НАСТРОЙКИ ПРИВАТНОСТИ`,
        HowToAddBlackList: `Как добавить Пользователя в «черный список»?`,
        InOrderToPutUser: `Для того, чтобы поместить Пользователя в «черный список» зайдите на страницу интересующего Вас Пользователя и нажмите клавишу «…», расположенную напротив имени Пользователя. Далее  в выпадающем меню выберите «Добавить в черный список» и подтвердите свои действия нажатием клавиши «Да».`,
        HowToRemoveUser: `Как удалить Пользователя из «черного списка»?`,
        ToRemoveUser: `Для того, чтобы удалить Пользователя из «черного списка» нажатием  клавишей мыши на свое имя в верхнем правом углу страницы выберите раздел «Настройки».`,
        TheInTheSettings: `Затем в разделе «Настройки» выберите «Черный список». Напротив интересующего Вас Пользователя нажмите клавишу «Удалить» и подтвердите свои действия нажатием клавиши «Да».`,
        HowToLookThough: `Как посмотреть «черный список»?`,
        ToViewTheListUsers: `Для того, чтобы посмотреть список Пользователей, помещенных Вами в «черный список», нажатием  клавишей мыши на свое имя в верхнем правом углу страницы выберите раздел «Настройки».`,
        ThenChooseBlacklist: `Затем в разделе «Настройки» и выберите «Черный список».`,
        WHyIsThereSendMessage: `Почему на странице человека нет кнопки «Написать сообщение»?`,
        IfTheUserPagedoesnotSendMessage: `Если на странице Пользователя нет кнопки «Написать сообщение», это означает, что Пользователь ограничил круг своего общения и, по-видимому, поместил Вас в «черный список».`,
        CanUsersSeeMyPhoneNumber: `Видят ли Пользователи мой телефон (логин)?`,
        NoneOfTheNewnextUsers: `Ни у кого из Пользователей newnext, кроме Вас, нет доступа к Вашему логину и Вашему паролю.`,
        CanISeeWhoPut: `Можно ли посмотреть, кто поместил меня  в «черный список»?`,
        ThereIsNoPossibility: `Возможности посмотреть список Пользователей, поместивших Вас в «черный список», нет.`,
        HowToDeleteMyPage: `Как удалить свою страницу?`,
        TheUserPageCanBeDeleted: `Страница Пользователя может быть удалена только Администрацией Сайта.`,
        YouCanSubmitARequest2: `Вы можете направить запрос на удаление страницы в службу Поддержки.`,
        CanISeeWhoVisitsPage: `Можно ли посмотреть,  кто заходит на мою страницу?`,
        NoYouCannotSeeVisitedPage: `Нет. Вы не сможете посмотреть, кто заходил на Вашу страницу.`,




        from: 'от',
        sec: 'сек',
        PasswordContainThanCharacters: 'Пароль должен содержать не менее 6 символов, цифры (0-9), латинские символы верхнего (A-Z) и нижнего (a-z) регистра.',
        deleteApp: 'Удалить',
        restore: 'Восстановить',
        author: 'Автор',
        ddmmyy: 'дд-мм-гггг',
        dateTime: 'ru',
        rub: 'Рубль',
        usd: 'Доллар США',
        Yesterday: 'Вчера',
        Today: 'Сегодня',
        Hour: 'час',
        Day: 'день',
        Week: 'неделя',
        You: 'Вы',
        MonthYear: 'месяц/год',
        Months: 'месяца(ев)',
        Month: 'месяц',
        SimilarContacts: 'Похожие контакты',
        Message: 'Сообщение',
        Year: 'год',
        Years: 'год/лет',
        YearsOldOne: 'год',
        YearsOldTwo: 'года',
        YearsOldThree: 'лет',
        MonthOldOne: 'месяц',
        MonthOldTwo: 'месяца',
        MonthOldThree: 'месяцев',
        DescriptionInvestor: {one: 'Инвестор - это физическое или юридическое лицо, которое делает вложения собственных средств в инвестиционные проекты и продукты с целью получения прибыли.', two: 'При вложении денежных средств инвестор преследует цель получить прибыль со своих вложений и заработать на инвестициях.'},
        DescriptionCoinvestor: {one: 'Соинвестор - это физическое или юридическое лицо, которое делает вложения собственных средств совместно с другими лицами, инвестирующими средства в один и тот же инвестиционный проект.', two: 'Для соинвестора целью участия в инвестировании является получение материальной выгоды, прирост имущества, иными словами - "доходность".'},
        DescriptionNovator: {one: 'Изобретатель - физическое или юридическое лицо, которое придумывает и проводит в жизнь какие-то абсолютно новые прогрессивные идеи и приемы, в какой-либо области деятельности.', two: 'Изобретатель - физическое или юридическое лицо, занимающееся интеллектуальной деятельностью, результатом которой являются новые технологии, новое в производственных операциях, новые механизмы, сооружения и т.п.'},
        DescriptionBusinessSeller: {one: 'Предприниматель - физическое или юридическое лицо, предлагающее бизнес-продукты.', two: 'Бизнес-продукты не требуют вложений в развитие или проектирование, а являются продуктами или услугами, которые в готовом виде предлагаются на рынке и удовлетворяют потребностям покупателей.', three: 'К этой же категории участников относятся посредники и агенты.'},
        DescriptionProvider: {one: 'Поставщик занимается поставками товаров или услуг.', two: 'Поставщик размещает качественные характеристики поставляемого товара или услуги, а также параметры предполагаемых сделок, с целью поставки ', bold: 'товаров или услуг заказчикам.'},
        DescriptionBusinessLady: {one: 'Бизнес-леди - успешная женщина, которая создала бизнес идею и воплотила ее в жизнь, занимается бизнесом, созданием коммерческих структур, ориентирована на финансовый успех.'},
        DescriptionProjectant: {one: 'Проектант - физическое или юридическое лицо, разработавшее проект к внедрению или предлагающее услуги по разработке и внедрению проекта по заданию заказчика.'},
        DescriptionUnemployer: {one: 'Фрилансер - физические лица, обладающие опытом и квалификацией в какой-либо сфере деятельности и стремящиеся получить работу и заключить контракт с работодателем.'},
        DescriptionEmployer: {one: 'Работодатель - юридическое лицо (организация), имеющее намерением вступить в трудовые отношения с работником, обладающим требуемой квалификацией и опытом работы.'},
        DescriptionHRManager: {one: 'Кадровик - юридическое лицо (организация), имеющая взаимоотношения с работодателеми на предмет подбора персонала в соответствии с установленными требованиями и обладающего требуемой квалификацией и опытом работы.'},
        DescriptionExpert: {one: 'Эксперт - высококвалифицированный специалист в какой-либо области знания, привлекаемый для того, чтобы предоставить свое мнение, дать заключение по какому-либо вопросу, мнению, проекту.'},
        Country: 'Страна',
        YouMayInterested: 'Вас могут заинтересовать',
        Russian: 'Русский',
        English: 'Английский',
        Authorization: 'Авторизация',
        LogSocialNetworks: 'Войти через Соц. сети',
        EnterLastName: 'Введите Фамилию *',
        EnterTheCity: 'Введите Город *',
        EnterYourEmail: 'Введите E-mail',
        EnterThePassword: 'Введите пароль',
        RepeatPassword: 'Повторите Пароль *',
        AgreeWithDataProcessing: 'Согласен на обработку данных и т.п.',
        PasswordsDifferent: 'Введенные пароли различаются',
        WritePasswordSureWillRemember: 'Запишите пароль, если Вы не уверены, что запомните его',
        FirstNameEnteredIncorrectly: 'Имя введено не правильно',
        LastNameEnteredIncorrectly: 'Фамилия введено не правильно',
        NoProfileTypeSelected: 'Не выбран тип профиля',
        NoDialogs: 'Диалогов нет',
        SearchDialog: 'Поиск диалога',
        NoNews: 'Новостей нет',
        NoRecommends: 'Рекомендаций нет',
        Notice: 'УВЕДОМЛЕНИЕ',
        Warning: 'Предупреждение!',
        Cancel: 'Отменить',
        NewMessage: 'Новое сообщение',
        ShowAllResults: 'Показать все результаты',
        SingleUnmarried: 'Холост / Не замужем',
        Married: 'Женат / Замужем',
        MarriedSex:{ one: 'Женат', two: 'Замужем'},
        SingleUnmarriedSex: { one: 'Холост', two: 'Не замужем'},
        ChooseUserCategory: 'Выберите категорию пользователя',
        LegalEntity: 'Юридическое лицо',
        NaturalPerson: 'Физическое лицо',
        LegalEntityIndividual: 'Юридическое лицо/Физическое лицо',
        AtPresentTime: 'По настоящее время',
        YouHaveNotEnteredLastName: 'Вы не ввели фамилию',
        YouHaveNotEnteredFirstName: 'Вы не ввели имя',
        YouChosenGender: 'Вы не выбрали пол',
        YouSpecifiedDateBirth: 'Вы не указали дату рождения',
        YouUnderYearsAge: 'Ваш возраст не достиг 18-ти лет',
        YouSpecifiedCountryRegion: 'Вы не указали страну/регион',
        YouSpecifiedACity: 'Вы не указали город',
        YouSpecifiedEmail: 'Вы не указали email',
        ChooseYourGenders: 'Выберите пол',
        Male: 'Мужской',
        Female: 'Женский',
        YouHaveNotSpecifiedPhone: 'Вы не указали телефон',
        CompanyNamed: 'Название компании',
        ViewDetails: 'Смотреть подробнее',
        Hide: 'Скрыть',
        // MoreDetails: 'подробно',
        SpecifyDetails: 'Укажите подробнее',
        ChooseARegion: 'Выберите регион',
        ChooseACity: 'Выберите город',
        NoSimilarContactsFound: 'Не найдено похожих контактов',
        Contact: 'Контакт',
        AnErrorRecoveringPassword: 'Произошла ошибка при восстановлении пароля',
        ChooseMemberType: 'Выберите тип участника',
        login: 'Логин',
        Password: 'Пароль',
        EnterButton: 'Войти',
        ChooseCountry: 'Выберите страну',
        ChooseIndustry: 'Выберите отрасль',
        MyContacts: 'Мои контакты',
        NewsAboutContacts: 'Новости контактов',
        FriendshipApproved: 'Дружба одобрена',
        AddingContactApproved: 'Добавление контакта одобрено',
        MinimizeButton: 'Свернуть',
        ContactSearch: 'Поиск контактов',
        ChooseCurrency: 'Выберите валюту',
        ChooseInvestmentIndustry: 'Выберите отрасль инвестирования',
        YouHaveAmountExpectedInvestment: 'Вы не указали объем предполагаемых инвестиций',
        YouSpecifiedInvestmentIndustry: 'Вы не указали отрасль инвестирования',
        YouSpecifiedInvestmentRegion: 'Вы не указали регион инвестирования',
        ChooseTypePayment: 'Выберите вид оплаты',
        YouSpecifiedPosition: 'Вы не указали должность',
        YouSpecifiedSalaryWage: 'Вы не указали оклад',
        YouSpecifiedDescription: 'Вы не указали описание',
        YouSpecifiedName: 'Вы не указали наименование',
        YouSpecifiedIndustry: 'Вы не указали отрасль',
        YouIndicatedAmountExpectedInvestment: 'Вы не указали объем предполагаемых соинвестиций',
        YouSpecifiedCoinvestmentIndustry: 'Вы не указали отрасль соинвестирования',
        YouSpecifiedCoinvestmentRegion: 'Вы не указали регион соинвестирования',
        ChooseCoinvestmentIndustry: 'Выберите отрасль соинвестирования',
        PeriodCoinvestment: 'Срок соинвестирования',
        YouNnotSpecifiedIndustry: 'Вы не указали отрасль внедрения',
        ChooseImplementationIndustry: 'Выберите отрасль внедрения',
        ChooseOneOptions: 'Выберите один из вариантов',
        TheChoiceVacancyResume: 'Выбор Вакансия или Резюме ',
        YouHaveNotSpecifiedYourName: 'Вы не указали ФИО',
        YouSpecifiedIntendedPlaceWork: 'Вы не указали предполагаемое место работы',
        AdditionalItems: 'Дополнительные пункты',
        YouSpecifiedCompanyName: 'Вы не указали наименование предприятия',
        YouSpecifiedWorkExperienceApplicant: 'Вы не указали стаж работы соискателя',
        YouSpecifiedFormPayment: 'Вы не указали вид оплаты',
        YouSpecifiedDeliveryType: 'Вы не указали тип поставки *',
        ChooseTypeDelivery: 'Выберите тип поставки',
        DeliveryType: 'Тип поставки',
        UnitMeasurement: 'Единица измерения',
        YouSpecifiedTypeService: 'Вы не указали вид услуг',
        YouNotSpecifiedFieldWork: 'Вы не указали сферу деятельности',
        FieldWork: 'Сфера деятельности',
        // Experience: 'Опыт',
        YouHaveSpecifiedIncome: 'Вы не указали доход',
        YouHaveNotSpecifiedRegion: 'Вы не указали регион',
        YouHaveSpecifiedAcompanyName: 'Вы не указали название компании',
        YouHaveNotSpecifiedAcompanyType: 'Вы не указали тип компании',
        YouSpecifiedSoftCap: 'Вы не указали Soft Cap',
        YouSpecifiedHardCap: 'Вы не указали Hard Cap',
        YouSpecifiedStartTime: 'Вы не указали Start Time',
        YouSpecifiedEndTime: 'Вы не указали End Time',
        YouSpecifiedShortDescription: 'Вы не указали короткое описание',
        YouSpecifiedFullDescription: 'Вы не указали полное описание',
        UploadImage: 'Загрузите изображение',
        LargeFileSize: 'большой размер файла',
        UploadImageBtn: 'Загрузить изображение',
        CompanyName: 'Название кампании',
        TypeApplications: 'Тип заявок',
        TypeApplication: 'Тип заявки',
        ChangeTypeApplication: 'Выберите тип заявки',
        SectorAffiliation: 'Отраслевая принадлежность',
        MoreDetails: 'Подробнее',
        WaitingStart: 'Ожидает запуска',
        InProcess: 'Запущенно',
        Finished: 'Окончено',
        Reset: 'Сбросить',
        YourApplicationHasSentModeration: {one: 'Отправлено успешно!', two: ' Ваша заявка отправлена на модерацию.'},
        AddContact: 'Добавить контакт',
        btnAdd: 'Добавить',
        btnAddMore: 'Добавить еще',
        Reject: 'Отклонить',
        Rejected: 'Отклонено',
        YouHaveNoContacts: 'У вас нет контактов',
        YouHaveNoRequests: 'У вас нет запросов',
        description: {text: 'Глобальная бизнес-сеть newnext создана для тех, кому необходимо', one: 'Установить новые бизнес-связи;', two: 'Найти интересные бизнес-проекты;', three: 'Предложить собственные бизнес-продукты;', four: 'Cвязаться с продавцами и поставщиками.'},
        registration: 'Регистрация',
        deleteAccount: 'Запрос на удаление страницы',
        repareAccount: 'Отменить запрос на удаление страницы',
        deleteAccountLabel: 'Удаление профиля',
        urlProfileLabel: 'URL адрес профиля',
        DeleteAccountModal: 'Вы действительно хотите удалить свой аккаунт?',
        RestoreAccountModal: 'Восстановить аккаунт?',
        userCategory: 'Тип участника',
        userCategoryss: 'Кто вы?',
        investor: 'Инвестор',
        coinvestor: 'Соинвестор',
        novator: 'Изобретатель',
        innovator: 'Изобретатель',
        sellerBusiness: 'Предприниматель',
        seller_business_products: 'Предприниматель',
        supplier: 'Поставщик',
        provider: 'Поставщик',
        businessWoman: 'Бизнес-леди',
        business_lady: 'Бизнес-леди',
        designer: 'Проектант',
        unemployed: 'Фрилансер',
        employer: 'Работодатель',
        hrManager: 'Кадровик',
        expert: 'Эксперт',
        admin: 'Администратор',
        firstName: 'Имя',
        lastName: 'Фамилия',
        phone: 'Телефон',
        clickRegister: {text_1: 'Нажимая «Зарегистрироваться», Вы принимаете ', link_1: 'условия Пользовательского соглашения', link_2: 'Политики конфиденциальности', text_2: ' и ', link_3: 'Политики использования файлов cookie', text_3: ' newnext.'},
        register: 'Зарегистрироваться',
        aboutProject: 'О проекте',
        rules: 'Правила',
        advertisement: 'Реклама',
        technicalSupport: 'Техподдержка 24/7',
        fillField: 'Заполните поле',
        errorPhone: 'Телефон введен неправильно',
        forgotPassword: { one: 'Забыли пароль?', two: 'Его можно быстро', three: 'восстановить'},
        language: 'Язык',
        fullNumber: 'Введите полный номер',
        phoneConfirmation: {one: 'Подтверждение', two: 'телефона'},
        fullName: 'Введите имя полностью *',
        codeSMS: 'Введите полученный код из SMS-сообщения.',
        codeFromSMS: 'Код из SMS',
        yourPhone: 'Ваш телефон',
        codePhone: 'Если код не пришел в течение 2 минут, проверьте правильность введенного номера телефона.',
        recoverPassword: {one: "Не получилось восстановить пароль? ", two: 'Свяжитесь с нами '},
        resendCode: 'Отправить код повторно',
        codeIncorrect: 'Код указан неверно',
        wrongLogin: 'Неверно введен логин и пароль',
        createPassword: 'Создание пароля ',
        yourLogin: 'Ваш логин',
        newPassword: 'Новый пароль ',
        confirmPassword: 'Подтверждение пароля',
        save: 'Сохранить',
        createPasswordLong: 'Придумайте и введите пароль длиной не менее 6 символов. ',
        securePassword: 'В безопасном пароле должны присутствовать буквы, цифры и символы.',
        errorRegistration: 'Произошла ошибка при регистрации',
        forgotPasswordSmall: 'Забыли пароль?',
        sendTheCode: 'Выслать код',
        indicatePhone: 'Пожалуйста, укажите телефон, который Вы использовали для входа на сайт.',
        sendFreeSMS: 'Мы пришлём бесплатное SMS-сообщение с кодом подтверждения.',
        passwordRecovery: 'Восстановление пароля',
        applicationLegalEntity: 'Анкета для юридического лица',
        naming: 'Наименование',
        industry: 'Отрасль',
        periodWork: 'Срок работы на рынке',
        countryResidence: 'Страна проживания *',
        cityResidence: 'Город',
        regionResidence: 'Регион',
        loading: 'Загрузка',
        website: 'Сайт ',
        education: 'Образование',
        educationalInstitution: 'Учебное заведение',
        yearStart: 'Год начала',
        yearGraduation: 'Год окончания',
        youCantSendMessage: 'Вы не можете отправить сообщение пользователю, потому что он добавил Вас в черный список, либо Вы добавили его в свой черный список.',
        youCantSendEmptyMessage: 'Вы не можете отправить пустое сообщение пользователю',
        youNeedHaveCareers: 'Вам необходимо заполнить данные о карьере',
        youNeedHaveEdu: 'Вам необходимо заполнить данные об образовании',
        academicSpeciality: 'Специальность',
        career: 'Карьера',
        placeWork: 'Место работы',
        expWork: 'Опыт работы',
        workExperience: 'Трудовой стаж (лет/год) ',
        additionalInformation: 'Дополнительно',
        maritalStatus: 'Семейное положение',
        selectFamilyStatus: 'Выберите семейное положение',
        hobby: 'Хобби',
        chooseHobby: 'Выберите хобби',
        additionalInformationBlock: 'Дополнительная информация',
        uploadedPhoto: 'Загрузить фотографию',
        notMoreMB: 'Не более 1 МБ',
        profileUpdated: 'Ваш профиль успешно обновлен',
        questionnaireIndividual: 'Анкета для физического лица',
        patronymicName: 'Отчество',
        sex: 'Пол',
        dateBirth: 'Дата рождения',
        recommend: 'Рекомендуют',
        request: 'Предложения',
        chatRequest: 'чат по заявке',
        noEntries: 'Заявки отсутствуют',
        networkRating: 'Рейтинг в сети ',
        aboutMe: 'Обо мне ',
        recommends: 'Рекомендуем',
        search: 'Поиск ',
        scrollUp: 'Свернуть',
        extended: 'Расширенный ',
        selecteType: 'Выберите тип',
        selectList: 'Выберите из списка',
        countryCity: 'Страна, город',
        age: 'Возраст',
        find: 'Найти',
        nothingFound: 'Список пуст',
        addingContact: 'Добавление контакта одобрено',
        newApplication: 'Новое предложение   ',
        allContacts: 'Все контакты ',
        writeMessage: 'Написать сообщение',
        removeContacts: 'Удалить из контактов',
        remove: 'Удалить',
        removeDialog: 'Удалить диалог',
        messageRequest: 'Сообщение по предложению',
        myProfile: 'Мой профиль',
        editProfile: 'Редактировать профиль',
        exit: 'Выход',
        information: 'Сообщения',
        news: 'Новости',
        contacts: 'Контакты',
        requests: 'Запросы',
        myDialogues: 'Мои диалоги',
        writeMessageS: 'Напишите сообщение',
        deleteMessage: 'Вы хотите удалить сообщение',
        messageSuccessfullyDeleted: 'Сообщение успешно удалено',
        send: 'Отправить',
        cancel: 'Отмена',
        searchFullName: 'Поиск по ФИО',
        confirmAction: 'Подтвердите действие',
        removeContact: {one: 'Вы хотите удалить', two: 'из контактов?'},
        yes: 'Да ',
        no: 'Нет',
        removedContacts: 'успешно удален/а из контактов',
        addedContacts: 'успешно добавлен/а в контакты ',
        createNewRequest: 'Создать новое предложение ',
        closeNewRequest: 'Закрыть окно заявки',
        myApplication: 'Мои предложения',
        networkRequests: 'Предложения сети',
        archive: 'Архив ',
        coverCard: 'Обложка карточки кампании ( минимальная ширина 810 px)',
        campaignType: 'Тип кампании',
        shortDescription: 'Короткое описание ( до 250 символов )',
        fullDescription: 'Полное описание',
        applicationSuccessfullySent: 'Ваше предложение успешно отправлено',
        unpublished: 'Не опубликовано',
        sendModeration: 'Отправить на модерацию',
        edit: 'Редактировать ',
        archiveDo: 'Архивировать',
        deleteApplication: 'Вы хотите удалить предложение от ',
        archiveApplication: 'Вы хотите заархивировать предложение от ',
        requestModeration: 'Вы хотите отправить предложение на модерацию ?',
        applicationChanged: 'Ваше предложение успешно изменено',
        deleteRequestArchive: 'Вы хотите восстановить или удалить из архива заявку от ',
        recover: 'Восстановить',
        inModeration: 'На модерации',
        applicationSuccessfullyDeleted: 'Ваша заявка успешно удалена',
        applicationSearch: 'Поиск предложения',
        supplierApplication: 'Предложение поставщика',
        expertApplication: 'Предложение эксперта',
        projectApplication: 'Предложение проектанта',
        summary: 'Резюме',
        Vacancy: 'Вакансия',
        YouDoNotHaveArchivedRequests: 'У вас нет архивированных заявок.',
        applicationHR: 'Предложение кадровика',
        applicationEmployer: 'Предложение работодателя',
        ApplicationUnemployed: 'Предложение фрилансера',
        BusinesswomanApplication: 'Предложение бизнес-леди',
        ApplicationBusinessProduct: 'Предложение предпринимателя',
        ApplicationInnovation: 'Предложение о новации/изобретении',
        ApplicationCoinvestment: 'Предложение на соинвестирование',
        ApplicationInvestment: 'Предложение на инвестирование',
        VolumeInvestments: 'Объем предполагаемых инвестиций',
        InvestmentIndustry: 'Отрасль инвестирования',
        InvestmentRegion: 'Регион инвестирования',
        InvestmentTerm: 'Срок инвестирования',
        PaybackTime: 'Срок окупаемости',
        ExpectedReturn: 'Ожидаемая доходность',
        ExperienceInvestment: 'Опыт на рынке инвестирования',
        FirstTime: 'Впервые',
        participationProjects: 'Есть участие в проектах',
        volumeCoinvestments: 'Объем предполагаемых соинвестиций',
        CoinvestmentIndustry: 'Отрасль соинвестирования',
        CoinvestmentRegion: 'Регион соинвестирования',
        EquityParticipation: 'Долевое участие',
        IndustryIntroduction: 'Отрасль внедрения',
        Income: 'Доход',
        patent: '№ патента. Страна, выдавшая патент',
        Cost: 'Стоимость',
        Introduced: 'Внедрено',
        Description: 'Описание',
        ReturnsInvestment: 'Доходность',
        BusinessOwner: 'Собственник бизнеса',
        Experience: 'Стаж работы',
        Salary: 'Оклад',
        PaymentMethod: 'Вид оплаты',
        WorkplaceGeography: 'География рабочего места',
        NumberEmployees: 'Количество сотрудников в подчинении',
        NameCompany: 'Наименование предприятия',
        Position: 'Должность',
        WorkExperienceApplicant: 'Стаж работы соискателя',
        SpecialRequirements: 'Особые требования',
        LabourActivity: 'Трудовая деятельность',
        IntendedPlaceWork: 'Предполагаемое место работы',
        Car: 'Автомобиль',
        Rights: 'Водительские права',
        CriminalRecord: 'Судимость',
        LevelImplementation: 'Уровень готовности к внедрению',
        TypeService: 'Вид услуг',
        Manufacturer: 'Производитель',
        Intermediary: 'Посредник',
        CostDelivery: 'Стоимость поставки',
        DeliveryTime: 'Срок поставки',
        MaximumScopeSupply: 'Максимальный объем поставки',
        SelectImage: 'Выберите область изображения',
        errorr: 'Ошибка!',
        onlineStatus: 'В сети',
        sharedInPraticipation: 'Доля участия в %',
        AutomotiveBusiness: {
            _1: 'Автозапчасти, шины (розничная торговля)',
            _2: 'Автокомпоненты, запчасти (производство)',
            _3: 'Автокомпоненты, запчасти, шины (продвижение, оптовая торговля)',
            _4: 'Легковые, грузовые автомобили, мототехника, автобусы, троллейбусы (продвижение, оптовая торговля)',
            _5: 'Легковые, грузовые автомобили, мототехника, автобусы, троллейбусы (производство)',
            _6: 'Розничная торговля автомобилями (дилерский центр)',
            _7: 'Техническое обслуживание, ремонт автомобилей',
            _8: 'Финансовые услуги (кэптивные банки и лизинговые компании)',
        },
        Hotels: {
            _1: 'Гостиница',
            _2: 'Кейтеринг (выездное обслуживание)',
            _3: 'Ресторан, общественное питание, фаст-фуд',
        },
        Mining: {
            _1: 'Добыча и обогащение минерального сырья (соль, сера, глинозем), разработка карьеров (песок, глина, камень) добыча торфа',
            _2: 'Добыча и обогащение руд черных, цветных, драгоценных, благородных, редких металлов',
            _3: 'Добыча и обогащение угля',
            _4: 'Добыча драгоценных металлов и камней',
            _5: 'Инженерно-изыскательские, гидрогеологические, геологоразведочные работы',
        },
        Utilities: {
            _1: 'Благоустройство и уборка территорий и зданий',
            _2: 'Вентиляция и кондиционирование (монтаж, сервис, ремонт)',
            _3: 'Водоснабжение и канализация',
            _4: 'Лифтовое хозяйство (монтаж, сервис, ремонт)',
            _5: 'Обеспечение пожарной безопасности, молниезащиты',
            _6: 'Ремонт зданий и сооружений',
            _7: 'Слаботочные сети (монтаж, сервис, ремонт)',
            _8: 'Утилизация бытовых отходов',
            _9: 'Энергоснабжение',
        },
        ItSystem: {
            _1: 'Интернет-компания (поисковики, платежные системы, соц.сети, информационно-познавательные и развлекательные ресурсы, продвижение сайтов и прочее)',
            _2: 'Интернет-провайдер',
            _3: 'Разработка программного обеспечения',
            _4: 'Системная интеграция, автоматизации технологических и бизнес-процессов предприятия, ИТ-консалтинг',
        },
        Art: {
            _1: 'Архив, библиотека, искусствоведение',
            _2: 'Ботанический сад, зоопарк, заповедник',
            _3: 'Кинопроизводство',
            _4: 'Кинотеатры',
            _5: 'Музей, галерея, театр',
        },
        TimberIndustry: {
            _1: 'Деревообработка (производство)',
            _2: 'Продукция деревообработки (продвижение, оптовая торговля)',
            _3: 'Столярно-строительные изделия (продвижение, оптовая торговля)',
            _4: 'Столярно-строительные изделия (производство)',
            _5: 'Целлюлозно-бумажная продукция (продвижение, оптовая торговля)',
            _6: 'Целлюлозно-бумажное производство',
        },
        Medicine: {
            _1: 'Аптека, оптика',
            _2: 'Ветеринарная аптека',
            _3: 'Ветеринарная практика',
            _4: 'Клинические исследования',
            _5: 'Лаборатория, исследовательский центр',
            _6: 'Лечебно-профилактические учреждения',
            _7: 'Учреждение соц.помощи и защиты',
            _8: 'Фармацевтическая продукция (продвижение, оптовая торговля)',
            _9: 'Фармацевтическая продукция (производство)',
        },
        Metallurgy: {
            _1: 'Драгоценные, благородные и редкие металлы (производство)',
            _2: 'Драгоценные, благородные и редкие металлы (продвижение, оптовая торговля)',
            _3: 'Металлические изделия, металлоконструкции (продвижение, оптовая торговля)',
            _4: 'Металлические изделия, металлоконструкции (производство)',
            _5: 'Продукция цветной металлургии (продвижение, оптовая торговля)',
            _6: 'Продукция черной металлургии (продвижение, оптовая торговля)',
            _7: 'Цветная металлургия (выплавка, металлопрокат)',
            _8: 'Черная металлургия (производство чугуна, стали, проката)'
        },
        OilGas: {
            _1: 'ГСМ, топливо (продвижение, оптовая торговля)',
            _2: 'ГСМ, топливо (розничная торговля)',
            _3: 'Добыча газа',
            _4: 'Добыча нефти',
            _5: 'Нефтепереработка, нефтехимия (производство)',
            _6: 'Переработка газа',
            _7: 'Нефтехимия (продвижение, оптовая торговля)',
            _8: 'Транспортировка, хранение газа',
            _9: 'Транспортировка, хранение нефти',
        },
        EducationalInstitutions: {
            _1: 'Автошкола',
            _2: 'Бизнес-образование',
            _3: 'Вуз, ссуз колледж, ПТУ',
            _4: 'Интернат, детский дом',
            _5: 'Научно-исследовательская, научная, академическая деятельность',
            _6: 'Обучение иностранным языкам',
            _7: 'Обучение искусствам (рисование, пение, танцы, фото и т. п.)',
            _8: 'Повышение квалификации, переквалификация',
            _9: 'Спортивное обучение',
            _10: 'Тренинговые компании',
            _11: 'Школа, детский сад',
        },
        Volunteering: {
            _1: 'Ассоциация в сфере культуры, искусства',
            _2: 'Благотворительная организация',
            _3: 'Общественная, политическая организация',
            _4: 'Профессиональная, предпринимательская организация',
            _5: 'Религиозная организация',
            _6: 'Спортивная федерация',
            _7: 'Фонд, грантодатель',
        },
        Transportation: {
            _1: 'Авиаперевозки',
            _2: 'Автомобильные перевозки',
            _3: 'ВЭД, таможенное оформление',
            _4: 'Железнодорожные перевозки',
            _5: 'Курьерская, почтовая доставка',
            _6: 'Морские, речные перевозки',
            _7: 'Складские услуги',
            _8: 'Транспортно-логистические комплексы, порты (воздушный, водный, железнодорожный)',
        },
        FoodProducts: {
            _1: 'Алкогольные напитки (продвижение, оптовая торговля)',
            _2: 'Безалкогольные напитки (продвижение, оптовая торговля)',
            _3: 'Безалкогольные напитки (производство)',
            _4: 'Вино (производство)',
            _5: 'Детское питание (продвижение, оптовая торговля)',
            _6: 'Детское питание (производство)',
            _7: 'Кондитерские изделия (продвижение, оптовая торговля)',
            _8: 'Кондитерские изделия (производство)',
            _9: 'Консервированная продукция (продвижение, оптовая торговля)',
            _10: 'Консервированная продукция (производство)',
            _11: 'Корма для животных (продвижение, оптовая торговля)',
            _12: 'Корма для животных (производство)',
            _13: 'Ликероводочная продукция (производство)',
            _14: 'Макаронные изделия (продвижение, оптовая торговля)',
            _15: 'Макаронные изделия (производство)',
            _16: 'Масложировая продукция (продвижение, оптовая торговля)',
            _17: 'Масложировая продукция (производство)',
            _18: 'Молочная продукция (продвижение, оптовая торговля)',
            _19: 'Молочная продукция (производство)',
            _20: 'Мука, крупы (продвижение, оптовая торговля)',
            _21: 'Мука, крупы (производство)',
            _22: 'Мясная продукция (продвижение, оптовая торговля)',
            _23: 'Мясная продукция (производство)',
            _24: 'Пиво (производство)',
            _25: 'Пищевые концентраты (продвижение, оптовая торговля)',
            _26: 'Пищевые концентраты (производство)',
            _27: 'Полуфабрикаты, в том числе замороженные (продвижение, оптовая торговля)',
            _28: 'Полуфабрикаты, в том числе замороженные (производство)',
            _29: 'Рыба и морепродукты (продвижение, оптовая торговля)',
            _30: 'Рыба и морепродукты (производство)',
            _31: 'Сахар (продвижение, оптовая торговля)',
            _32: 'Сахар (производство)',
            _33: 'Свежие овощи, фрукты (продвижение, оптовая торговля)',
            _34: 'Снеки (продвижение, оптовая торговля)',
            _35: 'Снеки (производство)',
            _36: 'Хлебобулочные изделия (продвижение, оптовая торговля)',
            _37: 'Хлебобулочные изделия (производство)',
            _38: 'Чай, кофе (продвижение, оптовая торговля)',
            _39: 'Чай, кофе (производство)',
        },
        IndustrialEquipment: {
            _1: 'Дорожно-строительная техника, сельскохозяйственная и другая спец.техника, оборудование, лифты, погрузочно-разгрузочное, складское оборудование (монтаж, сервис, ремонт)',
            _2: 'Дорожно-строительная техника, сельскохозяйственная и другая спец.техника, оборудование, лифты, погрузочно-разгрузочное, складское оборудование (продвижение, оптовая торговля)',
            _3: 'Дорожно-строительная техника, сельскохозяйственная и другая спец.техника, оборудование, лифты, погрузочно-разгрузочное, складское оборудование (производство)',
            _4: 'Медицинское, диагностическое оборудование, инструменты (монтаж, сервис, ремонт)',
            _5: 'Медицинское, диагностическое оборудование, инструменты (продвижение, оптовая торговля)',
            _6: 'Медицинское, диагностическое оборудование, инструменты (производство)',
            _7: 'Оборудование для деревообработки, заготовки леса, целлюлозно-бумажной промышленности (монтаж, сервис, ремонт)',
            _8: 'Оборудование для деревообработки, заготовки леса, целлюлозно-бумажной промышленности (продвижение, оптовая торговля)',
            _9: 'Оборудование для деревообработки, заготовки леса, целлюлозно-бумажной промышленности (производство)',
            _10: 'Оборудование для пищевой промышленности, упаковки и хранения (монтаж, сервис, ремонт)',
            _11: 'Оборудование для пищевой промышленности, упаковки и хранения (продвижение, оптовая торговля)',
            _12: 'Оборудование для пищевой промышленности, упаковки и хранения (производство)',
            _13: 'Оборудование для ресторанов, гостиниц, кейтеринга, розничной торговли (монтаж, сервис, ремонт)',
            _14: 'Оборудование для ресторанов, гостиниц, кейтеринга, розничной торговли (продвижение, оптовая торговля)',
            _15: 'Оборудование для ресторанов, гостиниц, кейтеринга, розничной торговли (производство)',
            _16: 'Оборудование и станки для добывающей, энергетической, нефтегазовой и химической отрасли (монтаж, сервис, ремонт)',
            _17: 'Оборудование и станки для добывающей, энергетической, нефтегазовой и химической отрасли (продвижение, оптовая торговля)',
            _18: 'Оборудование и станки для добывающей, энергетической, нефтегазовой и химической отрасли (производство)',
            _19: 'Оборудование и станки для металлургии и металлообработки (монтаж, сервис, ремонт)',
            _20: 'Оборудование и станки для металлургии и металлообработки (продвижение, оптовая торговля)',
            _21: 'Оборудование и станки для металлургии и металлообработки (производство)',
            _22: 'Оборудование и станки для отраслей легкой промышленности (продвижение, оптовая торговля)',
            _23: 'Оборудование и станки для отраслей легкой промышленности (производство)',
            _24: 'Оборудование и станки для отраслей легкой промышленности (монтаж, сервис, ремонт)',
        },
        Retail: {
            _1: 'Вендинг (торговля через автоматы)',
            _2: 'Зоомагазин',
            _3: 'Интернет-магазин',
            _4: 'Несетевая розница, мелкий опт',
            _5: 'Розничная сеть (DIY и товары для дома)',
            _6: 'Розничная сеть (drogerie, товары повседневного спроса)',
            _7: 'Розничная сеть (книги, игрушки, подарки, часы и прочее)',
            _8: 'Розничная сеть (мебель)',
            _9: 'Розничная сеть (мобильный ретейл)',
            _10: 'Розничная сеть (одежда, обувь, аксессуары)',
            _11: 'Розничная сеть (парфюмерия, косметика)',
            _12: 'Розничная сеть (продуктовая)',
            _13: 'Розничная сеть (спортивные товары)',
            _14: 'Розничная сеть (товары для детей)',
            _15: 'Розничная сеть (электроника, бытовая техника)',
            _16: 'Розничная сеть (ювелирные изделия)',
        },
        Media: {
            _1: 'Издательская деятельность',
            _2: 'Киностудии и студии звукозаписи',
            _3: 'Маркетинговые, рекламные, BTL, дизайнерские, Event-, PR-агентства, организация выставок',
            _4: 'Продюсерский центр',
            _5: 'Производство мультимедиа, контента, редакторская деятельность',
            _6: 'Производство печатной, полиграфической продукции',
            _7: 'Распространение мультимедиа и печатной продукции',
            _8: 'Распространение телепрограмм, кино (кабельное телевидение)',
            _9: 'Теле- и радиовещание',
        },
        Agriculture: {
            _1: 'Земледелие, растениеводство, животноводство',
            _2: 'Рыболовство, рыбоводство',
            _3: 'Сельскохозяйственная продукция (продвижение, оптовая торговля)',
        },
        Construction: {
            _1: 'Агентские услуги в недвижимости',
            _2: 'Архитектура, проектирование',
            _3: 'Девелопмент',
            _4: 'Консалтинг, оценка в недвижимости',
            _5: 'Строительство дорожное и инфраструктурное',
            _6: 'Строительство жилищное',
            _7: 'Строительство коммерческих объектов (торговые площади, офисные здания)',
            _8: 'Строительство объектов металлургической отрасли',
            _9: 'Строительство объектов нефтегазовой отрасли',
            _10: 'Строительство объектов телекоммуникационной отрасли',
            _11: 'Строительство промышленное',
            _12: 'Строительство энергетическое',
            _13: 'Управление и эксплуатация недвижимости',
        },
        Telecommunications: {
            _1: 'Мобильная связь',
            _2: 'Оптоволоконная связь',
            _3: 'Спутниковая связь',
            _4: 'Фиксированная связь',
        },
        FMCG: {
            _1: 'Бытовая химия, парфюмерия, косметика (продвижение, оптовая торговля)',
            _2: 'Бытовая химия, парфюмерия, косметика (производство)',
            _3: 'Канцтовары, расходные материалы (продвижение, оптовая торговля)',
            _4: 'Канцтовары, расходные материалы (производство)',
            _5: 'Мебель (продвижение, оптовая торговля)',
            _6: 'Мебель (производство)',
            _7: 'Одежда, обувь, аксессуары, текстиль (продвижение, оптовая торговля)',
            _8: 'Одежда, обувь, аксессуары, текстиль (производство)',
            _9: 'Посуда, свет, товары для дома (продвижение, оптовая торговля)',
            _10: 'Посуда, свет, товары для дома (производство)',
            _11: 'Сантехника (продвижение, оптовая торговля)',
            _12: 'Сантехника (производство)',
            _13: 'Спортивные товары и оборудование (продвижение, оптовая торговля)',
            _14: 'Спортивные товары и оборудование (производство)',
            _15: 'Стройматериалы, сборные конструкции для ремонта и строительства (продвижение, оптовая торговля)',
            _16: 'Стройматериалы, сборные конструкции для ремонта и строительства (производство, монтаж, сервис, ремонт)',
            _17: 'Табак (продвижение, оптовая торговля)',
            _18: 'Табак (производство)',
            _19: 'Товары для детей (продвижение, оптовая торговля)',
            _20: 'Товары для детей (производство)',
            _21: 'Ювелирные изделия, бижутерия (продвижение, оптовая торговля)',
            _22: 'Ювелирные изделия, бижутерия (производство)',
        },
        HeavyEngineering: {
            _1: 'Авиационная, вертолетная, аэрокосмическая промышленность',
            _2: 'Двигатели (монтаж, сервис, ремонт)',
            _3: 'Двигатели (продвижение, оптовая торговля)',
            _4: 'Двигатели (производство)',
            _5: 'Железнодорожное машиностроение, вагоностроение',
            _6: 'Продукция авиационной, аэрокосмической, вертолетной промышленности (монтаж, сервис, ремонт)',
            _7: 'Продукция авиационной, аэрокосмической, вертолетной промышленности (продвижение, оптовая торговля)',
            _8: 'Продукция железнодорожного машиностроения, вагоностроения (монтаж, сервис, ремонт)',
            _9: 'Продукция железнодорожного машиностроения, вагоностроения (продвижение, оптовая торговля)',
            _10: 'Продукция судостроения (продвижение, оптовая торговля)',
            _11: 'Производство и ремонт военной техники, оборудования и вооружения',
            _12: 'Судоремонт',
            _13: 'Судостроение',
        },
        Multiprofile: {
            _1: 'Управляющая компания группы, холдинга, штаб-квартира'
        },
        BusinessServices: {
            _1: 'Безопасность, охранная деятельность',
            _2: 'Кадровые агентства',
            _3: 'Колл-центры, диспетчерские службы',
            _4: 'Консалтинговые услуги',
            _5: 'Контроль качества, экспертиза, испытания и сертификация',
            _6: 'Переводческая деятельность',
            _7: 'Услуги по организации поездок и бронированию',
            _8: 'Экологическое обслуживание, водоочистка, утилизация отходов',
            _9: 'Юридическое обслуживание',
        },
        PublicServices: {
            _1: 'Аренда автомобилей',
            _2: 'Домашний персонал',
            _3: 'Игорный бизнес',
            _4: 'Пошив, ремонт одежды и обуви',
            _5: 'Ритуальные услуги',
            _6: 'Салоны красоты',
            _7: 'Спортивные и фитнес-клубы',
            _8: 'Такси',
            _9: 'Туристические компании',
            _10: 'Фото- и видеоуслуги',
            _11: 'Химчистка',
            _12: 'Центры развлечения',
        },
        ChemicalProduction: {
            _1: 'Агрохимия (продвижение, оптовая торговля)',
            _2: 'Агрохимия (производство)',
            _3: 'Бетон, кирпич, стекло и прочие силикаты (продвижение, оптовая торговля)',
            _4: 'Бетон, кирпич, стекло и прочие силикаты (производство)',
            _5: 'Лакокрасочная продукция, сырье (продвижение, оптовая торговля)',
            _6: 'Лакокрасочная продукция, сырье (производство)',
            _7: 'Неорганическая химия (продвижение, оптовая торговля)',
            _8: 'Неорганическая химия (производство)',
            _9: 'Органическая химия (продвижение, оптовая торговля)',
            _10: 'Органическая химия (производство)',
            _11: 'Резина, пластмасса и прочее (продвижение, оптовая торговля)',
            _12: 'Резина, пластмасса и прочее (производство)',
        },
        FinancialSector: {
            _1: 'Аудит, управленческий учет, финансово-юридический',
            _2: 'Банк',
            _3: 'Коллекторская деятельность',
            _4: 'Лизинговые компании',
            _5: 'НПФ',
            _6: 'Страхование, перестрахование',
            _7: 'Управляющая, инвестиционная компания (управление активами)',
            _8: 'Услуги по ведению бухгалтерского и налогового учета, расчет заработной платы',
            _9: 'Факторинговые компании',
            _10: 'Финансово-кредитное посредничество (биржа, брокерская деятельность, выпуск и обслуживание карт, оценка рисков, обменные пункты, агентства по кредитованию, инкассация, ломбард, платежные системы)',
        },
        Electronics: {
            _1: 'Бытовая техника, электроника, климатическое оборудование (монтаж, сервис, ремонт)',
            _2: 'Бытовая техника, электроника, климатическое оборудование (продвижение, оптовая торговля)',
            _3: 'Бытовая техника, электроника, климатическое оборудование (производство)',
            _4: 'Промышленное, бытовое электрооборудование и электротехника (монтаж, сервис, ремонт)',
            _5: 'Промышленное, бытовое электрооборудование и электротехника (продвижение, оптовая торговля)',
            _6: 'Промышленное, бытовое электрооборудование и электротехника (производство)',
            _7: 'Электронно-вычислительная, оптическая, контрольно-измерительная техника, радиоэлектроника, автоматика (монтаж, сервис, ремонт)',
            _8: 'Электронно-вычислительная, оптическая, контрольно-измерительная техника, радиоэлектроника, автоматика (продвижение, оптовая торговля)',
            _9: 'Электронно-вычислительная, оптическая, контрольно-измерительная техника, радиоэлектроника, автоматика (производство)',
        },
        PowerIndustry: {
            _1: 'Альтернативная энергетика: гео-, ветро-, солнце-, био- (генерация электроэнергии)',
            _2: 'Атомная энергетика (генерация электроэнергии, АЭС)',
            _3: 'Гидроэнергетика (генерация электроэнергии, ГЭС)',
            _4: 'Инфраструктурная компания в энергетике (СО ЕЭС, АТС, ЦФР и т. п.)',
            _5: 'Консалтинг в области энергоэффективности',
            _6: 'Передача, распределение электроэнергии',
            _7: 'Сбыт и потребление электроэнергии',
            _8: 'Теплоснабжение (ТЭЦ)',
            _9: 'Теплоэнергетика (генерация электроэнергии и тепла, ТЭС)',
        },
        IndustryAll: {
            _1: 'Автомобильный бизнес',
            _2: 'Гостиницы, рестораны, общепит, кейтеринг',
            _3: 'Добывающая отрасль',
            _4: 'ЖКХ',
            _5: 'Информационные технологии, системная интеграция, интернет',
            _6: 'Искусство, культура',
            _7: 'Лесная промышленность, деревообработка',
            _8: 'Медицина, фармацевтика, аптеки',
            _9: 'Металлургия, металлообработка',
            _10: 'Нефть и газ',
            _11: 'Образовательные учреждения',
            _12: 'Общественная деятельность, партии, благотворительность, НКО',
            _13: 'Перевозки, логистика, склад, ВЭД',
            _14: 'Продукты питания',
            _15: 'Промышленное оборудование, техника, станки и комплектующие',
            _16: 'Розничная торговля',
            _17: 'СМИ, маркетинг, реклама, BTL, PR, дизайн, продюсирование',
            _18: 'Сельское хозяйство',
            _19: 'Строительство, недвижимость, эксплуатация, проектирование',
            _20: 'Телекоммуникации, связь',
            _21: 'Товары народного потребления (непищевые)',
            _22: 'Тяжелое машиностроение',
            _23: 'Управление многопрофильными активами',
            _24: 'Услуги для бизнеса',
            _25: 'Услуги для населения',
            _26: 'Финансовый сектор',
            _27: 'Химическое производство, удобрения',
            _28: 'Электроника, приборостроение, бытовая техника, компьютеры и оргтехника',
            _29: 'Энергетика',
        },
        hobbies: {
            _1: 'Альпинизм',
            _2: 'Археология',
            _3: 'Аэробика',
            _4: 'Аэрография',
            _5: 'Бег',
            _6: 'Бильярд',
            _7: 'Боулинг',
            _8: 'Велопоездки',
            _9: 'Гимнастика',
            _10: 'Горные лыжи',
            _11: 'Граффити',
            _12: 'Дайвинг',
            _13: 'Дегустация блюд',
            _14: 'Дегустация вин',
            _15: 'Диггерство',
            _16: 'Дизайн помещений',
            _17: 'Дрессировка животных',
            _18: 'Живопись, рисование',
            _19: 'Игрушки',
            _20: 'Изучение иностранных языков',
            _21: 'Интернет',
            _22: 'Йога',
            _23: 'Исполнение музыки',
            _24: 'Историческое фехтование',
            _25: 'Катание на коньках',
            _26: 'Коллекционирование',
            _27: 'Конный спорт',
            _28: 'Кроссворды',
            _29: 'Кулинария',
            _30: 'Ландшафтный дизайн',
            _31: 'Лыжи',
            _32: 'Массаж',
            _33: 'Медитации',
            _34: 'Настольные игры',
            _35: 'Общение',
            _36: 'Парашютный спорт',
            _37: 'Пейнтбол',
            _38: 'Пение',
            _39: 'Плавание',
            _40: 'Пляжный отдых',
            _41: 'Посещение музеев и выставок',
            _42: 'Походы',
            _43: 'Прогулки',
            _44: 'Прослушивание лекций',
            _45: 'Просмотр театральных постановок',
            _46: 'Просмотр телепередач',
            _47: 'Просмотр фильмов',
            _48: 'Растения',
            _49: 'Ролевые игры',
            _50: 'Ролики',
            _51: 'Рукоделие',
            _52: 'Рыбалка',
            _53: 'Силовые тренировки',
            _54: 'Создание мультфильмов',
            _55: 'Сочинение музыки',
            _56: 'Спелеология',
            _57: 'Спорт',
            _58: 'Танцы',
            _59: 'Театр',
            _60: 'Тренинги',
            _61: 'Фотоохота',
            _62: 'Туризм',
            _63: 'Чайная церемония',
            _64: 'Чтение книг',
            _65: 'Яхтенный спорт',
        }
    },
    en: {
        sharedInPraticipation: 'Share of participation in %',
        mm: 'mm',
        cm: 'cm',
        km: 'km',
        inch: 'in',
        foot: 'ft',
        yard: 'yard',
        mile: 'mile',
        mm2: 'mm2',
        cm2: 'cm2',
        m2: 'm2',
        ha: 'ha',
        km2: 'km2',
        inch2: 'in2',
        foot2: 'ft2',
        yard2: 'yard2',
        a: 'a',
        mm3: 'mm3',
        cm3: 'cm3',
        m3: 'm3',
        ml: 'ml',
        l: 'l',
        dm3: 'dm3',
        inch3: 'in3',
        foot3: 'ft3',
        yard3: 'yard3',
        mg: 'mg',
        k: 'k',
        g: 'g',
        kg: 'kg',
        t: 't',
        kt: 'kt',
        Status: 'Status',

        VerifyYour: 'Verify your',
        Phone: 'phone',

        robotRecaptcha: 'Confirm that you are not a robot',
        techSupport: {one: 'Thank you!', two: 'Your message has been successfully sent, we\'ll do our best to give you a reply as soon as possible.', three: 'Please, do not duplicate your request, our specialists will certainly contact you. If you\'ve sent us a message on weekend, the reply may be delayed.'},
        recaptcha: 'en',
        AuthorizationRegistration: 'Authorization / Registration',
        LogRegisterSupport: 'Log in or register to send the form to technical support',
        userDocuments: 'User documents',
        techSupportFormText: 'If you are experiencing a problem with newnext please don\'t hesitate to contact us. Please provide as much detail about the problem as possible to help us find the best solution.',
        ABOUTTHEPROJECTFIGURES: 'ABOUT THE PROJECT IN FIGURES',
        YourBlacklistEmpty: 'Your blacklist is empty.',
        beforeTheEndOf: 'Before the end of',
        delete: 'Delete',
        UsersPersonalPrivacy: 'Users\' personal data privacy policy of newnext Site',
        GENERALTERMS: '1. GENERAL TERMS',
        GENERALTERMS_1_1: '1.1. The subject of this Users\' personal data privacy policy of newnext site (hereinafter – the Policy) are the conditions that the site Administration complies with in respect to personal data that the site Administration can obtain about the User during the use of the newnext website and its functionality',
        GENERALTERMS_1_2: '1.2. The purpose of this Policy is to ensure proper protection of users\' personal data of site by the site Administration from unauthorized access and disclosure.',
        GENERALTERMS_1_3: '1.3. The user should carefully read the terms of this Policy, which the site Administration defines as a public offer in accordance with article 437 of the Civil Code of the Russian Federation.',
        GENERALTERMS_1_3_1: 'The relations arising in connection to the collection, processing and storage of information about users of the site are governed by this Policy, other official documents of the Site Administration and the current legislation of the Russian Federation.',
        GENERALTERMS_1_4: {one: '1.4. The policy is a public document placed in the public domain at:', link: 'http://newnextglobal.com/privacy', two: ', and is available to any user.'},
        GENERALTERMS_1_5: '1.5. This Policy is an official document of the site Administration.',
        GENERALTERMS_1_6: '1.6. This Policy is developed in full compliance with the terms of use of the site. In case of any conflict between this Policy and other official documents of the Administration, this Policy shall be applied.',
        GENERALTERMS_1_7: '1.7. The User\'s use of the site and its functionality constitutes acceptance of this Policy and the terms of processing the user’s personal data.',
        GENERALTERMS_1_8: '1.8. In case of disagreement with the terms of the Policy, the User must stop using the site and its functionality.',
        GENERALTERMS_1_9: '1.9. This Policy applies only to the site. The site Administration does not control and is not responsible for third-party sites on which the user can click on the links available on the site.',
        GENERALTERMS_1_10: '1.10. The site administration does not check the accuracy of personal data provided by the user of the site.',
        GENERALTERMS_1_11: '1.11. The site Administration reserves the right to make changes to this Policy. After making changes, the Administration shall place the new version of the Policy on the Website no later than 7 (Seven) days from the date on which the Policy comes into force in the new edition.',
        DEFINITIONOFTERMS: '2. DEFINITION OF TERMS',
        DEFINITIONOFTERMS_1: 'The following terms are used in this Policy:',
        DEFINITIONOFTERMS_2:  {one: 'Newnext site (hereinafter -the Site) international global business network newnext, is an Internet resource and is a set of information and computer programs contained in the information system that provides access to such information on the Internet at:', link: 'http://newnextglobal.com', two: '(including all levels of the specified domain, both functioning at the date of acceptance by the User of this Policy, and started and put into operation during all terms of its action).'},
        DEFINITIONOFTERMS_3: 'The newnext site Administration (previously and hereinafter – site Administration or the Administration) is the limited liability company ‘Alternative Contemporary Technologies”, a legal entity created according to the legislation of the Russian Federation and registered at the address: 445012, Samara region, Togliatti, 8 Kommunisticheskaya St., in which authorized employees have management power over the website, the organization and implementation of processing personal data, and also determining the purpose personal data processing, the structure of the personal data that are subject to processing, and actions (operations) made with personal data.',
        DEFINITIONOFTERMS_4: 'Personal data is any information relating directly or indirectly to a specific or identifiable individual (subject of personal data).',
        DEFINITIONOFTERMS_5: 'Processing of personal data is any action (operation) or a set of actions (operations) performed using automation or without the use of such means with personal data, including the collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, and destruction of personal data.',
        DEFINITIONOFTERMS_6: 'Privacy Policy of personal data is mandatory for the Administration or other authorized personnel who have access to personal data; requiring the prevention of their distribution without the consent of the subject concerning personal data or other legal grounds.',
        DEFINITIONOFTERMS_7: 'The user of the newnext site (hereinafter - the User) is a person who has access to the site through the Internet and uses the site and its functionality.',
        DEFINITIONOFTERMS_8: 'Cookies are small pieces of data sent by the web server and stored on the User\'s computer, which the web client or web browser sends each time the web server in an HTTP request when you try to open a page of the Site.',
        DEFINITIONOFTERMS_9: 'IP-address is a unique network address of a node in a computer network built on the IP Protocol.',
        INFORMATIONABOUTUSERS: '3. INFORMATION ABOUT USERS OF THE SITE',
        INFORMATIONABOUTUSERS_1: '3.1. This Policy sets out the obligations of the Site Administration on non-disclosure and protection of personal data privacy that the User provides at the request of the Site Administration when registering on the Site or using the site functionality.',
        INFORMATIONABOUTUSERS_2: '3.2. The site Administration protects the personal data allowed to be processed within the framework of this privacy Policy – data which the User provides by filling out the registration form on the Site and using the site functionality.',
        INFORMATIONABOUTUSERS_3: '3.2.1. Personal data of Users',
        INFORMATIONABOUTUSERS_4: 'Personal data of Users include:',
        INFORMATIONABOUTUSERS_5: '1) provided by Users and the minimum required for registration on the Site: a User type, name, last name, a mobile phone number;',
        INFORMATIONABOUTUSERS_6: '2) provided by Users using the editing section of their pages on the Site: sex, date of birth, address, email address, education, industry, career, marital status, hobbies, additional information provided by the User when editing the profile;',
        INFORMATIONABOUTUSERS_7: '3) provided by Users at the request of the Site Administration in cases when the site Administration has the right to fulfill its obligations to Users, the site Administration, in particular, request from the User a copy of an identity document or other document containing the name, last name, a photo of the User, as well as other additional information that, at the discretion of the Site Administration, will be necessary and sufficient to identify such a User and will allow the elimination of the abuse and rights violations of third parties.',
        INFORMATIONABOUTUSERS_8: '3.2.2. Other information about Users processed by the site Administration',
        INFORMATIONABOUTUSERS_9: 'Other User information includes: IP address:',
        INFORMATIONABOUTUSERS_10: '1) data obtained when accessing the Site, including data on technical means (devices), technological interaction with the Site (including the IP-address of the host, the type of the user\'s operating system, a browser type, a geographical location, an Internet service provider, data from the address book, data obtained as a result of access to the camera, microphone, etc. devices), and the subsequent actions of the User on the Site, as well as information containing the history of the user\'s visit to the Site;',
        INFORMATIONABOUTUSERS_11: '2) information automatically obtained when accessing the Site using cookies;',
        INFORMATIONABOUTUSERS_12: '3) information created by users on the Site outside the page editing section (including NNotice, messages, comments, photos);',
        INFORMATIONABOUTUSERS_13: '4) information obtained as a result of User\'s actions on the Site (in particular, information about adding other Users to the list of contacts, posting photos, information about the messages sent and received). This User information can be accessed by The user\'s contacts according to the User\'s settings;',
        INFORMATIONABOUTUSERS_14: '5) information obtained as a result of actions of other users on the Site related to the use of the Site functions.',
        INFORMATIONABOUTUSERS_15: '3.4. The site Administration collects statistics about the IP addresses of its Users. This information is used to identify and solve potential technical problems.',
        INFORMATIONABOUTUSERS_16: '3.5. Any other personal information not mentioned above (a history of Site visits, used browsers and operating systems, etc.) is subject to safe storage and non-proliferation, except as provided in paragraph 5.4 (sub paras 2,3) of this privacy Policy.',
        PURPOSEPROCESSINGPERSONAL: '4. PURPOSE OF PROCESSING OF PERSONAL USER DATA',
        PURPOSEPROCESSINGPERSONAL_1: '4.1. Collection of personal data of the User by the site Administration is carried out in order to comply with the terms of use of the Site, where the site Administration provides the User with access to the use of the Site and its functions.',
        PURPOSEPROCESSINGPERSONAL_2: 'In accordance with sub para 2 paragraph 2 of article 22 of the Federal law of 27th July 2006 No152- Federal Law ‘On personal data’ the site Administration has the right to process personal data without notifying the authorized body for the protection of rights of personal data subjects.\n',
        PROCESSINGUSERPERSONAL: '5. PROCESSING USER PERSONAL DATA',
        PROCESSINGUSERPERSONAL_1: '5.1. The principles of information processing:',
        PROCESSINGUSERPERSONAL_2: '1) Processing of personal data is carried out on a legal and fair basis.',
        PROCESSINGUSERPERSONAL_3: '2) Processing of personal data is limited to the achievement of specific, pre-defined and legitimate goals set by the Site Administration.',
        PROCESSINGUSERPERSONAL_4: '3) It is not allowed to combine databases containing personal data, the processing of which is carried out for purposes incompatible with each other.',
        PROCESSINGUSERPERSONAL_5: '4) Only personal data that meet the purposes of processing shall be processed.',
        PROCESSINGUSERPERSONAL_6: '5) The Content and volume of personal data processed correspond to the stated purposes of processing. Processed personal data are not excessive in relation to the stated purposes of their processing.',
        PROCESSINGUSERPERSONAL_7: '6) The processing of personal data ensures the accuracy of personal data, its sufficiency, and, where necessary, and relevance to the purposes of processing personal data. The site Administration takes the necessary measures to delete or clarify incomplete or inaccurate data.',
        PROCESSINGUSERPERSONAL_8: '7) The storage of personal data is carried out in a way that allows the Administration to identify the subject of personal data, for no longer than processing personal data demands.',
        PROCESSINGUSERPERSONAL_9: '8) Processed personal data shall be subject to destruction or depersonalization upon achievement of the purposes of processing or in case of the loss of the need to achieve these purposes.',
        PROCESSINGUSERPERSONAL_10: '5.2. The collection of personal data',
        PROCESSINGUSERPERSONAL_11: 'The collection of the User’s personal data is carried out on the Site during registration, as well as when the User adds additional information about himself / herself at his / her own initiative when editing the profile.',
        PROCESSINGUSERPERSONAL_12: '5.3. Storage and use of personal data',
        PROCESSINGUSERPERSONAL_13: 'Personal data of users are stored exclusively on electronic media and processed using automated systems, except in cases where manual personal data processing is necessary in connection with the implementation of the requirements of the law.',
        PROCESSINGUSERPERSONAL_14: '5.4. Transfer of personal data',
        PROCESSINGUSERPERSONAL_15: '1) Personal data of Users are not transferred to any third parties, except as expressly provided for in these Terms.',
        PROCESSINGUSERPERSONAL_16: '2) If the User is specified or if the user agrees, the user\'s personal data may be transferred to third parties-contractors of the Site Administration with the condition that such contractors undertake obligations to ensure the confidentiality of the information received.',
        PROCESSINGUSERPERSONAL_17: '3) Personal data of Users are provided at the request of state bodies (local authorities) in the manner prescribed by the legislation of the Russian Federation.',
        PROCESSINGUSERPERSONAL_18: '4) In order to comply with the Terms of use of the Site and provide the User with access to the use of the site functions, the site Administration develops the services and products, develops and introduces new services and products, optimizes the quality of services and products, improves the available functions of the Site and services. \n' +
        'To ensure the implementation of these goals, the User agrees to the implementation of the site Administration in compliance with applicable legislation, the collection, storage, storage, systematization, extraction, comparison, use, filling (refinement) of their data, as well as to receive and transmit to affiliates and partners the results of the automated processing of such data using various models of information evaluation, in the form of integer and / or text values and identifiers, meeting the evaluation criteria specified in the requests for data processing by the site Administration and/or the persons specified in this paragraph.',
        PROCESSINGUSERPERSONAL_19: '5.5. Destruction of personal data',
        PROCESSINGUSERPERSONAL_20: 'The user\'s personal data is destroyed when:',
        PROCESSINGUSERPERSONAL_21: '1) self-deletion of data by the User from his / her personal page;',
        PROCESSINGUSERPERSONAL_22: '2) removal by the site Administration of the information posted by the User, as well as the User\'s personal page in cases established by the Terms of use of the site.',
        PROCESSINGUSERPERSONAL_23: 'In case of the removal of a personal page, the site Administration stores on its electronic media personal and other necessary User data for the necessary and established by the current legislation of the Russian Federation.',
        PROCESSINGUSERPERSONAL_24: '5.6. By posting information on the personal page, including your personal data, the User understands and agrees that this information may be available to other Internet users, taking into account the features of the architecture and functions of the Site. The user determines the privacy mode and conditions of access to information through the appropriate settings. The site administration takes technical and organizational measures to ensure the functioning of the appropriate tools of the Site.',
        PROCESSINGUSERPERSONAL_25: '5.7. The site administration takes the necessary organizational and technical measures to protect the User\'s personal information from unauthorized or accidental access, destruction, modification, blocking, copying, distribution, as well as other illegal actions of third parties.',
        PROCESSINGUSERPERSONAL_26: '5.8. The site Administration together with the User takes all necessary measures to prevent losses or other negative consequences caused by the loss or disclosure of the User\'s personal data.',
        RIGHTSOBLIGATIONSUSERS: '6. THE RIGHTS AND OBLIGATIONS OF USERS',
        RIGHTSOBLIGATIONSUSERS_1: '6.1. The user is obliged:',
        RIGHTSOBLIGATIONSUSERS_2: '6.1.1. Provide information about personal data necessary for the use of the Site and its functionality.',
        RIGHTSOBLIGATIONSUSERS_3: '6.1.2. Update, add the provided information on personal data in case of changes in this information.',
        RIGHTSOBLIGATIONSUSERS_4: '6.2. User has the right:',
        RIGHTSOBLIGATIONSUSERS_5: '6.2.1. To provide free access to information about himself/herself by downloading his/her personal pages on the Site using his/her username and password.',
        RIGHTSOBLIGATIONSUSERS_6: '6.2.2. With the help of the site tools to set the desired level of privacy for individual users of the Site, placing them in the ‘blacklist’.',
        RIGHTSOBLIGATIONSUSERS_7: '6.2.3. Independently to make changes and corrections to the information about himself/herself on the User\'s personal page on the Site, provided that such changes and corrections contain current and accurate information;',
        RIGHTSOBLIGATIONSUSERS_8: '6.2.4. To delete information about himself/herself from your personal page on the Site;',
        RIGHTSOBLIGATIONSUSERS_9: '6.2.5. To demand from the Site Administration an update of their personal data, their blocking or destruction in case data are incomplete, outdated, false, unlawfully obtained or are not necessary for the declared purpose of processing.',
        RIGHTSOBLIGATIONSUSERS_10: '6.2.6. To receive from the site Administration information regarding the processing of personal data on the basis of a request.',
        RIGHTSOBLIGATIONSUSERS_11: '6.3. Since the Site is a universal means of communication and search for business contacts, the main function of the Site is to establish and maintain the necessary business connections, any registered User of the Site has the following information:',
        RIGHTSOBLIGATIONSUSERS_12: {_1: 'User type,', _2: 'first name,', _3: 'last name,', _4: 'patronymic name,', _5: 'mobile phone number,', _6: 'sex,', _7: 'date of birth,', _8: 'profile photo,', _9: 'residential address,', _10: 'email address,', _11: 'education, ', _12: 'industry,', _13: 'career, ', _14: 'marital status, ', _15: 'hobby,', _16: 'additional information provided by the User,', _17: 'contacts,', _18: 'NNoffer,', _19: 'messages,', _20: 'comments.', },
        OBLIGATIONSSITEADMINISTRATION: '7. OBLIGATIONS OF SITE ADMINISTRATION',
        OBLIGATIONSSITEADMINISTRATION_1: '7.1. Use the information obtained only for the purposes specified in paragraph 4 of this Privacy Policy.',
        OBLIGATIONSSITEADMINISTRATION_2: '7.2. Provide storage of confidential information in secret, not to disclose without the prior written permission of the User, and not to sell, exchange, publish, disclose or any other means possible the User’s transmitted personal data. ',
        OBLIGATIONSSITEADMINISTRATION_3: '7.3. Take precautions to protect the confidentiality of the User\'s personal data in accordance with the procedure commonly used to protect such information in the existing business.',
        OBLIGATIONSSITEADMINISTRATION_4: '7.4. To block personal data related to the respective User from the moment of application or request of the User or his / her legal representative or the authorized body for the protection of the rights of personal data subjects for the period of verification, in case of identification of false personal data or illegal actions.',
        MEASURESPROTECTUSER: '8. MEASURES TO PROTECT THE PERSONAL DATA OF THE USER',
        MEASURESPROTECTUSER_1: '8.1. The site administration takes technical, organizational and legal measures to ensure the protection of the User\'s personal data from unauthorized or accidental access to them, destruction, modification, blocking, copying, distribution, as well as other illegal actions.',
        MEASURESPROTECTUSER_2: '8.2. To authorize access to the Site, the user\'s login (mobile phone number) and password are used. The responsibility for maintaining the confidentiality of this information is at your own risk. The user is not entitled to transfer his / her own login and password to third parties, and is obliged to take measures to ensure their confidentiality.',
        MEASURESPROTECTUSER_3: '8.3. In order to provide more reliable protection of information about Users, the site Administration uses the system of binding the page to the mobile phone. To implement this system, the User must provide the site Administration with his/her mobile phone number.',
        MEASURESPROTECTUSER_4: 'Within the system page binding to the mobile phone, the User in case of loss of login or password can restore access to the page using the recovery code contained in the SMS message that the User receives on his mobile phone.',
        MEASURESPROTECTUSER_5: '8.4. The user has the right to receive information about the time and from what devices the authorization to his/her personal page was made.',
        RESPONSIBILITYPARTIES: '9. RESPONSIBILITY OF PARTIES',
        RESPONSIBILITYPARTIES_1: '9.1. The site Administration, which has not fulfilled its obligations to protect the User\'s personal data, is responsible for losses incurred by the User in connection with the unlawful use of personal data, in accordance with the legislation of the Russian Federation, except as provided for in paragraph 5.4 (PP.2.3) of this Privacy Policy.',
        RESPONSIBILITYPARTIES_2: '9.2. In case of loss or disclosure of Confidential information, the site Administration is not responsible if this confidential information:',
        RESPONSIBILITYPARTIES_3: '9.2.1. Became public until it was lost or disclosed.',
        RESPONSIBILITYPARTIES_4: '9.2.2. It was received from a third party until it was received by the site Administration.',
        RESPONSIBILITYPARTIES_5: '9.2.3. It was disclosed with the consent of the User.',
        ADDITIONALCONDITIONS: '10. ADDITIONAL CONDITIONS',
        ADDITIONALCONDITIONS_1: {text:'10.1. All suggestions or requests regarding this privacy Policy should be reported to the Site at:', link: 'newnextglobal.com'},
        ADDITIONALCONDITIONS_2: '10.2. The request sent by the User must contain the following information:',
        ADDITIONALCONDITIONS_3: '1) scan-a copy of the number of the main document proving the identity of the User or his/her representative;',
        ADDITIONALCONDITIONS_4: '2) information confirming the participation of the User in the relationship with the site Administrator (in particular, the serial number of the user id or a short (sub-domain) name replacing the serial number of the id);',
        ADDITIONALCONDITIONS_5: '3) a photo of the User together with a photo on the passport, which can identify the identity of the passport or other identity document.',
        ADDITIONALCONDITIONS_6: '10.3. The site Administration shall consider and send a response to the user\'s request within 30 (Thirty) days from the date of receipt by the site Administration of the User\'s request.',
        ADDITIONALCONDITIONS_7: '10.4. All correspondence received by the site Administration from users refers to restricted access information and is not disclosed without the written consent of the User. Personal data and other information about the user who sent the request may not be used without the User\'s special consent, except to respond to the topic of the request or in cases expressly provided by law.',

        TermsUseCookies: 'Terms of use of cookies and similar technologies',
        StatusOfTheTerms: '1. Status of the Terms for the use of Cookies and similar technologies (hereinafter-the Terms)',
        StatusOfTheTerms_1: '1.1. These Terms determine the use of cookies and similar technologies when using the site and its functionality.',
        StatusOfTheTerms_2: '1.2. The site Administration defines these Terms as a public offer in accordance with article 437 of the Civil Code of the Russian Federation.',
        StatusOfTheTerms_3: '1.3. Prior to registration on the Site, the user is obliged to fully read these Terms. User registration on the Site means full and unconditional acceptance of these Terms by the User in accordance with article 438 of the Civil Code of the Russian Federation.',
        StatusOfTheTerms_4: '1.4. The terms and definitions used in these Terms of use are defined in the Rules of Use.',
        StatusOfTheTerms_5: '1.5. By using the Site, the User confirms his / her consent to the use of cookies in accordance with the terms described below.',
        StatusOfTheTerms_6: '1.6. If the user of the Site does not agree to use these types of files and similar technologies, he/she should properly adjust the settings of his browser or not use the functionality of the Site.',
        UseOfCookies: '2. Use of cookies',
        UseOfCookies_1: '2.1. Cookies are small files consisting of letters and numbers that are stored by the browser of the website user on the device of the website user (a computer, a phone, a tablet, etc.), if the website user agrees to their use.',
        UseOfCookies_2: '2.2. Cookies and similar technologies on the newnext website are necessary in order to distinguish the user of the website from other users. The use of cookies allows the site administration owner to determine the features of the use of the site to further improve its functionality, enhance its attractiveness, and to improve content.',
        UseOfCookies_3: '2.3. Types of cookies used on the site:',
        UseOfCookies_4: '• Essential cookies. These are the files which are necessary for the correct operation of the site.',
        UseOfCookies_5: '• Analytical / technical cookies. They allow us to estimate and count the number of visitors to the site, as well as understand how they move around the site when working with it. This helps to make improvements to the site, making it more effective.',
        UseOfCookies_6: '• Functional files. These cookies are used to recognize the user when he / she visits the site again.',
        UseOfCookies_7: '• Target cookies. These files record the user\'s visits of the site, remember the pages visited by the user and the links he / she followed.',
        UseOfCookies_8: {attentional: 'IMPORTANT!', text: ' Please note that third parties (e.g. advertising networks or external service providers such as web traffic analysis) may also use cookies. This process is not controlled by the site Administration. These cookies are likely to be analytical/performance cookies or target cookies.'},
        UseOfCookies_9: 'The user can block cookies by activating a certain setting in the browser, which will allow the user to refuse to accept all or part of the cookies. However, in case of blocking cookies in the Internet browser (including essential cookies), the User may lose access to all or several sections of the site.',
        TheShelfLifeCookies: '3. The shelf life of the cookies',
        TheShelfLifeCookies_1: '3.1. ‘Session.’ These are cookies that are in effect from the moment the user logs onto the site until the end of this particular session in the browser. When you close your browser, these cookies expire and are automatically deleted.',
        TheShelfLifeCookies_2: '3.2. ‘Permanent.’ These cookies are stored on your device and between browser sessions — they are not deleted after you close your browser. The storage time of persistent cookies on the device varies for different cookies.',
        CookieManagement: '4. Cookie management',
        CookieManagement_1: '4.1. Most Internet browsers are initially set to automatically accept cookies. The user can change the settings to block cookies or to warn when files of this type will be sent from the device.',
        CookieManagement_2: '4.2. To manage cookies, you need to refer to your browser\'s instructions and learn more about how to adjust or change your browser settings. If you disable cookies that are used by the site, this may affect the work on the site.',
        CookieManagement_3: '4.3. If you use different devices to access the site (for example, a computer, a smartphone, a tablet, etc.), you need to make sure that each browser on each device is configured to work with cookies according to the user\'s point of view.',
        OtherSimilarTechnologies: '5. Other similar technologies',
        OtherSimilarTechnologies_1: '5.1. The site administration also uses technologies similar to cookies.',
        OtherSimilarTechnologies_2: '5.2. Such technologies provide information about how the site and its content are used by users and allow us to determine whether the site was visited from a computer or device of the user.',
        OtherSimilarTechnologies_3: '5.3. The servers automatically read the user\'s IP address when he/she visits the site, and associate this information with the domain name or with the user\'s Internet service provider.',
        OtherSimilarTechnologies_4: '5.4. The site administration may also collect ‘information about visits’ related to the use of the site by the user. Information about the visits includes information about the user\'s computer or device, web browser or operating system and its installations, the page that led to the site, the pages and content that the user sees and clicks on during the visit, and when and for how long the user does so, what downloads, what sites the user visits after leaving the site, as well as the keywords that the user entered on the site or sites from which he / she came to the site.',
        ChangesTermsCookies: '6. Changes to the Terms of use of cookies',
        ChangesTermsCookies_1: '6.1. The administration reserves the right to make changes to the Rules at any time and update them in accordance with updated legislation. When using the site, the user can be acquainted with the updates and changes to the Terms, the development of methods of data collection online and the use of these data by the site.',
        ChangesTermsCookies_2: '6.2. For questions arising as to the execution of these Terms, the user can contact the technical support of the site.',


        WatchVideoProject: {one: 'Watch the video about the project', two: ' (under development)'},
        AboutTheProject: 'About the project',
        NewnextWorldFirst: 'Newnext is international global business network that serves as a platform for business, bringing together interested users in the business community. Newnext business network allows its users to solve the set business tasks and implement business ideas by creating a range of necessary connections and contacts in a reasonable time with minimum of resource costs.',
        FoundedIn: {one: 'Founded in', two: '2018'},
        MoreDevelopers: {one: '>20', two: 'developers'},
        MoreDesigners: {one: '4', two: 'designers'},
        MoreAnalysts: {one: '6', two: 'analysts'},
        WhatCanYouNewnext: 'What can you do in newnext?',
        PotentialInvestorss: 'Potential investors can find projects for financial investments',
        PlaceProjectsInvestment: 'Place projects on the investment market in order to attract investment',
        FindBestDealsBusiness: 'Find the best deals on business products',
        FindGoodsProducts: 'Find goods and products',
        FindInterestedUsersImplementation: 'Find interested users for the implementation of innovative projects and inventions',
        ReceiveGualifiedSupport: 'Receive qualified support from experts and professionals in various sectors of the economy and business',
        TheServicesExisting: 'The best services of existing social networks are combined in a new global business network newnext',
        TheFunctionalCapabilities: 'The functional capabilities of newnext',
        EstablishBusinessRelationships: 'Establish business relationships that meet the interests of each type of user',
        ReceiveConvenientTargeted: 'Receive convenient targeted search of contacts in accordance with the purposes of each type of participant newnext',
        CreatePersonalContactList: 'Create a personal contact list',
        ViewYourContactsFeeds: 'View your contacts\' news feeds',
        MaintainPersonalCorrespondenceBusinessNetwork: 'Maintain personal correspondence with other members of the business network',
        MakeWithAbilityAmong: 'Make NNoffer with the ability to distribute among targeted user groups',
        ANYIDEAS: 'ANY IDEAS?',
        AreYouUserNetworkWould: 'Are you a user of newnext business network and would like to offer an interesting idea for the improvement or development of the project? Write to us!',
        OfferIdea: 'Offer an idea',



        RestrictedAccess: 'Limited access to the account',
        canNotSend: 'Unfortunately, your message cannot be sent due to this user\'s privacy settings.',
        toAddBlackList: {one:'Do you want to add', two: 'into a blacklist?'},
        addToBlackList: 'Add to blacklist',
        fromTheBlacklist: 'from the blacklist?',
        removeFromBlackList: 'Remove from blacklist',
        blackList: 'Blacklist',
        Contents: 'Contents',
        Chapters: 'Chapters',
        DidNotHelp: 'Did not help, create an appeal to technical support',
        showMore: 'Show more ',
        help: 'Help',
        selectStatus: 'Select status',
        TermsOfUser: `Terms of use of the newnext Website`,
        WelcomeToNewnext: `Welcome to the newnext Website, an internet resource that helps you solve business problems and implement business ideas by searching and creating the necessary connections and contacts in a reasonable time with with a minimum of any costs.`,
        NewNextWebsiteServes: `The newnext website serves as a platform for business, bringing together users in professional business communities.`,
        TheSubjectOfTheseTerms: `The subject of these terms of use of the newnext website (hereinafter – the Rules) are the conditions in which the newnext website Administration provides you with access to the use of the newnext website and its functionality.`,
        ShouldCarefullyRead: `You should carefully read the terms of these Rules, which the site Administration defines as a public offer in accordance with article 437 of the civil code of the Russian Federation.`,
        StatusOfTermsUse: `1. Status of the Terms of use of the newnext Website`,
        TheseTermsUseNewnextSite: `1.1. These terms of use of newnext site (previously and hereinafter – the Rules) are developed by the site Administration.`,
        RulesDetermineTermsConditions: ` The Rules determine the terms and conditions of use of the site and its functionality, rights and obligations of its users and Administration.`,
        IfResultTheUseSite: `If, as a result of the use of the site and its functionality by the user, the rights and interests of third parties that are not users of the site are affected, then the Rules also apply to relations related to the rights and interests of these third parties.`,
        TheseRulesAreLegally: `1.2. These Rules are a legally binding agreement between the user and the site Administration.`,
        TheSubjectOfAgreement: `The subject of the agreement between the user and the site Administration is the provision of the site Administration to the user with access to the use of the site and its functionality.`,
        AllSpecialDocuments: `All special documents regulating the provision of access to the use of a separate site functionality, not included in the Rules, also refer to the agreement between the user and the site Administration and are placed in the relevant sections of the site on the Internet.`,
        RulesAreOpenAndPublic: '1.3. These Rules are an open and public document. The current version of the Rules is available on the Internet at: ',
        RulesAreOpenAndPubliclink: {text: ' http://newnextglobal.com/terms', url: '/terms'},
        PriorToRegistration: `1.4. Prior to registration on the site, the user is obliged to fully read these Rules. User registration on the site means full and unconditional acceptance of these Rules by the user in accordance with article 438 of the Civil code of the Russian Federation.`,
        RulesCanBeChangedSupplemented: `1.5. These Rules can be changed and/or supplemented on the initiative of the site Administration unilaterally without special notice. The site Administration recommends that users regularly check the terms of these Rules for changes and/or additions, as the continued use of the site by the user after making changes and/or additions to these Rules clearly means the acceptance and consent of the user with such changes and / or additions.`,
        NewnextSiteStatus: `2. Newnext site status`,
        NewnextWebsiteInternational: `2.1. Newnext website – (or site)- an international global business network ‘newnext’, is an Internet resource and it is a set of information and computer programs contained in the information system that provides access to such information on the Internet at: newnextglobal.com (including all levels of the specified domain, both functioning on the date of acceptance by the user of these Rules, and started and put into operation during all term of its action).`,
        SiteAdministrationProvidesAccess: `2.2. The site Administration provides access to the site to all interested persons in accordance with these Rules and the current legislation of the Russian Federation.`,
        RightsSiteAsWhole: `Rights to the site as a whole and to use a network address (domain name) http://newnextglobal.com belong to the site Administration.`,
        TheConditionsAreSet: `2.3. The conditions are set by these Rules, under which the right to use information and intellectual activity results (including, but not limited to literary, musical, audiovisual works and phonograms, works of graphics and design, photographic works, computer programs), composed of individual sections of the site, may belong to site users and other persons who independently created and/or place the specified facilities on the site without direct participation of the site Administration.`,
        NewnextSiteAdministration: `3. Newnext site Administration`,
        SiteAdministrationTheseRules: `3.1. The newnext site Administration (previously and hereinafter- the site Administration or Administration) in these Rules and other special documents posted on the Site, means a Limited Liability Company (OOO in Russian language)  ‘Alternative Modern Technologies’, a legal entity established in accordance with the legislation of the Russian Federation and registered at the address: 445012, Samara region, Togliatti, Kommunisticheskaya street, 8.`,
        AppealsProposalsAndClaims: `3.2. Appeals, proposals and claims of individuals and legal entities to the site Administration in connection with these Rules and all issues on the operation of the Site, violations of the rights and interests of third parties in its use, as well as for requests of persons authorized by the legislation of the Russian Federation may be sent to the postal address of  ‘Alternative Modern Technologies’ specified in the paragraph 4.1 of this rule.`,
        WithRegardToTheOperation: `3.3. With regard to the operation and development of the site, the Administration conforms to the legislation of the Russian Federation, these Rules and other special documents that have been developed or can be developed and adopted by the site Administration in order to regulate the provision of users with access to certain functionality of the site.`,
        TheRightUseBrandName: `3.4. The right to use the brand name, trademarks, domain names and other distinctive signs of the site Administration may be granted only by written agreement with the site Administration. No provisions of these Rules grant the user the right to use the brand name, trademarks, domain names and other distinctive signs of the site Administration.`,
        RegistrationOnTheNewnextUser: `4. Registration on the newnext Website and user status`,
        UserRegistrationSiteIfFree: '4.1. User registration on the site is free, voluntary and is made at the address on the Internet:',
        UserRegistrationSiteIfFreelink: {text: 'newnextglobal.com', url: '/'},
        UserOfSiteisANatural: `4.2. The user of the site is a natural or legal person registered on the site in accordance with the procedure established by these Rules, who has reached the age allowed, in accordance with the legislation of the Russian Federation, for the acceptance of these Rules (for individuals), (previously and further – the user).`,
        IfTheUserofTheSiteLegal: `If the user of the site is a legal entity, the representative(s) of the user who is registering the user, who subsequently has access to the user's page, this user is determined by the user independently and is not regulated by these Rules.`,
        WhenRegisteringonTheSite: `4.3. When registering on the site, the user is obliged to provide the site Administration with the necessary reliable and relevant information for the formation of the user's personal page, including a unique login and password for each user to access the site, as well as all data marked on the registration form as mandatory. The registration form of the site contains additional information, the filling of which will allow the user to use the features and functionality of the site more widely.`,
        TheUserIsResponsible: `4.4. The user is responsible for the accuracy, relevance, completeness and compliance with the legislation of the Russian Federation of information provided during registration, and its purity from the claims of third parties.`,
        AfterProvidingInformation: `4.5. After providing the information specified in paragraph 4.3. of these Rules, the user is obligated to:`,
        ConfirmRegistrationAutomated: `(a) confirm the registration by automated test recognition designed to distinguish computers from people (authentication code ‘captcha’);`,
        ConfirmTheRegistration: ` (b) confirm the registration by entering into the appropriate form on the Website the code received by the user in the form of an sms message from the website Administration to the user's mobile phone, the number of which is provided by the user himself/herself.`,
        InTheCaseCorrect: `In the case of the correct sequential execution of all registration actions on the site, a personal user page is created, which has a network address of the form http://newnextglobal.com/id[number].`,
        RegistrationTheUserOnTheSite: `4.6. Registration of the user on the site means that the user agrees with these Rules and accepts the rights and obligations specified in them related to the use of the site and its functionality. The user agrees to receive through the services of the site Administration and/or services of third parties electronic messages, sms and other types of newsletters, including advertising and information content, including those from the partners of the site Administration, with whom the user has agreed to receive the messages. The user gets full access to the use of site functionality (its data and commands).`,
        AfterSuccessfulReg: `4.7. After successful registration of the user on the site, the Administration has the rights and obligations to the user specified in these Rules.`,
        TheProcessingUsers: `4.8. The processing of the user’s personal data is carried out in accordance with the legislation of the Russian Federation. The site Administration processes the user's personal data in order to provide the user with access to the use of the site functionality, including in order to receive personalized (targeted) advertising; check, research and analysis of such data, allowing maintenance and improvement of the functionality and sections of the site, as well as to develop new functionality and sections of the site.`,
        SiteAdminTakesAll: `The site Administration takes all reasonable measures to protect the user's personal data from unauthorized access, modification, disclosure or destruction. The Administration provides access to the user's personal data only to those employees, contractors and agents of the Administration who need this information to ensure the functioning of the site and provide the user with access to it. (deleted “to its use”)`,
        SiteAdminHasRightUse: `The site Administration has the right to use the information provided by the user, including personal data, as well as to transfer it to third parties, in order to ensure compliance with the requirements of the current legislation of the Russian Federation, to protect the rights and interests of users, the site Administration, and third parties (including for the purpose of identifying, checking/investigating and/or suppressing illegal actions). Disclosure of the information provided by the user may be made only in accordance with the current legislation of the Russian Federation at the request of the court, law enforcement agencies, as well as in other cases stipulated by the legislation of the Russian Federation. Since the site Administration processes the user's personal data in order to comply with these Rules, due to the provisions of the legislation on personal data, the user's separate consent to the processing of his personal data is not required.`,
        LoginAndPassword: `4.9. Login (username) and password registered by the user are necessary and sufficient information for the user's access to the site. The user has no right to transfer his / her login (username) and password to third parties, and the user is fully responsible for their own safety, independently choosing the way of their own storage. The user can allow the storage of login and password (using cookies) for subsequent automatic authorization on the site on the hardware and software used by him/her.`,
        AnyActionsPerformed: `4.10. Any actions performed using the user's login and password are considered to be performed by the relevant user, unless the user proves otherwise. The user is obliged to inform the site Administration immediately about the cases of unauthorized access to the login and password, the user's personal page, the distribution of the login and password.`,
        TheUserHasRightCreate: `4.11. The user has the right to create, use and determine the content of his / her own personal page independently for personal purposes and the conditions of access of other users to its content, as well as access and placement of information on the personal pages of other users (subject to obtaining appropriate access rights from their owners), use of newnext widgets on third-party sites without additional authorization. When using widgets by the user, third-party sites are not provided with data about the user's login and password; his/her data are also protected by privacy settings.`,
        TheUserAsTheOwner: `4.12. The user, as the owner of the information posted on his / her own personal page, is aware that, except for the cases established by these Rules and the current legislation of the Russian Federation, the site Administration does not participate in the formation and use of the content and control of access of other users to the personal page of the user. By posting information on the personal page, including your personal data, the user understands and agrees that this information may be available to other internet users, taking into account the features of the architecture and functionality of the site.`,
        WithRegardToPersonal: `4.13. With regard to personal information, the user has the right to:`,
        IndependentlyDeleteInfo: `(a) independently delete the information previously posted by the user on the website;`,
        IndependentlyDeletePersonal: `(b) independently delete the user's personal page by sending a request to the support service of the site.`,
        ObligationsTheUser: `5. Obligations of the user of the Website`,
        WhenUsingTheSite: `5.1. When using the site and its functionality, the user is obliged:`,
        ToComplyProvisions: `(a) to comply with the provisions of the current legislation of the Russian Federation, these Rules and other documents of the Administration related to the operation of the site;`,
        ToEnterReliable: `(b) to enter reliable data when filling out the registration form on the website for registration, to monitor their updating;`,
        ToInformImmediately: ` (c) to inform immediately the site Administration about unauthorized access to the personal page and / or unauthorized access and / or use of the user's password and login;`,
        ToWithholdAccess: `(d) to withhold access from other users from their own personal page or to the separate information contained on it in case it may lead to violation of the legislation of the Russian Federation and / or these Rules, special documents of the site Administration;`,
        NotToPost: `(e) not to post on the personal page information and objects (including links to them) that may violate the rights and interests of other persons;`,
        ToBeResponsibleLegality: `(f) to be responsible for the legality of the placement of information and objects (including, but not limited to, images of other persons, other people's texts of various content, audio recordings and videos);`,
        ToBeResponsibleSecurity: ` (g) to be responsible for the security of information containing personal data, business activity and private life of other users and third parties;`,
        NotToProvide: `(h) not to provide, without appropriate prior permission, other users and third parties with information containing personal data, business activity and the privacy of other users and third parties.`,
        SiteAdminRecommends: `5.2. The site Administration recommends refraining from any actions, including the placement of information or access, in case of doubts about their legality.`,
        TheUserOfTheSiteProhibited: `5.3. The user of the site is prohibited:`,
        FromRegistering: `5.3.1. from registering on behalf of or instead of another person by creating a ‘fake account’. Registration on behalf of and on behalf of another natural person or legal entity is permitted, provided that there are mandatory powers in the manner and form provided for by the legislation of the Russian Federation;`,
        FromMisleading: `5.3.2. from misleading users about their identity, using the login and password of another user, to distort information about themselves, their relations with third parties or organizations;`,
        FromUploadingStore: `5.3.3. from uploading, store, publish, distribute and make available or otherwise use any information that:`,
        ViolatesPrivacy: `(a) violates the privacy of other users or third parties;`,
        ViolatesMinors: `(b) violates the rights of minors;`,
        VulgarOrObscene: `(c) is vulgar or obscene, contains pornographic images and texts or scenes of a sexual nature involving minors, contains threats, discredits, insults, besmirches the honor or dignity or discredits business reputation of other users or third parties,`,
        ContainsScenesAnimalAbuse: `(d) contains scenes of animal abuse;`,
        IncitementToCommitSuicide: `(e) contains any incitement to commit suicide;`,
        PromotesRacial: `(f) promotes and/or promotes racial, religious, ethnic hatred or enmity, fascism or ideology of racial superiority;`,
        ExtremistMaterials: `(g) contains extremist materials;`,
        CriminalActivityAdvice: `(h) promotes criminal activity, advice, instructions or guidelines how to commit criminal acts;`,
        RestrictedInfo: ` (i) contains restricted information, including, but not limited to, state and commercial secrets, information about the private lives of third parties;`,
        AdvertisesOrPromo: `(j) advertises or promotes the attractiveness of using drugs, including ‘digital drugs’ (audio files affecting the human brain through binaural rhythms), information on the distribution of drugs, their manufacture recipes and tips for use;`,
        isFraudulent: `(k) is fraudulent;`,
        ViolatesOtherRights: `(l) violates other rights and interests of citizens and legal entities, as well as the requirements of the legislation of the Russian Federation.`,
        ToUploadStore: `5.3.4. to upload, store, publish, distribute and provide access to or, any other way, to use the intellectual property of the users and third parties by gaining access to them illegally.`,
        WithoutConsentOther: `5.3.5. without the consent of other users of the site to carry out mass mailing of messages to them.`,
        ToCarryOutActions: `5.3.6. to carry out actions, including using software aimed at the occurrence of failures in the site.`,
        ToUseViruses: `5.3.7. to use viruses, Trojans and other malicious programs.`,
        ToUseAutomatedScripts: `5.3.8. to use automated scripts (programs, bots, crawlers) to collect information on the site and/or interact with the site and its functionality.`,
        InAnyWayTry: `5.3.9. in any way to try to gain access to personal data, login and password of another user, to carry out the illegal collection and processing of personal data of other persons;`,
        UseSiteWayOtherThan: `5.3.10. to use the site in a way other than through the interface provided by the site Administration without prior agreement with the Administration.`,
        ToCopyAndTransfer: `5.3.11. to copy and transfer (sell) access to use the site;`,
        ToPostCommercial: `5.3.12. to post commercial and political advertisements outside of special website sections established by the website Administration;`,
        ToPostAnyOtherInfo: `5.3.13. to post any other information that does not meet the objectives of the site, infringes upon the interests of users or for other reasons is undesirable for posting on the site.`,
        UserPersonally: `5.4. The user is personally responsible for any information that is posted on the site by him/her, or whether he/she reports to other users, as well as for any interaction with other users, these are carried out at their own risk.`,
        UserObliged: `5.5. The user is obliged to refuse to use the site in case of disagreement with these Rules or their future updates, informing the site Administration.`,
        IntellectualProperty: `6. Intellectual property rights`,
        ExclusiveRights: `6.1. Exclusive rights to the content posted on the site.`,
        ObjectsExclusive: `6.1.1. The objects of exclusive rights of the Administration, users of the site and other owners are all objects placed on the site, including design elements, text, graphics, illustrations, videos, scripts and other objects and their collections (hereinafter — the Content). All rights to these objects are protected.`,
        NoContentMayBeCopied: `6.1.2. No Content may be copied (reproduced), processed, widespread, displayed in a frame, published, downloaded, transferred, sold or otherwise used in whole or in part without prior permission of the copyright holder, except when the copyright holder explicitly expressed its consent to free use of content by any person, and except as required by these Rules and the applicable laws of the Russian Federation.`,
        UserGrantsOtherUsers: `6.1.3. The user grants other users a non-exclusive right to use the content on the site, which is legally owned by the user, within the framework of the functionality provided by the site, by viewing or reproducing (including copying) for the purpose of personal non-commercial use, unless such use causes or may cause harm to the interests of the right holder protected by law.`,
        UsersUseContentAccess: `6.1.4. The user's use of the Content, access to which is obtained exclusively for personal non-commercial use, is allowed provided that all authorship marks (copyright) or other authorship notices are preserved, the author's name is preserved unchanged, and the work is preserved unchanged.`,
        UserGrantsSiteAdmin: `6.1.5. The user grants the site Administration a non-exclusive right to use the content posted on the site free of charge and belonging to him / her legally for the purpose of ensuring the functioning of the site to the extent determined by the functionality and architecture of the site.`,
        SiteAdminGranted: `The site Administration is granted a non-exclusive right to use and display the content among the promotional materials of the site Administration, including within the framework of images of the site interface, including the use to bring such promotional materials to the public.`,
        NonExclusiveRight: `This non-exclusive right extends its effect to the territory of countries around the world and is provided for the period of posting content on the site.`,
        ExpirationTheTerm: `The expiration of the term of posting Content on the site and/or the term of the non-exclusive right does not entail the need to withdraw from the turnover of promotional materials of the site Administration with the display of content (including their removal from the internet). The user agrees that the Administration has the right to use the functionality and technical capabilities of the site that provide the display of the user content, including the player, intended for the purpose of displaying the content, at its discretion, including for the purpose of displaying advertising information.`,
        SiteAdminHasRightTransfer: `The site Administration has the right to transfer the rights specified in this paragraph to third parties.`,
        AdminReservesRight: `6.1.6. The Administration reserves the right, if necessary, due to the technical features of the site, to keep archival copies of user content for the necessary period, even if the user removes its content from the site, and this has led to an automatic revocation of the non-exclusive right referred to in paragraph 6.1.5. of this rule.`,
        UserDoesNotHaveRightTo: `6.1.7. The user does not have a right to upload or , any other way, to make available to the public (publish on the site) the content of other sites, databases and other results of intellectual activity, except for its own content, in the absence of the express consent of the right holder to such actions.`,
        AnyUserSite: `6.1.8. Any use of the site or Content without the prior written permission of the right holder is strictly prohibited, except as permitted in these Terms and conditions or in case of the express consent of the right holder to such use.`,
        NothingTheseTerms: `6.1.9. Nothing in these terms may be considered as a transfer of exclusive rights to the content, unless otherwise expressly provided in these terms.`,
        Liability: `6.2. Liability for the violation of exclusive rights.`,
        UserPersonallyResponsible: `6.2.1. The user is personally responsible for any content or other information that he / she downloads or otherwise makes available to the public (publishes) on or through the site.`,
        TheUserDoesNotRelevant: `If the user does not have the relevant rights acquired or transferred to him/her in accordance with the legislation of the Russian Federation, he/she has no right to upload, transmit or publish content on the site. To consider complaints regarding violations of rights, the user must send a request to the support service of the site.`,
        AdminNotObliged: `6.2.2. The site Administration is not obliged to look through the site for the presence of prohibited content, but may remove or move (without notice) any content or users at its discretion.`,
        SiteAdminMay: `The site Administration may, at its sole discretion, for any reason or without reason, without any restrictions, move or delete content that, in the personal opinion of the Administration, violates these Rules, the legislation of the Russian Federation and/or may violate the rights, harm or threaten the safety of other users or third parties.`,
        WebsitedAndContent: `6.3. Websites and Content of third parties.`,
        SiteMayContain: `6.3.1. The site may contain links to other sites on the internet (third party sites), including articles, photographs, illustrations, graphics, music, sounds, videos, information, applications, programs and other content owned by third parties (third party content), which are products of intellectual activity and are protected in accordance with the legislation of the Russian Federation.`,
        AdminNotResponsible: `6.3.2. The Administration is not responsible for any information posted on third-party websites to which the user gets access to through the website or through the content of third parties, and does not check for compliance with any requirements.`,
        LinksOrManuls: `6.3.3. Links or manuals on downloading files and/or installing third-party programs posted on the site do not mean that the Administration supports or approves of these actions.`,
        LinkToAnySiteProduct: `6.3.4. A link to any site, product, service, any commercial or non-commercial information posted on the site does not mean the approval or recommendation of these products (services) by the Administration.`,
        IfUserGoesThirdParty: `6.3.5. If the user goes to third-party websites or uses or installs third-party programs after leaving the site, the site is not responsible for any further actions of the user, and from this moment these Rules no longer apply to the user.`,
        OpertaionNewnextSite: `7. Operation of the newnext Website and responsibility for its use`,
        UsersResponsible: `7.1. Users are responsible:`,
        ForTheirOwnActions: `- for their own actions in connection with the creation and placement of information on their personal page on the website,`,
        ForActionsRelatedPlacement: `- for actions related to the placement of information on the personal pages of other users and in other sections of the site in accordance with the current legislation of the Russian Federation. Violation of these Rules and the current legislation of the Russian Federation entails civil, administrative and criminal liability.`,
        AdminDoesNotParticipate: `7.2. The Administration does not participate in the formation of the content of the users’ personal pages and does not control and is not responsible for the actions or inaction of any persons in relation to the use of the site or the formation and use of the content of the users' personal pages on the site, but only provides a technical opportunity to use the site.`,
        SiteAdminCanImplement: `7.3. The site Administration can implement technical solutions that carry out automatic censorship and control of actions and information relations of users on the use of the site, violations to the rights of third parties, and to the results of intellectual activity.`,
        AdminReservesRightToChange: `7.4. The Administration reserves the right to change the design of the site, its content, functionality, modify or supplement the scripts, software and other objects used or stored on the site, any server applications at any time with or without prior notice.`,
        SiteAdminNotEngaged: `7.5. The site Administration is not engaged in pre-moderation or censorship of the information contained on the personal pages of users, and takes action to protect the rights and interests of persons and ensure compliance with the requirements of the legislation of the Russian Federation only after there is the claim (request, complaint) in the prescribed manner of the person concerned to the site Administration.`,
        SiteaDminHasRightChange: `7.6. The site Administration has the right to change (moderate), block or delete any information published by the user that violates the prohibitions established by these Rules at its own discretion, as well as when receiving information from other users or third parties about the violation of these Rules by the user. The site Administration reserves the right to suspend, restrict or terminate the user's access to any of the sections or functionality of the site at any time for any reason or without explanation, with or without prior notice, as well as the site as a whole.`,
        SiteAdminDelete: `The site Administration has the right to delete the user's personal page and/or suspend, restrict or terminate the user's access to any of the site functionality, if the Administration finds that in its opinion, the user poses a threat to the site and / or its Users.`,
        SiteAdminBlock: `The site Administration has the right to block and / or remove API applications, restrict users' access to API applications, websites, third-party applications, other third-party resources, remove links to them, if the site Administration has reason to believe that they represent or may pose a threat to the normal operation of the site and its users.`,
        SiteAdminImplementsMeasures: `The site Administration implements the measures described above in accordance with the applicable law and is not responsible for possible negative consequences of such measures for the user or third parties.`,
        RemovalPersonalPage: `7.7. The removal of the user's personal page means automatic removal of all information posted on it, as well as all information entered by the user when registering on the site. After deleting the personal page, the user loses access to the use of the site.`,
        SiteAdminEnsures: `7.8. The site Administration ensures the functioning and operability of the site and endeavors to promptly restore its operability in the event of technical failures and interruptions.`,
        SiteAdminNotResponsible: `The site Administration is not responsible for temporary failures and interruptions in the work of the site and the consequences of loss of information caused by them.`,
        AdminNotResponsibleAnyDamage: `The Administration is not responsible for any damage to the user's computer or any other person, mobile devices, any other equipment or software caused by or related to the downloading of materials from the site or links posted on the site.`,
        SiteADminDispose: `7.9. The Site Administration has the right to dispose statistical information related to the operation of the site, as well as user information to ensure the targeted display of advertising information to various audiences of site users.`,
        SiteAdminHasSend: `7.10. The Site Administration has the right to send the user information about the development of the site and its functionality, as well as to advertise its own activities and services.`,
        LimitationLiability: `7.11. Limitation of liability of the Site Administration:`,
        WEBSITEFUNCTIONALITY: `7.11.1. THE WEBSITE AND ITS FUNCTIONALITY, INCLUDING ALL SCRIPTS, APPLICATIONS, CONTENT AND WEBSITE DESIGN ARE PROVIDED ‘AS THEY ARE’. THE ADMINISTRATION DISCLAIMS ALL WARRANTIES THAT THE SITE OR ITS FUNCTIONALITY MAY OR MAY NOT BE SUITABLE FOR SPECIFIC USES. THE ADMINISTRATION CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SITE AND/OR ITS FUNCTIONALITY;`,
        USERSHOULDTAKE: `7.11.2. THE USER SHOULD TAKE PRECAUTIOUS MEASURES WHEN DOWNLOADING FROM THE WEBSITE OR VIA THE LINKS ON IT AND USING ANY FILES, INCLUDING SOFTWARE. THE WEBSITE ADMINISTRATION STRONGLY RECOMMENDS USING ONLY LICENSED, INCLUDING ANTI-VIRUS SOFTWARE;`,
        BYUSINGWEBSITE: `7.11.3. BY USING THE WEBSITE, THE USER AGREES THAT BY DOWNLOADING FROM THE SITE OR USING ANY MATERIALS IS AT HIS/HER OWN RISK AND HE/SHE PERSONALLY RESPONSIBLE FOR THE CONSEQUENCES OF USE OF THESE MATERIALS, INCLUDING FOR DAMAGE WHICH MAY BE CAUSED TO THE USER'S COMPUTER OR THIRD PARTIES, FOR LOSS OF DATA OR ANY OTHER DAMAGE;`,
        UNDERNOCIRCUMSTANCES: `7.11.4. UNDER NO CIRCUMSTANCES WILL THE WEBSITE ADMINISTRATION OR ITS REPRESENTATIVES BE LIABLE FOR THE USER OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, UNINTENTIONAL DAMAGE, INCLUDING LOST PROFITS OR LOST DATA, HARM THE HONOR, DIGNITY OR BUSINESS REPUTATION CAUSED IN CONNECTION WITH USE OF THE SITE, SITE CONTENT OR OTHER MATERIALS TO WHICH YOU OR OTHER PERSONS GOT ACCESS WITH THE HELP OF THE WEBSITE, EVEN IF THE SITE ADMINISTRATION WARNED OR POINTED THE POSSIBILITY OF SUCH DAMAGE.`,
        FinalProvision: `8. Final provision`,
        TheseTermsAreAgreement: `8.1. These Terms are an agreement between the user and the site Administration regarding the use of the site and its functionality.`,
        RulesShallBeGoverned: `8.2. These Rules shall be governed by and construed in accordance with the laws of the Russian Federation. Issues not regulated by the Rules shall be resolved in accordance with the legislation of the Russian Federation.`,
        InCaseAnyDisputed: `8.3. In case of any disputes or disagreements related to the execution of these Rules, the user and the site Administration will make every effort to resolve them through negotiations between them. If disputes are not resolved through negotiations, the disputes shall be settled in the manner established by the current legislation of the Russian Federation.`,
        RulesShallEnterInto: `8.4. These Rules shall enter into force for the user from the moment of its accession to them and shall be valid for an indefinite period.`,
        RulesAreWrittenRussian: `8.5. These Rules are written in Russian and can be provided to the user for review in another language. In case of any discrepancies between the Russian version of the Rules and the version of the Rules in another language, the Russian version of these Rules will be applied.`,
        IfForAnyReason: `8.6. If, for any reason, one or more provisions of these terms are found to be invalid or unenforceable, this shall not affect the validity or enforceability of the remaining provisions.`,


        GlobalBusiness: `Global business network guide newnext (newnext-guide)`,
        NewnextGuide: `Newnext-guide is a guide that can help users of the global business network newnext get answers to frequently asked questions on the use of newnext functionality.`,
        GENERALQUESTIONS: `GENERAL QUESTIONS`,
        WhoToContact: `Who to contact if you have sent a Support request but have not received a response?`,
        WeWillDefinitelyAnswer: `We will definitely answer all the questions you have asked in the order of the queue. The answer will come to your email within 7 days.`,
        IfYouHaveNotReceived: `If you have not received a response to your request within 7 days, please check your email address specified during registration by going to the ‘Edit profile’ section.`,
        DontAskUsTheSame: `Don't ask us the same question several times. The questions asked to the Support Service are not lost.`,
        WhatIfYourDataIs: `What if your data is used on someone else's page?`,
        IfAnyUserUsesYour: `If any user uses your first name, last name, photo or other data on the page, please contact us to Support Service.`,
        InYourRequestPlease: `In your request, please provide the following information:`,
        FirstALinkToThe: `1. A link to the ‘impostor’ page.`,
        SecondADescriptionOfWhat: `2. A description of what exactly belongs to you (a main photo, information, a name, etc.).`,
        ThirdAPhotoOfYour: `3. A photo of your identity document.`,
        FourthAPhotoThatShowsYour: `4. A photo that shows your face with your request in the background (next to the monitor so that your request could be easily seen).`,
        YouMustUnderstandThatAll: `You must understand that all requested data is necessary for us to be sure that the true owner of these photos is the one who is addressing us.`,
        HowToChangeTheSize: `How to change the size of page or font size?`,
        PleasePressCTRL0: `Please press Ctrl+0 (hold down Ctrl and press zero) - the page will return to the standard size. Holding Ctrl and turning the mouse wheel, you can always choose a convenient scale.`,
        HowToTakeAScreen: `How to take a screenshot?`,
        ForWindows: `For Windows:`,
        PressThePrtScrKeyIn: `Press the PrtScr key in the upper right corner of the keyboard. Then open Start → All programs → Accessories → Paint, select "Paste"’. Save the file as PNG or JPG.`,
        ForOSX: `For OS X:`,
        CommandShiftThree: `Command + Shift + 3: full screen to file on desktop.`,
        CommandShiftFour: `Command + Shift + 4: select area to file.`,
        CommandShiftFourSpace: `Command + Shift + 4 + space: window of the program to the file.`,
        ToSaveToTheClipboard: `To save to the clipboard you need to hold Ctrl.`,
        WhoToContactIfYouAre: `Who to contact if you are threatened with complaints or blocking?`,
        YouNeedToContactTheSupport: `You need to contact the Support Service and describe the situation.`,
        PleaseNoteThatOnlyTheSite: `Please note that only the Site Administration can block a user.`,
        IncommingComplaintsAre: `Incoming complaints are checked by the site moderators manually. Unsubstantiated complaints will be dismissed.`,
        HowToRefreshThePage: `How to refresh the page?`,
        PressTheF5: `Press the F5 button in the top row of the keyboard and the page will refresh.`,
        WhereCanIReadTheTerms: `Where can I read the terms of use for newnext?`,
        YouNeedToLinkTo:  {text: `You need to link to: `, link: {text: 'newnextglobal.com/terms.', url: '/terms'}},
        WhereCanIReport: `Where can I report a website error?`,
        IfYouFindAnError: `If you find an error in the Website and want to tell us about it, please report it to the Support Service.`,
        InOrderTo: `In order to understand the error detected by you completely, please indicate the steps to reproduce the problem and attach screenshots to your request.`,
        HowCanIChangeTheTime: `How can I change the time settings on the Website?`,
        OurWebsiteUses: `Our website uses your device's time settings, so you need to check and install them yourself.`,
        WeRecommendThat: `We recommend that you turn automatic time zone detection on.`,
        WhereToGoIfYou: `Where to go if you are deceived or how to deal with scams?`,
        NewNextIsAPlatformThat: `Newnext is a platform that includes users who provide products and services. We grant you the right to use the services of our Website, but do not check the integrity of all users. We do not have the tools to confirm or deny the information about the products and services provided by users.`,
        IfYouAreAVictim: `If you are a victim of fraud, we strongly advise you to contact law enforcement`,
        DESCRIPTIONOFNEWNEXT: `DESCRIPTION OF NEWNEXT`,
        WhatIsTheInternational: `What is the international global business network newnext?`,
        NewnextIsTheWorlds: `Newnext is the world's first international global business network that serves as a platform for business, bringing together users into a professional business community of interested people.`,
        NewnextAllowsYouToSolve: `Newnext allows you to solve the set business tasks and implement business ideas by purchasing and installing the necessary business connections and contacts within a reasonable time and with the minimum amount of resource costs.`,
        WhatServiceDoesNewnext: `What service does newnext provide to users?`,
        NewnextUsersAreProvided: `Newnext users are provided with a convenient service that allows:`,
        PotentialInvestors: `• potential investors to find projects for financial investments;`,
        UsersToPlaceProjects: `• users to place projects on the investment market to attract investment;`,
        UsersToFindTheBest: `• users to find the best deals on business products;`,
        UsersToFindGoods: `• users to find goods and products;`,
        UsersToLearnAbout: `• users to learn about the latest innovations from the world of innovations and high technologies;`,
        UsersToFindInterested: `• users to find interested users to implement innovative projects and inventions;`,
        UsersToReceive: `• users to receive the qualified support of experts and professionals from different sectors of the economy and business;`,
        UsersToFindCustomers: `• users to find customers and business partners;`,
        UserToFindQualified: `• users to find qualified personnel;`,
        UsersToFindJobs: `• users to find jobs that match qualifications and material needs;`,
        UsersToFormATeam: `• users to form a team to solve project tasks;`,
        UsersToImplement: `• users to implement crowdfunding projects, even through the use of attracting investments via ICO.`,
        NewnextHelpsUsersToDevelop: `Newnext helps users to develop their own business, earn real money, attract real investments, establish real business contacts.`,
        NewnextFunctionalCapabilities: `Newnext functional capabilities`,
        NewnextUsersHave: `Newnext users have the ability to:`,
        EstablishBusinnes: `• Establish business relationships that meet the interests of each type of user.`,
        AttractInvestments: `• Attract investments.`,
        FindProjectsForInvestment: `• Find projects for investment.`,
        CreateStoreAndModify: `• Create, store and modify personal information.`,
        ReceiveAListOfNewnext: `• Receive a list of newnext network members (potential contacts) automatically in accordance with the interests of each type of network member.`,
        ToObtainEasyTargeted: `• To obtain easy targeted search of contacts in accordance with the business goals of each type of the network participant.`,
        VisitAndViewPagesOf: `• Visit and view pages of other project participants.`,
        CreateAPersonalContact: `• Create a personal contact list.`,
        MaintainPersonalCorrespondence: `• Maintain personal correspondence with other members of the system.`,
        CommunicateWithOtherParticipants: `• Communicate with other participants in a convenient language (using automatic message translation)`,
        PlaceApllicationsWith: `• Place applications with the function of distribution to target groups of participants.`,
        USERPAGE: `USER PAGE`,
        HowToCreateAPage: `How to create a page?`,
        InOrderToCreateAPage: `In order to create a page in newnext, you need to perform the following set of actions:`,
        FirstFollowTheLink: {text: `1. Follow the link  `, link: {text: 'newnextglobal.com.', url: '/'}},
        SecondFillInReg: `2. Fill in ‘Registration’ on the main page: type of member, first name, last name, phone number, press ‘Register’.`,
        ThirdConfirmThePhone: `3. Confirm the phone number by verification code from SMS that came to your specified phone.`,
        ThePhoneNumberIsYour: `! The phone number is your username to log in newnext.`,
        FourthEnterAndConfirm: `4. Enter and confirm the password in the next window.`,
        AfterPerformingThe: `After performing the above actions, you are asked to select the category of newnext user – natural or legal person, fill in the fields of the application form corresponding to the selected user category, and press the ‘Send’ button.`,
        CanIDeleteMyPage: `Can I delete my page?`,
        TheUserPageCanBe: `The user page can be deleted only by the website Administration.`,
        YouCanSubmitA: `You can submit a request to delete your page to the Support Service.`,
        ATTENTIONItIsPossible:  { attention: 'ATTENTION!', text: 'It is possible to delete a page that cannot be accessed if the page contains the real first name and last name, as well as your photo on the avatar.'},
        InYourRequest: `In your request to the Support Service, you must attach a link to the page and two images:`,
        APhotoOfYourDocument: `- A photo of your document (a passport, a driver's license, a student card, a birth certificate, etc.).`,
        APhotoThatShows: `- A photo that shows your face with your request in the background (next to the monitor so that your question could be easily seen).`,
        OnlyInThisCaseWe: `Only in this case we can be sure that your request to Support Service has been sent by you.`,
        HowToRecoverA: `How to recover a forgotten password?`,
        IfYourPageIsLinkedToTheCurrent: `If your page is linked to the current phone number, you can change your password by pressing the ‘Forgot password’ / ‘Recover password’ button on the main page of the website.`,
        IfYourPageIsLinkedToAPhone: `If your page is linked to a phone number that is currently unavailable, you must contact Support Service.`,
        WhatDoesTypeOfMember: `What does ‘type of member’ mean?`,
        TypeOfMemberInNewnext: `‘Type of member’ in newnext is the main defining concept.`,
        DueToTheFactThatTheMain: `Due to the fact that the main task of newnext is to establish business relations between users, develop their own business, implement business projects, ideas, etc., ‘type of member’ allows us to provide information automatically for a particular User in the areas of interest to their business.`,
        ItIsThereforeRecommended: `It is therefore recommended that you consider carefully the choice of the ‘type of member’ during registration in newnext.`,
        WhyIsItNecessary: `Why is it necessary to choose a user category - legal or natural person?`,
        YouCanRegisterInNewnext: `You can register in newnext as a natural person (1) or as a legal person (2).`,
        InThefirstCaseAll: `In the first case, all your actions will be conducted on its own behalf.`,
        InTheSecondCaseYou: `In the second case, you register on behalf of the legal entity as its representative, and your actions are carried out within the framework of the legal entity.`,
        WeWouldLikeToPoint: `We would like to point out that depending on the category you have chosen, you are provided with the application form field of either a legal entity (person) or a natural person when filling out the member profile.`,
        WhatDoesTheIndustryField: `What does the ‘Industry’ field in the user application form mean?`,
        TheFieldIndustryInTheApllication: `The field ‘Industry’ in the application form reflects the industry of the main type of business of the user newnext.`,
        CanIChangeThePhone: `Can I change the phone linked to my page?`,
        YouCannotChangeThePhone: `You cannot change the phone linked to the page on your own, as the phone is your login to newnext.`,
        YouDoNotHaveArchivedRequests: 'You do not have archived requests.',
        IfThePhoneNumberProvided: `If the phone number provided during registration is no longer valid, you should contact the Support Service.`,
        HowToSetTheMainPhoto: `How to set the main photo (avatar)?`,
        OnTheLeftSideOf: `On the left side of the user page, click the ‘Upload photo’ button (maximum 1 MB).`,
        DoYouWantToGetALink: `Do you want to get a link to your page?`,
        ToGetALinkToYour: `To get a link to your page, you need to copy the profile address from the browser's navigation bar, which is located at the top of the window.`,
        InThisCaseTheCharacter: `In this case, the character set after ‘.../profile/’ in the address bar reflects Your ID number.`,
        WhatDoesVerified: `What does ‘verified’ page mean?`,
        VerifiedPageMeansThat: `‘Verified’ page means that the page has been verified - checked for authenticity and compliance with the person or organization.`,
        ThePresenceOfACheck: `The presence of a check mark to the right of the User name is a confirmation that you are not a clone.`,
        WhatNewsWillISee: `What news will I see on my page?`,
        OnTheUserPageThe: `On the user page the news section displays the following information:`,
        WhetherYouHaveBeen: `- whether you have been added to contacts by newnext Users,`,
        PlacingOrdersByUsers: `- placing orders by users from your contact list.`,
        CanIViewTheNewsOn: `Can I view the news on someone else's page?`,
        NoTheNewsOnSomeoneElses: `No. The news on someone else's page is available only to the owner of that page.`,
        HowToChangePersonal: `How to change personal data in the application form?`,
        YouCanChangeTheData: `You can change the data in the application form by going to the ‘edit profile’ section.`,
        ItIsImportantToRemember: `It is important to remember that you cannot change the phone number (login - username), member category and type of member.`,
        CONTACTS: `CONTACTS`,
        HowToAddAUser: `How to add a user to my contact list?`,
        AfterYouHaveVisitedThe: `After you have visited the page of the user you are interested in, press the ‘+ Contact’ button under the avatar. After that the comment ‘the User has been successfully added to the contact’ will appear.`,
        YouCanAlsoAddAUser: `You can also add a user to the contacts on your page by pressing the ‘+ Contact’ key under the Username in the ‘Recommended’ section.`,
        IfTheUserInTurn: `If the user, in turn, also added you to the contacts, you will receive a notification in the ‘Contact news".`,
        CanIFindAUserByPhone: `Can I find a user by phone number?`,
        ItIsImpossibleToFind: `It is impossible to find a User by phone number in newnext.`,
        HowToFindAUserById: `How to find a user by his ID?`,
        PageIDIsTheNumberThat: `Page ID is the number that the User receives when registering in newnext.`,
        TheUserPageCanBeFound: `The user page can be found if you know the ID. To do this, type in the browser newnextglobal.com.profile/idXXX, where XXX is the desired ID number.`,
        WhyDoINeedToAdd: `Why  do I need to add a user to your contact list?`,
        NewnextIsAnInternational: `Newnext is an international global business network that will help you establish the necessary business contacts and connections.`,
        EachUserOfNewnext: `Each user of newnext corresponds to ‘member type’ selected during the registration. According to their member type, users form a list of applications for the promotion of their business interests.`,
        InformationAbout: `Information about all applications generated by users from your contact list will be sent to your news feed.`,
        HowWillTheUserKnow: `How will the user know that I have added him/her to my contact list?`,
        IfYouHaveAddedTheUser: `If you have added the user to the contact list on his/her page on the top above the section ‘Contacts’, the notification will light up, that is, the user who added it to his/her contacts.`,
        ByClickingTheContacts: `By clicking the ‘Contacts’ link at the top of the page, the user opens the section of the same name, where, by going to the "Requests" section, he gets a list of users who have added him as a contact`,
        InAccordanceWithTheList: `In accordance with the list, the user can, by pressing the ‘Accept’ button, add you to his contacts, and a notification will be sent to you in the ‘news’ section.`,
        HowCanIRemoveAUser: `How can I remove a user from my contacts?`,
        AfterYouHavevisitedThePageOf: `After you have visited the page of the user you are interested in, press the ‘x  Remove from contacts’ button under the avatar, after which you will be asked to confirm the removal action. By pressing ‘Yes’, the user will be removed from your contact list and you will receive a comment ‘User has been successfully removed from the contacts’.`,
        YouCanAlsoRemoveAUser: `You can also remove a user from the contacts on your page by clicking on the ‘Contacts’ link and pressing the ‘ x Delete" key in the opened contact list next to the User you want to remove from the contacts.`,
        HowWillTheUserKnowThatIDeleted: `How will the user know that I deleted/removed him/her from my contact list?`,
        ThereWillNotBeAny: `There will not be any notifications that the user has been removed from your contact list.`,
        HowManyUsersCanIHave: `How many users can I have in my contact list?`,
        TheNumberOfUsersInThe: `The number of users in the contact list is not limited.`,
        WhyDoesEachUser: `Why does each user in the contact list correspond to a ‘user type’?`,
        NewnextWillHelp: `Newnext is an international global business network that will help you establish the necessary business contacts and connections.`,
        EachNewnextUserCorresponds: `Each newnext user corresponds to their ‘type of member’ selected during the registration.`,
        AccordingToTheirMemberType: `According to their member type, users form a list of applications for the promotion of their business interests.`,
        WhenYouOpenAContact: `When you open a contact list, the ‘user type’ information gives you basic business-oriented user information.`,
        MESSAGES: `MESSAGES`,
        HowToWrite: `How to write and send a message?`,
        YouCanSend: `You can send a message to the newnext user by going to the user's page and pressing the ‘Send a message’ button located under the avatar.`,
        AfterYouFinished: `After you have finished typing the message it will be sent to the user by pressing the ‘Send’ button.`,
        YouCanSendAMessage: `You can send a message to a contact from your list by pressing the ‘Message’ key in the corresponding line of the contact list.`,
        AreThereAnyLimits: `Are there any limits for sending messages?`,
        ThereAreNoLimits: `There are no limits on the size and number of messages in newnext.`,
        HowToDeleteAMessage: `How to delete a message?`,
        YouCanDeleteAMes: `You can delete a message by pressing the ‘x’ key located opposite the message you are interested in.`,
        HowCanIDeleteEntireDialog: `How can I delete the entire dialog?`,
        ItIsPossibleToRemove: `It is possible to remove a dialogue entirely by pressing the ‘x’ key located next to the chosen dialogue.`,
        HowCanIRecover: `How can I recover a deleted message or a deleted dialogue?`,
        YouCannotRecover: `You cannot recover messages or dialog that you have deleted.`,
        PleaseNoteThatDeleting: `Please note that deleting a message is a one-way action, and the messages remain on the recipient’s page, even if you have deleted them on your page.`,
        IfYouNeedInformation: `If you need information from deleted messages, contact the recipient, and if his/her messages are not deleted, he/she will send you the necessary information from the messages or send the entire dialogue.`,
        HowToInsertEmoticons: `How to insert emoticons into a message?`,
        YouCanUseEmoticons: `You can use emoticons in personal correspondence. An icon is displayed in the corner of the text input field, when you hover over it you will see a list of all available emoticons.`,
        AlsoYouCanEnterEmoticons: `Also, you can enter emoticons from the keyboard: many text emoticons (for example  «:-)» or «<3») are automatically converted to graphic ones.`,
        PleaseBeCarefulAndDoNotInstall: `Please be careful and do not install programs that promise to add to our set more new emoticons (memes, trollface and so on). They were not created by us, it often happens that passwords are stolen and spam can be sent on Your behalf.`,
        IfThereIsNoNecessary: `If there is no necessary emoticon`,
        WeUserEmoticons: `We use emoticons of Emoji standard.`,
        HowCanICancel: `How can I cancel sending a message?`,
        YouCannotCancelASent: `You cannot cancel a sent message.`,
        WhyIsThereNo: `Why is there no ‘Send a message’ button on the user page?`,
        IfTheUsersPageDoesNot: `If the user's page does not have a “Send message’ button, it means that the user has limited his / her social circle and has apparently put you on a ‘black list’.`,
        HowCanIPrevent: `How can I prevent a user from sending me messages?`,
        YouCanLimitTheCircle: `You can limit the circle of communication with a user by placing him/her in the ‘black list’.`,
        HowCanIKnowThatTheRecipient: `How can I know that the recipient has read the message I sent?`,
        AllUnreadMessagesAre: `All unread messages are highlighted with a tinted background.  If the message is displayed on a white background, then it has been read by the recipient.`,
        // Notice: `NNoffer`,
        WhatDoesNnnotice: `What does NNoffer mean?`,
        NewnextIsAnInternational2: `Newnext is an international global business network that will help you establish the necessary business contacts and connections.`,
        EachNewnextUserCorresponds2: `Each newnext user corresponds to their ‘member type’ selected during the registration. According to their member type, users form a list of NNoffer for the promotion of their business interests.`,
        DependingOnYourUserType: `Depending on your ‘user type’ you may create NNoffer in which you place detailed information reflecting the business direction you are offering.`,
        HowToMakeNNnotice: `How to make NNoffer?`,
        InOrderToMakeNNnotice: `In order to make NNoffer you need to go to the ‘NNoffer’ section and click ‘Create a new NNoffer".`,
        YouWillBeAsked: `You will be asked to complete an NNoffer form corresponding to your ‘user type’.`,
        WhatAreNetwortNnnotice: `What are  ‘Network NNoffer’?`,
        ThisIsAListOf: `This is a list of NNoffer of all newnext Users.`,
        HowCanIAddAnImage: `How can I add an image to my NNoffer?`,
        InOrderToAddAn: `In order to add an image to  NNoffer, upload the desired image to the file-sharing site (for example dropmefiles.com), and form a link to it.`,
        AddTheGenerated: `Add the generated link to the ‘Additional information’ field.`,
        WhatIfIWantToAdd: `What if I want to add additional information to  NNoffer, but there is no special field?`,
        IfThereIsNoSpecial: `If there is no special field for placing your information in the NNoffer form, use the ‘Additional information’ field.`,
        WhatDoesTheIndustryFieldNnotice: `What does the ‘Industry’ field mean in NNoffer?`,
        TheFieldIndustry: `The field ‘Industry’ in the application reflects the special industry of the user's NNoffer.`,
        WhatDoesNNnoticeHasNotBeen: `What does ‘NNoffer has not been published’ mean?`,
        AfterYouHaveSavedNNnotice: `After you have saved NNoffer you created, the status field of  NNoffer will reflect the status ‘unpublished’. This means that your NNoffer is not available to other newnext users.`,
        IfYouWantToMake: `If you want to make  NNoffer available to users of newnext, select the command ‘Send for moderation’.`,
        WhatDoesTheNNnotice: `What does the NNoffer status mean?`,
        Unpublished: `«Unpublished»`,
        NNnoticeForSomeReason: `NNoffer for some reason is not sent to the user for moderation, and it is stored only on his page.`,
        BeingModerated: `"Being moderated»`,
        NNnoticeHasBeenSent: `NNoffer has been sent to the administration of newnext to check before making it available to newnext users.`,
        Refused: `«Refused»`,
        NNnoticeDidNotPassModeration: `NNoffer did not pass moderation and remained available only to the user who created it.`,
        Published: `«Published»`,
        NNnoticeIsAvailable: `NNoffer is available to all users of newnext.`,
        HowToSendNNnotice: `How to send NNoffer for verification (moderation)?`,
        InTheListOfNNnotice: `In the list of  NNoffer in front of NNoffer you are interested, click the far left button and in the pop-up window, select ‘Send for moderation’.`,
        WhyIsNNnotice: `Why is NNoffer archive necessary?`,
        TheNNnoticeArchive: `The NNoffer archive stores NNoffer that the user has removed from the NNoffer list, but has not deleted them, giving the possibility of recovery.`,
        HowToPutNNnotice: `How to put NNoffer to the archive and how to return NNoffer from the archive?`,
        IfYouNeedToPut: `If you need to put NNoffer to the archive, click the far left button in the list of NNoffer in front of  NNoffer you are interested, and in the pop-up window, select ‘Archive’ and confirm the action by pressing ‘Yes’.`,
        IfYouWantToReturn: `If you want to return NNoffer from the archive, in the archive section, hover over the cursor to select the desired NNoffer, then in the pop-up window, select ‘recover’ and confirm the action by pressing ‘Yes’.`,
        HowToFindNNnotice: `How to find NNoffer on the search criteria I set?`,
        InTheSection: `In the section ‘Network NNoffer’ there is a screen form ‘search NNoffer’ on the right side. After filling in the parameters you are interested in, you will receive a list of NNoffer corresponding to the parameters you have chosen.`,
        WhatIsICO: `What is ICO?`,
        ICOIsAForm: `ICO is a form of attracting investments in the form of selling to investors a fixed number of new units of cryptocurrencies (tokens).`,
        ICOIsACryptocurrency: `ICO is a cryptocurrency crowdfunding, search for money, for an idea, or a ready-made project, but unlike ordinary money, only a cryptocurrency is used here. As a rule, it is about new services and technologies that are in the final stage of creation or ideas with a prototype (a prototype of the final idea). In return for the money spent, the investor receives some amount of tokens, which, if successful, will increase in price.`,
        HowToCreateYourICO: `How to create your ICO in newnext?`,
        InOrderToCreateYourICO: `In order to create your ICO, select ‘Create a new NNoffer’ in the ‘NNoffer’ section, then ‘ICO’.`,
        AfterFillingInTheProposed: `After filling in the proposed form, press the ‘Send’ button.`,
        HowToContact: `How to contact the user-author of  NNoffer?`,
        TheUserAuthor: `The user-author of NNoffer is reflected on the list of newnext NNoffer – the user who placed NNoffer on newnext. By hovering over the author of the NNoffer and clicking the mouse button, you can get to the page of the user (author), where you can send a message or add to contacts.`,
        ACCESSTOTHEPAGE: `ACCESS TO THE PAGE`,
        RestoreAccessPage: `How to restore access to the page?`,
        FirstIfYouNeedRestore: `1. If you need to restore access to your раge in case of the password loss:`,
        IfYourPageCurrentPhone: `If your page is linked to a current phone number, you can change your password by pressing the ‘Forgot password’ / ‘Recover password’ button on the main page of the website.`,
        IfYourPagePhoneNumberThat: `If your page is linked to a phone number that is currently unavailable, you must contact Support Service.`,
        SecondAccessToThePage: `2.    Access to the page may be blocked by the website Administration for violation of the rules of use of the site.`,
        InThisCaseYouNeed: `In this case, you need to contact the Support Service, attaching the following documents:`,
        ALinkToBlocked: `- a link to blocked page,`,
        APhotoOfYourIdentity: `- a photo of your identity document,`,
        APhotoThatShowsYourFace: `- a photo that shows your face with your request in the background (next to the monitor so that your request could be easily seen).`,
        YouMustUnderstandThatAll2: `You must understand that all requested data is necessary for us to be sure that the true owner of these photos makes this request.`,
        YouForgotMyUsername: `You forgot my username and my password. What should I do? `,
        InNewnextTheUsername: `In newnext, the username (login) is the number of the linked phone (in international format).`,
        IfYourPageIsLinkedToTheCurrentPhone: `If your page is linked to the current phone number, you can change your password by pressing the ‘Forgot password’ / ‘Recover password’ button on the main page of the site.`,
        IfYourPageIsLinkedToAPhoneNumber: `If your page is linked to a phone number that is currently unavailable, you must contact Support Service.`,
        WhatToDoIf: `What to do if your page has been hacked?`,
        IfYourPageHasBeen: `If your page has been hacked, you must:`,
        FirstTryToRestoreAccess: {text: '1. Try to restore access to the page by clicking on the link:', link: {text: 'https://newnextglobal.com/recovery/.', url: '/recovery'}},
        SecondIfYouHaveProblems: `2. If you have problems with recovery, please contact the Support Service; make sure to provide a link to the page.`,
        ATTENTIONToAvoid: { attention: 'ATTENTION!:', text: `To avoid hacking in the future, it is necessary to link the page to the actual mobile phone number, as well as regularly scan the computer with an antivirus.`} ,
        WhatToDoIfPageBlocked: `What to do if your page has been blocked?`,
        TheNewnextUserPage: `The newnext user page can be blocked only by the site Administration for violation of the rules of use of the site.`,
        ATTENTIONRestoring: { attention: 'ATTENTION!:', text: `Restoring access to the blocked page is free, so if you are faced with the fact that you are offered to pay by informing the payment card data, or send an SMS somewhere-in any case, then do not take these actions, since surely you are dealing with scammers.`},
        HowToDeletePage: `How to delete my page?`,
        TheUserPageCan: `The user page can be deleted only by the site administration.`,
        YouCanSubmitARequest: `You can submit a request to delete your page to the support service.`,
        ATTENTIONDeletingPage: { attention: 'ATTENTION!:', text: `Deleting a page, which cannot be accessed, is possible if the page contains the real name and surname, as well as your photo on the avatar.`} ,
        InYourRequestToTheSupport: `In your request to the support service, you must attach a link to the page and two images:`,
        APhotoOfYourDocument2: `- A photo of your document (a passport, a driver's license, a student card, a birth certificate, etc.).`,
        APhotoThatShowsYourFace2: `- A photo that shows your face with your request in the background (next to the monitor so that your question could be easily seen).`,
        OnlyInThisCaseWe2: `Only in this case we can be sure that your request to the support service has been sent by you.`,
        WhatToDoCloseThePage: `What to do if you want to close the page of a user who is not alive?`,
        UnfortunatelyWeCannot: `Unfortunately, we cannot provide access to the page of a user who is not alive.`,
        WeCanEither: `We can either delete his/her page or block it.`,
        YouNeedToContact: `You need to contact the support service with a request, attaching a scan or a clear photo of the his/her death certificate, a document confirming the relationship, or other right to dispose of the page.`,
        YouCannotGoToTheSite: `You cannot go to the site as a page validation is required!`,
        IfYouCannot: `If you cannot go to newnext, and you are invited to send SMS or transfer money to some account, do not do it under any circumstances.`,
        NewnextDoesNot: `Newnext does not require any payments and/or sending messages from your phone to log in to newnext.`,
        IfThePageDoes: `If the page does not load, try from another computer.`,
        IfAfterThatTheProblem: `If after that the problem remains, please email to the support service.`,
        WhatToDoTeRemoveThe: `What to do if you want to remove the autosave password in the browser?`,
        YouNeedGoToSettings: `You need go to the ‘Settings’ of the browser you are using.`,
        ForGoogleChrome: `for Google Chrome:`,
        FirstInTheTopRight: `1. In the top right corner, click the ‘three bars’ icon.`,
        SecondSelectSettings: `2. Select ‘Settings’.`,
        ThirdClickShow: `3. Click ‘Show advanced settings’ at the bottom of the page and find the ‘Passwords and forms’ section.`,
        FourthUncheckThe: `4. Uncheck the ‘Enable autofill to enter data into web forms with one click’ checkbox.`,
        ForOpera: {text: `For Opera: `, link: {text: 'http://help.opera.com/opera/Windows/1284/ru/controlPa..', url: '/recovery'}},
        ForExplorer: {text: `For Internet Explorer: `, link: {text: 'https://support.microsoft.com/ru-ru/help/17499/windows..', url: 'https://support.microsoft.com/ru-ru/help/17499/windows-internet-explorer-11-remember-passwords-fill-out-web-forms#ie=ie-11'}},
        ForMozilla: {text: `For Mozilla: `, link: {text: 'https://support.mozilla.org/ru/kb/upravlenie-avtomati..', url: 'https://support.mozilla.org/ru/kb/upravlenie-avtomaticheskim-zapolneniem-form-vashej'}},
        ChangeThePhone: `What to do if you want to change the phone number linked to your page?`,
        YouCannotChangeThePhone2: `You cannot change the phone linked to the page on your own, as the phone is your username (login) to newnext.`,
        IfThePhoneNumberProvided2: `If the phone number provided during registration is no longer valid, you should contact the Support Service.`,
        WhatToDoIfYouWant: `What to do if you want to link your phone number to your page but it is busy by another user?`,
        InThisCaseYouNeedToContact: `In this case, you need to contact the Support Service by entering your phone number.`,
        InYourRequestToTheSupportService: `In your request to the support service, please send a link to the profile to which your phone number is linked, and state your version of what happened, which will speed up the solution of the issue.`,
        PRIVACYSETTINGS: `PRIVACY SETTINGS`,
        HowToAddBlackList: `How to add a user to the ‘black list’?`,
        InOrderToPutUser: `In order to put a user in the ‘black list’, go to the page of the user you are interested in and press the ‘ ... ‘ key located next to the user name. Next, in the drop-down menu, select ‘add to blacklist’ and confirm your actions by pressing the ‘Yes’ key.`,
        HowToRemoveUser: `How to remove a user from the ‘black list’?`,
        ToRemoveUser: `To remove a user from the "black list", click on your name in the upper right corner of the page, select ‘Settings’ section.`,
        TheInTheSettings: `Then, in the ‘Settings’ section, select the ‘black list’. Next to the chosen user press ‘Remove’ and confirm your action by pressing ‘Yes’.`,
        HowToLookThough: `How to look though the ‘black list’?`,
        ToViewTheListUsers: `To view the list of users you have placed in the ‘black list’, click on your name in the upper right corner of the page, select ‘Settings’ section.`,
        ThenChooseBlacklist: `Then choose ‘Black list’ in the ‘Settings’ section.`,
        WHyIsThereSendMessage: `Why is there no ‘Send a message’ button on a person's page?`,
        IfTheUserPagedoesnotSendMessage: `If the user's page does not have a ‘Send message’ button, it means that the user has limited his / her communication circle and has apparently put you in the ‘black list’.`,
        CanUsersSeeMyPhoneNumber: `Can users see my phone number (login-username)?`,
        NoneOfTheNewnextUsers: `None of the newnext users, except you, have access to your login and your password.`,
        CanISeeWhoPut: `Can I see who put me on the ‘black list’?`,
        ThereIsNoPossibility: `There is no possibility to see the list of users who put you on the ‘black list’.`,
        HowToDeleteMyPage: `How to delete my page?`,
        TheUserPageCanBeDeleted: `The user page can be deleted only by the website administration.`,
        YouCanSubmitARequest2: `You can submit a request to delete your page to the support service.`,
        CanISeeWhoVisitsPage: `Can I see who visits my page?`,
        NoYouCannotSeeVisitedPage: `No. You cannot see who visited your page.`,

        deleteAccount: 'Request to delete my profile',
        repareAccount: 'Cancel my profile removal request',
        deleteAccountLabel: 'Delete your profile',
        urlProfileLabel: 'URL profile',
        DeleteAccountModal: 'Do you really want to delete your account?',
        RestoreAccountModal: 'Restore account?',




        from: 'from',
        sec: 'sec',
        PasswordContainThanCharacters: 'Password can not contain less than 6 characters',
        deleteApp: 'Delete',
        restore: 'Restore',
        author: 'Author',
        ddmmyy: 'dd-mm-yyyy',
        dateTime: 'en',
        rub: 'rub',
        usd: 'usd',
        Yesterday: 'Yesterday',
        Today: 'Today',
        Hour: 'hour',
        Day: 'day',
        Week: 'week',
        You: 'You',
        MonthYear: 'month/year',
        Months: 'months',
        Month: 'month',
        SimilarContacts: 'Similar contacts',
        Message: 'Message',
        Years: 'years',
        Year: 'year',
        YearsOldOne: 'years',
        YearsOldTwo: 'years',
        YearsOldThree: 'years',
        MonthOldOne: 'month',
        MonthOldTwo: 'years',
        MonthOldThree: 'years',
        DescriptionInvestor: {one: 'An investor is a natural or legal person who invests his/her own funds in investment projects and products for the purpose of making a profit.', two: 'When investing money, the investor pursues the goal of making a profit from his investments and making money on investments. He/she assumes the sole (100%) investment, without co-investors.'},
        DescriptionCoinvestor: {one: 'A co-investor is a natural or legal person who makes investments of his/her own funds together with other persons investing funds in the same investment project.', two: 'For a co-investor, the purpose of participation in investing is to obtain material benefit, an increase in property, in other words - "profitability".'},
        DescriptionNovator: {one: 'An inventor is a natural or legal person who invents and implements some absolutely new progressive ideas and techniques in any field of activity.', two: 'The inventor is a natural or legal person engaged in intellectual activity, the results of which are new technologies, new in-production operations, new mechanisms, facilities, etc.'},
        DescriptionBusinessSeller: {one: 'The seller of business products is a natural or legal person offering business products.', two: 'Business products do not require investments in development or design, but are products or services that are offered on the market in finished form and meet the needs of customers.', three: 'This category of participants includes intermediaries and agents.'},
        DescriptionProvider: {one: 'The supplier delivers goods or services.', two: 'The supplier sets the quality characteristics of the supplied goods or services, as well as the parameters of the proposed transactions, in order to supply ', bold: 'goods or services to customers.'},
        DescriptionBusinessLady: {one: 'A business lady is a successful woman who has created a business idea and brought it to life; who has engaged in business, created commercial structures, oriented towards financial success.'},
        DescriptionProjectant: {one: 'Designer - a natural or legal person who has developed a project for implementation or offers services for the development and implementation of a project on the instructions of the customer.'},
        DescriptionUnemployer: {one: 'The freelancer – are natural persons with experience and qualifications in any field of activity and seeking to get a job and conclude a contract with an employer.'},
        DescriptionEmployer: {one: 'An employer is a legal entity (organization) who intends to enter into an employment relationship with an employee who has the required qualifications and work experience.'},
        DescriptionHRManager: {one: 'Personnel are a legal entity (organization) who have a relationship with the employer for the selection of personnel in accordance with established requirements and have the required qualifications and work experience.'},
        DescriptionExpert: {one: 'The expert is a highly qualified specialist in any field of knowledge, inclined to give his/her own opinion on any issues, opinions, and projects.'},
        Country: 'Country',
        YouMayInterested: 'You may be interested in',
        Russian: 'Russian',
        English: 'English',
        Authorization: 'Authorization',
        LogSocialNetworks: 'Log in via Social Networks',
        EnterLastName: 'Enter Last Name *',
        EnterTheCity: 'Enter The City *',
        EnterYourEmail: 'Enter your E-mail',
        EnterThePassword: 'Enter the password',
        RepeatPassword: 'Repeat Password *',
        AgreeWithDataProcessing: 'I agree with the data processing, etc.',
        PasswordsDifferent: 'Passwords are different',
        WritePasswordSureWillRemember: 'Write down the password if you are not sure that you will remember it',
        FirstNameEnteredIncorrectly: 'First name has been entered incorrectly',
        LastNameEnteredIncorrectly: 'Last name has been entered incorrectly',
        NoProfileTypeSelected: 'No profile type selected',
        NoDialogs: 'No dialogs',
        SearchDialog: 'Search dialog',
        NoNews: 'No news',
        NoRecommends: 'No Recommends',
        Notice: 'NOTICE',
        Warning: 'Warning!',
        Cancel: 'Cancel',
        NewMessage: 'New message',
        ShowAllResults: 'Show all results',
        SingleUnmarried: 'Single / Unmarried',
        Married: 'Married',
        MarriedSex:{ one: 'Married', two: 'Married'},
        SingleUnmarriedSex: { one: 'Single', two: 'Unmarried'},
        ChooseUserCategory: 'Choose the user category',
        LegalEntity: 'Legal entity',
        NaturalPerson: 'Individual',
        LegalEntityIndividual: 'Legal entity/Individual',
        AtPresentTime: 'At the present time',
        YouHaveNotEnteredLastName: 'You have not entered last name',
        YouHaveNotEnteredFirstName: 'You have not entered first name',
        YouChosenGender: 'You have not chosen a gender',
        YouSpecifiedDateBirth: 'You have not specified a date of birth',
        YouUnderYearsAge: 'You are under 18 years of age',
        YouSpecifiedCountryRegion: 'You have not specified country/region/city',
        YouSpecifiedACity: 'You have not specified a city',
        YouSpecifiedEmail: 'You have not specified an email',
        ChooseYourGenders: 'Choose your gender',
        Male: 'Male',
        Female: 'Female',
        YouHaveNotSpecifiedPhone: 'You have not specified a phone',
        CompanyNamed: 'Company name',
        ViewDetails: 'View details',
        Hide: 'Hide',
        // MoreDetails: 'More details',
        SpecifyDetails: 'Specify details',
        ChooseARegion: 'Choose a region',
        ChooseACity: 'Choose a city',
        NoSimilarContactsFound: 'No similar contacts found',
        Contact: 'Contact',
        AnErrorRecoveringPassword: 'An error occurred while recovering your password',
        ChooseMemberType: 'Choose a member type',
        login: 'Login',
        Password: 'Password',
        EnterButton: 'Enter',
        ChooseCountry: 'Choose a country',
        ChooseIndustry: 'Choose an industry',
        MyContacts: 'My contacts',
        NewsAboutContacts: 'News about contacts',
        FriendshipApproved: 'Friendship approved',
        AddingContactApproved: 'Adding a contact is approved',
        MinimizeButton: 'Minimize button',
        ContactSearch: 'Contact search',
        ChooseCurrency: 'Choose currency',
        ChooseInvestmentIndustry: 'Choose an investment industry',
        YouHaveAmountExpectedInvestment: 'You have not specified the amount of the expected investment',
        YouSpecifiedInvestmentIndustry: 'You have not specified an investment industry',
        YouSpecifiedInvestmentRegion: 'You have not specified an investment region',
        ChooseTypePayment: 'Choose the type of payment',
        YouSpecifiedPosition: 'You have not specified a position',
        YouSpecifiedSalaryWage: 'You have not specified salary/ wage',
        YouSpecifiedDescription: 'You have not specified a description',
        YouSpecifiedName: 'You have not specified a name',
        YouSpecifiedIndustry: 'You have not specified an industry',
        YouIndicatedAmountExpectedInvestment: 'You have not indicated the amount of the expected investment',
        YouSpecifiedCoinvestmentIndustry: 'You have not specified a co-investment industry',
        YouSpecifiedCoinvestmentRegion: 'You have not specified a co-investment region',
        ChooseCoinvestmentIndustry: 'Choose a co-investment industry',
        PeriodCoinvestment: 'Period of co-investment',
        YouNnotSpecifiedIndustry: 'You have not specified an industry',
        ChooseImplementationIndustry: 'Choose the implementation industry',
        ChooseOneOptions: 'Choose one of the options',
        TheChoiceVacancyResume: 'The choice of a vacancy or Resume (CV)',
        YouHaveNotSpecifiedYourName: 'You have not specified your name',
        YouSpecifiedIntendedPlaceWork: 'You have not specified your intended place of work',
        AdditionalItems: 'Additional items',
        YouSpecifiedCompanyName: 'You have not specified a company name',
        YouSpecifiedWorkExperienceApplicant: 'You have not specified the work experience of the applicant',
        YouSpecifiedFormPayment: 'You have not specified a form of payment',
        YouSpecifiedDeliveryType: 'You have not specified a delivery type *',
        ChooseTypeDelivery: 'Choose the type of delivery',
        DeliveryType: 'Delivery type',
        UnitMeasurement: 'Unit of measurement',
        YouSpecifiedTypeService: 'You have not specified a type of service',
        YouNotSpecifiedFieldWork: 'You have not specified a field of work',
        FieldWork: 'Field of work',
        // Experience: 'Experience',
        YouHaveSpecifiedIncome: 'You have not specified your income',
        YouHaveNotSpecifiedRegion: 'You have not specified a region',
        YouHaveSpecifiedAcompanyName: 'You have not specified a company name',
        YouHaveNotSpecifiedAcompanyType: 'You have not specified a company type',
        YouSpecifiedSoftCap: 'You have not specified a Soft Cap',
        YouSpecifiedHardCap: 'You have not specified a Hard Cap',
        YouSpecifiedStartTime: 'You have not specified Start Time',
        YouSpecifiedEndTime: 'You have not specified End Time',
        YouSpecifiedShortDescription: 'You have not specified a short description',
        YouSpecifiedFullDescription: 'You have not specified a full description',
        UploadImage: 'Upload image',
        LargeFileSize: 'Large file size',
        UploadImageBtn: 'Upload image',
        CompanyName: 'Company name',
        TypeApplications: 'Type of NNoffer',
        TypeApplication: 'Type of NNoffer',
        ChangeTypeApplication: 'Choose type of application',
        SectorAffiliation: 'Sector affiliation',
        MoreDetails: 'More details',
        WaitingStart: 'Waiting to start',
        InProcess: 'In process',
        Finished: 'Finished',
        Reset: 'Reset',
        YourApplicationHasSentModeration: {one: 'Sent successfully!', two: 'Your NNoffer has been sent for moderation.'},
        AddContact: 'Add a contact',
        btnAdd: 'Add',
        btnAddMore: 'Add more',
        Reject: 'Reject',
        Rejected: 'Decline',
        YouHaveNoContacts: 'You have no contacts',
        YouHaveNoRequests: 'You have no requests',
        description: {text: 'The global business network newnext has been created for those', one: 'Who need to establish new business communications;', two: 'Find interesting business projects;', three: 'Offer their own business products;', four: 'Contact sellers and suppliers.'},
        registration: 'Registration',
        userCategory: 'User Category',
        userCategoryss: 'User Category',
        investor: 'Investor',
        coinvestor: 'Co-investor',
        novator: 'Inventor',
        sellerBusiness: 'The seller of the business products',
        supplier: 'Supplier',
        businessWoman: 'Businesswoman',
        designer: 'Designer',
        unemployed: 'Freelancer',
        employer: 'Employer',
        hrManager: 'HR manager',
        expert: 'Expert',
        admin: 'Admin',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        clickRegister: {text_1: 'By clicking \'Register\' you accept ', link_1: 'the terms of our User Agreement and', link_2: 'Privacy Policy', text_2: ' and ', link_3: 'the Policy of use of newnext cookies.', text_3: ' '},
        register: 'Register',
        aboutProject: 'About the project',
        rules: 'User Agreement',
        advertisement: 'Advertisement',
        technicalSupport: '24/7 technical support',
        fillField: 'Fill in the field',
        errorPhone: 'The phone number is wrong',
        forgotPassword: { one: 'Forgot password?', two: 'It can be quickly', three: 'restored'},
        language: 'Language',
        fullNumber: 'Enter the  telephone number',
        phoneConfirmation: {one: 'Phone', two: 'confirmation'},
        fullName: 'Enter full name *',
        codeSMS: 'Enter the received code from the SMS.',
        codeFromSMS: 'The code from the SMS',
        yourPhone: 'Your phone number',
        codePhone: 'If the code does not come within 2 minutes, check the entered phone number.',
        recoverPassword: {one: "Couldn't recover your password?", two: 'Contact us'},
        resendCode: 'Resend code',
        codeIncorrect: 'The code is incorrect',
        wrongLogin: 'Wrong login (username) and password',
        createPassword: 'Create a password',
        yourLogin: 'Your login',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        save: 'Save',
        createPasswordLong: 'Create and enter a password that is at least 6 characters long. ',
        securePassword: 'The secure password must contain letters, numbers, and symbols.',
        errorRegistration: 'An error occurred during registration',
        forgotPasswordSmall: 'Forgot password?',
        sendTheCode: 'Send the code',
        indicatePhone: 'Please indicate the phone number you used to access the site.',
        sendFreeSMS: 'We will send you a free SMS with a confirmation code. ',
        passwordRecovery: 'Password recovery',
        applicationLegalEntity: 'Application form for legal entity',
        naming: 'Name',
        industry: 'Industry',
        periodWork: 'Period of work on the market',
        countryResidence: 'Country of residence *',
        regionResidence: 'Region',
        cityResidence: 'City',
        website: 'Website',
        education: 'Education',
        loading: 'Loading',
        educationalInstitution: 'Educational institution',
        yearStart: 'Year of begin',
        yearGraduation: 'Year of graduation',
        youCantSendMessage: 'You can\'t send message cause user is blocked or block you',
        youCantSendEmptyMessage: 'You can\'t send empty message',
        youNeedHaveCareers: 'You need have careers',
        youNeedHaveEdu: 'You need have education',
        academicSpeciality: 'Academic Speciality/Major',
        career: 'Career',
        placeWork: 'Place of work',
        expWork: 'Experience of work',
        workExperience: 'Work experience (years)',
        additionalInformation: 'Additional information',
        maritalStatus: 'Marital status',
        selectFamilyStatus: 'Select marital status',
        hobby: 'Hobby',
        chooseHobby: 'Choose a hobby',
        additionalInformationBlock: 'Additional information',
        uploadedPhoto: 'Upload photo',
        notMoreMB: 'Not more than 1 MB',
        profileUpdated: 'Your profile has been successfully updated',
        questionnaireIndividual: 'Questionnaire for an individual',
        patronymicName: 'Second name',
        sex: 'Sex',
        dateBirth: 'Date of birth',
        recommend: 'Recommend',
        request: 'NNoffer',
        chatRequest: 'Chat of request',
        noEntries: 'No NNoffer',
        networkRating: 'Network rating',
        aboutMe: 'About me',
        recommends: 'Recommend',
        search: 'Search',
        scrollUp: 'Minimize button',
        extended: 'Extended',
        selecteType: 'Select type',
        selectList: 'Select from the list',
        countryCity: 'Country, city',
        age: 'Age',
        find: 'Find',
        nothingFound: 'Nothing was found',
        addingContact: 'Adding a contact is approved',
        newApplication: 'New NNoffer',
        allContacts: 'All contacts',
        writeMessage: 'Send a message',
        removeContacts: 'Remove from contacts',
        remove: 'Remove',
        removeDialog: 'Remove dialog',
        messageRequest: 'The message on NNoffer',
        myProfile: 'My profile',
        editProfile: 'Edit profile',
        exit: 'Exit',
        information: 'Messages',
        news: 'News',
        contacts: 'Contacts',
        requests: 'Requests',
        myDialogues: 'Chat',
        writeMessageS: 'Type a message',
        deleteMessage: 'Do you want to delete the message',
        messageSuccessfullyDeleted: 'The message was successfully deleted',
        send: 'Send',
        searchFullName: 'Search by full name',
        confirmAction: 'Confirm the action',
        removeContact: {one: 'Do you want to remove', two: 'from your contacts? '},
        yes: 'Yes',
        no: 'No',
        removedContacts: 'was successfully removed from contacts',
        addedContacts: 'was successfully added to contacts',
        createNewRequest: 'Create new NNoffer ',
        closeNewRequest: 'Close new NNoffer',
        cancel: 'Cancel',
        myApplication: 'My NNoffer',
        networkRequests: 'Network NNoffer',
        archive: 'Archive',
        coverCard: 'Cover card of the company ( minimum width of 810 px)',
        campaignType: 'Campaign Type',
        shortDescription: 'Short description ( up to 250 characters )',
        fullDescription: 'Full description',
        applicationSuccessfullySent: 'Your NNoffer has been successfully sent',
        unpublished: 'Unpublished',
        sendModeration: 'Send for moderation',
        edit: 'Edit',
        archiveDo: 'Archive',
        deleteApplication: 'Do you want to delete NNoffer from ',
        archiveApplication: 'Do you want to archive NNoffer from ',
        requestModeration: 'Do you want to send NNoffer for moderation ?',
        applicationChanged: 'Your NNoffer has been successfully changed',
        deleteRequestArchive: 'Do you want to restore or delete NNoffer from the archive from ',
        recover: 'Recover',
        inModeration: 'In moderation',
        applicationSuccessfullyDeleted: 'Your NNoffer has been successfully deleted',
        applicationSearch: 'NNoffer Search ',
        supplierApplication: 'Supplier NNoffer ',
        expertApplication: 'Expert NNoffer',
        projectApplication: 'Project applicant NNoffer',
        summary: 'Resume',
        Vacancy: 'Vacancy',
        applicationHR: 'NNoffer of the HR',
        applicationEmployer: 'NNoffer of the employer',
        ApplicationUnemployed: 'NNoffer of unemployed',
        BusinesswomanApplication: 'Businesswoman NNoffer',
        ApplicationBusinessProduct: 'NNoffer for the proposed business product',
        ApplicationInnovation: 'NNoffer for innovation / invention',
        ApplicationCoinvestment: 'NNoffer for co-investment',
        ApplicationInvestment: 'NNoffer for investment',
        VolumeInvestments: 'Volume of desired investments',
        InvestmentIndustry: 'Investment industry',
        InvestmentRegion: 'Investment region',
        InvestmentTerm: 'Investment term',
        PaybackTime: 'Payback time',
        ExpectedReturn: 'Expected return',
        ExperienceInvestment: 'Experience in the investment market',
        FirstTime: 'First time',
        participationProjects: 'There is a participation in projects',
        volumeCoinvestments: 'Volume of desired co-investments',
        CoinvestmentIndustry: 'Co-investment industry',
        CoinvestmentRegion: 'Co-investment region',
        EquityParticipation: 'Equity participation',
        Income: 'Income',
        IndustryIntroduction: 'Industry introduction',
        patent: '№ patent. Country that issued the patent',
        Cost: 'Cost',
        Introduced: 'Introduced',
        Description: 'Description',
        ReturnsInvestment: 'Returns on investment',
        BusinessOwner: 'Business owner',
        Experience: 'Experience',
        Salary: 'Salary',
        PaymentMethod: 'Payment method',
        WorkplaceGeography: 'Workplace geography',
        NumberEmployees: 'Number of employees under your employ',
        NameCompany: 'Name of the company ',
        Position: 'Position',
        WorkExperienceApplicant: 'Work experience of the applicant',
        SpecialRequirements: 'Special requirements',
        LabourActivity: 'Labour activity ',
        IntendedPlaceWork: 'Intended place of work ',
        Car: 'Car',
        Rights: 'Driving license',
        CriminalRecord: 'Criminal record',
        LevelImplementation: 'Level of readiness for implementation',
        TypeService: 'Type of service',
        Manufacturer: 'Manufacturer',
        Intermediary: 'Intermediary',
        CostDelivery: 'Cost of delivery',
        DeliveryTime: 'Delivery time',
        MaximumScopeSupply: 'Maximum scope of supply',
        SelectImage: 'Select an image area',
        errorr: 'Error!',
        onlineStatus: 'Online',
        AutomotiveBusiness: {
            _1: 'Auto Parts, Tires (Retail)',
            _2: 'Auto Parts and Components (Manufacturing)',
            _3: 'Auto Components, Parts, Tires (Promotion, Wholesale)',
            _4: 'Cars, Trucks, Motor Transport, Buses, Trolleybuses (Promotion, Wholesale)',
            _5: 'Cars, Trucks, Motor Transport, Buses, Trolleybuses (Manufacturing)',
            _6: 'Car Retail (Dealer Center)',
            _7: 'Maintenance, Repairs of Automobiles',
            _8: 'Financial Services (Captive Banks and Leasing Companies)'
        },
        Hotels: {
            _1: 'Hotel',
            _2: 'Catering (Outside Food Services)',
            _3: 'Restaurant, Food Service Industry, Fast Food',
        },
        Mining: {
            _1: 'Mining and Processing of Mineral Raw Materials (salt, sulfur, alumina), Quarry Development (sand, clay, stone), Peat Digging',
            _2: 'Mining and Processing of Ferrous, Non-ferrous, Precious, Noble, Rare Metal Ores',
            _3: 'Coal Mining and Processing',
            _4: 'Mining of precious metals and stones',
            _5: 'Engineering and Surveying, Hydrogeological, Geological Exploration Works',
        },
        Utilities: {
            _1: 'Landscaping, Cleaning of Buildings and Outdoor Areas',
            _2: 'Ventilation and Air Conditioning (Assembly, Maintenance, Repair)',
            _3: 'Water and Wastewater Systems',
            _4: 'Elevator Facilities (Assembly, Maintenance, Repair)',
            _5: 'Fire Protection, Lightning Protection',
            _6: 'Repairs of Buildings and Constructions',
            _7: 'Low-Voltage Networks (Assembly, Maintenance, Repair)',
            _8: 'Household Waste Recycling',
            _9: 'Electric Power Supply',
        },
        ItSystem: {
            _1: 'Internet Company (Search Engines, Payment Systems, Social Networks, Information and Educational, Entertainment Resources, Website Promotion etc.)',
            _2: 'Internet Service Provider',
            _3: 'Software Development',
            _4: 'System Integration, Technological and Business Processes Automation, IT Consulting',
        },
        Art: {
            _1: 'Archive, Library, Art History',
            _2: 'Botanical Garden, Zoo, Reserve',
            _3: 'film production',
            _4: 'cinema',
            _5: 'Museum, Gallery, Theater',
        },
        TimberIndustry: {
            _1: 'Timber Industry (Production)',
            _2: 'Timber Industry Products (Promotion, Wholesale)',
            _3: 'Woodwork Products for Construction (Promotion, Wholesale)',
            _4: 'Woodwork Products for Construction (Manufacturing)',
            _5: 'Cellulose and Paper Products (Promotion, Wholesale)',
            _6: 'Cellulose and Paper Production',
        },
        Medicine: {
            _1: 'Pharmacy, Optician',
            _2: 'Veterinary Pharmacy',
            _3: 'Veterinary Medicine',
            _4: 'Clinical Research',
            _5: 'Laboratory, Research Center',
            _6: 'Medical Institutions',
            _7: 'Institute of Social Assistance and Protection',
            _8: 'Pharmaceutical Products (Promotion, Wholesale)',
            _9: 'Pharmaceutical Products (Production)',
        },
        Metallurgy: {
            _1: 'Precious, Noble and Rare Metals (Production)',
            _2: 'Precious, Noble and Rare Metals (Promotion, Wholesale)',
            _3: 'Metalware, Metal Structures (Promotion, Wholesale)',
            _4: 'Metalware, Metal Structures (Manufacturing)',
            _5: 'Non-ferrous Metallurgy Products (Promotion, Wholesale)',
            _6: 'Ferrous Metallurgy Products (Promotion, Wholesale)',
            _7: 'Non-ferrous Metallurgy (Smelt, Rolled Metal)',
            _8: 'Ferrous Metallurgy (Production of Iron, Steel, Mill Products)',
        },
        OilGas: {
            _1: 'Fuels and Lubricants (Promotion, Wholesale)',
            _2: 'Fuels and Lubricants (Retail)',
            _3: 'Gas Extraction',
            _4: 'Oil Extraction',
            _5: 'Oil Refining, Petrochemistry (Production)',
            _6: 'Gas Processing',
            _7: 'Petrochemistry (Promotion, Wholesale)',
            _8: 'Gas Transportation, Storage',
            _9: 'Oil Transportation, Storage',
        },
        EducationalInstitutions: {
            _1: 'Driving School',
            _2: 'Business Education',
            _3: 'Higher Education Institution, Secondary Specialized College, College, Vocational School',
            _4: 'Boarding School, Orphanage',
            _5: 'Research, Scientific, Academic Activity',
            _6: 'Teaching Foreign Languages',
            _7: 'Teaching Arts (Drawing, Singing, Dancing, Photography)',
            _8: 'Professional Development, Retraining',
            _9: 'Teaching Sports',
            _10: 'Training Companies',
            _11: 'School, Kindergarten',
        },
        Volunteering: {
            _1: 'Culture or Art Association',
            _2: 'Charitable Organization',
            _3: 'Civic, Political Organization',
            _4: 'Professional, Entrepreneurial Organization',
            _5: 'Religious Organization',
            _6: 'Sports Federation',
            _7: 'Fund, Donor',
        },
        Transportation: {
            _1: 'Air Cargo',
            _2: 'Freight Transport',
            _3: 'International Logistics, Customs Clearance',
            _4: 'Rail Transportation',
            _5: 'Courier, Mail Delivery',
            _6: 'Sea, River Transportation',
            _7: 'Warehouse Services',
            _8: 'Transport and Logistics Systems, Ports (Air, Water, Railway)',
        },
        FoodProducts: {
            _1: 'Alcoholic Drinks (Promotion, Wholesale)',
            _2: 'Non-alcoholic Beverages (Promotion, Wholesale)',
            _3: 'Non-alcoholic Beverages (Production)',
            _4: 'Wine (Production)',
            _5: 'Baby Food (Promotion, Wholesale)',
            _6: 'Baby Food (Production)',
            _7: 'Confectionery Products (Promotion, Wholesale)',
            _8: 'Confectionery Products (Production)',
            _9: 'Canned Products (Promotion, Wholesale)',
            _10: 'Canned Products (Production)',
            _11: 'Pet Food (Promotion, Wholesale)',
            _12: 'Pet Food (Production)',
            _13: 'Liqueurs and Spirits (Production)',
            _14: 'Pasta (Promotion, Wholesale)',
            _15: 'Pasta (Production)',
            _16: 'Fat and Oil Products (Promotion, Wholesale)',
            _17: 'Fat and Oil Products (Production)',
            _18: 'Dairy Products (Promotion, Wholesale)',
            _19: 'Dairy Products (Production)',
            _20: 'Flour, Cereals (Promotion, Wholesale)',
            _21: 'Flour, Cereals (Production)',
            _22: 'Meat Products (Promotion, Wholesale)',
            _23: 'Meat Products (Production)',
            _24: 'Beer (Production)',
            _25: 'Food Concentrates (Promotion, Wholesale)',
            _26: 'Food Concentrates (Production)',
            _27: 'Semi-Processed Foods, including Frozen Foods (Promotion, Wholesale)',
            _28: 'Semi-Processed Foods, including Frozen Foods (Manufacturing)',
            _29: 'Fish and Seafood (Promotion, Wholesale)',
            _30: 'Fish and Seafood (Production)',
            _31: 'Sugar (Promotion, Wholesale)',
            _32: 'Sugar (Production)',
            _33: 'Fresh Vegetables and Fruit (Promotion, Wholesale)',
            _34: 'Snacks (Promotion, Wholesale)',
            _35: 'Snacks (Manufacturing)',
            _36: 'Bakery Products (Promotion, Wholesale)',
            _37: 'Bakery Products (Production)',
            _38: 'Tea, Coffee (Promotion, Wholesale)',
            _39: 'Tea, Coffee (Production)',
        },
        IndustrialEquipment: {
            _1: 'Construction Machines, Equipment, Elevators, Loading, Warehouse Equipment (Assembly, Maintenance, Repair)',
            _2: 'Construction Machines, Equipment, Elevators, Loading, Warehouse Equipment (Promotion, Wholesale)',
            _3: 'Construction Machines, Equipment, Elevators, Loading, Warehouse Equipment (Manufacturing)',
            _4: 'Medical, Diagnostic Equipment, Tools (Assembly, Maintenance, Repair)',
            _5: 'Medical, Diagnostic Equipment, Tools (Promotion, Wholesale)',
            _6: 'Medical, Diagnostic Equipment, Tools (Manufacturing)',
            _7: 'Equipment for Timber Industry, Logging, Cellulose and Paper Industry (Assembly, Maintenance, Repair)',
            _8: 'Equipment for Timber Industry, Logging, Cellulose and Paper Industry (Promotion, Wholesale)',
            _9: 'Equipment for Timber Industry, Logging, Cellulose and Paper Industry (Manufacturing)',
            _10: 'Food Industry and Agriculture Equipment, Packaging Equipment (Assembly, Maintenance, Repair)',
            _11: 'Food Industry and Agriculture Equipment, Packaging Equipment (Promotion, Wholesale)',
            _12: 'Food Industry and Agriculture Equipment, Packaging Equipment (Manufacturing)',
            _13: 'Restaurant, Hotel, Catering, and Retail Equipment (Assembly, Maintenance, Repair)',
            _14: 'Restaurant, Hotel, Catering, and Retail Equipment (Promotion, Wholesale)',
            _15: 'Restaurant, Hotel, Catering, and Retail Equipment (Manufacturing)',
            _16: 'Equipment and Machines for Mining, Energy, Oil and Gas, and Chemical Industries (Assembly, Maintenance, Repair)',
            _17: 'Equipment and Machines for Mining, Energy, Oil and Gas, and Chemical Industries (Promotion, Wholesale)',
            _18: 'Equipment and Machines for Mining, Energy, Oil and Gas, and Chemical Industries (Manufacturing)',
            _19: 'Equipment and Machines for Metal Industry and Metal Processing (Assembly, Maintenance, Repair)',
            _20: 'Equipment and Machines for Metal Industry and Metal Processing (Promotion, Wholesale)',
            _21: 'Equipment and Machines for Metal Industry and Metal Processing (Manufacturing)',
            _22: 'Equipment and Machines for Consumer Industries (Promotion, Wholesale)',
            _23: 'Equipment and Machines for Consumer Industries (Manufacturing)',
            _24: 'Equipment and Machines for Consumer Industries (Assembly, Maintenance, Repair)',
        },
        Retail: {
            _1: 'Vending (trade through vending machines)',
            _2: 'Pet Store',
            _3: 'Internet Store',
            _4: 'Non-Chain Retail, Small Wholesale',
            _5: 'Retail Chain (DIY and Household Products)',
            _6: 'Retail Chain (Drugstore Goods, FMCG)',
            _7: 'Retail Chain (Books, Toys, Gifts, Watches etc.)',
            _8: 'Retail Chain (Furniture)',
            _9: 'Retail Chain (Mobile Retail)',
            _10: 'Retail Chain (Clothing, Footwear, Accessories)',
            _11: 'Retail Chain (Perfumery, Cosmetics)',
            _12: 'Retail Chain (Food)',
            _13: 'Retail Chain (Sports Goods)',
            _14: 'Retail Chain (Children\'s Products)',
            _15: 'Retail Chain (Electronics, Household Appliances)',
            _16: 'Retail Chain (Jewelry)',
        },
        Media: {
            _1: 'Publishing',
            _2: 'Film Production and Audio Recording Studios',
            _3: 'Marketing, Advertising, Designer, Event and PR Agencies',
            _4: 'Production Center',
            _5: 'Production of Multimedia and Content, Editing',
            _6: 'Production of Print Material',
            _7: 'Distribution of Multimedia and Printed Material',
            _8: 'TV Show, Film Distribution (Cable TV)',
            _9: 'Television and Radio Broadcasting',
        },
        Agriculture: {
            _1: 'Agriculture, Crop Production, Livestock Breeding',
            _2: 'Fishing, Fish Farming',
            _3: 'Agricultural Products (Promotion, Wholesale)',
        },
        Construction: {
            _1: 'Agent Services in Real Estate',
            _2: 'Architecture, Design',
            _3: 'Development',
            _4: 'Consulting, Real Estate Valuation',
            _5: 'Road and Infrastructure Construction',
            _6: 'Housing Construction',
            _7: 'Commercial Property Construction (Retail Spaces, Office Buildings)',
            _8: 'Construction of Metallurgy Industry Facilities',
            _9: 'Construction of Oil and Gas Industry Facilities',
            _10: 'Construction of Telecommunication Industry Facilities',
            _11: 'Industrial Construction',
            _12: 'Energy Construction',
            _13: 'Real Estate Management and Use',
        },
        Telecommunications: {
            _1: 'Mobile Communications',
            _2: 'Fiber Optic Communications',
            _3: 'Satellite Communications',
            _4: 'Fixed Communications',
        },
        FMCG: {
            _1: 'Household Cleaning Products, Fragrance, Cosmetics (Promotion, Wholesale)',
            _2: 'Household Cleaning Products, Fragrance, Cosmetics (Manufacturing)',
            _3: 'Office Supplies, Consumables (Promotion, Wholesale)',
            _4: 'Office Supplies, Consumables (Manufacturing)',
            _5: 'Furniture (Promotion, Wholesale)',
            _6: 'Furniture (Manufacturing)',
            _7: 'Clothing, Footwear, Accessories, Textiles (Promotion, Wholesale)',
            _8: 'Clothing, Footwear, Accessories, Textiles (Manufacturing)',
            _9: 'Dishes, Lighting, Household Products (Promotion, Wholesale)',
            _10: 'Dishes, Lighting, Household Products (Manufacturing)',
            _11: 'Plumbing (Promotion, Wholesale)',
            _12: 'Plumbing (Manufacturing)',
            _13: 'Sporting Goods and Equipment (Promotion, Wholesale)',
            _14: 'Sporting Goods and Equipment (Manufacturing)',
            _15: 'Prefabricated Structures for Repair and Construction Work (Promotion, Wholesale)',
            _16: 'Prefabricated Structures for Repair and Construction Work (Assembly, Maintenance, Repair)',
            _17: 'Tobacco (Promotion, Wholesale)',
            _18: 'Tobacco (Production)',
            _19: 'Products for Children (Promotion, Wholesale)',
            _20: 'Products for Children (Manufacturing)',
            _21: 'Jewelry, Fashion Jewelry (Promotion, Wholesale)',
            _22: 'Jewelry, Fashion Jewelry (Manufacturing)',
        },
        HeavyEngineering: {
            _1: 'Aviation, Helicopter, Airspace Industry',
            _2: 'Engines (Assembly, Maintenance, Repair)',
            _3: 'Engines (Promotion, Wholesale)',
            _4: 'Engines (Manufacturing)',
            _5: 'Railway Engineering, Car Building',
            _6: 'Aviation, Airspace, Helicopter Industry Products (Assembly, Maintenance, Repair)',
            _7: 'Aviation, Airspace, Helicopter Industry Products (Promotion, Wholesale)',
            _8: 'Railway Engineering, Car Building Products (Assembly, Maintenance, Repair)',
            _9: 'Railway Engineering, Car Building Products (Promotion, Wholesale)',
            _10: 'Shipbuilding Products (Promotion, Wholesale)',
            _11: 'Manufacturing and Repair of Military Machinery, Equipment and Weaponry',
            _12: 'Ship Repair',
            _13: 'Shipbuilding',
        },
        Multiprofile: {
            _1: 'Management Holding Company, Headquarters'
        },
        BusinessServices: {
            _1: 'Safety, Security',
            _2: 'Recruitment Agencies',
            _3: 'Call Centers, Operations Control Services',
            _4: 'Consulting Services',
            _5: 'Quality Control, Assessment, Testing and Certification',
            _6: 'Translation, Interpreting',
            _7: 'Travel Logistics and Booking Services',
            _8: 'Environmental Maintenance, Water Purification, Waste Recycling',
            _9: 'Legal Services',
        },
        PublicServices: {
            _1: 'Car Hire',
            _2: 'Domestic Staff',
            _3: 'Gambling',
            _4: 'Tailoring, Mending Clothing and Footwear',
            _5: 'Funeral Services',
            _6: 'Beauty Salons',
            _7: 'Sports and Fitness Clubs',
            _8: 'Taxi',
            _9: 'Travel Companies',
            _10: 'Photo and Video Services',
            _11: 'Dry Cleaning',
            _12: 'Entertainment Centers',
        },
        ChemicalProduction: {
            _1: 'Agrochemistry (Promotion, Wholesale)',
            _2: 'Agrochemistry (Production)',
            _3: 'Concrete, Brick, Glass and Other Silicates (Promotion, Wholesale)',
            _4: 'Concrete, Brick, Glass and Other Silicates (Manufacturing)',
            _5: 'Paint and Varnish Products, Raw Materials (Promotion, Wholesale)',
            _6: 'Paint and Varnish Products, Raw Materials (Manufacturing)',
            _7: 'Inorganic Chemistry (Promotion, Wholesale)',
            _8: 'Inorganic Chemistry (Manufacturing)',
            _9: 'Organic Chemistry (Promotion, Wholesale)',
            _10: 'Organic Chemistry (Manufacturing)',
            _11: 'Rubber, Plastic etc. (Promotion, Wholesale)',
            _12: 'Rubber, Plastic etc. (Manufacturing)',
        },
        FinancialSector: {
            _1: 'Auditing, Management Accounting, Financial and Legal Consulting',
            _2: 'Banking',
            _3: 'Debt Collection',
            _4: 'Leasing Companies',
            _5: 'Non-governmental Pension Fund',
            _6: 'Insurance, Reinsurance',
            _7: 'Managing, Investment Company (Asset Management)',
            _8: 'Accounting and Tax Accounting Services, Payroll Preparation',
            _9: 'Factoring Companies',
            _10: 'Financial and Credit Brokering (Stock Exchange, Brokerage Activity, Card Issuing and Servicing, Risk Assessment, Currency Exchange Points, Crediting Agencies, Cash Collectors, Pawnshop, Payment Systems)',
        },
        Electronics: {
            _1: 'Household Appliances, Electronics, Climate Control Equipment (Assembly, Maintenance, Repair)',
            _2: 'Household Appliances, Electronics, Climate Control Equipment (Promotion, Wholesale)',
            _3: 'Household Appliances, Electronics, Climate Control Equipment (Manufacturing)',
            _4: 'Industrial, Domestic Electrical Appliances and Electronics (Assembly, Maintenance, Repair)',
            _5: 'Industrial, Domestic Electrical Appliances and Electronics (Promotion, Wholesale)',
            _6: 'Industrial, Domestic Electrical Appliances and Electronics (Manufacturing)',
            _7: 'Computer, Optical, Control and Measurement Technology, Radio and Electronics, Automatics (Assembly, Maintenance, Repair)',
            _8: 'Computer, Optical, Control and Measurement Technology, Radio and Electronics, Automatics (Promotion, Wholesale)',
            _9: 'Computer, Optical, Control and Measurement Technology, Radio and Electronics, Automatics (Manufacturing)',
        },
        PowerIndustry: {
            _1: 'Alternative Energy: geo-, wind-, solar-, bio- (power generation)',
            _2: 'Nuclear Power (Power Generation, NPP)',
            _3: 'Hydroelectric Engineering (Power Generation, HEP)',
            _4: 'Infrastructural Company in the Energy Sector (System Operator of the Unified Power System, Trading Grid Administrator, Financial Arrangements Center etc.)',
            _5: 'Consulting in Energy Efficiency',
            _6: 'Power Transmission and Distribution',
            _7: 'Sale and Consumption of Power',
            _8: 'Heat Supply (TPP)',
            _9: 'Thermal Engineering (Generation of Power and Heat, TPP)',
        },
        IndustryAll: {
            _1: 'Automotive Business',
            _2: 'Hotels, Restaurants, Food Service Industry, Catering',
            _3: 'Mining Industry',
            _4: 'Utilities',
            _5: 'IT, System Integration, Internet',
            _6: 'Art, Culture',
            _7: 'Timber Industry',
            _8: 'Medicine, Pharmaceuticals, Pharmacies',
            _9: 'Metallurgy, Metalwork',
            _10: 'Oil and Gas',
            _11: 'Educational Institutions',
            _12: 'Public Activity, Political Parties, Volunteering, Non-Profit Organizations',
            _13: 'Transportation, Logistics, Warehousing, International Logistics',
            _14: 'Food Products',
            _15: 'Industrial Equipment, Machine Tools and Components',
            _16: 'Retail',
            _17: 'Media, Marketing, Advertising, PR, Design, Production',
            _18: 'Agriculture',
            _19: 'Construction, Real Estate, Architecture',
            _20: 'Telecommunications, Communications',
            _21: 'FMCG (non-edible)',
            _22: 'Heavy Engineering',
            _23: 'Multiprofile Asset Management',
            _24: 'Business Services',
            _25: 'Public Services',
            _26: 'Financial Sector',
            _27: 'Chemical Production, Fertilizers',
            _28: 'Electronics, Tool Engineering, Household Appliances, Computers and Office Equipment',
            _29: 'Power Industry'
        },
        hobbies: {
            _1: 'Mountain climbing',
            _2: 'Archaeology',
            _3: 'Aerobics',
            _4: 'Aerography',
            _5: 'Running',
            _6: 'Billiards (Snooker)',
            _7: 'Bowling',
            _8: 'Cycling',
            _9: 'Gymnastics',
            _10: 'Alpine skiing',
            _11: 'Graffiti',
            _12: 'Diving',
            _13: 'Food Tasting',
            _14: 'Wine-tasting',
            _15: 'Digging (exploration)',
            _16: 'Design of premises',
            _17: 'Animal training',
            _18: 'Painting, drawing',
            _19: 'Toys',
            _20: 'Studying foreign languages',
            _21: 'Internet',
            _22: 'Yoga',
            _23: 'Music Performance',
            _24: 'Historical fencing',
            _25: 'Skating',
            _26: 'Collecting',
            _27: 'Horse racing',
            _28: 'Crosswords/puzzles',
            _29: 'Cooking',
            _30: 'Landscape design',
            _31: 'Skiing',
            _32: 'Massage',
            _33: 'Meditating',
            _34: 'Playing Board Games',
            _35: 'Communication',
            _36: 'Sky diving (parachuting)',
            _37: 'Paintballing',
            _38: 'Singing',
            _39: 'Swimming',
            _40: 'Going to the beach',
            _41: 'Visiting museums and exhibitions',
            _42: 'Camping (hiking)',
            _43: 'Going for walks',
            _44: 'Listening to lectures',
            _45: 'Watching Theatrical Productions',
            _46: 'Watching TV programmes',
            _47: 'Watching movies',
            _48: 'Planting',
            _49: 'Role playing',
            _50: 'Roller Skating',
            _51: 'Needlework',
            _52: 'Fishing',
            _53: 'Strength training',
            _54: 'Drawing cartoons',
            _55: 'Composing music',
            _56: 'Spelunking',
            _57: 'Sport',
            _58: 'Dancing',
            _59: 'Theatre',
            _60: 'Training (Seminar)',
            _61: 'Tourism',
            _62: 'Photo hunting',
            _63: 'Tea Party',
            _64: 'Book reading',
            _65: 'Yachting',
        }
    }
};

Language = Language[getItem('language', 'ru')];

export default Language;
