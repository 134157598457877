import Input from 'components/UI/Fields/Input';
import Language from "language/index";
import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';


class IdeaForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="form fullWidth" style={{marginTop: '-15px'}}>
        <form
          onSubmit={handleSubmit}
        >
          <Field
            name="name"
            type="text"
            component={Input}
            label={`${Language.firstName} *`}
            placeholder="Введите имя"
          />
          <Field
            name="phone"
            type="text"
            component={Input}
            label="Телефон *"
            placeholder="Введите телефон"
            mask="+7 (999) 999-99-99"
          />
          <Field
            name="email"
            type="text"
            component={Input}
            label="Email *"
            placeholder="Введите email"
          />
          <Field
            name="text"
            type="text"
            component={Input}
            label="Краткое описание идеи *"
          />

          <button
            type="submit"
            className="orange-btn"
          >
            {this.props.preloading ? 'Loading...' : Language.send}
          </button>
        </form>
      </div>
    );
  }
}


export default reduxForm({
  form: 'ideaForm',
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(stopSubmit('ideaForm'));
    }
  }
})(IdeaForm);