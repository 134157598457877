import Accordion from "components/UI/Accordion/Accordion";
import DatePickers from "components/UI/Fields/DatePicker";
import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import { NameFieldGroup } from "components/UI/Forms/GroupFields/NameFieldGroup";
import Region from "components/UI/Forms/GroupFields/Region";
import Additionally from "components/UI/Forms/ProfileIndividualForm/Additionally";
import Career from "components/UI/Forms/ProfileIndividualForm/Career";
import Education from "components/UI/Forms/ProfileIndividualForm/Education";
import IndustryDropDown from "components/UI/Forms/ProfileIndividualForm/IndustryDropDown";
import { ActionNotification } from "components/UI/Notifications/action";
import Language from "language/index";
import { SET_INDIVIDUAL_PROFILE } from "mutation/profile";
import { GET_MY_PROFILE } from "query/index";
import React from 'react';
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from "redux-form";
import { submitProfileIndividualForm } from "./submit";
import ym from "react-yandex-metrika";

class ProfileIndividualForm extends React.Component {
    render() {
        let { handleSubmit, invalid, initialValues } = this.props;
        return (
            <div className="elements-group">
                <div className="form fullWidth">
                    <form onSubmit={handleSubmit} id="profile-form">
                        <NameFieldGroup/>
                        <Field
                            name="midName"
                            type="text"
                            component={Input}
                            label={Language.patronymicName}
                            placeholder="Введите отчество"
                        />
                        <Field
                            name="birthday"
                            type="text"
                            component={DatePickers}
                            label={`${Language.dateBirth} *`}
                            placeholder="Выберите дату рождения"
                            form="profileIndividualForm"
                        />
                        <Field
                            name="sex"
                            type="text"
                            component={Select}
                            label={`${Language.sex} *`}
                            placeholder={Language.ChooseYourGenders}
                            options={[
                                { value: 1, label: Language.Male },
                                { value: 2, label: Language.Female }
                            ]}
                        />
                        <Region
                            {...initialValues}
                            countryLabel={Language.countryResidence}
                            regionLabel={`${Language.regionResidence} *`}
                            cityLabel={`${Language.cityResidence} *`}
                        />
                        <Field
                            name="email"
                            type="text"
                            component={Input}
                            label="Email *"
                            placeholder="Введите email"
                        />
                        {/*<Field*/}
                        {/*name="phone"*/}
                        {/*type="text"*/}
                        {/*component={Input}*/}
                        {/*label="Телефон *"*/}
                        {/*placeholder="Введите телефон"*/}
                        {/*/>*/}
                        <div className="stage">
                            <div className="elements-group">
                                <div className="accordion mb-15">
                                    <Accordion
                                        label={`${Language.education}`}
                                    >
                                        <Education {...initialValues}/>
                                    </Accordion>
                                    <Accordion
                                        label={`${Language.career}`}
                                    >
                                        <Career {...initialValues}/>
                                    </Accordion>
                                    <Accordion
                                        label={Language.industry}
                                    >
                                        <IndustryDropDown initialValues={initialValues}/>
                                    </Accordion>
                                    <Accordion
                                        label={Language.additionalInformation}
                                    >
                                        <Additionally {...initialValues}/>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className="stage save-settings-profile-btn">
                            <div className="elements-group">
                                <button type="submit" className="orange-btn">{Language.save}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}


ProfileIndividualForm = reduxForm({
    form: 'profileIndividualForm',
    enableReinitialize: true,
    onChange: (newValues, dispatch) => {
        dispatch(stopSubmit('profileIndividualForm', {}));
    }
})(ProfileIndividualForm);

export default props => {
    return (
        <Mutation
            mutation={props.mutation ? props.mutation : SET_INDIVIDUAL_PROFILE}
            onCompleted={() => {
                ActionNotification('2', Language.profileUpdated);
                if (props.initialValues.__typename === 'Client') {ym('reachGoal', 'regsuccess')}
                if (props.cb) props.cb();

            }}
            refetchQueries={[{ query: GET_MY_PROFILE }]}
        >
            {mutate => (
                <ProfileIndividualForm
                    {...props}
                    onSubmit={values => submitProfileIndividualForm(values, mutate, props.cb)}/>
            )}
        </Mutation>
    )
};
