import { Page } from 'components/v1/Page';
import React, { useEffect, useRef, useState } from 'react';

import { SEND_CODE, SET_PASSWORD } from 'mutation';
import { getItem, removeItem, setItem } from 'libs/localStorage';
import { client } from 'apollo';
import { CHECK_CODE, REPEAT_CODE, SET_NEW_PASSWORD } from 'query';
import { ActionNotification } from 'components/UI/Notifications/action';
import { AuthForm, ConfirmPhoneForm, RegistrationForm, SetPasswordForm } from './components/RegistrationForms';
import RestorePassword from "components/UI/Forms/RestorePassword/index";

import classes from './styles.module.scss';
import { InfoBlock } from 'pages/Registration/components/InfoBlock';
import { showError } from 'libs/showFormsError';
import { required } from 'libs/fieldValidation';
import { passwordConfirm } from 'libs/validationsForms';

export const Registration = (props) => {
  // const interval = useRef();
  const [step, setStep] = useState(getItem('regStep', 1));
  const [phone, setPhone] = useState(getItem('phone', ''));
  const [seconds, setSeconds] = useState(120);
  const [isRegistration, setIsRegistration] = useState(props.match.path === '/');
  const [isRecovery, setIsRecovery] = useState(props.match.path === '/recovery')
  // console.log(props.match.path === '/auth');

  useEffect(() => {
    setIsRegistration(props.match.path === '/');
    setIsRecovery(props.match.path === '/recovery');
    setStep(1)
  }, [props.match.path])

  useEffect(() => {
    let interval = null

    if (step === 2) {
      interval = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(interval)
        } else {
          setSeconds(seconds => seconds - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval)
    }
  }, [step, seconds])

  const startTimeCode = () => {
    setSeconds(120);
  };

  const startTwoStep = () => {
    setStep(2);
    setItem('regStep', 2);
    // startTimeCode();
  };

  const startStepThree = () => {
    setStep(3);
    setItem('regStep', 3);
  };

  const successRegistration = () => {

    if (getItem('regToken', false)) {
      setItem('token', getItem('regToken'));
    }

    removeItem('regToken');
    removeItem('phone');
    removeItem('regStep');

    props.history.go('/profile/edit')
  };

  const repeatCode = () => {
    let phone = getItem('phone', '');
    phone = phone.replace(/\D+/g, "");

    client.query({ variables: { phone }, query: REPEAT_CODE })
      .then(res => {
        startTimeCode();
        ActionNotification('2', 'Код успешно отправлен')
      })
  };

  const checkCode = value => {
    const { code } = value;

    showError({
      code: [required(code)],
    });

    let phone = getItem('phone', '');
    phone = phone.replace(/\D+/g, "");

    client.query({ query: CHECK_CODE, variables: { phone, code } })
      .then(res => {
        if (res.data.testCode) {
          setItem('code', code);
          startStepThree();
        } else {
          ActionNotification('1', `Неверный код`);
        }
      })
  };

  useEffect(() => {
    if (step === 2) {
      startTimeCode();
    }
  }, [step]);

  const setNewPassword = (values, mutate) => {
    console.log('called setNewPassword');
    let { password } = values;

    let validateObj = {
      password: [required(password)],
      // repeatPassword: [required(repeatPassword)]
    };
    //
    // if (password && repeatPassword) {
    //   validateObj['repeatPassword'] = [passwordConfirm(password, repeatPassword)]
    // }

    showError(validateObj);

    let phone = getItem('phone', '');
    phone = phone.replace(/\D+/g, "");
    let code = getItem('code');

    mutate({ variables: { phone, code, password, repeatPassword: password } })
      .then(res => {
        console.log(res);
        let { token } = res.data.setNewPassword;
        setItem('token', token);
        removeItem('code');
        removeItem('phone');
        props.history.go('/profile/me')
      }).catch(e => {
      console.log(e);
    })
  };

  const initialValues = { phone: getItem('phone', '') };

  return (
    <Page className={classes.background}>

      <div className={classes.containerMain}>
        <InfoBlock />
        {isRegistration && (
          <React.Fragment>
            {step === 1 && <RegistrationForm nextStep={startTwoStep}/>}
            {step === 2 && (
              <ConfirmPhoneForm
                mutate={SEND_CODE}
                resendCode={repeatCode}
                initialValues={initialValues}
                nextStep={startStepThree}
                counter={seconds}
              />
            )}
            {step === 3 && <SetPasswordForm mutate={SET_PASSWORD} nextStep={successRegistration}/>}
          </React.Fragment>
        )}

        {isRecovery && (
          <React.Fragment>
            {step === 1 && <RestorePassword nextStep={startTwoStep}/>}
            {step === 2 && (
              <ConfirmPhoneForm
                mutate={SEND_CODE}
                resendCode={repeatCode}
                initialValues={initialValues}
                nextStep={startStepThree}
                counter={seconds}
                isRecovering
                submit={checkCode}
              />
            )}
            {step === 3 && <SetPasswordForm isRecovering mutate={SET_NEW_PASSWORD} submit={setNewPassword}/>}
          </React.Fragment>
        )}

        {!isRegistration && !isRecovery && <AuthForm />}
      </div>
    </Page>
  )
}
