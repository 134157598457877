import React from 'react';
import { Link } from "react-router-dom";
import Language from "language/index";
import Select from "react-select";
import { getItem } from "libs/localStorage";
import { setItem } from "../../../libs/localStorage";


class Footer extends React.Component {

  state = {
    lang: getItem('language', false) ? getItem('language') : 'ru'
  };

  handleSelectChangeLang = (selectedOption) => {
    this.setState({ lang: selectedOption });
    this.changeLang(selectedOption.value);
  }

  changeLang = (lang) =>  {

    setItem('language', lang);
    window.location.reload()
  };


  render() {
    return (
      <footer>
        <div className="container">
          <div className="foo-bt">
            <div className="copyright">© newnext 2019</div>
            <div className="middle-nav-wrap">
              <ul className="middle-nav flex clearfix">
                <li><Link to="/about">{Language.aboutProject}</Link></li>
                <li><Link to="/terms">{Language.rules}</Link></li>
                <li><Link to="/help">{Language.help}</Link></li>
                <li><Link to="/support">{Language.technicalSupport}</Link></li>
              </ul>
            </div>

            <React.Fragment>
              <div className="lang">
                <span>Language:</span>
                <div className="lang-select lang_select">
                  <form>
                    <Select
                      onChange={this.handleSelectChangeLang}
                      value={this.state.lang}
                      name="lang-select"
                      clearable={false}
                      filterOptions={false}
                      className="select-single"
                      menuPlacement={"bottom"}
                      options={[
                        { value: 'ru', label: 'Russian' },
                        { value: 'en', label: 'English' },
                      ]}
                    />
                  </form>
                </div>
              </div>
            </React.Fragment>


          </div>
        </div>
      </footer>
    )
  }
}


export default Footer;