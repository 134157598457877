import { GET_MY_PROFILE } from "query";
import React, { useState } from 'react';
import { Query } from "react-apollo";
import { ProfileInfo } from 'pages/Profile/components/ProfileInfo';
import { About, AboutCompany } from 'src/pages/Profile/components/About';
import { ABOUT_COMPANY, TAB_ABOUT, TAB_CONTACTS, TAB_NEWS, TAB_REQUEST } from 'pages/Profile/constants';
import { MyContacts } from 'pages/Profile/components/MyContacts';
import { MyRequest } from 'pages/Profile/components/MyRequest';
import NewsPage from 'pages/News';
import { HeaderMobile } from 'components/v1/HeaderMobile';


const Profile = ({ getMyProfile }) => {
  const [activeTab, setActiveTab] = useState(TAB_NEWS);
  return (
    <div>
      <HeaderMobile goBack title="Мой профиль" />
      <ProfileInfo user={getMyProfile} onTabChange={setActiveTab} isMyPage />
      {activeTab === TAB_NEWS && <NewsPage isProfilePage isMyPage {...getMyProfile} />}
      {activeTab === TAB_ABOUT && <About {...getMyProfile} />}
      {activeTab === TAB_CONTACTS && <MyContacts typeUser={getMyProfile.typeKeys[0]}/>}
      {activeTab === TAB_REQUEST && <MyRequest />}
      {activeTab === ABOUT_COMPANY && <AboutCompany {...getMyProfile} />}
    </div>
  )
}


export const MyProfile = (props) => {
  return (
    <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
      {({ data, loading, error }) => {
        if (error || loading) return 'Loading...';
        if (data.ProfileGetter) {
          return <Profile {...props} {...data.ProfileGetter}/>
        } else return 'Loading...'
      }}
    </Query>
  );
}
