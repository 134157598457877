import React, { useState, useRef, useEffect } from 'react'
import { Query } from 'react-apollo'
import Language from 'language'

import { SearchIcon } from 'components/v1/Icons/SearchIcon'
import { User } from 'components/v1/User'

import { SEARCH_USER_BY_FULLNAME } from 'mutation'

import styles from './styles.module.scss'


export const Search = () => {

  const [searchValue, setSearchValue] = useState('')
  const [isOpened, setIsOpened] = useState(false)

  const ref = useRef(null)

  const handleClickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      setIsOpened(false)
    }
  }

  useEffect(() => {
    if (ref) {
      document.addEventListener('click', handleClickOutside, false);
    }

    return () => {
      if (ref) {
        document.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [ref])

  return (
    <div className={styles.search} ref={ref}>
      <form className={styles.form}>
        <SearchIcon className={styles.icon} />
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Поиск по сети"
          value={searchValue}
          onClick={() => {
            setIsOpened(true)
          }}
          onChange={e => {
            setSearchValue(e.target.value)
          }}
        />
      </form>
      {searchValue !== '' && isOpened && (
        <div className={styles.searchData}>
          <Query
            query={SEARCH_USER_BY_FULLNAME}
            variables={{ name: searchValue }}
            fetchPolicy="only-network"
          >
            {({ loading, data: { SearchGetter }, error }) => {
              if (error) return <p>Ошибка..</p>
              if (loading) return <p>Загрузка...</p>
              if (SearchGetter.searchByFullName.total === 0) {
                return <div className={styles.empty}>{Language.nothingFound}</div>
              }
              if (SearchGetter.searchByFullName.total > 0) {
                return SearchGetter.searchByFullName.users.map((user) => (
                  <User
                    size="small"
                    key={user._id}
                    {...user}
                  />
                ))
              }
            }}
          </Query>
        </div>
      )}
    </div>
  )
}
