import React, { Component } from 'react';
import { dateToString } from "libs/convertDate";
import { formatIdOffer } from "libs/formatNumbers";

const RequestHeaderItem = ({ title, id, date }) => {
  return (
    <div className="sb-title clearfix">
      <span>{title}</span>
      <div className="add-info clearfix">
        <div className="ai-date">
          № {formatIdOffer(id) + ', '}
          {dateToString(new Date(date), 'stringFullDate')}
        </div>
      </div>
    </div>
  );
};

export default RequestHeaderItem;