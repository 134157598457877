import React, { useRef, useState } from 'react'
import { Mutation } from 'react-apollo';
import Language from 'language';
import { SET_AVATAR_PROFILE } from 'mutation/profile';
import { GET_MY_PROFILE } from 'query';
import Cropper from 'react-cropper'

import { ActionNotification } from 'components/UI/Notifications/action';
import ModalWindow from 'components/UI/ModalWindow';
import { UserIcon } from 'components/v1/Icons/UserIcon';

import styles from './styles.module.scss'
import { getItem } from 'libs/localStorage';
import * as axios from 'axios';
import { Button } from 'components/v1/Button';
import { useSizeXS } from 'libs/useMediaQuery';
import { Avatar } from 'components/v1/Avatar/Avatar';

const LoaderAvatar = ({ value, mutate, firstName, lastName, companyName }) => {

  const isMobile = useSizeXS()
  const [file, setFile] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  let cropper = useRef()

  const onToggle = () => {
    setIsOpen(isOpen => !isOpen)
  }

  const onChange = (value) => {
    let file = value.target.files[0];

    if (!file) return false;
    let url = window.URL || window.webkitURL;
    let img = new Image();
    img.onload = () => {
      if (img.width > 255 && img.height > 330) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.size > 10000000) {
          ActionNotification('1', Language.LargeFileSize);
        } else {
          reader.onloadend = () => {
            setFile(reader.result)
            onToggle()
          }
        }
      } else {
        ActionNotification('1', 'Фотография должна иметь размер не меньше 255 точек по ширине и 330 по высоте');
      }
    };
    img.src = url.createObjectURL(file);
  }

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const _crop = () => {
    setLoading(true)

    if (cropper.current.cropper.getCroppedCanvas().width < 255) {
      ActionNotification('1', 'Увеличьте область изображения');
      setLoading(false)
      return;
    }

    let imageInput = document.querySelector('.inputfile_1');
    imageInput.src = cropper.current.cropper.getCroppedCanvas().toDataURL();
    let photoImage = document.querySelector('#photo_user_profile_1');
    // let data = new FormData(photoImage);
    let form = document.getElementById("photo_user_profile_1");
    let ImageURL = cropper.current.cropper.getCroppedCanvas({
      width: 510,
      height: 660,
      fillColor: '#fff',
    }).toDataURL();

    let block = ImageURL.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = b64toBlob(realData, contentType);

    let formDataToUpload = new FormData();
    formDataToUpload.append("name", blob);
    const token = getItem('token', false);

    axios({
      url: '/upload/image',
      method: 'post',
      data: formDataToUpload,
      headers: { 'token': token, 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        // console.log(response);
        setIsOpen(false)
        setLoading(false)
        mutate({ variables: { avatarUrl: response.data[0].link } })
      })
      .catch(error => {
        setLoading(false)
        ActionNotification('1', 'Произошла ошибка. Уменьшите область изображения');
      });
  };

  return (
    <>
      {isMobile ? (
        <div className={styles.mobileChange}>
          <input onChange={onChange} id="upload" type="file"/>
          <Avatar source={value} />
          <div className={styles.infouser}>
            <div>
              <div className={styles.username}>{firstName}</div>
              <div className={styles.username}>{lastName}</div>
              <label htmlFor="upload">
                Изменить фотографию
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.change}>
          <div className={styles.container}>
            <div className={styles.empty}>
              {value ? (
                <img src={value} alt=""/>
              ) : (
                <UserIcon />
              )}
            </div>

            <div className={styles.upload}>
              <input onChange={onChange} id="upload" type="file"/>
              <label htmlFor="upload">
                <div className={styles.text}>
                  <div className={styles.uploadTitle}>{Language.uploadedPhoto}</div>
                  <div className={styles.uploadSize}>{Language.notMoreMB}</div>
                </div>
              </label>
            </div>

            <label htmlFor="upload" className={styles.hoverOverflow} />
          </div>
        </div>
      )}
      {
        isOpen && (
          <ModalWindow
            title={Language.SelectImage}
            handleClose={onToggle}
          >
            <Cropper
              dragMode="move"
              zoomable={false}
              minContainerWidth={255}
              ref={cropper}
              src={file}
              aspectRatio={255 / 330}
              viewMode={2}
              modal={true}
              style={{ maxHeight: '400px', width: '99%' }}
            />
            <form id="photo_user_profile_1 hidden" style={{ display: 'none' }}>
              <input type="image" src="" className="hide inputfile_1" id="upload" name="picture"/>
            </form>
            <div className={styles.formFooter}>
              <Button onClick={loading ? () => {}: _crop} type="button">
                {loading ? 'Loading...' : Language.save}
              </Button>
              <Button variant="outlined" color="gray" onClick={onToggle} type="button">{Language.Cancel}</Button>
            </div>
          </ModalWindow>
        )
      }
    </>
  )
}

export const ChangeAvatar = (props) => {
  return (
    <Mutation
      onCompleted={() => ActionNotification('2', Language.profileUpdated)}
      onError={() => ActionNotification('1', 'Произошла ошибка загрузке изображения')}
      mutation={SET_AVATAR_PROFILE}
      refetchQueries={[{query: GET_MY_PROFILE}]}
    >
      {mutate => (
        <LoaderAvatar mutate={mutate} {...props} />
      )}
    </Mutation>
  )
}
