import React, { useState } from 'react';
import { GET_OFFERS_LIST } from 'query/offers';
import { Query } from 'react-apollo';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { NotFound } from '../NotFound/';
import Loading from 'components/UI/Prealoders/Loading';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import { Request } from 'components/v1/Request/Request';

import classes from './styles.module.scss'
import { GET_MY_ID } from 'query/profile';


const ApplicationListComponent = (props) => {
  const [page, setPage] = useState(1)

  const loadMore = page => {
    props.refetch({ isMy: props.isMy, page: 0, count: page * 30 });
    setPage(page)
  };

  const { isNetwork } = props;
  const isOffers = (props?.OfferGetter?.getOfferList?.offers || []).length === 0;
  const { loading } = props;

  if (loading && isOffers) return <Loading/>;

  return (
    <React.Fragment>

      {
        !loading && isOffers &&
        <NotFound />
      }

      {!isOffers && (
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={!loading && page * 10 < props.OfferGetter.getOfferList.total}
          loader={<PreloaderCircle key={2351}/>}
        >
          <div className={classes.requests}>
            {props.OfferGetter.getOfferList.offers?.map((el, index) => {
              if (el.status === 'ARCHIVED' || el.status === 'REMOVED') return null;
              return (
                <Request isNetwork={isNetwork} isMe={props.myId  === el.user._id} {...el} key={index}/>
              )
            })}
          </div>
          {loading && <PreloaderCircle key={322323}/>}
        </InfiniteScroll>
      )}
    </React.Fragment>
  );
}


export const ApplicationList = props => {
  return (
    <Query query={GET_MY_ID}>
      {({ data, loading }) => {
        if (data.ProfileGetter) {
          const { getMyProfile } = data.ProfileGetter
          return (
            <Query query={GET_OFFERS_LIST} variables={{ isMy: props.isMy, ...props.variables, count: 30 }}>
              {({ loading, error, data, refetch }) => {
                if (error) return 'Loading...';
                return (
                  <ApplicationListComponent
                    loading={loading}
                    refetch={refetch}
                    isNetwork={!props.isMy}
                    myId={getMyProfile._id}
                    {...data}
                    {...props}
                  />
                )
              }}
            </Query>
          )
        }
      }}
    </Query>
  )
};
