import Language from "language/index";
import language from "language/index";

export const required = val => {
    const err = Language.fillField;
    if (val instanceof Array) {
        return val.length === 0 ? err : undefined;
    }
    if (val instanceof Object && Object.keys(val).length > 0) {
        for (let key in val) {
            if (val[key]) {
                return undefined;
            }
        }
    }
    return val ? undefined : err;
};

export const isPerfections = data => {
  let date = new Date(data);

    if (6570 > (new Date() - date) / 1000 / 60 / 60 / 24) {
       return Language.YouUnderYearsAge;
    } else return undefined;
};


export const passwords = val => val && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(val) ?
    language.PasswordContainThanCharacters : undefined;

export const passwordConfirm = (pass1, pass2) => {
  if (pass2 && pass2.length > 0)
        return pass1 === pass2 ? undefined : language.PasswordsDifferent;
    else return undefined;
};

export const code = val => val && (!/[0-9]{4,}$/.test(val) || val.length !== 4) ?
    language.errorCode : undefined;
