import SideBlock from "components/UI/SideBlock/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import { GET_SEARCH_WITH_FILTER } from 'query';
import PropTypes from 'prop-types';
import SbContactsList from 'components/UI/SbContacts/SbContactsList';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import InfiniteScroll from 'react-infinite-scroller';


class SearchUserList extends Component {

  static propTypes = {
    SearchGetter: PropTypes.object
  };

  static defaultProps = {
    SearchGetter: { filterUserByParams: { users: [], total: 0 } }
  };

  counter = 0;

  state = {
    page: 1
  };

  loadMore = page => {
    this.props.refetch({ page: 0, count: page * 30 });
  };

  render() {
    const { total, users } = this.props.SearchGetter.filterUserByParams;
    const { loading } = this.props;
    return (
      <SideBlock
        count={String(total)}
        title="Найдено совпадений:"
      >
        {loading && users.length === 0 && <PreloaderCircle/>}
        {
          users.length !== 0 &&
          <InfiniteScroll
            pageStart={1}
            loadMore={this.loadMore}
            hasMore={!loading && this.state.page * 30 < total}
            loader={<PreloaderCircle/>}
          >
            <SbContactsList isStatus users={users}/>
            {loading && (
              <PreloaderCircle/>
            )}
          </InfiniteScroll>
        }
      </SideBlock>
    )
  }
}


export default props => (
  <Query query={GET_SEARCH_WITH_FILTER} variables={{ ...props.variables }}>
    {({ loading, error, data, refetch }) => {
      if (error) return 'Loading...';
      return (
        <SearchUserList
          loading={loading}
          refetch={refetch}
          {...data}
          {...props}
        />
      )
    }}
  </Query>
)
