import { Avatar } from 'components/UI/Avatar/index';
import ContactStatus from 'components/UI/ContactStatus/index';
import { dateToString } from 'libs/convertDate';
import { DELETE_CONTACTS } from 'mutation/Contacts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import Language from 'language/index';


class SContactsItem extends PureComponent {

	static propTypes = {
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		avatar: PropTypes.string,
		_id: PropTypes.string,
		personalLink: PropTypes.string,
		isContacts: PropTypes.bool,
		onlineStatus: PropTypes.object,
		typeKeys: PropTypes.array,
		offer: PropTypes.object,
	};

	static defaultProps = {
		input: {},
		meta: {},
		onlineStatus: {},
		offer: {},
		typeKeys: []
	};


	state = {
		isOpened: false
	};

	setWrapperRef = node => {
		this.wrapperRef = node;
	};

	handleClickOutside = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({ isOpened: false });
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, false);
	};


	render() {
		const { firstName, lastName, avatar, personalLink, _id, typeKeys, onlineStatus, isContacts, lastMsg, offer, link, createDate, isAdmin, check } = this.props;
		return (
			<li>
				<div className="hover"/>

				{
					link ?
						<Link target={`${isAdmin && '_blank'}`} to={`${link}`}/> :
						<Link to={`/messages/${_id}`}/>
				}

				<Avatar
					size={70}
					avatar={avatar}
				/>

				<div className="s-contact-info">

					<div className="contact-name">
						<i className={`${onlineStatus.isOnline ? 'online' : 'offline'}`}/>
						{`${firstName}  ${lastName}`}
					</div>

					<ContactStatus className="contact-status" type={typeKeys[0]}/>

					{lastMsg &&
					<div className="contact-message">
						{lastMsg.text}
					</div>
					}

					{
						createDate &&
						<div className="contact-message">
							ID: {_id} &nbsp;&nbsp; Дата регистрации: {dateToString(new Date(createDate), 'stringDate')}
							&nbsp;&nbsp;&nbsp;{check && 'Ads'}
							<br/>
						</div>
					}

					<div className="right-mes-desc">
						{
							isAdmin &&
							<div className="posa linealign" ref={this.setWrapperRef}
								 style={{ zIndex: this.state.isOpened ? 30 : 12 }}>
								<div className="dots-btn-wrap more-info">
									<button type="button" className="dots-btn visible-info"
											onClick={() => this.setState({ isOpened: !this.state.isOpened })}>
										<span>...</span>
									</button>
									{
										this.state.isOpened &&
										<ul className="hidden-info" style={{ display: 'block' }}>
											<li style={{ paddingTop: 0 }}>
												<a className="user-info-link share" href="#"
												   style={{ position: 'static' }}
												   onClick={(e) => {
													   e.preventDefault();
													   this.props.actionEdit(_id)
												   }}
												>
													<i className="panton c">u</i>
													Редактировать
												</a>
											</li>
											<li style={{ paddingTop: 0 }}>
												<a className="user-info-link share" href="#"
												   style={{ position: 'static' }}
												   onClick={(e) => {
													   e.preventDefault();
													   this.props.actionDelete(_id)
												   }}
												>
													<i className="panton b">k</i>
													Удалить
												</a>
											</li>
											{!check &&
											<li style={{ paddingTop: 0 }}>
												<a className="user-info-link share" href="#"
												   style={{ position: 'static' }}
												   onClick={(e) => {
													   e.preventDefault();
                                                       this.setState({ isOpened: false });
													   this.props.actionStatus(_id)
												   }}
												>
													<i className="panton d">G</i>
													Ads
												</a>
											</li>
											}
										</ul>
									}
								</div>
							</div>
						}

						{offer && <em>Диалог по заявке №{offer._id},</em>}
						{lastMsg && <span className="time">{dateToString(new Date(lastMsg.createDate), 'time')}</span>}
					</div>
				</div>
			</li>
		);
	}
}


export default props => (
	<Mutation
		mutation={DELETE_CONTACTS}
		variables={{ userId: props._id }}
		update={(cache, { data }) => updateContact(cache, { data }, props.queryUpdate)}
	>
		{(removeContact, called) => {
			return <SContactsItem mutate={removeContact} {...props} called={called}/>
		}}
	</Mutation>
);


const updateContact = (cache, { data }, queryUpdate) => {
	// console.log(data);
	// let { ContactGetter } = cache.readQuery({ query: GET_CONTACTS });
	// if (queryUpdate) {
	//     const { UserGetter } = cache.readQuery(queryUpdate);
	//     console.log(UserGetter);
	//     cache.writeQuery({
	//         ...queryUpdate,
	//         data: {
	//             UserGetter: {
	//                 getRecommendedUserByUserType: {
	//                     ...UserGetter.getRecommendedUserByUserType,
	//                     users: UserGetter.getRecommendedUserByUserType.users.map(el => {
	//                         if (el._id === ContactSetter.addContact._id) {
	//                             el.isContacts = true
	//                         }
	//                         return el
	//                     }),
	//                     __typename: "result"
	//                 },
	//                 __typename: "getUser"
	//             }
	//         }
	//     })
	//
	// }
	//
	// cache.writeQuery({
	//     query: GET_CONTACTS,
	//     data: {
	//         ContactGetter: {
	//             getContactList: {
	//                 contacts: [
	//                     ...ContactGetter.getContactList.contacts,
	//                     ContactSetter.addContact
	//                 ],
	//                 total: parseInt(ContactGetter.getContactList.total) + 1,
	//                 __typename: "MyContacts"
	//             },
	//             __typename: "ContactGetter"
	//         }
	//     },
	// });
};



