import React from 'react';
import classes from './MessagesList.module.scss';
import { AttachIcon } from 'components/v1/Icons/AttachIcon';
import { SendMessageIcon } from 'components/v1/Icons/SendMessageIcon';
import { Mutation } from 'react-apollo';
import { SEND_MESSAGE } from 'mutation/dialog';
import { client } from 'apollo';
import { GET_MESSAGES } from 'query/messages';
import { getItem } from 'libs/localStorage';
import * as axios from 'axios';
import { ActionNotification } from 'components/UI/Notifications/action';
import { TextareaAutosize } from 'react-autosize-textarea/lib/TextareaAutosize';
import Language from 'language';
import File from 'components/UI/Messages/message-list/parts/message-file';



class SendMessageComponent extends React.Component {
  inputForFile = undefined;
  sendMessage = () => {
    // e.preventDefault();

    const { dialogId } = this.props;
    let msg = {
      dialogId,
      text: document.querySelector('#input-default').value,
      links: this.state.files
    };

    this.props.mutate({
      variables: { msg },
      update: (cache, { data: { DialogSetter } }) => {
        const { DialogGetter } = client.readQuery({
          query: GET_MESSAGES,
          variables: { "dialogId": dialogId, count: 50 }
        });

        // DialogGetter.getMessagesByDialogId.messages.unshift(DialogSetter.sendMsg);
        // DialogGetter.getMessagesByDialogId.total = DialogGetter.getMessagesByDialogId.total + 1;


        // client.writeQuery({
        //   query: GET_MESSAGES,
        //   variables: { "dialogId": dialogId, count: 50 },
        //   data: { DialogGetter }
        // });

        // const data = client.readQuery({
        //   query: GET_MESSAGES,
        //   variables: { "dialogId": dialogId, count: 50 }
        // });

        setTimeout(() => {
          let messagesList = document.querySelector('#scrollable_messages');
          let scroll = messagesList.scrollHeight;
          messagesList.scrollTop += scroll + 500;
        }, 50);
      }
    })
      .then(res => {
        this.setState({
          text: '',
          showEmoji: false,
          files: []
        });
      })
  };

  handleKeyDown = e => {
    if (e.ctrlKey && e.keyCode === 13) this.sendMessage();
  };

  handleAddFile = () => {
    if (this.inputForFile) {
      this.inputForFile.click();
    }
  };

  saveFile = () => {
    if (!this.inputForFile || this.state.files.length >= 10 || this.inputForFile.files[0] === undefined) {
      return false;
    }

    let formDataToUpload = new FormData();
    formDataToUpload.append("name", this.inputForFile.files[0]);

    this.setState({
        loading: true,
        files: [
          ...this.state.files,
          'image.loading'
        ]
      }
    );
    const token = getItem('token', false);

    axios({
      url: '/upload/file',
      method: 'post',
      data: formDataToUpload,
      headers: { 'token': token, 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        this.removePreview(response.data[0].link);
      })
      .catch(error => {
        console.log(error);
        this.removePreview();
        // alert(Language.LargeFileSize);
        // this.props.client
        ActionNotification('1', 'Размер файла превышает допустимый');
      });
  };
  removeFile = url => {
    this.setState({
      files: this.state.files.filter(it => it !== url)
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      tuid: this.props.userId,
      text: '',
      showEmoji: false,
      loading: false,
      files: []
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onHandleEmoji = this.onHandleEmoji.bind(this);
    // this.sendMessage = this.sendMessage.bind(this);
    this.onToggleEmojiState = this.onToggleEmojiState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onHandleEmoji(code, emoji) {
    // let emojiPic = jsemoji.replace_colons(`:${emoji.name}:`);
    // this.setState({
    //   text: this.state.text + emojiPic
    // });
  }

  onToggleEmojiState() {
    this.setState({ showEmoji: !this.state.showEmoji });
  }

  textMessages(e) {
    this.setState({ text: e.target.value });
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
    document.querySelector('#input-default').addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
    document.querySelector('#input-default').removeEventListener('keydown', this.handleKeyDown, false);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ showEmoji: false });
    }
  }

  removePreview(file = null) {
    let newFile = [];

    if (file !== null) {
      newFile = [
        ...this.state.files,
        file
      ];
    } else {
      newFile = this.state.files;
    }

    let index = newFile.findIndex(el => el === 'image.loading' ? el : false);
    let fileArray = newFile.splice(index, 1);

    this.setState({
      loading: false,
      files: newFile
    });
  }

  render() {
    return (

      <div className={classes.form}>
        <button onClick={this.handleAddFile} disabled={this.state.loading}>
          <AttachIcon />
        </button>
        <input
          id="input-default"
          type="text"
          placeholder="Напишите сообщение"
          onChange={this.textMessages.bind(this)}
          value={this.state.text}
        />
        <button onClick={this.sendMessage} disabled={this.state.loading}>
          <SendMessageIcon />
        </button>

        <input
          style={{ display: 'none' }}
          type="file"
          ref={it => this.inputForFile = it}
          onChange={this.saveFile}
        />

        <div className="user-files" >
          {
            this.state.files.map(url => File(url, () => {
              this.removeFile(url);
            }))
          }
        </div>
      </div>
    )
  }
}

export const SendMessage = props => (
  <Mutation
    mutation={SEND_MESSAGE}
  >
    {mutate => (
      <SendMessageComponent
        {...props}
        mutate={mutate}
      />
    )}
  </Mutation>
);
