import React from 'react';
import { getItem } from "./localStorage";

const GetYearLabel = number => {
    if(typeof(number) !== 'number') {
        return <React.Fragment>Ошибка даты</React.Fragment>;
    }

    if (getItem('language') === 'en') {
        return 'age'
    }

    number = Math.abs(number) % 100;
    let number1 = number % 10;
    if (number > 10 && number < 20) { return 'лет'; }
    if (number1 > 1 && number1 < 5) { return 'года'; }
    if (number1 === 1) { return 'год'; }
    return 'лет';
};


export const getAge = date => {
    let bDate = new Date(date);

    if(date === undefined) {
        return '';
    }

    let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    if (isSafari && typeof (date.substring) === 'function') {
        bDate = new Date(date.substring(0, date.length - 5));
    }

    let now = Date.now();
    let dateYear = (now - bDate) / (365 * 24 * 60 * 60 * 1000);

    if (isNaN(dateYear)) {
        return ''
    } else if (dateYear <= 2) {
        return ''
    } else {
        return `${Math.floor(dateYear)} ${GetYearLabel(Math.floor(dateYear))}`;
    }
};