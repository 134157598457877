import React, { useState } from 'react';
import { Query } from 'react-apollo';

import { GET_PROFILE_USER } from 'query/profile';

import { ABOUT_COMPANY, TAB_ABOUT, TAB_CONTACTS, TAB_NEWS, TAB_REQUEST } from './constants';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import { About, AboutCompany } from './components/About';
import { ProfileInfo } from './components/ProfileInfo';
import { UserRequests } from './components/UserRequests';
import { UserContacts } from './components/UserContacts';
import NewsPage from 'pages/News';
import { HeaderMobile } from 'components/v1/HeaderMobile';


const ProfileComponent = (props) => {
  const { getUserById } = props;
  const [activeTab, setActiveTab] = useState(TAB_NEWS);

  return (
    <div>
      <HeaderMobile goBack title={`Профиль ${getUserById.firstName}`} />
      <ProfileInfo isUser user={getUserById} onTabChange={setActiveTab} />
      {activeTab === TAB_NEWS && <NewsPage isProfilePage userId={props.match.params.id} />}
      {activeTab === TAB_ABOUT && <About {...getUserById} />}
      {activeTab === TAB_CONTACTS && <UserContacts id={props.match.params.id} typeUser={getUserById.typeKeys[0]}/>}
      {activeTab === TAB_REQUEST && <UserRequests id={props.match.params.id} />}
      {activeTab === ABOUT_COMPANY && <AboutCompany {...getUserById} />}
    </div>
  )
}


export const UserProfile = (props) => {
  return (
    <Query query={GET_PROFILE_USER} variables={{ 'userId': props.match.params.id }} fetchPolicy="cache-and-network">
      {({ data, loading, error }) => {
        if (error || loading || !data) return <PreloaderCircle/>;
        return <ProfileComponent {...props} {...data.UserGetter} />
      }}
    </Query>
  );
}
