import { dialogueChecking } from 'components/UI/Contacts/dialogueChecking';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import AboutProfile from 'components/UI/Profile/AboutProfile';
import UserDescription from 'components/UI/Profile/UserDescription';
import SbContactsList from 'components/UI/SbContacts/SbContactsList';
import SideBlock from 'components/UI/SideBlock/index';
import { ADD_CONTACT, DELETE_CONTACTS } from 'mutation/Contacts';
import { GET_CONTACTS_BY_USERID } from 'query/contacts';
import { GET_PROFILE_USER } from 'query/profile';
import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import Language from 'language/index';
import { ActionNotification } from 'components/UI/Notifications/action';
import { GET_OFFERS_BU_USER_ID } from 'query/offers';
import Application from 'components/UI/Requests/index';
import ReccomendUserByType from 'components/UI/ReccomendUsers/ReccomendUserByType';


const ProfileImage = ({ avatar, actionMessage, actionContactAdd, actionContactRemove, isContacts, isLockedUser }) => {
  return (
    <div className="img">
      <img src={avatar ? avatar : '/templates/img/uploader_default.jpg'} alt="..."/>
      {
        !isLockedUser &&
        <div className="clickable-el-row">
          <button
            onClick={actionMessage}
            className="gray-btn send"
            style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}>
            {Language.writeMessage}
          </button>
          <button
            onClick={isContacts ? actionContactRemove : actionContactAdd}
            className={`gray-btn ${!isContacts ? 'add' : ''}`}
            style={{ marginTop: '5px', width: '100%', textAlign: 'center' }}>
            <span>{isContacts ? Language.removeContacts : Language.Contact}</span>
          </button>
        </div>
      }

    </div>
  )
};


class ProfileUser extends Component {
  render() {
    const { getUserById } = this.props;
    return (
      <React.Fragment>

        <div className="centerside">

          <SideBlock>
            <div className="user-main-info clearfix">
              <Mutation
                mutation={ADD_CONTACT}
                variables={{ contact: { userId: this.props.match.params.id } }}
                onCompleted={data => {
                  const { lastName, firstName } = data.ContactSetter.addContact.user;
                  ActionNotification('2', `${firstName} ${lastName} ${Language.addedContacts}`);
                }}
                refetchQueries={[{
                  query: GET_PROFILE_USER,
                  variables: { 'userId': this.props.match.params.id }
                }]}>
                {mutate => {
                  let actionContactAdd = mutate;
                  return (
                    <Mutation
                      mutation={DELETE_CONTACTS}
                      variables={{ userId: this.props.match.params.id }}
                      onCompleted={data => {
                        const { lastName, firstName } = data.ContactSetter.removeContact.user;
                        ActionNotification('2', `${firstName} ${lastName} ${Language.removedContacts}`);
                      }}
                      refetchQueries={[{
                        query: GET_PROFILE_USER,
                        variables: { 'userId': this.props.match.params.id }
                      }]}
                    >
                      {mutate => (
                        <ProfileImage
                          actionContactAdd={actionContactAdd}
                          actionContactRemove={mutate}
                          isLockedUser={getUserById.isLockedUser}
                          actionMessage={() => dialogueChecking(getUserById._id)}
                          isContacts={getUserById.isContacts}
                          avatar={getUserById.avatar}
                        />
                      )}
                    </Mutation>
                  )
                }}
              </Mutation>
              <UserDescription notMy {...getUserById}/>
            </div>
          </SideBlock>


          <Query query={GET_CONTACTS_BY_USERID}
                 variables={{ 'userId': this.props.match.params.id, count: 6 }}>
            {({ data, loading, error }) => {
              if (error || loading || !data) return null;
              if (data.ContactGetter && data.ContactGetter.getContactListByUserId && data.ContactGetter.getContactListByUserId.total === 0) return null;
              return (
                <SideBlock
                  count={data.ContactGetter.getContactListByUserId.total}
                  title="Контакты"
                >
                  <SbContactsList
                    isStatus
                    users={data.ContactGetter.getContactListByUserId.contacts}/>
                </SideBlock>
              )
            }}
          </Query>
          <Query query={GET_OFFERS_BU_USER_ID}
                 variables={{ 'userId': this.props.match.params.id }}>
            {({ data: { OfferGetter }, loading }) => {
              if (loading) return <PreloaderCircle/>;
              if (OfferGetter.getOfferByUserId.total === 0) return null;
              return (
                <React.Fragment>
                  {OfferGetter.getOfferByUserId.offers.map((el, index) => {
                    if (el.status === 'ARCHIVED' || el.status === 'REMOVED') return null;
                    return (
                      <Application isNetwork={true} {...el} key={index}/>
                    )
                  })}
                </React.Fragment>
              )
            }}

          </Query>
        </div>
        <div className="rightside">
          {getUserById && <AboutProfile description={getUserById.description}/>}
          <ReccomendUserByType userType={getUserById.typeKeys[0]}/>
        </div>
      </React.Fragment>
    )
  }
}


export default props => {
  //
  // if (props.match.params.id.match(/^[0-9a-fA-F]{24}$/)) {
  //     console.log('isID')
  // } else {
  //     console.log('noID')
  // }

  return (
    <Query query={GET_PROFILE_USER} variables={{ 'userId': props.match.params.id }} fetchPolicy="cache-and-network">
      {({ data, loading, error }) => {
        if (error || loading || !data) return <PreloaderCircle/>;
        return <ProfileUser {...props} {...data.UserGetter} />
      }}
    </Query>
  )
}
