import React, { useMemo, useState } from 'react'

import { SelectController } from 'components/v1/HookFields/Select';

import Language from 'language';
import IndustryData from 'components/UI/Forms/GroupFields/IndustryData';

import styles from 'pages/Profile/EditForms/styles.module.scss';

const IndustryGroup = ({
  control,
  setValue,
  watch,
}) => {
  const watchedIndustry = watch('industry[groupName]')

  const [industry, setIndustry] = useState({})

  const onHandleIndustryGroupName = (option) => {
    if (option) {
      setIndustry({ name: null, groupName: option.value })
      setValue('industry.name', '')
    }
  }

  const options = useMemo(() => {
    if (industry.groupName || watchedIndustry) {
      return IndustryData[`_${industry.groupName || watchedIndustry}`]
    }

    return []
  }, [industry])

  return (
    <>
      <div className={styles.section}>
        <div>Отрасль</div>
        <SelectController
          control={control}
          name="industry[groupName]"
          placeholder={Language.ChooseIndustry}
          options={IndustryData.all_industries}
          label={''}
          change={onHandleIndustryGroupName}
        />
      </div>
      {watchedIndustry && (
        <div className={styles.section}>
          <div>Отрасль ({Language.MoreDetails})</div>
          <SelectController
            control={control}
            name="industry[name]"
            placeholder={'Выберите доп отрасль'}
            options={options}
            label={''}
          />
        </div>
      )}
    </>
  )
}


export default IndustryGroup
