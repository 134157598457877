import cn from 'classnames';
import classes from './styles.module.scss';


export const Page = ({ children, className }) => {
  return <section id="main" className={cn(classes.section, className)}>{children}</section>;
};

Page.Body = ({ children, className }) => {
  return <div className={cn(classes.body, className)}>{children}</div>;
};

Page.Aside = ({ children, className }) => {
  return <div className={cn(classes.aside, className)}>{children}</div>;
};


