import Footer from 'components/UI/Footer';
// import { Header } from 'components/v1/Header';
import { LeftMenu } from 'components/v1/LeftMenu';
import React from 'react';
import HeaderPanel from 'components/UI/Header/HeaderPanel';
import { Button } from 'components/v1/Button';
import { Layout } from 'components/v1/Layout';
import Header from 'components/v1/Header/Header';

export default class MainLayout extends React.Component {

  render() {
    const arrPath = this.props.location.location.pathname.split('/');
    // console.log(arrPath);
    const d = arrPath

    const isAuthLayout = ['/', '/auth', '/nnnotice', '/nnnotice-my', '/recovery'].includes(this.props.location.location.pathname)

    if (['/admin', '/admin_user'].includes(this.props.location.location.pathname)) {
      return (
        <div className="wrapper">
          <Header/>
          {/*<LeftMenu />*/}
          <div className="user-template">
            <div className="container">
              <div className="sides clearfix">
                {this.props.children}
              </div>
            </div>
          </div>
          {/*<Footer/>*/}
        </div>
      )
    }

    return (
      <Layout withLeftMenu={!isAuthLayout} location={arrPath} isNotContainer={isAuthLayout}>
        {this.props.children}
      </Layout>
    )

    // if (arrPath[1] === 'profile' && (arrPath[2] === 'me' || arrPath[2].match(/^[0-9a-fA-F]{24}$/))) {
    //   return (
    //     <div className="wrapper">
    //       <Header/>
    //       <LeftMenu />
    //       <div className="user-template">
    //         {/*<HeaderPanel uid={arrPath[2].match(/^[0-9a-fA-F]{24}$/) ? arrPath[2] : false}/>*/}
    //         <div className="container">
    //           <div className="sides clearfix">
    //             {this.props.children}
    //           </div>
    //         </div>
    //       </div>
    //       {/*<Footer/>*/}
    //     </div>
    //   );
    // }
    //
    // if (this.props.location.location.pathname === '/' || this.props.location.location.pathname === '/recovery')
    //   return (
    //     <div className="wrapper pb0 pt0">
    //       <div className="registration-body">
    //         <Header/>
    //         <LeftMenu />
    //         <div className="flex center">
    //           {this.props.children}
    //         </div>
    //         {/*<Footer/>*/}
    //       </div>
    //     </div>
    //   );
    //
    // if (this.props.location.location.pathname === '/about' || this.props.location.location.pathname === '/request')
    //   return (
    //     <div className="wrapper about">
    //       <Header/>
    //       <LeftMenu />
    //       {this.props.children}
    //       {/*<Footer/>*/}
    //     </div>
    //   );
    //
  }
}
