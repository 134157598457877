import Loading from "components/UI/Prealoders/Loading";
import SbContactsList from "components/UI/SbContacts/SbContactsList";
import SideBlock from "components/UI/SideBlock/index";
import PropTypes from 'prop-types';
import { GET_RECCOMEND_USER } from "query/reccomendUser";
import React, { PureComponent } from 'react';
import { Query } from "react-apollo";
import Language from "language/index";


class ReccommendUsers extends PureComponent {

    static propTypes = {
        UserGetter: PropTypes.object,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        UserGetter: {
            getRecommendedUser: {
                users: [],
                total: 0
            }
        },
        loading: false
    };

    render() {
        const { total, users } = this.props.UserGetter.getRecommendedUser,
            { loading } = this.props;
        return (
            <SideBlock
                count={total}
                title={Language.YouMayInterested}
            >
                {
                    loading ?
                        <Loading/> :
                        <SbContactsList isStatus users={users}/>
                }
            </SideBlock>
        )
    }
}


export default props => {
    return (
        <Query query={GET_RECCOMEND_USER} variables={{ userType: props.userType }}>
            {({ loading, error, data }) => {
                if (error) return 'Error...';
                return (
                    <ReccommendUsers loading={loading} {...data} {...props}/>
                )
            }}
        </Query>
    )
};