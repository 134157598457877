import classes from './styles.module.scss';
import { SearchIcon } from 'components/v1/Icons/SearchIcon';
import { SettingsIcon } from 'components/v1/Icons/SettingsIcon';
import { SettingsRequesIcon } from 'components/v1/Icons/SettingsRequesIcon';

export const SearchPanel = ({ onChange, onOpenModal }) => {
  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <SearchIcon />
      </div>
      <input onChange={onChange} type="text" placeholder="Поиск контактов"/>
      <div className={classes.settings} onClick={onOpenModal}>
        <SettingsRequesIcon />
      </div>
    </div>
  )
}
