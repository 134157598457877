import React, { PureComponent } from 'react'
import { Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Avatar } from 'components/UI/Avatar/index'
import ContactStatus from 'components/UI/ContactStatus/index'

import MappingLocation from 'libs/MappingLocation'
import { ADD_CONTACT } from 'mutation/Contacts'
import { GET_CONTACTS } from 'query/contacts'

import { client } from '../../../apollo'


const ContactName = ({ firstName, lastName, _id }) => {
  return (
    <div className="contact-name">
      <Link to={`/profile/${_id}`}>
        {`${firstName}  ${lastName}`}
      </Link>
    </div>
  )
};

const ContactMoreInfo = ({ address }) => {
  return (
    <div className="contact-more-info">
      <MappingLocation location={{ ...address }}/>
    </div>
  )
};


class SbContactsItem extends PureComponent {

  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.string,
    isStatus: PropTypes.bool,
    _id: PropTypes.string,
    called: PropTypes.object,
    address: PropTypes.object,
    isContacts: PropTypes.bool,
    typeKeys: PropTypes.array,
  };

  static defaultProps = {
    firstName: '',
    lastName: '',
    avatar: '',
    _id: '',
    called: {},
    address: {},
    isContacts: false,
    typeKeys: [],
    isStatus: false
  };


  render() {

    const {
      firstName,
      lastName,
      avatar,
      _id,
      called,
      isContacts,
      birthday,
      address,
      isStatus,
      typeKeys,
      name,
    } = this.props;

    return (
      <div
        className="sb-contact clearfix"
        style={{ minHeight: '90px' }}
      >
        <Link
          to={`/profile/${_id}`}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}
        >
          <Avatar size={90} avatar={avatar}/>
        </Link>
        <div className="sb-contact-info">
          <ContactName
            firstName={firstName}
            lastName={lastName}
            _id={_id}
          />
          <ContactMoreInfo
            birthday={birthday}
            address={address}
          />
          <ContactStatus
            className="contact-status"
            type={typeKeys[0]}
          />
          {!!name && (
            <div style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: '11px',
              lineHeight: '15px',
              color: '#D98B4D',
            }}>
              “{name}”
            </div>
          )}
          {!isStatus && (
            <React.Fragment>
              <button
                className={`gray-btn s-btn mt10 ${isContacts ? 'added' : 'add'}`}
                onClick={() => {
                  !isContacts && this.props.mutate()
                }}
              >
                <span>{`${isContacts ? 'В контактах' : called.loading ? 'Loading...' : 'Контакт'}`}</span>
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}


export default props => (
  <Mutation
    mutation={ADD_CONTACT}
    variables={{ contact: { userId: props._id } }}
    refetchQueries={[{ query: GET_CONTACTS, variables: { status: 'is_added', count: 50 } }, props.refetchQuery]}
    update={(cache, { data: { ContactSetter } }) => updateContact(cache, ContactSetter, props.queryUpdate)}
  >
    {(addContact, called) => {
      return <SbContactsItem mutate={addContact} {...props} called={called}/>
    }}
  </Mutation>
);


const updateContact = (cache, ContactSetter, queryUpdate) => {
  if (queryUpdate) {
    let data = client.readQuery({ ...queryUpdate });
    data.UserGetter.getRecommendedUserByUserType.users.map(el => {
      if (el._id === ContactSetter.addContact._id) el.isContacts = true;
      return el
    });
    client.writeQuery({
      ...queryUpdate,
      data: { ...data }
    });
  }
};



