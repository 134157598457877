import Input from 'components/UI/Fields/Input';
import { submitPhoneVerificationForm } from "components/UI/Forms/PhoneVerificationForm/submit";
import Language from "language/index";
import { SEND_CODE } from "mutation/index";
import React, { Component } from 'react';
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { ActionNotification } from "components/UI/Notifications/action";
import { Link } from "react-router-dom";


class PhoneVerificationForm extends Component {

    state = { className: 'r-window' };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ className: 'r-window active' })
        }, 10)
    }

    render() {
        const { handleSubmit, counter, resendCode } = this.props;
        return (
            <div className={this.state.className}>
                <div className="flex">
                    <div>
                        <article>{Language.codeSMS}  </article>
                        <p> {Language.codePhone} </p>
                        <p> {Language.recoverPassword.one} <Link to="/support">{Language.recoverPassword.two}</Link></p>
                    </div>
                    <div>
                        <div className="recovery-title">{Language.VerifyYour}<br/> {Language.Phone}</div>
                        <div className="form-pass-recovery">
                            <form onSubmit={handleSubmit}>
                                <Field
                                    strong={Language.yourPhone}
                                    disabled
                                    component={Input}
                                    name="phone"
                                />
                                <Field
                                    strong={Language.codeFromSMS}
                                    counter={counter}
                                    name="code"
                                    component={Input}
                                />

                                <div className="link-wrap">
                                    <a href="/" onClick={
                                        e => {
                                            e.preventDefault();
                                            if (counter === 0) { resendCode() } }
                                    }
                                       className={`${counter !== 0 ? 'disabled' : ''}`}>{Language.resendCode}</a>
                                </div>
                                <button type="submit" className="orange-btn">{Language.send}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const PhoneVerificationFormRedux = reduxForm({
    form: 'phoneVerification',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('phoneVerification'));
        }
    }
})(PhoneVerificationForm);

export default props => (
    <Mutation
        mutation={props.mutate}
        onCompleted={() => props.nextStep()}
    >
        {mutate => (
            <PhoneVerificationFormRedux
                {...props}
                onSubmit={values => props.submit ? props.submit(values) : submitPhoneVerificationForm(values, mutate)}
            />
        )}
    </Mutation>
)

