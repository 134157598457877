import { Query } from 'react-apollo';
import cn from 'classnames'
import { GET_DIALOG_BY_ID } from 'query/dialogs';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import { GET_MY_ID } from 'query/profile';
import { getItem } from 'libs/localStorage';
import { UPDATE_MESSAGES } from 'subscription';
import { NotFound } from '../MessagesList/NotFound';
import { GET_MESSAGES } from 'query/messages';
import { UserInfo } from './UserInfo';
import { MessagesList } from './MessagesList';
import { SendMessage } from './SendMessage';
import { useSizeSM } from 'libs/useMediaQuery';

import classes from './MessagesList.module.scss';

export const MessagesListComponent = props => {
  const { activeChatId } = props;
  const { userFrom, userTo, offer, favorite } = props.getDialogById;
  const { _id } = props.getMyProfile
  const user = userFrom._id === _id ? userTo : userFrom;

  const isMobile = useSizeSM()

  return (
    <>
      <UserInfo {...user} isMobile={isMobile} closeChat={props.closeChat} />
      <Query query={GET_MESSAGES} variables={{ "dialogId": activeChatId, count: 50 }}
             fetchPolicy='cache-and-network'>
        {({ loading, data, error, subscribeToMore, fetchMore }) => {
          if (error) return <p> Error.... </p>;
          if (loading) return <PreloaderCircle/>;
          return (
            <div className={cn('side-block', classes.scrollable)} id="scrollable_messages">
              <div className={cn('s-messages', classes.containerMess)} id="scroll_block_messages">
                <MessagesList
                  subscribeToNewComments={() => subscribe(subscribeToMore, activeChatId, _id)}
                  {...data.DialogGetter.getMessagesByDialogId}
                  userFrom={userFrom}
                  userTo={userTo}
                  offer={offer}
                  _id={_id}
                  dialogId={activeChatId}
                />
              </div>
            </div>
          )
        }}
      </Query>
      <SendMessage dialogId={activeChatId} client={props.client} />
    </>
  )
}

export const MessagesListMain = props => (
  <div className={classes.root}>
    {!props.activeChatId && <NotFound/>}
    {props.activeChatId && (
      <Query query={GET_DIALOG_BY_ID} variables={{ 'dialogId': props.activeChatId }} fetchPolicy="cache-and-network">
        {({ loading, data, client, error }) => {
          if (error) return <p> Произошла ошибка получения диалога </p>;
          if (!data.getDialogById && loading) return <PreloaderCircle/>;
          let { ProfileGetter } = client.cache.readQuery({ query: GET_MY_ID });
          return <MessagesListComponent
            client={client}
            {...data.DialogGetter}
            {...ProfileGetter}
            {...props}
          />
        }}
      </Query>
    )}
  </div>
);

const subscribe = (subscribeToMore, dialogId) => {
  const token = getItem('token', null);
  subscribeToMore({
    document: UPDATE_MESSAGES,
    variables: { dialogId, token },
    updateQuery: (prev, { subscriptionData }) => {
      console.log(prev);
      console.log(subscriptionData);
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.updateMessages;
      let { messages } = prev.DialogGetter.getMessagesByDialogId;
      if (messages.some(el => el._id === newFeedItem._id)) {
        messages.map(el => {
          if (el._id === newFeedItem._id) {
            el.isRead = true;
          }
          return el
        });

        return {
          ...prev,
          DialogGetter: {
            getMessagesByDialogId: {
              messages: [...messages],
              total: prev.DialogGetter.getMessagesByDialogId.total,
              __typename: 'Messages'
            },
            __typename: 'getDialogs'
          }
        }

      }
      return {
        ...prev,
        DialogGetter: {
          getMessagesByDialogId: {
            messages: [newFeedItem, ...prev.DialogGetter.getMessagesByDialogId.messages],
            total: prev.DialogGetter.getMessagesByDialogId.total + 1,
            __typename: 'Messages'
          },
          __typename: 'getDialogs'
        }
      }
    }
  })
};
