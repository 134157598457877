import { client } from 'apollo';
import { removeItem } from 'libs/localStorage';
import forwardTo from 'libs/forwardTo';

export const logout = () => {
  client.cache.restore({
    isAuth: false,
    notification: {
      type: '',
      text: '',
      isOpen: false,
      __typename: 'Notification'
    }
  });
  removeItem('token');
  forwardTo('/')
};
