// it's for Education and Career forms
import React, { Fragment, useMemo } from 'react'
import get from 'lodash/get'

import { InputController } from 'components/v1/HookFields/Input';
import { SelectController } from 'components/v1/HookFields/Select';
import { Button } from 'components/v1/Button';
import { PlusIcon } from 'components/v1/Icons/PlusIcon';
import { CloseIcon } from 'components/v1/Icons/CloseIcon';
import { required } from 'libs/rules';

import styles from 'pages/Profile/EditForms/styles.module.scss';

export const FormfieldArray = ({
  fields,
  control,
  append,
  remove,
  fieldNames,
  name,
  appendValues,
  errors,
}) => {

  const optionsYear = useMemo(() => {
    const options = []

    for (let i = 2022; i >= 1920; i--) {
      options.push({ value: `${i}-01-01T20:00:00.000Z`, label: i })
    }

    return options
  }, [])

  return (
    <Fragment>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <div className={styles.section}>
            <div>{fieldNames[0].label}</div>
            <InputController
              control={control}
              key={field.id}
              name={`${name}.${index}.${fieldNames[0].name}`}
              rules={{required}}
              errors={get(errors, `[${name}][${index}][${fieldNames[0].name}]`, '')}
            />
          </div>
          <div className={styles.section}>
            <div>{fieldNames[1].label}</div>
            <InputController
              control={control}
              key={field.id}
              name={`${name}.${index}.${fieldNames[1].name}`}
              rules={{required}}
              errors={get(errors, `[${name}][${index}][${fieldNames[1].name}]`, '')}
            />
          </div>
          {/*{name === 'career' && ( // костыль для старого бека*/}
            <div className={styles.section}>
              <div>{fieldNames[2].label}</div>
              <div style={{ maxWidth: 289, width: '100%' }}>
                <SelectController
                  control={control}
                  label={''}
                  options={optionsYear}
                  key={field.id}
                  name={`${name}.${index}.${fieldNames[2].name}`}
                  rules={{required}}
                  errors={get(errors, `[${name}][${index}][${fieldNames[2].name}]`, '')}
                />
              </div>
            </div>
          {/*)}*/}
          <div className={styles.section}>
            <div>{fieldNames[3].label}</div>
            <div style={{ maxWidth: 289, width: '100%' }}>
              <SelectController
                control={control}
                label={''}
                options={optionsYear}
                key={field.id}
                name={`${name}.${index}.${fieldNames[3].name}`}
                rules={{required}}
                errors={get(errors, `[${name}][${index}][${fieldNames[3].name}]`, '')}
              />
            </div>
          </div>
          {index > 0 && (
            <div className={styles.section}>
              <div />
              <div>
                <Button type="button" variant="normal" color={"red"} onClick={() => remove(index)}>
                  <CloseIcon /> Удалить информацию
                </Button>
              </div>
            </div>
          )}
          {/*<div className="separator" />*/}
        </Fragment>
      ))}
      {/*<div className={styles.section}>*/}
      {/*  <div />*/}
      {/*  <div>*/}
      {/*    <Button type="button" variant="normal" onClick={() => append(appendValues)}>*/}
      {/*      <PlusIcon /> {fieldNames[4].label}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Fragment>
  )
}
