import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { change } from "redux-form";

export default class DatePickers extends PureComponent {

  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.string,
    mask: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    input: {},
    meta: {},
    type: 'text',
    label: '',
    mask: '',
    placeholder: '',
    className: 'element',
    disabled: false
  };

  state = {
    val: this.props.input.value ? new Date(this.props.input.value) : null
  };

  setDate = val => {
    this.props.meta.dispatch(change(this.props.meta.form, this.props.input.name, val));
    this.setState({ val })
  };

  render() {
    const { input, label, type, className, meta, mask, placeholder, change, disabled } = this.props;
    const Language = {};
    return (
      <React.Fragment>
        <div className={`${className} ${meta.error && 'error'}`}>
          <p>{label}</p>
          <div className="input calendar">
            <DatePicker
              selected={this.state.val}
              onChange={this.setDate}
              dropdownMode="select"
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              placeholderText={placeholder}
              className={meta.error && 'error'}
            />
          </div>

          {
            meta.error &&
            <span className="error">{meta.error}</span>
          }
        </div>
      </React.Fragment>
    );
  }
}