import Input from "components/UI/Fields/Input";
import Industry from "components/UI/Forms/GroupFields/Industry";
import { NameFieldGroup } from "components/UI/Forms/GroupFields/NameFieldGroup";
import Region from "components/UI/Forms/GroupFields/Region";
import TimePeriod from "components/UI/Forms/GroupFields/TimePeriod";
import Language from "language/index";
import { SET_ENTITY_PROFILE } from "mutation/profile";
import { GET_MY_PROFILE } from "query/index";
import React from 'react';
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from "redux-form";
import { ActionNotification } from "../../Notifications/action";
import { submitLegalEntityFormForm } from "./submit";
import ym  from "react-yandex-metrika";

let ProfileLegalEntityForm = (props) => {
    let { handleSubmit, invalid, initialValues } = props;

    return (
        <div className="elements-group">
            <div className="form fullWidth">
                <form onSubmit={handleSubmit} id="profile-form">
                    <NameFieldGroup/>
                    <Field
                        name="companyName"
                        type="text"
                        component={Input}
                        label={`${Language.CompanyNamed} *`}
                        placeholder="Введите название компании"
                    />
                    <Industry
                        {...initialValues}
                        isReq
                    />
                    <TimePeriod
                        label={`${Language.periodWork} *`}
                        placeholder="Введите срок работы на рынке"
                        name="termOfWork"
                    />
                    <Region
                        {...initialValues}
                    />
                    <Field
                        name="email"
                        type="text"
                        component={Input}
                        label="Email *"
                        placeholder="Введите email"
                    />
                    {/*<Field*/}
                    {/*name="phone"*/}
                    {/*type="text"*/}
                    {/*component={Input}*/}
                    {/*label="Телефон *"*/}
                    {/*placeholder="Введите телефон"*/}
                    {/*/>*/}
                    <Field
                        name="site"
                        type="text"
                        component={Input}
                        label={Language.website}
                        placeholder="Введите URL сайта"
                    />
                    <Field
                        name="description"
                        type="text"
                        component={Input}
                        label={Language.additionalInformationBlock}
                        placeholder="Введите дополнительную информацию"
                    />
                    <Field
                        name="personalLink"
                        type="text"
                        component={Input}
                        label={Language.urlProfileLabel}
                        placeholder="Введите URL адрес профиля"
                    />

                    <div className="stage save-settings-profile-btn">
                        <div className="elements-group">
                            <button type="submit" className="orange-btn">{Language.save}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

ProfileLegalEntityForm = reduxForm({
    form: 'profileLegalEntityForm',
    enableReinitialize: true,
    onChange: (newValues, dispatch) => {
        dispatch(stopSubmit('profileLegalEntityForm', {}));
    }
})(ProfileLegalEntityForm);

export default props => {
    return (
        <Mutation
            onCompleted={() => {
                ActionNotification('2', Language.profileUpdated);
                if (props.initialValues.__typename === 'Client') {ym('reachGoal', 'regsuccess')}
                if (props.cb) props.cb();
            }}
            mutation={props.mutation ? props.mutation : SET_ENTITY_PROFILE}
            refetchQueries={[{ query: GET_MY_PROFILE }]}
        >
            {mutate => (
                <ProfileLegalEntityForm  {...props}
                                         onSubmit={values => submitLegalEntityFormForm(values, mutate, props.cb)}/>
            )}
        </Mutation>
    )
};