import { useEffect } from 'react';
import cn from 'classnames';
import { Avatar } from 'components/v1/Avatar';
import { compareTime } from 'libs/getMessageTime';
import { dateToString } from 'libs/convertDate';
import { MessageReadIcon } from 'components/v1/Icons/MessageReadIcon';
import { CheckMessageIcon } from 'components/v1/Icons/CheckMessageIcon';

import classes from './MessagesList.module.scss';

export const MessagesItem = props => {
  const { readMessage, text, _id } = props;

  useEffect(() => {
    readMessage && readMessage();
  }, [])

  const { createDate, senderId, userFrom, userTo, prevMess, links, isRead } = props;
  const senderMsg = userFrom._id === senderId ? userFrom : userTo;
  let author = true;
  let isMy = false;
  if (prevMess) {
    if (prevMess.senderId === senderId && compareTime(createDate, prevMess.createDate)) {
      author = false;
    }
  }
  if (senderMsg._id === _id) {
    author = false;
    isMy = true;
  }

  return (
    <div className={cn(classes.item, { [classes.my]: isMy })}>
      {author && <Avatar source={senderMsg.avatar} className={classes.avatar} size="small" type="circle"/>}
      <div className={classes.message}>
        <div className={classes.text}>
          {text.split(' ').map(el => {
            if (!!el.match(/http:\/\/[\w\.\-]+\b/ig) || !!el.match(/https:\/\/[\w\.\-]+\b/ig)) {
              return (
                <a key={el} className="color_link" href={el} target="_blank">{el}</a>
              )
            } else return el + ' '
          })}

          {links.map((url, index) => {
            let path = url.split('.'),
              ext = path[path.length - 1];
            switch (ext) {
              default:
                return (
                  <a key={index} href={url} target="_blank" class="link">
                    { url.split('/')[url.split('/').length - 1] }
                  </a>
                );
              case 'jpg':
              case 'jpeg':
              case 'png':
              case 'gif':
                return <a key={index} href={url} target="_blank"><img src={url} alt={url}/></a>;
            }
          })}
        </div>

        <span className={classes.time}>
          {isMy && (
            <>
              {isRead ? <MessageReadIcon /> : <CheckMessageIcon />}
            </>
          )}
          &nbsp;
          {dateToString(new Date(createDate), 'time')}
        </span>
      </div>
    </div>
  )
}
