import Select from "components/UI/Fields/Select";
import IndustryData from "components/UI/Forms/GroupFields/IndustryData";
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from "redux-form";
import Language from "language/index";


class Industry extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        isReq: PropTypes.bool,
        industry: PropTypes.object
    };

    static defaultProps = {
        label: 'Отрасль',
        isReq: false,
        industry: {}
    };

    state = {
        industry: this.props.industry
    };


    setIndustryGroupName = option => {
        if (option)
            this.setState({
                industry: {
                    name: null,
                    groupName: option.value
                }
            })
    };

    render() {
        let { isReq, label } = this.props,
            { industry } = this.state,
            options = [];
        isReq = isReq ? '*' : '';
        if (industry.groupName) {
            options = IndustryData[`_${industry.groupName}`]
        }

        return (
            <React.Fragment>
                <Field
                    name="industry[groupName]"
                    type="text"
                    component={Select}
                    label={`Отрасль ${isReq}`}
                    placeholder={Language.ChooseIndustry}
                    options={IndustryData.all_industries}
                    change={this.setIndustryGroupName}
                />
                {
                    industry.groupName &&
                    <Field
                        name="industry[name]"
                        type="text"
                        component={Select}
                        label={`Подкатегория отрасли ${isReq}`}
                        placeholder="Выберите доп отрасль"
                        options={options}
                    />
                }

            </React.Fragment>
        );
    }
};

export default Industry;
