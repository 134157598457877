import React from 'react';
import PropTypes from 'prop-types';
import Language from "language/index";

function DescriptionType({ userType }) {
    switch(userType) {
        case 'Investor':
            return (
                <React.Fragment>
                    <p><b>{Language.DescriptionInvestor.one}</b></p>
                    <p>{Language.DescriptionInvestor.two}</p>
                </React.Fragment>
            );
        case 'Innovator':
            return (
                <React.Fragment>
                    <p><b>{Language.DescriptionNovator.two}</b></p>
                </React.Fragment>
            );
        case 'BusinessProduct':
            return (
                <React.Fragment>
                    <p><b>{Language.DescriptionBusinessSeller.one}</b></p>
                    <p>{Language.DescriptionBusinessSeller.two}</p>
                    <p>{Language.DescriptionBusinessSeller.three}</p>
                </React.Fragment>
            );
        case 'Provider':
            return (
                <React.Fragment>
                    <p><b>{Language.DescriptionProvider.one}</b></p>
                    <p>{Language.DescriptionProvider.two} <b style={{fontSize: '13px'}}>{Language.DescriptionProvider.bold}</b></p>
                </React.Fragment>
            );
        case 'BusinessWoman':
            return (
                <React.Fragment>
                    <p><b>{Language.DescriptionBusinessLady.one}</b></p>
                </React.Fragment>
            );
        case 'Unemployed':
            return (
                <React.Fragment>
                    <p><b>{Language.DescriptionUnemployer.one}</b></p>
                </React.Fragment>
            );
        default:
            return <React.Fragment />
    }
}

DescriptionType.propTypes = {
    userType: PropTypes.string.isRequired,
};

export default DescriptionType;
