import Language from "language/index";


const IndustryData =  {
    _34b7a4c03f1540c58b15a9b49415bfaf: [
        {
            value: 'fe508d085e034335951e77b13685dd61',
            label: Language.AutomotiveBusiness._1
        }, {
            value: '00a748ad55ca4dbea0d91e1967022618',
            label: Language.AutomotiveBusiness._2
        }, {
            value: '4b0b1ffd08b34beaa997363e1ba0a2b2',
            label: Language.AutomotiveBusiness._3
        }, {
            value: 'ab1c0dc2f7d54f59ada15564db2bb108',
            label: Language.AutomotiveBusiness._4
        }, {
            value: '15b04fdba0384d5e86d91b67aa736cfd',
            label: Language.AutomotiveBusiness._5
        }, {
            value: 'aeab82229fd0424c9a35852ab0171f99',
            label: Language.AutomotiveBusiness._6
        }, {
            value: 'dc0e1f5412a9450a9dd1f1d032371b33',
            label: Language.AutomotiveBusiness._7
        }, {
            value: 'af239e31e08440ca9ff8069ef9055f55',
            label: Language.AutomotiveBusiness._8
        }],
    _658dc84f185345758daabdd24a2bc1aa: [
        {
            value: 'a515d357a79b4dfb884e00269cad837a',
            label: Language.Hotels._1
        }, {
            value: 'c52011fdeb144e1f89e2d3932b6c3a52',
            label: Language.Hotels._2
        }, {
            value: '32b42b4c2ecb46f0aed07c9fe36f4af0',
            label: Language.Hotels._3
        }],
    _eabaf7e8f45448b99a7aa76250093af1: [
        {
            value: '6ee88aa9ef5e4d03a8ee1b1177a765a6',
            label: Language.Mining._1
        }, {
            value: '9704abeb15154426b1442781fc16e1b3',
            label: Language.Mining._2
        }, {
            value: '2a55f29ed3dd4fe88d518d9b34c8807c',
            label: Language.Mining._3
        }, {
            value: 'd483be45a13a4235a7218498f2cff2ac',
            label: Language.Mining._4
        }, {
            value: '090a3c3be5e54e8296e733e8a3142f64',
            label: Language.Mining._5
        }],
    _d7ff765e8db84cadbd131b7ef882b950: [
        { value: 'e6c4d84d13a240468784c7c2b6028e53', label: Language.Utilities._1 },
        { value: '70ce174ea35e4c358b7cd8724d3490f1', label: Language.Utilities._2 },
        { value: '2c8edae6f7704861be1ed7582df1d5b6', label: Language.Utilities._3 },
        { value: '45a16ad081c04e829c26d131a86b1b24', label: Language.Utilities._4 },
        { value: '8b78179fd6e74cad93a95fa3f9eedb7c', label: Language.Utilities._5 },
        { value: '6fce52427e884251b6ebee6b8b414ace', label: Language.Utilities._6 },
        { value: '1c4397b0c4a4409eb3c04e44a16434ee', label: Language.Utilities._7 },
        { value: '0869a1edfecc4701b3eeb5e625b70941', label: Language.Utilities._8 },
        { value: '349f37bcdb034ff19d943fa0b0e33782', label: Language.Utilities._9 },
    ],
    _9faabc7dc2314f61bbb6c0a866134f8e: [
        {
            value: 'a540994506cb4038b056035129da2fa0',
            label: Language.ItSystem._1
        },
        { value: 'ae92dd29820d4a97aa4a208b85e4dadb', label: Language.ItSystem._2 },
        { value: 'a8f8af339aa641c88234f6b388810e57', label: Language.ItSystem._3 },
        {
            value: '61255f37851b4d1d86e9bcbbdc41db52',
            label: Language.ItSystem._4
        },
    ],
    _331d7f2e892744dca4c550e50dc8dd71: [
        { value: '874b5d1e5df44fb080e443612096ce8c', label: Language.Art._1 },
        { value: 'da7a3636713d41e0a442c7246b57bf01', label: Language.Art._2 },
        { value: '8a1f1ffa5f474440a971266ef2c634e8', label: Language.Art._3 },
        { value: '36c18701f7314322a850c21a245e0ca6', label: Language.Art._4 },
        { value: '4151605eca894df2a531ec871a712488', label: Language.Art._5 },
    ],
    _e57fa759d16848c98a572d18dcbf827f: [
        { value: '7d0ad13f29684cad9998af4bd1273b82', label: Language.TimberIndustry._1 },
        { value: '7f9b74a0da2e4f7f81e79402eaefa7c6', label: Language.TimberIndustry._2 },
        {
            value: '8fb86d6666a24a8eb488f38d7e574155',
            label: Language.TimberIndustry._3
        },
        { value: '4978b1dfcf59496c9243d95e034c045f', label: Language.TimberIndustry._4 },
        {
            value: '1762587fc58845c0b89549f5c32c2e20',
            label: Language.TimberIndustry._5
        },
        { value: 'ed9c99045b444276b9221dcb546e57c7', label: Language.TimberIndustry._6 },
    ],
    _e9b69a047d2d4ff1bd5ecd63ba1ae68f: [
        { value: '9021f289893340938181480279166205', label: Language.Medicine._1 },
        { value: '545f515fe49d4eaa9bff872990d67231', label: Language.Medicine._2 },
        { value: '8a5ff069bb904ef5b8df06704134f72f', label: Language.Medicine._3 },
        { value: 'bcb8306e7d644a4fb9933c157a31746e', label: Language.Medicine._4 },
        { value: '36d5117b051741f986a494f13905dd56', label: Language.Medicine._5 },
        { value: '78f74863650745b2a0a91b30ab521fac', label: Language.Medicine._6 },
        { value: '08da4ea85e1c47e98330374bc186007c', label: Language.Medicine._7 },
        { value: 'afe47ea731ae437aa3e732ecf4cb427a', label: Language.Medicine._8 },
        { value: 'cee2a240788a43a993ba2274ca351eed', label: Language.Medicine._9 },
    ],
    _1b6ab6fd41aa446d908fb7cba78e40a6: [
        { value: 'ac179f1d25344fdda74dfb69779ac2c0', label: Language.Metallurgy._1 },
        {
            value: '2a6a7171cb3c47fabf0e63573b77d726',
            label: Language.Metallurgy._2
        },
        {
            value: '7f135d63b7c54eae9729934e3221aa75',
            label: Language.Metallurgy._3
        },
        { value: 'bf5692e2cebe45b29a629871732d6b37', label: Language.Metallurgy._4 },
        {
            value: '2777d843f6eb45f7af5b9d6224b467a2',
            label: Language.Metallurgy._5
        },
        {
            value: 'f4db7c231ef549628ec3220bf6a4ecf7',
            label: Language.Metallurgy._6
        },
        { value: '660773d05d574db7b9892ef673b0e0b0', label: Language.Metallurgy._7 },
        { value: '9350115c0d144ce39ea3e519a03e8fcf', label: Language.Metallurgy._8 },
    ],
    _919a197be2d94bd1a01b43b6c1d41d23: [
        { value: 'd0139895f66d4dfca878532ed083f42a', label: Language.OilGas._1 },
        { value: '3021c9f685ef4a72b4a0d2bb51c11076', label: Language.OilGas._2 },
        { value: '7a4939d77e154ab5b77515dd263711c0', label: Language.OilGas._3 },
        { value: '27a7fc1fa59c45bd848b346b1c2e0dd6', label: Language.OilGas._4 },
        { value: '0579628ad9564c1f9c0abdab526f8bd4', label: Language.OilGas._5 },
        { value: '58e5ad1543d64a7cb1b6d57a20db6eef', label: Language.OilGas._6 },
        { value: 'a5ee2f54a8d145cbb2eb85c085446429', label: Language.OilGas._7 },
        { value: 'd16ab8199d5a494592c25dae6c396787', label: Language.OilGas._8 },
        { value: 'c1ac1c17f1d4487381648f3ca589f1cd', label: Language.OilGas._9 },
        { value: '3c575efcbc984fdbb7af0544e2914585', label: '' }],
    _13d41716a3a24be88b07440bbcee0a01: [
        { value: '5f5d41b28c684b2f8ff36c0e1fc8297e', label: Language.EducationalInstitutions._1 },
        { value: '6564d164ea74487e95abb024ea31d2be', label: Language.EducationalInstitutions._2 },
        { value: 'df50c318963047ea9889120bb2941ef4', label: Language.EducationalInstitutions._3 },
        { value: 'e1db522bc8cb4381b21fae7421c48157', label: Language.EducationalInstitutions._4 },
        {
            value: '2a97c1e2ebb14cf0a873097a87497011',
            label: Language.EducationalInstitutions._5
        },
        { value: 'd8477a45258e40fea0c4dfd434186719', label: Language.EducationalInstitutions._6 },
        { value: '7a9d1bdbfd0346038b575649f5ff69ab', label: Language.EducationalInstitutions._7 },
        { value: '6e3e0fc80659433d83de4c480bcd44f2', label: Language.EducationalInstitutions._8 },
        { value: '739e277c5b54402797609473d4cd3a1c', label: Language.EducationalInstitutions._9 },
        { value: 'd7d65aced4364f62b7fce3853a7215d0', label: Language.EducationalInstitutions._10 },
        { value: '27c25581720b4befae6533a95845b98e', label: Language.EducationalInstitutions._11 }],
    _5b698abde9be48c19cfb3bbac06d2dd0: [
        { value: '9564826c7e624938ab3c14a95dd1cf4d', label: Language.Volunteering._1 },
        { value: '3dd465662c0d4cfab0ff9c9a512d873a', label: Language.Volunteering._2 },
        { value: '62c0a6ee74f749fdb97206f4837e8625', label: Language.Volunteering._3 },
        { value: 'd094e37a393e4e72bb63a8e50ebc72a0', label: Language.Volunteering._4 },
        { value: '70d4537a63164c7fb1589ceaf6a4d89d', label: Language.Volunteering._5 },
        { value: '2377eecc539d4b43836bc1e396348202', label: Language.Volunteering._6 },
        { value: '0f42c93957d1475da98e6945ded4a833', label: Language.Volunteering._7 }],
    _aea553c1e0b74285a8380d88653befed: [
        { value: '839881c23d674371983fc4f64012b5d8', label: Language.Transportation._1 },
        { value: '56eb990903374d8c8e0293b3842b796a', label: Language.Transportation._2 },
        { value: '160eb0a940a546bfb1abcaf74ffbb85e', label: Language.Transportation._3 },
        { value: 'e2b0222bce2c4ed8a0cc7fd0787e37c9', label: Language.Transportation._4 },
        { value: '798bc6a66ad54854917d6e41bab78b8b', label: Language.Transportation._5 },
        { value: '6a3cb082c9ff421d8ee812051b23258d', label: Language.Transportation._6 },
        { value: '2bcae513d1fb479d8d6bec4b43324e22', label: Language.Transportation._7 },
        {
            value: '228562c5170c44cf8dc6268611265cf5',
            label: Language.Transportation._8
        }],
    _f72b930863024e83baf3adac1c3d4828: [
        { value: 'e000e96c996943118f670677279e67ba', label: Language.FoodProducts._1 },
        { value: '8673b335070842f699cda789ff6ff4aa', label: Language.FoodProducts._2 },
        { value: '765a733964464fcf82e42e33ed397625', label: Language.FoodProducts._3 },
        { value: 'b32c944d7e864725a18308d71f09d434', label: Language.FoodProducts._4 },
        { value: 'd3061cbdbe034bc7817c04c8f0729072', label: Language.FoodProducts._5 },
        { value: '66dfae0cc3a64ba3b9a2a0885c5d7981', label: Language.FoodProducts._6 },
        { value: '007e86a3044642499881aa6ce40850da', label: Language.FoodProducts._7 },
        { value: '0658fa5a7a844f08bf286f53a8b8e576', label: Language.FoodProducts._8 },
        { value: '503dd886011847a190eb95d1bd94fd1a', label: Language.FoodProducts._9 },
        { value: '15cb114105e34f58bdeb06d4ffdcd969', label: Language.FoodProducts._10 },
        { value: '714455d7a9404b2988e63782f5cde1f5', label: Language.FoodProducts._11 },
        { value: 'ffb241287a194cbe89a15aa499d1b8cd', label: Language.FoodProducts._12 },
        { value: 'b099a835362b455aabf32fc8f3cb6aba', label: Language.FoodProducts._13 },
        { value: '163f3fbb809141b1bc1fce46dec79775', label: Language.FoodProducts._14 },
        { value: '0db4a7f040fb4ce9a6e0ccdd23071a63', label: Language.FoodProducts._15 },
        { value: 'd770a4587ce249da843af7a675161a46', label: Language.FoodProducts._16 },
        { value: '38cda81b1b1f48f0a4bc12f8d0485d60', label: Language.FoodProducts._17 },
        { value: '4c00ce0ccf524c2eb2b875f889df5ceb', label: Language.FoodProducts._18 },
        { value: 'c05ab57c71944f7a8721e86a716929ef', label: Language.FoodProducts._19 },
        { value: '24394d4f2170433eb90e8d0dc000595a', label: Language.FoodProducts._20 },
        { value: '6c61ef29df3f4f37aa6aa11f01104199', label: Language.FoodProducts._21 },
        { value: '49f1e389eef84e21a57b8f1540cc2f9e', label: Language.FoodProducts._22 },
        { value: '425480fde4594d02bb7fa6dbf8be6b4f', label: Language.FoodProducts._23 },
        { value: 'd8e62f1bfa1b44d090e1ed2d121f3943', label: Language.FoodProducts._24 },
        { value: 'cc406a3c1c094040968a78cc8f275c09', label: Language.FoodProducts._25 },
        { value: '68f9d82879fb466b97fd330688c860fd', label: Language.FoodProducts._26 },
        { value: '9139cd81604a43758925c4d5451c9e1e', label: Language.FoodProducts._27 },
        { value: 'f714a51b6ee94931ae27d4cc7af101e4', label: Language.FoodProducts._28 },
        { value: '7b7496a0ae134c0fb7026b0c22251f2c', label: Language.FoodProducts._29 },
        { value: '4000a010a511478b892d232c999b3789', label: Language.FoodProducts._30 },
        { value: '4c0b7c2e0e024cdfa71b7faf2bbc5fd7', label: Language.FoodProducts._31 },
        { value: 'd1ba1e42b21d4c7a99e07ab48ec428b6', label: Language.FoodProducts._32 },
        { value: '5ac8f6cd9e5a44419b7e22a6518ed662', label: Language.FoodProducts._33 },
        { value: 'ece9a036d4e34622943af19e438a5218', label: Language.FoodProducts._34 },
        { value: 'cc61929d316744f6b94dbc8ac9d45a95', label: Language.FoodProducts._35 },
        { value: '6b86dcdfe9ae4a5f96ceb481a62d2cc2', label: Language.FoodProducts._36 },
        { value: 'aa26d6c3295c4a55ba9e0b1872291b7f', label: Language.FoodProducts._37 },
        { value: '76f1612a3092434b9c08e59b76b3bd45', label: Language.FoodProducts._38 },
        { value: 'ac6b28e985d1439a8c563f0f6b22023a', label: Language.FoodProducts._39 }],
    _103c7b3eddf74d87ac8d8ad521c6caa6: [
        {
            value: '0f932466d1e040cdb4056696bb441d8a',
            label: Language.IndustrialEquipment._1
        },
        {
            value: '6b53b374373d4aca9ed50db434e7bb7a',
            label: Language.IndustrialEquipment._2
        },
        {
            value: '9501a066c4484e91ab4b69280e2c37c3',
            label: Language.IndustrialEquipment._3
        },
        {
            value: '7b4d7a415e1d43ce906799460e962521',
            label: Language.IndustrialEquipment._4
        },
        {
            value: 'e9bd07f40b71480399a40390c6665620',
            label: Language.IndustrialEquipment._5
        },
        {
            value: 'c5329c98f4e546a3a481bebf34b128a1',
            label: Language.IndustrialEquipment._6
        },
        {
            value: '046671c9f98c4c56ba2a1142746c86bb',
            label: Language.IndustrialEquipment._7
        },
        {
            value: 'cde20b0d2c4b426aaf68772329688256',
            label: Language.IndustrialEquipment._8
        },
        {
            value: 'fcadcea9682a4fe4a3393d926ce4dc59',
            label: Language.IndustrialEquipment._9
        },
        {
            value: '5ff2709bb67d4ea0ba9f18856a08ede2',
            label: Language.IndustrialEquipment._10
        },
        {
            value: '4dfbac22e9414e868d47f479fb3768d3',
            label: Language.IndustrialEquipment._11
        },
        {
            value: 'b081388c1e664cb28b0d8f3756fd7a5f',
            label: Language.IndustrialEquipment._12
        },
        {
            value: 'bf083c022259455586d9f70ec77e5fa0',
            label: Language.IndustrialEquipment._13
        },
        {
            value: '69986f45cd0245b796f88903ff50dddc',
            label: Language.IndustrialEquipment._14
        },
        {
            value: '6845c9c0915f48e2a23c49852b80fccd',
            label: Language.IndustrialEquipment._15
        },
        {
            value: '9cfa36e5db8f4999820cccbfd23c5277',
            label: Language.IndustrialEquipment._16
        },
        {
            value: '1420556d88664b79a447115cfbedd3c6',
            label: Language.IndustrialEquipment._17
        },
        {
            value: 'c04dd61c12244c59b48ed133bb31c290',
            label: Language.IndustrialEquipment._18
        },
        {
            value: 'bc6d177dd70b4483ba07f0d020d11cf9',
            label: Language.IndustrialEquipment._19
        },
        {
            value: 'f0de5ce3d87d4c1f8e874ae25e14f29d',
            label: Language.IndustrialEquipment._20
        },
        {
            value: '88a8886fe29444bfb289ca2a0cb41250',
            label: Language.IndustrialEquipment._21
        },
        {
            value: 'ef945da1ff954144804510f13aeb9345',
            label: Language.IndustrialEquipment._22
        },
        {
            value: '82f511cb466841a2bf739d790e4e6089',
            label: Language.IndustrialEquipment._23
        },
        {
            value: '0c18c03ef7784c3a8b47b8de52a81c15',
            label: Language.IndustrialEquipment._24
        }],
    _4ae15d10e8ea4a94a279dc53b65270a9: [
        { value: 'fe09177e091b4d72ae1a0b6ade8ae945', label: Language.Retail._1 },
        { value: 'a302bd4f35514ac3b41da3f19be96632', label: Language.Retail._2 },
        { value: '4566fa611c9144f0877b2707aeb63465', label: Language.Retail._3 },
        { value: '4d46c24128b64a998a320e7244f75f16', label: Language.Retail._4 },
        { value: '68d9a205cecf42c2b19c9893a2b02866', label: Language.Retail._5 },
        { value: '22feef0decd046a8b7b160e58d483197', label: Language.Retail._6 },
        { value: '1ef0db54d5fa41f78ed09e58089b3cfc', label: Language.Retail._7 },
        { value: 'b56fb67f11574aeabbc746f7618af422', label: Language.Retail._8 },
        { value: '4601d88c48304cdfb5e6f1ec2af7f052', label: Language.Retail._9 },
        { value: '0840157b13db408fa1f8d3c22f06122e', label: Language.Retail._10 },
        { value: '6808e2e5218e4d9a8444663486169645', label: Language.Retail._11 },
        { value: '0c6a6cb169314b6f89ae64a0dbfac54c', label: Language.Retail._12 },
        { value: '8eb450ee7a8c4b27910715ac4ed6ad2a', label: Language.Retail._13 },
        { value: '9bbcd4cf7c564e1e97f3155df63211aa', label: Language.Retail._14 },
        { value: 'a2bee38be2274582947f6c20bdf393e8', label: Language.Retail._15 },
        { value: 'c41b347ca3c7493ab04e5a82477c9bf2', label: Language.Retail._16 }],
    _7ab4a926ef2d415594166188f9441f20: [
        { value: '3b0ccb1d91ae494482cdde6b0dbc485a', label: Language.Media._1 },
        { value: '30927a3d7dc1413a8e985e8b5648261e', label: Language.Media._2 },
        {
            value: '625ee72c4309452c8f6648f6b7c40bcc',
            label: Language.Media._3
        },
        { value: '225fb9bdc3a743d283d9368fe1251a63', label: Language.Media._4 },
        {
            value: '0898f03095c64e2e891eaadc463ac6cb',
            label: Language.Media._5
        },
        { value: 'cdbf5b3b154b4426991e934d884d8a30', label: Language.Media._6 },
        { value: '12e77c488f084651bd51f3b12487c7c9', label: Language.Media._7 },
        { value: '8fa623c538804e42981278b5058e7338', label: Language.Media._8 },
        { value: '19613de63a134ec3a8dfc5b09b7bcd16', label: Language.Media._9 }],
    _b2460ca7b6ca4332a95ed88408cda05e: [
        { value: '40e6c1ae312046128015bd249b0012b2', label: Language.Agriculture._1 },
        { value: 'd0cd3d683cb8413baf96beae599aab4e', label: Language.Agriculture._2 },
        {
            value: 'd1c08c24cd85498baf744af3f6b70a4c',
            label: Language.Agriculture._3
        }],
    _742c4cddb31346feaa056a224b0db07a: [
        { value: '5ce084cdbe41437e8866d400d29d235f', label: Language.Construction._1 },
        { value: 'c99d34ee00224ddab0ae6137157b6ff6', label: Language.Construction._2 },
        { value: 'db28d8131d51473e85b9b668d1175b56', label: Language.Construction._3 },
        { value: '3e9e795b30214f2a8184e0dcae1caff7', label: Language.Construction._4 },
        { value: 'f658fcc5bd0f42f79d901af7b3c6b1b7', label: Language.Construction._5 },
        { value: 'bf3352369f0e4bb5a50a12362ae36ed4', label: Language.Construction._6 },
        {
            value: 'e48994db5e80433f996fdd69d5fe2753',
            label: Language.Construction._7
        },
        { value: '3d0abd58c1b44e1a9c7a052914f40429', label: Language.Construction._8 },
        { value: 'b6d63d55b05244389dbba4ac35907cb8', label: Language.Construction._9 },
        { value: '13c8329e02f440fe9e76b90e9bc80cad', label: Language.Construction._10 },
        { value: 'b1a3c1980de742dda267f2cd389eac91', label: Language.Construction._11 },
        { value: '36198c4dca3245dea9ff115931464c9c', label: Language.Construction._12 },
        { value: 'a752dab6848f4ba7940cb986c26673cc', label: Language.Construction._13 }],
    _e807327ef859446ba3cd979ed306c23c: [
        { value: '96df841364c84264afe4b8bf2913ddfa', label: Language.Telecommunications._1 },
        { value: '9b55e75d98974a01a130d00d8533ab8d', label: Language.Telecommunications._2 },
        { value: '791242cd813a4842bb7f9fc344ea6dfd', label: Language.Telecommunications._3 },
        { value: '2cab827b14154efaab234126fa52d67d', label: Language.Telecommunications._4 },

    ],
    _6cc3369cc4c843dc9dca655894b81481: [
        {
            value: 'eefd68ce163949d893e2b3f895f7dff2',
            label: Language.FMCG._1
        },
        { value: 'dc34056101944b698c4fc0c5d1a29bfc', label: Language.FMCG._2 },
        {
            value: '4e3d768e972c4d4e9bce0cf1e02efed3',
            label: Language.FMCG._3
        },
        { value: '9eb90cf67890452e89c750770aca8a41', label: Language.FMCG._4 },
        { value: '2d647a35036843928f1f2818af14f8c6', label: Language.FMCG._5 },
        { value: '1fca5c0282df449eb82e02d4bbdbd8de', label: Language.FMCG._6 },
        {
            value: 'ecda7da734bd44d5901857a7f2c39a5d',
            label: Language.FMCG._7
        },
        { value: 'bb173cce4786438cad41fe18fbcb8d48', label: Language.FMCG._8 },
        {
            value: 'df3c507428ea4762b010cddeaf527636',
            label: Language.FMCG._9
        },
        { value: '0d550ede279f43b0bad85a718f3e4814', label: Language.FMCG._10 },
        { value: 'ea0b39ce69374c51bea475b797efad1b', label: Language.FMCG._11 },
        { value: 'cf81ab11f2f9429a9a06e65031ad27ba', label: Language.FMCG._12 },
        {
            value: 'd455e6b03b62467d8daed4e6d4ba6ff4',
            label: Language.FMCG._13
        },
        { value: 'bbdbc0110e244e4288cfc07dcca7c7a2', label: Language.FMCG._14 },
        {
            value: '4889a0a88ad240f59a739008cfafd012',
            label: Language.FMCG._15
        },
        {
            value: '8e1590984fc745eca437a822d21d0106',
            label: Language.FMCG._16
        },
        { value: '68fd5f344ba3411db15cf5dfff1a99e0', label: Language.FMCG._17 },
        { value: 'def5fd2d3631481d8d45d920576be88e', label: Language.FMCG._18 },
        { value: 'ce7866bbe0bc4eb8944b18085317aea7', label: Language.FMCG._19 },
        { value: '2494cd145f124e65a9cb36ca8124a9c4', label: Language.FMCG._20 },
        {
            value: '6b5f1982f43c4567b60af7fe942fe7e7',
            label: Language.FMCG._21
        },
        { value: 'b7973dd956ad498ebbd00213b0b5bce7', label: Language.FMCG._22 }],
    _283bf1eb0f3d4aa99189956f6e4d5d7c: [
        { value: 'c138ae30e10541789dc9ba933486ca01', label: Language.HeavyEngineering._1 },
        { value: 'd2f3f1a375f1473a90f06a3558bea837', label: Language.HeavyEngineering._2 },
        { value: '43c42eff05ad45869c549e74bab4f7b3', label: Language.HeavyEngineering._3 },
        { value: 'ee054e8b7fe644ada33f394e4ff02965', label: Language.HeavyEngineering._4 },
        { value: '2434397495e04efdae16bc46e32c7b93', label: Language.HeavyEngineering._5 },
        {
            value: 'c4d4b2f844b64b0799ba303c7bdafdba',
            label: Language.HeavyEngineering._6
        },
        {
            value: '89ad3c1b87a34ba59131d3569ed31243',
            label: Language.HeavyEngineering._7
        },
        {
            value: '0731b3e4e4f64bcfaa5cb9fa0c02ef4f',
            label: Language.HeavyEngineering._8
        },
        {
            value: '824964a332af4c338f949339f757c199',
            label: Language.HeavyEngineering._9
        },
        { value: '0d7f3f2e65ac4e6ab1b336ee40998b37', label: Language.HeavyEngineering._10 },
        {
            value: 'c9385fea36ca4af5b2dd9c794b6e4e64',
            label: Language.HeavyEngineering._11
        },
        { value: '1eb143ae94f049648a9cbd86f3b0fe8b', label: Language.HeavyEngineering._12 },
        { value: '3709495590a040978d4a30cf6c370981', label: Language.HeavyEngineering._13 }],
    _b54ad3287ce24d9382c51f4eb2da04c7: [
        { value: 'a6c87aa756de4f338213a15f083fe9d2', label: Language.Multiprofile._1 },
    ],
    _66d57ba9837d4de1bbb9481e04266da7: [
        { value: '8808ce1ce6e74364a4ef2456bb7b187a', label: Language.BusinessServices._1 },
        { value: '74f9f5f9464e4aaf80a82eb73cfc1283', label: Language.BusinessServices._2 },
        { value: '5acb152ae0ad4d6fbad93030c06e90c5', label: Language.BusinessServices._3 },
        { value: '6e2197c707674df495cb306baa8a195c', label: Language.BusinessServices._4 },
        { value: '477b07eae534412c9c991d5c9b0b46c5', label: Language.BusinessServices._5 },
        { value: '9d553aae716349d5bac7fdf4ebcfbc3f', label: Language.BusinessServices._6 },
        { value: '7605b78f30be42bcb41b989524267858', label: Language.BusinessServices._7 },
        { value: 'c67dde0db9ec4c129510b893394ebabf', label: Language.BusinessServices._8 },
        { value: '8830ad25579e424bb681631c7e917824', label: Language.BusinessServices._9 }],
    _84607ccdcb3649108cd0db0407341d20: [
        { value: '594ca1d9b02b4cc98a66c68a71d3c976', label: Language.PublicServices._1 },
        { value: '6b696b7767f6401a902040b5be0f2e70', label: Language.PublicServices._2 },
        { value: 'b7b56adf10c94426b9d7e8fcda702005', label: Language.PublicServices._3 },
        { value: 'fc18426d82484c168b1a721a41bd95d3', label: Language.PublicServices._4 },
        { value: '82f01284296346448492fd33b84896ce', label: Language.PublicServices._5 },
        { value: 'b98b7f28e0334664a41772628c6dada5', label: Language.PublicServices._6 },
        { value: 'b5be19c401d748ca977264b983ad72fb', label: Language.PublicServices._7 },
        { value: '0ee44d91f9a74a34a52b4b5e87ff5d36', label: Language.PublicServices._8 },
        { value: 'f98834eff96346328515aa9d2f5a00c1', label: Language.PublicServices._9 },
        { value: '2648d7adb40f401c988adff31f846eac', label: Language.PublicServices._10 },
        { value: 'bbbc6571f7e84b6aa88f09c38c225688', label: Language.PublicServices._11 },
        { value: '305636ed1b70416bb447ba6be5ebbb77', label: Language.PublicServices._12 }],
    _1b57723ad61a486ca5489077a29b092b: [
        {
            value: '606550e32b3b4522bdb3e4e0e50f3231',
            label: Language.FinancialSector._1
        },
        { value: '85a6bf2a03654dceaadd0b91c7a7b706', label: Language.FinancialSector._2 },
        { value: 'a3a3ad864bd54accb14fdd346ec43b2f', label: Language.FinancialSector._3 },
        { value: '07eda9c456124568a12a258a03b30620', label: Language.FinancialSector._4 },
        { value: 'cb944470227946e69b28e35a73455e83', label: Language.FinancialSector._5 },
        { value: '02d4beb4249d468bae98ff2d78ef1308', label: Language.FinancialSector._6 },
        { value: '69f17b34722e41aea4ee2ec55f8c4a54', label: Language.FinancialSector._7 },
        {
            value: '0abced4083d242e19dd93a2328b5bfa5',
            label: Language.FinancialSector._8
        },
        { value: '940a42ad20d84b11b4133e5193f81de4', label: Language.FinancialSector._9 },
        {
            value: '00cb9de515c049b58992071facc636b0',
            label: Language.FinancialSector._10
        }],
    _e70912829c2f424c9308f03464252c5e: [
        { value: '98f19e27aa3240c5b2b3be86b68a1fee', label: Language.ChemicalProduction._1 },
        { value: 'f04bcb56c37e4b0887cdeb226bdb7812', label: Language.ChemicalProduction._2 },
        {
            value: 'bb52e40d36394d2b9f6fca956d7cdf9b',
            label: Language.ChemicalProduction._3
        },
        { value: '7c364055c753402bbd4a8e63398c65cb', label: Language.ChemicalProduction._4 },
        {
            value: '32a26d48ec1b4c71879aeeca48e18467',
            label: Language.ChemicalProduction._5
        },
        { value: '86f0cf8b6026439886c47db77cec7e44', label: Language.ChemicalProduction._6 },
        { value: '13e1b0a20813401baf15ebaa3c761d1b', label: Language.ChemicalProduction._7 },
        { value: '5cf21af0b54b42378817b05f8570d5e5', label: Language.ChemicalProduction._8 },
        { value: 'edf63a2c8d9f4bbe80dd75e8750e6efd', label: Language.ChemicalProduction._9 },
        { value: 'b0e7c93445784e78a7f4b43c6c3f6784', label: Language.ChemicalProduction._10 },
        { value: '513aaceb03fa4d8a85a7c308c78fc7cf', label: Language.ChemicalProduction._11 },
        { value: 'd66d92c3193d4b759f006f6ac1b6b79d', label: Language.ChemicalProduction._12 }],
    _e75f60fc6be8425f8e2af99d81239fea: [
        {
            value: 'f5c1301382f74ff0b1cf8fa4dade71cd',
            label: Language.Electronics._1
        },
        {
            value: '0aa69740938449b2aab27ede1ec2cc2a',
            label: Language.Electronics._2
        },
        {
            value: 'eeb54ca558914b3684b108d42dc36209',
            label: Language.Electronics._3
        },
        {
            value: '9e79460410e34eaaab4bd2c11d307c78',
            label: Language.Electronics._4
        },
        {
            value: 'cc0a9cc114a741f195caa946648dee6e',
            label: Language.Electronics._5
        },
        {
            value: '503a747fba1b45afbb85bce32508fa83',
            label: Language.Electronics._6
        },
        {
            value: '4bb8deed0cbd489cb7d864288e0b6c26',
            label: Language.Electronics._7
        },
        {
            value: '76a0ba6cff884486ba6674cd48326bf5',
            label: Language.Electronics._8
        },
        {
            value: 'a08f8b66cdd8425c909622abfdaca37e',
            label: Language.Electronics._9
        }],
    _2db15127161143298e0c084ba77e75ca: [
        {
            value: '7421f374446946f7ac6c0f57cd11e35e',
            label: Language.PowerIndustry._1
        },
        { value: '9d77dda952694adeaf058b5ffe154eb5', label: Language.PowerIndustry._2 },
        { value: '85a314f94a9e422b8b00a63f6b14a773', label: Language.PowerIndustry._3 },
        {
            value: '1a46ed56fb824857a18838c94220d0ea',
            label: Language.PowerIndustry._4
        },
        { value: '42b3e2991dc147c4b3b26da3f4fb8b56', label: Language.PowerIndustry._5 },
        { value: 'd246497d7b13449eb219c6e4330d56c9', label: Language.PowerIndustry._6 },
        { value: '3c075df638f84c8c902476dd200f246e', label: Language.PowerIndustry._7 },
        { value: '0548c3507a6b412eaa67b6f1cdf68eb0', label: Language.PowerIndustry._8 },
        { value: '5d1bd61614904bc4a946243e482330e4', label: Language.PowerIndustry._9 }
    ],
    all_industries: [
        { value: '34b7a4c03f1540c58b15a9b49415bfaf', label: Language.IndustryAll._1 },
        { value: '658dc84f185345758daabdd24a2bc1aa', label: Language.IndustryAll._2 },
        { value: 'eabaf7e8f45448b99a7aa76250093af1', label: Language.IndustryAll._3 },
        { value: 'd7ff765e8db84cadbd131b7ef882b950', label: Language.IndustryAll._4 },
        { value: '9faabc7dc2314f61bbb6c0a866134f8e', label: Language.IndustryAll._5 },
        { value: '331d7f2e892744dca4c550e50dc8dd71', label: Language.IndustryAll._6 },
        { value: 'e57fa759d16848c98a572d18dcbf827f', label: Language.IndustryAll._7 },
        { value: 'e9b69a047d2d4ff1bd5ecd63ba1ae68f', label: Language.IndustryAll._8 },
        { value: '1b6ab6fd41aa446d908fb7cba78e40a6', label: Language.IndustryAll._9 },
        { value: '919a197be2d94bd1a01b43b6c1d41d23', label: Language.IndustryAll._10 },
        { value: '13d41716a3a24be88b07440bbcee0a01', label: Language.IndustryAll._11 },
        { value: '5b698abde9be48c19cfb3bbac06d2dd0', label: Language.IndustryAll._12 },
        { value: 'aea553c1e0b74285a8380d88653befed', label: Language.IndustryAll._13 },
        { value: 'f72b930863024e83baf3adac1c3d4828', label: Language.IndustryAll._14 },
        { value: '103c7b3eddf74d87ac8d8ad521c6caa6', label: Language.IndustryAll._15 },
        { value: '4ae15d10e8ea4a94a279dc53b65270a9', label: Language.IndustryAll._16 },
        { value: '7ab4a926ef2d415594166188f9441f20', label: Language.IndustryAll._17 },
        { value: 'b2460ca7b6ca4332a95ed88408cda05e', label: Language.IndustryAll._18 },
        { value: '742c4cddb31346feaa056a224b0db07a', label: Language.IndustryAll._19 },
        { value: 'e807327ef859446ba3cd979ed306c23c', label: Language.IndustryAll._20 },
        { value: '6cc3369cc4c843dc9dca655894b81481', label: Language.IndustryAll._21 },
        { value: '283bf1eb0f3d4aa99189956f6e4d5d7c', label: Language.IndustryAll._22 },
        { value: 'b54ad3287ce24d9382c51f4eb2da04c7', label: Language.IndustryAll._23 },
        { value: '66d57ba9837d4de1bbb9481e04266da7', label: Language.IndustryAll._24 },
        { value: '84607ccdcb3649108cd0db0407341d20', label: Language.IndustryAll._25 },
        { value: '1b57723ad61a486ca5489077a29b092b', label: Language.IndustryAll._26 },
        { value: 'e70912829c2f424c9308f03464252c5e', label: Language.IndustryAll._27 },
        {
            value: 'e75f60fc6be8425f8e2af99d81239fea',
            label: Language.IndustryAll._28
        },
        { value: '2db15127161143298e0c084ba77e75ca', label: Language.IndustryAll._29 },
    ],
    all: [
        { value: '34b7a4c03f1540c58b15a9b49415bfaf', label: 'Автомобильный бизнес' },
        { value: '658dc84f185345758daabdd24a2bc1aa', label: 'Гостиницы, рестораны, общепит, кейтеринг' },
        { value: 'eabaf7e8f45448b99a7aa76250093af1', label: 'Добывающая отрасль' },
        { value: 'd7ff765e8db84cadbd131b7ef882b950', label: 'ЖКХ' },
        { value: '9faabc7dc2314f61bbb6c0a866134f8e', label: 'Информационные технологии, системная интеграция, интернет' },
        { value: '331d7f2e892744dca4c550e50dc8dd71', label: 'Искусство, культура' },
        { value: 'e57fa759d16848c98a572d18dcbf827f', label: 'Лесная промышленность, деревообработка' },
        { value: 'e9b69a047d2d4ff1bd5ecd63ba1ae68f', label: 'Медицина, фармацевтика, аптеки' },
        { value: '1b6ab6fd41aa446d908fb7cba78e40a6', label: 'Металлургия, металлообработка' },
        { value: '919a197be2d94bd1a01b43b6c1d41d23', label: 'Нефть и газ' },
        { value: '13d41716a3a24be88b07440bbcee0a01', label: 'Образовательные учреждения' },
        { value: '5b698abde9be48c19cfb3bbac06d2dd0', label: 'Общественная деятельность, партии, благотворительность, НКО' },
        { value: 'aea553c1e0b74285a8380d88653befed', label: 'Перевозки, логистика, склад, ВЭД' },
        { value: 'f72b930863024e83baf3adac1c3d4828', label: 'Продукты питания' },
        { value: '103c7b3eddf74d87ac8d8ad521c6caa6', label: 'Промышленное оборудование, техника, станки и комплектующие' },
        { value: '4ae15d10e8ea4a94a279dc53b65270a9', label: 'Розничная торговля' },
        { value: '7ab4a926ef2d415594166188f9441f20', label: 'СМИ, маркетинг, реклама, BTL, PR, дизайн, продюсирование' },
        { value: 'b2460ca7b6ca4332a95ed88408cda05e', label: 'Сельское хозяйство' },
        { value: '742c4cddb31346feaa056a224b0db07a', label: 'Строительство, недвижимость, эксплуатация, проектирование' },
        { value: 'e807327ef859446ba3cd979ed306c23c', label: 'Телекоммуникации, связь' },
        { value: '6cc3369cc4c843dc9dca655894b81481', label: 'Товары народного потребления (непищевые)' },
        { value: '283bf1eb0f3d4aa99189956f6e4d5d7c', label: 'Тяжелое машиностроение' },
        { value: 'b54ad3287ce24d9382c51f4eb2da04c7', label: 'Управление многопрофильными активами' },
        { value: '66d57ba9837d4de1bbb9481e04266da7', label: 'Услуги для бизнеса' },
        { value: '84607ccdcb3649108cd0db0407341d20', label: 'Услуги для населения' },
        { value: '1b57723ad61a486ca5489077a29b092b', label: 'Финансовый сектор' },
        { value: 'e70912829c2f424c9308f03464252c5e', label: 'Химическое производство, удобрения' },
        { value: 'e75f60fc6be8425f8e2af99d81239fea', label: 'Электроника, приборостроение, бытовая техника, компьютеры и оргтехника' },
        { value: '2db15127161143298e0c084ba77e75ca', label: 'Энергетика' },
    ]
};

export default IndustryData;