import * as axios from "axios";
import ModalWindow from "components/UI/ModalWindow/index";
import { ActionNotification } from "components/UI/Notifications/action";
import 'cropperjs/dist/cropper.css';
import Language from "language/index";
import { getItem } from "libs/localStorage";
import { SET_AVATAR_PROFILE } from "mutation/profile";
// import Tooltip from 'components/UI/Tooltip';
import PropTypes from 'prop-types';
import { GET_MY_PROFILE } from "query/index";
import React, { PureComponent } from 'react';
import { Mutation } from "react-apollo";
import Cropper from 'react-cropper';


class LoaderAvatar extends PureComponent {

    static propTypes = {
        input: PropTypes.object,
    };
    static defaultProps = {
        input: {},
    };

    state = {
        file: null,
        isOpen: false,
        loading: false
    };

    onChange = value => {
        let file = value.target.files[0];
        if (!file) return false;
        let url = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = () => {
            if (img.width > 255 && img.height > 330) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                if (file.size > 10000000) {
                    ActionNotification('1', Language.LargeFileSize);
                } else {
                    reader.onloadend = () => {
                        this.setState({ file: reader.result }, this.toggleModal)
                    }
                }
            } else {
                ActionNotification('1', 'Фотография должна иметь размер не меньше 255 точек по ширине и 330 по высоте');
            }
        };
        img.src = url.createObjectURL(file);
    };

    b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    _crop = () => {

        this.setState({ loading: true });

        if (this.refs.cropper.getCroppedCanvas().width < 255) {
            ActionNotification('1', 'Увеличьте область изображения');
            this.setState({ loading: false });
            return;
        }

        let imageInput = document.querySelector('.inputfile_1');
        imageInput.src = this.refs.cropper.getCroppedCanvas().toDataURL();
        let photoImage = document.querySelector('#photo_user_profile_1');
        // let data = new FormData(photoImage);
        let form = document.getElementById("photo_user_profile_1");
        let ImageURL = this.refs.cropper.getCroppedCanvas({
            width: 510,
            height: 660,
            fillColor: '#fff',
        }).toDataURL();

        let block = ImageURL.split(";");
        let contentType = block[0].split(":")[1];
        let realData = block[1].split(",")[1];
        let blob = this.b64toBlob(realData, contentType);

        let formDataToUpload = new FormData();
        formDataToUpload.append("name", blob);
        const token = getItem('token', false);

        axios({
            url: '/upload/image',
            method: 'post',
            data: formDataToUpload,
            headers: { 'token': token, 'Content-Type': 'multipart/form-data' }
        })
            .then((response) => {
                console.log(response);
                this.setState({ isOpen: false, loading: false });
                this.props.mutate({ variables: { avatarUrl: response.data[0].link } })
            })
            .catch(error => {
                this.setState({ loading: false });
                ActionNotification('1', 'Произошла ошибка. Уменьшите область изображения');
            });
    };

    toggleModal = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
        const { input } = this.props;

        return (
            <React.Fragment>
                <div id="photo_user_profile">
                    <img src={input.value ? input.value : '/templates/img/uploader_default.jpg'} alt="..."/>
                    <input onChange={this.onChange}
                           type="file"
                           className="hide inputfile"
                           id="upload"
                           name="avatar"
                           style={{ display: 'none' }}
                    />
                    <label htmlFor="upload">
                    <span className="upload-text">
                        <span>{Language.uploadedPhoto}</span>
                        <em>{Language.notMoreMB}</em>
                    </span>
                    </label>
                </div>

                {
                    this.state.isOpen &&
                    <ModalWindow
                        title={Language.SelectImage}
                        handleClose={this.toggleModal}
                    >
                        <Cropper
                            dragMode="move"
                            // cropBoxResizable={false}
                            zoomable={false}
                            minContainerWidth={255}
                            // minCropBoxWidth={255}
                            // minCanvasWidth={255}
                            // minCanvasHeight={329}
                            // minCropBoxHeight={329}
                            ref='cropper'
                            src={this.state.file}
                            aspectRatio={255 / 330}
                            viewMode={2}
                            modal={true}
                            style={{ maxHeight: '400px', width: '99%' }}
                        />
                        <form id="photo_user_profile_1 hidden" style={{ display: 'none' }}>
                            <input type="image" src="" className="hide inputfile_1" id="upload" name="picture"/>
                        </form>
                        <div className="modal__body__btn">
                            <button
                                onClick={this.state.loading ? () => {
                                } : this._crop} type="button"
                                className="orange-btn btn_color_green">
                                {this.state.loading ? 'Loading...' : Language.save}
                            </button>
                            <button onClick={this.toggleModal} type="button"
                                    className="orange-btn btn_color_red">{Language.Cancel}</button>
                        </div>
                    </ModalWindow>
                }
            </React.Fragment>
        );
    }
}


export default props => {
    return (
        <Mutation
            onCompleted={() => ActionNotification('2', Language.profileUpdated)}
            onError={() => ActionNotification('1', 'Произошла ошибка загрузке изображения')}
            mutation={SET_AVATAR_PROFILE}
            refetchQueries={[{query: GET_MY_PROFILE}]}

        >
            {mutate => (
                <LoaderAvatar mutate={mutate} {...props}/>
            )}
        </Mutation>
    )
}
