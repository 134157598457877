import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import Industry from "components/UI/Forms/GroupFields/Industry";
import Money from "components/UI/Forms/GroupFields/Money";
import TimePeriod from "components/UI/Forms/GroupFields/TimePeriod";
import Volume from "components/UI/Forms/GroupFields/Volume";
import { submitProviderForm } from "components/UI/Forms/Requests/Provider/submit";
import Language from "language/index";
import { SET_PROVIDER } from "mutation/offer";
import { GET_OFFERS_LIST } from "query/offers";
import React, { Component } from 'react';
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from "redux-form";
import { Button } from 'components/v1/Button';


class ProviderForm extends Component {

    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return (
            <div className="form fullWidth">
                <form className="new_application" id="new_request_novator" onSubmit={handleSubmit}>
                    <Field
                        name="title"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.naming} *`}
                    />
                    <Field
                        name="description"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.Description} *`}
                    />
                    <Field
                        name="deliveryType"
                        component={Select}
                        label={`${Language.DeliveryType} *`}
                        options={[
                            { value: 'Производитель', label: Language.Manufacturer },
                            { value: 'Посредник', label: Language.Intermediary },
                        ]}
                    />
                    <Money
                        label={`${Language.Cost} *`}
                        nameField="cost"
                    />
                    <TimePeriod
                        name="deliveryTime"
                        label={`${Language.DeliveryTime} *`}
                    />
                    <Volume
                        label={`${Language.MaximumScopeSupply} *`}
                        nameField="maximumDeliveryVolume"
                    />
                    <Industry
                        isReq
                        label={`${Language.industry}`}
                        {...initialValues}
                    />
                    <Button type="submit" disabled={loading} className="orange-btn"> Сохранить</Button>
                </form>
            </div>
        );
    }
}


ProviderForm = reduxForm({
    form: 'providerForm',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('providerForm'));
        }
    }
})(ProviderForm);


export default props => {
    const initialValues = Boolean(Object.keys(props.initialValues).length) ? props.initialValues : {
        cost: {
            currency: 'rub'
        },
        deliveryTime: {
            typeDate: 'month'
        },
        maximumDeliveryVolume: {
            typeVolume: 'mm'
        }
    };
    return (
        <Mutation mutation={SET_PROVIDER} refetchQueries={[{ query: GET_OFFERS_LIST, variables: { isMy: true } }]}>
            {(mutate,{ loading }) => (
                <ProviderForm   {...props} initialValues={initialValues} loading={loading}
                              onSubmit={values => submitProviderForm(values, mutate, props.cb)}/>
            )}
        </Mutation>

    )
};

