import SbContactsItem from "components/UI/SbContacts/SbContactsItem";
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class SbContactsList extends PureComponent {

  static propTypes = {
    users: PropTypes.array,
    isStatus: PropTypes.bool,
    queryUpdate: PropTypes.object,
  };

  static defaultProps = {
    users: [],
    isStatus: false,
    queryUpdate: {}
  };


  render() {
    const { users, isStatus, queryUpdate, refetchQuery } = this.props;
    return (
      <div className="sb-contacts">
        <div className="flex">
          {
            users.map((el, index) => {
              let user = el.user ? el.user : el;
              return (
                <SbContactsItem
                  {...user}
                  key={index}
                  isStatus={isStatus}
                  queryUpdate={queryUpdate}
                  refetchQuery={refetchQuery}
                />
              )
            })
          }
        </div>
      </div>
    );
  }
}


