import { changeStatusRequests } from "components/Pages/Requests/changeStatusRequest";
import { StatusRequest } from "components/UI/Requests/RequestControl/StatusRequests";
import React, { Component } from 'react';
import Language from "language/index";
import { dialogueChecking } from "../Contacts/dialogueChecking";
import { GET_OFFERS_ARCHIVE } from "query/offers";

const ButtonDots = ({ action }) => {
  return (
    <button
      type="button"
      onClick={action}
      className="dots-btn visible-info">
      <span>...</span>
    </button>
  )
};

const ButtonList = props => {
  return (
    <li>
      <div className="user-info-link" onClick={props.action}>
        {props.children}
      </div>
    </li>
  )
};


class RequestControl extends Component {
  wrapperRef = null;

  state = {
    isOpenMenu: false
  };

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ isOpenMenu: false });
    }
  };

  toggleMenu = () => this.setState({ isOpenMenu: !this.state.isOpenMenu });

  createMessages = (userId) => {
    this.toggleMenu();
    dialogueChecking(userId, this.props._id)
  };
  changeStatusRequest = status => {
    this.toggleMenu();
    changeStatusRequests(status, this.props._id, { query: GET_OFFERS_ARCHIVE, variables: { archive: true } });
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  render() {
    const { isNetwork, status, user: { _id } } = this.props;
    return (

      <div className="posa mobile_posa_request" >
        {
          !isNetwork &&
          <StatusRequest status={status}/>
        }

        <div ref={ref => this.wrapperRef = ref} className="dots-btn-wrap more-info more-inf">
          <ButtonDots action={this.toggleMenu}/>
          {
            this.state.isOpenMenu &&
            <React.Fragment>
              {
                isNetwork &&
                <ul className="hidden-info" style={{ display: 'block' }}>
                  <ButtonList action={() => this.createMessages(_id)}>
                    <i className="panton c">u</i> {Language.messageRequest}
                  </ButtonList>
                </ul>
              }
              {
                !isNetwork &&
                <ul className="hidden-info" style={{ display: 'block' }}>
                  {
                    (status !== 'MODERATION' && status !== 'ACCEPTED') &&
                    <ButtonList action={() => this.changeStatusRequest('MODERATION')}>
                      <i className="panton d">d</i>
                      {Language.sendModeration}
                    </ButtonList>
                  }
                  <ButtonList action={() => {
                    this.toggleMenu();
                    this.props.edit()
                  }}>
                    <i className="panton c">u</i>
                    {Language.edit}
                  </ButtonList>
                  <ButtonList action={() => this.changeStatusRequest('ARCHIVED')}>
                    <i className="panton c">v</i>
                    {Language.archiveDo}
                  </ButtonList>
                  {
                    status !== 'ARCHIVED' &&
                    <ButtonList action={() => this.changeStatusRequest('REMOVED')}>
                      <i className="panton b">k</i>
                      {Language.remove}
                    </ButtonList>
                  }
                </ul>
              }
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}


export default RequestControl;
