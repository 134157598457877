import BusinessmanForm from "components/UI/Forms/Requests/Businessman/index";
import BusinessWomanForm from "components/UI/Forms/Requests/BussinessWoman/index";
import FreelancerForm from "components/UI/Forms/Requests/Freelancer/index";
import InvestorForm from "components/UI/Forms/Requests/Investor/index";
import NovatorForm from "components/UI/Forms/Requests/Novator/index";
import ProviderForm from "components/UI/Forms/Requests/Provider/index";
import React, { Component } from 'react';
import { connect } from 'react-redux';


class ProfileTypeForm extends Component {
    render() {
        let form = null;

        const { initialValues, cb } = this.props;

        switch (this.props.type) {
            case 'Investor':
                form = <InvestorForm initialValues={initialValues} cb={cb}/>;
                break;
            case 'BusinessProduct':
                form = <BusinessmanForm initialValues={initialValues} cb={cb}/>;
                break;
            case 'Innovator':
                form = <NovatorForm initialValues={initialValues} cb={cb}/>;
                break;
            case 'BusinessWoman':
                form = <BusinessWomanForm initialValues={initialValues} cb={cb}/>;
                break;
            case 'Unemployed':
                form = <FreelancerForm initialValues={initialValues} cb={cb}/>;
                break;
            case 'Provider':
                form = <ProviderForm initialValues={initialValues} cb={cb}/>;
                break;
            default:
        }
        return form
    }
}


export default connect()(ProfileTypeForm)
