import React from 'react';
import Language from 'language/index';
import { Link } from 'react-router-dom';
import Video from './video';
import ModalIdeaForm from './ModalIdeaForm';
import { Query } from 'react-apollo';
import { GET_AUTH } from 'query';
import forwardTo from 'libs/forwardTo';

class AboutPage extends React.Component {

	state = { openVideo: false, openIdea: false };


	openVideo = e => {
		e.preventDefault();
		this.setState({ openVideo: true })
	};

	toggleModalIdea = () => {
	  if (this.props.isAuth) {
        this.setState({ openIdea: !this.state.openIdea })
      } else {
	    forwardTo('/');
      }
	};

	closeVideo = () => this.setState({ openVideo: false });

	render() {

		return (
			<React.Fragment>
				<ModalIdeaForm isOpen={this.state.openIdea} close={this.toggleModalIdea}/>
				<div className="user-template">
					<div style={{ height: '440px', overflow: 'hidden', textAlign: 'center' }}>
						<img src="/templates/img/fon1.jpg" alt=""/>
					</div>
					<div className="container">
						<section className="about-info flex">
							<div className="block-info">
								<h1 className="title-section">{Language.AboutTheProject}</h1>
								<p>
									{Language.NewnextWorldFirst}
								</p>
							</div>
							<a href={'/'} onClick={e => this.openVideo(e)} className="block-video-link">
								<div className="orange-box">
									<div className="title">
										<img src="/templates/img/play-button.svg" className="imgblock" width="50"
											 alt=""/>
										{Language.WatchVideoProject.one}
									</div>
								</div>
							</a>
						</section>
						<section className="about-analytic border-box">
							<h3 className="top-h3">{Language.ABOUTTHEPROJECTFIGURES}</h3>
							<div className="items flex">
								<div className="item">
									<span>{Language.FoundedIn.two}</span>
									{Language.FoundedIn.one}
								</div>
								<div className="item">
									<span> >15000 </span>
									{Language.MoreDevelopers.two}
								</div>
								<div className="item">
									<span> >100 </span>
									{Language.MoreDesigners.two}
								</div>
								<div className="item">
									<span>{Language.MoreAnalysts.one}</span>
									{Language.MoreAnalysts.two}
								</div>
							</div>
						</section>
						<section className="todo-in-newnext">
							<h3 className="title-section">{Language.WhatCanYouNewnext}</h3>
							<div className="items flex">
								<div className="item">
									{Language.PotentialInvestorss}
								</div>
								<div className="item">
									{Language.PlaceProjectsInvestment}
								</div>
								<div className="item">
									{Language.FindBestDealsBusiness}
								</div>
								<div className="item">
									{Language.FindGoodsProducts}
								</div>
								<div className="item">
									{Language.FindInterestedUsersImplementation}
								</div>
								<div className="item">
									{Language.ReceiveGualifiedSupport}
								</div>
							</div>
							{/*<div className="text-center">*/}
							{/*<button className="gray-btn s-btn">Показать все</button>*/}
							{/*</div>*/}
						</section>
					</div>
					<div style={{ height: '440px', overflow: 'hidden', textAlign: 'center' }} className="mt60">
						<img src="/templates/img/fon2.jpg" alt=""/>
					</div>
					<div className="container">
						<div className="orange-box bestservices-box">
							<div className="title title-small">{Language.TheServicesExisting}</div>
						</div>
						<section className="capability">
							<h3 className="title-section">{Language.TheFunctionalCapabilities}</h3>
							<div className="items flex">
								<div className="item flex">
									<div className="img">
										<img src="templates/img/case.svg" alt=""/>
									</div>
									{Language.EstablishBusinessRelationships}
								</div>
								<div className="item flex">
									<div className="img">
										<img src="templates/img/target.svg" alt=""/>
									</div>
									{Language.ReceiveConvenientTargeted}
								</div>
								<div className="item flex">
									<div className="img">
										<img src="templates/img/contact.svg" alt=""/>
									</div>
									{Language.CreatePersonalContactList}
								</div>
								<div className="item flex">
									<div className="img">
										<img src="templates/img/newspaper.svg" alt=""/>
									</div>
									{Language.ViewYourContactsFeeds}
								</div>
								<div className="item flex">
									<div className="img">
										<img src="templates/img/speech-bubble.svg" alt=""/>
									</div>
									{Language.MaintainPersonalCorrespondenceBusinessNetwork}
								</div>
								<div className="item flex">
									<div className="img">
										<img src="templates/img/send.svg" alt=""/>
									</div>
									{Language.MakeWithAbilityAmong}
								</div>
							</div>
						</section>
						<section className="do-you-have-idea border-box">
							<h3 className="top-h3">{Language.ANYIDEAS}</h3>
							<div className="items flex">
								<div className="item">
									<p>{Language.AreYouUserNetworkWould}</p>
								</div>
								<div className="item">
									<button className="orange-btn desktop-show"
											onClick={this.toggleModalIdea}>{Language.OfferIdea}</button>
									<Link to="/" className="mobile-show">
										<button className="orange-btn">{Language.registration}</button>
									</Link>
								</div>
							</div>
						</section>
					</div>
				</div>
				{/*<Modal/>*/}
				<Video show={this.state.openVideo} close={this.closeVideo}/>
			</React.Fragment>
		)
	}
}

export default props => {
	return (
		<Query query={GET_AUTH} fetchPolicy="cache-only">
			{({ data, loading, error }) => {
				if (error || loading) return 'Loading...';
				return <AboutPage {...props} {...data}/>
			}}
		</Query>
	)
};
