import React from 'react';
import cn from 'classnames'

import { Tab } from './Tab';
import classes from './styles.module.scss';


export const Tabs = ({ items, activeTab, onChangeTab, className }) => {
  return (
    <div className={cn(classes.tabs, className)}>
      {items.map(({ label, value }) => (
        <Tab
          key={value}
          label={label}
          value={value}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
        />
      ))}
    </div>
  );
};
