import React from 'react'
import { Controller } from 'react-hook-form';

import Select from 'components/UI/Fields/Select';


export const SelectController = ({
  control,
  name,
  label,
  rules,
  ...rest
}) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <Select
          label={label}
          onChange={onChange}
          value={value}
          input={{ onChange, value }}
          name={name}
          {...rest}
        />
      )}
    />
  )
}
