import { required } from "libs/fieldValidation";
import { setItem } from "libs/localStorage";
import { showError } from "libs/showFormsError";
import { ActionNotification } from "components/UI/Notifications/action";

export const submitRestorePasswordForm = (values, mutate) => {
    let { phone, captcha } = values;

    showError({
        phone: [required(phone)]
    });

    setItem('phone', phone);
    phone = phone.replace(/\D+/g, "");

    if (captcha === undefined) {
        ActionNotification('1', `ReCAPTCHA не пройдена`)
    } else {
        mutate({ variables: { captcha, phone } });
    }
    
};