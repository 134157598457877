import React from 'react'

import { TextareaController } from 'components/v1/HookFields/Textarea';

import styles from './styles.module.scss'

export const AboutCompanyForm = ({ control }) => {

  // const { control } = useForm()

  return (
    <>
      <div className={styles.section}>
        <div>О компании</div>
        <TextareaController
          control={control}
          name="description"
        />
      </div>
      {/*<div className="separator" />*/}
      {/*<Button type="submit">*/}
      {/*  Сохранить*/}
      {/*</Button>*/}
    </>
  )
}
