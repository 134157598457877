import { NotFoundIcon } from './NotFoundIcon';

import classes from './MessagesList.module.scss';

export const NotFound = props => {
  return (
    <div className={classes.notfound}>
      <NotFoundIcon />
      <div className={classes.text}>
        Чат не выбран
        <span>Выберите чат, чтобы начать общение</span>
      </div>
    </div>
  )
}
