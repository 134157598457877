import React, { useState } from 'react';

import { Query } from 'react-apollo';
import { GET_DIALOGS } from 'query/dialogs';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import { getItem } from 'libs/localStorage';
import { useSizeSM } from 'libs/useMediaQuery';
import { UPDATE_DIALOGS } from 'subscription';

import { DialogList } from './components/DialogList/DialogList';
import { MessagesListMain } from './components/MessagesList';

import classes from './styles.module.scss';
import forwardTo, { goBack } from 'libs/forwardTo';
import { HeaderMobile } from 'components/v1/HeaderMobile';

export const MessagesComponent = props => {
  const [chatId, setChatId] = useState(props.match.params.id || null)
  const { dialogs, total } = props.DialogGetter.getMyDialogs;
  const isMobile = useSizeSM()

  const handleChangeChatId = id => {
    setChatId(id);
    forwardTo(`/messages/${id}`);
  }

  const handleCloseChat = () => {
    setChatId(null);
    goBack();
  }

  return (
    <div className={classes.root}>
      <HeaderMobile goBack title="Чаты" />
      {(!isMobile || (isMobile && !chatId)) && (
        <DialogList dialogs={dialogs} total={total} onChangeChatId={handleChangeChatId} activeChatId={chatId} />
      )}
      {(!isMobile || (isMobile && chatId)) && (
        <MessagesListMain activeChatId={chatId} closeChat={handleCloseChat} />
      )}
    </div>
  )
}

export const MessagesNew = props => {
  return (
    <Query query={GET_DIALOGS} fetchPolicy={'cache-and-network'}>
      {({ loading, data, client, error, subscribeToMore, fetchMore }) => {
        if (error) return <p> Error.... </p>;
        if (!data.DialogGetter && loading) return <PreloaderCircle/>;
        return <MessagesComponent
          {...props}
          {...data}
          fetchMore={fetchMore}
          subscribeToNewComments={() => subscribe(subscribeToMore)}
        />
      }}
    </Query>
  )
};


const subscribe = (subscribeToMore) => {
  const token = getItem('token', null);
  subscribeToMore({
    document: UPDATE_DIALOGS,
    variables: { token }
  })
};
