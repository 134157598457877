import classes from './InfoBlock.module.scss';
import { TEXTS } from 'pages/Registration/components/InfoBlock/constants';

export const InfoBlock = () => {
  return (
    <div className={classes.root}>
      <h1>
        <span>Есть идея </span> для бизнеса?
      </h1>
      <div className={classes.description}>
        Бизнес-сеть для физических и юридических лиц, объединяющая пользователей в бизнес-сообщество заинтересованных людей.
      </div>

      <div className={classes.details}>
        {TEXTS.map((text, index) => (
          <div key={index} className={classes.item}>
            <div className={classes.index}>{index + 1}</div>
            {text}
          </div>
        ))}
      </div>
    </div>
  )
}
