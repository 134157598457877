import React, { Component } from 'react';


class Loading extends Component {

    render() {
        return (
            <p>
                Loading....
            </p>
        )
    }
}


export default Loading;