import React from 'react';
import { Mutation } from "react-apollo";

import { addContactWithContactList, removeContact } from "libs/updateQuery";
import { ADD_CONTACT, DELETE_CONTACTS } from "mutation/Contacts";
import { GET_CONTACTS, GET_COUNT_REQUEST } from "query/contacts";
import { Button } from 'components/v1/Button';


const RequestActionsComponent = props => {
  const {calledAdd, calledRemove, addContact, removeContact} = props;
  return (
    <>
      <Button variant="outlined" onClick={removeContact}>
        {calledRemove ? 'Loading...' : 'Отклонить'}
      </Button>
      <Button onClick={addContact}>
        {calledAdd ? 'Loading...' : 'Принять'}
      </Button>
    </>
  )
}

export const RequestActions = props => (
  <Mutation
    mutation={DELETE_CONTACTS}
    variables={{ userId: props._id }}
    // update={(cache, { data }) => removeContact(cache, { data }, props.type)}
    refetchQueries={[
      { query: GET_COUNT_REQUEST },
      { query: GET_CONTACTS, variables: { status: 'request', count: 50 } }
    ]}
  >
    {(removeContacts, { called }) => {
      let calledRemove = called;
      return (
        <Mutation
          mutation={ADD_CONTACT}
          variables={{ contact: { userId: props._id } }}
          update={(cache, { data }) => {
            addContactWithContactList(data.ContactSetter.addContact);
          }}
          refetchQueries={[
            { query: GET_COUNT_REQUEST },
            { query: GET_CONTACTS, variables: { status: 'request', count: 50 } }
          ]}
        >
          {(addContact, { client, called }) => {
            return <RequestActionsComponent
              removeContact={removeContacts}
              addContact={addContact}
              client={client}
              {...props}
              calledRemove={calledRemove}
              calledAdd={called}
            />
          }}
        </Mutation>
      )
    }}
  </Mutation>
);
