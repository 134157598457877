import {SubmissionError} from 'redux-form';

const setValue = (object, path, value) => {
	let a = path.split('.'),
		o = object;
	for (let i = 0; i < a.length - 1; i++) {
		let n = a[i];
		if (n in o) {
			o = o[n];
		} else {
			o[n] = {};
			o = o[n];
		}
	}
	o[a[a.length - 1]] = value;
};

const showError = (errors:Object) => {
	for (const err in errors) {
		if(Array.isArray(errors[err])){
			errors[err] = errors[err].filter((it) => it !== undefined);
			if (errors[err].length === 0) {
				delete errors[err];
			} else {
				errors[err] = errors[err].join('; ');
			}
		}
	}
	if (Object.keys(errors).length > 0) {
		for (const key in errors) {
			if(key.indexOf('.') !== -1){
				setValue(errors, key, errors[key])
			}
		}
		throw new SubmissionError(errors);
	}
};

export const showErrorAsync = (errors) => {
	for (const err in errors) {
		errors[err] = errors[err].filter((it) => it !== undefined);
		if (errors[err].length === 0) {
			delete errors[err];
		} else {
			errors[err] = errors[err].join('; ');
		}
	}
	if (Object.keys(errors).length > 0) {
		throw errors;
	}
};

export const showErrorReg = (errors:Object) => {
    for (const err in errors) {
        if(Array.isArray(errors[err])){
            errors[err] = errors[err].filter((it) => it !== undefined);
            if (errors[err].length === 0) {
                delete errors[err];
            } else {
                errors[err] = errors[err].join('; ');
            }
        }
    }
    if (Object.keys(errors).length > 0) {
        for (const key in errors) {
            if(key.indexOf('.') !== -1){
                setValue(errors, key, errors[key])
            }
        }
        return errors
    }
};

export default showError;