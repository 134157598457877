import { ActionNotification } from "../../../Notifications/action";
import showError from "libs/showFormErrors";
import { required } from "libs/fieldValidation";
import Language from "language/index";

export const submitNovatorForm = (values, mutate, cb) => {

  const { additionallyInform, cost, name, industry} = values;

  let validateObj = {
    'additionallyInform': [required(additionallyInform)],
    'cost.amount': [required(cost)],
    'name': [required(name)],
    'industry.groupName': [required(industry)],
    'industry.name': [required(industry)],
  };

  if (industry) {
    validateObj['industry.groupName'] = [required(industry.groupName)];
    validateObj['industry.name'] = [required(industry.name)];
  }
  if (cost) {
    validateObj['cost.amount'] = [required(cost.amount)];
    validateObj['cost.currency'] = [required(cost.currency)];
  }

  showError(validateObj);

  const fields = ['_id', 'additionallyInform', 'cost', 'isImplemented', 'name', 'industry', 'patentNumber'];

  const value = { ...values };

  for (let key in value) {
    if (value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
    if (!fields.some(el => el === key)) {
      delete value[key];
    } else if (value[key].hasOwnProperty('amount')) {
      value[key].amount = parseInt(value[key].amount);
    }
  }

  mutate({ variables: { offer: { ...value } } })
      .then(res => {
          if (cb) {
              cb();
              ActionNotification('2', Language.applicationChanged);
          } else {
              ActionNotification('2', Language.applicationSuccessfullySent);
          }
      })
      .catch(err => {
          if (err.hasOwnProperty('graphQLErrors') && err.graphQLErrors[0].hasOwnProperty('code') && err.graphQLErrors[0].hasOwnProperty('description')) {
              ActionNotification('1', err.graphQLErrors[0].description);
          } else {
              ActionNotification('1', "Произошла ошибка");
          }
      })
};