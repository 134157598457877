import { Title } from "components/UI/SideBlock/Title"
import Tabs from "components/UI/Tabs/index"

import React, { PureComponent } from 'react'


export default class SideBlock extends PureComponent {
  render() {
    const {
        title,
        tabs,
        changeTab,
        activeTabIndex,
        className,
        isSpan,
        titlePage,
        rightBlock
    } = this.props;
    return (
      <div className={`side-block ${className}`}>
        {titlePage && (
          <div className="title-block">
            <div className="flex jcsb align">
              <h1>{titlePage}</h1>
              {rightBlock}
            </div>
          </div>
        )}

        {(title || tabs) && (
          <div className="sb-title">
            {!!title && (
              <Title {...this.props}/>
            )}
            {tabs && (
              <Tabs
                item={tabs}
                isSpan={isSpan}
                activeTabIndex={activeTabIndex}
                changeTab={changeTab}
              />
            )}
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}
