import { client } from "../../../apollo";
import { GET_NOTIFICATION } from "query/index";

export const ActionNotification = (type, text) => {
    client.writeQuery({
        query: GET_NOTIFICATION,
        data: {
            notification: {
                type,
                text,
                isOpen: true,
                __typename: 'Notification'
            }
        }
    })
};

export const CloseNotification = () => {
    client.writeQuery({
        query: GET_NOTIFICATION,
        data: {
            notification: {
                type: '',
                text: '',
                isOpen: false,
                __typename: 'Notification'
            }
        }
    })
};