import { NotFoundRequestIcon } from './NotFoundRequestIcon';

import classes from './NotFound.module.scss';
import { Button } from 'components/v1/Button';
import forwardTo from 'libs/forwardTo';

export const NotFound = props => {
  return (
    <div className={classes.notfound}>
      <NotFoundRequestIcon />
      <div className={classes.text}>
        Здесь сейчас пусто
        <span>Перейдите в каталог предложений, чтобы предложить свою идею или стать частью команды!</span>
      </div>

      <Button className={classes.button} onClick={() => forwardTo('/nnnotice')}>Перейти к предложениям</Button>
    </div>
  )
}
