import React, { memo } from 'react'
import { Link } from 'react-router-dom';

import { Badge } from 'components/v1/Badge';

import styles from './styles.module.scss'

export const MenuList = memo(({ items }) => {
  return (
    <ul>
      {items.map((el) => {
        if (el.hasOwnProperty('onClick')) {
          return (
            <li key={el.label} className={styles.item} onClick={el.onClick}>
              {el.icon}
              {el.label}
              <Badge color="gray" className={styles.badge} count={el.badge} />
            </li>
          )
        }
        return (
          <li key={el.label} className={styles.item}>
            <Link to={el.href}>
              {el.icon}
              {el.label}
              <Badge color="gray" className={styles.badge} count={el.badge} />
            </Link>
          </li>
        )
      })}
    </ul>
  )
})
