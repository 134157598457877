import Loading from "components/UI/Prealoders/Loading";
import SideBlock from "components/UI/SideBlock/index";
import TContactsList from "components/UI/TContacts/TContactsList";
import Language from "language/index";
import PropTypes from 'prop-types';
import { GET_RECCOMEND_USER } from "query/reccomendUser";
import React, { Component } from 'react';
import { Query } from "react-apollo";


class ReccomendUserByType extends Component {


    static propTypes = {
        UserGetter: PropTypes.object,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        UserGetter: {
            getRecommendedUser: {
                users: [],
                total: 0
            }
        },
        loading: false
    };

    render() {
        const { total, users } = this.props.UserGetter.getRecommendedUser,
            { loading } = this.props;
        return (
            <SideBlock
                count={total}
                title={Language.YouMayInterested}
            >
                {
                    loading ?
                        <Loading/> :
                        <TContactsList users={users.slice(0, 10)}/>
                }
            </SideBlock>
        )
    }
}


export default props => {
    return (
        <Query query={GET_RECCOMEND_USER} variables={{ userType: props.userType }}>
            {({ loading, error, data }) => {
                if (error) return 'Error...';
                return (
                    <ReccomendUserByType loading={loading} {...data} {...props}/>
                )
            }}
        </Query>
    )
};