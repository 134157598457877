import { replaceTo } from 'libs/forwardTo';
import { setItem } from 'libs/localStorage';
import { GET_AUTH, GET_MY_PROFILE } from 'query';
import {client} from "apollo";

export const updateLoginForm = (cache, { data: { auth } }) => {
    const isMobile = window.matchMedia(`(max-width: 575px)`).matches
    setItem('token', auth.token);
    client.query({ query: GET_MY_PROFILE });
    cache.writeQuery({
        query: GET_AUTH,
        data: { isAuth: true }
    });
    replaceTo(isMobile ? '/menu' : '/profile/me');
    window.history.replaceState(null, null, isMobile ? "/menu" : "/profile/me")
};
