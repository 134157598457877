export const BurgerIcon = ({ className }) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.00005 2.10118C1.00005 1.5489 1.4242 1.10118 1.94742 1.10118H18.0527C18.5759 1.10118 19 1.5489 19 2.10118C19 2.65347 18.5759 3.10118 18.0527 3.10118H1.94742C1.4242 3.10118 1.00005 2.65347 1.00005 2.10118Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.800049 2.10118C0.800049 1.44877 1.30369 0.901184 1.94742 0.901184H18.0527C18.6964 0.901184 19.2001 1.44877 19.2001 2.10118C19.2001 2.7536 18.6964 3.30118 18.0527 3.30118H1.94742C1.30369 3.30118 0.800049 2.7536 0.800049 2.10118ZM1.94742 1.10118C1.4242 1.10118 1.00005 1.5489 1.00005 2.10118C1.00005 2.65347 1.4242 3.10118 1.94742 3.10118H18.0527C18.5759 3.10118 19 2.65347 19 2.10118C19 1.5489 18.5759 1.10118 18.0527 1.10118H1.94742Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.00005 9.10118C1.00005 8.5489 1.4242 8.10118 1.94742 8.10118H18.0527C18.5759 8.10118 19 8.5489 19 9.10118C19 9.65347 18.5759 10.1012 18.0527 10.1012H1.94742C1.4242 10.1012 1.00005 9.65347 1.00005 9.10118Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.800049 9.10118C0.800049 8.44877 1.30369 7.90118 1.94742 7.90118H18.0527C18.6964 7.90118 19.2001 8.44877 19.2001 9.10118C19.2001 9.7536 18.6964 10.3012 18.0527 10.3012H1.94742C1.30369 10.3012 0.800049 9.7536 0.800049 9.10118ZM1.94742 8.10118C1.4242 8.10118 1.00005 8.5489 1.00005 9.10118C1.00005 9.65347 1.4242 10.1012 1.94742 10.1012H18.0527C18.5759 10.1012 19 9.65347 19 9.10118C19 8.5489 18.5759 8.10118 18.0527 8.10118H1.94742Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.00005 16.1012C1.00005 15.5489 1.4242 15.1012 1.94742 15.1012H18.0527C18.5759 15.1012 19 15.5489 19 16.1012C19 16.6535 18.5759 17.1012 18.0527 17.1012H1.94742C1.4242 17.1012 1.00005 16.6535 1.00005 16.1012Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.800049 16.1012C0.800049 15.4488 1.30369 14.9012 1.94742 14.9012H18.0527C18.6964 14.9012 19.2001 15.4488 19.2001 16.1012C19.2001 16.7536 18.6964 17.3012 18.0527 17.3012H1.94742C1.30369 17.3012 0.800049 16.7536 0.800049 16.1012ZM1.94742 15.1012C1.4242 15.1012 1.00005 15.5489 1.00005 16.1012C1.00005 16.6535 1.4242 17.1012 1.94742 17.1012H18.0527C18.5759 17.1012 19 16.6535 19 16.1012C19 15.5489 18.5759 15.1012 18.0527 15.1012H1.94742Z" fill="currentColor"/>
    </svg>
  )
};
