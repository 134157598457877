import Language from "language/index";
import { ADD_BLACK_LIST, REMOVE_FROM_BLACK_LIST } from "mutation/blackList";
import { GET_PROFILE_USER } from "query/profile";
import React, { PureComponent } from 'react';
import { client } from '../../../apollo';
import { ActionNotification } from "components/UI/Notifications/action";


class UserDescMenu extends PureComponent {

    state = {
        isOpened: false
    };

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleClickOutside = e => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.setState({ isOpened: false });
        }
    };
    addOrRemoveFromBlackList = (e) => {
        e.preventDefault();
        let mutateObj = { variables: { userId: this.props._id }  };
        if (this.props.isLockedYou) {
            mutateObj.mutation = REMOVE_FROM_BLACK_LIST;
        } else {
            mutateObj.mutation = ADD_BLACK_LIST;
        }

        client.mutate({
            ...mutateObj,
            refetchQueries: [{ query: GET_PROFILE_USER, variables:{ "userId": this.props._id } }],
          update: data => { ActionNotification('2', `Успешно ${!this.props.isLockedYou ? 'добавлен в черный список' : 'удален из черного списка'}`);  }
        });

        this.setState({ isOpened: false });
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
    };

    render() {
        const { isLockedYou } = this.props;
        return (
            <div className="posa linealign" ref={this.setWrapperRef}>
                <div className="dots-btn-wrap more-info">
                    <button type="button" className="dots-btn visible-info"
                            onClick={() => this.setState({ isOpened: !this.state.isOpened })}>
                        <span>...</span>
                    </button>
                    {
                        this.state.isOpened &&
                        <ul className="hidden-info" style={{ display: 'block' }}>
                            <li>
                                {
                                    isLockedYou &&
                                    <a className="user-info-link share" href="#"
                                       onClick={this.addOrRemoveFromBlackList}>
                                        <i className="panton d">G</i>
                                        {Language.removeFromBlackList}
                                    </a>
                                }

                                {
                                    !isLockedYou &&
                                    <a className="user-info-link share" href="#"
                                       onClick={this.addOrRemoveFromBlackList}>
                                        <i className="panton d">G</i>
                                        {Language.addToBlackList}
                                    </a>
                                }
                            </li>
                        </ul>
                    }

                </div>
            </div>
        )
    }
}


export default UserDescMenu;