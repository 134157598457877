import React, { Component } from 'react';
import Language  from "language/index";

class RightSide extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="rightside">
        <div className="fixed-help-block">
          <div className="side-block ">
            <div className="sb-title">
              <div className="element clearfix">
                <em>{Language.Chapters}</em>
              </div>
            </div>
            <ul className="blue-links">
              <li><a href='#StatusOfTheTerms'> {Language.StatusOfTheTerms} </a> </li>
              <li><a href='#UseOfCookies'> {Language.UseOfCookies} </a> </li>
              <li><a href='#TheShelfLifeCookies'> {Language.TheShelfLifeCookies} </a> </li>
              <li><a href='#CookieManagement'> {Language.CookieManagement} </a> </li>
              <li><a href='#OtherSimilarTechnologies'> {Language.OtherSimilarTechnologies} </a> </li>
              <li><a href='#ChangesTermsCookies'> {Language.ChangesTermsCookies} </a> </li>
            </ul>
          </div>

          <div className="side-block ">
            <ul className="blue-links">
              <li><a target="_blank" href='/terms'> {Language.TermsOfUser} </a> </li>
            </ul>
          </div>

          <div className="side-block ">
            <ul className="blue-links">
              <li><a target="_blank" href='/privacy'> {Language.UsersPersonalPrivacy} </a> </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default RightSide;