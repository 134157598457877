import { passwords, required } from "libs/fieldValidation";
import { showError } from "libs/showFormsError";
import { passwordConfirm } from "libs/validationsForms";

export const submitSetPasswordForm = (values, mutate) => {
    let { password, repeatPassword } = values;

    let validateObj = {
        password: [required(password)],
        // repeatPassword: [required(repeatPassword)]
    };

    // if (password) {
    //     validateObj['password'] = [passwords(password)]
    // }

    // if (password && repeatPassword) {
    //     validateObj['repeatPassword'] = [passwordConfirm(password, repeatPassword)]
    // }

    showError(validateObj);
    repeatPassword = password;

    mutate({ variables: { password, repeatPassword } })
};
