
const FormatUsd = number =>  new Intl.NumberFormat('ru-RU', {
  style: "currency",
  currency: "USD"
}).format(number);

const FormatCurrency = number => new Intl.NumberFormat('en-EN', {
  maximumSignificantDigits: 8
}).format(number);

const formatIdOffer = id => {
  return (id.replace(/\D+/g, "")).slice(0, 6)
};

export {FormatUsd, FormatCurrency, formatIdOffer};
