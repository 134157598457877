import * as axios from "axios";
import ModalWindow from "components/UI/ModalWindow/index";
import { ActionNotification } from "components/UI/Notifications/action";
import 'cropperjs/dist/cropper.css';
import Language from "language/index";
import { getItem } from "libs/localStorage";
import { SET_AVATAR_PROFILE } from "mutation/profile";
// import Tooltip from 'components/UI/Tooltip';
import PropTypes from 'prop-types';
import { GET_MY_PROFILE } from "query/index";
import React, { PureComponent } from 'react';
import { Mutation } from "react-apollo";
import Cropper from 'react-cropper';


class LoaderImage extends PureComponent {

    static propTypes = {
        input: PropTypes.object,
    };
    static defaultProps = {
        input: {},
    };

    state = {
        file: null,
        isOpen: false
    };
    onChange = value => {
        let file = value.target.files[0];

        let reader = new FileReader();
        reader.readAsDataURL(file);

        if (file.size > 2000000) {
            ActionNotification('1', Language.LargeFileSize);
        } else {
            let formDataToUpload = new FormData();
            formDataToUpload.append("name", file);
            const token = getItem('token', false);
            axios({
                url: '/upload/image',
                method: 'post',
                data: formDataToUpload,
                headers: { 'token': token, 'Content-Type': 'multipart/form-data' }
            })
                .then((response) => {
                    this.setState({ isOpen: false, image: response.data[0].link });
                    // this.props.mutate({ variables: { avatarUrl: response.data[0].link } })
                })
                .catch(error => {
                    this.setState({ isOpen: false });
                    ActionNotification('1', 'Произошла ошибка при загрузке изображение');
                });
        }
    };

    b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    _crop = () => {
        let imageInput = document.querySelector('.inputfile_1');
        imageInput.src = this.refs.cropper.getCroppedCanvas().toDataURL();
        let photoImage = document.querySelector('#photo_user_profile_1');
        let data = new FormData(photoImage);
        let form = document.getElementById("photo_user_profile_1");
        let ImageURL = this.refs.cropper.getCroppedCanvas({
            width: 255,
            height: 332,
            fillColor: '#fff',
        }).toDataURL();

        let block = ImageURL.split(";");
        let contentType = block[0].split(":")[1];
        let realData = block[1].split(",")[1];
        let blob = this.b64toBlob(realData, contentType);

        let formDataToUpload = new FormData(form);
        formDataToUpload.append("name", blob);
        const token = getItem('token', false);

        axios({
            url: '/upload/image',
            method: 'post',
            data: formDataToUpload,
            headers: { 'token': token, 'Content-Type': 'multipart/form-data' }
        })
            .then((response) => {
                this.setState({ isOpen: false });
                this.props.mutate({ variables: { avatarUrl: response.data[0].link } })
            })
            .catch(error => {
                this.setState({ isOpen: false });
                ActionNotification('1', 'Произошла ошибка при загрузке изображение');
            });
    };

    toggleModal = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
        const { input } = this.props;
        return (
            <React.Fragment>
                <div className="element">
                    <div className="row clearfix">
                        <div className="col-12">
                            <p style={{marginLeft: '5px'}}>{Language.coverCard}</p>
                            <div className="container_file_update">
                                <div className="text_input" />
                                <img id="image" src={this.state.image} alt=""/>
                                <input type="file" id="file_upload" name="picture" multiple=""
                                       onChange={this.onChange}/><br/>
                                <img src="/templates/img/upload-to-cloud-button.png" alt=""/>
                                <p>{Language.UploadImageBtn}<br/><span>{Language.notMoreMB}</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.isOpen &&
                    <ModalWindow
                        title={Language.SelectImage}
                        handleClose={this.toggleModal}
                    >
                        <Cropper
                            dragMode="move"
                            ref='cropper'
                            src={this.state.file}
                            style={{ height: 400, width: '99%' }}
                            aspectRatio={3.1 / 4}
                            autoCropArea={0.5}
                            viewMode={0}
                        />
                        <form id="photo_user_profile_1 hidden" style={{ 'display': 'none' }}>
                            <input type="image" src="" className="hide inputfile_1" id="upload" name="picture"/>
                        </form>
                        <div className="modal__body__btn">
                            <button onClick={this._crop} type="button"
                                    className="orange-btn btn_color_green">{Language.save}
                            </button>
                            <button onClick={this.toggleModal} type="button"
                                    className="orange-btn btn_color_red">{Language.Cancel}</button>
                        </div>
                    </ModalWindow>
                }
            </React.Fragment>
        );
    }
}


export default props => {
    return (
        <Mutation
            onCompleted={() => ActionNotification('2', Language.profileUpdated)}
            onError={() => ActionNotification('1', 'Произошла ошибка загрузке изображения')}
            mutation={SET_AVATAR_PROFILE}
            update={(cache, { data: { ProfileSetter } }) => {
                const { ProfileGetter } = cache.readQuery({ query: GET_MY_PROFILE });
                ProfileGetter.getMyProfile.avatar = ProfileSetter.setAvatarProfile.avatar;
                cache.writeQuery({
                    query: GET_MY_PROFILE,
                    data: { ProfileGetter: { ...ProfileGetter } },
                });
            }}
        >
            {mutate => (
                <LoaderImage mutate={mutate} {...props}/>
            )}
        </Mutation>
    )
}