import React, { Component } from 'react';
import Language  from "language/index";
import { Link } from "react-router-dom";

class DescBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      openOrClose: e => this.openOrClose(e),
    };
  }

  openOrClose(e) {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const textMap = this.props.info.text;
    return (
      <li className={this.state.isOpen ? 'help-open' : ''}>

        <a href="/" onClick={this.state.openOrClose}>
          {this.props.info.name}
          <span className="help-arrow">
            <img src="templates/img/down-arrow.svg" alt=""/>
          </span>
        </a>

        <div className="hidden-block">

          {
            textMap.map((text, i) => {
              if (text.hasOwnProperty('attention')) {
                return (
                  <p key={i}>
                    <span> {text.attention} </span>
                    {text.text}
                  </p>
                )
              }
              if (text.hasOwnProperty('link')) {
                return (
                  <p key={i}>
                    {`${text.text} `}
                    <a target="_blank" href={text.link.url}>{text.link.text} </a>
                  </p>
                )
              }
              return (
                <p key={i}>{text}</p>
              );
            })
          }

          <Link
            to='/support'
            className="btn btn-default" style={{textDecoration: 'none', margin: '6px 0', display: 'inline-block'}}>{Language.DidNotHelp}</Link>

        </div>
        <hr/>
      </li>
    );
  }

}

export default DescBlock;
