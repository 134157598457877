import React, { useMemo } from 'react'
import { useFieldArray } from 'react-hook-form';

import { FormfieldArray } from 'pages/Profile/EditForms/FormfieldArray';

export const careerValues = {
  jobName: '',
  position: '',
  beginDate: '',
  endDate: '',
}

export const CareerForm = ({ control, errors }) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: "career",
  });

  const fieldNames = useMemo(() => {
    return [
      { label: 'Место работы', name: 'jobName' },
      { label: 'Должность', name: 'position' },
      { label: 'Год начала работы', name: 'beginDate' },
      { label: 'Год окончания работы', name: 'endDate' },
      { label: 'Добавить место работы' },
    ]
  }, [])

  return (
    <FormfieldArray
      control={control}
      fields={fields}
      append={append}
      remove={remove}
      fieldNames={fieldNames}
      name="career"
      appendValues={careerValues}
      errors={errors}
    />
  )
}
