import React from 'react';
import { Avatar } from 'components/v1/Avatar';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss'
import { dateToString } from 'libs/convertDate';

export const NotificationItem = ({ isMe, userFrom, userTo, createDate, ...rest }) => {

  if (rest.__typename === 'NewsSystem') {
    return null;
  }

  return (
    <div className={styles.item}>
      <Link to={`/profile/${isMe ? 'me' : userFrom._id}`}>
        <Avatar source={userFrom ? userFrom.avatar : ''} size={'circle'} />
      </Link>
      <div className={styles.body}>
        {userFrom ? (
          <h4>
            <Link to={`/profile/${isMe ? 'me' : userFrom._id}`}>
              {userFrom.firstName} {userFrom.lastName}
            </Link>
          </h4>
        ) : (
          <h4>Система</h4>
        )}
        {['acceptContact', 'createOffer','changeAvatar'].includes(rest.newsType) && (
          <p>
            {rest.newsType === 'acceptContact' &&
              <>Добавил в контакты {userTo && <Link to={`/profile/${userTo._id}`}>{userTo.firstName} {userTo.lastName}</Link>}</>
            }
            {
              rest.newsType === 'createOffer' &&
              'Добавил новую заявку'
            }
            {
              rest.newsType === 'changeAvatar' &&
              'Сменил/Загрузил новый аватар'
            }
          </p>
        )}
        <div className={styles.createDate}>
          {dateToString(new Date(createDate), 'stringFullDate')}
          {new Date(createDate).getFullYear() < new Date(Date.now()).getFullYear()
            ? ` ${new Date(createDate).getFullYear()}`
            : ''
          }, {dateToString(new Date(createDate), 'time')}
        </div>
      </div>
    </div>
  )
}
