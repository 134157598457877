import PropTypes from 'prop-types';
import React from 'react';
import * as ReactDOM from 'react-dom';


export default class ModalWindow extends React.PureComponent {

    static propTypes = {
        children: PropTypes.any.isRequired,
        handleClose: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        document.addEventListener('click', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
        document.removeEventListener('click', this.handleClickOutside, false);
    }


    handleClickOutside = (e) => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
           // this.props.handleClose();
        }
    };


    render() {
        const { children, handleClose, title } = this.props;
        console.log(title);
        return ReactDOM.createPortal(
            <div className={`modal ${this.props.className}`}>
                <div className="overlay" style={{opacity: 1}} onClick={handleClose}/>
                <div className="flex" >
                    <div className="modal_wrap" ref={ref => this.wrapperRef = ref }>
                        <div className="modal__title">
                            <p> {title} </p>
                            <button type="button" className="close" onClick={handleClose}/>
                        </div>
                        <div className="modal__body">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
            ,
            document.getElementById('modal')
        );
    }
}
