import { changeStatusRequests } from "components/Pages/Requests/changeStatusRequest";
import ModalWindow from "components/UI/ModalWindow/index";
import Language from "language/index";
import { dateToString } from "libs/convertDate";
import { formatIdOffer } from "libs/formatNumbers";
import PropTypes from 'prop-types';
import { GET_OFFERS_ARCHIVE } from "query/offers";
import React, { PureComponent } from 'react';
import { Query } from "react-apollo";
import Loading from "../Prealoders/Loading";


class Archive extends PureComponent {

    static propTypes = {
        OfferGetter: PropTypes.object
    };

    static defaultProps = {
        OfferGetter: { getOfferList: { offers: [], total: 0 } }
    };

    state = {
        isOpened: false,
        req: null
    };

    toggleModal = (el = null) => this.setState({ isOpened: !this.state.isOpened, req: el });

    changeStatusOffer = status => {
        changeStatusRequests(status, this.state.req._id, { query: GET_OFFERS_ARCHIVE, variables: { archive: true } });
        this.toggleModal();
    };

    render() {
        const { offers, total } = this.props.OfferGetter.getOfferList,
            { loading } = this.props;

        return (
            <div className="side-block">

                <div className="sb-title">
                    <em>Архив</em>
                </div>

                {
                    (loading && offers.length === 0) &&
                    <Loading/>
                }

                {
                    (!loading && offers.length === 0) &&
                    <p className="not_found_text"> В архиве пока пусто </p>
                }

                <ul className="blue-links">
                    {
                        offers.map((el, index) => {
                            if (el.status === 'ARCHIVED') {
                                return (
                                    <li key={index}>
                                        <div onClick={() => this.toggleModal(el)}>
                                            №{formatIdOffer(el._id)}&nbsp;
                                            {Language.from}&nbsp;
                                            {dateToString(new Date(el.createDate), 'stringFullDate')}
                                        </div>
                                    </li>
                                )
                            } else return null
                        })
                    }
                </ul>

                {
                    total.length > 6 &&
                    <div className="view-all">
                        <a href="#"> {Language.showMore} </a>
                    </div>
                }

                {
                    this.state.isOpened &&
                    <ModalWindow handleClose={this.toggleModal} title={Language.confirmAction}>
                        <p>
                            {Language.deleteRequestArchive} {dateToString(new Date(this.state.req.createDate), 'stringFullDate')}
                            ?
                        </p>
                        <div className="modal__body__btn">
                            <button
                                onClick={() => this.changeStatusOffer('CREATED')}
                                type="button"
                                className="orange-btn btn_color_green"> {Language.restore}
                            </button>
                            <button
                                onClick={() => this.changeStatusOffer('REMOVED')}
                                type="button"
                                className="orange-btn btn_color_red">{Language.deleteApp}
                            </button>
                        </div>
                    </ModalWindow>
                }

            </div>
        )
    }
}


export default () => {
    return (
        <Query query={GET_OFFERS_ARCHIVE} variables={{ archive: true }}>
            {({ loading, error, data, refetch }) => {
                if (error) return 'Error...';
                return (
                    <Archive loading={loading} refetch={refetch} {...data}/>
                )
            }}
        </Query>
    )
};