import { gql } from "apollo-boost";

export const RESTORE_PASSWORD = gql`
    mutation ($phone: String!, $captcha: String!){
        restorePassword(phone: $phone captcha: $captcha) {
            phone
        }
    }
`;

export const SET_AVATAR_PROFILE = gql`
    mutation ($avatarUrl: String){
        ProfileSetter {
            setAvatarProfile(avatarUrl: $avatarUrl) {
                ...on EntityProfile {
                    avatar
                }

                ... on IndividualProfile {
                    avatar
                }
            }
        }
    }
`;

export const SET_INDIVIDUAL_PROFILE = gql`
    mutation ($profile: IndividualProfileInput){
        ProfileSetter {
            setIndividualProfile(profile: $profile) {
                _id
                firstName
                lastName
                avatar
                sex
                industry {
                    name
                    groupName
                }
                typeKeys
                birthday
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                industryDetail
                email
                site
                description
            }
        }
    }
`;

export const SET_ENTITY_PROFILE = gql`
    mutation ($profile: EntityProfileInput){
        ProfileSetter {
            setEntityProfile(profile: $profile) {
                _id
                firstName
                lastName
                avatar
                industry {
                    groupName
                    name
                }
                typeKeys
                birthday
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                termOfWork {
                    count
                    typeDate
                }
                email
                site
                description
            }
        }
    }
`;


export const SET_ENTITY_PROFILE_ADMIN = gql`
    mutation ($profile: EntityProfileInput){
        ProfileSetter {
            adminChangeEntityProfile(params: $profile) {
                _id
                firstName
                lastName
                avatar
                industry {
                    groupName
                    name
                }
                typeKeys
                birthday
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                termOfWork {
                    count
                    typeDate
                }
                email
                site
                description
                typeKeys
            }
        }
    }
`;

export const SET_INDIVIDUAL_PROFILE_ADMIN = gql`
    mutation ($profile: IndividualProfileInput){
        ProfileSetter {
            adminChangeIndividualProfile(params: $profile) {
                _id
                firstName
                lastName
                midName
                avatar
                sex
                industry {
                    name
                    groupName
                }
                typeKeys
                birthday
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                industryDetail
                email
                site
                description
                typeKeys
            }
        }
    }
`;


export const SET_NOTIFICATION_STATUS = gql`
    mutation($notification: Boolean) {
        ProfileSetter {
            setNotification(notification: $notification)
        }
    }
`;

export const CANCEL_NOTIFICATION = gql`
    mutation($userId: String) {
        ProfileSetter {
            disableNotificationByLink( UserId: $userId)
        }
    }
`;
