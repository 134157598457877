import cn from 'classnames'

import { ArrowLeftIcon } from 'components/v1/Icons/ArrowLeftIcon'
import { goBack } from 'libs/forwardTo'

import styles from './styles.module.scss'

// icon also = 'close'
export const BackButton = ({ className, onClick, icon = 'back' }) => {



  return (
    <button
      className={cn(styles.button, className)}
      onClick={onClick || goBack}
      type="button"
    >
      <ArrowLeftIcon />
    </button>
  )
}
