import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { MessengerIcon } from 'components/v1/Icons/MessengerIcon'
import { RequestNetworkIcon } from 'components/v1/Icons/RequestNetworkIcon'
import { SearchIcon } from 'components/v1/Icons/SearchIcon'
import { NewsIcon } from 'components/v1/Icons/NewsIcon'
import { BurgerIcon } from 'components/v1/Icons/BurgerIcon'

import styles from './styles.module.scss'

export const BottomNavBar = ({ location }) => {

  const [activeMenu, setActiveMenu] = useState()

  const list = [
    { href: '/news', title: 'Новости', icon: <NewsIcon/>, value: 'news' },
    { href: '/contacts', title: 'Поиск', icon: <SearchIcon/>, value: 'contacts' },
    { href: '/nnnotice', title: 'Предложения', icon: <RequestNetworkIcon/>, value: 'nnnotice' },
    { href: '/messages', title: 'Чаты', icon: <MessengerIcon/>, value: 'messages' },
    { href: '/menu', title: 'Меню', icon: <BurgerIcon/>, value: 'menu' },
  ]

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        {list.map((el) => (
          <div key={el.title} className={cn(styles.item, { [styles.active]: location.includes(el.value) })}>
            <Link to={el.href}>
              {el.icon}
              <span>{el.title}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
