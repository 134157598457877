import gql from 'graphql-tag';

export const GET_MESSAGES = gql`
    query($dialogId: String!, $page: Int, $count: Int) {
        DialogGetter {
            getMessagesByDialogId(dialogId: $dialogId, page: $page, count: $count) {
                messages {
                    _id
                    dialogId
                    senderId
                    text
                    isRead
                    links
                    createDate
                }
                total
            }
        }
    }
`;


export const GET_COUNTER_NEW_MSG = gql`
    query {
        DialogGetter {
            getCountNewMsg
        }
    }
`;


