import gql from 'graphql-tag';

const contacts = gql`
    fragment contactsItem on User {
        _id
        firstName
        lastName
        avatar
        typeKeys
        isContacts
        isLockedUser
        isLockedYou
#        personalLink
        address {
            countryId
            stateId
            cityId
        }
        onlineStatus {
            isOnline
            lastTime
        }
    }
`;

export const GET_CONTACTS = gql`query($status: ContactStatusEnum, $count: Int, $name: String, $page: Int) {
    ContactGetter {
        getContactList (status: $status, count: $count, name: $name, page: $page ) {
            contacts {
                user {
                    ...contactsItem   
                }
                status
                tags
            }
            total
        }
    }
}
${contacts}
`;

export const GET_CONTACTS_BY_USERID = gql`query($userId: String!, $count: Int) {
    ContactGetter {
        getContactListByUserId (userId: $userId, count: $count ) {
            contacts {
                user {
                    ...contactsItem
                }
                status
                tags
            }
            total
        }
    }
}
${contacts}
`;

export const GET_GUESTS = gql`query {
    getGuests {
        _id
        firstName
        lastName
        avatar
        isContacts
        isLockedYou
        isLockedUser
        typeKeys
        onlineStatus {
            isOnline
            lastTime
        }
    }
}
`;

export const GET_CONTACTLIST_BY_USERID = gql`query($userId: String!, $count: Int, $page: Int) {
    ContactGetter {
        getContactList (userId: $userId, count: $count, page: $page ) {
            contacts {
                user {
                    ...contactsItem
                }
                status
                tags
            }
            total
        }
    }
}
${contacts}
`;


export const GET_COUNT_REQUEST = gql`
    query {
        ContactGetter {
            getCountRequest
        }
    }
`;


export const GET_BLACK_LIST = gql`
    query($page: Int, $count: Int) {
        BlackListGetter {
            getBlackList(page: $page, count: $count) {
                blackList {
                    _id
                    firstName
                    lastName
                    avatar
                    typeKeys
#                    personalLink
                }
                total
            }
        }
    }
`

