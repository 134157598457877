// import Tooltip from 'components/UI/Tooltip';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';

import classes from './styles.module.scss'
import { EyeClosedIcon } from 'components/v1/Icons/EyeClosedIcon';
import { EyeOpenedIcon } from 'components/v1/Icons/EyeOpenedIcon';

const RelativeContainer = ({ type, children }) => {
    if (type === 'password') {
        return (
          <div className={classes.relativeContainer}>
              {children}
          </div>
        )
    }

    return <>{children}</>
}

export default class Input extends PureComponent {

    state = {
        showPassword: false,
    }

    static propTypes = {
        input: PropTypes.object,
        meta: PropTypes.object,
        type: PropTypes.string,
        label: PropTypes.string,
        strong: PropTypes.string,
        mask: PropTypes.string,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        counter: PropTypes.oneOfType(PropTypes.bool, PropTypes.number),
    };
    static defaultProps = {
        input: {},
        meta: {},
        type: 'text',
        label: '',
        strong: '',
        mask: '',
        placeholder: '',
        className: 'element',
        disabled: false,
        counter: false,
    };

    inputRef = undefined;

    render() {
        const { input, label, strong, type, className, meta, mask, placeholder, change, disabled, counter, children, errors } = this.props;

        return (
            <React.Fragment>
                <div className={cn(classes.input, className, { [classes.error]: meta.error || errors })}>
                    {label !== '' && <p className={classes.label}>{label}</p>}
                    {strong !== '' && <strong>{strong}</strong>}
                    {
                        counter !== false &&
                        <div className="sms-code">
                            <InputMask
                                {...input}
                                inputRef={ref => this.inputRef = ref}
                                type={type}
                                mask={mask}
                                onChange={e => {
                                    change && change(e.target.value);
                                    e.target.value = e.target.value.slice(0, 40);
                                    this.props.input.onChange(e);
                                }}
                                value={this.props.input.value}
                                placeholder={placeholder}
                                className={(meta.error || errors) && 'error'}
                                disabled={disabled}
                            />
                            <div className="counter-down">
                                <span className="dynamicNumber">{counter}</span> сек
                            </div>
                        </div>
                    }
                    {
                        counter === false &&
                          <RelativeContainer type={type}>
                            <InputMask
                                {...input}
                                inputRef={ref => this.inputRef = ref}
                                type={this.state.showPassword ? 'text' : type}
                                mask={mask}
                                onChange={e => {
                                    change && change(e.target.value);
                                    e.target.value = e.target.value.slice(0, 40);
                                    this.props.input.onChange(e);
                                }}
                                value={this.props.input.value}
                                placeholder={placeholder}
                                className={(meta.error || errors) && 'error'}
                                disabled={disabled}
                            />
                              {type === 'password' && (
                                <div className={classes.showPassword}>
                                    {this.state.showPassword
                                      ? (<EyeOpenedIcon onClick={() => this.setState({ showPassword: false })}/>)
                                      : (<EyeClosedIcon onClick={() => this.setState({ showPassword: true })}/>)}
                                </div>
                              )}
                          </RelativeContainer>
                    }
                    {errors && (
                      <span className="error">{errors.message}</span>
                    )}
                    {
                        meta.error &&
                        <span className="error">{meta.error}</span>
                    }

                    {
                        children &&
                        children
                    }
                </div>
            </React.Fragment>
        );
    }
}
