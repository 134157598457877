import React from 'react';
import { Query } from 'react-apollo';
import { GET_CONTACTS_BY_USERID } from 'query/contacts';

import { MyActions, User } from 'components/v1/User';
import { RecommendContacts } from 'components/v1/RecommendContacts';

import classes from './styles.module.scss';
import { EmptyContent } from 'components/v1/EmptyContent';

export const UserContactsComponent = ({ users, typeUser }) => {
  return (
    <div className={classes.root}>
      <div className={classes.users}>
        {users?.map((contact) => (
          <User
            key={contact.user._id}
            {...contact.user}
            // actions={(
            //   <div className={classes.buttons}>
            //     <MyActions {...contact.user} />
            //   </div>
            // )}
          />
        ))}
      </div>
      <aside>
        <RecommendContacts typeUser={typeUser} />
      </aside>
    </div>
  )
}

export const UserContacts = props => {
  return (
    <Query query={GET_CONTACTS_BY_USERID}
           variables={{ 'userId': props.id, count: 6 }}>
      {({ data, loading, error }) => {
        if (error || loading || !data) return null;
        if (data.ContactGetter && data.ContactGetter.getContactListByUserId && data.ContactGetter.getContactListByUserId.total === 0) return <EmptyContent text=""/>;
        return (
          <UserContactsComponent {...props} users={data.ContactGetter.getContactListByUserId.contacts}/>
        )
      }}
    </Query>
  )
};
