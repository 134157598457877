import SearchProfileUser from "components/Pages/Admin/SearchProfileUser";
import ProfileUsersList from "components/UI/Admin/ProfileUsersList";
import SideBlock from "components/UI/SideBlock/index";
import React, { Component } from 'react';
import StatisticUsers from "components/UI/Admin/StatisticUsers";
import SearchStatisticUsers from "components/UI/Admin/SearchStatisticUsers";


class ProfileUsers extends Component {

    state = {
        activeTabIndex: 0,
        search: {},
        searchStatistic: {}
    };


    changeTab = activeTabIndex => this.setState({ activeTabIndex });

    searchFilterUsers = values => {
        this.setState({ search: { ...values } });
    };

    resetSearch = () => {
        this.setState({ search: {} })
    };

    searchFilterUsersStatistic = values => {
        this.setState({ searchStatistic: { ...values } });
    };

    resetSearchStatistic = () => {
        this.setState({ searchStatistic: {} })
    };


    render() {

        const isActiveUser = this.state.activeTabIndex === 0;
        let tabs = [
            { title: 'Список пользователей', type: 'user_list' },
            { title: 'Статистика', type: 'statistic', },
        ];

        return (
            <React.Fragment>
                <div className="centerside">

                    <SideBlock tabs={tabs} changeTab={this.changeTab} activeTabIndex={this.state.activeTabIndex}/>
                    {
                        isActiveUser &&
                        <ProfileUsersList variables={this.state.search}/>
                    }

                    {
                        !isActiveUser &&
                        <StatisticUsers variables={this.state.searchStatistic}/>
                    }

                </div>
                <div className="rightside">
                    {
                        isActiveUser &&
                        <SearchProfileUser resetSearch={this.resetSearch} onSubmit={this.searchFilterUsers}/>
                    }

                    {
                        !isActiveUser &&
                        <SearchStatisticUsers resetSearch={this.resetSearchStatistic} onSubmit={this.searchFilterUsersStatistic}/>
                    }
                </div>
            </React.Fragment>
        )
    }
}


export default ProfileUsers;
