import React from 'react';

import { AsideTab } from './AsideTab';
import classes from './styles.module.scss';


export const AsideTabs = ({ items = [], activeTab, onChangeTab }) => {
  return (
    <div className={classes.root}>
      {items.map(({ label, value, count }) => (
        <AsideTab
          key={value}
          label={label}
          value={value}
          count={count}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
        />
      ))}
    </div>
  );
};
