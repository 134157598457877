export const MessengerIcon = ({ className }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.51337 0C8.26757 0 7.03396 0.245714 5.88299 0.723112C4.73202 1.20051 3.68622 1.90024 2.80531 2.78236C1.02622 4.56388 0.0267394 6.98013 0.0267394 9.49958C0.018446 11.6932 0.776935 13.8205 2.17072 15.5128L0.273392 17.4127C0.141757 17.5463 0.0525867 17.716 0.0171313 17.9002C-0.0183242 18.0845 0.0015244 18.2752 0.0741725 18.4482C0.152966 18.6191 0.2807 18.7627 0.44116 18.8608C0.601619 18.9589 0.787568 19.0071 0.975402 18.9992H9.51337C12.0294 18.9992 14.4423 17.9983 16.2214 16.2168C18.0005 14.4353 19 12.019 19 9.49958C19 6.98013 18.0005 4.56388 16.2214 2.78236C14.4423 1.00085 12.0294 0 9.51337 0ZM9.51337 17.0992H3.26168L4.14394 16.2158C4.32063 16.0378 4.4198 15.797 4.4198 15.5461C4.4198 15.2951 4.32063 15.0543 4.14394 14.8763C2.90174 13.6338 2.12819 11.9985 1.95508 10.2489C1.78196 8.49932 2.21999 6.74376 3.19454 5.28131C4.16909 3.81887 5.61986 2.74002 7.29968 2.22858C8.9795 1.71713 10.7844 1.80473 12.407 2.47646C14.0295 3.14818 15.3693 4.36247 16.1981 5.91244C17.0268 7.4624 17.2932 9.25216 16.952 10.9768C16.6107 12.7014 15.6828 14.2542 14.3264 15.3705C12.9701 16.4869 11.2691 17.0979 9.51337 17.0992Z" fill="currentColor"/>
    </svg>
  )
};



