import React, { useState } from 'react';

import { Page } from 'components/v1/Page';
import { List } from 'components/v1/Menu';
import { RequestNetworkIcon } from 'components/v1/Icons/RequestNetworkIcon';
import { MyRequestIcon } from 'components/v1/Icons/MyRequestIcon';
import { ArchiveIcon } from 'components/v1/Icons/ArchiveIcon';
import { Button } from 'components/v1/Button';
import { PlusIcon } from 'components/v1/Icons/PlusIcon';
import { SearchIcon } from 'components/v1/Icons/SearchIcon';
import forwardTo from 'libs/forwardTo';

import { ApplicationList } from './components/ApplicationList';

import classes from './styles.module.scss';
import SearchRequest from 'components/UI/Forms/SearchRequest/SearchRequest';
import { SettingsRequesIcon } from 'components/v1/Icons/SettingsRequesIcon';
import { LeftMenu } from 'components/v1/LeftMenu';
import { removeItem } from 'libs/localStorage';
import { HeaderMobile } from 'components/v1/HeaderMobile';

const items = [
  { icon: <RequestNetworkIcon />, title: 'Предложения сети', link: '/nnnotice' },
  { icon: <MyRequestIcon />, title: 'Мои предложения', link: '/nnnotice-my' },
  // { icon: <FavoriteIcon />, title: 'Избранные предложения', link: '/favorites-request' },
  // { icon: <ArchiveIcon />, title: 'Архив предложений', link: '/archive-request' },
];

export const Request = (props) => {
  const [variables, setVariables] = useState({})

  const handleSearch = (values) => {
    let value = { typeOffer: values.typeOffer };

    if (values.hasOwnProperty('industry')) {
      if (values.industry.groupName) value.industryGroupName = values.industry.groupName;
      if (values.industry.name) value.industryName = values.industry.name;
    }

    if (values.hasOwnProperty('address')) {
      if (values.address.countryId) value.countryId = values.address.countryId;
      if (values.address.stateId) value.stateId = values.address.stateId;
      if (values.address.cityId) value.cityId = values.address.cityId;
    }

    setVariables(value);
  }

  const handleResetSearch = () => {
    setVariables({});
  }

  return (
    <Page className={classes.container}>
      <HeaderMobile goBack title="Предложения" />
      <LeftMenu content={(
        <div className={classes.searchRequest}>
          <div className={classes.subTitle}>
            <SettingsRequesIcon />
            Настроить фильтр
          </div>
          <SearchRequest
            onSubmit={handleSearch}
            resetSearch={handleResetSearch}
          />
        </div>
      )}/>
      {/*<Page.Aside className={classes.menu}>*/}
      {/*  */}
      {/*  <List items={items} />*/}
      {/*</Page.Aside>*/}
      <Page.Body className={classes.body}>
        <div className={classes.search}>
          <Button variant="outlined" onClick={() => {
            removeItem('editrequest')
            forwardTo('/create')
          }}>
            <PlusIcon className={classes.icon} />
            Добавить предложение
          </Button>
          {/*<div className={classes.input}>*/}
          {/*  <SearchIcon className={classes.icon} />*/}
          {/*  <input type="text" placeholder="Поиск предложений"/>*/}
          {/*</div>*/}
        </div>
        <ApplicationList isMy={props.match.path === '/nnnotice-my'} variables={variables} />
      </Page.Body>
    </Page>
  );
};

