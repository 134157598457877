import Input from "components/UI/Fields/Input";
import React from 'react';
import { Field } from "redux-form";
import Language from "language/index";

export const NameFieldGroup = () => {
    return (
        <React.Fragment>
            <Field
                name="firstName"
                type="text"
                component={Input}
                label={`${Language.firstName} *`}
                placeholder="Введите имя"
            />
            <Field
                name="lastName"
                type="text"
                component={Input}
                label={`${Language.lastName} *`}
                placeholder={Language.EnterLastName}

            />
        </React.Fragment>
    );
};