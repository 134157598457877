import gql from 'graphql-tag';

export const GET_DIALOGS = gql`
    fragment user on User {
        _id
        firstName
        lastName
        avatar
        typeKeys
        birthday
#        personalLink
        onlineStatus {
            isOnline
            lastTime
        }
    }

    query($favoriteDialog: Boolean, $offerDialog: Boolean, $page: Int) {
        DialogGetter {
            getMyDialogs(favoriteDialog: $favoriteDialog, offerDialog: $offerDialog, count: 50, page: $page) {
                dialogs {
                    _id
                    userTo {
                        ...user
                    }
                    userFrom {
                        ...user
                    }
                    lastMsg {
                        _id
                        dialogId
                        senderId
                        text
                        isRead
                        links
                        createDate
                    }
                    offer {
                        _id
                    }
                    favorite
                    countNewMsg
                }
                total
            }
        }
    }
`;

export const GET_DIALOG_BY_ID = gql`
    fragment user on User {
        _id
        firstName
        lastName
        avatar
        typeKeys
        birthday
    }

    query($dialogId: String!) {
        DialogGetter {
            getDialogById(dialogId: $dialogId) {
                _id
                userTo {
                    ...user
                }
                userFrom {
                    ...user
                }
                offer {
                    _id
                }
                favorite
            }
        }
    }
`;

export const GET_MESSAGES_BY_DIALOG_ID = gql`query ($dialogId: String!){
    DialogGetter {
        getMessagesByDialogId(dialogId: $dialogId) {
            messages {
                _id
                dialogId
                senderId
                text
                isRead
                links
                createDate
            }
            total
        }
    }
}
`;

