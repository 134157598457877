export const NotFoundIcon = ({ className }) => {
  return (
    <svg width="258" height="262" viewBox="0 0 258 262" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_326_5248)">
        <path d="M0 123.507V154.893C0 160.572 4.62013 165.177 10.3176 165.177H123.21C128.907 165.177 133.528 160.572 133.528 154.893V128.376L147.58 113.658L10.3441 113.23C4.63334 113.21 0 117.821 0 123.514L0 123.507Z" fill="#B9B9B9" fillOpacity="0.15"/>
        <path d="M116.885 132.243L14.4355 131.769L14.4488 128.633L116.898 129.101L116.885 132.243Z" fill="#E3E3E3"/>
        <path d="M116.885 142.566L14.4355 142.092L14.4488 138.956L116.898 139.43L116.885 142.566Z" fill="#E3E3E3"/>
        <path d="M76.363 149.288L76.3477 152.424L116.884 152.621L116.899 149.485L76.363 149.288Z" fill="#B9B9B9" fillOpacity="0.15"/>
        <path d="M98.9464 36.8471V57.659C98.9464 61.4275 95.8861 64.4778 92.1054 64.4778H17.225C13.4443 64.4778 10.384 61.4275 10.384 57.659V40.0687L1.06445 30.3117L92.0856 30.0284C95.8729 30.0152 98.9464 33.0721 98.9464 36.8471Z" fill="#B9B9B9" fillOpacity="0.4"/>
        <path d="M21.4226 42.6376L89.3695 42.3279L89.3629 40.2461L21.416 40.5557L21.4226 42.6376Z" fill="#F5F5F5"/>
        <path d="M21.4226 49.4899L89.3695 49.1736L89.3629 47.0918L21.416 47.408L21.4226 49.4899Z" fill="#F5F5F5"/>
        <path d="M48.3036 53.9511L21.416 54.082L21.4262 56.1639L48.3138 56.0329L48.3036 53.9511Z" fill="#F5F5F5"/>
        <path d="M240.794 141.091V161.903C240.794 165.671 237.734 168.722 233.953 168.722H159.073C155.292 168.722 152.232 165.671 152.232 161.903V144.313L142.912 134.549L233.933 134.266C237.721 134.252 240.794 137.309 240.794 141.084V141.091Z" fill="#B9B9B9" fillOpacity="0.4"/>
        <path d="M163.27 146.883L231.217 146.573L231.211 144.491L163.264 144.801L163.27 146.883Z" fill="#F5F5F5"/>
        <path d="M163.27 153.733L231.217 153.417L231.211 151.335L163.264 151.651L163.27 153.733Z" fill="#F5F5F5"/>
        <path d="M190.147 158.201L163.26 158.332L163.27 160.414L190.158 160.283L190.147 158.201Z" fill="#F5F5F5"/>
        <path d="M65.4688 88.5639C63.4264 86.9827 62.5143 84.1894 62.9373 81.6463C63.3669 79.1033 65.0127 76.8568 67.141 75.4008C68.1258 74.7288 69.3486 74.1951 70.4987 74.5311C71.5695 74.8474 72.3163 75.8817 72.5345 76.9753C72.7526 78.069 72.5146 79.2021 72.1511 80.2562C70.8953 83.8665 69.0181 87.1343 65.4688 88.5705" fill="#455A64"/>
        <path d="M62.1108 94.7307C58.0856 91.0677 52.2096 89.5393 46.9021 90.7647C45.5471 91.0743 44.0269 91.7858 43.8022 93.1562C43.5774 94.5265 44.8068 95.686 46.0098 96.3778C51.0133 99.27 57.7947 98.7232 62.2695 95.0733" fill="#455A64"/>
        <path d="M42.7115 66.1776C44.9456 65.5649 47.3911 66.1183 49.3674 67.3174C51.3437 68.5164 52.8903 70.3215 54.1263 72.2716C56.519 76.0401 57.365 81.1129 57.4708 85.5731C52.5004 85.3755 48.257 81.4819 46.2278 79.3671C43.0222 76.0335 39.2547 68.0618 42.7115 66.171" fill="#455A64"/>
        <path d="M69.6325 101.47C69.3417 100.943 68.8658 100.574 68.3899 99.6055C67.458 97.6752 66.2021 94.9214 65.5941 91.6536C64.986 88.3793 65.3958 85.2829 66.2154 83.2669C66.6119 82.2524 67.0614 81.4815 67.4183 80.994C67.7752 80.4999 68.0066 80.2627 68.033 80.2759C68.1189 80.3286 67.2399 81.3695 66.5525 83.3855C65.8452 85.3817 65.5015 88.3727 66.0898 91.568C66.6846 94.7632 67.8678 97.4973 68.7204 99.4606" fill="#263238"/>
        <path d="M69.6782 101.53C69.0106 100.673 68.1447 99.5533 67.0872 98.2027C64.9126 95.3896 61.892 91.5092 58.5013 87.2664C55.1106 83.0302 51.9908 79.2289 49.7237 76.4882C48.6398 75.164 47.7342 74.0704 47.0468 73.2337C46.4189 72.4629 46.0884 72.0214 46.1083 72.0017C46.1347 71.9819 46.5181 72.3772 47.1922 73.1085C47.8664 73.8398 48.8248 74.9202 49.9881 76.2642C52.3279 78.9522 55.4939 82.7206 58.8847 86.9634C62.2754 91.2127 65.2563 95.1261 67.3582 97.9985C68.4091 99.4347 69.2552 100.601 69.817 101.424" fill="#263238"/>
        <path d="M50.0079 93.6896C50.0013 93.6567 50.3053 93.5644 50.8672 93.4261C51.1514 93.3536 51.4951 93.2746 51.9049 93.2284C52.3147 93.1757 52.7773 93.0901 53.2995 93.0769C54.3372 92.9979 55.5798 93.0374 56.948 93.2153C58.3096 93.4195 59.7902 93.7753 61.2839 94.3418C62.7645 94.9282 64.0996 95.666 65.2431 96.4303C66.3733 97.2209 67.3185 98.0312 68.0257 98.7888C68.4025 99.1446 68.6867 99.5267 68.9511 99.8363C69.2221 100.146 69.427 100.436 69.5856 100.68C69.9095 101.161 69.8632 101.385 69.8368 101.404C69.7575 101.464 69.2485 100.449 67.7812 99.0326C67.0541 98.3211 66.1023 97.5569 64.9853 96.8058C63.8551 96.0811 62.5464 95.3696 61.1055 94.803C59.6514 94.2496 58.2105 93.8938 56.8885 93.6698C55.56 93.4722 54.3438 93.3931 53.3193 93.4261C51.277 93.4788 50.0277 93.7753 50.0079 93.683V93.6896Z" fill="#263238"/>
        <path d="M160.886 261.794L84.5448 261.444C75.364 261.405 67.9546 253.954 67.9943 244.796L68.8007 54.0299C68.8403 44.8724 76.3224 37.4805 85.5098 37.5266L161.851 37.8758C171.032 37.9153 178.441 45.3665 178.401 54.524L177.595 245.29C177.555 254.448 170.073 261.84 160.886 261.794Z" fill="#263238"/>
        <path d="M161.738 44.4567L146.94 44.3908C144.957 44.3842 143.337 46.051 143.331 48.1197L143.317 50.7945C143.311 52.8632 141.692 54.53 139.709 54.5234L110.858 54.3916C108.875 54.385 107.269 52.6985 107.282 50.6298L107.295 47.955C107.302 45.8863 105.702 44.1998 103.719 44.1932L98.5108 44.1668L85.556 44.1075C79.1248 44.0812 73.8834 49.2529 73.857 55.6697L73.0704 241.87C73.044 248.281 78.2325 253.498 84.6637 253.531L160.853 253.881C167.284 253.914 172.525 248.735 172.552 242.318L173.338 56.1177C173.365 49.7074 168.176 44.4896 161.745 44.4633L161.738 44.4567Z" fill="#FAFAFA"/>
        <path d="M118.623 236.054C118.623 236.798 117.916 237.398 117.043 237.391C116.177 237.391 115.477 236.778 115.477 236.041C115.477 235.296 116.184 234.697 117.056 234.703C117.922 234.703 118.623 235.316 118.623 236.054Z" fill="#455A64"/>
        <path d="M123.496 236.073C123.496 236.818 122.789 237.417 121.916 237.411C121.05 237.411 120.35 236.798 120.35 236.06C120.35 235.316 121.057 234.716 121.929 234.723C122.802 234.729 123.496 235.335 123.496 236.073Z" fill="#455A64"/>
        <path d="M128.367 236.1C128.367 236.844 127.66 237.444 126.787 237.437C125.921 237.437 125.221 236.824 125.221 236.086C125.221 235.342 125.928 234.742 126.8 234.749C127.666 234.749 128.367 235.362 128.367 236.1Z" fill="#455A64"/>
        <path d="M96.627 134.227C97.8696 134.404 172.757 149.043 172.757 149.043V134.227H96.627Z" fill="#B9B9B9" fillOpacity="0.15"/>
        <path d="M73.0645 228.299C73.0645 228.299 154.535 216.81 153.821 217.165C153.114 217.521 73.0645 217.165 73.0645 217.165V228.299Z" fill="#B9B9B9" fillOpacity="0.15"/>
        <path d="M167.674 10.8975C167.766 10.8975 167.846 15.0019 167.846 20.0682C167.846 25.1344 167.766 29.2389 167.674 29.2389C167.581 29.2389 167.502 25.1344 167.502 20.0682C167.502 15.0019 167.581 10.8975 167.674 10.8975Z" fill="#263238"/>
        <path d="M200.273 31.9994C200.312 32.085 196.571 33.8111 191.925 35.8601C187.278 37.9024 183.477 39.4901 183.438 39.4045C183.398 39.3188 187.133 37.5927 191.786 35.5438C196.432 33.5015 200.233 31.9138 200.273 31.9994Z" fill="#263238"/>
        <path d="M199.447 0.000274931C199.447 0.000274931 199.394 0.12545 199.268 0.349447C199.123 0.606384 198.931 0.922615 198.706 1.3179C198.211 2.16119 197.477 3.36682 196.552 4.84256C194.701 7.80064 192.037 11.826 189.023 16.2137C186.003 20.6014 183.313 24.6004 181.409 27.519C180.477 28.9552 179.717 30.1279 179.162 30.9843C178.911 31.3664 178.706 31.6761 178.54 31.9198C178.395 32.1307 178.316 32.2427 178.302 32.2361C178.289 32.2361 178.355 32.1109 178.481 31.8869C178.626 31.63 178.818 31.3137 179.043 30.9184C179.538 30.0752 180.272 28.8761 181.197 27.3938C183.048 24.4357 185.712 20.4103 188.732 16.0226C191.746 11.6349 194.443 7.64252 196.34 4.71739L198.587 1.25202C198.839 0.86991 199.043 0.560267 199.209 0.316506C199.354 0.105685 199.433 -0.00631321 199.447 0.000274931Z" fill="#263238"/>
        <path d="M245.146 77.5625H96.1383C92.9194 77.5625 90.3086 80.1648 90.3086 83.3732V129.444C90.3086 132.653 92.9194 135.255 96.1383 135.255H107.242C108.135 140.064 109.186 146.138 109.186 146.138L119.232 135.255H245.152C248.371 135.255 250.982 132.653 250.982 129.444V83.3732C250.982 80.1648 248.371 77.5625 245.152 77.5625H245.146Z" fill="#FF7400"/>
        <path d="M183.669 102.552C183.669 102.644 165.936 102.723 144.065 102.723C122.193 102.723 104.453 102.644 104.453 102.552C104.453 102.46 122.187 102.381 144.065 102.381C165.942 102.381 183.669 102.46 183.669 102.552Z" fill="#F5F5F5"/>
        <path d="M183.669 95.4691C183.669 95.5614 165.936 95.6404 144.065 95.6404C122.193 95.6404 104.453 95.5614 104.453 95.4691C104.453 95.3769 122.187 95.2979 144.065 95.2979C165.942 95.2979 183.669 95.3769 183.669 95.4691Z" fill="#F5F5F5"/>
        <path d="M183.669 109.555C183.669 109.647 165.936 109.726 144.065 109.726C122.193 109.726 104.453 109.647 104.453 109.555C104.453 109.463 122.187 109.384 144.065 109.384C165.942 109.384 183.669 109.463 183.669 109.555Z" fill="#F5F5F5"/>
        <path d="M156.339 116.815C156.339 116.907 144.719 116.986 130.396 116.986C116.073 116.986 104.453 116.907 104.453 116.815C104.453 116.723 116.066 116.644 130.396 116.644C144.726 116.644 156.339 116.723 156.339 116.815Z" fill="#F5F5F5"/>
        <path d="M177.82 160.389H27.5899C25.336 160.389 23.5117 162.207 23.5117 164.454V213.199C23.5117 215.446 25.336 217.264 27.5899 217.264H136.701L146.636 228.023C146.636 228.023 147.68 221.975 148.553 217.264H177.82C180.074 217.264 181.898 215.446 181.898 213.199V164.454C181.898 162.207 180.074 160.389 177.82 160.389Z" fill="#455A64"/>
        <path d="M171.931 185.53C171.931 185.622 154.198 185.701 132.326 185.701C110.455 185.701 92.7148 185.622 92.7148 185.53C92.7148 185.437 110.448 185.358 132.326 185.358C154.204 185.358 171.931 185.437 171.931 185.53Z" fill="#F5F5F5"/>
        <path d="M171.931 178.447C171.931 178.539 154.198 178.618 132.326 178.618C110.455 178.618 92.7148 178.539 92.7148 178.447C92.7148 178.354 110.448 178.275 132.326 178.275C154.204 178.275 171.931 178.354 171.931 178.447Z" fill="#F5F5F5"/>
        <path d="M171.931 192.533C171.931 192.625 154.198 192.704 132.326 192.704C110.455 192.704 92.7148 192.625 92.7148 192.533C92.7148 192.44 110.448 192.361 132.326 192.361C154.204 192.361 171.931 192.44 171.931 192.533Z" fill="#F5F5F5"/>
        <path d="M144.6 199.786C144.6 199.878 132.981 199.957 118.658 199.957C104.335 199.957 92.7148 199.878 92.7148 199.786C92.7148 199.693 104.328 199.614 118.658 199.614C132.987 199.614 144.6 199.693 144.6 199.786Z" fill="#F5F5F5"/>
        <path d="M207.689 58.0222C207.775 56.81 206.691 55.1432 205.693 54.1813C204.689 53.2194 204.067 51.9216 204.589 49.9847C204.993 48.4892 205.944 48.6012 206.321 47.7183C206.599 47.0661 206.48 46.3217 206.539 45.6167C206.738 43.1923 209.408 41.2949 211.774 41.901C212.442 39.5952 214.596 37.7966 216.989 37.5528C219.382 37.3025 221.86 38.6201 222.991 40.7415C224.359 40.0366 226.077 40.0563 227.419 40.8074C228.761 41.5584 229.693 42.9946 229.805 44.5297C231.134 43.5744 233.176 43.9433 234.234 45.1951C235.291 46.4468 235.351 48.3706 234.551 49.8002C233.751 51.2298 232.204 52.1588 230.579 52.4091C228.953 52.6595 227.28 52.2773 225.806 51.5461C224.339 50.8148 223.057 49.7541 221.893 48.6012" fill="#263238"/>
        <path d="M218.494 133.734C234.296 133.734 247.107 121.263 247.107 105.879C247.107 90.4954 234.296 78.0244 218.494 78.0244C202.691 78.0244 189.881 90.4954 189.881 105.879C189.881 121.263 202.691 133.734 218.494 133.734Z" fill="#E0E0E0"/>
        <path d="M247.228 105.898L244.062 97.8008L232.713 104.909L242.826 120.457C242.826 120.457 246.937 115.128 247.228 105.898Z" fill="#EB996E"/>
        <path d="M215.89 88.3732C219.969 88.3732 223.333 85.2241 223.538 81.1987C223.703 77.8454 223.848 74.6172 223.848 74.6172C223.848 74.6172 230.095 74.1692 230.67 68.0817C231.245 61.9943 231.324 47.9286 231.324 47.9286C224.437 44.3249 216.115 44.6872 209.572 48.8707L208.977 49.2528L208.256 80.7376C208.21 84.9342 211.64 88.3666 215.89 88.3732Z" fill="#FFBE9D"/>
        <path d="M219.475 57.2837C219.944 57.4352 220.202 57.9425 220.043 58.4037C219.885 58.8715 219.376 59.1218 218.906 58.9637C218.437 58.8122 218.179 58.3049 218.338 57.8437C218.496 57.376 219.005 57.1256 219.475 57.2837Z" fill="#263238"/>
        <path d="M221.186 57.6001C221.08 57.699 220.505 57.2048 219.652 57.1521C218.8 57.0863 218.159 57.4947 218.073 57.3827C218.026 57.33 218.139 57.1455 218.416 56.9611C218.694 56.7766 219.157 56.6119 219.685 56.6449C220.214 56.6844 220.65 56.915 220.895 57.139C221.146 57.363 221.225 57.5606 221.179 57.6067L221.186 57.6001Z" fill="#263238"/>
        <path d="M228.793 57.2837C229.262 57.4352 229.52 57.9425 229.362 58.4037C229.203 58.8715 228.694 59.1218 228.225 58.9637C227.755 58.8122 227.498 58.3049 227.656 57.8437C227.815 57.376 228.324 57.1256 228.793 57.2837Z" fill="#263238"/>
        <path d="M230.617 57.6197C230.512 57.7185 229.937 57.2244 229.084 57.1717C228.231 57.1058 227.59 57.5143 227.504 57.4023C227.458 57.3495 227.57 57.1651 227.848 56.9806C228.126 56.7961 228.588 56.6314 229.117 56.6644C229.646 56.7039 230.082 56.9345 230.327 57.1585C230.578 57.3825 230.657 57.5801 230.611 57.6263L230.617 57.6197Z" fill="#263238"/>
        <path d="M224.205 64.2013C224.205 64.1355 224.74 64.0696 225.6 64.0235C225.824 64.0235 226.003 63.9839 226.029 63.8654C226.082 63.7336 226.016 63.4964 225.924 63.2263C225.752 62.6531 225.573 62.0734 225.395 61.4673C224.668 58.9638 224.179 56.9083 224.311 56.8687C224.436 56.8292 225.137 58.832 225.864 61.3355C226.036 61.9482 226.201 62.528 226.36 63.1011C226.419 63.3646 226.552 63.6677 226.406 64.0235C226.327 64.2013 226.142 64.3199 225.996 64.3397C225.844 64.3726 225.719 64.3595 225.613 64.3595C224.747 64.3199 224.212 64.2672 224.212 64.1948L224.205 64.2013Z" fill="#263238"/>
        <path d="M223.862 74.5313C223.862 74.5313 219.942 74.5313 216.01 71.626C216.01 71.626 217.55 76.1652 223.75 76.1059L223.862 74.5248V74.5313Z" fill="#EB996E"/>
        <path d="M208.824 58.1865C208.824 57.6923 208.448 57.2707 207.952 57.2444C206.802 57.1916 204.905 57.6067 204.667 60.7427C204.33 65.2226 208.719 64.1883 208.732 64.0631C208.745 63.9643 208.798 59.9323 208.818 58.1865H208.824Z" fill="#FFBE9D"/>
        <path d="M207.773 62.2778C207.773 62.2778 207.688 62.3239 207.555 62.3766C207.423 62.4227 207.192 62.4491 206.967 62.3437C206.504 62.1328 206.154 61.3555 206.194 60.5451C206.214 60.1366 206.326 59.7545 206.498 59.4449C206.663 59.1287 206.894 58.9112 207.139 58.8915C207.383 58.852 207.542 59.0101 207.588 59.1418C207.641 59.2736 207.602 59.3592 207.628 59.3658C207.641 59.379 207.734 59.3 207.707 59.1089C207.694 59.0167 207.648 58.9112 207.549 58.8124C207.45 58.7202 207.291 58.6609 207.119 58.6675C206.769 58.6675 206.432 58.9574 206.24 59.3C206.035 59.6425 205.896 60.0708 205.87 60.5253C205.83 61.4279 206.233 62.3107 206.875 62.5479C207.185 62.6533 207.463 62.5808 207.608 62.482C207.76 62.3832 207.787 62.2844 207.773 62.2712V62.2778Z" fill="#EB996E"/>
        <path d="M217.158 55.215C217.383 55.5048 218.163 55.3665 219.181 55.327C220.192 55.2479 220.985 55.2874 221.17 54.9712C221.382 54.6484 220.397 53.8973 219.095 54.0093C217.786 54.062 216.907 54.9251 217.158 55.2215V55.215Z" fill="#263238"/>
        <path d="M227.061 54.9184C227.186 55.3071 227.927 55.4257 228.753 55.4191C229.579 55.3994 230.319 55.2676 230.432 54.8723C230.551 54.4902 229.797 53.9368 228.733 53.9566C227.669 53.9697 226.935 54.5363 227.061 54.9184Z" fill="#263238"/>
        <path d="M232.189 49.8524C232.189 49.8524 228.488 51.6378 223.663 49.2397C220.497 47.6651 216.815 46.8943 216.003 47.5334C215.19 48.1658 215.018 51.4138 211.885 52.3625C211.885 52.3625 212.103 58.5619 209.618 58.3379C207.139 58.1139 208.686 48.515 208.686 48.515L214.753 45.5635L221.37 44.3184L227.609 45.1946L232.434 46.5122L232.189 49.8524Z" fill="#263238"/>
        <path d="M190.676 85.7183C197.16 79.0708 208.568 79.8021 208.568 79.8021L218.026 86.5484L223.387 80.3226C223.387 80.3226 229.025 80.9419 234.306 83.6891L234.669 83.8011V83.8736C238.814 86.1201 244.69 91.5027 250.281 96.7271L243.586 102.92L236.176 109.475C236.176 109.475 236.203 109.521 236.19 109.607C236.11 110.002 237.221 119.028 238.358 125.88C231.609 132.303 223.003 135.05 213.453 133.377C210.419 132.85 208.951 132.112 206.149 131.374C201.998 130.287 198.991 126.703 198.621 122.441C198.118 116.696 197.728 109.581 197.728 109.581" fill="#455A64"/>
        <path d="M229.314 81.4888C229.314 81.4888 229.48 81.8577 229.724 82.5495C229.969 83.2412 230.299 84.2426 230.689 85.4878C231.469 87.9781 232.467 91.45 233.406 95.3239C234.344 99.1977 235.045 102.742 235.494 105.311C235.719 106.596 235.884 107.644 235.984 108.362C236.083 109.086 236.129 109.488 236.109 109.488C236.089 109.488 235.997 109.1 235.851 108.382C235.699 107.611 235.501 106.59 235.256 105.351C234.748 102.795 234.007 99.2636 233.075 95.3963C232.137 91.5291 231.185 88.0571 230.465 85.5471C230.114 84.3348 229.823 83.3334 229.605 82.5758C229.407 81.8775 229.301 81.4822 229.328 81.4756L229.314 81.4888Z" fill="#263238"/>
        <path d="M235.403 105.239C235.344 105.167 238.173 102.769 241.715 99.8895C245.265 97.0105 248.186 94.731 248.246 94.8034C248.305 94.8759 245.476 97.274 241.934 100.153C238.384 103.032 235.463 105.312 235.403 105.239Z" fill="#263238"/>
        <path opacity="0.2" d="M199.229 94.8435L193.426 113.863L195.587 114.983L197.715 109.601C197.715 109.601 199.903 98.3023 199.229 94.8369V94.8435Z" fill="black"/>
        <g opacity="0.2">
          <path opacity="0.2" d="M228.688 81.4954C231.815 83.1358 223.976 87.0953 220.439 86.9899L223.011 80.2305C223.011 80.2305 227.644 80.9486 228.688 81.502V81.4954Z" fill="black"/>
        </g>
        <path d="M207.637 80.2304V75.1904C207.637 75.1904 217.505 77.7203 217.868 83.6101C217.868 83.6101 219.124 77.101 224.207 76.7913L224.927 85.9423L219.012 91.0745H213.01L208.258 86.9898L207.637 80.6322" fill="#455A64"/>
        <path d="M220.081 111.847C220.081 111.847 220.055 111.458 220.061 110.766C220.074 110.068 220.088 109.06 220.094 107.821C220.101 105.331 220.021 101.899 219.711 98.117C219.394 94.3354 218.904 90.9293 218.481 88.4785C218.27 87.2992 218.098 86.3176 217.966 85.5797C217.84 84.8946 217.781 84.5124 217.801 84.5059C217.82 84.5059 217.926 84.8748 218.091 85.5468C218.263 86.2254 218.475 87.207 218.719 88.4324C219.202 90.8766 219.737 94.2893 220.055 98.084C220.372 101.879 220.411 105.331 220.339 107.821C220.306 109.066 220.253 110.074 220.2 110.773C220.147 111.471 220.107 111.853 220.081 111.853V111.847Z" fill="#263238"/>
        <path d="M217.412 81.9173C217.412 81.9173 217.187 81.8317 216.824 81.9701C216.447 82.0886 216.11 82.5564 215.833 83.2284C215.224 84.5394 214.478 86.4236 213.955 88.6702L213.903 88.8942L213.704 88.7756C213.116 88.4198 212.508 88.0377 211.9 87.6227C210.723 86.8255 209.639 86.0086 208.701 85.1983C208.225 84.7898 207.835 84.3023 207.61 83.7752C207.385 83.2482 207.3 82.7277 207.28 82.2533C207.24 81.3047 207.399 80.5602 207.498 80.0529C207.61 79.5456 207.689 79.2689 207.716 79.2755C207.742 79.2755 207.703 79.5654 207.63 80.0793C207.57 80.5931 207.452 81.3376 207.524 82.2468C207.564 82.6947 207.656 83.1889 207.875 83.6632C208.093 84.1376 208.45 84.579 208.912 84.9611C209.844 85.7385 210.928 86.5488 212.098 87.3394C212.7 87.7479 213.301 88.13 213.883 88.4857L213.632 88.5911C214.173 86.3182 214.973 84.4274 215.641 83.1296C215.806 82.8067 215.965 82.5103 216.163 82.2797C216.355 82.0491 216.593 81.9108 216.791 81.8515C217.207 81.7329 217.432 81.9042 217.412 81.9108V81.9173Z" fill="#263238"/>
        <path d="M224.206 76.7915C224.206 76.7915 224.259 77.0682 224.299 77.5755L224.458 79.7166C224.53 80.6192 224.643 81.6931 224.828 82.8724C224.9 83.4587 225.105 84.0912 224.947 84.8027C224.742 85.5076 224.213 86.0347 223.651 86.4563C222.858 87.0426 222.032 87.4775 221.477 88.1033L221.212 88.3998L221.186 88.0045C221.047 85.9227 220.353 84.2097 219.725 83.0963C219.097 81.9764 218.582 81.3966 218.621 81.3637C218.635 81.3505 218.773 81.4823 219.011 81.7524C219.243 82.0225 219.566 82.4309 219.904 82.9909C220.591 84.0977 221.351 85.837 221.51 87.9782L221.219 87.8793C221.82 87.1942 222.68 86.7396 223.433 86.1796C223.968 85.7777 224.444 85.2836 224.61 84.717C224.748 84.1373 224.577 83.5114 224.504 82.9119C224.332 81.7194 224.246 80.6324 224.206 79.7232C224.16 78.8141 224.154 78.0762 224.16 77.5689C224.16 77.0616 224.18 76.7783 224.2 76.7783L224.206 76.7915Z" fill="#263238"/>
        <path d="M255.978 78.0566C255.575 78.531 241.047 97.8935 241.047 97.8935L243.096 99.0465L258.007 79.9277L255.985 78.0566H255.978Z" fill="#263238"/>
        <path d="M197.596 93.3674C197.596 93.3674 212.884 115.299 213.803 115.734C215.621 116.584 236.533 98.5654 238.529 96.8459C238.807 96.477 239.369 95.7061 240.486 94.1711C240.486 94.1711 242.138 86.6474 243.897 87.2206C245.655 87.8004 242.932 91.0483 244.082 93.9076C245.232 96.7668 250.48 89.9481 250.691 89.3354L252.046 87.5698C252.046 87.5698 253.467 91.582 253.368 93.2619C253.057 98.6313 247.961 105.015 247.961 105.015C247.961 105.015 225.072 133.278 209.394 134.155C204.88 134.405 196.856 121.637 191.489 112.058C188.25 106.274 189.863 98.987 195.236 95.0869L197.596 93.3674Z" fill="#FFBE9D"/>
        <path d="M250.797 94.2112C250.744 94.1783 251.233 93.3153 251.775 92.2216C252.317 91.128 252.707 90.2188 252.767 90.2386C252.819 90.2584 252.509 91.2071 251.96 92.3139C251.412 93.4207 250.843 94.2376 250.797 94.2112Z" fill="#EB996E"/>
        <path d="M195.011 82.6611C195.011 82.6611 178.599 88.4785 190.801 115.977L209.744 104.58L207.047 98.1499L195.011 82.6611Z" fill="#455A64"/>
        <path d="M193.749 83.136C193.749 83.136 194.462 83.0174 195.725 83.3007C196.346 83.4588 197.093 83.7355 197.88 84.2032C198.666 84.671 199.479 85.3298 200.272 86.1599C201.865 87.8135 203.352 90.126 204.727 92.7942C206.115 95.469 207.411 98.5127 208.739 101.715C209.136 102.67 209.532 103.618 209.903 104.514L209.962 104.659L209.83 104.732C204.35 107.71 199.578 110.497 196.201 112.585C194.515 113.626 193.167 114.482 192.248 115.075C191.805 115.359 191.455 115.583 191.184 115.754C190.94 115.905 190.807 115.984 190.801 115.971C190.801 115.965 190.913 115.866 191.144 115.701C191.376 115.53 191.72 115.279 192.176 114.97C193.074 114.344 194.403 113.455 196.075 112.387C199.42 110.246 204.179 107.42 209.658 104.429L209.585 104.646C209.215 103.75 208.819 102.808 208.422 101.846C207.093 98.6444 205.811 95.6073 204.443 92.9391C203.088 90.2709 201.634 87.9716 200.094 86.3246C199.327 85.5011 198.541 84.8423 197.781 84.3679C197.02 83.8936 196.3 83.6037 195.698 83.4258C194.476 83.1096 193.755 83.1821 193.755 83.1294L193.749 83.136Z" fill="#263238"/>
        <path d="M229.05 42.3623C229.05 42.3623 229.09 42.4611 229.13 42.6522C229.163 42.8432 229.202 43.1331 229.176 43.5086C229.136 44.2399 228.872 45.3731 227.927 46.3613C227.008 47.3495 225.27 47.9754 223.512 47.4747C222.652 47.2244 221.78 46.7039 221.251 45.8408C220.993 45.4126 220.841 44.8856 220.914 44.3519C220.987 43.8315 221.251 43.3044 221.727 42.9948C222.203 42.6719 222.831 42.6785 223.313 42.8959C223.802 43.1002 224.206 43.5021 224.397 43.983C224.787 44.9514 224.496 45.9924 223.961 46.7237C222.824 48.2257 220.769 48.3443 219.625 47.6658C219.024 47.3891 218.495 47.1321 217.992 47.0399C217.497 46.9411 217.054 47.0267 216.73 47.1716C216.082 47.4813 215.818 47.8239 215.791 47.7909C215.785 47.7843 215.851 47.7053 215.983 47.5669C216.049 47.4945 216.135 47.4088 216.254 47.3232C216.366 47.2375 216.499 47.1321 216.677 47.0531C217.008 46.8752 217.49 46.7632 218.032 46.8554C218.574 46.9345 219.129 47.1914 219.731 47.4483C220.035 47.5669 220.345 47.7053 220.683 47.7646C221.013 47.8239 221.383 47.8173 221.747 47.7448C222.474 47.6131 223.221 47.2178 223.71 46.5458C224.186 45.8935 224.424 44.9383 224.087 44.1213C223.789 43.3242 222.679 42.7905 221.912 43.2912C221.542 43.535 221.31 43.9698 221.251 44.4112C221.185 44.8526 221.31 45.3006 221.542 45.6827C222.005 46.4469 222.804 46.9477 223.604 47.1848C225.237 47.6723 226.876 47.1124 227.761 46.2032C228.68 45.294 228.978 44.2202 229.05 43.5086C229.13 42.7839 229.017 42.3821 229.05 42.3755V42.3623Z" fill="#455A64"/>
        <path d="M225.475 67.2061C225.481 67.2456 225.27 67.3313 224.906 67.351C224.543 67.3708 224.034 67.2851 223.532 67.0348C223.036 66.7844 222.666 66.4221 222.461 66.119C222.256 65.816 222.203 65.5986 222.243 65.5788C222.329 65.5261 222.771 66.2771 223.69 66.7251C224.596 67.1929 225.462 67.1007 225.475 67.1995V67.2061Z" fill="#263238"/>
        <path d="M230.142 44.582C230.142 44.582 230.42 45.0037 230.459 45.7943C230.506 46.5651 230.281 47.718 229.481 48.6469C228.668 49.5758 227.564 49.958 226.784 50.0172C225.998 50.0897 225.529 49.9053 225.535 49.8723C225.542 49.813 226.018 49.8987 226.751 49.7735C227.472 49.6549 228.477 49.2596 229.217 48.4163C229.95 47.5665 230.202 46.5189 230.215 45.7943C230.235 45.0498 230.083 44.5952 230.142 44.582Z" fill="#455A64"/>
        <path d="M207.788 99.7053C207.828 99.7909 203.472 102.024 198.052 104.693C192.632 107.361 188.211 109.456 188.164 109.377C188.125 109.291 192.48 107.058 197.9 104.389C203.32 101.721 207.742 99.6262 207.788 99.7053Z" fill="#E0E0E0"/>
        <path d="M203.814 92.2282C203.861 92.3138 199.981 94.4748 195.149 97.0573C190.318 99.6399 186.365 101.669 186.319 101.583C186.273 101.498 190.152 99.3368 194.984 96.7543C199.816 94.1717 203.768 92.1426 203.808 92.2282H203.814Z" fill="#E0E0E0"/>
        <path d="M199.975 86.1866C199.994 86.2327 199.248 86.6544 198.031 87.333C196.729 88.0577 195.097 88.9668 193.286 89.9748C191.488 90.996 189.855 91.9249 188.566 92.6562C187.357 93.3413 186.603 93.763 186.577 93.7169C186.55 93.6773 187.258 93.1898 188.441 92.4454C189.617 91.7009 191.27 90.7127 193.12 89.6717C194.971 88.6308 196.676 87.7348 197.919 87.1155C199.168 86.4963 199.955 86.1471 199.975 86.1866Z" fill="#E0E0E0"/>
        <path d="M214.491 84.7568C214.484 84.7964 213.975 84.7305 213.215 84.4143C212.455 84.1046 211.47 83.5117 210.545 82.6816C209.613 81.8515 208.892 80.9884 208.39 80.356C207.888 79.7169 207.61 79.3084 207.643 79.2755C207.683 79.2426 208.027 79.6049 208.575 80.1978C209.124 80.7908 209.864 81.6143 210.776 82.4246C211.682 83.235 212.614 83.8411 213.321 84.1969C214.028 84.5592 214.511 84.7041 214.497 84.7568H214.491Z" fill="#E0E0E0"/>
        <path d="M224.134 81.1934C224.22 81.2197 223.816 82.287 222.759 83.1698C221.708 84.0592 220.584 84.27 220.571 84.1844C220.538 84.0856 221.556 83.743 222.534 82.9129C223.526 82.0893 224.035 81.1538 224.127 81.1999L224.134 81.1934Z" fill="#E0E0E0"/>
        <path d="M241.318 91.1075C241.318 91.1075 241.193 91.1405 240.948 91.1734C240.664 91.2064 240.314 91.2525 239.884 91.3052C238.959 91.404 237.617 91.5358 235.965 91.661C232.647 91.9245 228.06 92.1814 222.99 92.3659C217.92 92.5438 213.327 92.6096 209.995 92.5833C208.33 92.5701 206.988 92.5372 206.056 92.5042C205.62 92.4845 205.27 92.4647 204.985 92.4515C204.741 92.4383 204.615 92.4186 204.615 92.412C204.615 92.3988 204.747 92.3922 204.992 92.3856H206.063C207.041 92.3659 208.369 92.3593 209.995 92.3461C213.32 92.3132 217.907 92.2078 222.977 92.0299C228.04 91.8454 232.627 91.628 235.945 91.4238C237.564 91.3184 238.899 91.2393 239.871 91.1734C240.301 91.1471 240.657 91.1273 240.942 91.1075C241.186 91.0944 241.318 91.0944 241.318 91.1075Z" fill="#E0E0E0"/>
        <path d="M236.103 98.9151C236.103 98.9151 235.719 99.0008 235.019 99.0864C234.318 99.1721 233.3 99.2841 232.038 99.3961C229.52 99.6267 226.036 99.8638 222.183 100.022C218.33 100.173 214.833 100.22 212.308 100.193C211.046 100.18 210.021 100.154 209.314 100.127C208.607 100.094 208.217 100.068 208.217 100.042C208.217 100.015 208.607 100.002 209.314 99.989C210.081 99.9758 211.085 99.9627 212.308 99.9495C214.833 99.9165 218.323 99.8375 222.17 99.6794C226.017 99.5213 229.5 99.3236 232.025 99.1523C233.241 99.0733 234.245 99.0008 235.012 98.9547C235.719 98.9086 236.109 98.8954 236.109 98.9151H236.103Z" fill="#E0E0E0"/>
        <path d="M224.253 108.554C224.253 108.6 223.42 108.725 222.065 108.863C220.704 109.002 218.827 109.153 216.745 109.258C214.663 109.364 212.779 109.397 211.411 109.397C210.043 109.397 209.203 109.351 209.203 109.305C209.203 109.258 210.049 109.212 211.411 109.153C212.871 109.087 214.702 109.008 216.731 108.916C218.761 108.804 220.592 108.705 222.052 108.626C223.414 108.547 224.26 108.514 224.26 108.56L224.253 108.554Z" fill="#E0E0E0"/>
        <path d="M209.11 85.6859C209.11 85.7847 207.081 85.8045 204.582 85.9099C202.084 86.0087 200.061 86.1536 200.055 86.0548C200.042 85.9626 202.057 85.6661 204.569 85.5673C207.074 85.4619 209.11 85.5937 209.11 85.6859Z" fill="#E0E0E0"/>
        <path d="M236.989 85.3293C236.989 85.4216 234.359 85.494 231.114 85.4809C227.868 85.4677 225.238 85.382 225.244 85.2898C225.244 85.1976 227.875 85.1251 231.12 85.1383C234.365 85.1514 236.989 85.2371 236.989 85.3293Z" fill="#E0E0E0"/>
        <path d="M238.007 125.419C238.007 125.419 237.802 125.564 237.406 125.762C237.009 125.966 236.421 126.236 235.687 126.539C234.213 127.158 232.125 127.883 229.752 128.43C227.372 128.977 225.178 129.234 223.579 129.319C222.779 129.365 222.138 129.379 221.688 129.365C221.245 129.359 220.994 129.339 220.994 129.319C220.994 129.267 221.979 129.227 223.559 129.076C225.138 128.924 227.313 128.634 229.673 128.094C232.032 127.547 234.114 126.869 235.595 126.309C237.082 125.755 237.981 125.36 238.007 125.406V125.419Z" fill="#E0E0E0"/>
        <path d="M59.4588 215.097C75.2613 215.097 88.0718 202.915 88.0718 187.888C88.0718 172.861 75.2613 160.679 59.4588 160.679C43.6562 160.679 30.8457 172.861 30.8457 187.888C30.8457 202.915 43.6562 215.097 59.4588 215.097Z" fill="#E0E0E0"/>
        <path d="M53.6312 122.289C49.0903 122.526 47.3124 127.243 46.7638 128.297C46.2218 129.352 46.1358 130.57 46.0367 131.75C45.9376 132.929 45.7988 134.154 45.1642 135.156C44.1662 136.743 42.0643 137.567 41.4959 139.352C40.8481 141.375 42.5072 143.536 42.0643 145.611C41.8264 146.724 41.0134 147.64 40.7226 148.74C40.4185 149.893 40.7358 151.132 41.3373 152.16C42.831 154.709 45.9177 155.994 48.8788 156.099C51.8399 156.205 54.735 155.322 57.5705 154.446" fill="#263238"/>
        <path d="M73.6065 128.153C73.1637 129.109 73.5801 130.275 74.2807 131.072C74.9747 131.869 75.9133 132.403 76.7395 133.068C77.5657 133.727 78.3192 134.603 78.4183 135.651C78.5043 136.612 78.0218 137.574 78.1539 138.53C78.491 140.994 82.437 141.738 82.6485 144.215C82.7873 145.869 81.0886 147.384 81.5579 148.972C81.7364 149.578 82.2123 150.052 82.5427 150.599C83.6598 152.45 82.9063 155.007 81.2803 156.443C79.6609 157.879 77.4004 158.386 75.2325 158.485C73.4082 158.564 71.3394 158.268 70.2621 156.799C69.2772 155.455 69.4821 153.616 69.6804 151.963C70.5991 144.242 70.4405 136.441 70.2819 128.667" fill="#263238"/>
        <path d="M54.827 163.88C54.8601 159.822 54.827 155.553 54.827 155.553C54.827 155.553 49.5922 154.624 48.5876 147.792C47.6292 141.276 49.2551 127.27 49.2551 127.27C56.8364 124.292 65.3694 125.445 70.6769 131.526L69.8507 164.466C69.7582 168.288 66.3807 171.252 62.2761 171.121C58.1253 170.989 54.794 167.747 54.827 163.88Z" fill="#FFBE9D"/>
        <path d="M50.6746 138.028C50.6548 138.489 51.0249 138.891 51.5074 138.917C51.9899 138.95 52.3997 138.601 52.4195 138.14C52.4394 137.678 52.0692 137.277 51.5867 137.25C51.1042 137.217 50.6944 137.566 50.6746 138.028Z" fill="#263238"/>
        <path d="M49.2607 137.356C49.3664 137.474 50.0406 136.987 50.9792 137C52.7572 137.257 52.7241 137.527 52.7109 137.375C52.7638 137.323 52.6514 137.119 52.354 136.895C52.0566 136.671 51.5542 136.453 50.966 136.453C50.3777 136.453 49.882 136.657 49.5912 136.875C49.3003 137.092 49.1946 137.296 49.2475 137.349L49.2607 137.356Z" fill="#263238"/>
        <path d="M60.3328 138.305C60.313 138.766 60.6831 139.168 61.1656 139.194C61.6481 139.227 62.0579 138.878 62.0777 138.417C62.0976 137.956 61.7274 137.554 61.2449 137.528C60.7624 137.495 60.3526 137.844 60.3328 138.305Z" fill="#263238"/>
        <path d="M59.11 137.679C59.2158 137.798 59.89 137.31 60.8285 137.323C61.7737 137.323 62.4611 137.817 62.5602 137.699C62.6131 137.646 62.5008 137.442 62.2033 137.218C61.9059 136.994 61.4036 136.776 60.8153 136.776C60.227 136.776 59.7313 136.981 59.4405 137.198C59.1497 137.415 59.0505 137.62 59.0968 137.672L59.11 137.679Z" fill="#263238"/>
        <path d="M55.5996 144.426C55.5996 144.373 55.018 144.268 54.0728 144.129C53.8349 144.103 53.6035 144.05 53.5639 143.885C53.511 143.714 53.6101 143.451 53.7357 143.174C53.9671 142.594 54.2116 141.988 54.4694 141.356C55.4873 138.76 56.2275 136.632 56.1284 136.592C56.0226 136.553 55.1105 138.622 54.0926 141.217C53.8481 141.856 53.6101 142.469 53.3854 143.049C53.2929 143.319 53.1276 143.628 53.2466 143.991C53.3061 144.169 53.4845 144.3 53.6366 144.347C53.7886 144.393 53.9274 144.399 54.0464 144.406C55.0048 144.465 55.593 144.478 55.5996 144.426Z" fill="#263238"/>
        <path d="M54.8203 155.553C54.8203 155.553 59.4206 155.902 64.0077 153.221C64.0077 153.221 61.734 157.879 54.893 157.16L54.8203 155.553Z" fill="#EB996E"/>
        <path d="M55.9768 146.698C56.3205 146.256 56.922 146.012 57.5103 146.085C57.9201 146.131 58.3298 146.335 58.5744 146.671C58.819 147.007 58.8652 147.482 58.6339 147.791C58.3761 148.147 57.8407 148.219 57.3913 148.088C56.9418 147.956 56.5585 147.653 56.1949 147.356C56.0958 147.271 55.99 147.185 55.9306 147.073C55.8711 146.961 55.8578 146.816 55.9306 146.724" fill="#EB996E"/>
        <path d="M58.3702 145.11C58.2182 145.097 58.205 146.118 57.3193 146.836C56.4336 147.554 55.343 147.429 55.3364 147.567C55.3232 147.633 55.5744 147.772 56.0371 147.785C56.4865 147.805 57.1541 147.666 57.6895 147.231C58.2314 146.79 58.4694 146.204 58.509 145.782C58.5553 145.354 58.443 145.104 58.3702 145.104V145.11Z" fill="#263238"/>
        <path d="M67.7025 126.664C65.1974 130.221 58.5746 133.878 53.8289 134.727C54.3841 134.003 57.2989 132.211 57.8541 131.486C55.706 133.087 52.6259 135.228 49.7706 135.373C49.4335 135.393 49.07 135.386 48.7923 135.222C48.4156 134.991 48.3363 134.543 48.2966 134.148C48.1116 132.481 48.2305 129.622 48.4949 127.968C48.7593 126.314 49.526 124.667 50.9603 123.561C52.7912 122.151 55.402 121.808 57.8674 121.749C59.9362 121.696 62.0645 121.821 63.9548 122.566C65.8452 123.31 67.4579 124.779 67.7355 126.545" fill="#263238"/>
        <path d="M74.8803 142.6C75.224 140.834 75.5016 139.161 75.5611 138.041C75.766 134.523 74.7415 121.538 62.5732 122.585L62.0841 126.281C62.0841 126.281 60.2003 138.924 68.0724 139.023C68.0724 139.023 68.5086 142.791 72.0712 146.454C72.0712 146.454 74.1334 146.395 74.8737 142.607" fill="#263238"/>
        <path d="M68.0785 139.016C68.8122 138.885 69.5855 138.911 70.2795 139.181C70.9735 139.451 71.5816 139.972 71.8724 140.657C72.1633 141.342 72.1302 142.139 71.8724 142.838C71.6081 143.536 71.1454 144.142 70.6034 144.656C70.22 145.025 69.7772 145.367 69.2683 145.519C68.7527 145.671 68.1512 145.618 67.748 145.269" fill="#FFBE9D"/>
        <path d="M68.4298 143.443C68.3439 143.47 68.6612 143.911 69.3287 143.74C69.6526 143.661 69.9699 143.377 70.1615 143.028C70.3598 142.672 70.4854 142.284 70.5383 141.823C70.5912 141.368 70.492 140.88 70.2408 140.538C70.0029 140.182 69.6129 139.971 69.2891 139.958C68.6017 139.932 68.3835 140.406 68.4629 140.419C68.5157 140.479 68.7867 140.235 69.2362 140.347C69.6658 140.432 70.0822 141.025 69.9831 141.763C69.8905 142.514 69.5931 143.193 69.1899 143.364C68.7735 143.568 68.4695 143.377 68.4232 143.437L68.4298 143.443Z" fill="#EB996E"/>
        <path d="M69.4005 125.327C70.3325 125.854 71.4759 126.005 72.507 125.735C73.5448 125.465 74.4635 124.773 75.0121 123.858C75.369 123.265 75.5673 122.547 75.3955 121.881C75.1575 120.965 74.2652 120.353 73.3531 120.116C72.0245 119.773 70.5176 120.102 69.5129 121.031C68.5082 121.967 68.0786 123.489 68.5214 124.787C68.9643 126.078 70.306 127.033 71.6808 126.961" fill="#263238"/>
        <path d="M70.5111 144.992C69.837 145.48 69.1496 145.974 68.6208 146.619C68.092 147.265 67.7285 148.082 67.8078 148.912C67.8475 149.373 68.0259 149.834 67.9598 150.296C67.8673 150.928 67.3451 151.396 67.0014 151.943C66.1091 153.339 66.4925 155.303 67.6227 156.521C68.7464 157.74 70.4583 158.287 72.1173 158.346C73.0823 158.379 74.0936 158.241 74.8801 157.694C76.017 156.897 76.4532 155.415 76.4862 154.025" fill="#263238"/>
        <path d="M71.3371 128.047C71.3701 128.066 71.1322 128.56 70.6166 129.292C70.1077 130.016 69.3079 130.965 68.2702 131.848C67.2325 132.731 66.1816 133.383 65.3818 133.772C64.5821 134.167 64.0533 134.332 64.0401 134.292C63.9872 134.193 66.023 133.337 68.0455 131.584C70.0879 129.852 71.2379 127.981 71.3305 128.047H71.3371Z" fill="#455A64"/>
        <path d="M72.6006 127.981C72.6403 127.981 72.6138 128.633 72.3759 129.641C72.1379 130.656 71.6488 132.026 70.8292 133.39C70.003 134.753 69.0182 135.827 68.2317 136.506C67.4451 137.191 66.8833 137.514 66.8635 137.481C66.7841 137.395 68.9323 135.906 70.5384 133.212C72.1776 130.537 72.4882 127.948 72.6006 127.981Z" fill="#455A64"/>
        <path d="M46.1831 133.476C46.2822 133.469 46.4144 134.609 46.811 135.959C47.1944 137.31 47.6967 138.344 47.6108 138.39C47.5381 138.436 46.8837 137.448 46.4805 136.052C46.0707 134.661 46.0972 133.476 46.1831 133.476Z" fill="#455A64"/>
        <path d="M75.3319 156.778C75.3518 156.738 75.636 157.087 76.3366 157.101C76.6737 157.101 77.1033 157.008 77.4999 156.732C77.8965 156.468 78.2204 155.987 78.379 155.401C78.5376 154.814 78.5442 154.123 78.3922 153.418C78.2468 152.7 77.9692 151.962 77.7246 151.158C77.4867 150.354 77.2752 149.452 77.4338 148.503C77.5858 147.535 78.2137 146.757 78.5178 145.888C78.8284 145.024 78.7029 144.063 78.3195 143.285C77.9295 142.501 77.2686 141.928 76.5085 141.632C76.1317 141.473 75.7351 141.335 75.3716 141.137C75.0081 140.946 74.671 140.676 74.4661 140.327C74.0431 139.635 74.0563 138.878 74.0563 138.252C74.0695 137.613 74.1026 137.053 73.9902 136.578C73.8844 136.098 73.706 135.709 73.4945 135.445C73.0516 134.905 72.5823 134.938 72.6022 134.898C72.6022 134.898 72.7013 134.879 72.893 134.918C73.0781 134.958 73.3491 135.083 73.6002 135.36C73.8448 135.63 74.0563 136.038 74.1819 136.539C74.3207 137.04 74.3008 137.639 74.3075 138.258C74.3207 138.878 74.3339 139.589 74.7106 140.176C75.0808 140.782 75.8475 141.032 76.634 141.342C77.4404 141.638 78.2071 142.284 78.6235 143.134C79.0466 143.977 79.192 145.031 78.8417 146C78.498 146.942 77.8965 147.699 77.7643 148.556C77.6189 149.412 77.8039 150.275 78.0287 151.066C78.26 151.856 78.531 152.614 78.6698 153.358C78.8152 154.103 78.802 154.834 78.6103 155.467C78.4252 156.093 78.0551 156.606 77.599 156.89C77.1496 157.173 76.6869 157.252 76.3234 157.232C75.9533 157.213 75.6889 157.081 75.5302 156.975C75.3716 156.87 75.3187 156.778 75.3187 156.778H75.3319Z" fill="#455A64"/>
        <path d="M70.5586 156.317C70.5586 156.317 70.6445 156.528 70.9684 156.673C71.2592 156.811 71.9003 156.818 72.2507 156.324C72.6076 155.823 72.5547 154.986 72.1449 154.261C71.7682 153.517 71.0213 152.786 70.8296 151.699C70.7304 151.172 70.856 150.572 71.1997 150.177C71.5236 149.781 71.9003 149.511 72.1515 149.189C72.6472 148.53 72.6935 147.706 72.6142 147.087C72.5283 146.448 72.3432 145.941 72.2374 145.591C72.1185 145.242 72.059 145.045 72.0854 145.031C72.1052 145.025 72.2044 145.203 72.363 145.539C72.515 145.875 72.7332 146.375 72.8587 147.047C72.9711 147.7 72.9711 148.602 72.4093 149.38C72.1251 149.755 71.7417 150.038 71.4707 150.381C71.1865 150.717 71.094 151.172 71.1799 151.639C71.3385 152.588 72.0391 153.306 72.4357 154.11C72.8653 154.9 72.9182 155.869 72.4556 156.462C72.2308 156.765 71.8937 156.89 71.6161 156.916C71.3319 156.943 71.0874 156.883 70.9155 156.785C70.5586 156.567 70.5586 156.297 70.5718 156.31L70.5586 156.317Z" fill="#455A64"/>
        <path d="M44.6555 154.598C44.6555 154.598 44.444 154.427 44.1201 154.065C43.8029 153.696 43.3204 153.155 42.9701 152.332C42.7916 151.923 42.6792 151.429 42.6925 150.896C42.7057 150.369 42.8643 149.769 43.2741 149.301C43.6839 148.827 44.2788 148.583 44.6357 148.142C45.0058 147.7 45.0521 147.061 44.9067 146.449C44.7613 145.823 44.4771 145.263 44.1796 144.709C43.8954 144.149 43.631 143.583 43.5385 142.983C43.4327 142.39 43.5649 141.764 43.8822 141.323C44.1862 140.875 44.5696 140.565 44.8869 140.276C45.5544 139.729 45.9312 139.136 46.0171 138.668C46.1096 138.207 45.951 137.95 45.9907 137.943C45.9973 137.943 46.0435 137.996 46.0898 138.115C46.1361 138.233 46.1823 138.437 46.1493 138.688C46.103 139.195 45.7131 139.874 45.0521 140.453C44.7414 140.756 44.3779 141.066 44.1201 141.475C43.8558 141.876 43.7566 142.397 43.8558 142.931C43.9483 143.471 44.1995 144.011 44.4837 144.558C44.7745 145.098 45.0918 145.704 45.2438 146.376C45.4024 147.035 45.3561 147.819 44.8935 148.359C44.444 148.886 43.8558 149.117 43.5054 149.519C43.1485 149.914 42.9899 150.434 42.9701 150.915C42.9436 151.403 43.0362 151.858 43.1882 152.253C43.4922 153.043 43.9351 153.603 44.2193 153.992C44.5101 154.381 44.6753 154.598 44.6555 154.611V154.598Z" fill="#455A64"/>
        <path d="M72.2171 126.189C72.1246 126.235 71.6619 125.227 70.6044 124.443C69.5601 123.639 68.4629 123.475 68.4827 123.369C68.4695 123.284 69.679 123.303 70.8159 124.167C71.9593 125.016 72.3097 126.176 72.2171 126.182V126.189Z" fill="#FF7400"/>
        <path d="M73.1691 119.878C73.2021 119.858 73.4269 120.115 73.6053 120.622C73.7838 121.13 73.8895 121.901 73.6648 122.711C73.4401 123.521 72.951 124.134 72.5412 124.477C72.1248 124.826 71.8009 124.931 71.7811 124.898C71.7546 124.852 72.0256 124.674 72.3693 124.305C72.7196 123.943 73.1294 123.357 73.3343 122.625C73.5392 121.894 73.4864 121.182 73.374 120.695C73.2682 120.201 73.1294 119.904 73.1757 119.885L73.1691 119.878Z" fill="#455A64"/>
        <path opacity="0.3" d="M39.6055 200.925C39.6055 200.925 56.0238 216.467 65.7862 213.654C75.5486 210.841 78.437 203.541 78.437 203.541L78.6749 200.925L77.6967 200.286L40.1144 197.256L39.6055 200.925Z" fill="black"/>
        <path d="M29.8887 186.364L33.0547 178.268L44.4034 185.376L34.2907 200.924C34.2907 200.924 30.1795 195.594 29.8887 186.364Z" fill="#EB996E"/>
        <path d="M42.6986 163.998C47.9797 161.251 54.8207 160.453 54.8207 160.453C56.8036 164.413 63.0629 164.914 66.8502 162.212L70.0427 160.058C70.0427 160.058 70.5384 160.038 71.3778 160.111C78.8467 160.77 84.9672 166.33 86.4874 173.656C87.0426 176.324 87.4524 179.381 87.2872 182.214C86.8707 189.408 79.3953 190.061 79.3953 190.061C79.3953 190.061 79.0119 197.176 78.503 202.921C78.1262 207.216 75.8922 210.424 71.9661 212.216C69.038 213.554 67.4252 214.173 63.6313 214.779C54.054 216.308 44.8534 212.684 38.7924 206.208C39.9292 199.35 41.0132 190.476 40.9339 190.08C40.9207 189.995 40.9471 189.949 40.9471 189.949L33.5377 183.393L29.334 179.875C29.334 179.875 38.5544 166.238 42.6986 163.991" fill="#FF7400"/>
        <path d="M51.8851 168.438C51.8586 168.504 51.2506 167.931 50.0542 167.931C49.4726 167.931 48.7521 168.102 48.1639 168.583C47.873 168.821 47.6219 169.15 47.3244 169.486C47.0336 169.809 46.6569 170.184 46.1281 170.277C45.5927 170.375 45.1036 170.145 44.7335 169.901C44.3501 169.657 44.0262 169.394 43.6958 169.203C43.0414 168.801 42.3011 168.84 41.7591 169.031C41.2039 169.222 40.8404 169.552 40.6355 169.809C40.4306 170.066 40.3513 170.237 40.3248 170.23C40.3116 170.23 40.3447 170.033 40.5297 169.73C40.7148 169.44 41.085 169.051 41.6798 168.807C42.2549 168.564 43.1075 168.491 43.861 168.926C44.2311 169.13 44.5616 169.394 44.9251 169.618C45.2821 169.842 45.6786 170.013 46.0818 169.941C46.485 169.868 46.8089 169.572 47.0931 169.262C47.3839 168.946 47.6483 168.603 47.9854 168.34C48.6596 167.813 49.4527 167.661 50.0806 167.687C50.7152 167.714 51.1977 167.911 51.4951 168.089C51.7925 168.274 51.9181 168.425 51.9049 168.432L51.8851 168.438Z" fill="#FAFAFA"/>
        <path d="M59.4127 178.267C59.3664 178.32 58.9765 177.589 57.8396 177.207C57.291 177.022 56.5574 176.956 55.8369 177.227C55.48 177.358 55.1363 177.589 54.7529 177.813C54.3762 178.03 53.9003 178.261 53.3649 178.182C52.8295 178.109 52.433 177.727 52.162 177.385C51.8777 177.035 51.653 176.68 51.4019 176.39C50.9127 175.803 50.1923 175.599 49.6173 175.612C49.029 175.619 48.5795 175.817 48.3019 175.988C48.0243 176.166 47.8921 176.304 47.8723 176.284C47.8591 176.278 47.9516 176.1 48.2226 175.876C48.4936 175.659 48.9695 175.408 49.604 175.369C50.2253 175.323 51.0582 175.52 51.6332 176.179C51.9174 176.489 52.1487 176.844 52.4197 177.174C52.6841 177.497 53.008 177.793 53.4112 177.846C53.8144 177.905 54.2176 177.727 54.5877 177.523C54.9644 177.319 55.3214 177.075 55.7312 176.937C56.5441 176.653 57.3373 176.759 57.9189 176.989C58.5072 177.22 58.9104 177.556 59.1285 177.819C59.3532 178.09 59.4259 178.267 59.4061 178.281L59.4127 178.267Z" fill="#FAFAFA"/>
        <path d="M76.1431 163.867C76.1298 163.94 75.416 163.505 74.2461 163.762C73.6777 163.887 73.0101 164.203 72.5408 164.796C72.3029 165.086 72.131 165.468 71.9129 165.857C71.7014 166.232 71.4106 166.68 70.9082 166.884C70.4059 167.095 69.8771 166.97 69.4673 166.812C69.0443 166.654 68.6676 166.463 68.304 166.351C67.5836 166.1 66.8631 166.291 66.374 166.595C65.8783 166.898 65.5875 167.299 65.4355 167.589C65.2901 167.886 65.2504 168.07 65.2173 168.064C65.2041 168.064 65.1909 167.866 65.3099 167.537C65.4289 167.214 65.7065 166.753 66.2352 166.39C66.7442 166.035 67.5638 165.778 68.39 166.048C68.7932 166.166 69.1765 166.357 69.5731 166.496C69.9697 166.641 70.3927 166.726 70.7694 166.562C71.1462 166.41 71.404 166.048 71.6088 165.685C71.827 165.316 72.012 164.928 72.283 164.598C72.8316 163.94 73.5719 163.623 74.1866 163.524C74.8079 163.419 75.3301 163.505 75.6539 163.617C75.9844 163.735 76.1364 163.854 76.1298 163.867H76.1431Z" fill="#FAFAFA"/>
        <path d="M68.4225 174.678C68.3763 174.724 68.0921 174.151 67.2328 173.828C66.8164 173.67 66.2612 173.597 65.7126 173.782C65.4416 173.868 65.1706 174.039 64.86 174.197C64.5559 174.349 64.1792 174.52 63.7495 174.441C63.3199 174.362 63.0291 174.045 62.8242 173.769C62.6193 173.486 62.4607 173.202 62.2756 172.978C61.9253 172.517 61.3965 172.339 60.9537 172.319C60.0415 172.293 59.6185 172.807 59.5855 172.748C59.5723 172.734 59.645 172.603 59.8631 172.431C60.0812 172.267 60.458 172.082 60.9537 172.076C61.4428 172.056 62.0905 172.24 62.5135 172.774C62.7251 173.024 62.8903 173.308 63.0886 173.565C63.2803 173.822 63.5116 174.045 63.809 174.098C64.4171 174.204 64.9723 173.657 65.6068 173.479C66.2546 173.288 66.8693 173.406 67.3121 173.604C68.2309 174.026 68.4424 174.685 68.4093 174.678H68.4225Z" fill="#FAFAFA"/>
        <path d="M51.403 183.782C51.3567 183.829 51.0725 183.255 50.2133 182.933C49.7969 182.774 49.2417 182.702 48.6931 182.886C48.4221 182.972 48.1511 183.143 47.8404 183.302C47.5364 183.453 47.1596 183.624 46.73 183.545C46.3004 183.466 46.0096 183.15 45.8047 182.873C45.5998 182.59 45.4411 182.307 45.2561 182.083C44.9058 181.622 44.377 181.444 43.9341 181.424C43.022 181.398 42.5924 181.911 42.566 181.852C42.5527 181.839 42.6254 181.707 42.8436 181.536C43.0617 181.371 43.4384 181.187 43.9341 181.18C44.4233 181.16 45.071 181.345 45.494 181.878C45.7055 182.129 45.8708 182.412 46.069 182.669C46.2607 182.926 46.4921 183.15 46.7895 183.203C47.3976 183.308 47.9528 182.761 48.5873 182.583C49.2351 182.392 49.8498 182.511 50.2926 182.709C51.2113 183.13 51.4228 183.789 51.3898 183.782H51.403Z" fill="#FAFAFA"/>
        <path d="M67.3132 183.987C67.2801 184.046 66.8307 183.598 65.912 183.565C65.4691 183.552 64.9205 183.664 64.4578 184.013C64.2265 184.185 64.0282 184.429 63.7837 184.679C63.5457 184.923 63.2417 185.206 62.812 185.265C62.3824 185.324 61.9991 185.127 61.7215 184.929C61.4306 184.725 61.1927 184.514 60.9415 184.356C60.459 184.033 59.8972 184.033 59.4808 184.158C58.6083 184.428 58.3704 185.054 58.3241 185.008C58.3043 185.008 58.3307 184.85 58.4827 184.62C58.6348 184.396 58.9322 184.099 59.4015 183.928C59.8575 183.75 60.5317 183.717 61.1068 184.086C61.391 184.257 61.6421 184.468 61.9065 184.652C62.1709 184.837 62.4617 184.975 62.7592 184.929C63.3673 184.83 63.7176 184.139 64.2596 183.763C64.8082 183.374 65.4295 183.282 65.9186 183.328C66.9232 183.434 67.3396 183.987 67.3 183.987H67.3132Z" fill="#FAFAFA"/>
        <path d="M62.0593 191.227C62.0262 191.287 61.5768 190.839 60.658 190.806C60.2152 190.792 59.6666 190.904 59.2039 191.254C58.9726 191.425 58.7743 191.669 58.5298 191.919C58.2918 192.163 57.9878 192.446 57.5581 192.505C57.1285 192.565 56.7452 192.367 56.4676 192.169C56.1767 191.965 55.9388 191.754 55.6876 191.596C55.2051 191.273 54.6433 191.273 54.2269 191.399C53.3544 191.669 53.1165 192.295 53.0702 192.248C53.0504 192.248 53.0768 192.09 53.2288 191.86C53.3809 191.636 53.6783 191.339 54.1476 191.168C54.6036 190.99 55.2778 190.957 55.8529 191.326C56.1371 191.497 56.3882 191.708 56.6526 191.893C56.917 192.077 57.2078 192.216 57.5053 192.169C58.1133 192.077 58.4637 191.379 59.0056 191.003C59.5542 190.615 60.1755 190.522 60.6647 190.569C61.6693 190.674 62.0857 191.227 62.0461 191.227H62.0593Z" fill="#FAFAFA"/>
        <path d="M50.9533 209.186C50.9071 209.232 50.6163 208.665 49.757 208.356C49.3406 208.204 48.7854 208.138 48.2368 208.329C47.9658 208.422 47.6948 208.593 47.3908 208.758C47.0933 208.916 46.71 209.087 46.287 209.015C45.8573 208.942 45.5599 208.626 45.355 208.356C45.1435 208.073 44.9782 207.796 44.7932 207.572C44.4363 207.117 43.9009 206.946 43.4646 206.933C42.5459 206.92 42.1361 207.44 42.1031 207.381C42.0898 207.368 42.1626 207.236 42.3741 207.065C42.5856 206.9 42.9623 206.709 43.4646 206.689C43.9472 206.663 44.6015 206.841 45.0377 207.368C45.2559 207.618 45.4277 207.895 45.626 208.152C45.8243 208.409 46.0556 208.626 46.353 208.679C46.9611 208.777 47.5097 208.224 48.1443 208.033C48.7854 207.829 49.4067 207.941 49.8562 208.132C50.7815 208.54 50.9996 209.193 50.9666 209.186H50.9533Z" fill="#FAFAFA"/>
        <path d="M63.9482 213.007C63.9151 213.067 63.4723 212.612 62.5535 212.566C62.1107 212.546 61.5555 212.645 61.0928 212.994C60.8615 213.159 60.6566 213.403 60.412 213.653C60.1741 213.89 59.8634 214.167 59.4338 214.226C59.0042 214.286 58.6208 214.075 58.3498 213.87C58.0656 213.666 57.8277 213.449 57.5831 213.284C57.1072 212.955 56.5454 212.948 56.1224 213.067C55.2433 213.324 55.0053 213.95 54.9525 213.897C54.9326 213.897 54.9657 213.739 55.1177 213.515C55.2697 213.291 55.5738 213.001 56.0431 212.836C56.4991 212.665 57.1733 212.645 57.7483 213.014C58.0259 213.185 58.2771 213.403 58.5415 213.587C58.8059 213.772 59.0967 213.917 59.3941 213.877C60.0022 213.791 60.3658 213.1 60.9144 212.731C61.4696 212.349 62.0909 212.27 62.5734 212.322C63.578 212.441 63.9812 213.001 63.9482 213.001V213.007Z" fill="#FAFAFA"/>
        <path d="M21.1444 158.523C21.5476 158.998 36.0755 178.36 36.0755 178.36L34.0265 179.513L19.1152 160.394L21.1378 158.523H21.1444Z" fill="#263238"/>
        <path d="M77.8609 172.339C77.8609 172.339 64.2318 195.766 63.3065 196.201C61.4888 197.051 40.576 179.032 38.5799 177.313C38.3023 176.944 37.7405 176.173 36.6234 174.638C36.6234 174.638 34.971 167.114 33.2129 167.687C31.4547 168.267 34.1779 171.515 33.0278 174.374C31.8777 177.234 26.6297 170.415 26.4182 169.802L25.0632 168.037C25.0632 168.037 23.6421 172.049 23.7413 173.729C24.0519 179.098 29.148 185.482 29.148 185.482C29.148 185.482 49.8559 211.347 65.5339 212.23C70.0483 212.48 78.6739 199.521 84.0409 189.949C87.2796 184.164 85.7792 178.288 80.4056 174.388L77.8477 172.345L77.8609 172.339Z" fill="#FFBE9D"/>
        <path d="M26.327 174.678C26.2808 174.704 25.7057 173.887 25.1638 172.781C24.6152 171.68 24.3045 170.725 24.3574 170.705C24.4103 170.686 24.8068 171.595 25.3488 172.688C25.8908 173.782 26.3799 174.645 26.327 174.678Z" fill="#EB996E"/>
        <path d="M77.2205 165.823C76.6851 166.146 64.0078 189.6 64.0078 189.6L80.3204 202.341C80.3204 202.341 84.5637 191.919 86.8044 183.447C87.4125 181.147 87.1018 175.001 86.2426 172.629C85.0264 169.262 83.1889 170.099 80.4327 168.129L77.2205 165.823Z" fill="#FF7400"/>
        <g opacity="0.7">
          <path opacity="0.7" d="M82.5795 165.823C82.5795 165.823 82.4208 165.73 82.1168 165.579C81.8194 165.427 81.3236 165.236 80.6825 165.276C80.0414 165.296 79.2813 165.612 78.5542 166.159C77.8205 166.712 77.1067 167.496 76.4325 168.438C75.7451 169.374 75.1767 170.52 74.4761 171.706C73.7953 172.905 73.0616 174.176 72.2949 175.52C70.7549 178.208 69.0562 181.153 67.2782 184.25C66.1942 186.127 65.1433 187.952 64.1518 189.672L64.1122 189.461C68.534 192.939 72.3808 195.97 75.1503 198.157C76.492 199.231 77.576 200.101 78.3559 200.72C78.7062 201.01 78.9904 201.241 79.202 201.418C79.3936 201.583 79.4928 201.669 79.4862 201.675C79.4862 201.682 79.3738 201.609 79.1689 201.465C78.9442 201.3 78.6534 201.082 78.2832 200.812C77.4901 200.213 76.3863 199.369 75.0181 198.329C72.2288 196.168 68.3556 193.17 63.9073 189.731L63.7949 189.645L63.8676 189.52C64.8525 187.801 65.9034 185.976 66.9874 184.098C68.772 181.008 70.4706 178.063 72.0239 175.382C72.7972 174.045 73.5375 172.773 74.2315 171.581C74.9321 170.401 75.527 169.249 76.2408 168.306C76.9415 167.358 77.6817 166.567 78.4485 166.014C79.2086 165.46 80.0083 165.151 80.6891 165.151C81.3633 165.131 81.8656 165.348 82.1631 165.526C82.3217 165.605 82.4208 165.697 82.4935 165.744C82.5662 165.796 82.5993 165.823 82.5993 165.829L82.5795 165.823Z" fill="black"/>
        </g>
        <path d="M77.7097 187.815C77.6634 187.861 77.3792 187.288 76.5199 186.965C76.1035 186.807 75.5483 186.734 74.9997 186.919C74.7287 187.004 74.4577 187.176 74.1471 187.334C73.843 187.485 73.4663 187.657 73.0367 187.577C72.607 187.498 72.3162 187.182 72.1113 186.906C71.9064 186.622 71.7478 186.339 71.5627 186.115C71.2124 185.654 70.6836 185.476 70.2408 185.456C69.3287 185.43 68.9056 185.944 68.8726 185.884C68.8594 185.871 68.9321 185.739 69.1502 185.568C69.3683 185.403 69.7451 185.219 70.2408 185.212C70.7299 185.193 71.3776 185.377 71.8007 185.911C72.0122 186.161 72.1774 186.444 72.3757 186.701C72.5674 186.958 72.7987 187.182 73.0961 187.235C73.7042 187.34 74.2594 186.794 74.894 186.616C75.5417 186.425 76.1564 186.543 76.5992 186.741C77.518 187.162 77.7295 187.821 77.6964 187.815H77.7097Z" fill="#FAFAFA"/>
        <path d="M85.1652 184.778C85.1057 184.81 84.9867 184.185 84.253 183.644C83.8961 183.381 83.3806 183.163 82.7989 183.19C82.5147 183.203 82.204 183.289 81.867 183.361C81.5365 183.427 81.1201 183.486 80.7301 183.295C80.3401 183.104 80.1418 182.722 80.0229 182.399C79.8973 182.07 79.8246 181.754 79.7056 181.49C79.4941 180.95 79.0314 180.634 78.615 180.502C77.7426 180.232 77.1939 180.614 77.1807 180.548C77.1675 180.535 77.2799 180.423 77.531 180.317C77.7822 180.219 78.192 180.146 78.6811 180.265C79.157 180.377 79.732 180.732 79.9964 181.358C80.1352 181.655 80.2145 181.978 80.3335 182.274C80.4459 182.577 80.6111 182.854 80.8821 182.986C81.4373 183.249 82.1247 182.874 82.7857 182.874C83.4599 182.86 84.0217 183.144 84.3984 183.453C85.1718 184.106 85.1916 184.797 85.1652 184.778Z" fill="#FAFAFA"/>
        <path d="M85.548 175.086C85.4885 175.119 85.3695 174.493 84.6358 173.953C84.2789 173.689 83.7634 173.472 83.1817 173.498C82.8975 173.512 82.5869 173.597 82.2498 173.67C81.9193 173.736 81.5029 173.795 81.1129 173.604C80.7229 173.413 80.5247 173.031 80.4057 172.708C80.2801 172.378 80.2074 172.062 80.0884 171.799C79.8769 171.258 79.4142 170.942 78.9978 170.81C78.1254 170.54 77.5768 170.922 77.5635 170.857C77.5503 170.843 77.6627 170.731 77.9139 170.626C78.165 170.527 78.5748 170.455 79.0639 170.573C79.5398 170.685 80.1149 171.041 80.3792 171.667C80.518 171.963 80.5974 172.286 80.7163 172.583C80.8287 172.886 80.9939 173.162 81.2649 173.294C81.8201 173.558 82.5075 173.182 83.1685 173.182C83.8427 173.169 84.4045 173.452 84.7813 173.762C85.5546 174.414 85.5744 175.106 85.548 175.086Z" fill="#FAFAFA"/>
        <path d="M81.9405 197.374C81.8811 197.407 81.7621 196.781 81.0284 196.241C80.6715 195.978 80.1559 195.76 79.5743 195.786C79.2901 195.8 78.9794 195.885 78.6423 195.958C78.3119 196.024 77.8955 196.083 77.5055 195.892C77.1155 195.701 76.9172 195.319 76.7983 194.996C76.6727 194.666 76.6 194.35 76.481 194.087C76.2695 193.546 75.8068 193.23 75.3904 193.098C74.5179 192.828 73.9693 193.21 73.9561 193.145C73.9429 193.131 74.0553 193.019 74.3064 192.914C74.5576 192.815 74.9674 192.743 75.4565 192.861C75.9324 192.973 76.5074 193.329 76.7718 193.955C76.9106 194.251 76.9899 194.574 77.1089 194.871C77.2213 195.174 77.3865 195.45 77.6575 195.582C78.2127 195.846 78.9001 195.47 79.5611 195.47C80.2353 195.457 80.7971 195.74 81.1738 196.05C81.9472 196.702 81.967 197.394 81.9405 197.374Z" fill="#FAFAFA"/>
      </g>
      <defs>
        <clipPath id="clip0_326_5248">
          <rect width="258" height="262" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}
