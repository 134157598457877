import React, { Component } from 'react';
import ContactStatus from "components/UI/ContactStatus/index";
import Language from "language/index";
import { Link } from "react-router-dom";
import { Avatar } from "components/UI/Avatar/index";

class BlackListItem extends Component {
  render() {

    const styleBtn = {
      height: '90px',
      display: 'flex',
      alignItems: 'center',
      zIndex: 122
    };

    const { _id, firstName, lastName, avatar, typeKeys } = this.props;
    return (
      <li>
        <div className="hover"/>
        <Link to={`/profile/${_id}`}/>
        <Avatar
          size={70}
          avatar={avatar}
        />
        <div className="s-contact-info">
          <div className="contact-name">
            <Link className="link-btn" to={`/profile/${_id}`}>
              {`${firstName}  ${lastName}`}
            </Link>
          </div>

          <ContactStatus className="contact-status" type={typeKeys[0]}/>
          <div className="right-mes-desc right_btn_messages_block" style={styleBtn}>
            <div className="test_btn">
              <button
                onClick={this.props.removeUser}
                className="gray-btn s-btn mt10 delete">
                <span>{Language.remove}</span>
              </button>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default BlackListItem;
