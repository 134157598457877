import ContactStatus from "components/UI/ContactStatus/index";
import { dateToString } from "libs/convertDate";
import { GET_NEWS } from "query/news";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import Language from "language/index";


class News extends Component {
  render() {
    return (

      <Query query={GET_NEWS}>
        {({ data, loading }) => {
          if (!data.NewsGetter && loading) return 'Loading...';

          const isNews = data.NewsGetter.getNews.total > 0;
          if (!isNews && !this.props.isPage) return null;
          return (
            <div className="side-block">
              <div className="tabs-wrapper">
                <div className="sb-title">
                  <span>{Language.NewsAboutContacts}</span>
                  {/*<ul className="tabs">*/}
                  {/*  <li className="active" data-tab="0">{Language.NewsAboutContacts}</li>*/}
                  {/*  /!*<li data-tab="1">Новости сети</li>*!/*/}
                  {/*</ul>*/}
                </div>
                <ul className="tab_content">
                  <li data-tab="0">
                    <div className="news-contacts">
                      <ul className="contacts-list">
                        {!isNews && (
                          Language.nothingFound
                        )}
                        {
                          data.NewsGetter.getNews.news.slice(0, 3).map((el, index) => {
                            if (el.__typename === 'NewsSystem') {
                              return null;
                            }

                            const { userFrom, createDate } = el;

                            return (
                              <li key={index}>
                                <div className="contact-name">
                                  <Link to={`/profile/${userFrom._id}`}>
                                    {userFrom.firstName} {userFrom.lastName}
                                  </Link>
                                </div>
                                <ContactStatus
                                  className="contact-status"
                                  type={userFrom.typeKeys[0]}
                                />
                                <div className="new-report">
                                  {el.newsType === 'acceptContact' &&
                                    'Добавил Вас в контакты.'
                                  }

                                  {
                                    el.newsType === 'createOffer' &&
                                    'Добавил новую заявку.'
                                  }

                                  {
                                    el.newsType === 'changeAvatar' &&
                                    'сменил/загрузил новый аватар'
                                  }
                                </div>
                                  <span
                                    className="time"
                                    style={{ position: 'static', display: 'block', marginTop: '11px' }}
                                  >
                                    {dateToString(new Date(createDate), 'stringFullDate')}
                                    {new Date(createDate).getFullYear() < new Date(Date.now()).getFullYear()
                                      ? ` ${new Date(createDate).getFullYear()}`
                                      : ''
                                    }, {dateToString(new Date(createDate), 'time')}
                                </span>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                    {
                      !this.props.isPage &&
                      <Link className="see_more" to="/news">{Language.showMore}</Link>
                    }

                    {/*<button type="button" ></button>*/}
                  </li>
                </ul>
              </div>
            </div>
          )
        }}
      </Query>

    )
  }
}


export default News;
