import ContactStatus from "components/UI/ContactStatus/index";
import SideBlock from "components/UI/SideBlock/index";
import { TOGGLE_FAVORITE_DIALOG } from "mutation/dialog";
import { GET_DIALOG_BY_ID, GET_DIALOGS } from "query/dialogs";
import React, { Component, Fragment } from 'react';
import { Mutation } from "react-apollo";


const LeftBlock = props => (
    <div className="_left flex align">
        {props.isOnline && <i className="online"/>}
        <h1> {props.firstName} {props.lastName} </h1>
        <ContactStatus
            className={'contact-status mt0'}
            type={props.typeKeys[0]}
        />
    </div>
);


class UserInfo extends Component {
    render() {
        const { user, favorite } = this.props;
        return (
            <SideBlock>
                <div className="title-block">
                    <div className="flex jcsb align">
                        <LeftBlock {...user}/>
                        <div className="_right">
                            <button
                                type="button"
                                onClick={this.props.mutate}
                                className={`tooltip-featured gray-btn s-btn feabtn tooltipstered ${favorite ? 'active' : ''}`}>
                                <i className="panton d">
                                    K
                                </i>
                            </button>
                            {/*&nbsp;*/}
                            {/*<button type="button" className="gray-btn s-btn cogs">*/}
                                {/*<i className="panton c">*/}
                                    {/*i*/}
                                {/*</i>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </SideBlock>
        )
    }
}


export default props => (
    <Mutation
        mutation={TOGGLE_FAVORITE_DIALOG}
        variables={{ "dialogId": props._id, "favorite": !props.favorite }}
        refetchQueries={[{ query: GET_DIALOGS, variables: { "favoriteDialog": true } }, {query: GET_DIALOG_BY_ID, variables: { "dialogId": props.dialogId }}]}
    >
        {(mutate, client) => (
            <UserInfo {...props} mutate={mutate}/>
        )}
    </Mutation>
);
