import React, { useMemo, useState } from 'react'

import { SelectController } from 'components/v1/HookFields/Select';

import Language from 'language';
import IndustryData from 'components/UI/Forms/GroupFields/IndustryData';

import { required } from 'libs/rules';
import { MONTH_OPTIONS } from 'pages/Profile/EditForms/utils';

import styles from 'pages/Profile/EditForms/styles.module.scss';


const BirthGroup = ({
  control,
  setValue,
  errors,
}) => {

  const [selectedMonth, setSelectedMonth] = useState(null)

  const mapYears = () => {
    const options = []

    for (let i = 2004; i >= 1940; i--) {
      options.push({ value: `${i}`, label: i })
    }

    return options
  }

  const mapDays = (days) => {
    const options = []

    for (let i = 1; i <= days; i++) {
      const day = `0${i}`.slice(-2)
      options.push({ value: day, label: day })
    }

    return options
  }

  const optionsDay = useMemo(() => {
    if (selectedMonth) {
      console.log(selectedMonth);
    }

    return mapDays(31)
  }, [selectedMonth])

  const optionsYear = useMemo(() => {
    return mapYears()
  }, [])

  return (
    <div className={styles.section}>
      <div>День рождения</div>
      <div className={styles.flexRowSection}>
        <div>
          <SelectController
            control={control}
            name="dayOfBirth"
            placeholder={'День'}
            options={optionsDay}
            label={''}
            errors={errors.dayOfBirth}
            rules={{required}}
          />
        </div>
        <div>
          <SelectController
            control={control}
            name="monthOfBirth"
            placeholder={'Месяц'}
            options={MONTH_OPTIONS}
            label={''}
            errors={errors.monthOfBirth}
            rules={{required}}
          />
        </div>
        <div>
          <SelectController
            control={control}
            name="yearOfBirth"
            placeholder={'Год'}
            options={optionsYear}
            label={''}
            errors={errors.yearOfBirth}
            rules={{required}}
          />
        </div>
      </div>
    </div>
  )
}


export default BirthGroup
