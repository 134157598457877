import Loading from "components/UI/Prealoders/Loading";
import SideBlock from "components/UI/SideBlock/index";
import TContactsList from "components/UI/TContacts/TContactsList";
import PropTypes from 'prop-types';
import { GET_GUESTS } from "query/contacts";
import React, { PureComponent } from 'react';
import { Query } from "react-apollo";


class Guests extends PureComponent {

    static propTypes = {
        getGuests: PropTypes.array,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        getGuests: [],
        loading: false
    };

    render() {
        const { getGuests, loading } = this.props;
        return (
            <SideBlock
                title="Гости"
            >
                {
                    loading ?
                        <Loading/> :
                        <TContactsList users={getGuests}/>
                }
            </SideBlock>
        )
    }
}


export default props => {
    return (
        <Query query={GET_GUESTS}>
            {({ loading, error, data }) => {
                if (error) return 'Error...';
                return <Guests loading={loading} {...data} {...props}/>
            }}
        </Query>
    )
}