import React, { Component } from 'react';
import styles from './styles.module.scss';


import icon from './icon-app.png';
import { getItem, setItem } from "../../../libs/localStorage";

class NotificationApp extends Component {

  state = {
    isShow: false
  };


  componentDidMount() {
    setTimeout(() => {
      if (navigator.userAgent.match(/iPhone|iPad/i)) {
        if (getItem('mobile_app_notification', false)) {
          this.setState({ isShow: false });
        } else {
          this.setState({ isShow: true });
        }
      }

    }, 1000)
  }


  closeNotification = () => {
    this.setState({ isShow: false });
    setItem('mobile_app_notification', true);
  };

  render() {

    return (
      <div className={`${styles.notification} ${this.state.isShow && styles.is_open} `}>
        <div className={styles.close} onClick={this.closeNotification}>
          <svg width="11" height="12" viewBox="0 0 11 12"><g><g opacity=".55"><path d="M6.156 5.966l4.723-4.724a.459.459 0 1 0-.648-.649L5.507 5.317.783.593a.459.459 0 0 0-.649.65l4.724 4.723-4.724 4.723a.459.459 0 0 0 .65.65l4.723-4.724 4.723 4.723a.459.459 0 0 0 .65-.649z"/></g></g></svg>
        </div>
        <img src={icon} alt="..." className={styles.icon}/>
        <div className={styles.desc}>
          <div className={styles.title}>
            newnext
          </div>
          Открыть в приложении
        </div>
        <a onClick={this.closeNotification} href="https://apps.apple.com/ru/app/newnext/id1498650490" target="_blank" className={styles.white_button}>
          ОТКРЫТЬ
        </a>
      </div>
    )
  }
}


export default NotificationApp;
