import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/global.scss';

// Sentry.init({ dsn: 'https://fb345686e3f14703bcbf035661d59dd8@sentry.io/1526203' });

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);



