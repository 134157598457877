import { ActionNotification } from "components/UI/Notifications/action";
import { required } from "libs/fieldValidation";
import { setItem } from "libs/localStorage";
import { showError } from "libs/showFormsError";
import { lastnames, name, phones } from "libs/validationsForms";

export const submitRegForm = (values, mutate) => {
    let { firstName, lastName, phone, typeKeys, captcha } = values;

    let objValid = {
        phone: [required(phone)],
        firstName: [required(firstName)],
        lastName: [required(lastName)],
        typeKeys: [required(typeKeys)],
    };

    if (phone) objValid['phone'] = [phones(phone)];
    if (firstName) {
        firstName = firstName.replace(/\s/g, '');
        objValid['firstName'] = [name(firstName)];
    }
    if (lastName) {
        lastName = lastName.replace(/\s/g, '');
        objValid['lastName'] = [lastnames(lastName)];
    }
    showError(objValid);

    setItem('phone', phone);

    typeKeys = [typeKeys];
    phone = phone.replace(/\D+/g, "");

    if (captcha === undefined) {
        ActionNotification('1', `ReCAPTCHA не пройдена`)
    } else {
        mutate({ variables: { captcha, client: { firstName, lastName, phone, typeKeys } } })
            .then(res => {
                if (res) {
                    setItem('phone', phone);
                    setItem('regToken', res.data.registration.token);
                }
            })
    }
};