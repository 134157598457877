import { updateLoginForm } from "components/UI/Forms/LoginForm/update";
import { required } from 'libs/fieldValidation';
import { showError } from 'libs/showFormsError';

export const submitLoginForm = (values, mutate) => {

    let { phone, password } = values;

    showError({
        phone: [required(phone)],
        password: [required(password)],
    });

    phone = phone.replace(/[^0-9]/gim, '');

    mutate({ variables: { phone, password } }, updateLoginForm)
};
