import TContactsItem from "components/UI/TContacts/TContactsItem";
import React, { PureComponent } from 'react';
import Language from "language/index";

export default class TContactsList extends PureComponent {
    render() {
        const notFound = this.props.users.length === 0;
        return (
            <div className="t-contacts">
                {
                    notFound &&
                    <p style={{paddingTop: '15px'}}>{Language.nothingFound}</p>
                }

                {
                    !notFound &&
                    this.props.users.map((el, index) => {
                        if (!el.onlineStatus) el.onlineStatus = {};
                        let user = el.user ? el.user : el;
                        return <TContactsItem {...user} key={index}/>
                    })
                }
            </div>
        )
    }
}

