import React from 'react'
import { GET_BLACK_LIST } from 'query/contacts';
import { Query } from 'react-apollo';
import classes from 'pages/Profile/components/MyContacts/styles.module.scss';
import { User } from 'components/v1/User';
import { Button } from 'components/v1/Button';
import { CloseIcon } from 'components/v1/Icons/CloseIcon';
import { REMOVE_FROM_BLACK_LIST } from 'mutation/blackList';

const Blacklist = ({ getBlackList, client }) => {
  const isEmpty = getBlackList.total === 0

  const removeUser = (userId) => {
    client.mutate({
      mutation: REMOVE_FROM_BLACK_LIST,
      variables: { userId },
      refetchQueries: [{ query: GET_BLACK_LIST }]
    })
  }

  return (
    <div>
      {isEmpty && (
        <div>Нет заблокированных контактов</div>
      )}
      {!isEmpty && getBlackList.blackList.map((el) => (
        <User
          key={el._id}
          {...el}
          actions={(
            <div className={classes.buttons}>
              <Button icon={<CloseIcon />} variant="outlined" onClick={() => removeUser(el._id)} />
            </div>
          )}
        />
      ))}
    </div>
  )
}

export default props => {
  return (
    <Query query={GET_BLACK_LIST} fetchPolicy={'cache-and-network'}>
      {({ loading, data, client, error }) => {
        if (error) return <p> Error.... </p>;
        if (!data.BlackListGetter && loading) return 'Loading...';
        return <Blacklist
          client={client}
          {...props}
          {...data.BlackListGetter}
        />
      }}
    </Query>
  )
}
