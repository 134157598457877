import React, { Component } from 'react';
import Language  from "language/index";

class SearchBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSearchOpen: true,
      openOrCloseSearch: () => this.openOrCloseSearch(),
    };
  }

  openOrCloseSearch() {
    this.setState({isSearchOpen: !this.state.isSearchOpen});
  }

  render() {
    // const {isSearchOpen} = this.state;
    return(
      <React.Fragment>
      <div className="side-block">
        <div className="title-block">
          <div className="flex jcsb align">
            <h1>{Language.help}</h1>
            <div className="_right">

              {/*{*/}
                {/*isSearchOpen &&*/}
                {/*<span onClick={this.state.openOrCloseSearch} className="close-or-search"><a href="#"><img src="templates/img/close.svg" alt="" /></a></span>*/}
              {/*}*/}
              {/*{*/}
                {/*!isSearchOpen &&*/}
                {/*<span onClick={this.state.openOrCloseSearch} className="close-or-search"><a href="#"><img src="templates/img/search.svg" alt="" /></a></span>*/}
              {/*}*/}

            </div>
          </div>
        </div>
      </div>
      {/*<div className={'search-form-dialogs form ' + (this.state.isSearchOpen ? '' : 'search-form-close')}>*/}
        {/*<form action="" target="post_form">*/}
          {/*<div className="element">*/}
            {/*<input type="text" name="search" placeholder="Поиск по вопросам" />*/}
            {/*<button type="submit">Найти</button>*/}
          {/*</div>*/}
        {/*</form>*/}
      {/*</div>*/}
      </React.Fragment>
    );
  }
}

export default SearchBlock;