import { Field, reduxForm, stopSubmit } from 'redux-form';
import { Mutation } from 'react-apollo';
import Language from 'language';
import Input from 'components/UI/Fields/Input';
import { Button } from 'components/v1/Button';
import { submitPhoneVerificationForm } from 'components/UI/Forms/PhoneVerificationForm/submit';

import classes from './styles.module.scss';

export const ConfForm = ({ handleSubmit, counter = 0, resendCode, initialValues = {}, isRecovering }) => {

  return (
    <div className={classes.block}>
      <div className={classes.title}>
        {isRecovering ? 'Восстановление пароля' : 'Подтверждение телефона'}
      </div>
      <div className={classes.info}>
        Мы отправили SMS с кодом на номер {initialValues?.phone}
      </div>
      <form onSubmit={handleSubmit}>
        <Field
          label={Language.codeFromSMS}
          name="code"
          component={Input}
          className={classes.field}
        />
        {counter === 0 && (
          <div className={classes.repeat} onClick={resendCode}>Отправить код повторно</div>
        )}
        {counter > 0 && (
          <div className={classes.description}>SMS придет в течении {counter} сек</div>
        )}
        <Button className={classes.button} type="submit">Продолжить</Button>
      </form>
    </div>
  )
}

const ConfReduxForm = reduxForm({
  form: 'phoneVerification',
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(stopSubmit('phoneVerification'));
    }
  }
})(ConfForm);

export const ConfirmPhoneForm = props => (
  <Mutation
    mutation={props.mutate}
    onCompleted={() => props.nextStep()}
  >
    {mutate => (
      <ConfReduxForm
        {...props}
        onSubmit={values => props.submit ? props.submit(values) : submitPhoneVerificationForm(values, mutate)}
      />
    )}
  </Mutation>
)
