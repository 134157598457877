import React, { useState } from 'react'
import cn from 'classnames'

import { Avatar } from 'components/v1/Avatar'

import { Button } from 'components/v1/Button';
import { Comments } from 'components/v1/Post/Comments';

import { BookmarkIcon } from 'components/v1/Icons/BookmarkIcon';
import { WatchedIcon } from 'components/v1/Icons/WatchedIcon';
import { ShareIcon } from 'components/v1/Icons/ShareIcon';
import { CommentIcon } from 'components/v1/Icons/CommentIcon';
import { LikeIcon, LikeTracedIcon } from 'components/v1/Icons/LikeIcon';

import styles from './styles.module.scss'
import { Dropdown } from 'components/v1/Dropdown';
import { TrashIcon } from 'components/v1/Icons/TrashIcon';
import { EditIcon } from 'components/v1/Icons/EditIcon';
import { ADD_LIKE, DELETE_NEWS, RESTORE_NEWS } from 'mutation/news';
import { dateToString } from 'libs/convertDate';
import { Link } from 'react-router-dom';
import { client } from 'apollo';
import { ActionNotification } from 'components/UI/Notifications/action';

export const Post = ({
  _id,
  userFrom,
  title,
  content,
  countWatched,
  countLiked,
  youLiked,
  createDate,
  isMe,
  ...rest
}) => {
  const [openedComments, setOpenedComments] = useState(false)

  const [isDeleted, setIsDeleted] = useState(false)
  const [showFullText, setShowFullText] = useState(!Boolean(content.length > 200))

  const onDeletePost = () => {
    client.mutate({
      variables: { newsId: _id },
      mutation: DELETE_NEWS,
    }).then(() => {
      ActionNotification('2', 'Ваша новость удалена')
      setIsDeleted(true)
    })
  }

  const onRestorePost = () => {
    client.mutate({
      variables: { newsId: _id },
      mutation: RESTORE_NEWS,
    }).then((res) => {
      ActionNotification('2', 'Ваша новость восстановлена')
      setIsDeleted(false)
    })
  }

  const items = [
    { label: 'Удалить пост', onClick: onDeletePost, icon: <TrashIcon/> },
    // { label: 'Редактировать', onClick: () => {}, icon: <EditIcon/> },
    // { label: 'Сохранить в закладках', onClick: () => {}, icon: <BookmarkIcon/> },
  ]

  if (rest.__typename === 'NewsSystem') {
    return null;
  }

  const showWatched = !isNaN(countWatched) && countWatched > 0

  return (
    <div className={cn(styles.post, { [styles.deletedPost]: isDeleted })}>
      <div className={styles.postContent}>
        <div className={styles.header}>
          <Link to={`/profile/${isMe ? 'me' : userFrom._id}`}>
            <Avatar source={userFrom ? userFrom.avatar : ''} size={'circle'} />
          </Link>
          <div className={styles.userdata}>
            {userFrom ? (
              <h4><Link to={`/profile/${isMe ? 'me' : userFrom._id}`}>{userFrom.firstName} {userFrom.lastName}</Link></h4>
            ) : (
              <h4>Система</h4>
            )}
            <div>
              {/*todo: поменять на норм генерацию*/}
              {dateToString(new Date(createDate), 'stringFullDate')}
              {new Date(createDate).getFullYear() < new Date(Date.now()).getFullYear()
                ? ` ${new Date(createDate).getFullYear()}`
                : ''
              }, {dateToString(new Date(createDate), 'time')}
              {/*{createDate}*/}
            </div>
          </div>
          {isMe && (
            <Dropdown items={items} />
          )}
          {/*  : (*/}
          {/*  <Button icon={<BookmarkIcon />} variant="outlined" onClick={() => {}} />*/}
          {/*)}*/}
        </div>
        <div className={styles.body}>
          {title && (
            <h3>{title}</h3>
          )}
          {content && (
            <>
              <div>
                {showFullText ? content : `${content.slice(0, 280)}...`}
              </div>
              {!showFullText && (
                <div
                  role="button"
                  onClick={() => setShowFullText(true)}
                  className={styles.next}
                >
                  Показать полностью...
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.footer}>
        <div className={styles.leftPart}>
          {/*<Mutation*/}
          {/*  mutation={ADD_LIKE}*/}
          {/*  onCompleted={(val) => {*/}
          {/*    console.log(val);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {mutate => (*/}
          {/*    <div*/}
          {/*      role="button"*/}
          {/*      className={cn(styles.action, styles.like, { [styles.yourLike]: youLiked })}*/}
          {/*      onClick={() => mutate({ variables: { newsId: _id } })}*/}
          {/*    >*/}
          {/*      {youLiked ? <LikeIcon /> : <LikeTracedIcon />}*/}
          {/*      {countLiked || 0}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</Mutation>*/}
          {/*<div role="button" className={styles.action} onClick={() => setOpenedComments(true)}>*/}
          {/*  <CommentIcon /> 10*/}
          {/*</div>*/}
          {/*<div role="button" className={styles.action}>*/}
          {/*  <ShareIcon /> 2*/}
          {/*</div>*/}
        </div>
        <div className={styles.rightPart}>
          {showWatched && (
            <div className={styles.view}>
              <WatchedIcon /> {countWatched}
            </div>
          )}
        </div>
      </div>
        {isDeleted && (
          <div className={styles.deletedContent}>
            <div>
              Запись удалена<br/><button onClick={onRestorePost} className={styles.restoreBtn}>Восстановить</button>
            </div>
          </div>
        )}
      </div>
      {openedComments && (
        <Comments newsId={_id} />
      )}
    </div>
  )
}
//
// export const Post = (props) => {
//   return (
//     <Mutation
//       mutation={DELETE_NEWS}
//       onCompleted={(val) => {
//         console.log(val);
//       }}
//     >
//       {mutation => (
//         <PostContent {...props} />
//       )}
//     </Mutation>
//   )
// }
