import React, { Component } from 'react';
import cn from 'classnames';

import { changeStatusRequests } from 'components/Pages/Requests/changeStatusRequest';
import { StatusRequest } from 'components/UI/Requests/RequestControl/StatusRequests';
import Language from 'language/index';
import { GET_OFFERS_ARCHIVE } from 'query/offers';
import { Button } from 'components/v1/Button';

import classes from './styles.module.scss';


const ButtonList = props => {
  return (
    <li>
      <div className={classes.item} onClick={props.action}>
        {props.children}
      </div>
    </li>
  )
};


export class RequestControl extends Component {
  wrapperRef = null;

  state = {
    isOpenMenu: false
  };

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ isOpenMenu: false });
    }
  };

  toggleMenu = () => this.setState({ isOpenMenu: !this.state.isOpenMenu });

  changeStatusRequest = status => {
    this.toggleMenu();
    changeStatusRequests(status, this.props._id, { query: GET_OFFERS_ARCHIVE, variables: { archive: true } });
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  render() {
    const { isNetwork, status } = this.props;

    if (isNetwork) return null;

    return (
      <div ref={ref => this.wrapperRef = ref} className={cn('dots-btn-wrap more-info more-inf', classes.menu)}>
        <Button
          variant="outlined"
          size="small"
          icon={<div className={classes.threeDots}>...</div>}
          onClick={this.toggleMenu}
        />
        {this.state.isOpenMenu && (
          <ul className="hidden-info" style={{ display: 'block' }}>
            {(status !== 'MODERATION' && status !== 'ACCEPTED') && (
              <ButtonList action={() => this.changeStatusRequest('MODERATION')}>
                <i className="panton d">d</i>
                {Language.sendModeration}
              </ButtonList>
            )}
            <ButtonList action={() => {
              this.toggleMenu();
              this.props.edit()
            }}>
              <i className="panton c">u</i>
              {Language.edit}
            </ButtonList>
            <ButtonList action={() => this.changeStatusRequest('ARCHIVED')}>
              <i className="panton c">v</i>
              {Language.archiveDo}
            </ButtonList>
            {status !== 'ARCHIVED' && (
              <ButtonList action={() => this.changeStatusRequest('REMOVED')}>
                <i className="panton b">k</i>
                {Language.remove}
              </ButtonList>
            )}
          </ul>
        )}
      </div>
    );
  }
}
