import RightControlBlock from "components/UI/Requests/RightControlBlock";
import React from 'react';


const SideBlockTemporary = ({ tabs, changeTab, activeTab, action }) => {

    // TODO: переделать компонент и сайд блока под заявки

    return (
        <div className="side-block">
            <div className="title-block">
                <div className="flex jcsb align" style={{height: 'auto'}}>
                    <ul className="flex applications_my_with_network">
                        {
                            tabs.map((el, index) => (
                                <li
                                    key={index}
                                    onClick={() => changeTab(index)}
                                    className={`${activeTab === index ? 'is_active' : ''}`}
                                >
                                    {el.title}
                                </li>
                            ))
                        }
                    </ul>
                    <RightControlBlock {...action}/>
                </div>
            </div>
        </div>
    )
};

export default SideBlockTemporary;