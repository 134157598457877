import DatePickers from "components/UI/Fields/DatePicker";
import Input from "components/UI/Fields/Input";
import LoaderImage from "components/UI/Fields/LoaderImage";
import Select from "components/UI/Fields/Select";
import Money from "components/UI/Forms/GroupFields/Money";
import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from "redux-form";


class ICOForm extends Component {

    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return (
            <div className="form fullWidth">
                <div className="ico_form" style={{ display: 'block' }}>

                    {/* TODO: добавить поле загрузки файлов */}


                    <form id='new_ico_form' onSubmit={handleSubmit}>
                        <form id="picture_ico">
                            <LoaderImage/>
                        </form>

                        <Field
                            name="title"
                            type="text"
                            maxLength="40"
                            component={Input}
                            label="Название кампании" // Language.CompanyName
                        />
                        <Field
                            name="campaignType"
                            component={Select}
                            label="Тип кампании"  // Language.campaignType
                            options={[
                                { value: 'preICO', label: 'preICO' },
                                { value: 'ICO', label: 'ICO' },
                                { value: 'Crowdfunding', label: 'Crowdfunding' },
                                { value: 'CrowdInvesting', label: 'CrowdInvesting' }
                            ]}
                        />
                        <Money
                            nameField="softCap"
                            label="Soft cap"
                            options={[
                                { value: 'BTC', label: 'BTC' },
                                { value: 'ETH', label: 'ETH' },
                            ]}
                        />
                        <Money
                            nameField="hardCap"
                            label="Hard cap"
                            options={[
                                { value: 'BTC', label: 'BTC' },
                                { value: 'ETH', label: 'ETH' },
                            ]}
                        />
                        <Field
                            name="startTime"
                            maxLength="40"
                            component={DatePickers}
                            label="Start time"
                        />
                        <Field
                            name="endTime"
                            maxLength="40"
                            component={DatePickers}
                            label="End time"
                        />

                        {/*<ul className="language_check">*/}
                        {/*<li className="active_language">EN</li>*/}
                        {/*<li>RU</li>*/}
                        {/*<li>ZH</li>*/}
                        {/*<li>JA</li>*/}
                        {/*<li>KO</li>*/}
                        {/*<li>IT</li>*/}
                        {/*<li>DE</li>*/}
                        {/*<li>HI</li>*/}
                        {/*<li>ES</li>*/}
                        {/*<li>FR</li>*/}
                        {/*</ul>*/}

                        <Field
                            name="shortDescription"
                            type="text"
                            maxLength="250"
                            component={Input}
                            label="Короткое описание ( до 250 символов )" // Language.shortDescription
                        />
                        <Field
                            name="description"
                            type="text"
                            maxLength="500"
                            component={Input}
                            label="Полное описание" // Language.fullDescription
                        />
                        <button type="submit" disabled={loading} className="orange-btn"> Сохранить</button>
                        {
                            this.props.editRequestClose &&
                            <button type="button" onClick={this.props.editRequestClose} className="gray-btn"
                                    style={{ marginLeft: '15px', marginTop: '2px' }}>
                                Отмена
                            </button>
                        }
                    </form>
                </div>
            </div>
        );
    }
}


ICOForm = reduxForm({
    form: 'ICOForm',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('ICOForm'));
        }
    }
})(ICOForm);


export default ICOForm;
