import EditProfile from 'components/UI/Admin/EditProfile';
import Language from 'language/index';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import InfiniteScroll from 'react-infinite-scroller';
import { GET_PROFILE_FOR_ADMIN } from '../../../query/index';
import PreloaderCircle from '../../UI/Prealoders/PreloaderCircle';
import SContactsItem from '../../UI/SContacts/SContactsItem';
import NotFound from 'components/UI/NotFound';


class ProfileUsersListSocial extends Component {

	state = {
		page: 1,
		loading: false,
		userId: false,
		name: '',
		profiles: []
	};

	pagination = page => {
		this.setState({ loading: true });
		this.props.fetchMore({
			variables: {
				page
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev;
				this.setState({ page, loading: false });
				prev.ProfileGetter.getProfiles.profiles = [...prev.ProfileGetter.getProfiles.profiles, ...fetchMoreResult.ProfileGetter.getProfiles.profiles];
				return prev;
			}
		})
	};


	submit = e => {
		e.preventDefault();
		this.setState({ loading: true, profiles: [] });

		this.props.client.query({
			query: GET_PROFILE_FOR_ADMIN,
			variables: {
				name: this.state.name,
				page: 0,
				count: 100
			}
		})
			.then(res => {
				this.setState({ profiles: res.data.ProfileGetter.getProfiles.profiles })
			})
			.finally(res => {
				this.setState({ loading: false })
			})

	};

	render() {
		const { total, profiles } = this.state;

		return (
			<React.Fragment>
				<div className="search-form-dialogs form">
					<form action="" target="post_form">
						<div className="element">
							<div style={{ display: 'inline', position: 'relative' }} className="add-clear-span">
								<input
									type="text"
									name="search"
									placeholder={Language.searchFullName}
									style={{ paddingRight: '20px' }}
									onChange={e => {
										if (e.target.value && e.target.value.length > 2) {
											this.setState({ name: e.target.value })
										} else if (e.target.value.length === 0) {
											this.setState({ name: '' });
										}
									}}
								/>
							</div>
							<button onClick={this.submit} type="submit">{Language.find}</button>
						</div>
					</form>
				</div>
				<div className="side-block">
					{/*<div className="sb-title">*/}
					{/*	<span>Найдено пользователей:</span>*/}
					{/*	<i>{total}</i>*/}
					{/*</div>*/}
					<InfiniteScroll
						pageStart={0}
						loadMore={this.pagination}
						hasMore={!this.state.loading && this.state.page * 10 < total}
						loader={<PreloaderCircle key={2351}/>}
					>
						<div className="s-messages">
							<ul className="mes-list">
								{
									profiles.map((el, index) => {
										if (el._id) {
											return <SContactsItem
												actionEdit={this.toggleUserId}
												actionDelete={this.setUserDelete}
												link={`/profile/${el._id}`}
												offer={false}
												key={index}
												{...el}
											/>
										}
										return null
									})
								}

								{
									(profiles.length === 0 && !this.state.loading) &&
									<NotFound title="Пользователи не найденны"/>
								}
							</ul>
							{
								this.state.loading &&
								<PreloaderCircle/>
							}
						</div>
					</InfiniteScroll>
				</div>
			</React.Fragment>
		)
	}
}


export default props => {
	return (
		<Query query={GET_PROFILE_FOR_ADMIN} variables={{ page: 0, ...props.variables }}>
			{({ client, data, loading, error, fetchMore, refetch }) => {
				if (error) return 'Error...';
				if (loading && !data.ProfileGetter) return <PreloaderCircle/>;
				return <ProfileUsersListSocial refetch={refetch} fetchMore={fetchMore}
											   client={client} {...props} {...data.ProfileGetter}/>
			}}
		</Query>
	)
};
