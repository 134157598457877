import { BackButton } from 'components/v1/BackButton'

import { useSizeXS } from 'libs/useMediaQuery'

import headerClasses from 'components/v1/Header/styles.module.scss'
import styles from './styles.module.scss'

export const HeaderMobile = ({
  title,
  goBack,
  actions = [],

}) => {

  const isMobile = useSizeXS()

  if (!isMobile) {
    return null
  }

  return (
    <div className={headerClasses.header}>
      <div className={headerClasses.container}>
        {goBack && (
          <BackButton />
        )}
        <h3 className={styles.title}>{title}</h3>
      </div>
    </div>
  )
}
