import Input from "components/UI/Fields/Input";
import Select from 'components/UI/Fields/Select';
import TypeProfileSelect from "components/UI/Forms/RegForm/TypeProfileSelect";
import React, { Component } from 'react';
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import { change, Field, reduxForm, reset, stopSubmit, formValueSelector } from 'redux-form';
import Region from 'components/UI/Forms/GroupFields/Region';
import Industry from 'components/UI/Forms/GroupFields/Industry';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Language from "language/index";
import { Button } from 'components/v1/Button';

import styles from 'pages/Contacts/styles.module.scss'

class SearchFormRedux extends Component {

  state = {
    params: {
      typeId: '',
      value: {
        min: 18,
        max: 100
      },
      input: {
        min: 18,
        max: 100
      }
    },
  };


  changeValue = values => {


    this.setState({ params: { ...this.state.params, value: { ...values } } });
    this.setValue(parseInt(values.min), 'yearBegin');
    this.setValue(parseInt(values.max), 'yearEnd');
  };

  setValue = (values, nameField) => {
    this.props.dispatch(change('searchUserForm', nameField, values));
  };

  resetForm = () => {
    this.props.dispatch(reset('searchUserForm'));
    // this.props.resetSearch();
  };

  isClearly = () => {
    return Object.keys(this.props.values).length !== 0;
  };

  componentDidMount() {
    this.setValue(18, 'yearBegin');
    this.setValue(100, 'yearEnd');
  }

  changeValueInput = (e, name) => {

    if (parseInt(e) < 18) {
      e = 18;
    } else if (parseInt(e) > 100) {
      e = 100;
    }

    this.setState({
      params: {
        ...this.state.params,
        value: {
          ...this.state.params.value,
          [name]: parseInt(e)
        }
      }
    })
  };


  render() {

    const { handleSubmit, isAdditionalSearch } = this.props,
      isClearly = this.isClearly();

    return (
      <div className="form">
        <form onSubmit={handleSubmit}>
          <Field
            name="typeKeys"
            component={Select}
            label={`${Language.userCategory}`}
            placeholder={Language.ChooseMemberType}
            className={`element selectReq`}
            options={TypeProfileSelect}
          />
          <Region
            {...this.props.initialValues}
            countryLabel={Language.Country}
            regionLabel={Language.regionResidence}
            cityLabel={Language.cityResidence}
            form="searchUserForm"
            dispatch={this.props.dispatch}
          />
          <Industry
            {...this.props.initialValues}
            label={`${Language.industry}`}
          />

          {
            isAdditionalSearch &&
            <React.Fragment>
              {/*<Field*/}
              {/*  name="hobby"*/}
              {/*  component={Select}*/}
              {/*  label={Language.hobby}*/}
              {/*  placeholder={Language.chooseHobby}*/}
              {/*  options={hobbies}*/}
              {/*/>*/}
              <div className={styles.filterRow}>
                <Field
                  name="yearBegin"
                  component={Input}
                  label={`${Language.age}`}
                  className={`element selectReq w45`}
                  change={e => this.changeValueInput(e, 'min')}
                />
                <div className="element__line">

                </div>
                <Field
                  name="yearEnd"
                  component={Input}
                  className={`element selectReq w45 mt14`}
                  change={e => this.changeValueInput(e, 'max')}
                />
              </div>

              {/*<div className="input_range_container">*/}
              {/*  <InputRange*/}
              {/*    minValue={18}*/}
              {/*    value={this.state.params.value}*/}
              {/*    onChange={this.changeValue}*/}
              {/*    maxValue={100}*/}
              {/*  />*/}
              {/*</div>*/}

            </React.Fragment>
          }

          <div>
            <Button type="submit" style={{ width: '100%' }}>
              {Language.find}
            </Button>
            <Button
              variant="outlined"
              type="button"
              className="reset"
              onClick={this.resetForm}
              style={{ display: isClearly ? 'block' : 'none', width: '100%', marginTop: '15px' }}
            >
              {Language.Reset}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('searchUserForm');

export default compose(
  reduxForm({
    form: 'searchUserForm',
    onChange: (values, dispatch, props) => {
      console.log(values, 'values');
      if (props.submitFailed) {
        dispatch(stopSubmit('searchUserForm'));
      }
    }
  }),
  connect(
    state => ({
      values: selector(state, 'typeKeys', 'address', 'industry', 'yearBegin', 'yearEnd'),
    })
  )
)(SearchFormRedux);
