import IndustryData from "components/UI/Forms/GroupFields/IndustryData";
import Language from "../language/index";
import React from 'react';
import { FormatCurrency } from "libs/formatNumbers";


export const declOfNum = (number, titles) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
};

export const GetCurrencyValue = currencyObj => {
  let currency = '';
  switch (currencyObj.currency) {
    case 'rub':
      currency = declOfNum(currencyObj.amount, ['рубль','рубля','рублей']);
      break;
    case 'dollar':
      currency = '$';
      break;
    case 'eur':
      currency = '€';
      break;
    case 'percent':
      currency = '%';
      break;
    default:
      currency = '';
      break;
  }
  if (currencyObj.amount === '') return null;
  return `${FormatCurrency(currencyObj.amount)} ${currency}`;
};


export const GetPeriodValue = ({ periodObj }) => {
  let time = periodObj.typeDate === 'month' ?
    declOfNum(periodObj.count, ['месяц','месяца', 'месяцев']) :
    declOfNum(periodObj.count, ['год','года', 'лет']) ;
  return `${periodObj.count} ${time}`;
};

export const GetDeliveryValue = currencyObj => {
  return `${currencyObj.maximumDeliveryVolume} ${Language[currencyObj.variableUnit]}`;
};

export const GetExperienceValue = experienceObj => {
  return experienceObj === 'true' ? Language.participationProjects : Language.FirstTime;
};

export const GetImplementedValue = implementedObj => {
  return String(implementedObj) === 'true' ? Language.yes : Language.no;
};

export const GetSalaryValue = currencyObj => {
  let payType = '';
  switch (currencyObj.paymentType) {
    case 'час':
      payType = Language.Day;
      break;
    case 'день':
      payType = Language.Week;
      break;
    case 'неделя':
      payType = Language.Week;
      break;
    case 'месяц':
      payType = Language.Month;
      break;
    case 'год':
      payType = Language.Year;
      break;
    default:
      payType = null;
      break;
  }
  if (currencyObj.amount === '') return null;
  return `${GetCurrencyValue(currencyObj.salary)} ${payType !== null ? `/ ${payType}` : ''}`;
};

export const GetIndustry = industry => {
  if (typeof(industry) === 'object') {
    let groupName = null,
      name = null;
    IndustryData.all_industries.map(el => {
      if (el.value === industry.groupName) groupName = el;
    });
    if (industry.name) {
      IndustryData[`_${groupName.value}`].map(el => {
        if (el.value === industry.name) {
          name = el
        }
      })
    }
    return `${groupName !== null ? groupName.label : ''} ${name !== null ? `<br/> ${name.label}` : ''}`;
  }
};

export const GetShortIndustry = industry => {
  if (typeof(industry) === 'object') {
    let groupName = null,
      name = null;
    IndustryData.all_industries.map(el => {
      if (el.value === industry.groupName) groupName = el;
    });
    if (industry.name) {
      IndustryData[`_${groupName.value}`].map(el => {
        if (el.value === industry.name) {
          name = el
        }
      })
    }
    return `${groupName !== null ? groupName.label : ''}`;
  }
};
