import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import React from 'react';
import { Field } from "redux-form";

class TimePeriod extends React.Component {
    render() {
        const { label, placeholder, name } = this.props;
        return (
            <div className="element">
                <div className="row clearfix">
                    <Field
                        className="col-10"
                        name={`${name}[count]`}
                        type="number"
                        component={Input}
                        label={label}
                        placeholder={placeholder}
                    />
                    <Field
                        className="col-2"
                        name={`${name}[typeDate]`}
                        type="text"
                        component={Select}
                        options={[
                            { value: 'month', label: 'месяц/ев' },
                            { value: 'year', label: 'лет/год' },
                        ]}
                    />
                </div>
            </div>
        )
    }
}


export default TimePeriod;