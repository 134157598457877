import React from 'react';
import axios from "axios";
import jsonpAdapter from 'axios-jsonp';
import { getItem } from "libs/localStorage";

const lang = getItem('language', 'ru');

// TODO: Переделать компонент ( взят со старой версии NN)

export default class MappingLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataRegion: '',
            dataCountry: '',
            dataCity: '',
        };
        this.mappingRegion = this.mappingRegion.bind(this);
    }

    mappingRegion(items) {
        items.forEach(el => {
            if (el.id === this.props.location.stateId) {
                this.setState({
                    dataRegion: el.title,
                })
            }
        })
    }

    getLocation = () => {
        if (this.props.location === undefined) return;
        if(this.props.location.countryId === 0) return;

        axios({
            url: `https://api.vk.com/method/database.getCountriesById?country_ids=${this.props.location.countryId}&need_all=1&lang=${lang}&count=236&v=5.92&access_token=a866b793a866b793a866b793c8a80473bdaa866a866b793f297308c31df4d5b2ba35960`,
            method: 'GET',
            adapter: jsonpAdapter
        }).then((response) => {
            if (response.data && response.data.response && response.data.response.length > 0) {
                this.setState({
                    dataCountry: response.data.response[0].title
                })
            }
        });

        axios({
            url: `https://api.vk.com/method/database.getRegions?country_id=${this.props.location.countryId}&lang=${lang}&v=5.92&access_token=a866b793a866b793a866b793c8a80473bdaa866a866b793f297308c31df4d5b2ba35960`,
            method: 'GET',
            adapter: jsonpAdapter
        }).then((response) => {
            if (response.data && response.data.response && response.data.response.count > 0) {
                this.mappingRegion(response.data.response.items);
            }
        });

        axios({
            url: `https://api.vk.com/method/database.getCitiesById?city_ids=${this.props.location.cityId}&lang=${lang}&v=5.92&access_token=a866b793a866b793a866b793c8a80473bdaa866a866b793f297308c31df4d5b2ba35960`,
            method: 'GET',
            adapter: jsonpAdapter
        }).then((response) => {
            if (response.data && response.data.response && response.data.response.length > 0) {
                this.setState({
                    dataCity: response.data.response[0].title
                })
            }
        });
    };

    componentWillMount() {
        this.getLocation();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.getLocation();
        }
    }


    render() {
        const { dataCountry, dataRegion, dataCity } = this.state;
        return (
            <React.Fragment>
                {
                    lang === 'ru' &&
                    <React.Fragment>
                        {
                            dataCountry.length > 0 && dataRegion.length === 0 && dataCity.length === 0 &&
                            <React.Fragment>
                                {dataCountry}
                            </React.Fragment>
                        }
                        {
                            dataCountry.length > 0 && dataRegion.length > 0 && dataCity.length === 0 &&
                            <React.Fragment>
                                {dataCountry + ', ' + dataRegion}
                            </React.Fragment>
                        }
                        {
                            dataCountry.length > 0 && dataRegion.length === 0 && dataCity.length > 0 &&
                            <React.Fragment>
                                {dataCountry + ', ' + dataCity}
                            </React.Fragment>
                        }
                        {
                            dataCountry.length > 0 && dataRegion.length > 0 && dataCity.length > 0 &&
                            <React.Fragment>
                                {dataCountry + ', ' + dataRegion + ', ' + dataCity}
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {
                    lang === 'en' &&
                    <React.Fragment>
                        {
                            dataCountry.length > 0 && dataRegion.length === 0 && dataCity.length === 0 &&
                            <React.Fragment>
                                {dataCountry}
                            </React.Fragment>
                        }
                        {
                            dataCountry.length > 0 && dataRegion.length > 0 && dataCity.length === 0 &&
                            <React.Fragment>
                                {dataCountry}
                            </React.Fragment>
                        }
                        {
                            dataCountry.length > 0 && dataRegion.length === 0 && dataCity.length > 0 &&
                            <React.Fragment>
                                {dataCountry + ', ' + dataCity}
                            </React.Fragment>
                        }
                        {
                            dataCountry.length > 0 && dataRegion.length > 0 && dataCity.length > 0 &&
                            <React.Fragment>
                                {dataCountry + ', ' + dataCity}
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}