import React from 'react'
import { useForm } from 'react-hook-form';

import { InputController } from 'components/v1/HookFields/Input';

import styles from '../EditForms/styles.module.scss'
import { Button } from 'components/v1/Button';
import Language from 'language';
import { SET_PASSWORD } from 'mutation';
import { ActionNotification } from 'components/UI/Notifications/action';
import { Mutation } from 'react-apollo';
import { rules, required } from 'libs/rules';
import { passwordConfirm } from 'libs/fieldValidation';


const ChangePassword = ({ mutate }) => {

  const { control, handleSubmit, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    }
  })

  const onSubmit = async (data) => {
    mutate({ variables: data }).then(res => {
      ActionNotification('2', 'Пароль успешно изменен');
      reset()
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/*<div className={styles.section}>*/}
      {/*  <div>Старый пароль</div>*/}
      {/*  <InputController*/}
      {/*    control={control}*/}
      {/*    name="oldPassword"*/}
      {/*    type="password"*/}
      {/*  />*/}
      {/*</div>*/}
      <div className={styles.section}>
        <div>Новый пароль</div>
        <InputController
          control={control}
          name="password"
          type="password"
          rules={rules.passwords}
          errors={errors.password}
        />
      </div>
      <div className={styles.section}>
        <div>{Language.confirmPassword}</div>
        <InputController
          control={control}
          name="repeatPassword"
          type="password"
          rules={{
            required,
            validate: {
              passwordConfirm: (val) => {
                return passwordConfirm(watch('password'), val)
              }
            }
          }}
          errors={errors.repeatPassword}
        />
      </div>
      <div className="separator" />
      <Button type="submit">
        {Language.save}
      </Button>
    </form>
  )
}

export const ChangePasswordForm = () => {
  return (
    <Mutation
      mutation={SET_PASSWORD}
      onCompleted={() => ActionNotification('2', 'Пароль успешно изменен')}
    >
      {mutate => (
        <ChangePassword mutate={mutate} />
      )}
    </Mutation>
  )
}
