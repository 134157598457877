import SbContactsList from "components/UI/SbContacts/SbContactsList";
import SideBlock from "components/UI/SideBlock/index";
import { reccomendType, userTypeDesc } from "libs/uaerTypes";
import { GET_RECCOMEND_USER, GET_RECCOMEND_USER_BY_USER_TYPE } from "query/reccomendUser";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import { GET_NEWS } from "query/news";
import { GET_CONTACTS } from "query/contacts";



class Profile extends Component {

    state = {
        tabs: [],
        activeTab: 0
    };
    changeTab = index => {
        this.setState({ activeTab: index });
        let test = this.state.tabs[index];
    };

    componentDidMount() {
        const myType = 'CoInvestor';
        let tabs = reccomendType(myType).map(el => ({ title: userTypeDesc(el), type: el }));
        this.setState({ tabs });
    }

    render() {
        const isTabs = this.state.tabs.length > 0;
        return (
            <Query query={GET_RECCOMEND_USER} variables={{ userType: "Investor" }}>
                {({ data, loading }) => {
                    if (loading) return 'Loading...';  // TODO: сделать прелоудер
                    return (
                        <SideBlock
                            count={data.UserGetter.getRecommendedUser.total}
                            title="Могут заитересовать"
                        >
                            <SbContactsList users={data.UserGetter.getRecommendedUser.users}/>
                        </SideBlock>
                    )
                }}
            </Query>
        )
    }
}


export default Profile;