import SearchRequest from "components/UI/Forms/SearchRequest/SearchRequest";
import SideBlockTemporary from "components/UI/Requests/SideBlockTemporary";
import SideBlock from "components/UI/SideBlock/index";
import Language from "language/index";
import { GET_MY_PROFILE } from "query/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import ApplicationList from "../../UI/Requests/RequestApplicationList";


class AdminRequests extends Component {

    state = {
        variables: {}
    };

    search = values => {
        let value = { typeOffer: values.typeOffer };

        if (values.hasOwnProperty('industry')) {
            if (values.industry.groupName) value.industryGroupName = values.industry.groupName;
            if (values.industry.name) value.industryName = values.industry.name;
        }

        if (values.hasOwnProperty('address')) {
            if (values.address.countryId) value.countryId = values.address.countryId;
            if (values.address.stateId) value.stateId = values.address.stateId;
            if (values.address.cityId) value.cityId = values.address.cityId;
        }

        this.setState({ variables: value })
    };

    render() {
        return (
            <React.Fragment>
                <div className="centerside">
                    {/*<SideBlock title="Заявки для администрирования"/>*/}
                    <ApplicationList isMy admin variables={this.state.variables}/>
                </div>
                <div className="rightside">
                    <SideBlock>
                        <div className="pickup">
                            <div className="sb-title">
                                <span>{Language.applicationSearch}</span>
                            </div>
                            <SearchRequest
                                onSubmit={this.search}
                                resetSearch={() => this.setState({ variables: {} })}
                            />
                        </div>
                    </SideBlock>
                </div>
            </React.Fragment>
        )
    }
}


export default props => {
    return (
        <Query query={GET_MY_PROFILE} fetchPolicy="cache-only">
            {({ data, loading, error, client }) => {
                if (error || loading) return 'Loading...';
                if (data.ProfileGetter) {
                    return <AdminRequests client={client} {...props} {...data.ProfileGetter}/>
                } else return 'Loading...'
            }}
        </Query>
    )
};