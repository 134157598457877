import gql from 'graphql-tag';

export const GET_NEWS = gql
  `query($page: Int, $count: Int) {
    NewsGetter {
        getNews(page: $page, count: $count) {
            news {
                ... on NewsUser {
                    _id
                    newsType
                    userFrom {
                        _id
                        firstName
                        lastName
                        avatar
                        typeKeys
                    }
                    youWatched
                    countWatched
                    createDate
                    __typename
                }

                ... on NewsContact {
                    _id
                    newsType
                    userFrom {
                        _id
                        firstName
                        lastName
                        avatar
                        typeKeys
                    }
                    userTo {
                        _id
                        firstName
                        lastName
                        typeKeys
                    }
                    youWatched
                    countWatched
                    createDate
                    __typename
                }
#
                ... on NewsOffer {
                    _id
                    newsType
                    userFrom {
                        _id
                        firstName
                        lastName
                        avatar
                        typeKeys
                    }
                    offer {
                        _id
                        status
                        typeOffer
                    }
                    youWatched
                    countWatched
                    createDate
                    __typename
                }
#
                ... on NewsSystem {
                    _id
                    content
                    newsType
                    content
                    youWatched
                    countWatched
                    createDate
                    __typename
                }
            }
            total
        }
    }
}
`;

export const GET_USER_NEWS = gql
        `query($feedType: NewsFeedTypeEnum, $page: Int, $count: Int, $userId: String) {
        UserNewsGetter {
            getNews(page: $page, count: $count, feedType: $feedType, userId: $userId) {
                news {
                    _id
                    title
                    content
                    createDate
                    userFrom {
                        _id
                        __typename
                        firstName
                        lastName
                        avatar
                    }
                }
                total
            }
        }
    }
    `;

export const GET_COMMENTS = gql
    `query ($page: Int, $count: Int, $newsId: String!) {
        UserNewsGetter {
            getNewsComments(page: $page, count: $count, newsId: $newsId) {
                comments {
                    _id
                    message
                    createDate
                    userFrom {
                        ... on User {
                            _id
                            firstName
                            lastName
                            avatar
                        }
                    }
                    userTo {
                        ... on User {
                            _id
                            firstName
                            lastName
                            avatar
                        }
                    }
                    youLiked
                    countLiked
                }
                total
            }
        }
    }`
