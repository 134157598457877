import React, { useEffect, useState } from 'react';

import { Avatar } from 'components/v1/Avatar';
import { Tabs } from 'components/v1/Tabs';
import MappingLocation from 'libs/MappingLocation';
import { declOfNum, GetIndustry, GetShortIndustry } from 'libs/getApplicationFields';

import { TABS_ITEMS, TABS_ITEMS_COMPANY } from './constants';
import { Actions } from './Actions';
import classes from './styles.module.scss';
import { UserTypeBadge } from 'components/v1/UserTypeBadge';
import Language from 'language';
import { userTypesClassName } from 'libs/uaerTypes';
import { Button } from 'components/v1/Button';
import { EditIcon } from 'components/v1/Icons/EditIcon';
import { Link } from 'react-router-dom';
import { getAge } from 'libs/getAge';
import { dateToString } from 'libs/convertDate';
import { useSizeSM } from 'libs/useMediaQuery';

export const ProfileInfo = ({ user, isUser = false, onTabChange, isMyPage }) => {

  const isMobile = useSizeSM()

  const { avatar, firstName, lastName, phone, email, address, site, birthday, industry, termOfWork } = user;
  const [activeTab, setActiveTab] = useState(TABS_ITEMS[0].value);

  useEffect(() => {
    onTabChange && onTabChange(activeTab);
  }, [activeTab]);

  const isEntityProfile = user.__typename === "EntityProfile"
  let currentTabItems = isEntityProfile ? TABS_ITEMS_COMPANY : TABS_ITEMS;

  if (isMobile) {
    currentTabItems = currentTabItems.filter(({ value }) => ['news', 'request'].includes(value))
  }

  const url = site && typeof site === 'string' ? site.replace('https://', '').replace('http://', '') : false

  return (
    <div className={classes.root}>
      <div className={classes.profile}>
        {!isMobile && (
          <div className={classes.left}>
            <Avatar source={avatar || '/templates/img/uploader_default.jpg'}/>
            {isUser && (
              <Actions id={user._id} isContacts={user.isContacts} />
            )}
          </div>
        )}
        <div className={classes.right}>
          <div className={classes.header}>
            {isMobile && (
              <Avatar source={avatar || '/templates/img/uploader_default.jpg'}/>
            )}
            <div className={classes.withAvatar}>
              {user.companyName && <div className={classes.companyName}>{user.companyName}</div>}
              <div className={classes.name}>
                {firstName} {lastName}
              </div>
              <div className={classes.typeKey}>
                {Array.isArray(user.typeKeys) && user.typeKeys.length > 0 && (
                  <UserTypeBadge name={Language[userTypesClassName(user.typeKeys[0])]} />
                )}
              </div>
            </div>
            {isMyPage && !isMobile && (
              <Link to={'/profile/edit'} className={classes.edit}>
                <Button icon={<EditIcon />} variant="outlined" />
              </Link>
            )}
          </div>
          {isMobile && isUser && (
            <Actions id={user._id} isContacts={user.isContacts} />
          )}
          <div className={classes.info}>
            {/*<div className={classes.item}>*/}
            {/*  <span> Телефон </span> {phone || '+7 900 000-00-00'}*/}
            {/*</div>*/}
            {isEntityProfile && industry && (
              <div className={classes.item}>
                <span> Отрасль </span> <div dangerouslySetInnerHTML={{__html: GetIndustry(industry)}}/>
              </div>
            )}
            {birthday && (
              <div className={classes.item}>
                <span> <div style={{ paddingRight: 11 }}>День рождения</div> </span> {dateToString(new Date(birthday), 'stringFullDate')}
                {new Date(birthday).getFullYear() < new Date(Date.now()).getFullYear()
                  ? ` ${new Date(birthday).getFullYear()}`
                  : ''
                } ({getAge(birthday) || '-'})
              </div>
            )}
            <div className={classes.item}>
              <span> E-mail </span> {email}
            </div>
            <div className={classes.item}>
              <span> Регион </span> <MappingLocation location={{ ...address }}/>
            </div>
            {url && (
              <div className={classes.item}>
                <span> Сайт </span> <noindex><a href={`https://${url}`} rel="nofollow" target="_blank">{url}</a></noindex>
              </div>
            )}
            {!isEntityProfile && industry && (
              <div className={classes.item}>
                <span> Направление <br/> деятельности </span> {GetShortIndustry(industry)}
              </div>
            )}
            {isEntityProfile && termOfWork &&
              <div className={classes.item}>
                <span> Срок работы <br/> на рынке </span>
                {`${termOfWork.count} ${termOfWork.typeDate === 'month' ? declOfNum(termOfWork.count, ['месяц','месяца', 'месяцев']) :
                  declOfNum(termOfWork.count, ['год','года', 'лет'])}`}
              </div>
            }
          </div>
        </div>
      </div>
      <div className={classes.tabs}>
        <Tabs items={currentTabItems} activeTab={activeTab} onChangeTab={setActiveTab} />
      </div>
    </div>
  )
}
