export default `

type Notification {
      type: String
      text: String
      isOpen: Boolean
  }

  type Query {
    isAuth: Boolean
    userTypes: [UserType]
    counter: Int
    notification: Notification
  }
  
  type UserType {
      value: String!
      label: String!
  }
  
  
`;