import { useEffect, useRef } from 'react';
import { change, Field, reduxForm, stopSubmit } from 'redux-form';
import { Mutation } from 'react-apollo';
import { REGISTRATION } from 'mutation';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import Language from 'language';

import { getItem } from 'libs/localStorage';
import { Button } from 'components/v1/Button';
import { submitRegForm } from 'components/UI/Forms/RegForm/submit';
import Input from 'components/UI/Fields/Input';
import Select from 'components/UI/Fields/Select';
import TypeProfileSelect from 'components/UI/Forms/RegForm/TypeProfileSelect';

import classes from './styles.module.scss';

export const RegForm = ({ handleSubmit, dispatch }) => {
  const captcha = useRef();


  const onLoadRecaptcha = () => {
    if (captcha.current) {
      captcha.current.reset();
    }
  };

  useEffect(() => {
    loadReCaptcha();

    if (captcha.current) {
      captcha.current.reset();
    }
  }, [])


  const handleCaptcha = value => {
    dispatch(change('registration', 'captcha', value))
  };

  return (
    <div className={classes.block}>
      <div className={classes.title}> Регистрация</div>
      <form onSubmit={handleSubmit}>
        <Field
          name="typeKeys"
          component={Select}
          change={options => this.setState({ typeUser: options.value })}
          label={Language.userCategory}
          placeholder={Language.userCategoryss}
          className={`element selectReq ${classes.field}`}
          options={TypeProfileSelect}

        />
        <Field
          name="firstName"
          component={Input}
          label={Language.firstName}
          className={classes.field}
        />
        <Field
          name="lastName"
          component={Input}
          label={Language.lastName}
          className={classes.field}
        />
        <Field
          name="phone"
          component={Input}
          label={Language.phone}
          mask="+7 (999) 999-99-99"
          className={classes.field}
        />
        <div className={classes.recaptcha}>
          <ReCaptcha
            ref={captcha}
            hl={getItem('language', 'ru')}
            size="normal"
            render="explicit"
            sitekey="6LdcFbkUAAAAAIwbZGXl_UQwVDWmuxNjjIcY5Bb8"
            onloadCallback={onLoadRecaptcha}
            verifyCallback={handleCaptcha}
          />
        </div>
        <Button className={classes.button} type="submit">Продолжить</Button>
      </form>

      <div className={classes.confident}>
        {Language.clickRegister.text_1}
        {Language.clickRegister.link_1}, &nbsp;
        {/*<a target="_blank" href="/">{Language.clickRegister.link_1}</a>, &nbsp;*/}
        <a title={Language.UsersPersonalPrivacy} target="_blank" href="/privacy">{Language.clickRegister.link_2}</a>
        {Language.clickRegister.text_2}
        <a title={Language.TermsUseCookies} target="_blank" href="/cookie">{Language.clickRegister.link_3}</a>

        {Language.clickRegister.text_3}
      </div>
    </div>
  )
}

const RegFormRedux = reduxForm({
  form: 'registration',
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(stopSubmit('registration'));
    }
  }
})(RegForm);

export const RegistrationForm = props => {
  return (
    <Mutation
      mutation={REGISTRATION}
      onCompleted={() => props.nextStep()}
      onError={(err) => console.log(err)}
    >
      {(mutate, { data }) => (
        <RegFormRedux
          {...props}
          {...data}
          onSubmit={values => submitRegForm(values, mutate)}
        />
      )}
    </Mutation>
  )
};
