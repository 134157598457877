import DatePicker from "components/UI/Fields/DatePicker";
import Language from "language/index";
import React, { Component } from 'react';
import 'react-input-range/lib/css/index.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm, reset, stopSubmit } from 'redux-form';


class SearchStatisticUsersAdmin extends Component {

    setValue = (values, nameField) => {
        this.props.dispatch(change('searchStatisticUsersAdmin', nameField, values));
    };

    resetForm = () => {
        this.props.dispatch(reset('searchStatisticUsersAdmin'));
        this.props.resetSearch();
    };

    isClearly = () => {
        return Object.keys(this.props.values).length !== 0;
    };


    render() {

        const { handleSubmit } = this.props,
            isClearly = this.isClearly();

        return (
            <div className="form">
                <form onSubmit={handleSubmit}>

                    <Field
                        name="beginDate"
                        component={DatePicker}
                        label="Дата регистрации от:"
                        className={`element datePickerInput`}
                    />

                    <Field
                        name="endDate"
                        component={DatePicker}
                        label="Дата регистрации до:"
                        className={`element datePickerInput`}
                    />

                    <div className="flex">
                        <button type="submit" className="orange-btn search_users">{Language.find}</button>
                        <button
                            type="button"
                            className="reset"
                            onClick={this.resetForm}
                            style={{ display: isClearly ? 'block' : 'none' }}
                        >
                            {Language.Reset}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}


const selector = formValueSelector('searchStatisticUsersAdmin');

export default compose(
    reduxForm({
        form: 'searchStatisticUsersAdmin',
        onChange: (values, dispatch, props) => {
            if (props.submitFailed) {
                dispatch(stopSubmit('searchStatisticUsersAdmin'));
            }
        }
    }),
    connect(
        state => ({
            values: selector(state, 'beginDate', 'endDate'),
        })
    )
)(SearchStatisticUsersAdmin);