import Input from "components/UI/Fields/Input";
import Industry from "components/UI/Forms/GroupFields/Industry";
import Money from "components/UI/Forms/GroupFields/Money";
import Region from "components/UI/Forms/GroupFields/Region";
import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from "redux-form";
import { Mutation } from "react-apollo";
import { SET_BUSSINES_PRODUCT } from "mutation/offer";
import { submitBussinesManForm } from "./submit";
import { GET_OFFERS_LIST } from "query/offers";
import Language from "language/index";
import { Button } from 'components/v1/Button';


class BusinessmanForm extends Component {

    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return (
            <div className="form fullWidth">
                <form className="new_application" id="new_request_business" onSubmit={handleSubmit}>
                    <Field
                        name="title"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.naming} *`}
                    />
                    <Field
                        name="description"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.Description} *`}
                    />
                    <Industry
                        isReq
                        label={Language.industry}
                        {...initialValues}
                    />
                    <Region
                      countryLabel={`${Language.Country} *`}
                      regionLabel={`${Language.regionResidence} *`}
                      {...initialValues}
                    />
                    <Money
                        nameField="profitability"
                        label={`${Language.ReturnsInvestment} *`}
                    />
                    <Field
                        name="additionallyInform"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={Language.additionalInformationBlock}
                    />
                    <Button type="submit" disabled={loading} className="orange-btn"> {Language.save} </Button>

                </form>
            </div>
        );
    }
}


BusinessmanForm = reduxForm({
    form: 'businessmanForm',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('businessmanForm'));
        }
    }
})(BusinessmanForm);


export default props => {
  const initialValues = Boolean(Object.keys(props.initialValues).length) ? props.initialValues : {
    profitability: {
      currency: 'rub'
    }
  };
    return (
      <Mutation mutation={SET_BUSSINES_PRODUCT} refetchQueries={[{query: GET_OFFERS_LIST, variables: { isMy: true}}]}>
        {(mutate,{ loading }) => (
          <BusinessmanForm   {...props} initialValues={initialValues} loading={loading}
                        onSubmit={values => submitBussinesManForm(values, mutate, props.cb)}/>
        )}
      </Mutation>
    )
};
