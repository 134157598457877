import UserDescAdditionalData from "components/UI/Profile/UserDescAdditionalData";
import UserDescMenu from "components/UI/Profile/UserDescMenu";
import Language from "language/index";
import { getAge } from "libs/getAge";
import MappingLocation from "libs/MappingLocation";
import React, { PureComponent } from 'react';

const MoreInfo = ({ birthday, address }) => {
    return (
        <div className="user-more-info">
            {birthday && `${getAge(birthday)}, `}
            <MappingLocation location={{ ...address }}/>
        </div>
    )
};

const BlackListInfo = () => {
    return (
        <div className="blocked_user_profile"
             style={{ marginTop: '20px', color: 'rgb(161, 161, 161)' }}>
            {Language.RestrictedAccess}
        </div>
    )
};


class UserDescription extends PureComponent {
    render() {
        const { birthday, lastName, firstName, address, isContacts, notMy, __typename, companyName, isLockedYou, _id, onlineStatus, isLockedUser } = this.props;

        if (isLockedUser) {
            return (
                <div className="user-desc">
                    <div className="user-desc-name">
                        {lastName} {firstName}
                    </div>
                    <BlackListInfo/>
                </div>
            )
        }

        return (
            <div className="user-desc">
                {
                    notMy &&
                    <UserDescMenu isLockedYou={isLockedYou} _id={_id}/>
                }
                {
                    __typename === 'EntityProfile' &&
                    <React.Fragment>
                        <div className="user-desc-name">
                            {onlineStatus && onlineStatus.isOnline && <i className="online"/>}
                            {companyName}
                        </div>
                        <div className="user-more-info"> {lastName} {firstName} </div>
                    </React.Fragment>
                }
                {
                    __typename !== 'EntityProfile' &&

                    <div className="user-desc-name">
                        {onlineStatus && onlineStatus.isOnline && <i className="online"/>}
                        {lastName} {firstName}
                    </div>
                }

                <MoreInfo
                    birthday={birthday}
                    address={address}
                />
                <UserDescAdditionalData lastName={lastName} {...this.props}/>
            </div>
        )
    }
}


export default UserDescription;