import Loading from "components/UI/Prealoders/Loading";
import SideBlock from "components/UI/SideBlock/index";
import TContactsItem from "components/UI/TContacts/TContactsItem";
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Query } from "react-apollo";
import { GET_DIALOGS } from "query/dialogs";
import { GET_MY_ID } from "query/profile";
import Language from "language/index";


class FavoritesDialogs extends PureComponent {

    static propTypes = {
        getMyDialogs: PropTypes.object,
        loading: PropTypes.bool,
        _id: PropTypes.string
    };

    static defaultProps = {
        getMyDialogs: { dialogs: [] },
        loading: false,
        _id: ''
    };


    render() {
        const { loading } = this.props,
            { _id } = this.props.getMyProfile,
            isDialogs = this.props.getMyDialogs.dialogs.length > 0;

        return (
            <React.Fragment>
                <SideBlock title={<em className="featured-title">Избранные</em>}>
                    {
                        loading ?
                            <Loading/> :
                            <div className="t-contacts featured">
                                {
                                    isDialogs &&
                                    this.props.getMyDialogs.dialogs.map((el, index) => {
                                        let user = el.userFrom._id === _id ? el.userTo : el.userFrom;
                                        return (
                                            <TContactsItem key={index} dialogId={el._id} onlineStatus={{}}  {...user}/>
                                        )
                                    })
                                }

                                {
                                    !isDialogs &&
                                    <p style={{ paddingTop: '5px' }}> {Language.nothingFound} </p>
                                }
                              {/*<div className="view-all">*/}
                              {/*<a href="#">Еще 26 контактов</a>*/}
                              {/*</div>*/}
                            </div>
                    }
                </SideBlock>
            </React.Fragment>
        );
    }
}


export default props => {
    return (
        <Query query={GET_DIALOGS} variables={{ "favoriteDialog": true }}>
            {({ loading, data: { DialogGetter }, client, error }) => {
                if (error) return <p> Error.... </p>;
                let { ProfileGetter } = client.cache.readQuery({ query: GET_MY_ID });
                return (
                    <FavoritesDialogs loading={loading} {...DialogGetter} {...ProfileGetter} {...props}/>
                )
            }}
        </Query>
    )
}