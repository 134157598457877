import React, { Component } from 'react';
import { Mutation } from "react-apollo";
import { CANCEL_NOTIFICATION } from "mutation/profile";
import { ActionNotification } from "components/UI/Notifications/action";
import { Link } from "react-router-dom";
import NotFound from "components/UI/NotFound/index";


class Subscribtions extends Component {

    componentDidMount() {
        this.props.mutate();
    }

    render() {
        return (
            <div className="centerside">

                <NotFound title="Вы успешно отменили подписку">
                    Вы больше не будете получать сообщения по электронной почте от newnext о новостях связанных с вашим профилем.

                    <Link style={{ marginTop: '35px', display: 'block', width: 'max-content' }} className="gray-btn new-request-js active" to="/">Перейти на главную</Link>
                </NotFound>
            </div>
        )
    }
}


export default props => {
    return (
        <Mutation
            mutation={CANCEL_NOTIFICATION}
            variables={{...props.match.params}}
        >
            {mutate => (
                <Subscribtions
                    {...props}
                    mutate={mutate}
                />
            )}
        </Mutation>
    )
};