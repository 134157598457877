import { userTypeDesc, userTypesClassName } from "libs/uaerTypes";
import React, { Component } from 'react';


class ContactStatus extends Component {
    render() {
        const { className, type, isSpan } = this.props;
        return (
            <div className={`${className} ${userTypesClassName(type)}`}>
                {
                    isSpan && <span>{ userTypeDesc(type) }</span>
                }
                {
                    !isSpan &&
                    userTypeDesc(type)
                }
            </div>
        )
    }
}


export default ContactStatus;