import React from 'react'
import { useForm } from 'react-hook-form';
import { GET_NOTIFICATION_SUBSCRIBE } from 'query/profile';

import { CheckboxController } from 'components/v1/HookFields/Checkbox';
import { Button } from 'components/v1/Button';

import styles from '../EditForms/styles.module.scss'
import { Query } from 'react-apollo';
import { client } from 'apollo';
import { SET_NOTIFICATION_STATUS } from 'mutation/profile';


const Subscriptions = ({ getNotification }) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      getNotification,
    }
  })

  const toggleChecked = (value) => {
    client.mutate({ mutation: SET_NOTIFICATION_STATUS, variables: { notification: value } })
      .catch(() => {
        setValue('getNotification', !value)
      })
  }

  const onSubmit = (data) => {
    console.log(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.checkbox}>
        <CheckboxController
          control={control}
          name="getNotification"
          label={'Подписаться на E-mail уведомления'}
          onChange={(value) => toggleChecked(value)}
        />
      </div>
      {/*<CheckboxController*/}
      {/*  control={control}*/}
      {/*  name="123321"*/}
      {/*  label={'Подписаться на SMS уведомления'}*/}
      {/*/>*/}
      {/*<div className="separator" />*/}
      {/*<Button type="submit">*/}
      {/*  Сохранить*/}
      {/*</Button>*/}
    </form>
  )
}

export const SubscriptionsForm = ({}) => {
  return (
    <Query query={GET_NOTIFICATION_SUBSCRIBE} fetchPolicy={'cache-and-network'}>
      {({ loading, error, data }) => {
        if (error) return null;
        if ((error || loading) && !data.ProfileGetter) return null;
        return <Subscriptions {...data.ProfileGetter} />
      }}
    </Query>
  )
}
