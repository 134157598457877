import gql from 'graphql-tag';

export const GET_AUTH = gql`
    {
        isAuth @client
    }
`;

export const GET_MY_PROFILE = gql`
    {
        ProfileGetter {
            getMyProfile {
                ... on EntityProfile {
                    _id
                    firstName
                    lastName
                    avatar
                    phone
                    typeKeys
                    industry {
                        groupName
                        name
                    }
                    termOfWork {
                        count
                        typeDate
                    }
                    email
                    site
                    description
                    companyName
                    address {
                        countryId
                        stateId
                        cityId
                        countryName
                        stateName
                        cityName
                    }
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                    status
                    __typename
                }

                ... on IndividualProfile {
                    _id
                    firstName
                    lastName
                    avatar
                    sex
                    birthday
                    hobby
                    phone
                    industry {
                        groupName
                        name
                    }
                    career {
                        jobName
                        beginDate
                        endDate
                        position
                    }
                    educations {
                        institutionName
                        completeDate
                        specialty
                        startDate
                    }
                    typeKeys
                    email
                    site
                    description
                    address {
                        countryId
                        stateId
                        cityId
                        countryName
                        stateName
                        cityName
                    }
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                    status
                    __typename
                }

                ... on Client {
                    firstNames: firstName
                    lastNames: lastName
                    phone
                    typeKeys
                    __typename
                }
            }
        }
    }
`;

export const GET_NOTIFICATION = gql`
    {
        notification @client {
            type
            text
            isOpen
        }
    }
`;

export const GET_SEARCH_WITH_FILTER = gql`
    query(
        $page: Int,
        $count: Int,
        $typeKeys: [String],
        $address: SearchAddressInput,
        $industry: IndustryInput,
        $yearBegin: Int,
        $yearEnd: Int,
        $hobby: String
        $name: String
    ) {
        SearchGetter {
            filterUserByParams(
                page: $page,
                count: $count,
                typeKeys: $typeKeys,
                address: $address,
                industry: $industry,
                yearBegin: $yearBegin,
                yearEnd: $yearEnd,
                hobby: $hobby,
                name: $name,
            ) {
                users {
                    _id
                    firstName
                    lastName
                    avatar
                    birthday
                    typeKeys
                    address {
                        countryId
                        stateId
                        cityId
                    }
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                }
                total
            }
        }
    }

`;


export const CHECK_CODE = gql`
    query($code: String!, $phone: String!) {
        testCode(code: $code, phone: $phone)
    }
`;

export const SET_NEW_PASSWORD = gql`
    mutation($code: String!, $phone: String!, $password: String!, $repeatPassword: String!) {
        setNewPassword(phone: $phone, code: $code, password: $password, repeatPassword: $repeatPassword) {
            token
        }
    }
`;


export const GET_PROFILE_FOR_ADMIN = gql`
    query ($count: Int, $page: Int, $dateBegin: DateTime, $dateEnd: DateTime, $name: String, $userType: String ) {
        ProfileGetter {
            getProfiles(page: $page, count: $count, dateBegin: $dateBegin, dateEnd: $dateEnd, name: $name, userType: $userType) {
                profiles {
                    ... on EntityProfile {
                        _id
                        firstName
                        lastName
                        birthday
                        typeKeys
                        avatar
                        createDate
                        check
                    }

                    ... on IndividualProfile {
                        _id
                        firstName
                        lastName
                        birthday
                        typeKeys
                        avatar
                        createDate
                        check
                    }

                    ... on Client {
                        lastNames: lastName
                        firstNames: firstName
                        createDate
                    }
                }
                total
            }
        }
    }
`;

export const GET_PROFILE_FOR_ADMIN_TOTAL = gql`
    query ($count: Int, $page: Int, $dateBegin: DateTime, $dateEnd: DateTime, $name: String, $userType: String ) {
        ProfileGetter {
            getProfiles(page: $page, count: $count, dateBegin: $dateBegin, dateEnd: $dateEnd, name: $name, userType: $userType) {
                total
            }
        }
    }
`;

export const STATISTIC_REGISTRATION_USERS = gql`
    query($beginDate: DateTime, $endDate: DateTime) {
        ReportGetter {
            getReportRegistrationUsersByDate(beginDate: $beginDate, endDate: $endDate) {
                rows {
                    date
                    count
                }
            }
        }
    }
`;


export const REPEAT_CODE = gql`query {
    repeatCode {
        phone
    }
}`;

export const GET_RECCOMEND = gql` query($userType: String!) {
        UserGetter {
            getRecommendedOffers(userType: $userType) {
                _id
                name
                title
                userId {
                    _id
                    firstName
                    lastName
                    avatar
                    sex
                    isContacts
                    isLockedYou
                    isLockedUser
                    check
                    typeKeys
                    birthday
                    address {
                        countryId
                        stateId
                        cityId
                        countryName
                        stateName
                        cityName
                    }
                    createDate
                }
            }
        }
    }`
