import Input from 'components/UI/Fields/Input';
import Select from "components/UI/Fields/Select";
import Language from "language/index";
import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';


class TechnicalSupports extends Component {

  render() {

    const type = ['не удается восстановить пароль', 'проблемы с регистрацией',
      'профиль взломан', 'профиль заблокирован',
      'удаление профиля', 'предложение не прошло модерацию на сайте',
      'управление сообщениями эл.почты, которые вы получаете от newnext',
      'блокировка/разблокировка участника', 'другой вопрос'
    ];
    let options = [];
    type.map((el, index) => options[index] = { value: el, label: el });

    return (
      <div className="form fullWidth" style={{ maxWidth: '550px' }}>
        <form  onSubmit={this.props.handleSubmit}>
          <Field
            name="theme"
            component={Select}
            label="Тема обращения"
            placeholder="Выберите тему обращения"
            className={`element selectReq`}
            options={options}
          />
          <Field
            name="text"
            type="text"
            component={Input}
            label="Текст обращения"
            placeholder="Введите текст обращения"
          />
          <button type="submit" className="orange-btn">{this.props.preloader ? 'Loading...' : Language.send}</button>
        </form>
      </div>
    );
  }
}


export default reduxForm({
  form: 'setPassword',
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(stopSubmit('setPassword'));
    }
  }
})(TechnicalSupports);
