import cn from 'classnames'
import { Avatar } from 'components/v1/Avatar';
import { getMessageDateString } from 'libs/getMessageTime';

import classes from './DialogList.module.scss';
import { Counter } from 'components/v1/Counter';

export const DialogItem = props => {
  const { active, user, avatar, lastMsg, myProfileId, countNewMsg, onClick } = props;
  return (
    <div className={cn(classes.item, { [classes.active]: active })} onClick={onClick}>
      <Avatar type="circle" size="small" source={avatar} className={classes.avatar} />
      <div className={classes.info}>
        <div className={classes.header}>
          <div className={classes.name}>
            {user.firstName} {user.lastName}
          </div>
          <div className={classes.date}>
            {lastMsg?.createDate ? getMessageDateString(lastMsg?.createDate) : ''}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.message}>
            {myProfileId === lastMsg?.senderId ? 'Вы: ' : ''}
            {lastMsg?.text || 'Сообщений нет'}
          </div>
          {Boolean(countNewMsg) && <Counter count={countNewMsg} />}
        </div>

      </div>
    </div>
  )
}
