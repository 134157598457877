import { required } from "libs/fieldValidation";
import showError from "libs/showFormErrors";
import { ActionNotification } from "components/UI/Notifications/action";
import Language from "language/index";

export const submitProviderForm = (values, mutate, cb) => {


    const { title, description, deliveryType, cost, deliveryTime, maximumDeliveryVolume, industry} = values;

    let validateObj = {
        'title': [required(title)],
        'description': [required(description)],
        'deliveryType': [required(deliveryType)],
        'cost.amount': [required(cost)],
        'deliveryTime.count': [required(deliveryTime)],
        'maximumDeliveryVolume.count': [required(maximumDeliveryVolume)],
        'industry.groupName': [required(industry)],
    };

    if (industry) {
        validateObj['industry.groupName'] = [required(industry.groupName)];
        validateObj['industry.name'] = [required(industry.name)];
    }
    if (cost) {
        validateObj['cost.amount'] = [required(cost.amount)];
        validateObj['cost.currency'] = [required(cost.currency)];
    }

    showError(validateObj);

    const fields = ['_id', 'title', 'description', 'deliveryType', 'cost', 'deliveryTime', 'maximumDeliveryVolume', 'industry'];

    const value = { ...values };

    for (let key in value) {
        if (value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
        if (!fields.some(el => el === key)) {
            delete value[key];
        } else if (value[key].hasOwnProperty('amount')) {
            value[key].amount = parseInt(value[key].amount);
        } else if (value[key].hasOwnProperty('count')) {
            value[key].count = parseInt(value[key].count);
        }
    }

    mutate({ variables: { offer: { ...value } } })
        .then(res => {
            if (cb) {
                cb();
                ActionNotification('2', Language.applicationChanged);
            } else {
                ActionNotification('2', Language.applicationSuccessfullySent);
            }
        })
        .catch(err => {
            if (err.hasOwnProperty('graphQLErrors') && err.graphQLErrors[0].hasOwnProperty('code') && err.graphQLErrors[0].hasOwnProperty('description')) {
                ActionNotification('1', err.graphQLErrors[0].description);
            } else {
                ActionNotification('1', "Произошла ошибка");
            }
        })
};