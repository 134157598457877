import { Avatar } from "components/UI/Avatar/index";
import { dateToString } from "libs/convertDate";
import { userTypeDesc, userTypesClassName } from "libs/uaerTypes";
import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";

export default class TContactsItem extends PureComponent {

  render() {

    const { avatar, firstName, lastName, typeKeys, personalLink, _id, onlineStatus, dialogId } = this.props;
    const typeUser = {
      desc: userTypeDesc(typeKeys[0]),
      className: userTypesClassName(typeKeys[0])
    };


    return (
      <div className={`t-contact ${onlineStatus && onlineStatus.isOnline && 'online'}`}>
        <Link to={`${dialogId ? `/messages/${dialogId}` : `/profile/${_id}`}`}/>
        <div className="flex">
          <Avatar
            size={70}
            avatar={avatar}
          />
          <div className="t-contact-info">
            <div className="contact-name">
              <Link to={`/profile/${personalLink ? personalLink : _id}`}>
                {`${firstName}  ${lastName}`}
              </Link>
            </div>
            <div className={`contact-status  ${typeUser.className}`}> {typeUser.desc} </div>
            {
              onlineStatus && !onlineStatus.isOnline &&
              <div className="time">
                {
                  onlineStatus.lastTime === null
                    ? 'был в сети когда-то'
                    : `
                    ${dateToString(new Date(onlineStatus.lastTime), 'stringFullDate')}${new Date(onlineStatus.lastTime).getFullYear() < new Date(Date.now()).getFullYear()
                    ? ` ${new Date(onlineStatus.lastTime).getFullYear()}`
                    : ''
                    }, ${dateToString(new Date(onlineStatus.lastTime), 'time')}  
                    `
                }
              </div>
            }
          </div>

        </div>
      </div>
    )
  }
}

