import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export const Badge = ({ children, className, count, color = 'orange' }) => {

  if (!count) {
    return null
  }

  if (children) {
    return (
      <div className={cn(styles.container, styles[color], className)}>
        {children}
        <div className={styles.badge}>
          {count > 99 ? '99+' : count}
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.badge, styles[color], className)}>
      {count > 99 ? '99+' : count}
    </div>
  )
}
