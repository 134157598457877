import { SlideToggleAnimation } from "components/UI/Accordion/SlideToggleAnimation";
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Accordion extends PureComponent {

    static propTypes = {
        label: PropTypes.string,
        children: PropTypes.node,
        open: PropTypes.bool
    };

    static defaultProps = {
        label: '',
        open: false
    };

    state = {
        openTab: this.props.open
    };

    onHandleToggleTab = () => this.setState({ openTab: !this.state.openTab });

    render() {
        return (
            <div className="stage acc-item">
                <div className="sb-title acc-title" onClick={this.onHandleToggleTab}>
                    <span className={this.state.openTab ? 'active' : ''}>
                        {this.props.label}
                    </span>
                </div>
                <SlideToggleAnimation open={this.state.openTab}>
                    {this.props.children}
                </SlideToggleAnimation>
            </div>
        );
    }
}