import Language from "language/index";

export default [
    { value: 'BusinessProduct', label: Language.sellerBusiness },
    { value: 'Investor', label: Language.investor },
    { value: 'Innovator', label: Language.novator },
    { value: 'BusinessWoman', label: Language.businessWoman },
    { value: 'Unemployed', label: Language.unemployed },
    { value: 'Provider', label: Language.supplier },

]
