import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';

import { GET_CONTACTS } from 'query/contacts';
import { AsideTabs } from 'components/v1/AsideTabs';
import { Page } from 'components/v1/Page';
import { User, MyActions, RequestActions } from 'components/v1/User';
import { SearchPanel } from 'components/v1/SearchPanel';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';

import { SEARCH, MY, REQUEST } from './constants';
import classes from './styles.module.scss';
import { EmptyContent } from 'components/v1/EmptyContent';
import SearchFormRedux from 'components/UI/Forms/SearchUser';
import { GET_SEARCH_WITH_FILTER } from 'query';
import { SettingsRequesIcon } from 'components/v1/Icons/SettingsRequesIcon';
import useDebounce from 'libs/useDebounce';
import { HeaderMobile } from 'components/v1/HeaderMobile';
import ModalWindow from 'components/UI/ModalWindow';


export const ContactsPage = ({ is_added, request, refetchAdded, refetch, _id }) => {

  const [filterVariables, setFilterVariables] = useState({ count: 30 })
  const [inputValue, setInputValue] = useState('')
  const [isOpened, setIsOpened] = useState(false);

  const [type, setType] = useState(SEARCH);
  const tabs = [
    { label: 'Поиск контактов', value: SEARCH },
    { label: 'Мои контакты', value: MY, count: is_added.ContactGetter.getContactList.total },
    { label: 'Запросы', value: REQUEST, count: request.ContactGetter.getContactList.total },
  ];

  const currentUserList = type === MY ? is_added.ContactGetter.getContactList : request.ContactGetter.getContactList;

  const onHandleChange = (e) => {
    const baseParams = {
      count: 50,
      name: e.target.value
    };

    if (type === MY) {
      refetchAdded({ status: 'is_added', ...baseParams});
    }
    if (type === REQUEST) {
      refetch({ status: 'request', ...baseParams });
    }
    if (type === SEARCH) {
      setInputValue(e.target.value)
    }
  }

  const debouncedSearchTerm = useDebounce(inputValue, 500);

  useEffect(() => {
    setFilterVariables(filterVariables => ({ ...filterVariables, name: debouncedSearchTerm }))
  }, [debouncedSearchTerm])

  const onSubmit = (values) => {
    if (values.yearBegin) values.yearBegin = parseInt(values.yearBegin);
    if (values.yearEnd) values.yearEnd = parseInt(values.yearEnd);

    const variables = values;
    variables.count = 30;

    setFilterVariables(variables)
  }

  return (
    <Page>
      <HeaderMobile goBack title="Контакты" />
      <Page.Body>
        <SearchPanel onChange={onHandleChange} onOpenModal={() => setIsOpened(true)}/>
        {isOpened && (
          <ModalWindow className={classes.filterBody} title="Настроить поиск" handleClose={() => setIsOpened(false)}>
            <SearchFormRedux onSubmit={onSubmit} isAdditionalSearch />
          </ModalWindow>
        )}

        {type === SEARCH ? (
          <Query query={GET_SEARCH_WITH_FILTER} variables={filterVariables}>
            {({ data, loading, error }) => {
              if (!data.SearchGetter && loading) return <PreloaderCircle/>;
              if (data.SearchGetter.filterUserByParams.total === 0) {
                return (
                  <EmptyContent text="Здесь ничего нет" />
                )
              }
              return (
                <div className={classes.list}>
                  {
                    data.SearchGetter.filterUserByParams.users.map((contact) => {
                      return (
                        <User
                          key={contact._id}
                          {...contact}
                          actions={(
                            <div className={classes.buttons}>
                              {type === MY && <MyActions {...contact} />}
                            </div>
                          )}
                        />
                      )
                    })
                  }
                </div>
              )
            }}
          </Query>
        ) : (
          <>
            {currentUserList?.contacts.length === 0 && (
              <EmptyContent text="Здесь ничего нет" />
            )}
            {currentUserList?.contacts.length > 0 && (
              <div className={classes.list}>
                {currentUserList?.contacts.map((contact) => (
                  <User
                    key={contact.user._id}
                    {...contact.user}
                    actions={(
                      <div className={classes.buttons}>
                        {type === MY && <MyActions {...contact.user} />}
                        {type === REQUEST && <RequestActions {...contact.user} />}
                      </div>
                    )}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </Page.Body>
      <Page.Aside>
        <AsideTabs items={tabs} activeTab={type} onChangeTab={setType}/>
        <div className={classes.filterBox}>
          <div className={classes.filterHeader}>
            <SettingsRequesIcon />Настроить поиск
          </div>
          <div className={classes.filterBody}>
            <SearchFormRedux onSubmit={onSubmit} isAdditionalSearch />
          </div>
        </div>
      </Page.Aside>
    </Page>
  );
};

// TODO: переделать на хуки!!!
export const Contacts = props => {
  return (
    <Query query={GET_CONTACTS} variables={{ status: 'is_added', count: 50 }} fetchPolicy="cache-and-network">
      {({ data, loading, error, refetch, fetchMore }) => {
        let refetchAdded = refetch;
        if (!data.ContactGetter && loading) return <PreloaderCircle/>;
        return (
          <Query query={GET_CONTACTS} variables={{ status: 'request', count: 50 }}>
            {({ data: dataTwo, loading, error, refetch }) => {
              if (!dataTwo.ContactGetter && loading) return <PreloaderCircle/>;
              return (
                <ContactsPage
                  loading={loading}
                  refetchAdded={refetchAdded}
                  fetchMore={fetchMore}
                  refetch={refetch}
                  request={dataTwo}
                  is_added={data}
                  {...props}
                />
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
};
