import React from 'react'
import Language from 'language';

import { InputController } from 'components/v1/HookFields/Input';
import { SelectController } from 'components/v1/HookFields/Select';
import RegionGroup from 'components/v1/HookFields/Group/Region';
import IndustryGroup from 'components/v1/HookFields/Group/Industry';

import { TYPE_DATE_OPTIONS } from 'pages/Profile/EditForms/utils';
import { rules, required } from 'libs/rules';

import styles from './styles.module.scss'


export const MainCompanyForm = ({ control, setValue, errors, watch }) => {

  // const { control, watch, setValue } = useForm({
  //   defaultValues: {
  //     address: {
  //       countryId: '',
  //     }
  //   }
  // })

  return (
    <>
      <div className={styles.section}>
        <div>{Language.firstName}</div>
        <InputController
          control={control}
          name="firstName"
          rules={rules.firstName}
          errors={errors.firstName}
        />
      </div>
      <div className={styles.section}>
        <div>{Language.lastName}</div>
        <InputController
          control={control}
          name="lastName"
          rules={rules.firstName}
          errors={errors.firstName}
        />
      </div>
      <div className={styles.section}>
        <div>Название компании</div>
        <InputController
          control={control}
          name="companyName"
          rules={{ required }}
          errors={errors.companyName}
        />
      </div>
      <IndustryGroup control={control} setValue={setValue} watch={watch} errors={errors} />
      <div className={styles.section}>
        <div>Срок работы на рынке</div>
        <div className={styles.flexGroupSection}>
          <InputController
            control={control}
            name="termOfWork[count]"
            type="number"
          />
          <SelectController
            control={control}
            name="termOfWork[typeDate]"
            options={TYPE_DATE_OPTIONS}
            label={''}
          />
        </div>
      </div>
      <RegionGroup control={control} setValue={setValue} watch={watch} errors={errors} />
      {/*<div className="separator" />*/}
      {/*<Button type="submit">*/}
      {/*  Сохранить*/}
      {/*</Button>*/}
    </>
  )
}
