import React, { Component } from 'react';
import Language  from "language/index";

class CenterSide extends Component {
  render() {
    return (
      <div className="centerside">
        <div className="side-block">
          <div className="title-block">
            <div className="flex jcsb align">

              <h1> {Language.TermsUseCookies} </h1>

            </div>
          </div>
        </div>

        <div className="side-block mt15 terms-content">
          <div className="element clearfix">

            <div className="test" id="StatusOfTheTerms" />
            <div >
              <h3> {Language.StatusOfTheTerms}  </h3>
              <p>
                {Language.StatusOfTheTerms_1}
              </p>
              <p>
                {Language.StatusOfTheTerms_2}
              </p>
              <p>
                {Language.StatusOfTheTerms_3}
              </p>
              <p>
                {Language.StatusOfTheTerms_4}
              </p>
              <p>
                {Language.StatusOfTheTerms_5}
              </p>
              <p>
                {Language.StatusOfTheTerms_6}
              </p>


              {/*<p>*/}
                {/*{Language.RulesAreOpenAndPublic}*/}
                {/*<a href={Language.RulesAreOpenAndPubliclink.url}>{Language.RulesAreOpenAndPubliclink.text}</a>*/}
              {/*</p>*/}


            </div>
            <div className="test" id="UseOfCookies" />
            <div>
              <h3> {Language.UseOfCookies} </h3>

              <p>
                {Language.UseOfCookies_1}
              </p>

              <p>
                {Language.UseOfCookies_2}
              </p>

              <p>
                {Language.UseOfCookies_3}
              </p>

              <p>
                {Language.UseOfCookies_4}
              </p>

              <p>
                {Language.UseOfCookies_5}
              </p>

              <p>
                {Language.UseOfCookies_6}
              </p>

              <p>
                {Language.UseOfCookies_7}
              </p>

              <p>
                <b>{Language.UseOfCookies_8.attentional}</b>
                {Language.UseOfCookies_8.text}
              </p>

              <p>
                {Language.UseOfCookies_9}
              </p>

            </div>
            <div className="test" id="TheShelfLifeCookies" />
            <div>
              <h3> {Language.TheShelfLifeCookies} </h3>
              <p>
                {Language.TheShelfLifeCookies_1}
              </p>
              <p>
                {Language.TheShelfLifeCookies_2}
              </p>
            </div>
            <div className="test" id="CookieManagement" />
            <div>
              <h3> {Language.CookieManagement} </h3>
              <p>
                {Language.CookieManagement_1}
              </p>
              <p>
                {Language.CookieManagement_2}
              </p>
              <p>
                {Language.CookieManagement_3}
              </p>

            </div>
            <div className="test" id="OtherSimilarTechnologies" />
            <div>
              <h3> { Language.OtherSimilarTechnologies } </h3>
              <p>
                {Language.OtherSimilarTechnologies_1}
              </p>
              <p>
                {Language.OtherSimilarTechnologies_2}
              </p>
              <p>
                {Language.OtherSimilarTechnologies_3}
              </p>
              <p>
                {Language.OtherSimilarTechnologies_4}
              </p>

            </div>
            <div className="test" id="ChangesTermsCookies" />
            <div>
              <h3> {Language.ChangesTermsCookies} </h3>
              <p>
                { Language.ChangesTermsCookies_1 }
              </p>
              <p>
                { Language.ChangesTermsCookies_2 }
              </p>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CenterSide;