import gql from 'graphql-tag';

export const GET_MY_DESCRIPTION = gql`
    {
        ProfileGetter {
            getMyProfile {
                ... on EntityProfile {
                    description
                    __typename
                }

                ... on IndividualProfile {
                    description
                    __typename
                }
            }
        }
    }
`;

export const GET_MY_ID = gql`
    {
        ProfileGetter {
            getMyProfile {
                ... on EntityProfile {
                    _id
                    typeKeys
                    __typename
                }

                ... on IndividualProfile {
                    _id
                    typeKeys
                    __typename
                }
            }
        }
    }
`;

export const GET_PROFILE_USER = gql`
    query ($userId: String!){
        UserGetter {
            getUserById(userId: $userId) {
                ... on EntityProfile {
                    _id
                    firstName
                    lastName
                    avatar
                    industry {
                        groupName
                        name
                    }
                    typeKeys
                    industryDetail
                    termOfWork {
                        count
                        typeDate
                    }
                    email
                    site
                    description
                    isContacts
                    isLockedUser
                    isLockedYou
                    address {
                        countryId
                        stateId
                        cityId
                        countryName
                        stateName
                        cityName
                    }
                    companyName
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                    check
                    __typename
                }

                ... on IndividualProfile {
                    _id
                    firstName
                    lastName
                    avatar
                    isContacts
                    isLockedUser
                    isLockedYou
                    industry {
                        groupName
                        name
                    }
                    typeKeys
                    industryDetail
                    email
                    site
                    description
                    hobby
                    birthday
                    address {
                        countryId
                        stateId
                        cityId
                        countryName
                        stateName
                        cityName
                    }
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                    career {
                        jobName
                        beginDate
                        endDate
                        position
                    }
                    educations {
                        institutionName
                        completeDate
                        specialty
                    }
                    sex
                    check
                    __typename
                }

                ... on User {
                    firstName
                    lastName
                    avatar
                    isLockedUser
                    __typename
                }
            }
        }
    }
`;

export const GET_NOTIFICATION_SUBSCRIBE = gql`
    query { 
        ProfileGetter {
            getNotification
        }
    }
`;

