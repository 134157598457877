import MessagesItem from "components/UI/Messages/MessagesItem";
import { formatIdOffer } from "libs/formatNumbers";
import { compareDate, getMessageDateString } from "libs/getMessageTime";
import { READ_MSG } from "mutation/dialog";
import { GET_MESSAGES } from "query/messages";
import React, { Component, Fragment } from 'react';
import { Mutation } from "react-apollo";


const Day = ({ createDate, offer }) => {
    return (
        <div className="day">
            <span>{getMessageDateString(createDate)}</span>
            <div className="right-mes-desc">
                {offer && <em>Диалог по заявке №{formatIdOffer(offer._id)}</em>}
            </div>
        </div>
    )
};


class MessagesListOld extends Component {

    scrollToBottom = () => {
        let el = document.querySelector('#scrollable_messages');
        if (document.querySelector('#scroll_block_messages')) {
          let height = document.querySelector('#scroll_block_messages').offsetHeight;
          el.scrollTo(0, height + 200);
        }
    };


    componentDidMount() {
        this.props.subscribeToNewComments();
        setTimeout(() => this.scrollToBottom(), 80)
    }

    componentDidUpdate(prevProps) {
        // console.log(prevProps)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.messages.length !== this.props.messages.length)
            setTimeout(() => this.scrollToBottom(), 80)
    }

    render() {
        const { messages, userFrom, userTo, offer, dialogId } = this.props;
        const newArrayMessages = [...messages].reverse();
        return (
            newArrayMessages.map((el, index) => {
                let isNeedRead = false;
                if (el.senderId !== this.props._id && !el.isRead) {
                    isNeedRead = true;
                }
                const prevMess = index !== 0 ? newArrayMessages[index - 1] : false;
                const isDay = !prevMess || (prevMess && !compareDate(el.createDate, prevMess.createDate));
                return (
                    <Fragment key={index}>
                        {
                            isDay &&
                            <Day
                                createDate={el.createDate}
                                offer={offer}
                            />
                        }
                        <ul className={`${el.senderId === this.props._id && !el.isRead ? 'not_read' : ''} mes-list user`}>
                            <MessagesItem
                                {...el}
                                prevMess={prevMess}
                                userFrom={userFrom}
                                userTo={userTo}
                                offer={offer}
                                readMessage={
                                    isNeedRead ?
                                    () => this.props.mutate({ variables: { dialogId, msgId: el._id } }) :
                                    () => {}
                                }
                            />
                        </ul>
                    </Fragment>
                )
            })
        )
    }
}


export default props => {
    return (
        <Mutation
            mutation={READ_MSG}
            update={(cache, { data: { DialogSetter } }) => updateReadMsg(cache, { data: { DialogSetter } }, props.dialogId)}>
            {mutate => {
                return (
                    <MessagesListOld mutate={mutate} {...props}/>
                )
            }}
        </Mutation>

    )
}


const updateReadMsg = (cache, { data: { DialogSetter } }, dialogId) => {
    let { DialogGetter } = cache.readQuery({ query: GET_MESSAGES, variables: { "dialogId": dialogId, count: 50 } });

    DialogGetter.getMessagesByDialogId.messages.map(el => {
        if (DialogSetter.readMsg._id === el._id) {
            el.isRead = true
        }
    });


    cache.writeQuery({
        query: GET_MESSAGES,
        variables: { "dialogId": dialogId, count: 50 },
        data: { DialogGetter },
    });
};


let data = {
    DialogSetter: {
        readMsg: {
            isRead: true,
            __typename: "Msg",
            _id: "5cdac927547d422dbfbbd181"
        },
        __typename: "upDialogs"
    }
}
