import Input from "components/UI/Fields/Input";
import Select from 'components/UI/Fields/Select';
import TypeProfileSelect from "components/UI/Forms/RegForm/TypeProfileSelect";
import React, { Component } from 'react';
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import { change, Field, reduxForm, reset, stopSubmit, formValueSelector } from 'redux-form';
import Region from 'components/UI/Forms/GroupFields/Region';
import Industry from 'components/UI/Forms/GroupFields/Industry';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Language from "language/index";
import DatePicker from "components/UI/Fields/DatePicker";

class SearchUsersAdmin extends Component {

    setValue = (values, nameField) => {
        this.props.dispatch(change('searchUserAdminForm', nameField, values));
    };

    resetForm = () => {
        this.props.dispatch(reset('searchUserAdminForm'));
        this.props.resetSearch();
    };

    isClearly = () => {
        return Object.keys(this.props.values).length !== 0;
    };


    render() {

        const { handleSubmit } = this.props,
            isClearly = this.isClearly();

        return (
            <div className="form">
                <form onSubmit={handleSubmit}>
                    <Field
                        name="userType"
                        component={Select}
                        label={`${Language.userCategory} *`}
                        placeholder={Language.ChooseMemberType}
                        className={`element selectReq`}
                        options={TypeProfileSelect}
                    />

                    <Field
                        name="dateBegin"
                        component={DatePicker}
                        label="Дата регистрации от:"
                        className={`element datePickerInput`}
                    />

                    <Field
                        name="dateEnd"
                        component={DatePicker}
                        label="Дата регистрации до:"
                        className={`element datePickerInput`}
                    />

                    <div className="flex">
                        <button type="submit" className="orange-btn search_users">{Language.find}</button>
                        <button
                            type="button"
                            className="reset"
                            onClick={this.resetForm}
                            style={{ display: isClearly ? 'block' : 'none' }}
                        >
                            {Language.Reset}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const selector = formValueSelector('searchUserAdminForm');

export default compose(
    reduxForm({
        form: 'searchUserAdminForm',
        onChange: (values, dispatch, props) => {
            if (props.submitFailed) {
                dispatch(stopSubmit('searchUserAdminForm'));
            }
        }
    }),
    connect(
        state => ({
            values: selector(state, 'typeKeys', 'dateEnd', 'dateBegin', 'yearBegin', 'yearEnd'),
        })
    )
)(SearchUsersAdmin);