import React from 'react';
import { Query } from 'react-apollo';
import { Request } from 'components/v1/Request';
import PreloaderCircle from 'components/UI/Prealoders/PreloaderCircle';
import { GET_OFFERS_BU_USER_ID } from 'query/offers';

import classes from './styles.module.scss';
import { EmptyContent } from 'components/v1/EmptyContent';

export const UserRequestsComponent = ({ requests }) => {
  return (
    <div className={classes.root}>
      {requests.map((request) => (
        <Request key={request._id} {...request} isNetwork />
      ))}
    </div>
  )
}

export const UserRequests = props => {
  return (
    <Query query={GET_OFFERS_BU_USER_ID} variables={{ 'userId': props.id }}>
      {({ data: { OfferGetter }, loading }) => {
        if (loading) return <PreloaderCircle/>;
        if (OfferGetter.getOfferByUserId.total === 0) return <EmptyContent text=""/>;
        return (
          <UserRequestsComponent {...props} requests={OfferGetter.getOfferByUserId.offers} />
        )
      }}
    </Query>
  )
};


