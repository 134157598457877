import { getItem } from "./localStorage";

export const serverDateToClientDate = (date) => {
	// return date;
	let result = new Date(),
		patch = date.split(' '),
		calendar = patch[0].split('-'),
		time = patch[1] ? patch[1].split(':') : undefined;
	result.setFullYear(parseInt(calendar[0]));
	result.setMonth(parseInt(calendar[1]) - 1);
	result.setUTCDate(parseInt(calendar[2]));
	if (time) {
		result.setHours(parseInt(time[0]));
		result.setMinutes(parseInt(time[1]));
		result.setSeconds(parseInt(time[2]));
	}
	return result;
};

export const stringToDate = (_date) => serverDateToClientDate(_date);

export const dateToString = (date, type) => {
	let monthList = ['Января','Февраля','Марта','Апреля','Мая','Июня','Июля','Августа','Сентября','Октября','Ноября','Декабря'];
	if (getItem('language', false) && getItem('language') === 'en') {
    monthList = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	}

	if (date && typeof(date.getDate) === 'function')
		switch (type) {
			case 'clientCargo':
				return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()},
						${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
			case 'server':
			case 'serverDate':
				return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
			case 'serverDateTime':
				return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} 00:00:00`;
			case 'serverDateFullTime':
				return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:00`;
			case 'dayAndMonth':
				return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}`;
			case 'stringShortDate':
				return `${('0' + date.getDate()).slice(-2)} ${monthList[date.getMonth()].slice(0, 3)}`;
			case 'stringFullDate':
				return `${date.getDate()} ${monthList[date.getMonth()]}`;
			case 'time':
				return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
			case 'stringDate':
				return `${('0' + date.getDate()).slice(-2)} ${monthList[date.getMonth()]} ${date.getFullYear()}г.`;
			case 'getDate':
				return `${('0' + date.getDate()).slice(-2)}`;
			case 'getMonthString':
				return `${monthList[date.getMonth()]}`;
			case 'getYear':
				return `${date.getFullYear()}`;
			default:
				return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
		}
	else
		return '';
};

export function createDateAsUTC(date) {
	console.log('createDateAsUTC', date);
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

export function convertDateToUTC(date) {
	console.log('convertDateToUTC', date);
	return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}
