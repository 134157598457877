import { Query } from 'react-apollo';
import { GET_MY_ID } from 'query/profile';

import { DialogItem } from './DialogItem';

import classes from './DialogList.module.scss';

export const DialogListComponent = props => {
  const { dialogs, getMyProfile, onChangeChatId, activeChatId } = props;
  return (
    <div className={classes.root}>
      {dialogs.map((dialog) => {
        const { userFrom, userTo } = dialog;
        let user = userFrom._id === getMyProfile._id ? userTo : userFrom;
        return (
          <DialogItem
            {...user}
            key={dialog._id}
            user={user}
            _id={dialog._id}
            myProfileId={getMyProfile._id}
            countNewMsg={dialog.countNewMsg}
            lastMsg={dialog.lastMsg}
            onClick={() => onChangeChatId(dialog._id)}
            active={activeChatId === dialog._id}
          />
        )
      })}
    </div>
  )
}


export const DialogList = props => (
  <Query query={GET_MY_ID} fetchPolicy="cache-only">
    {({ data: { ProfileGetter }, error }) => {
      if (error) return <p> Error.... </p>;
      return <DialogListComponent {...ProfileGetter} {...props}/>
    }}
  </Query>
);
