import React from 'react';
import { Field } from "redux-form";
import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import Language from "language/index";
import { hobbies } from "libs/optionsSelect";


class Additionally extends React.Component {
    render() {
        return (
            <div className="elements-group elements-group-fix">
                <div className="form fullWidth">
                    <Field
                        name="maritalStatus"
                        component={Select}
                        label={Language.maritalStatus}
                        placeholder={Language.selectFamilyStatus}
                        options={[
                          {value: 'single', label: Language.SingleUnmarried},
                          {value: 'married', label: Language.Married},
                        ]}

                    />
                    <Field
                        name="hobby[0]"
                        component={Select}
                        label={Language.hobby}
                        placeholder={Language.chooseHobby}
                        options={hobbies}
                    />
                    <Field
                        name="description"
                        type="text"
                        component={Input}
                        label={Language.aboutMe}
                        placeholder="Введите о себе"
                    />
                    <Field
                        name="personalLink"
                        type="text"
                        component={Input}
                        label={Language.urlProfileLabel}
                        placeholder="Введите URL адрес профиля"
                    />
                </div>
            </div>
        );
    }
}

export default Additionally;