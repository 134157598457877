import React, { Component } from 'react';
import CenterSide from "./center";
import RightSide from "./right";
import Language from "language/index";
import styles from 'pages/News/styles.module.scss';
import { HeaderMobile } from 'components/v1/HeaderMobile';


class HelpPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          id: `generalquestions`,
          imgSrc: `templates/img/locked.svg`,
          title: Language.GENERALQUESTIONS,
          list: [
            {
              name: Language.WhoToContact,
              text: [Language.WeWillDefinitelyAnswer, Language.IfYouHaveNotReceived, Language.DontAskUsTheSame]
            },
            {
              name: Language.WhatIfYourDataIs,
              text: [Language.IfAnyUserUsesYour, Language.InYourRequestPlease, Language.FirstALinkToThe, Language.SecondADescriptionOfWhat, Language.ThirdAPhotoOfYour, Language.FourthAPhotoThatShowsYour, Language.YouMustUnderstandThatAll]
            },
            {
              name: Language.HowToChangeTheSize,
              text: [Language.PleasePressCTRL0]
            },
            {
              name: Language.HowToTakeAScreen,
              text: [Language.ForWindows, Language.PressThePrtScrKeyIn, Language.ForOSX, Language.CommandShiftThree, Language.CommandShiftFour, Language.CommandShiftFourSpace, Language.ToSaveToTheClipboard]
            },
            {
              name: Language.WhoToContactIfYouAre,
              text: [Language.YouNeedToContactTheSupport, Language.PleaseNoteThatOnlyTheSite, Language.IncommingComplaintsAre]
            },
            {
              name: Language.HowToRefreshThePage,
              text: [Language.PressTheF5]
            },
            {
              name: Language.WhereCanIReadTheTerms,
              text: [Language.YouNeedToLinkTo]
            },
            {
              name: Language.WhereCanIReport,
              text: [Language.IfYouFindAnError, Language.InOrderTo]
            },
            {
              name: Language.HowCanIChangeTheTime,
              text: [Language.OurWebsiteUses, Language.WeRecommendThat]
            },
            {
              name: Language.WhereToGoIfYou,
              text: [Language.NewNextIsAPlatformThat, Language.IfYouAreAVictim]
            },
          ]
        },
        {
          id: `page`,
          imgSrc: `templates/img/user.svg`,
          title: Language.DESCRIPTIONOFNEWNEXT,
          list: [
            {
              name: Language.WhatIsTheInternational,
              text: [Language.NewnextIsTheWorlds, Language.NewnextAllowsYouToSolve]
            },
            {
              name: Language.WhatServiceDoesNewnext,
              text: [Language.NewnextUsersAreProvided, Language.PotentialInvestors, Language.UsersToPlaceProjects, Language.UsersToFindTheBest, Language.UsersToFindGoods, Language.UsersToLearnAbout, Language.UsersToFindInterested, Language.UsersToReceive, Language.UsersToFindCustomers, Language.UserToFindQualified, Language.UsersToFindJobs, Language.UsersToFormATeam, Language.UsersToImplement, Language.NewnextHelpsUsersToDevelop]
            },
            {
              name: Language.NewnextFunctionalCapabilities,
              text: [Language.NewnextUsersHave, Language.EstablishBusinnes, Language.AttractInvestments, Language.FindProjectsForInvestment, Language.CreateStoreAndModify, Language.ReceiveAListOfNewnext, Language.ToObtainEasyTargeted, Language.VisitAndViewPagesOf, Language.CreateAPersonalContact, Language.MaintainPersonalCorrespondence, Language.CommunicateWithOtherParticipants, Language.PlaceApllicationsWith]
            },
          ]
        },
        {
          id: `subscribers`,
          imgSrc: `templates/img/users.svg`,
          title: Language.USERPAGE,
          list: [
            {
              name: Language.HowToCreateAPage,
              text: [Language.InOrderToCreateAPage, Language.FirstFollowTheLink, Language.SecondFillInReg, Language.ThirdConfirmThePhone, Language.ThePhoneNumberIsYour, Language.FourthEnterAndConfirm, Language.AfterPerformingThe]
            },

            {
              name: Language.CanIDeleteMyPage,
              text: [Language.TheUserPageCanBe, Language.YouCanSubmitA, Language.ATTENTIONItIsPossible, Language.InYourRequest, Language.APhotoOfYourDocument, Language.APhotoThatShows, Language.OnlyInThisCaseWe]
            },
            {
              name: Language.HowToRecoverA,
              text: [Language.IfYourPageIsLinkedToTheCurrent, Language.IfYourPageIsLinkedToAPhone]
            },
            {
              name: Language.WhatDoesTypeOfMember,
              text: [Language.TypeOfMemberInNewnext, Language.DueToTheFactThatTheMain, Language.ItIsThereforeRecommended]
            },
            {
              name: Language.WhyIsItNecessary,
              text: [Language.YouCanRegisterInNewnext, Language.InThefirstCaseAll, Language.InTheSecondCaseYou, Language.WeWouldLikeToPoint]
            },
            {
              name: Language.WhatDoesTheIndustryField,
              text: [Language.TheFieldIndustryInTheApllication]
            },
            {
              name: Language.CanIChangeThePhone,
              text: [Language.YouCannotChangeThePhone, Language.IfThePhoneNumberProvided]
            },
            {
              name: Language.HowToSetTheMainPhoto,
              text: [Language.OnTheLeftSideOf,]
            },
            {
              name: Language.DoYouWantToGetALink,
              text: [Language.ToGetALinkToYour, Language.InThisCaseTheCharacter]
            },
            {
              name: Language.WhatDoesVerified,
              text: [Language.VerifiedPageMeansThat, Language.ThePresenceOfACheck]
            },
            {
              name: Language.WhatNewsWillISee,
              text: [Language.OnTheUserPageThe, Language.WhetherYouHaveBeen, Language.PlacingOrdersByUsers]
            },
            {
              name: Language.CanIViewTheNewsOn,
              text: [Language.NoTheNewsOnSomeoneElses]
            },
            {
              name: Language.HowToChangePersonal,
              text: [Language.YouCanChangeTheData, Language.ItIsImportantToRemember]
            },
          ]
        },
        {
          id: `chat`,
          imgSrc: `templates/img/speech-bubble-with-text-lines.svg`,
          title: Language.CONTACTS,
          list: [
            {
              name: Language.HowToAddAUser,
              text: [Language.AfterYouHaveVisitedThe, Language.YouCanAlsoAddAUser, Language.IfTheUserInTurn]
            },
            {
              name: Language.CanIFindAUserByPhone,
              text: [Language.ItIsImpossibleToFind]
            },
            {
              name: Language.HowToFindAUserById,
              text: [Language.PageIDIsTheNumberThat, Language.TheUserPageCanBeFound]
            },
            {
              name: Language.WhyDoINeedToAdd,
              text: [Language.NewnextIsAnInternational, Language.EachUserOfNewnext, Language.InformationAbout]
            },
            {
              name: Language.HowWillTheUserKnow,
              text: [Language.IfYouHaveAddedTheUser, Language.ByClickingTheContacts, Language.InAccordanceWithTheList]
            },
            {
              name: Language.HowCanIRemoveAUser,
              text: [Language.AfterYouHavevisitedThePageOf, Language.YouCanAlsoRemoveAUser,]
            },
            {
              name: Language.HowWillTheUserKnowThatIDeleted,
              text: [Language.ThereWillNotBeAny]
            },
            {
              name: Language.HowManyUsersCanIHave,
              text: [Language.TheNumberOfUsersInThe]
            },
            {
              name: Language.WhyDoesEachUser,
              text: [Language.NewnextWillHelp, Language.EachNewnextUserCorresponds, Language.AccordingToTheirMemberType, Language.WhenYouOpenAContact]
            },
          ]
        },
        {
          id: `list`,
          imgSrc: `templates/img/list.svg`,
          title: Language.MESSAGES,
          list: [
            {
              name: Language.HowToWrite,
              text: [Language.YouCanSend, Language.AfterYouFinished, Language.YouCanSendAMessage]
            },
            {
              name: Language.AreThereAnyLimits,
              text: [Language.ThereAreNoLimits]
            },
            {
              name: Language.HowToDeleteAMessage,
              text: [Language.YouCanDeleteAMes]
            },
            {
              name: Language.HowCanIDeleteEntireDialog,
              text: [Language.ItIsPossibleToRemove]
            },
            {
              name: Language.HowCanIRecover,
              text: [Language.YouCannotRecover, Language.PleaseNoteThatDeleting, Language.IfYouNeedInformation]
            },
            {
              name: Language.HowToInsertEmoticons,
              text: [Language.YouCanUseEmoticons, Language.AlsoYouCanEnterEmoticons, Language.PleaseBeCarefulAndDoNotInstall]
            },
            {
              name: Language.IfThereIsNoNecessary,
              text: [Language.WeUserEmoticons]
            },
            {
              name: Language.HowCanICancel,
              text: [Language.YouCannotCancelASent]
            },
            {
              name: Language.WhyIsThereNo,
              text: [Language.IfTheUsersPageDoesNot]
            },
            {
              name: Language.HowCanIPrevent,
              text: [Language.YouCanLimitTheCircle]
            },
            {
              name: Language.HowCanIKnowThatTheRecipient,
              text: [Language.AllUnreadMessagesAre]
            },
          ]
        },
        {
          id: `questions`,
          imgSrc: `templates/img/question.svg`,
          title: Language.Notice,
          list: [
            {
              name: Language.WhatDoesNnnotice,
              text: [Language.NewnextIsAnInternational2, Language.EachNewnextUserCorresponds2, Language.DependingOnYourUserType]
            },
            {
              name: Language.HowToMakeNNnotice,
              text: [Language.InOrderToMakeNNnotice, Language.YouWillBeAsked]
            },
            {
              name: Language.WhatAreNetwortNnnotice,
              text: [Language.ThisIsAListOf]
            },
            {
              name: Language.HowCanIAddAnImage,
              text: [Language.InOrderToAddAn, Language.AddTheGenerated]
            },
            {
              name: Language.WhatIfIWantToAdd,
              text: [Language.IfThereIsNoSpecial]
            },
            {
              name: Language.WhatDoesTheIndustryFieldNnotice,
              text: [Language.TheFieldIndustry]
            },
            {
              name: Language.WhatDoesNNnoticeHasNotBeen,
              text: [Language.AfterYouHaveSavedNNnotice, Language.IfYouWantToMake]
            },
            {
              name: Language.WhatDoesTheNNnotice,
              text: [Language.Unpublished, Language.NNnoticeForSomeReason, Language.BeingModerated, Language.NNnoticeHasBeenSent, Language.Refused, Language.NNnoticeDidNotPassModeration, Language.Published, Language.NNnoticeIsAvailable]
            },
            {
              name: Language.HowToSendNNnotice,
              text: [Language.InTheListOfNNnotice]
            },
            {
              name: Language.WhyIsNNnotice,
              text: [Language.TheNNnoticeArchive]
            },
            {
              name: Language.HowToPutNNnotice,
              text: [Language.IfYouNeedToPut, Language.IfYouWantToReturn]
            },
            {
              name: Language.HowToFindNNnotice,
              text: [Language.InTheSection]
            },
            {
              name: Language.WhatIsICO,
              text: [Language.ICOIsAForm, Language.ICOIsACryptocurrency]
            },
            {
              name: Language.HowToCreateYourICO,
              text: [Language.InOrderToCreateYourICO, Language.AfterFillingInTheProposed]
            },
            {
              name: Language.HowToContact,
              text: [Language.TheUserAuthor]
            },
          ]
        },
        {
          id: `accespage`,
          imgSrc: `templates/img/settings.svg`,
          title: Language.ACCESSTOTHEPAGE,
          list: [
            {
              name: Language.RestoreAccessPage,
              text: [Language.FirstIfYouNeedRestore, Language.IfYourPageCurrentPhone, Language.IfYourPagePhoneNumberThat, Language.SecondAccessToThePage, Language.InThisCaseYouNeed, Language.ALinkToBlocked, Language.APhotoOfYourIdentity, Language.APhotoThatShowsYourFace, Language.YouMustUnderstandThatAll2]
            },
            {
              name: Language.YouForgotMyUsername,
              text: [Language.InNewnextTheUsername, Language.IfYourPageIsLinkedToTheCurrentPhone, Language.IfYourPageIsLinkedToAPhoneNumber]
            },
            {
              name: Language.WhatToDoIf,
              text: [Language.IfYourPageHasBeen, Language.FirstTryToRestoreAccess, Language.SecondIfYouHaveProblems, Language.ATTENTIONToAvoid]
            },
            {
              name: Language.WhatToDoIfPageBlocked,
              text: [Language.TheNewnextUserPage, Language.ATTENTIONRestoring]
            },
            {
              name: Language.HowToDeletePage,
              text: [Language.TheUserPageCan, Language.YouCanSubmitARequest, Language.ATTENTIONDeletingPage, Language.InYourRequestToTheSupport, Language.APhotoOfYourDocument2, Language.APhotoThatShowsYourFace2, Language.OnlyInThisCaseWe2]
            },
            {
              name: Language.WhatToDoCloseThePage,
              text: [Language.UnfortunatelyWeCannot, Language.WeCanEither, Language.YouNeedToContact]
            },
            {
              name: Language.YouCannotGoToTheSite,
              text: [Language.IfYouCannot, Language.NewnextDoesNot, Language.IfThePageDoes, Language.IfAfterThatTheProblem]
            },
            {
              name: Language.WhatToDoTeRemoveThe,
              text: [Language.YouNeedGoToSettings, Language.ForGoogleChrome, Language.FirstInTheTopRight, Language.SecondSelectSettings, Language.ThirdClickShow, Language.FourthUncheckThe, Language.ForOpera, Language.ForExplorer, Language.ForMozilla]
            },
            {
              name: Language.ChangeThePhone,
              text: [Language.YouCannotChangeThePhone2, Language.IfThePhoneNumberProvided2]
            },
            {
              name: Language.WhatToDoIfYouWant,
              text: [Language.InThisCaseYouNeedToContact, Language.InYourRequestToTheSupportService]
            },
          ]
        },
        {
          id: `privatesettings`,
          imgSrc: `templates/img/settings.svg`,
          title: Language.PRIVACYSETTINGS,
          list: [
            {
              name: Language.HowToAddBlackList,
              text: [Language.InOrderToPutUser]
            },
            {
              name: Language.HowToRemoveUser,
              text: [Language.ToRemoveUser, Language.TheInTheSettings]
            },
            {
              name: Language.HowToLookThough,
              text: [Language.ToViewTheListUsers, Language.ThenChooseBlacklist]
            },
            {
              name: Language.WHyIsThereSendMessage,
              text: [Language.IfTheUserPagedoesnotSendMessage]
            },
            {
              name: Language.CanUsersSeeMyPhoneNumber,
              text: [Language.NoneOfTheNewnextUsers]
            },
            {
              name: Language.CanISeeWhoPut,
              text: [Language.ThereIsNoPossibility]
            },
            {
              name: Language.HowToDeleteMyPage,
              text: [Language.TheUserPageCanBeDeleted, Language.YouCanSubmitARequest2, Language.ATTENTIONItIsPossible, Language.InYourRequest, Language.APhotoOfYourDocument, Language.APhotoThatShows, Language.OnlyInThisCaseWe]
            },
            {
              name: Language.CanISeeWhoVisitsPage,
              text: [Language.NoYouCannotSeeVisitedPage]
            }
          ]
        },
      ],
    };
  }

  render() {
    return (
      <div className={styles.container}>
        <HeaderMobile goBack title="Помощь" />
        <div className="content">
        <CenterSide questions={this.state.questions}/>
        </div>
        <aside>
          <div className="content">
            <RightSide questions={this.state.questions}/>
          </div>
        </aside>
      </div>
    );
  }
}

export default HelpPage;
