import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { Mutation, Query } from 'react-apollo';

import { TextareaController } from 'components/v1/HookFields/Textarea'
import { InputController } from 'components/v1/HookFields/Input'
import { Avatar } from 'components/v1/Avatar'
import { Button } from 'components/v1/Button'

import styles from './styles.module.scss'
import { ADD_NEWS } from 'mutation/news';
import { GET_MY_PROFILE } from 'query';
import { GET_NEWS, GET_USER_NEWS } from 'query/news';

export const Post = ({ mutate }) => {

  const [isActive, setIsActive] = useState(false)

  const ref = useRef(null)
  const inputRef = useRef(null)

  const { control, watch, handleSubmit, reset, formState: { isDirty } } = useForm({
    defaultValues: {
      title: '',
      content: '',
    }
  })

  const handleClickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      setIsActive(false)
    }
  }

  useEffect(() => {
    if (ref) {
      document.addEventListener('click', handleClickOutside, false);
    }

    return () => {
      if (ref) {
        document.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [ref])

  const onSubmit = (data) => {
    mutate({ variables: { news: { ...data } } }).then(() => {
      reset()
      setIsActive(false)
    })
  }

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(styles.form, { [styles.active]: isActive })}
      onClick={() => setIsActive(true)}
    >
      <div className={styles.container}>
        <Query query={GET_MY_PROFILE} fetchPolicy={'cache-only'}>
          {({ data, loading }) => {
            if (data.ProfileGetter) {
              return <Avatar size={'circle'} source={data.ProfileGetter.getMyProfile.avatar} className={styles.avatar}/>
            }
            return <Avatar className={styles.avatar}/>
          }}
        </Query>
        <InputController
          control={control}
          name="title"
          ref={inputRef}
          placeholder={isActive ? 'Заголовок' : 'Чем вы хотите поделиться?'}
          className={cn(styles.input, { [styles.inputActive]: isActive || watch('title').length > 0 })}
        />
      </div>
      <div className={styles.extraContent}>
        <TextareaController
          control={control}
          name="content"
          className={styles.textarea}
          placeholder="Ваше сообщение"
        />
        <div className={styles.footer}>
          <Button disabled={!isDirty} type="submit">Создать запись</Button>
        </div>
      </div>
    </form>
  )
}

export const PostForm = (props) => {
  return (
    <Mutation
      mutation={ADD_NEWS}
      refetchQueries={[{ query: GET_USER_NEWS, variables: props.variables }]}
    >
      {mutate => (
        <Post mutate={mutate} />
      )}
    </Mutation>
  )
}
