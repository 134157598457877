import React from 'react';
import Input from 'components/UI/Fields/Input';
import { submitSetPasswordForm } from "components/UI/Forms/SetPasswordForm/submit";
import { Mutation } from "react-apollo";
import { Field, reduxForm, stopSubmit } from 'redux-form';
import Language from "language/index";
import { Button } from 'components/v1/Button';

import classes from './styles.module.scss';


const SetPassword = ({ handleSubmit, isRecovering }) => {
  return (
    <div className={classes.block}>
      <div className={classes.title}>
        {isRecovering ? 'Смена пароля' : 'Создание пароля'}
      </div>
      <form onSubmit={handleSubmit}>
        <Field
          name="password"
          type="password"
          component={Input}
          label={isRecovering ? 'Новый пароль' : Language.Password}
        />
        <div className={classes.description}>
          Не короче 6 символов и цифр. Не используйте личные данные, последовательности (123456, qwerty) и популярные пароли (password).
        </div>
        <Button className={classes.button} type="submit">{isRecovering ? 'Войти' : 'Продолжить'}</Button>
      </form>
    </div>
  )
}

const SetPasswordFormRedux = reduxForm({
  form: 'setPassword',
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(stopSubmit('setPassword'));
    }
  }
})(SetPassword);

export const SetPasswordForm = props => (
  <Mutation
    mutation={props.mutate}
    onCompleted={() => props.nextStep ? props.nextStep() : {}}
  >
    {mutate => (
      <SetPasswordFormRedux
        {...props}
        onSubmit={values => props.submit ? props.submit(values, mutate) : submitSetPasswordForm(values, mutate)}
      />
    )}
  </Mutation>
)
