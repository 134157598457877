import gql from 'graphql-tag';

export const GET_RECCOMEND_USER = gql`
    query($userType: String!) {
        UserGetter {
            getRecommendedUser(userType: $userType) {
                users {
                    _id
                    firstName
                    lastName
                    avatar
                    typeKeys
                    birthday
                    address {
                        countryId
                        stateId
                        cityId
                    }
                    isContacts
                    onlineStatus {
                        isOnline
                        lastTime
                    }
                }
                total
            }
        }
    }
`;

export const GET_RECCOMEND_USER_BY_USER_TYPE = gql`
    query($userType: String!) {
        UserGetter {
            getRecommendedUserByUserType(userType: $userType) {
                users {
                    _id
                    firstName
                    lastName
                    avatar
                    typeKeys
                    birthday
                    address {
                        countryId
                        stateId
                        cityId
                    }
                    isContacts
                }
                total
            }
        }
    }
`;
