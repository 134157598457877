import { gql } from "apollo-boost";

export const SET_BUSSINES_PRODUCT = gql`
    mutation ($offer: BusinessProductInput){
        OfferSetter {
            setBusinessProduct(offer: $offer) {
                _id
                additionallyInform
                description
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                title
                typeOffer
                industry {
                    name
                    groupName
                }
                profitability {
                    amount
                    currency
                }
                status
            }
        }
    }
`;

export const SET_BUSSINES_WOMAN = gql`
    mutation ($offer: BusinessWomanInput){
        OfferSetter {
            setBusinessWoman(offer: $offer) {
                _id
                additionallyInform
                description
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                title
                typeOffer
                industry {
                    name
                    groupName
                }
                isBusinessOwner
                status
            }
        }
    }
`;

export const SET_FREELANCER = gql`
    mutation ($offer: UnemployedInput){
        OfferSetter {
            setUnemployed(offer: $offer) {
                _id
                typeOffer
                additionallyInform
                paymentType
                position
                industry {
                    groupName
                    name
                }
                salary {
                    amount
                    currency
                }
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                status
            }
        }
    }
`;

export const SET_ICO = gql`
    mutation ($offer: ICOInput){
        OfferSetter {
            setICO(offer: $offer) {
                _id
                typeOffer
                image
                title
                campaignType
                startTime
                endTime
                shortDescription
                description
                hardCap {
                    amount
                    currency
                }
                softCap {
                    amount
                    currency
                }
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                status
            }
        }
    }
`;

export const SET_INVESTOR = gql`
    mutation ($offer: InvestorInput){
        OfferSetter {
            setInvestor(offer: $offer) {
                _id
                typeOffer
                experienceInvestment
                additionallyInform
                proportion
                expectedReturn {
                    amount
                    currency
                }
                industry {
                    name
                    groupName
                }
                investmentExperience
                investmentTerm {
                    count
                    typeDate
                }
                investmentVolume {
                    amount
                    currency
                }
                paybackPeriod {
                    count
                    typeDate
                }
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                status
            }
        }
    }
`;

export const SET_NOVATOR = gql`
    mutation ($offer: InnovatorInput){
        OfferSetter {
            setInnovator(offer: $offer) {
                _id
                typeOffer
                additionallyInform
                cost {
                    amount
                    currency
                }
                isImplemented
                name
                patentNumber
                industry {
                    name
                    groupName
                }
                status
            }
        }
    }
`;

export const SET_PROVIDER = gql`
    mutation ($offer: ProviderInput){
        OfferSetter {
            setProvider(offer: $offer) {
                _id
                typeOffer
                deliveryType
                deliveryTime {
                    count
                    typeDate
                }
                description
                title
                maximumDeliveryVolume {
                    count
                    typeVolume
                }
                industry {
                    name
                    groupName
                }
                status
                cost {
                    amount
                    currency
                }
            }
        }
    }
`;


export const CHANGE_STATUS_OFFER = gql`
    mutation ($status: StatusesOffer!, $offerId: String!) {
        OfferSetter {
            changeOfferStatus(status: $status, offerId: $offerId) {
                _id
                status
            }
        }
    }
`;
//
//
// const variables: {offer: {
//   additionallyInform: "test",
//   address: {countryId: 1, stateId: 1000001, cityId: 83},
//   expectedReturn: {amount: 123, currency: "rub"},
//   industry: {groupName: "34b7a4c03f1540c58b15a9b49415bfaf", name: "fe508d085e034335951e77b13685dd61"},
//   investmentExperience: "false",
//   investmentTerm: {count: 123, typeDate: "month"},
//   investmentVolume: {amount: 123, currency: "rub"},
//   paybackPeriod: {count: 123, typeDate: "month"},
// }
// }
