import { ABOUT_COMPANY, TAB_ABOUT, TAB_CONTACTS, TAB_NEWS, TAB_REQUEST } from '../../constants';

export const TABS_ITEMS = [
  {
    label: 'Новости',
    value: TAB_NEWS
  },
  {
    label: 'О себе',
    value: TAB_ABOUT
  },
  {
    label: 'Предложения',
    value: TAB_REQUEST
  },
  {
    label: 'Контакты',
    value: TAB_CONTACTS
  },
]


export const TABS_ITEMS_COMPANY = [
  {
    label: 'Новости',
    value: TAB_NEWS
  },
  {
    label: 'О компании',
    value: ABOUT_COMPANY
  },
  {
    label: 'Предложения',
    value: TAB_REQUEST
  },
  {
    label: 'Контакты',
    value: TAB_CONTACTS
  },
]
