import React, { Fragment, useMemo, useState } from 'react'
import { Mutation, Query } from 'react-apollo';

import { Tabs } from 'components/v1/Tabs';
import { Page } from 'components/v1/Page';

import { GET_MY_PROFILE } from 'query';

import { ChangeAvatar } from 'components/v1/Avatar';
import { ChangePasswordForm } from 'pages/Profile/SettingsForms/ChangePasswordForm';
import { SubscriptionsForm } from 'pages/Profile/SettingsForms/SubscriptionsForm';
import Blacklist from 'pages/Profile/Blacklist';

import styles from 'pages/Profile/styles.module.scss';
import { HeaderMobile } from 'components/v1/HeaderMobile';

const SettingsPage = ({ getMyProfile }) => {

  const [currentTab, setCurrentTab] = useState(0)

  const tabs = useMemo(() => ([
    { label: 'Сменить пароль', value: 0 },
    { label: 'Уведомления', value: 1 },
    { label: 'Черный список', value: 2 },
  ]), [])

  return (
    <Page className={styles.container}>
      <HeaderMobile goBack title="Настройки профиля" />
      <div className={styles.aside}>
        <ChangeAvatar
          value={getMyProfile.avatar}
          firstName={getMyProfile.firstName}
          lastName={getMyProfile.lastName}
          companyName={''}
        />
      </div>
      <Page.Body>
        <div className="content">
          <h1 className={styles.h1}>Настройки</h1>
          <Tabs
            items={tabs}
            activeTab={currentTab}
            onChangeTab={setCurrentTab}
            className={styles.tab}
          />
          <div>
            {currentTab === 0 && (
              <ChangePasswordForm />
            )}
            {currentTab === 1 && (
              <SubscriptionsForm />
            )}
            {currentTab === 2 && (
              <Blacklist />
            )}
          </div>
        </div>
      </Page.Body>
    </Page>
  )
}

export default (props) => {
  return (
    <Query
      query={GET_MY_PROFILE}
      fetchPolicy={'cache-only'}
    >
      {({ ...rest }) => {
        if (rest.loading) {
          return 'loading...'
        }
        if (rest.data.ProfileGetter) {
          return <SettingsPage {...rest.data.ProfileGetter} />
        }
        // if (!rest.error && !rest.loading && rest.data && Object.keys(rest.data).length > 0) {
        // }
        // return null
      }}
    </Query>
  )
}
