import React from 'react'
import { Controller } from 'react-hook-form'
import cn from 'classnames'

import styles from 'components/UI/Fields/styles.module.scss'

const Textarea = ({
  onChange,
  value,
  className,
  rows = 4,
  placeholder,
  onBlur,
  onFocus
}) => {

  return (
    <textarea
      onChange={onChange}
      value={value}
      className={cn(styles.textarea, className)}
      rows={rows}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {value}
    </textarea>
  )
}


export const TextareaController = ({
  control,
  name,
  label,
  ...rest
}) => {

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <Textarea
          onChange={onChange}
          value={value}
          onBlur={() => {
            rest.onBlur && rest.onBlur()
          }}
          onFocus={() => {
            rest.onFocus && rest.onFocus()
          }}
          {...rest}
        />
      )}
    />
  )
}
