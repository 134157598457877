import React, { Component } from 'react';
// import BlackListItem from "./parts/BlackListItem";
import Language from "language/index";
import PreloaderCircle from "components/UI/Prealoders/PreloaderCircle";
import { Query } from "react-apollo";
import { GET_BLACK_LIST } from "query/contacts";
import BlackListItem from "./parts/BlackListItem";
import { REMOVE_FROM_BLACK_LIST } from "mutation/blackList";

class BlackList extends Component {

  removeUser = userId => {
    this.props.client.mutate({
      mutation: REMOVE_FROM_BLACK_LIST,
      variables: { userId },
      refetchQueries: [{query: GET_BLACK_LIST}]
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      styleNotBlocked: { fontSize: '15px', paddingBottom: '15px', color: '#a1a1a1' }
    }
  }

  render() {
    const notUserFromBlackList = this.props.BlackListGetter.getBlackList.total === 0;
    return (
      <div className="centerside tabs-wrapper contacts">
        <div className="side-block">
          <ul className="tabs">
            <li className="active">
              <span>{Language.blackList}</span>
              <i> {this.props.BlackListGetter.getBlackList.total} </i>
            </li>
          </ul>
        </div>
        <div className="side-block contact_list">
          <ul className="tab_content">
            <li style={{ display: 'block' }}>
              <div className="s-messages">
                <ul className="mes-list">
                  {
                    notUserFromBlackList &&
                    <li style={this.state.styleNotBlocked}>
                      {Language.YourBlacklistEmpty}
                    </li>
                  }
                  {
                    this.props.BlackListGetter.getBlackList.blackList.map(el => {
                      return (
                        <BlackListItem
                          removeUser={() => this.removeUser(el._id)}
                          key={el}
                          {...el}
                        />
                      )
                    })
                  }
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default props => {
  return (
    <Query query={GET_BLACK_LIST} fetchPolicy={'cache-and-network'}>
      {({ loading, data, client, error }) => {
        if (error) return <p> Error.... </p>;
        if (!data.BlackListGetter && loading) return <PreloaderCircle/>;
        return <BlackList
          client={client}
          {...props}
          {...data}
        />
      }}
    </Query>
  )
};