import AboutPage from "components/Pages/About/index";
import AdminRequests from "components/Pages/Admin/index";
import BlackList from "components/Pages/BlackList/index";
// import Contacts from "components/Pages/Contacts/index";
import Dialogs from "components/Pages/Dialogs/index";
import HelpPage from "components/Pages/Help/index";
import { Help } from "pages/Help";
import Messages from "components/Pages/Messages/index";
// import NewsPage from "components/Pages/News/index";
import NewsPage from "pages/News";
import PrivacyPolicy from "components/Pages/privacyPolicy/index";
import ChangeProfile from "components/Pages/Profile/ChangeProfile";
import Profile from "components/Pages/Profile/index";
import ProfileUser from "components/Pages/Profile/ProfileUser";
import RecoveryPassword from "components/Pages/RecovertPasspowd/index";
// import Registration from 'components/Pages/Registration';
import Requests from "components/Pages/Requests/index";
import TechnicalSupport from "components/Pages/TechnicalSupport/index";
import TermsPage from "components/Pages/Terms/index";
import TermUseCookiesPage from "components/Pages/TermsUseCookies/index";
import MainLayout from 'components/UI/MainLayout';
import createHistory from 'history/createBrowserHistory';
import { getItem, removeItem } from "libs/localStorage";
import { GET_AUTH, GET_MY_PROFILE } from "query/index";
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { routerReducer } from 'react-router-redux';
import { combineReducers, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { client } from './apollo';
import ProfileUsers from "./components/Pages/Admin/ProfileUsers";
import { YMInitializer } from "react-yandex-metrika";
// import Settings from "components/Pages/Settings/index";
import Settings from "pages/Profile/SettingsProfile";
import Subscribtions from "components/Pages/Subscribtions/index";
import NotificationApp from "./components/UI/NotificationApp";
import ProfileUsersSocial from 'components/Pages/Admin/ProfileUserSocial';
import { MyProfile, UserProfile } from 'pages/Profile';
import { Contacts } from 'pages/Contacts';
import { Request } from 'pages/Request/Request';
import { CreateRequest } from 'pages/Request/CreateRequest';
import EditProfile from 'pages/Profile/EditProfile';
import { Registration } from 'pages/Registration';
import { DialogList } from 'pages/Mesages/components/DialogList/DialogList';
import { MessagesNew } from 'pages/Mesages';
import NotificationsPage from 'pages/Notifications';
import MenuPage from 'pages/Menu';

export const history = createHistory();
const store = createStore(combineReducers({ form: formReducer, routing: routerReducer }));


class App extends React.Component {
  // arrow-down-sign-to-navigate

  state = {
    loading: true
  };

  async componentWillMount() {
    if (getItem('token', false) || getItem('regToken', false)) {
      client.query({ query: GET_MY_PROFILE })
        .then(res => {
          let { ProfileGetter } = res.data;
          client.cache.writeQuery({
            query: GET_AUTH,
            data: { isAuth: true }
          });
          if (ProfileGetter.getMyProfile.__typename === 'Client') {
            history.push('/profile/edit');
          }
          // if (history.location.pathname === '/' || history.location.pathname === '/recovery') {
          //   history.push('/profile/me');
          // }
        })
        .catch(err => {
          removeItem('token');
          history.push('/');
        })
    } else {
      // history.push('/');
    }
    this.setState({ loading: false })
  }

  render() {

    if (this.state.loading) return 'Loading....';

    // const routes = [
    //     { url: '/recovery', component: RecoveryPassword, access: ['user', 'admin'] },
    //     { url: '/profile/me', component: Profile, access: ['user', 'admin'] },
    //     { url: '/profile/edit', component: ChangeProfile, access: ['user', 'admin'] },
    //     { url: '/profile/:id', component: ProfileUser, access: ['user', 'admin'] },
    //     { url: '/contacts', component: Contacts, access: ['user', 'admin'] },
    //     { url: '/nnnotice', component: Requests, access: ['user', 'admin'] },
    //     { url: '/admin', component: AdminRequests, access: ['admin'] },
    //     { url: '/admin_user', component: ProfileUsers, access: ['admin'] },
    //     { url: '/about', component: AboutPage, access: ['user', 'admin'] },
    //     { url: '/terms', component: TermsPage, access: ['user', 'admin'] },
    //     { url: '/help', component: HelpPage, access: ['user', 'admin'] },
    //     { url: '/cookie', component: TermUseCookiesPage, access: ['user', 'admin'] },
    //     { url: '/privacy', component: PrivacyPolicy, access: ['user', 'admin'] },
    //     { url: '/support', component: TechnicalSupport, access: ['user', 'admin'] },
    //     { url: '/news', component: NewsPage, access: ['user', 'admin'] },
    //     { url: '/blacklist', component: BlackList, access: ['user', 'admin'] },
    //     { url: '/messages', component: Dialogs, access: ['user', 'admin'] },
    //     { url: '/messages/:id', component: Messages, access: ['user', 'admin'] },
    // ];

    // 49980619

    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <BrowserRouter>
            <Router history={history}>
              <MainLayout location={history}>
                {/*<YMInitializer*/}
                {/*  accounts={[49980619]}*/}
                {/*  options={{*/}
                {/*    id: 49980619,*/}
                {/*    clickmap: true,*/}
                {/*    trackLinks: true,*/}
                {/*    accurateTrackBounce: true,*/}
                {/*    webvisor: true*/}
                {/*  }}*/}
                {/*  version="2"*/}
                {/*/>*/}
                <NotificationApp/>
                <Switch>
                  <Route exact path="/" component={Registration}/>
                  <Route exact path="/auth" component={Registration}/>
                  <Route path="/recovery" component={Registration}/>
                  <Route path="/profile/me" component={MyProfile}/>
                  <Route path="/profile/edit" component={EditProfile}/>
                  <Route path="/profile/settings" component={Settings}/>
                  <Route path="/profile/:id" component={UserProfile}/>
                  <Route path="/sub/:userId" component={Subscribtions}/>
                  <Route path="/contacts" component={Contacts}/>
                  <Route path="/nnnotice" component={Request}/>
                  <Route path="/nnnotice-my" component={Request}/>
                  <Route path="/admin" component={AdminRequests}/>
                  <Route path="/admin_user" component={ProfileUsers}/>
                  <Route path="/marketer-user" component={ProfileUsersSocial}/>
                  <Route path="/about" component={AboutPage}/>
                  <Route path="/terms" component={TermsPage}/>
                  <Route path="/help" component={HelpPage}/>
                  <Route path="/cookie" component={TermUseCookiesPage}/>
                  <Route path="/privacy" component={PrivacyPolicy}/>
                  <Route path="/support" component={TechnicalSupport}/>
                  <Route path="/news" component={NewsPage}/>
                  <Route path="/blacklist" component={BlackList}/>
                  <Route path="/request" component={Request}/>
                  <Route path="/create" component={CreateRequest}/>
                  <Route path="/edit" component={CreateRequest}/>
                  <Route path="/dialoglist" component={MessagesNew}/>
                  <Route path="/helpnew" component={Help}/>
                  <Route path="/notifications" component={NotificationsPage}/>
                  <Route path="/menu" component={MenuPage}/>



                  <Switch>
                    <Route exact path="/messages" component={MessagesNew}/>
                    <Route exact path="/messages/:id" component={MessagesNew}/>
                  </Switch>

                  {/*  TODO: need add 404 page */}
                  {/*<Route path="/" component={Page404} />*/}
                </Switch>
              </MainLayout>
            </Router>
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    )
  }
}


export default App;
