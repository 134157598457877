import React from 'react';
import ModalWindow from "components/UI/ModalWindow/index";
import IdeaForm from "components/UI/Forms/IdeaForm/index";
import showError from "libs/showFormErrors";
import { required } from "libs/fieldValidation";
import { emails } from "libs/validationsForms";
import { phones } from "../../../libs/validationsForms";
import { Mutation } from "react-apollo";
import { SEND_HAVE_IDEA } from "mutation/index";
import { ActionNotification } from "../../UI/Notifications/action";

class ModalIdeaForm extends React.Component {

  state = {
    preloading: false
  }

  sendIdeaForm = values => {
    const { name, phone, email, text } = values;

    let validateObj = {
      'name': [required(name)],
      'phone': [required(phone)],
      'email': [required(email)],
      'text': [required(text)],
    };

    if (email) validateObj['email'] = [emails(email)];
    if (phone) validateObj['phone'] = [phones(phone)];


    showError(validateObj);

    this.setState({ preloading: true });
    this.props.mutate({ variables: {...values, phone: phone.replace(/\D+/g, "")} })
      .then(res => {
        this.setState({ preloading:false });
        if (res.data.SupportSetter.iHaveIdea) {
          this.props.close();
          ActionNotification('2', 'Спасибо за идею. Мы рассмотрим ее')
        }
      })
  };


  render() {
    if (!this.props.isOpen) return null;
    return (
      <ModalWindow handleClose={this.props.close} title="Предложить идею">
        <IdeaForm preloading={this.state.preloading} onSubmit={this.sendIdeaForm}/>
      </ModalWindow>
    )
  }
}

export default props => {
  return (
    <Mutation mutation={SEND_HAVE_IDEA}>
      {mutate => (
        <ModalIdeaForm mutate={mutate} {...props}/>
      )}
    </Mutation>
  )
}
;