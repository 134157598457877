import React from 'react';

import { MenuList } from 'components/v1/LeftMenu/MenuList';
import { useSizeXS } from 'libs/useMediaQuery';

import { LookingForIcon } from 'components/v1/Icons/LookingForIcon';
import { NotificationLeftIcon } from 'components/v1/Icons/NotificationLeftIcon';
import { RequestNetworkIcon } from 'components/v1/Icons/RequestNetworkIcon';
import { MyRequestIcon } from 'components/v1/Icons/MyRequestIcon';

import styles from './styles.module.scss'
import { EditIcon } from 'components/v1/Icons/EditIcon';
import { SettingsIcon } from 'components/v1/Icons/SettingsIcon';
import { HelpIcon } from 'components/v1/Icons/HelpIcon';
import { logout } from 'libs/logout';
import Language from 'language';
import { LogoutIcon } from 'components/v1/Icons/LogoutIcon';

import ProfilePreview from './components/ProfilePreview'
import { HeaderMobile } from 'components/v1/HeaderMobile';

const MenuPage = () => {

  const isMobile = useSizeXS()

  const mainMenu = [
    { href: '/contacts', label: 'Поиск контактов', icon: <LookingForIcon/>, badge: 0 },
    { href: '/notifications', label: 'Уведомления', icon: <NotificationLeftIcon/>, badge: 0 },
    // { href: '/', label: 'Закладки', icon: <BookmarkIcon/>, badge: 0 },
  ]

  const extraMenu = [
    { href: '/nnnotice', label: 'Предложения сети', icon: <RequestNetworkIcon/> },
    { href: '/nnnotice-my', label: 'Мои предложения', icon: <MyRequestIcon/>, badge: 0 },
    // { href: '/', label: 'Избранные предложения', icon: <FavoriteIcon/> },
    // { href: '/', label: 'Архив предложений', icon: <ArchiveOutlineIcon/>, badge: 0 },
  ]

  const nav = [
    { href: '/profile/edit', label: 'Редактировать профиль', icon: <EditIcon /> },
    { href: '/profile/settings', label: 'Настройки', icon: <SettingsIcon /> },
    { href: '/help', label: 'Помощь', icon: <HelpIcon /> },
    { onClick: logout, label: Language.exit, icon: <LogoutIcon /> },
  ]

  return (
    <div className={styles.container}>
      <HeaderMobile title="Меню" />
      <ProfilePreview />
      <MenuList items={mainMenu} />
      <div className="separator separator-mini" />
      <MenuList items={extraMenu} />
      <div className="separator separator-mini" />
      <MenuList items={nav} />
    </div>
  )
}

export default MenuPage
