import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import { Field } from "redux-form";
import Language from "language/index";


export default class Volume extends PureComponent {

    static propTypes = {
        nameField: PropTypes.string,
        label: PropTypes.string
    };

    static defaultProps = {
        label: 'Volume',
        nameField: 'volume'
    };

    render() {
        const { nameField, label } = this.props;
        return (
            <div className="element">
                <div className="row clearfix">
                    <Field
                        name={`${nameField}[count]`}
                        type="number"
                        maxLength="40"
                        component={Input}
                        label={label}
                        className="col-10"
                    />
                    <Field
                        name={`${nameField}[typeVolume]`}
                        component={Select}
                        className="col-2"
                        options={[
                          { value: 'mm', label: Language.mm },
                          { value: 'cm', label: Language.cm },
                          { value: 'km', label: Language.km },
                          { value: 'inch', label: Language.inch },
                          { value: 'foot', label: Language.foot },
                          { value: 'yard', label: Language.yard },
                          { value: 'mile', label: Language.mile },
                          { value: 'mm2', label: Language.mm2 },
                          { value: 'cm2', label: Language.cm2 },
                          { value: 'm2', label: Language.m2 },
                          { value: 'ha', label: Language.ha },
                          { value: 'km2', label: Language.km2 },
                          { value: 'inch2', label: Language.inch2 },
                          { value: 'foot2', label: Language.foot2 },
                          { value: 'yard2', label: Language.yard2 },
                          { value: 'a', label: Language.a },
                          { value: 'mm3', label: Language.mm3 },
                          { value: 'cm3', label: Language.cm3 },
                          { value: 'ml', label: Language.ml },
                          { value: 'l', label: Language.l },
                          { value: 'dm3', label: Language.dm3 },
                          { value: 'm3', label: Language.m3 },
                          { value: 'inch3', label: Language.inch3 },
                          { value: 'foot3', label: Language.foot3 },
                          { value: 'yard3', label: Language.yard3 },
                          { value: 'mg', label: Language.mg },
                          { value: 'k', label: Language.k },
                          { value: 'g', label: Language.g },
                          { value: 'kg', label: Language.kg },
                          { value: 't', label: Language.t },
                          { value: 'kt', label: Language.kt }
                        ]}
                    />
                </div>
            </div>
        )
    }
};