import { CloseNotification } from "components/UI/Notifications/action";
import { GET_NOTIFICATION } from "query/index";
import React, { Component } from 'react';
import { Query } from "react-apollo";
import NotificationError from './error';
import NotificationInfo from './info';
import NotificationSuccess from './success';
import NotificationWarnings from './warnings';
import * as ReactDOM from 'react-dom';


class Notifications extends React.Component {
    time = null;

    closeNotification = () => {
        CloseNotification();
    };

    componentWillReceiveProps(nextProps) {
        clearTimeout(this.time);
        this.time = setTimeout(() => {
            this.closeNotification();
        }, 6000)
    }

    render() {
        const { notification } = this.props;
        if (!notification) return null;
        switch (notification.type) {
            case '1':
                return ReactDOM.createPortal( <NotificationError close={this.closeNotification} text={notification.text}/> ,
                    document.getElementById('dialog'));
            case '2':
                return ReactDOM.createPortal( <NotificationSuccess close={this.closeNotification} text={notification.text}/>,
                    document.getElementById('dialog'));
            case '3':
                return ReactDOM.createPortal(  <NotificationWarnings close={this.closeNotification} text={notification.text}/>,
                    document.getElementById('dialog'));
            case '4':
                return ReactDOM.createPortal(  <NotificationInfo close={this.closeNotification} text={notification.text}/>,
                    document.getElementById('dialog'));
            default:
                return null;
        }
    }
}


export default props => {
    return (
        <Query query={GET_NOTIFICATION}>
            {({ error, data }) => {
                if (error) return null;
                return <Notifications {...props} {...data}/>
            }}
        </Query>
    )
}
