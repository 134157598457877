import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import Industry from "components/UI/Forms/GroupFields/Industry";
import Region from "components/UI/Forms/GroupFields/Region";
import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from "redux-form";
import { SET_BUSSINES_WOMAN } from "mutation/offer";
import { Mutation } from "react-apollo";
import { submitBussinesWomanForm } from "./submit";
import { GET_OFFERS_LIST } from "query/offers";
import Language from "language/index";
import { Button } from 'components/v1/Button';
import Money from 'components/UI/Forms/GroupFields/Money';


class BusinessWomanForm extends Component {

    render() {
        const { handleSubmit, dispatch, initialValues, loading } = this.props;
        return (
            <div className="form fullWidth">
                <form className="new_application" id="new_request_businesswoman" onSubmit={handleSubmit}>
                    <Field
                        name="title"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.naming} *`}
                    />
                    <Field
                        name="description"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.Description} *`}
                    />
                    <Industry
                        label={`${Language.industry} *`}
                        {...initialValues}
                    />
                    <Region
                        countryLabel={`${Language.Country} *`}
                        regionLabel={`${Language.regionResidence} *`}
                      {...initialValues}
                    />
                    {/*<Money*/}
                    {/*  nameField="profitability"*/}
                    {/*  label={`${Language.ReturnsInvestment} *`}*/}
                    {/*/>*/}
                    <Field
                        name="isBusinessOwner"
                        component={Select}
                        label={`${Language.BusinessOwner}`}
                        options={[
                            { value: 'true', label: 'Да' },
                            { value: 'false', label: 'Нет' },
                        ]}
                    />
                    <Field
                        name="additionallyInform"
                        type="text"
                        maxLength="40"
                        component={Input}
                        label={`${Language.additionalInformationBlock}`}
                    />
                    <Button type="submit" disabled={loading} className="orange-btn"> {Language.save} </Button>

                </form>
            </div>
        );
    }
}


BusinessWomanForm = reduxForm({
    form: 'businessWomanForm',
    onChange: (values, dispatch, props) => {
        if (props.submitFailed) {
            dispatch(stopSubmit('businessWomanForm'));
        }
    }
})(BusinessWomanForm);


export default props => {
  const initialValues = props.initialValues ? props.initialValues : {};
  return (
    <Mutation mutation={SET_BUSSINES_WOMAN} refetchQueries={[{query: GET_OFFERS_LIST, variables: { isMy: true}}]}>
      {(mutate,{ loading }) => (
        <BusinessWomanForm initialValues={initialValues}  {...props} loading={loading}
                         onSubmit={values => submitBussinesWomanForm(values, mutate, props.cb)}/>
      )}
    </Mutation>
  )
};
