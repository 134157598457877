import Language from 'language';

const SEX_OPTIONS = [
  { value: 1, label: Language.Male },
  { value: 2, label: Language.Female }
]

const PROFILE_TYPES_OPTIONS = [
  { value: 'Investor', label: Language.investor },
  { value: 'Innovator', label: Language.coinvestor },
  { value: 'BusinessProduct', label: Language.novator },
  { value: 'Investor', label: Language.sellerBusiness },
  { value: 'Investor', label: Language.supplier },
  { value: 'BusinessWoman', label: Language.businessWoman },
  { value: 'Investor', label: Language.designer },
  { value: 'Unemployed', label: Language.unemployed },
  { value: 'Investor', label: Language.employer },
  { value: 'Investor', label: Language.hrManager },
  { value: 'Investor', label: Language.expert },
]

const MONTH_OPTIONS = [
  { value: '01', label: 'Январь', days: 31 },
  { value: '02', label: 'Февраль', days: 28 },
  { value: '03', label: 'Март', days: 31 },
  { value: '04', label: 'Апрель', days: 30 },
  { value: '05', label: 'Май', days: 31 },
  { value: '06', label: 'Июнь', days: 30 },
  { value: '07', label: 'Июль', days: 31 },
  { value: '08', label: 'Август', days: 31 },
  { value: '09', label: 'Сентябрь', days: 30 },
  { value: '10', label: 'Октябрь', days: 31 },
  { value: '11', label: 'Ноябрь', days: 30 },
  { value: '12', label: 'Декабрь', days: 31 },
]

const ACCOUNT_TYPE_OPTIONS = [
  {value: 'EntityProfile', label: 'Юр. лицо'},
  {value: 'IndividualProfile', label: 'Физ. лицо'}
]

const TYPE_DATE_OPTIONS = [
  { value: 'month', label: 'месяц/ев' },
  { value: 'year', label: 'лет/год' },
]

export { SEX_OPTIONS, PROFILE_TYPES_OPTIONS, MONTH_OPTIONS, ACCOUNT_TYPE_OPTIONS, TYPE_DATE_OPTIONS }
