import React from 'react';
import cn from 'classnames';
import { Mutation } from "react-apollo";
import { Link } from 'react-router-dom';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import Input from 'components/UI/Fields/Input';
import { submitLoginForm } from "components/UI/Forms/LoginForm/submit";
import { updateLoginForm } from "components/UI/Forms/LoginForm/update";
import Language from 'language/index';
import { AUTH } from "mutation/index";
import { Button } from 'components/v1/Button';

import classes from './styles.module.scss';
import forwardTo from 'libs/forwardTo';


const LoginFormMainPage = props => {

  const goReg = e => {
    e.preventDefault();
    forwardTo('/');
  }

  const { handleSubmit } = props;
  return (
    <div className={classes.block}>
      <div className={classes.title}> Авторизация </div>
      <form onSubmit={handleSubmit}>
        <Field
          name="phone"
          type="text"
          component={Input}
          label={Language.login}
          mask="+7 (999) 999-99-99"
          className={classes.field}
        />
        <Field
          name="password"
          type="password"
          component={Input}
          label={Language.Password}
          className={classes.field}
        />
        <div className={cn(classes.confident, classes.forgetPasword)}>
          <Link to={'/recovery'}>
            Забыли пароль?
          </Link>
        </div>
        <div className={classes.actions}>
          <Button className={classes.button} type="submit">Войти</Button>
          <Button onClick={goReg} variant="outlined" className={classes.button}>Регистрация</Button>
        </div>
      </form>
    </div>
  )
}


const LoginFormMainPageRedux = reduxForm({
  form: 'loginForm',
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(stopSubmit('loginForm'));
    }
  }
})(LoginFormMainPage);

export const AuthForm = props => {
  return (
    <Mutation
      mutation={AUTH}
      update={updateLoginForm}
    >
      {(mutate, client) => (
        <LoginFormMainPageRedux
          {...props}
          onSubmit={values => submitLoginForm(values, mutate, client)}
        />
      )}
    </Mutation>
  )
};
