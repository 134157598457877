import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import classes from './styles.module.scss';

export const Avatar = ({ source, type = 'main', size, className }) => {
  return (
    <img
      src={source || '/templates/img/user_avatar_default.svg'}
      className={cn(classes.avatar, classes[type], classes[size], className)}
      alt="avatar"
    />
  )
};

Avatar.prototype = {
  source: PropTypes.string.isRequired,
  size: 'main' | 'circle',
  className: PropTypes.string,
}
