import React from 'react';
import Language from '../language/index';

export const GetLabel = {
    investor: {
        additionallyInform: Language.additionalInformationBlock,
        expectedReturn: Language.ExpectedReturn,
        industry: Language.industry,
        investmentExperience: Language.ExperienceInvestment,
        investmentTerm: Language.InvestmentTerm,
        investmentVolume: Language.VolumeInvestments,
        paybackPeriod: Language.PaybackTime,
        address: Language.InvestmentRegion,
        proportion: Language.sharedInPraticipation
    },
    coinvestor: {
        additionallyInform: Language.additionalInformationBlock,
        expectedReturn: Language.ExpectedReturn,
        industry: Language.CoinvestmentIndustry,
        investmentExperience: Language.ExperienceInvestment,
        investmentTerm: Language.PeriodCoinvestment,
        investmentVolume: Language.volumeCoinvestments,
        paybackPeriod: Language.PaybackTime,
        address: Language.CoinvestmentRegion,
        sharedParticipation: Language.EquityParticipation,
    },
    novator: {
        additionallyInform: Language.additionalInformationBlock,
        cost: Language.Cost,
        industry: Language.IndustryIntroduction,
        isImplemented: Language.Introduced,
        name: Language.naming,
        patentNumber: Language.patent,
    },
    businessproduct: {
        additionallyInform: Language.additionalInformationBlock,
        description: Language.Description,
        industry: Language.industry,
        profitability: Language.ReturnsInvestment,
        address: Language.regionResidence,
        title: Language.naming,
    },
    provider: {
        cost: Language.Cost,
        deliveryTime: Language.DeliveryTime,
        deliveryType: Language.DeliveryType,
        description: Language.Description,
        industry: Language.industry,
        maximumDeliveryVolume: Language.MaximumScopeSupply,
        title: Language.naming,
    },
    businesswoman: {
        additionallyInform: Language.additionalInformationBlock,
        description: Language.Description,
        isBusinessOwner: Language.BusinessOwner,
        industry: Language.industry,
        address: Language.regionResidence,
        title: Language.naming,
    },
    projectant: {
        description: Language.Description,
        industry: Language.industry,
        address: Language.regionResidence,
        readyLevel: Language.LevelImplementation,
        title: Language.naming,
    },
    unemployed: {
        additionallyInform: Language.additionalInformationBlock,
        industry: Language.industry,
        position: Language.Position,
        salary: Language.Income,
        address: Language.regionResidence,
    },
    employer: {
        additionallyInform: Language.additionalInformationBlock,
        employeesNumber: Language.NumberEmployees,
        position: Language.Position,
        workExperience: Language.Experience,
        salary: Language.Salary,
        address: Language.WorkplaceGeography,
    },
    resume: {
        additionallyInform: Language.additionalInformationBlock,
        additionally_select: '',
        name: Language.naming,
        edu: Language.education,
        workExperience: Language.LabourActivity,
        salary: Language.Salary,
        workPlace: Language.IntendedPlaceWork,
    },
    vacancy: {
        companyName: Language.NameCompany,
        position: Language.Position,
        workExperience: Language.WorkExperienceApplicant,
        salary: Language.Salary,
        address: Language.WorkplaceGeography,
        industry: Language.industry,
        employeesNumber: Language.NumberEmployees,
        specialRequirements: Language.SpecialRequirements,
        additionallyInform: Language.additionalInformationBlock,
    },
    expert: {
        serviceType: Language.TypeService,
        activityField: Language.FieldWork,
        experience: Language.Experience,
        industry: Language.industry,
        additional: Language.additionalInformationBlock,
    },
    ico: {
        title: Language.CompanyName,
        campaignType: Language.campaignType,
        softCap: 'Soft cap',
        hardCap: 'Hard cap',
        startTime: 'Start time',
        endTime: 'End time',
        shortDescription: Language.shortDescription,
        description: Language.fullDescription,
    },
};
