import React, { useMemo } from 'react'
import { useFieldArray } from 'react-hook-form';

import { FormfieldArray } from 'pages/Profile/EditForms/FormfieldArray';

export const educationValues = {
  institutionName: '',
  specialty: '',
  startDate: '',
  completeDate: '',
}

export const EducationForm = ({ control, errors }) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: "educations",
  });

  const fieldNames = useMemo(() => {
    return [
      { label: 'Учебное заведение', name: 'institutionName' },
      { label: 'Специальность', name: 'specialty' },
      { label: 'Год начала обучения', name: 'startDate' },
      { label: 'Год окончания обучения', name: 'completeDate' },
      { label: 'Добавить образование' },
    ]
  }, [])

  return (
    <FormfieldArray
      control={control}
      fields={fields}
      append={append}
      remove={remove}
      fieldNames={fieldNames}
      name="educations"
      appendValues={educationValues}
      errors={errors}
    />
  )
}
