import { gql } from "apollo-boost";

export const GET_OFFERS_LIST = gql`
    query(
    $page: Int,
    $count: Int,
    $typeOffer: String,
    $isMy: Boolean,
    $archive: Boolean,
    $countryId: Int,
    $stateId: Int,
    $cityId: Int,
    $industryGroupName: String,
    $industryName: String,
    ) {
        OfferGetter {
            getOfferList(
                page: $page,
                count: $count,
                typeOffer: $typeOffer,
                isMy: $isMy,
                archive: $archive,
                countryId: $countryId,
                stateId: $stateId,
                cityId: $cityId,
                industryGroupName: $industryGroupName,
                industryName: $industryName
            ) {
                offers {
                    _id
                    status
                    user {
                        _id
                        firstName
                        lastName
                        avatar
                        typeKeys
                    }
                    typeOffer
                    createDate
                    numberOffer
                    countWatched

                    ... on BusinessProduct {
                        title
                        description
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        profitability {
                            amount
                            currency
                        }
                        additionallyInform
                    }



                    ... on BusinessWoman {
                        title
                        description
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        isBusinessOwner
                        additionallyInform
                    }


                    ... on ICO {
                        image
                        title
                        campaignType
                        startTime
                        endTime
                        shortDescription
                        description
                        hardCap {
                            currency
                            amount
                        }
                        softCap {
                            currency
                            amount
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                    }


                    ... on Innovator {
                        name
                        industry {
                            groupName
                            name
                        }
                        patentNumber
                        cost {
                            amount
                            currency
                        }
                        isImplemented
                        additionallyInform
                    }

                    ... on Investor {
                        investmentVolume {
                            amount
                            currency
                        }
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        investmentTerm {
                            count
                            typeDate
                        }
                        paybackPeriod {
                            count
                            typeDate
                        }
                        expectedReturn {
                            amount
                            currency
                        }
                        investmentExperience
                        proportion
                        additionallyInform
                    }



                    ... on Provider {
                        deliveryType
                        deliveryTime {
                            count
                            typeDate
                        }
                        description
                        title
                        maximumDeliveryVolume {
                            count
                            typeVolume
                        }
                        industry {
                            groupName
                            name
                        }
                        cost {
                            amount
                            currency
                        }
                    }


                    ... on Unemployed {
                        
                        salary {
                            amount
                            currency
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        industry {
                            groupName
                            name
                        }
                        paymentType
                        position
                        additionallyInform
                    }
                }
                total
            }
        }
    }
`;

export const GET_OFFERS_ARCHIVE = gql`
    query($page: Int, $count: Int, $isMy: Boolean, $archive: Boolean){
        OfferGetter {
            getOfferList(page: $page, count: $count, isMy: $isMy, archive: $archive) {
                offers {
                    _id
                    createDate
                    status
                }
                total
            }
        }
    }
`;


export const GET_OFFERS_BU_USER_ID = gql`    
    query(
    $page: Int,
    $count: Int,
    $userId: String!
    ) {
        OfferGetter {
            getOfferByUserId(
                page: $page,
                count: $count,
                UserId: $userId,
            ) {
                offers {
                    _id
                    status
                    user {
                        _id
                        firstName
                        lastName
                    }
                    typeOffer
                    createDate
                    numberOffer
                    countWatched

                    ... on BusinessProduct {
                        title
                        description
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        profitability {
                            amount
                            currency
                        }
                        additionallyInform
                    }



                    ... on BusinessWoman {
                        title
                        description
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        isBusinessOwner
                        additionallyInform
                    }


                    ... on ICO {
                        image
                        title
                        campaignType
                        startTime
                        endTime
                        shortDescription
                        description
                        hardCap {
                            currency
                            amount
                        }
                        softCap {
                            currency
                            amount
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                    }


                    ... on Innovator {
                        name
                        industry {
                            groupName
                            name
                        }
                        patentNumber
                        cost {
                            amount
                            currency
                        }
                        isImplemented
                        additionallyInform
                    }

                    ... on Investor {
                        investmentVolume {
                            amount
                            currency
                        }
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        investmentTerm {
                            count
                            typeDate
                        }
                        paybackPeriod {
                            count
                            typeDate
                        }
                        expectedReturn {
                            amount
                            currency
                        }
                        investmentExperience
                        additionallyInform
                    }



                    ... on Provider {
                        deliveryType
                        deliveryTime {
                            count
                            typeDate
                        }
                        description
                        title
                        maximumDeliveryVolume {
                            count
                            typeVolume
                        }
                        industry {
                            groupName
                            name
                        }
                        cost {
                            amount
                            currency
                        }
                    }


                    ... on Unemployed {
                        industry {
                            groupName
                            name
                        }
                        address {
                            countryId
                            stateId
                            cityId
                        }
                        salary {
                            amount
                            currency
                        }
                        paymentType
                        position
                        additionallyInform
                    }
                }
                total
            }
        }
    }
`;
