import React, { useState } from 'react'
import cn from 'classnames'
import { useForm } from 'react-hook-form';
import { Mutation, Query } from 'react-apollo';

import { TextareaController } from 'components/v1/HookFields/Textarea';
import { Avatar } from 'components/v1/Avatar'
import { SendIcon } from 'components/v1/Icons/SendIcon';

import styles from './styles.module.scss'
import { GET_COMMENTS } from 'query/news';
import { ADD_COMMENT } from 'mutation/news';

const CommentItem = ({ firstName, lastName, text }) => {

  return (
    <div className={styles.comment}>
      <div className={styles.avatarComment}>
        <Avatar className={styles.avatar} />
      </div>
      <div>
        <h4>{firstName} {lastName}</h4>
        <div className={styles.text}>{text}</div>
        <div className={styles.commentData}>
          <div className={styles.date}>17 минут назад</div>
        </div>
      </div>
    </div>
  )
}

const CommentList = ({ getNewsComments, newsId }) => {
  console.log(getNewsComments);

  return (
    <div className={styles.comments}>
      <CommentItem firstName={'Сергей'} lastName={'Корасёв'} text={'Идейные соображения высшего порядка'} />
      <CommentItem firstName={'Мария'} lastName={'Тумаева'} text={'И нет сомнений, что сторонники тоталитаризма в науке, вне зависимости от их уровня, должны быть рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок. '} />
      <div role="button" className={styles.next}>Показать следующие комментарии</div>
      <Mutation mutation={ADD_COMMENT}>
        {mutate => (
          <CommentForm mutate={mutate} newsId={newsId} />
        )}
      </Mutation>
    </div>
  )
}

const CommentForm = ({ mutate, newsId }) => {
  const [focusComment, setFocusComment] = useState(false)

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      text: ''
    }
  })

  const onSubmit = (data) => {
    mutate({ variables: { newsId, content: data.content } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.commentForm}>
      <div className={styles.avatarComment}>
        <Avatar className={styles.avatar} />
      </div>
      <div className={styles.commentBox}>
        <TextareaController
          control={control}
          name="content"
          placeholder="Написать комментарий"
          className={cn(styles.commentTextarea, { [styles.activeComment]: focusComment || watch('text').length > 0 })}
          onFocus={() => {
            setFocusComment(true)
          }}
          onBlur={() => {
            setFocusComment(false)
          }}
        />
        <button type="submit" className={styles.send}>
          <SendIcon width={17} height={17} />
        </button>
      </div>
    </form>
  )
}



export const Comments = ({ newsId }) => {
  return (
    <Query fetchPolicy={'network-only'} query={GET_COMMENTS} variables={{ page: 0, count: 10, newsId }}>
      {({ data, loading }) => {
        if (loading) {
          return 'Loading...'
        }
        if (data.NewsGetter) {
          return <CommentList {...data.NewsGetter} newsId={newsId} />
        }
      }}
    </Query>
  )
}


