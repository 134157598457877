import React from 'react';
import { Mutation } from 'react-apollo';

import { MessageIcon } from 'components/v1/Icons/MessageIcon';
import { Button } from 'components/v1/Button';
import { DeleteUserIcon } from 'components/v1/Icons/DeleteUserIcon';
import { dialogueChecking } from 'components/UI/Contacts/dialogueChecking';
import { DELETE_CONTACTS } from 'mutation/Contacts';
import { removeContact } from 'libs/updateQuery';
import { GET_CONTACTS } from 'query/contacts';


export const MyActionsComponent = ({ _id, removeContact }) => {
  return (
    <>
      <Button icon={<MessageIcon />} variant="outlined" onClick={() => dialogueChecking(_id)}/>
      <Button icon={<DeleteUserIcon />} variant="outlined" onClick={removeContact} />
    </>
  );
};

// TODO: переделать на хуки!!!
export const MyActions = props => {
  return (
    <Mutation
      mutation={DELETE_CONTACTS}
      variables={{ userId: props._id }}
      refetchQueries={[{ query: GET_CONTACTS, variables: { status: 'is_added', count: 50 } }]}
      update={(cache, { data }) => removeContact(cache, { data }, props.type)}
    >
      {(removeContact, { client, called }) => {
        return <MyActionsComponent removeContact={removeContact} client={client}  {...props} called={called}/>
      }}
    </Mutation>
  )
};


