import Language from "language/index";
import { required } from "libs/fieldValidation";
import showError from "libs/showFormErrors";
import { ActionNotification } from "../../../Notifications/action";

export const submitInvestForm = (values, mutate, cb) => {
    const { investmentVolume, industry, address, investmentTerm, paybackPeriod, expectedReturn, additionallyInform } = values;


    let validateObj = {
        'investmentVolume.amount': [required(investmentVolume?.amount)],
        'industry.groupName': [required(industry)],
        'address.countryId': [required(address)],
        'address.stateId': [required(address['stateId'])],
        'investmentTerm.count': [required(investmentTerm?.count)],
        'paybackPeriod.count': [required(paybackPeriod?.count)],
        'expectedReturn.amount': [required(expectedReturn?.amount)],
        'additionallyInform': [required(additionallyInform)],
    };


    if (industry) {
        validateObj['industry.groupName'] = [required(industry.groupName)];
        validateObj['industry.name'] = [required(industry.name)];
    }

    showError(validateObj);

    const fields = ['investmentVolume', 'industry', 'address', 'investmentTerm', 'paybackPeriod', 'expectedReturn', 'investmentExperience', 'additionallyInform', '_id', 'proportion'];

    const value = { ...values };

    for (let key in value) {
        if (value[key] && value[key].hasOwnProperty('__typename')) delete value[key].__typename;
        if (!fields.some(el => el === key)) {
            delete value[key];
        } else if (value[key] === null) {
            delete value[key];
        } else if (value[key].hasOwnProperty('amount')) {
            value[key].amount = parseInt(value[key].amount);
        } else if (value[key].hasOwnProperty('count')) {
            value[key].count = parseInt(value[key].count);
        } else if (key === 'proportion') {
            value[key] = parseInt(value[key]);
        }
    }

    mutate({ variables: { offer: { ...value } } })
        .then(res => {
            if (cb) {
                cb();
                ActionNotification('2', Language.applicationChanged);
            } else {
                ActionNotification('2', Language.applicationSuccessfullySent);
            }
        })
        .catch(err => {
            if (err.hasOwnProperty('graphQLErrors') && err.graphQLErrors[0].hasOwnProperty('code') && err.graphQLErrors[0].hasOwnProperty('description')) {
                ActionNotification('1', err.graphQLErrors[0].description);
            } else {
                ActionNotification('1', "Произошла ошибка");
            }
        })
};
