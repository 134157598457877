import gql from 'graphql-tag';

export const UPDATE_DIALOGS = gql`
    fragment user on User {
        _id
        firstName
        lastName
        avatar
        typeKeys
        birthday
        
        onlineStatus {
            isOnline
        }
    }

    subscription {
        updateDialogs {
            _id
            userTo {
                ...user
            }
            userFrom {
                ...user
            }
            lastMsg {
                _id
                dialogId
                senderId
                text
                isRead
                links
                createDate
            }
            countNewMsg
            offer {
                _id
                typeOffer
            }
        }
    }
`;

export const UPDATE_MESSAGES = gql`
    subscription($dialogId: String!) {
        updateMessages(dialogId: $dialogId) {
            _id
            dialogId
            senderId
            text
            isRead
            links
            createDate
            favorite
        }
    }
`;

export const UPDATE_CONTACTS = gql`
    subscription {
        updateContacts {
            _id
            firstName
            lastName
            midName
            avatar
            sex
            isContacts
            isLockedUser
            isLockedYou
            industry {
                name
                groupName
            }
            typeKeys
            birthday
            address {
                countryId
                stateId
                cityId
                countryName
                stateName
                cityName
            }
            status
            onlineStatus {
                isOnline
                lastTime
            }
            tags
        }
    }
`;

export const UPDATE_COUNT_MSG = gql`
    subscription {
        updateCountNewMessages
    }
`;

export const UPDATE_COUNT_CONTACTS = gql`
    subscription {
        updateRequestContactsCount
    }
`;
