import React from 'react'

import { MenuList } from './MenuList';

import { FavoriteIcon } from 'components/v1/Icons/FavoriteIcon';
import { MyRequestIcon } from 'components/v1/Icons/MyRequestIcon';
import { RequestNetworkIcon } from 'components/v1/Icons/RequestNetworkIcon';
import { ProfileIcon } from 'components/v1/Icons/ProfileIcon';
import { NewsIcon } from 'components/v1/Icons/NewsIcon';
import { MessengerIcon } from 'components/v1/Icons/MessengerIcon';
import { LookingForIcon } from 'components/v1/Icons/LookingForIcon';
import { BookmarkIcon } from 'components/v1/Icons/BookmarkIcon';
import { ArchiveOutlineIcon } from 'components/v1/Icons/ArchiveOutlineIcon';
import { NotificationLeftIcon } from 'components/v1/Icons/NotificationLeftIcon';

import styles from './styles.module.scss'
import { Link } from 'react-router-dom';

export const LeftMenu = ({ content }) => {

  const mainMenu = [
    { href: '/profile/me', label: 'Мой профиль', icon: <ProfileIcon /> },
    { href: '/news', label: 'Новости', icon: <NewsIcon/>, badge: 0 },
    { href: '/messages', label: 'Мессенджер', icon: <MessengerIcon/>, badge: 0 },
    { href: '/contacts', label: 'Поиск контактов', icon: <LookingForIcon/>, badge: 0 },
    { href: '/notifications', label: 'Уведомления', icon: <NotificationLeftIcon/>, badge: 0 },
    // { href: '/', label: 'Закладки', icon: <BookmarkIcon/>, badge: 0 },
  ]

  const extraMenu = [
    { href: '/nnnotice', label: 'Предложения сети', icon: <RequestNetworkIcon/> },
    { href: '/nnnotice-my', label: 'Мои предложения', icon: <MyRequestIcon/>, badge: 0 },
    // { href: '/', label: 'Избранные предложения', icon: <FavoriteIcon/> },
    // { href: '/', label: 'Архив предложений', icon: <ArchiveOutlineIcon/>, badge: 0 },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.aside}>
        {content && content}
        {!Boolean(content) && <MenuList items={mainMenu} />}
        <div className="separator separator-mini" />
        <MenuList items={extraMenu} />
      </div>
      <div className={styles.footer}>
        <Link to={'/about'}>О проекте</Link>
        <Link to={'/terms'}>Правила и документация</Link>
      </div>
    </div>
  )
}
