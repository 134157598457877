import { gql } from "apollo-boost";

export const ADD_NEWS_ADMIN = gql`
    mutation ($news: NewsInput){
        NewsSetter {
            addNews(news: $news) {
                ... on NewsSystem {
                    _id
                    newsType
                    content
                    youWatched
                    countWatched
                    createDate
                }
            }
        }
    }
`;


// enum StatusesOffer {
//     MODERATION
//     DECLINED
//     ARCHIVED
//     ACCEPTED
// }


export const CHANGE_OFFER_STATUS = gql`
    mutation ($status: StatusOffer!, $offerId: String!){
        OfferSetter {
            changeOfferStatus(status: $status, offerId: $offerId) {
                _id
            }
        }
    }
`;

export const ADMIN_CHANGE_INDIVIDUAL_PROFILE = gql`
    mutation ($params: IndividualProfileInput){
        ProfileSetter {
            adminChangeIndividualProfile(params: $params) {
                _id
                firstName
                lastName
                midName
                avatar
                sex
                industry {
                    groupName
                    name
                }
                typeKeys
                birthday
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                industryDetail
                termOfWork
                email
                site
                description
                personalLink
                hobby
                companyName
            }
        }
    }
`;

export const ADMIN_CHANGE_ENTITY_PROFILE = gql`
    mutation ($params: EntityProfileInput){
        ProfileSetter {
            adminChangeEntityProfile(params: $params) {
                _id
                firstName
                lastName
                midName
                avatar
                sex
                industry {
                    groupName
                    name
                }
                typeKeys
                birthday
                address {
                    countryId
                    stateId
                    cityId
                    countryName
                    stateName
                    cityName
                }
                industryDetail
                termOfWork
                email
                site
                description
                hobby
            }
        }
    }
`;

export const DELETE_USER = gql`
    mutation ($userId: String!){
		ProfileSetter {
			deleteUser(UserId: $userId)
		}
	}
`;

export const CHECK_USER = gql`
    mutation ($check: Boolean! $UserId: String!){
		ProfileSetter {
			checkUser(check: $check, UserId: $UserId)
		}
	}
`;



