import React from 'react';
import cn from 'classnames';

import classes from './styles.module.scss';

export const Tab  = ({ label, value, activeTab, onChangeTab }) => {
  const handleClickTab = () => onChangeTab(value);

  return (
    <div
      className={cn(classes.item, { [classes.active]: value === activeTab })}
      onClick={handleClickTab}
    >
      {label}
    </div>
  );
};
