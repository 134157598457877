import TechnicalSupports from "components/UI/Forms/TechnicalSupport/index";
import Language from "language/index";
import { GET_AUTH } from "query/index";
import React, { Component } from 'react';
import { Mutation, Query } from "react-apollo";
import { showError } from "libs/showFormsError";
import { required } from "libs/validationsForms";
import { SEND_MESSAGE_TECH_SUPPORT } from "mutation/index";


class CenterSide extends Component {

  state = {
    isSend: false,
    preloader: false
  };

  sendForm = values => {
    const { theme, text } = values;

    showError({
      theme: [required(theme)],
      text: [required(text)],
    });

    this.setState({ preloader: true });

    this.props.mutate({ variables: { ...values } })
      .then(res => {
        this.setState({ preloader: false });
        if (res.data.SupportSetter.HelpMe) {
          this.setState({ isSend: true })
        }
      })


  };


  render() {

    const { isAuth } = this.props;
    const { isSend } = this.state;
    return (
      <div className="centerside">
        <div className="side-block">
          <div className="title-block">
            <div className="flex jcsb align">

              <h1> {Language.technicalSupport} </h1>

            </div>
          </div>
        </div>

        <div className="side-block mt15 terms-content">
          <div className="element clearfix">
            <div className="elements-group">

              {
                isSend &&
                <React.Fragment>
                  <h3>{Language.techSupport.one}</h3>
                  <p>
                    {Language.techSupport.two}
                    <br/><br/>
                    {Language.techSupport.three}
                  </p>
                </React.Fragment>
              }

              {
                !isSend &&
                <React.Fragment>
                  {
                    !isAuth &&
                    <div>
                      <h3> {Language.LogRegisterSupport} </h3>
                      <a href="/"
                         className="link-orange"> {Language.AuthorizationRegistration} </a>
                    </div>
                  }

                  {
                    isAuth &&
                    <React.Fragment>
                      <p style={{ fontSize: '16px', maxWidth: '92%' }}>
                        {Language.techSupportFormText}
                      </p>
                      <TechnicalSupports preloader={this.state.preloader} onSubmit={this.sendForm}/>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default props => {
  return (
    <Mutation mutation={SEND_MESSAGE_TECH_SUPPORT}>
      {mutate => (
        <Query query={GET_AUTH} fetchPolicy="cache-only">
          {({ data, loading, error }) => {
            if (error || loading) return 'Loading...';
            return <CenterSide mutate={mutate} {...props} {...data}/>
          }}
        </Query>
      )}
    </Mutation>

  )
};