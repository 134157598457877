import { useEffect, useState } from "react";

const sizes = {
  xs: 575,
  sm: 768,
  md: 1024,
  lg: 1280,
  xlg: 1440,
}


function useMediaQuery(query) {
  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    handleChange();

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
  }, [query]);

  return matches;
}

function useSizeXS() {
  return useMediaQuery(`(max-width: ${sizes.xs}px)`);
}

function useSizeSM() {
  return useMediaQuery(`(max-width: ${sizes.sm}px)`);
}

function useSizeMD() {
  return useMediaQuery(`(max-width: ${sizes.md}px)`);
}

function useSizeLG() {
  return useMediaQuery(`(max-width: ${sizes.lg}px)`);
}

function useSizeXLG() {
  return useMediaQuery(`(max-width: ${sizes.xlg}px)`);
}

export { useMediaQuery, useSizeXS, useSizeSM, useSizeMD, useSizeLG, useSizeXLG };
