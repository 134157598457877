export const CheckMessageIcon = ({ className }) => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.58343 0.135842C9.27797 -0.0819395 8.83597 -0.0322701 8.59597 0.244733L3.63053 5.98092L1.25233 3.30832C1.0074 3.03514 0.565399 2.99056 0.262757 3.21153C-0.040589 3.43249 -0.0877448 3.83367 0.155073 4.10685L3.08929 7.40414C3.22372 7.55442 3.42501 7.64166 3.63827 7.64166H3.6432C3.85716 7.64039 4.05986 7.5506 4.19218 7.39714L9.70378 1.02926C9.94378 0.75289 9.89029 0.35235 9.58343 0.135842Z" fill="#B9B9B9"/>
    </svg>
  )
};




