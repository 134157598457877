import Input from "components/UI/Fields/Input";
import Select from "components/UI/Fields/Select";
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import { Field } from "redux-form";


export default class Money extends PureComponent {

    static propTypes = {
        nameField: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.array
    };

    static defaultProps = {
        label: 'Money',
        nameField: 'money',
        options: [
            { value: 'rub', label: 'Рубль' },
            { value: 'eur', label: 'Euro' },
            { value: 'dollar', label: 'USD' },
            { value: 'percent', label: '%'}
        ]
    };


    render() {
        const { nameField, label, options } = this.props;
        return (
            <div className="element">
                <div className="row clearfix">
                    <Field
                        name={`${nameField}[amount]`}
                        type="number"
                        maxLength="40"
                        component={Input}
                        label={label}
                        className="col-10"
                    />
                    <Field
                        name={`${nameField}[currency]`}
                        component={Select}
                        className="col-2"
                        options={options}
                    />
                </div>
            </div>
        )
    }
};